<template>
  <el-dialog :title="formTitle" :visible.sync="formDialog" :before-close="hideDialog" :close-on-click-modal="false">
    <div>
      <h4>엑셀로 편집한 데이터를 입력합니다.</h4>
      <input ref="upload" type="file" accept=".xlsx" @change="uploadExcel($event)" label="upload" />

      <div style="min-height: 200px; overflow: auto;">
        <table class="dk-table dk-table-small excel-preview-table" v-if="gridData.length > 0 && gridHeader.length > 0">
          <tr v-for="(row, index) in gridData" :class="checkIsChange(row)" :key="index" v-if="index > 0 || index == 0">
            <td v-for="(cell, index2) in gridHeader" :key="index2">
              <div>{{ row[cell.key] }}</div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="save" :disabled="gridData.length == 0" type="primary">저장</el-button>
      <el-button @click="hideDialog">닫기</el-button>
    </span>
  </el-dialog>
</template>

<script>
import XLSX from 'xlsx';
import moment from 'moment-timezone';
import _ from 'lodash';

export default {
  data() {
    return {
      systemLogInfo : {},
      formTitle: '시설물 정보 일괄 입력',
      formDialog: false,
      uploadData: [],
      gridData: [],
      gridHeader: [],
      fcltData: [],
      fcltTypeId: '',
      fcltTypeName: '',
      loading: null,
    };
  },
  created() {},
  methods: {
    async getFcltData(fcltTypeId) {
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fclt`, {
        params: { fcltTypeId: fcltTypeId },
      });
      this.fcltData = response.data.docs;

      this.fcltData.forEach((fcltData) => {
        _.map(fcltData.fcltTypeData, (fcltTypeData) => {
          fcltData[fcltTypeData.key] = fcltTypeData.value;
        });
      });
      this.gridData.forEach((gridData) => {
        let tmpData = this.fcltData.find((ff) => {
          return ff.fcltId == gridData.fcltId || (ff.fcltName == gridData.fcltName && gridData.fcltId === '');
        });
        if (tmpData) {
          let fcltData = _.pick(tmpData, _.keys(gridData));
          gridData.isChange = !_.isEqual(gridData, fcltData);
          gridData.isUpdate = true;
        }
      });
    },
    resetData() {
      this.uploadData = [];
      this.gridData = [];
      this.$refs.upload.value = '';
      this.fcltTypeId = '';
      this.fcltTypeName = '';
    },
    hideDialog() {
      if (this.uploadData.length > 0) {
        this.$confirm(`입력 중인 데이터가 있습니다. 취소 하시겠습니까?`, '경고', {
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          type: 'warning',
        })
          .then(() => {
            this.resetData();
            this.formDialog = false;
          })
          .catch(() => {
            this.formDialog = true;
          });
      } else {
        this.resetData();
        this.formDialog = false;
      }
    },
    showDialog() {
      this.formDialog = true;
    },
    async uploadExcel(e) {
      // if (e.target.files[0]['type'] !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      //   this.$message.error(`본 시스템에서 출력한 xlsx형식의 파일 외에는 업로드 하실 수 없습니다.`);
      //   this.$refs.upload.value = '';
      //   return false;
      // }
      var reader = new FileReader();
      var rABS = false;
      reader.onload = function (e) {
        var data = e.target.result;
        if (!rABS) data = new Uint8Array(data);
        try {
          let workbook = XLSX.read(data, {
            type: rABS ? 'binary' : 'array',
            cellDates: true,
            cellNF: false,
            cellText: false,
          });
          if (!workbook.Props || !workbook.Props.Application) {
            this.$message.error(`xlsx형식의 파일 외에는 업로드 하실 수 없습니다.`);
            this.$refs.upload.value = '';
            return false;
          }

          let sheetNameList = workbook.SheetNames;
          this.uploadData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNameList[0]]);
          const prop = XLSX.utils.sheet_to_json(workbook.Sheets[sheetNameList[1]]);
          this.gridData = _.cloneDeep(this.uploadData);
          if (prop[0] && prop[0].fcltTypeId !== '') {
            this.fcltTypeId = prop[0].fcltTypeId;
            this.fcltTypeName = prop[0].fcltTypeName;
            this.getFcltData(prop[0].fcltTypeId).then(() => {
              this.$store
                .dispatch('excel/getHeader', {
                  fcltTypeId: prop[0].fcltTypeId,
                })
                .then((result) => {
                  this.gridHeader = result.header;
                })
                .catch((err) => {
                  this.$message.error(`엑셀 파일 로딩에 실패했습니다.`);
                  this.$refs.upload.value = '';
                  return false;
                });
            });
          }
          // 첫번째 라벨 행 지움
          this.uploadData.splice(0, 1);
          this.uploadData.forEach((ud) => {
            ud.fcltTypeId = this.fcltTypeId;
          });
        } catch (err) {
          console.error(err);
          
        }
      }.bind(this);
      if (rABS) reader.readAsText(this.$refs.upload.files[0]);
      else reader.readAsArrayBuffer(this.$refs.upload.files[0]);
      this.systemLogInfo.page = `${this.$route.name}`
      this.systemLogInfo.saveType = "importExcel"
      await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
    },
    save() {
      // header정보 갱신하기전에 누군가가 좃나 빛의속도로 저장을 누르면 버그날 수 있음.
      let changeCount = 0;
      this.gridData.forEach((gridData) => {
        if (gridData.isChange) changeCount++;
      });
      if (changeCount > 0) {
        this.$confirm(`총 ${changeCount}건의 변경된 데이터가 있습니다. 계속 하시겠습니까?`, '경고', {
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          type: 'warning',
        })
          .then(() => {
            this.uploadExcelDataToServer();
          })
          .catch(() => {});
      } else {
        this.uploadExcelDataToServer();
      }
    },
    uploadExcelDataToServer() {
      let me = this;
      this.loading = this.$loading({
        lock: true,
        text: '시설물 데이터를 업로드 중입니다',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      let insertFcltCount = 0;
      for (var i = 1; i < this.gridData.length; i++) {
        if (!this.gridData[i].isUpdate) {
          insertFcltCount++;
        }
      }
      this.gridData = [];
      let inputData = _.groupBy(this.uploadData, 'fcltTypeId');
      /*
            this.uploadData.forEach(data => {
                for(var i=0; i<this.gridHeader.length; i++) {
                    let header = this.gridHeader[i]
                    if (header.formType.toLowerCase() == 'date') {      
                        //console.log(data[header.key])
                        //var x = require('ssf').parse_date_code(data[header.key])
                        //console.log(x)
                        //data[header.key] = moment(`${x.d}/${x.m}/${x.y}`, 'DD/MM/YYYY')                        
                    }
                }
            })
            */

      let licenseInfo = this.$store.getters['license/getLicense'];
      if (licenseInfo.currentFcltCount + insertFcltCount > licenseInfo.limitFcltCount) {
        this.$alert(
          `등록된 ${licenseInfo.currentFcltCount}건의 시설물 정보가 있고, ${insertFcltCount} 건의 시설물이 등록 대기중입니다. 현재 라이선스는 총 ${licenseInfo.limitFcltCount}건의 시설물이 등록 가능하므로, 유효한 시설물 등록 수를 초과합니다.`,
          '라이선스 등록 초과',
          {
            confirmButtonText: '확인',
            type: 'warning',
          }
        );
        me.loading.close();
        me.resetData();
        me.hideDialog();
        this.$store.dispatch('license/setDialog', true);
        return;
      }

      this.$store
        .dispatch('excel/saveData', { data: inputData[this.fcltTypeId] })
        .then((r) => {
          if (r.currentFcltCount) this.$store.dispatch('license/setCurrentFcltCount', r.currentFcltCount);

          if (r.result == 1) {
            me.$parent.$emit('procDataComplete', this.fcltDataDoc);
            me.loading.close();
            me.resetData();
            me.hideDialog();
            me.$message({
              type: 'success',
              message: '시설물 정보 일괄 저장 완료',
            });
          } else {
            me.loading.close();
            me.resetData();
            me.hideDialog();
            me.$message.error(`시설물 정보 일괄 저장 중 에러 발생`);
          }
        })
        .catch((err) => {
          me.loading.close();
          me.resetData();
          me.hideDialog();
          me.$message.error(`시설물 정보 일괄 저장 중 에러 발생`);
        });
    },
    checkIsChange(gridData) {
      if (gridData.isChange) {
        return 'change-row';
      } else {
        return '';
      }
    },
  },
};
</script>

<style>
.excel-preview-table td,
.excel-preview-table th {
  white-space: nowrap;
}
.change-row {
  background-color: #ef9a9a;
}
</style>
