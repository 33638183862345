<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {
      e1: 'ko',
      countries: this.$country,
      countrydetails: [],
    };
  },
  methods: {
    handleClick_changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
  },
  watch: {
    e1: function (val) {
      this.$i18n.locale = val;
      this.locale = val;
      this.$validator.localize(this.locale);
    },
  },
  created() {
    // for(var i in this.countries){
    //   this.$http.get(this.$config.getServerConfig().env.api.country+this.countries[i]).then((response)=>{
    //     this.countrydetails.push(response.data)
    //   })
    // }
  },
};
</script>
