import vue from 'vue' 
import cameraViewAndControl from './cameraViewAndControl.vue'
export default {
    data() {
        return {    
            value2: false,
            tableData: [{
                date: '1',
                name: 'Tom',
                state: 'California',
                city: 'Los Angeles',
                address: 'No. 189, Grove St, Los Angeles',
                zip: 'CA 90036',
                tag: 'Home'
              }, {
                date: '2',
                name: 'Tom',
                state: 'California',
                city: 'Los Angeles',
                address: 'No. 189, Grove St, Los Angeles',
                zip: 'CA 90036',
                tag: 'Office'
              }, {
                date: '3',
                name: 'Tom',
                state: 'California',
                city: 'Los Angeles',
                address: 'No. 189, Grove St, Los Angeles',
                zip: 'CA 90036',
                tag: 'Home'
              }, {
                date: '3',
                name: 'Tom',
                state: 'California',
                city: 'Los Angeles',
                address: 'No. 189, Grove St, Los Angeles',
                zip: 'CA 90036',
                tag: 'Home'
              }, {
                date: '3',
                name: 'Tom',
                state: 'California',
                city: 'Los Angeles',
                address: 'No. 189, Grove St, Los Angeles',
                zip: 'CA 90036',
                tag: 'Home'
              }, {
                date: '3',
                name: 'Tom',
                state: 'California',
                city: 'Los Angeles',
                address: 'No. 189, Grove St, Los Angeles',
                zip: 'CA 90036',
                tag: 'Home'
              }, {
                date: '3',
                name: 'Tom',
                state: 'California',
                city: 'Los Angeles',
                address: 'No. 189, Grove St, Los Angeles',
                zip: 'CA 90036',
                tag: 'Home'
              }, {
                date: '3',
                name: 'Tom',
                state: 'California',
                city: 'Los Angeles',
                address: 'No. 189, Grove St, Los Angeles',
                zip: 'CA 90036',
                tag: 'Home'
              }, {
                date: '3',
                name: 'Tom',
                state: 'California',
                city: 'Los Angeles',
                address: 'No. 189, Grove St, Los Angeles',
                zip: 'CA 90036',
                tag: 'Home'
              }],

            loadCamera: {
                user: '',
                e1: null,
                cameraList: [],
                errors: []
            },
            presetHeaders: [
                {text: '순서', value:'presetSeq', class:'dk-table-cell', width:'30px', align:'left'},
                {text: '번호', value: 'presetNo', class:'dk-table-cell', width:'30px', align:'left'},
                // {text: '프리셋 명칭', value: 'presetName', sortable: false},
                {text: '유형', value: 'presetType', sortable: false},
                {text: '', value:'action', sortable:false, class:'dk-table-cell',  width:'124px'}
            ],
            presetInfo: [],
            cameraId: '',
            cameraPlaceName: '',
            presetSaveMsg:"",
            presetSaveMsgSnackbar:false,
            presetData: {
                presetNo: 0,
                stat: {
                    presetName: "",
                    presetType: 5
                }
            },            
            speed: 80,
            presetType: [],            
            isSemiAutoPreset: false,
            period:3000,
            cameraControlIp:`${this.$config.getServerConfig().enf.config.cameraControlIp}`,
            cameraControlPort:this.$config.getServerConfig().enf.config.cameraControlPort,
            mjpegUrl:`${this.$config.getServerConfig().enf.config.mjpegUrl}`,
            activePresetInfoIdx:-1
        }
    },
    //컴포넌트 생성시
    created() {
        let me = this        
        this.$http.post(`${this.$config.getServerConfig().enf.api.video}/camera/db/getCameraListInfo`, {
            body: this.loadCamera.user
        })
        .then(response => {
            var list = [];
            response.data.cameraInfo.forEach(items => {
                list.push({
                    cameraNm: `[${items.cameraId}]${items.cameraPlaceName}`,
                    cameraId: items.cameraId
                })
            });            
            me.loadCamera.cameraList = list
            //this.loadCamera.cameraList = response.data.cameraInfo
        })
        .catch(e => {
            me.errors.push(e)
        })        
        this.presetType = this.$dkEnfCodeFormatter.getCodeValue("ENF_ZONE_TYPE")
        /*
        this.$http.post(`${this.$config.getServerConfig().enf.api.detect}/code/getDetectZoneType`,{})
        .then(response =>{
            var list = [];
            response.data.zoneType.forEach(items =>{
                list.push({
                    text : items.name,        
                    value : items.code
                })
                this.presetType = list
            })
        })*/

        this.$on('semiAutoPresetComplete', function(pData) {
            if (confirm("이 영역 정보로 단속영역을 설정하시겠습니까?")) {
                // 저장
                //console.log(pData)
                
                //console.log(this.presetInfo)
                let presetDatas =[]
                let presetMaxNum
                if(this.presetInfo.length==0){
                    presetMaxNum = 0
                }
                else{
                    for(var i=0; i<this.presetInfo.length; i++){
                        if (i==0){
                            presetMaxNum = this.presetInfo[i].presetNo
                        } 
                        else{
                            if(presetMaxNum < this.presetInfo[i].presetNo){
                                presetMaxNum = this.presetInfo[i].presetNo 
                            }
                        }                    
                    }    
                }


                for(var j=0; j<pData.length; j++){
                    presetDatas[j]={
                        cameraId: this.cameraId,
                        editType: 1,
                        presetNo: presetMaxNum + 1 + j ,
                        presetSeq: presetMaxNum + 1 + j ,
                        stat:{
                            presetName:"",
                            presetType: 5
                        },
                        ptz:{
                            pan: pData[j].pan,
                            tilt: pData[j].tilt,
                            zoom: pData[j].zoom
                        }
                    }
                }
                let prm = Promise.resolve();
                for (var k=0; k<presetDatas.length; k++){                    
                    prm = this.semiAutoPresetSave(presetDatas[k], prm)
                }

                this.isSemiAutoPreset = !this.isSemiAutoPreset
                this.$refs.cameraViewAndControl.setSemiAutoPreset(this.isSemiAutoPreset, this.period)  
                me.$refs.cameraViewAndControl.resetSemiAutoPreset()

            }
            else {
                // 취소
                me.$refs.cameraViewAndControl.resetSemiAutoPreset()
            }            
        })
    },



    //ui 다 붙엇을때
    mounted() {
        let _this = this;
        _this.$nextTick(function () {
            //_this.$refs.cameraViewAndControl.connectCamera('142'); 
      
            //_this.$refs.cameraViewAndControl.disconnectCamera();

            
        })        
    },    
    components: {
        'camera-view-and-control': cameraViewAndControl,
      },
    computed: {
    },
    // watch: {
        // presetInfo(newVal , oldVal) {
        //     console.log(newVal)
        // }
    // },
    methods: {
        changeCameraList(cameraId) {
            //this.$refs.cameraViewAndControl.disconnectCamera()            
            this.cameraId = cameraId
            this.cameraPlaceName = this.getCameraPlaceName(cameraId)
            var presetType = this.presetType
            this.$http.post(`${this.$config.getServerConfig().enf.api.video}/camera/db/getPresetInfo`, {
                'cameraId': this.cameraId
            })
            .then(response => {
                this.$refs.cameraViewAndControl.connectCamera(this.cameraId)
                let list = []
                response.data.presetInfo.forEach((data) => {
                    data.selected = false
                    list.push(data)
                })
                this.presetInfo = list
            })
            .catch(e => {
                this.errors.push(e)
            })
        },

        moveContinuous(val){            
            var tilt = 0
            var pan = 0
            var zoom = 0
            var cameraId = this.cameraId
            var moveSpeed = this.speed
            if(val == 'leftUp'){
                tilt = 1 *moveSpeed
                pan = -1 *moveSpeed

            }else if(val == 'up'){
                tilt = 1 *moveSpeed

            }else if(val == 'rightUp'){
                tilt = 1 *moveSpeed
                pan = 1 *moveSpeed

            }else if(val == 'left'){
                pan = -1 *moveSpeed

            }else if(val == 'right'){
                pan = 1 *moveSpeed

            }else if(val == 'downLeft'){
                tilt = -1 *moveSpeed
                pan = -1 *moveSpeed

            }else if(val == 'down'){
                tilt = -1 *moveSpeed

            }else if(val == 'downRight'){
                tilt = -1 *moveSpeed
                pan = 1 *moveSpeed

            }else if(val == 'stop'){
                tilt = 0
                pan = 0
            }else if(val == 'zoomIn'){
                zoom = 1 *moveSpeed

            }else if(val == 'zoomOut'){
                zoom = -1 *moveSpeed
            }
            this.$http.post(`${this.$config.getServerConfig().enf.api.video}/camera/control/moveContinuous`,{
                'pan' : pan,
                'tilt' : tilt,
                'zoom' : zoom,
                'cameraId' : cameraId
            })
        },
        semiAutoPresetSave(presetData, prm){
            return prm.then(()=>{
                new Promise((resolve, reject) => {
                    this.$http.post(`${this.$config.getServerConfig().enf.api.video}/camera/db/setPreset`,presetData)
                    .then((response)=> {                        
                            let newPresetData = JSON.parse(JSON.stringify(presetData))
                            delete newPresetData.editType
                            this.presetInfo.push(newPresetData)                        
                        return
                    })
                })  
            })
        },
        presetSave(data) {
            if (!this.cameraId) {
                alert("설정 할 카메라를 선택해 주세요")
                return
            }   
            let presetSeq = 0
            let presetData = {
                editType: 1
            }         
            if (data) {
                data.edited = false
                presetData.editType = 2
                Object.assign(presetData, data)
            }
            else {
                presetData.cameraId = this.cameraId
                Object.assign(presetData, this.presetData)
                //let presetIndex = this.presetInfo.length
                let presetMaxSeq
                if(this.presetInfo.length==0){
                    presetData.presetSeq = 1
                    presetData.presetNo = 1
                }
                else{
                    for(var i=0; i<this.presetInfo.length; i++){
                        if (i==0){
                            presetMaxSeq = this.presetInfo[i].presetSeq
                        } 
                        else{
                            if(presetMaxSeq < this.presetInfo[i].presetSeq){
                                presetMaxSeq = this.presetInfo[i].presetSeq 
                            }
                        }                    
                    }  
                    presetData.presetSeq = presetMaxSeq+1
                    presetData.presetNo = presetMaxSeq+1
                }

                
            }
            new Promise((resolve, reject) => {
                this.$http.post(`${this.$config.getServerConfig().enf.api.video}/camera/control/getPtz`, {
                    "cameraId" : this.cameraId
                })
                .then((response) => {                  
                    let ptz = {
                        pan: response.data.pan,
                        tilt: response.data.tilt,
                        zoom: response.data.zoom
                    }                    
                    return resolve(ptz)
                })
            })            
            .then((ptz) => {                
                presetData.ptz = ptz       
                this.$http.post(`${this.$config.getServerConfig().enf.api.video}/camera/db/setPreset`,presetData)
                .then((response)=> {
                    this.presetSaveMsg = this.cameraPlaceName + " " + presetData.presetNo + "번 프리셋"
                    if (presetData.editType == 1) {                             
                        let newPresetData = JSON.parse(JSON.stringify(presetData))
                        delete newPresetData.editType
                        this.presetInfo.push(newPresetData)                        
                        this.presetSaveMsg += " 추가"
                    }
                    else {
                        this.presetSaveMsg += " 수정"
                    }
                    this.presetSaveMsgSnackbar = true
                    return
                })                
            })
            .catch((err) => {
                alert(err)
            })
        },        
        presetDelete(data) {
            let presetData = {
                editType: 3
            }            
            Object.assign(presetData, data)
            const index = this.presetInfo.indexOf(data)

            if (!confirm(this.cameraPlaceName + " " + presetData.presetNo + "번 프리셋을 삭제 합니다. 맞습니까?")) {
                return 
            }
            new Promise((resolve, reject) => {
                this.$http.post(`${this.$config.getServerConfig().enf.api.video}/camera/db/setPreset`,presetData)
                .then((response)=> {
                    this.presetSaveMsg = this.cameraPlaceName + " " + presetData.presetNo + "번 프리셋 삭제 완료"
                    this.presetSaveMsgSnackbar = true
                    this.presetInfo.splice(index, 1)
                })
            })
        },
        presetMove(presetNo, props){
            if (this.activePresetInfoIdx > -1) {
                this.presetInfo[this.activePresetInfoIdx].selected = false
            }
            props.item.selected = true
            this.$http.post(`${this.$config.getServerConfig().enf.api.video}/camera/control/movePreset`,{
                'cameraId' : this.cameraId,
                'presetNo' : presetNo
            })
            this.activePresetInfoIdx = props.index            
        },        
        
        getCameraPlaceName(val) {
            var txt = "";
            for (var i=0; i<this.loadCamera.cameraList.length; i++) {
                if (this.loadCamera.cameraList[i].value == val) {
                    txt = this.loadCamera.cameraList[i].text
                    break
                }
            }
            return txt
        },
        gridPresetType(item) {
            item.edited = true
        },
        setSemiAutoPreset() {            
            if (!this.cameraId) {
                alert("설정 할 카메라를 선택해 주세요")
                return
            }   
            this.isSemiAutoPreset = !this.isSemiAutoPreset            
            this.$refs.cameraViewAndControl.setSemiAutoPreset(this.isSemiAutoPreset, this.period)         
        },
        handleClick() {
            console.log('click');
          }
    }
}