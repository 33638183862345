var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dk-template-table-wrap", style: _vm.gridWrapStyle },
    [
      _c("table", [
        _c("thead", [
          _c(
            "tr",
            { staticClass: "grid-header" },
            _vm._l(_vm.header, function(item, idx) {
              return _c("th", { key: idx }, [
                _vm._v("\n          " + _vm._s(item.label) + "\n        ")
              ])
            }),
            0
          )
        ]),
        _c(
          "tbody",
          _vm._l(_vm.data, function(row, rowIdx) {
            return _c(
              "tr",
              { key: rowIdx },
              _vm._l(_vm.header, function(headerItem, headerIdx) {
                return _c("td", { key: headerIdx }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.getCellValue(row, headerItem)) +
                      "\n        "
                  )
                ])
              }),
              0
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }