
export const incidentDescType = [
  {value: "0",	 label: "추가정보 필요없음"}
  ,{value: "1",	 label: "추가정보 필요"}
  ,{value: "2",  label: "차량 사고"}
  ,{value: "3",  label: "기상관련 사고"}
  ,{value: "4",	 label: "기후,고장 등 차량 정지"}
  ,{value: "5",  label: "차량 화재"}
  ,{value: "6",	 label: "장애물"}
  ,{value: "7",  label: "위험물질 방출"}
  ,{value: "8",  label: "지진"}
  ,{value: "9",	 label: "산사태"}
  ,{value: "10", label: "홍수"}
  ,{value: "11", label: "태풍"}
  ,{value: "12", label: "예고되지 않은 시위/집회"}
  ,{value: "13", label: "차량의 급격한 증가"}
]

export const incidentVehicle = [
  {value:"0",	label: "추가정보 필요없음"}
  ,{value:"1",	label: "추가정보 필요"}
  ,{value:"2",	label: "자전거/오토바이"}
  ,{value:"3",	label: "승용차"}
  ,{value:"4",	label: "버스"}
  ,{value:"5",	label: "트럭"}
  ,{value:"6",	label: "트레일러"}
]

export const incidentStatus = [
  {value:"0", label: "추가정보 필요없음"}
  ,{value:"1", label:	"추가정보 필요"}
  ,{value:"2", label:	"사고 감지"}
  ,{value:"3", label:	"확인 및 대응조치"}
  ,{value:"4", label:	"처리 및 복구"}
  ,{value:"5", label:	"완료"}
  ,{value:"6", label:	"기타"}
]

export const updateType = [
  {value:"0",	label:"추가정보 필요없음"}
  ,{value:"1",	label:"추가정보 필요"}
  ,{value:"2",	label:"신규"}
  ,{value:"3",	label:"업데이트"}
  ,{value:"4",	label:"삭제"}
  ,{value:"5",	label:"완료 혹은 통제"}
]

export const eventDescType = [
  {value:"0", label:"없음"}
  ,{value:"1", label:"기타"}
  ,{value:"2", label:"공사"}
  ,{value:"3", label:"도로폐쇄"}
  ,{value:"4", label:"특별행사", children: [
    {value:"0",	label:"없음"}
    ,{value:"1",	label:"기타"}
    ,{value:"2",	label:"퍼레이드"}
    ,{value:"3",	label:"스포츠이벤트"}
    ,{value:"4",	label:"시위 및 집회"}
    ,{value:"5",	label:"축제"}
    ,{value:"6",	label:"예술공연"}
    ,{value:"7",	label:"수행행렬"}
  ]}
]

export const plannedRoadClosure = [
  {value:"0",	label:"없음"}
  ,{value:"1",	label:"기타"}
  ,{value:"2",	label:"장기공사"}
  ,{value:"3",	label:"단기공사"}
  ,{value:"4",	label:"유지보수"}
  ,{value:"5",	label:"긴급유지보수"}  
]

export const lanesShouldersBlocked = [
  ,{value:"0", label:"없음"}
  ,{value:"1", label:"기타"}
  ,{value:"2", label:"우측 길어깨"}
  ,{value:"3", label:"좌측 길어깨"}
  ,{value:"4", label:"양쪽 길어깨"}
]