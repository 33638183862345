<template>
  <el-dialog
    :visible.sync="formDialog"
    :title="formTitle"
    @close="hideDialog"
    :close-on-click-modal="false"
  >
    <!-- 기자재 유형 관리 dialog -->
    <div>
      <el-form
        :model="materialTypeDoc"
        label-width="160px"
        size="medium"
        :rules="rules"
        ref="form0"
        :label-position="$isMobile ? 'top' : 'right'"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="기자재 유형 명칭" prop="materialTypeName">
              <el-input
                v-model="materialTypeDoc.materialTypeName"
                placeholder="기자재 유형 명칭"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div>
        <el-transfer
          v-model="tempMaterialTypeData"
          :data="datasets"
          :titles="['데이터셋', '기자재 유형 데이터']"
        ></el-transfer>
      </div>
    </div>
    <div slot="footer" class="form-bottom dialog-footer">
      <el-button type="primary" @click="save">저장</el-button>
      <el-button @click="hideDialog">취소</el-button>
    </div>

    <!-- 기자재 관리 dialog 끝 -->
  </el-dialog>
</template>

<script>
import dkdynamicform from '@/components/dkDynamicForm.vue';

export default {
  components: {
    'dk-dynamic-form': dkdynamicform,
  },
  created() {
    let me = this;
    this.getDataSet();
  },
  data() {
    return {
      formDialog: false,
      formTitle: '기자재 유형 추가',
      updateFlag: false,
      datasets: [],
      materialTypeDoc: {
        materialTypeName: '',
        materialTypeData: [],
      },
      tempMaterialTypeData: [],
      rules: {
        materialTypeName: [
          {
            required: true,
            message: '기자재 유형 명칭을 입력해 주세요',
            trigger: 'blur',
          },
          { min: 2, message: '2자 이상 입력해주세요', trigger: 'blur' },
        ],
      },
    };
  },
  props: {},
  updated() {
    this.$nextTick(() => {});
  },
  methods: {
    async getDataSet() {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/dataset`, {});
      this.datasets = response.data;
    },
    async save() {
      let me = this;
      let flag = false;
      this.$refs.form0.validate((valid) => {
        flag = valid;
        if (valid) {
          return true;
        } else {
          return false;
        }
      });
      if (!flag) {
        return;
      }

      let saveFunc = 'materialType/addd';
      if (this.updateFlag) {
        saveFunc = 'materialType/update';
      }
      let tempData = {};
      this.tempMaterialTypeData.forEach((val) => {
        let data = this.datasets.find((dataset) => {
          return val == dataset.key;
        });
        tempData[val] = data;
      });
      this.materialTypeDoc.materialTypeData = tempData;
      try {
        let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, {
          data: this.materialTypeDoc,
        });
        me.$parent.$emit('procDataComplete', this.materialTypeDoc);
        me.resetData();
        me.hideDialog();
        me.$message({
          type: 'success',
          message: `${me.materialTypeDoc.materialTypeName} 기자재 유형 저장 완료`,
        });
      } catch (error) {
        this.resetData();
        this.hideDialog();
        this.$message({
          type: 'info',
          message: `기자재 유형 저장 중 에러 발생`,
        });
      }
    },

    // setMaterialTypeDataDoc(data) {
    //     let me = this;
    //     this.materialTypeDoc = this.$lodash.cloneDeep(data);
    //     this.materialTypeDoc.setDate = new Date(this.materialTypeDoc.setDate);
    //     if (this.materialTypeDoc.files && typeof this.materialTypeDoc.files === "object") {
    //         this.materialTypeDoc.files.forEach((file) => {
    //             file.url = `${file.serverFilePath}`;
    //         });
    //     }
    // },
    resetData() {
      this.step = 0;
      this.materialTypeDoc = {
        materialTypeName: '',
        materialTypeData: [],
      };
      this.tempMaterialTypeData = [];
    },
    showDialog(row) {
      if (row) {
        this.updateFlag = true;
        this.formTitle = '기자재 유형 수정';
        let tempData = this.$lodash.cloneDeep(row);
        for (var val in tempData.materialTypeData) {
          this.tempMaterialTypeData.push(val);
        }
        console.log(this.tempMaterialTypeData);
        tempData.materialTypeData = [];
        this.materialTypeDoc = tempData;
      } else {
        this.updateFlag = false;
        this.formTitle = '기자재 유형 추가';
      }
      this.formDialog = true;
      this.$forceUpdate();
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
  },
};
</script>

<style scoped>
.el-form-item__content {
  margin-left: 120px;
}
</style>
