var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-spread-situation" }, [
    _c(
      "div",
      { staticClass: "top-bar-space" },
      [
        _c(
          "div",
          { staticClass: "gis-wrap" },
          [
            _c("dk-gis", {
              ref: "dkGis",
              staticStyle: { height: "340px" },
              attrs: {
                level: _vm.level,
                gisApiInfo: _vm.gisApiInfo,
                apiLoadComplete: _vm.apiLoadComplete
              }
            }),
            _c(
              "div",
              {
                staticClass: "mygis-location btn-style spread-location",
                on: { click: _vm.initMarkerPosition }
              },
              [
                _c("i", { staticClass: "icon-target" }),
                _c("div", [_vm._v("현재위치")])
              ]
            )
          ],
          1
        ),
        _c(
          "el-container",
          { staticClass: "dialog-container maintnce-container" },
          [
            _c(
              "el-container",
              [
                _c(
                  "el-form",
                  {
                    ref: "form1",
                    staticClass: "form-maintnce",
                    attrs: {
                      rules: _vm.rules,
                      size: "medium",
                      model: _vm.formData,
                      "label-position": "top"
                    }
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            !_vm.$isMobile
                              ? _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "유지관리 대상 시설물",
                                      prop: "fcltName"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      model: {
                                        value: _vm.formData.fcltName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "fcltName",
                                            $$v
                                          )
                                        },
                                        expression: "formData.fcltName"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            attrs: { span: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "작업 유형", prop: "workType" }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "90%" },
                                    attrs: { placeholder: "작업 유형" },
                                    model: {
                                      value: _vm.formData.workType,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formData, "workType", $$v)
                                      },
                                      expression: "formData.workType"
                                    }
                                  },
                                  _vm._l(_vm.workTypes, function(work, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: work.label,
                                        value: work.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: false,
                                expression: "false"
                              }
                            ],
                            attrs: { span: 12 }
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "중요도", prop: "importance" }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { placeholder: "중요도" },
                                    model: {
                                      value: _vm.formData.importance,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "importance",
                                          $$v
                                        )
                                      },
                                      expression: "formData.importance"
                                    }
                                  },
                                  _vm._l(_vm.importances, function(imp, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: imp.label,
                                        value: imp.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "요약",
                                  prop: "maintnceSummary"
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "상황전파 내용 요약" },
                                  model: {
                                    value: _vm.formData.maintnceSummary,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "maintnceSummary",
                                        $$v
                                      )
                                    },
                                    expression: "formData.maintnceSummary"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "설명", prop: "maintnceDesc" }
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "내용 설명",
                                    type: "textarea",
                                    rows: 5
                                  },
                                  model: {
                                    value: _vm.formData.maintnceDesc,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "maintnceDesc",
                                        $$v
                                      )
                                    },
                                    expression: "formData.maintnceDesc"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#606266",
                                  "font-size": "14px",
                                  "line-height": "40px"
                                }
                              },
                              [_vm._v("상황전파 관련 사진")]
                            ),
                            _c(
                              "dk-el-file-upload",
                              {
                                ref: "uploadImage",
                                attrs: {
                                  uploaderType: "image",
                                  listType: "picture-card",
                                  path: "maintnce",
                                  fileList: _vm.formData.files,
                                  onPreview: _vm.handlePictureCardPreview
                                }
                              },
                              [
                                _c("div", [
                                  _c("i", { staticClass: "el-icon-plus" })
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("el-aside", { attrs: { width: "280px" } }, [
              _c(
                "div",
                { staticClass: "info-panel" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form2",
                      staticClass: "form-maintnce",
                      attrs: {
                        size: "small",
                        model: _vm.formData,
                        "label-position": "top"
                      }
                    },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false"
                                    }
                                  ],
                                  attrs: { label: "진행상황", prop: "status" }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "작업 진행상황" },
                                      model: {
                                        value: _vm.formData.status,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "status", $$v)
                                        },
                                        expression: "formData.status"
                                      }
                                    },
                                    _vm._l(_vm.maintnceSteps, function(
                                      status,
                                      index
                                    ) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: status.label,
                                          value: status.status
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "전파할 그룹" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "그룹을 선택하세요"
                                      },
                                      model: {
                                        value: _vm.selectGroup,
                                        callback: function($$v) {
                                          _vm.selectGroup = $$v
                                        },
                                        expression: "selectGroup"
                                      }
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: "모두",
                                          value: undefined
                                        }
                                      }),
                                      _vm._l(_vm.groupList, function(group) {
                                        return _c("el-option", {
                                          key: group.groupId,
                                          attrs: {
                                            label: group.name,
                                            value: group.groupId
                                          }
                                        })
                                      })
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false"
                                    }
                                  ],
                                  attrs: { label: "담당자", prop: "managerId" }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "담당자를 선택하세요"
                                      },
                                      model: {
                                        value: _vm.formData.managerId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "managerId",
                                            $$v
                                          )
                                        },
                                        expression: "formData.managerId"
                                      }
                                    },
                                    _vm._l(_vm.userList, function(user, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: user.name,
                                          value: user.userid
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false"
                                    }
                                  ],
                                  attrs: { label: "작업자", prop: "workerId" }
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        placeholder: "작업자를 지정할 경우 선택"
                                      },
                                      model: {
                                        value: _vm.formData.workerId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formData,
                                            "workerId",
                                            $$v
                                          )
                                        },
                                        expression: "formData.workerId"
                                      }
                                    },
                                    _vm._l(_vm.userList, function(user, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: user.name,
                                          value: user.userid
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false"
                                    }
                                  ],
                                  attrs: {
                                    label: "작업완료 예정일",
                                    prop: "completePlanDate"
                                  }
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "date",
                                      placeholder: "완료일을 지정 할 경우 선택"
                                    },
                                    model: {
                                      value: _vm.formData.completePlanDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData,
                                          "completePlanDate",
                                          $$v
                                        )
                                      },
                                      expression: "formData.completePlanDate"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: false,
                                      expression: "false"
                                    }
                                  ],
                                  attrs: { label: "종료일", prop: "endDate" }
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "date",
                                      placeholder: "종료일을 수정 할 경우 선택"
                                    },
                                    model: {
                                      value: _vm.formData.endDate,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formData, "endDate", $$v)
                                      },
                                      expression: "formData.endDate"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ],
          1
        ),
        _c("div", { staticClass: "dialog-footer" }, [
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("저장")]
              ),
              _c("el-button", { on: { click: _vm.hideDialog } }, [
                _vm._v("취소")
              ])
            ],
            1
          )
        ]),
        _c(
          "el-dialog",
          {
            attrs: { "append-to-body": "", visible: _vm.imagePreviewDialog },
            on: {
              "update:visible": function($event) {
                _vm.imagePreviewDialog = $event
              }
            }
          },
          [
            _c("img", {
              attrs: { width: "100%", src: _vm.imagePreviewUrl, alt: "" }
            })
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }