<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    top="5vh"
    class="el-dialog-aside"
    append-to-body
    fullscreen
  >
    <div>
      <div class="col-6 flex-center">
        <el-date-picker :editable="false" v-model="searchStartDate" type="date" placeholder="시작 날짜" style="margin-right: 10px; width: 45%;">
        </el-date-picker>

        <el-date-picker :editable="false" v-model="searchEndDate" type="date" placeholder="종료 날짜" style="width: 45%;" @change="onChangeEndDate" />
      </div>
      <div class="date-search">
        <button @click="onClickSearchBtn">기간 조회하기</button>
      </div>
    </div>
    <!-- <div class="mb-both-mg disaster-msg">
      <span class="disaster-text"> <span class="disaster-time">13:22</span>울산머시기 저시기 발생</span>
    </div> -->
    <template v-for="dateData in disasterDatas">
      <div class="disaster-log-date">{{ dateData.label }}</div>
      <div class="mb-both-mg disaster-msg" v-for="hourData in dateData.datas" :key="hourData.key">
        <span class="disaster-text">
          <span class="disaster-time">{{ hourData.label }}</span
          >{{ hourData.text }}</span
        >
      </div>
    </template>
  </el-dialog>
</template>

<script>
import moment from 'moment';

export default {
  components: {},
  data() {
    return {
      searchStartDate: undefined,
      searchEndDate: undefined,
      formDialog: false,
      formTitle: '재난 로그',
      disasterDatas: [],
    };
  },
  created() {
    this.initSearchDate();
  },
  destroyed() {
    this.closeModal();
  },
  mounted() {},
  computed: {},
  methods: {
    showDialogOnGis() {
      this.getDisaster();
      this.formDialog = true;
    },

    hideDialog() {
      this.resetData();
      this.closeModal();
      this.formDialog = false;
    },

    closeModal() {
      const pickerList = document.getElementsByClassName('el-picker-panel') || [];
      for (let i = 0; i < pickerList.length; i++) {
        pickerList[i].style.display = 'none';
      }
    },

    isOpenDialog() {
      return this.formDialog;
    },

    resetData() {
      this.files = [];
    },

    pickerOptions: {
      disabledDate(time) {
        return time.getTime() > Date.now();
      },
      shortcuts: [
        {
          text: 'Today',
          onClick(picker) {
            picker.$emit('pick', new Date());
          },
        },
        {
          text: 'Yesterday',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          },
        },
        {
          text: 'A week ago',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          },
        },
      ],
    },

    initSearchDate() {
      const mn = moment();

      const sDate = moment(mn.format('YYYYMMDD000000000'), 'YYYYMMDDHHmmssSSS').toDate();
      sDate.setDate(sDate.getDate() - 30);
      this.searchStartDate = sDate;

      const eDate = moment(mn.format('YYYYMMDD235959999'), 'YYYYMMDDHHmmssSSS').toDate();
      this.searchEndDate = eDate;
    },

    async requestEvent(args) {
      try {
        const { search = {}, sort, limit } = args;

        const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/ulsanBukgu/api/event`, { search, sort, limit });
        if (!response || response.status !== 200) {
          throw new Error();
        }
        return response.data;
      } catch (err) {
        // this.$message({
        //   type: 'error',
        //   message: 'requestEvent faile',
        // });
        return [];
      }
    },

    async getDisaster() {
      const me = this;
      const { searchStartDate, searchEndDate } = me;
      const search = {
        eventTypeId: 'weatherSpecialReport',
        // startDate: { $lte: searchEndDate },
        // endDate: { $gte: searchStartDate },
        $or: [
          {
            eventStat: '1',
            startDate: { $lte: searchEndDate },
          },
          {
            eventStat: '99',
            startDate: { $lte: searchEndDate },
            endDate: { $gte: searchStartDate },
          },
        ],
      };

      const weatherList = await me.requestEvent({
        search,
        sort: { collectDateTime: -1 },
      });

      const disasterDatas = [];

      weatherList.forEach((weatherInfo) => {
        const { startDate, endDate, eventStat, eventData } = weatherInfo;

        const startDt = moment(startDate);
        const startYyyymmdd = startDt.format('YYYYMMDD');
        const startHhmm = startDt.format('HHmm');
        let startReport = `${eventData.areaName} ${eventData.warnVar}${eventData.warnStress} 발령`;

        me.setDisasterDatas(disasterDatas, startYyyymmdd, startHhmm, startReport, 1);

        if (eventStat === '99' && endDate) {
          const endDt = moment(endDate);
          const endYyyymmdd = endDt.format('YYYYMMDD');
          const endHhmm = endDt.format('HHmm');

          let endReport = `${eventData.areaName} ${eventData.warnVar}${eventData.warnStress} 해제`;
          me.setDisasterDatas(disasterDatas, endYyyymmdd, endHhmm, endReport, 2);
        }
      });

      me.disasterDatas = me.sortDisasterDatas(disasterDatas);
    },

    onClickSearchBtn() {
      this.getDisaster();
    },

    setDisasterDatas(disasterDatas, yyyymmdd, hhmm, text, flag) {
      console.log(`hhmm: ${hhmm}`);
      if (!disasterDatas) {
        disasterDatas = [];
      }

      let dateData = disasterDatas.find((elm) => elm.key === yyyymmdd);
      if (!dateData) {
        dateData = {
          key: yyyymmdd,
          label: moment(yyyymmdd, 'YYYYMMDD').format('YYYY.MM.DD'),
          datas: [],
        };

        disasterDatas.push(dateData);
      }

      dateData.datas.push({
        key: hhmm,
        label: moment(hhmm, 'HHmm').format('HH:mm'),
        flag,
        text,
      });
    },

    sortDisasterDatas(disasterDatas) {
      let temp1 = disasterDatas.sort((f, b) => {
        return b.key - f.key;
      });

      return temp1.map((dateData) => {
        const temp2 = dateData.datas.sort((f, b) => {
          const r = b.key - f.key;
          if (r === 0) {
            return b.flag - f.flag;
          }
          return r;
        });
        return {
          ...dateData,
          datas: temp2,
        };
      });
    },

    onChangeEndDate(endDate) {
      const mn = moment(endDate);
      const searchEndDate = moment(mn.format('YYYYMMDD235959999'), 'YYYYMMDDHHmmssSSS').toDate();
      this.searchEndDate = searchEndDate;
    },
  },
};
</script>

<style scoped></style>
