<template>
  <div class="fill-width-height">
    <el-row class="page-body content-wrap">
      <el-col :span="24" class="page-subject">
        <h3>{{ this.$route.name }}</h3>
      </el-col>
      <el-col :span="24" class="grid-header-menu">
        <el-button @click="addFormDialog" type="primary" size="medium">
          <i class="fas fa-plus"></i>&nbsp;새 데이터셋 추가
        </el-button>
      </el-col>
      <el-col :span="24" class="page-content">
        <el-table
          :data="datasets"
          empty-text="등록된 데이터셋 정보가 없습니다."
          size="mini"
        >
          <el-table-column
            label="데이터 명칭"
            prop="label"
            width="220"
          ></el-table-column>
          <el-table-column
            label="데이터 KEY"
            prop="key"
            width="200"
          ></el-table-column>
          <el-table-column label="데이터 유형" prop="formType">
            <template slot-scope="scope">
              <span v-if="scope.row.formType == 'string'">텍스트</span>
              <span v-else-if="scope.row.formType == 'number'">숫자</span>
            </template>
          </el-table-column>
          <el-table-column width="250">
            <template slot-scope="scope">
              <el-button size="mini" @click="updateFormDialog(scope.row)"
                >수정</el-button
              >
              <el-button size="mini" type="danger" @click="deleteRow(scope.row)"
                >삭제</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <form-dataset
      @procDataComplete="procDataComplete"
      ref="formDialog"
    ></form-dataset>
  </div>
</template>

<script>
import formDataset from './forms/formDataset';
export default {
  components: {
    'form-dataset': formDataset,
  },
  created() {
    this.getDataset();
  },
  data: () => ({
    systemLogInfo : {},
    datasets: [],
    tempDatasets: {},
  }),
  methods: {
    async getDataset() {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/dataset`, {});
      this.datasets = response.data;
    },
    procDataComplete(formData) {
      this.getDataset();
    },
    addFormDialog() {
      this.$refs.formDialog.showDialog();
    },
    updateFormDialog(row) {
      this.$refs.formDialog.showDialog(row);
    },
    // editRow(index) {
    //     if (!this.checkEditable()) {
    //         return false;
    //     }
    //     this.$confirm(`${this.datasets[index].label} 데이터셋을 수정 합니다.`, '확인', {
    //         confirmButtonText: '확인',
    //         cancelButtonText: '취소',
    //         type: 'info'
    //     })
    //     .then(() => {
    //         this.tempDatasets = this.$lodash.cloneDeep(this.datasets[index]);
    //         this.datasets[index].editable = true;
    //     })
    //     .catch(() => {
    //     });
    // },
    // saveRow(index) {
    //     let saveMessage = "추가";
    //     let methodName = "dataset/addDataset";
    //     if (this.datasets[index]._id) {
    //         saveMessage = "수정";
    //         methodName = "dataset/updateDataset";
    //     }
    //     if (this.datasets[index].label == "") {
    //         this.$message({
    //             showClose: true,
    //             message: '데이터 라벨값을 입력해주세요',
    //             type: 'error'
    //         });
    //         return false;
    //     }
    //     if (this.datasets[index].key == "") {
    //         this.$message({
    //             showClose: true,
    //             message: '데이터 키값을 입력해주세요',
    //             type: 'error'
    //         });
    //         return false;
    //     }

    //     this.$confirm(`${this.datasets[index].label} 데이터셋을 ${saveMessage} 합니다.`, '확인', {
    //         confirmButtonText: '확인',
    //         cancelButtonText: '취소',
    //         type: 'info'
    //     })
    //     .then(() => {
    //          this.$store.dispatch(methodName, {data:this.datasets[index]})
    //         .then((response) => {
    //               this.$message({
    //                 type: 'success',
    //                 message: `${this.datasets[index].label} 데이터셋 ${saveMessage} 완료`
    //             });
    //             this.datasets[index].editable = false;
    //          })
    //         .catch(() => {
    //             this.$message({
    //                 type: 'info',
    //                 message: `${this.datasets[index].label} 데이터셋 ${saveMessage} 중 에러 발생`
    //             });
    //             this.datasets[index].editable = false;
    //         });
    //     })
    //     .catch(() => {
    //     });
    // },
    deleteRow(args) {
      this.$confirm(`${args.label} 데이터셋을 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            let response = await this.$http.post(
              `${this.$config.getServerConfig().core.api}/core/api/dataset/delete`,
              { data: args }
            );
            this.$message({
              type: 'success',
              message: `${args.label} 데이터셋 삭제 완료`,
            });
            this.systemLogInfo.page = `${this.$route.name} ${args.label}`
            this.systemLogInfo.saveType = "delete"
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
            this.getDataset();
          } catch (error) {
            this.$message({
              type: 'info',
              message: `${args.label} 데이터셋 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${args.label} 데이터셋 삭제 취소`,
          });
        });
    },
    showAddPanel() {
      if (!this.checkEditable()) {
        return false;
      }
      let newData = {
        editable: true,
        label: '',
        key: '',
        formType: 'string',
      };
      this.datasets.push(newData);
    },
    checkEditable() {
      let filter = this.datasets.find((data) => {
        return data.editable == true;
      });
      if (!filter) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
