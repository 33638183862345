<template>
  <el-dialog :visible.sync="formDialog" :title="formTitle" @close="hideDialog" :close-on-click-modal="false" id="formFcltType">
    <!-- 시설물 유형 관리 dialog -->
    <div>
      <el-steps :active="step" finish-status="success" align-center style="margin: 5px 0 10px 0;">
        <el-step v-for="(s, index) in stepData" :key="index" :title="s.title"></el-step>
      </el-steps>
      <div v-show="step === 0" class="fclttype-form-wrap" style="padding-top: 30px;">
        <el-form :model="fcltTypeDoc" label-width="160px" size="medium" :rules="rules" ref="form0" :label-position="$isMobile ? 'top' : 'right'">
          <el-row>
            <el-col :span="16">
              <el-form-item label="시설물 유형 명칭" prop="fcltTypeName">
                <el-input v-model="fcltTypeDoc.fcltTypeName" placeholder="시설물 유형 명칭"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="GIS 표출 아이콘">
                <span v-if="!fcltTypeDoc.gisMarkerImage">선택된 마커가 없습니다</span>
                <img v-else :src="fcltTypeDoc.gisMarkerImage.normal" style="height: 70px; margin-left: 15px; width: auto;" />
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-button @click="gisMarkerDialog = !gisMarkerDialog">아이콘 선택</el-button>
            </el-col>
            <el-col :span="24">
              <el-form-item label="GIS 초기 표출여부">
                <el-checkbox v-model="fcltTypeDoc.initDisplay"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div></div>
      </div>
      <div v-show="step === 1">
        <el-transfer v-model="tempFcltTypeData" :data="datasets" :titles="['데이터셋', '시설물 유형 데이터']"></el-transfer>
        <el-form label-width="160px" size="medium" ref="form1">
          <el-form-item label="검색 가능 데이터셋 설정">
            <el-checkbox-group v-model="searchDataset">
              <el-checkbox v-for="(data, index) in tempFcltTypeData" :label="data" name="type" :key="index">{{
                getDataLabel(data)
              }}</el-checkbox> </el-checkbox-group
            >※불필요한 데이터의 검색 설정시, 검색속도가 저하될 수 있습니다.
          </el-form-item>
        </el-form>
      </div>
      <div class="form-bottom">
        <el-button @click="prev" style="float: left;" v-show="step > 0"> <i class="fas fa-angle-left"></i>&nbsp;&nbsp;이전 </el-button>
        <el-button @click="next" style="float: right;" v-show="step < stepData.length - 1">
          다음&nbsp;&nbsp;
          <i class="fas fa-angle-right"></i>
        </el-button>
        <el-button @click="save" style="float: right;" v-show="step === stepData.length - 1">
          저장&nbsp;&nbsp;
          <i class="fas fa-angle-right"></i>
        </el-button>
      </div>
    </div>
    <el-dialog width="40%" :visible.sync="gisMarkerDialog" append-to-body title="시설물 아이콘 선택" class="el-dialog-inner">
      <el-row>
        <el-col :span="4" v-for="(fcltIcon, index) in fcltIcons" :key="index">
          <span class="fclt-icon-grid-cell" @click="selectGisMarker(fcltIcon)">
            <el-popover placement="top" trigger="hover" ref="fcltIconPopover">
              <div>
                <table class="el-table el-table--mini fclt-icon-preview">
                  <tr>
                    <td>
                      <img :src="fcltIcon.gisMarkerImage.normal" />
                    </td>
                    <td>
                      <img :src="fcltIcon.gisMarkerImage.selected" />
                    </td>
                    <td>
                      <img :src="fcltIcon.gisMarkerImage.break" />
                    </td>
                    <td>
                      <img :src="fcltIcon.gisMarkerImage.repair" />
                    </td>
                  </tr>
                  <tr>
                    <th>일반</th>
                    <th>선택시</th>
                    <th>고장/사고</th>
                    <th>유지보수</th>
                  </tr>
                </table>
              </div>
              <img class="fclt-icon-grid" slot="reference" :src="fcltIcon.gisMarkerImage.normal" />
            </el-popover>
          </span>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 시설물 관리 dialog 끝 -->
  </el-dialog>
</template>

<script>
import dkdynamicform from '@/components/dkDynamicForm.vue';

export default {
  components: {
    'dk-dynamic-form': dkdynamicform,
  },
  created() {
    this.resetData();
    this.activeUser = this.$dkRouter.getUserInfo();
    this.getMenus();
    this.getDataSet();
    this.getFcltIcon();
  },
  data() {
    return {
      systemLogInfo: {},
      formDialog: false,
      formTitle: '시설물 유형 추가',
      updateFlag: false,
      activeUser: null,
      datasets: [],
      fcltIcons: [],
      groupList: [],
      step: 0,
      stepData: [
        { idx: 0, title: '시설물 유형 정보' },
        { idx: 1, title: '시설물 유형 데이터셋 설정' },
      ],
      fcltTypeDoc: {
        fcltTypeName: '',
        fcltTypeData: [],
        gisMarkerImage: null,
      },
      tempFcltTypeData: [],
      searchDataset: [],
      tempSearchDataset: [],
      rules: {
        fcltTypeName: [
          {
            required: true,
            message: '시설물 유형 명칭을 입력해 주세요',
            trigger: 'blur',
          },
          { min: 3, message: '3자 이상 입력해주세요', trigger: 'blur' },
        ],
      },
      gisMarkerDialog: false,
    };
  },
  props: {},
  updated() {},
  methods: {
    async getFcltIcon() {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltIcon`, {});
      this.fcltIcons = response.data;
    },
    async getDataSet() {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/dataset`, {});
      this.datasets = response.data;
    },
    async getMenus() {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/menus`, {
        params: this.activeUser,
      });
      this.groupList = response.data;
    },
    next() {
      if (this.$refs[`form${this.step}`] && this.$refs[`form${this.step}`].model) {
        this.$refs[`form${this.step}`].validate((valid) => {
          if (valid) {
            this.step++;
          } else {
            return false;
          }
        });
      } else {
        this.step++;
      }
    },
    prev() {
      if (this.step == 1) {
      }
      this.step--;
    },
    async save() {
      let me = this;
      let flag = false;

      let saveFunc = 'fcltType/add';
      if (this.updateFlag) {
        saveFunc = 'fcltType/updateOld';
      }
      this.systemLogInfo.page = `${this.$route.name} ${this.fcltTypeDoc.fcltTypeName}`;
      let tempData = {};
      if (this.updateFlag) {
        for (var key in this.originalData.fcltTypeData) {
          if (this.originalData.fcltTypeData[key].noTransfer) {
            tempData[key] = this.originalData.fcltTypeData[key];
          }
        }
      }
      this.tempFcltTypeData.forEach((val) => {
        let data = this.datasets.find((dataset) => {
          return val == dataset.key;
        });
        tempData[val] = data;
      });

      this.fcltTypeDoc.fcltTypeData = tempData;
      let check = this.$lodash.isEqual(this.searchDataset, this.tempSearchDataset);
      try {
        if (!check) {
          let confirm = await this.$confirm(`검색 키워드가 변경 되었습니다. 검색 인덱스를 새로 정렬하는 작업을 진행합니다.`, '알림', {
            confirmButtonText: '확인',
            cancelButtonText: '취소',
            type: 'info',
          });
        }
        this.searchDataset.forEach((searchKey) => {
          if (this.fcltTypeDoc.fcltTypeData[searchKey]) {
            this.fcltTypeDoc.fcltTypeData[searchKey].searchable = true;
          }
        });
        let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, {
          data: this.fcltTypeDoc,
        });
        me.$parent.$emit('procDataComplete', this.fcltTypeDoc);
        me.hideDialog();
        me.$message({
          type: 'success',
          message: `${me.fcltTypeDoc.fcltTypeName} 시설물 유형 저장 완료`,
        });
        this.systemLogInfo.saveType = saveFunc;
        await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
      } catch (err) {
        if (err === 'cancel') {
        } else {
          me.$message({
            type: 'warning',
            message: `${me.fcltTypeDoc.fcltTypeName} 시설물 유형 저장 중 에러 발생`,
          });
        }
      }
    },

    // setFcltTypeDataDoc(data) {
    //     let me = this;
    //     this.fcltTypeDoc = this.$lodash.cloneDeep(data);
    //     this.fcltTypeDoc.setDate = new Date(this.fcltTypeDoc.setDate);
    //     if (this.fcltTypeDoc.files && typeof this.fcltTypeDoc.files === "object") {
    //         this.fcltTypeDoc.files.forEach((file) => {
    //             file.url = `${file.serverFilePath}`;
    //         });
    //     }
    // },
    resetData() {
      this.step = 0;
      this.fcltTypeDoc = {
        fcltTypeName: '',
        fcltTypeData: [],
        gisMarkerImage: null,
      };
      this.tempFcltTypeData = [];
      this.searchDataset = [];
      this.tempSearchDataset = [];
      if (this.$refs.form0) {
        this.$refs.form0.resetFields();
      }
    },
    async showDialog(row) {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/dataset`, {});
      this.datasets = response.data;
      if (row) {
        this.updateFlag = true;
        this.formTitle = '시설물 유형 수정';
        this.originalData = this.$lodash.cloneDeep(row);
        let tempData = this.$lodash.cloneDeep(row);
        for (var val in tempData.fcltTypeData) {
          if (tempData.fcltTypeData[val].noTransfer) {
            continue;
          }
          if (this.getDataLabel(val) === '') {
            continue;
          }
          this.tempFcltTypeData.push(val);
          if (tempData.fcltTypeData[val].searchable) {
            this.searchDataset.push(val);
            this.tempSearchDataset.push(val);
          }
        }
        tempData.fcltTypeData = [];
        this.fcltTypeDoc = tempData;
      } else {
        this.updateFlag = false;
        this.formTitle = '시설물 유형 추가';
      }
      this.formDialog = true;
      this.$forceUpdate();
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    selectGisMarker(fcltIcon) {
      this.gisMarkerDialog = false;
      this.fcltTypeDoc.gisMarkerHeight = fcltIcon.gisMarkerHeight;
      this.fcltTypeDoc.gisMarkerImage = fcltIcon.gisMarkerImage;
      this.fcltTypeDoc.gisMarkerOffsetX = fcltIcon.gisMarkerOffsetX;
      this.fcltTypeDoc.gisMarkerOffsetY = fcltIcon.gisMarkerOffsetY;
      this.fcltTypeDoc.gisMarkerWidth = fcltIcon.gisMarkerWidth;
    },
    getDataLabel(key) {
      let data = this.datasets.find((dataset) => {
        return key == dataset.key;
      });
      if (data) return data.label;
      else return '';
    },
  },
};
</script>
<style scoped>
.fclt-icon-grid-cell {
  display: inline-block;
  height: 80px;
  padding: 3px 0;
  text-align: center;
  width: 100%;
}

.fclt-icon-grid-cell:hover {
  background-color: rgba(192, 192, 192, 0.3);
}

.fclt-icon-grid {
  display: inline-block;
  height: 68px;
  width: auto;
}

.fclt-icon-preview td,
.fclt-icon-preview th {
  text-align: center;
  width: 60px;
}

.fclt-icon-preview td > img {
  display: inline-block;
  height: 42px;
  width: auto;
}

.fclttype-form-wrap {
  box-sizing: border-box;
  min-height: 300px;
  padding-top: 20px;
}
</style>
