<template>
  <div class="page-set">
    <div :class="'side-menu-container ' + (isNaviSelect ? 'd-block' : 'd-none')" @click="backgroundOff">
      <div class="navi-wrap d-flex">
        <div class="btn-style" @click="kakaoNavi"><img src="../assets/img/kakaonavi.png" /><span>카카오내비</span></div>
        <div class="btn-style" @click="tmapNavi"><img src="../assets/img/tmap.png" /><span>TMap</span></div>
      </div>
    </div>
    <div class="top-bar-space" style="bottom: 0; overflow: hidden;">
      <ul class="disease-type-container">
        <li
          class=""
          v-for="menuInfo in menuList"
          :key="menuInfo.key"
          @click="
            (e) => {
              onClickMenu(e, menuInfo);
            }
          "
        >
          <span>
            {{ menuInfo.name }}
          </span>
        </li>
      </ul>
      <div class="func-item-container-disease flex-box">
        <div class="distance-legend">
          <div><span>500m</span><span class="color-circle" style="background-color: #eb6637;"></span></div>
          <div><span>3km</span><span class="color-circle" style="background-color: #f0d473;"></span></div>
          <div><span>10km</span><span class="color-circle" style="background-color: #9cc593;"></span></div>
        </div>
        <div>
          <div :class="'func-btn-box ' + (coverageFlag ? 'select-func' : '')" @click="onoffCoverage">
            <i class="icon-coverage2"></i>
          </div>
          <div class="func-btn-box" @click="changeViewMarker">
            <img :src="rangeImg[markerViewFlag]" />
          </div>
        </div>
      </div>
      <div class="fclt-menu-item-container" id="detailInfo" v-show="!!selectDisease.eventId || !!selectFclt.fcltId" ref="detail">
        <span class="fclt-info-open" @click="infoOpen">
          <i :class="'arrow-click icon-arrow' + (isArrowReverse ? ' arrow-reverse' : '')"></i>
          상세 보기
        </span>
        <div>
          <div class="mobile-fclt-label" @click="infoOpen">
            <p v-if="selectDisease.eventId" class="fclt-name">{{ selectDisease.farmName }}</p>
            <p v-else-if="selectFclt.fcltId" class="fclt-name">{{ selectFclt.fcltName }}</p>
            <!-- <span class="fclt-type">울산북구CCTV</span> -->
          </div>
          <ul class="fclt-detail-view-menu">
            <!-- <li v-for="diseaseInfoContents in diseaseInfoList" :key="diseaseInfoContents.key" class="">
              <i :class="diseaseInfoContents.icon"></i>
              <div>{{ diseaseInfoContents.name }}</div>
            </li> -->
            <li @click="setRoadview">
              <i class="fas fa-map-marker-alt"></i>
              <div>거리뷰</div>
            </li>
            <li @click="selectNavigation">
              <i class="fas fa-wrench"></i>
              <div>네비게이션</div>
            </li>
          </ul>
        </div>
        <div class="">
          <div class="dk-card">
            <table class="el-table el-table--mini table-width" v-if="selectDisease.eventId">
              <tr>
                <th>장소</th>
                <td>{{ selectDisease.place }}</td>
              </tr>
              <tr v-if="selectDisease.diseaseName">
                <th>전염병명</th>
                <td>{{ selectDisease.diseaseName }}</td>
              </tr>
              <tr v-if="selectDisease.occurDate">
                <th>발생일시</th>
                <td>{{ selectDisease.occurDate }}</td>
              </tr>
              <tr>
                <th>지번주소</th>
                <td>{{ selectDisease.address }}</td>
              </tr>
              <tr>
                <th>가축종류</th>
                <td>{{ selectDisease.livestockType }}</td>
              </tr>
              <tr>
                <th>가축두수</th>
                <td>{{ selectDisease.count }}</td>
              </tr>
              <!-- <tr>
                <th>상태</th>
                <td>{{ selectDisease.state }}</td>
              </tr> -->
              <tr>
                <th>
                  주변 농장
                </th>
                <td class="farm-distance-list">
                  <div style="height: 190px; overflow: auto;">
                    <tr v-for="farmElm in selectDisease.farmList" :key="farmElm.fcltId" @click="onClickFarmDistance(farmElm)" class="flex-box">
                      <td>
                        {{ farmElm.fcltName }}
                      </td>
                      <td>{{ farmElm.distance }} m</td>
                    </tr>
                  </div>
                </td>
              </tr>
            </table>
            <table class="el-table el-table--mini table-width" v-if="selectFclt.fcltId">
              <tr>
                <th>장소</th>
                <td>{{ selectFclt.place }}</td>
              </tr>
              <tr>
                <th>지번주소</th>
                <td>{{ selectFclt.address }}</td>
              </tr>
              <tr>
                <th>가축종류</th>
                <!-- <td>{{ selectFclt.livestockType }}</td> -->
                <td>{{ selectFclt.livestockType }}</td>
              </tr>
              <tr>
                <th>가축두수</th>
                <td>{{ selectFclt.count }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <!-- <div class="fill-width-height temp-gis"></div> -->
      <div class="fill-width-height" ref="gisContainer">
        <!-- <dk-gis fill-height :gisApiInfo="gisApiInfo" :apiLoadComplete="apiLoadComplete" ref="dkGis" id="dkGis" /> -->
        <dk-gis
          fill-height
          ref="dkGis"
          id="dkGis"
          :gisApiInfo="gisApiInfo"
          :apiLoadComplete="apiLoadComplete"
          :clusterData="clusterData"
          @moveEnd="changedBounds"
          @zoomEnd="changedZoom"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dkgismain from '@/components/dkGis/dkGisMain.vue';
import moment from 'moment';
export default {
  components: {
    'dk-gis': dkgismain,
  },
  props: {
    checkBackAction: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    ulsanCode: '31',
    bukguCode: '31200',
    menuList: [
      {
        key: 'diseaseInfo',
        name: '가축질병',
        // path:'/diseaseInfo',
      },
      {
        key: 'covid19',
        name: '코로나',
        path: '/covid19',
      },
    ],
    diseaseInfoList: [
      {
        key: 'li1',
        name: '거리뷰',
        icon: 'fas fa-map-marker-alt',
      },
      {
        key: 'li2',
        name: '네비게이션',
        icon: 'fas fa-wrench',
      },
    ],
    markerImage: {
      돼지열병: {
        1: 'static/images/gis/icon/pig-r.png',
        99: 'static/images/gis/icon/pig-b.png',
        돼지: 'static/images/gis/icon/foot-pig.png',
        소: 'static/images/gis/icon/foot-cow.png',
        사슴: 'static/images/gis/icon/foot-dear.png',
        기타: 'static/images/gis/icon/foot-etc.png',
      },
      구제역: {
        1: 'static/images/gis/icon/hoof-r.png',
        99: 'static/images/gis/icon/hoof-b.png',
        돼지: 'static/images/gis/icon/pigdisease-pig.png',
        기타: 'static/images/gis/icon/pigdisease-pig.png',
      },
      '고병원성 조류인플루엔자': {
        1: 'static/images/gis/icon/chicken-r.png',
        99: 'static/images/gis/icon/chicken-b.png',
        닭: 'static/images/gis/icon/ai-chick.png',
        기타: 'static/images/gis/icon/ai-chick.png',
      },
    },
    markerSize: {
      // width: 48,
      // height: 70,
      width: 42,
      height: 63,
      // width: 36,
      // height: 54,
      // width:24,
      // height:35,
    },
    gisApiInfo: [],
    selectDisease: {
      eventId: '',
      farmName: '',
      place: '',
      diseaseName: '',
      occurDate: '',
      address: '',
      livestockType: '',
      count: '',
      // state: '',
      farmList: [
        {
          fcltId: '1',
          fcltName: '농장1',
          distance: 50,
        },
        {
          fcltId: '2',
          fcltName: '농장2',
          distance: 75,
        },
      ],
    },
    selectFclt: {
      fcltId: '',
      fcltName: '',
      place: '',
      address: '',
      livestockType: '',
      count: '',
    },
    isArrowReverse: false,
    markerLayer: undefined,
    fcltMarkerLayer: undefined,
    circleLayer: undefined,
    diseaseList: [],
    circleList: [],
    legaldongCount: {},
    clusterData: [],
    clusterDataList: [],

    coverageFlag: true,

    level: 8,

    lat: 35.6100624002618,
    lng: 129.37980151001,

    isNaviSelect: false,

    markerViewFlag: 2,

    markerViewFlagList: ['disease', 'farm', 'cluster'],

    fcltTypeId: 'ulsanbukguFarm',
    fcltTypeInfo: undefined,
    fcltList: [],
    // rangeImgList: [],
    rangeImg: ['static/images/disease-disease.png', 'static/images/disease-all.png', 'static/images/cluster.png'],
    messageInstance: undefined,
  }),
  created() {
    const me = this;

    if (me.$isMobile && !Kakao.isInitialized()) {
      Kakao.init(me.$config.getSiteConfig().gis.apiKey);
    }

    // this.backAction();

    (async () => {
      me.gisApiInfo = me.$store.getters['gis/getGisApi'];
      if (!me.gisApiInfo.length || me.gisApiInfo.length.length === 0) {
        await me.$store.dispatch('gis/actGisApi', {});
        me.gisApiInfo = me.$store.getters['gis/getGisApi'];
      }
      me.gisApiInfo[0].apiKey = me.$config.getSiteConfig().gis.apiKey;
    })();

    me.gisApiInfo[0].geometry.lat = me.lat;
    me.gisApiInfo[0].geometry.lng = me.lng;
  },
  mounted() {
    this.backAction();
    this.$refs.detail.addEventListener('touchstart', this.onTouchstart, false);
    this.$refs.detail.addEventListener('touchmove', this.onTouchmove, false);
    this.$refs.detail.addEventListener('touchend', this.onTouchEnd, false);
  },
  computed: {},
  methods: {
    backAction() {
      const me = this;
      window.backAction = () => {
        try {
          if (window.JSBridge && window.JSBridge.wvStatus) {
            window.JSBridge.wvStatus('using');
          }
          if (me.checkBackAction()) {
            return;
          }
          // 네비게이션 선택창 닫기
          else if (this.isNaviSelect) {
            this.backgroundOff();
          } else if (me.selectDisease.eventId) {
            me.selectClear();
          } else {
            me.$router.go(-1);
          }
        } catch (err) {
          this.$message('뒤로가기 에러');
        }
      };
    },

    apiLoadComplete(currentGisApi) {
      this.$refs.dkGis.setLevel(this.level);

      const markerLayer = this.$refs.dkGis.createMarkerLayer();
      this.markerLayer = markerLayer;

      const fcltMarkerLayer = this.$refs.dkGis.createMarkerLayer();
      this.fcltMarkerLayer = fcltMarkerLayer;

      const circleLayer = this.$refs.dkGis.createCircleLayer();
      this.circleLayer = circleLayer;

      this.setBasicMapClickEvent();

      (async () => {
        await Promise.all([this.getGisDatas(), this.getFcltDatas()]);

        this.getClusterData();
      })();
    },

    // sleep(time) {
    //   return new Promise((resolve, reject) => {
    //     setTimeout(resolve, time);
    //   });
    // },

    // async checkRoopFunc() {
    //   const me = this;
    //   while (true) {
    //     try {
    //       me.$refs.dkGis.getLevel();
    //       break;
    //     } catch (err) {
    //       await me.sleep(50);
    //     }
    //   }
    // },

    onClickMenu(e, menuInfo) {
      if (menuInfo && menuInfo.path) {
        this.$router.push(menuInfo.path);
      } else {
        // this.$alert(menuInfo.name);
      }
    },
    infoOpen(e) {
      if (this.isArrowReverse === false) {
        this.$refs.detail.style = `top: calc( 100% - ${this.$refs.detail.scrollHeight}px ) !important`;
        this.isArrowReverse = true;
      } else {
        this.$refs.detail.style = `top: calc( 100% - 114px ) !important`;
        this.isArrowReverse = false;
      }
    },
    onTouchstart(e) {
      this.startY = e.touches[0].clientY;
      this.moveY = 0;
    },
    onTouchmove(e) {
      this.moveY = e.touches[0].clientY - this.startY;
    },

    onTouchEnd(e) {
      if (this.moveY > 30) {
        // 터치 다운무브
        this.$refs.detail.style = `top: calc( 100% - 114px ) !important`;
        this.isArrowReverse = false;
      } else if (this.moveY < -30) {
        // 터치 업무브
        this.$refs.detail.style = `top: calc( 100% - ${this.$refs.detail.scrollHeight}px ) !important`;
        this.isArrowReverse = true;
      }
      this.moveY = 0;
    },

    changedBounds() {
      // this.getGisDatas();
    },

    async requestLegaldongData(args) {
      try {
        const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/address`, { search: args });

        if (!response || response.status !== 200) {
          throw new Error();
        }

        return response.data;
      } catch (err) {
        // this.$message({
        //   type: 'error',
        //   message: 'requestEvent faile',
        // });

        return [];
      }
    },

    async getClusterData() {
      let clusterDataList = [];
      clusterDataList = await this.getLegaldongData();
      this.clusterDataList = clusterDataList;
      if (this.markerViewFlag === 2) {
        this.viewClusterMarker();
      }
    },

    async getGisDatas() {
      await this.getMarkerData();
      this.setCircleList();
    },

    async getMarkerData() {
      let diseaseList = [];

      this.legaldongCount = {};
      diseaseList = await this.getLivestockDisease();
      this.diseaseList = diseaseList;

      // let clusterDataList = [];
      // clusterDataList = await this.getLegaldongData();
      // this.clusterDataList = clusterDataList;
      // if (this.markerViewFlag === 2) {
      //   const level = this.$refs.dkGis.getLevel();
      //   this.viewClusterMarker(level);
      // }
    },

    async viewClusterMarker(level) {
      if (!Array.isArray(this.clusterDataList) || this.clusterDataList.length === 0) {
        return;
      }

      if (typeof level === 'undefined') {
        level = this.$refs.dkGis.getLevel();
      }

      if (level < 9) {
        this.clusterData = [...this.clusterDataList[2]];
      } else if (level > 11) {
        this.clusterData = [...this.clusterDataList[0]];
      } else {
        this.clusterData = [...this.clusterDataList[1]];
      }
    },

    changedZoom() {
      if (this.markerViewFlag === 2) {
        this.convertClusterMarkerType();
      }
    },

    convertClusterMarkerType() {
      const level = this.$refs.dkGis.getLevel();
      this.viewClusterMarker(level);
    },

    changeViewMarker() {
      this.mapClickEvent();
      if (this.markerViewFlag === this.markerViewFlagList.length - 1) {
        this.markerViewFlag = 0;
      } else {
        this.markerViewFlag += 1;
      }
    },

    async getStateData() {
      const me = this;
      const legaldongList = await this.requestLegaldongData({ addrCd: this.ulsanCode });

      const result = [];
      legaldongList.forEach((legaldong) => {
        const { gisLat, gisLng, addrCd, engName, korName, addr } = legaldong;

        const ck = result.findIndex((elm) => elm.properties.clusterCd === addrCd);
        if (ck > -1) return;

        const geometry = {
          lat: gisLat,
          lng: gisLng,
        };

        const cnt = me.legaldongCount[addrCd] || { disease: 0, fclt: 0 };

        const properties = {
          clusterCd: addrCd,
          count: `${cnt.disease}/${cnt.fclt}`,
          name: korName,
        };

        // status = 1;

        result.push({
          key: 'cluster_' + addrCd,
          geometry,
          properties,
          status: 1,
        });
      });

      return result;
    },

    async getSigunguData() {
      const me = this;
      const legaldongList = await this.requestLegaldongData({ addrCd: this.bukguCode });

      const result = [];
      legaldongList.forEach((legaldong) => {
        const { gisLat, gisLng, addrCd, engName, korName, addr } = legaldong;

        const ck = result.findIndex((elm) => elm.properties.clusterCd === addrCd);
        if (ck > -1) return;

        const geometry = {
          lat: gisLat,
          lng: gisLng,
        };

        const cnt = me.legaldongCount[addrCd] || { disease: 0, fclt: 0 };

        const properties = {
          clusterCd: addrCd,
          count: `${cnt.disease}/${cnt.fclt}`,
          name: korName,
        };

        // status = 1;

        result.push({
          key: 'cluster_' + addrCd,
          geometry,
          properties,
          status: 1,
        });
      });

      return result;
    },

    async getEmdData() {
      const me = this;
      const legaldongList = await this.requestLegaldongData({ addrCd: { $regex: `.*^${this.bukguCode}.*` } });

      const result = [];
      legaldongList.forEach((legaldong) => {
        const { gisLat, gisLng, addrCd, engName, korName, addr } = legaldong;

        const ck = result.findIndex((elm) => elm.properties.clusterCd === addrCd);
        if (ck > -1) return;
        else if (addrCd.length === 5) return;

        const geometry = {
          lat: gisLat,
          lng: gisLng,
        };

        const cnt = me.legaldongCount[addrCd] || { disease: 0, fclt: 0 };

        const properties = {
          clusterCd: addrCd,
          count: `${cnt.disease}/${cnt.fclt}`,
          name: korName,
        };

        // status = 1;

        result.push({
          key: 'cluster_' + addrCd,
          geometry,
          properties,
          status: 1,
        });
      });

      return result;
    },

    async getLegaldongData(level) {
      const me = this;

      const clusterDataList = await Promise.all([me.getStateData(), me.getSigunguData(), me.getEmdData()]);

      return clusterDataList;
    },

    async requestEvent(args = {}) {
      try {
        const { search = {}, sort, limit } = args;

        const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/ulsanBukgu/api/event`, { search, sort, limit });

        if (!response || response.status !== 200) {
          throw new Error();
        }

        return response.data;
      } catch (err) {
        // this.$message({
        //   type: 'error',
        //   message: 'requestEvent faile',
        // });

        return [];
      }
    },

    async requestInsideBoundLegaldong() {
      try {
        const bounds = this.$refs.dkGis.getBounds();
        const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/gis/getInsideBoundLegaldong`, { bounds });

        if (!response || response.status !== 200) {
          throw new Error();
        }

        return response.data.state || [];
      } catch (err) {
        // this.$message({
        //   type: 'error',
        //   message: 'requestInsideBoundLegaldong faile',
        // });
        return [];
      }
    },

    async requestFcltType() {
      const me = this;

      try {
        const response = await me.$http.get('coreapi/core/api/fcltType', {
          params: {
            fcltTypeId: me.fcltTypeId,
          },
        });

        if (!response || response.status !== 200) {
          throw new Error();
        }

        return response.data.docs;
      } catch (err) {
        // this.$message.error('requestFcltType faile');
        return [];
      }
    },

    async requestFclt() {
      const me = this;

      try {
        if (!me.fcltTypeInfo) {
          this.$message.error('농장 권한이 없습니다');
          return [];
        }

        const response = await me.$http.post('coreapi/core/api/fclt', {
          search: {
            fcltTypeId: me.fcltTypeId,
          },
        });

        if (!response || response.status !== 200) {
          throw new Error();
        }

        return response.data;
      } catch (err) {
        // this.$message.error('requestFcltType faile');
        return [];
      }
    },

    async getFcltDatas() {
      const me = this;
      try {
        const visible = me.markerViewFlag === 1;

        const fcltTypeInfo = await this.requestFcltType();
        if (Array.isArray(fcltTypeInfo) && fcltTypeInfo.length > 0) {
          this.fcltTypeInfo = fcltTypeInfo[0];
        } else {
          this.fcltTypeInfo = undefined;
        }

        const fcltList = await this.requestFclt();
        if (Array.isArray(fcltList) && fcltList.length > 0) {
          let gisImg = me.fcltTypeInfo.gisMarkerImage.repair;
          this.fcltList = fcltList.map((fcltInfo) => {
            const { fcltId, gisLat, gisLng, stateInfo } = fcltInfo;

            if (fcltInfo.fcltTypeData && fcltInfo.fcltTypeData.lvstck_nm) {
              switch (fcltInfo.fcltTypeData.lvstck_nm.value) {
                case '소': {
                  gisImg = 'static/images/gis/icon/bukgu/icon_소3.svg';
                  break;
                }
                case '닭': {
                  gisImg = 'static/images/gis/icon/bukgu/icon_닭2.svg';
                  break;
                }
                case '돼지': {
                  gisImg = 'static/images/gis/icon/bukgu/icon_돼지2.svg';
                  break;
                }
                case '염소': {
                  gisImg = 'static/images/gis/icon/bukgu/icon_염소3.svg';
                  break;
                }
                case '사슴': {
                  gisImg = 'static/images/gis/icon/bukgu/icon_사슴2.svg';
                  break;
                }
                case '조류': {
                  gisImg = 'static/images/gis/icon/bukgu/icon_조류.svg';
                  break;
                }
              }
            }

            me.getLegaldongCount('fclt', {
              stateCode: stateInfo.stateCd,
              sigunguCode: stateInfo.sigCd,
              emdCode: stateInfo.emdCd,
            });

            const mouseEvent = {};
            mouseEvent.click = me.onClickFcltMarker;

            return {
              key: fcltId,
              lat: gisLat,
              lng: gisLng,
              markerImage: {
                src: gisImg,
                size: me.markerSize,
              },
              event: mouseEvent,
              visible,
              properties: fcltInfo,
            };
          });
        } else {
          this.fcltList = [];
        }
      } catch (err) {
        // this.$message.error('getFcltDatas faile');
      }
    },

    async getLivestockDisease() {
      const me = this;
      const visible = me.markerViewFlag === 0;

      // const stateList = await me.requestInsideBoundLegaldong();

      const dt = new Date();

      const search = {
        eventTypeId: 'livestockDisease',
        $and: [
          { $or: [{ 'eventData.LKNTS_NM': '돼지열병' }, { 'eventData.LKNTS_NM': '구제역' }, { 'eventData.LKNTS_NM': '고병원성 조류인플루엔자' }] },
        ],
      };

      // 기간 조건 추가
      search.startDate = { $gt: dt.setFullYear(dt.getFullYear() - 1) };

      // 지역 조건 추가
      search['eventData.legaldong.sigunguCode'] = me.bukguCode;

      const responseData = await me.requestEvent({ search });

      if (!Array.isArray(responseData) || responseData.length === 0) {
        return [];
      }

      const diseaseList = [];

      responseData.forEach((diseaseInfo) => {
        const { eventId, eventStat, eventData, gisLat, gisLng } = diseaseInfo;

        if (!gisLat || !gisLng) return;

        const { LKNTS_NM, legaldong, LVSTCKSPC_NM } = eventData;

        const mouseEvent = {};
        mouseEvent.click = me.onClickMarker;

        const markerImage = {};
        markerImage.size = me.markerSize;

        let livestockShape = '기타';
        if (typeof LVSTCKSPC_NM === 'string') {
          livestockShape = LVSTCKSPC_NM.split('-')[0];
        }
        const diseaseIconInfo = me.markerImage[LKNTS_NM];
        if (diseaseIconInfo) {
          let iconSrc = diseaseIconInfo[livestockShape];
          if (!iconSrc) {
            iconSrc = diseaseIconInfo['기타'];
          }

          markerImage.src = iconSrc;
        } else {
          return;
        }

        me.getLegaldongCount('disease', legaldong);

        diseaseList.push({
          key: eventId,
          lat: gisLat,
          lng: gisLng,
          markerImage,
          event: mouseEvent,
          visible,
          // infowindow,
          // circleList,
          properties: diseaseInfo,
        });
      });

      // me.diseaseList = diseaseList;
      return diseaseList;
    },
    getLegaldongCount(key, legaldong = {}) {
      const me = this;
      const func = (code) => {
        if (typeof code === 'undefined') return;
        else if (typeof me.legaldongCount[code] === 'undefined') {
          // me.legaldongCount[code] = 1;
          me.legaldongCount[code] = {
            disease: 0,
            fclt: 0,
          };
        }
        // } else {
        //   me.legaldongCount[code] += 1;
        // }
        me.legaldongCount[code][key] += 1;
      };

      func(legaldong.stateCode);
      func(legaldong.sigunguCode);
      func(legaldong.emdCode.substr(0, 8));
    },

    onClickMarker(properties, gis) {
      const { eventId, eventStat, startDate, endDate, eventData = {} } = properties;
      const { lat, lng } = gis;

      const selectDisease = {};
      selectDisease.eventId = eventId;
      selectDisease.farmName = eventData.FARM_NM;
      selectDisease.place = eventData.FARM_LOCPLC;
      selectDisease.diseaseName = eventData.LKNTS_NM;
      selectDisease.occurDate = moment(eventData.OCCRRNC_DE).format('YYYY-MM-DD');
      selectDisease.address = eventData.FARM_LOCPLC;
      selectDisease.livestockType = eventData.LVSTCKSPC_NM;
      selectDisease.count = eventData.OCCRRNC_LVSTCKCNT;
      selectDisease.gisLat = lat;
      selectDisease.gisLng = lng;

      // selectDisease.state = (eventStat==='1') ? '미종식' : '종식';

      this.selectDisease = selectDisease;

      this.$refs.dkGis.setCenter({ lat, lng });

      this.setDiseaseNearFarm();
    },

    onClickFcltMarker(properties, gis) {
      const { fcltId, fcltName, setNumberAddr, fcltTypeData = {} } = properties;
      const { lat, lng } = gis;

      let count = 0;
      if (fcltTypeData.brd_head_qy && typeof fcltTypeData.brd_head_qy.value !== 'undefined') {
        count = fcltTypeData.brd_head_qy.value;
      }
      let livestockType = '';
      if (fcltTypeData.lvstck_nm && typeof fcltTypeData.lvstck_nm.value !== 'undefined') {
        livestockType = fcltTypeData.lvstck_nm.value;
      }

      const selectFclt = {};
      selectFclt.fcltId = fcltId;
      selectFclt.fcltName = fcltName;
      selectFclt.place = setNumberAddr;
      selectFclt.address = setNumberAddr;
      selectFclt.livestockType = livestockType;
      selectFclt.count = count;
      selectFclt.gisLat = lat;
      selectFclt.gisLng = lng;
      selectFclt.farmList = [];

      this.selectFclt = selectFclt;

      this.$refs.dkGis.setCenter({ lat, lng });
    },

    selectClear() {
      this.selectDisease = {};
      this.selectFclt = {};
      this.$refs.detail.style = `top: calc( 100% - 114px ) !important`;
      this.isArrowReverse = false;
    },

    onoffCoverage() {
      this.coverageFlag = !this.coverageFlag;
    },

    setCircleList() {
      const me = this;
      const circleList = [];
      this.diseaseList.forEach((diseaseInfo) => {
        const circleInfos = this.makeCircleInfo(diseaseInfo.properties.eventData);

        const circleMakerData = {
          // zIndex: 100,
          lat: diseaseInfo.lat,
          lng: diseaseInfo.lng,
        };

        circleInfos.forEach((c) => {
          const { radius, strokeColor, fillColor } = c;
          circleList.push({
            key: diseaseInfo.key,
            properties: diseaseInfo.properties,
            circleInfo: {
              ...circleMakerData,
              radius,
              strokeColor,
              fillColor,
              visible: me.coverageFlag,
            },
          });
        });
      });

      this.circleList = circleList;
    },

    makeCircleInfo(args) {
      const {} = args;

      const radiusList = [
        {
          radius: 10000,
          // strokeWeight,
          strokeColor: '#00ff30',
          // strokeOpacity,
          // strokeStyle,
          fillColor: '#00ff30',
          // fillOpacity: 0.5,
          // zIndex,
        },
        {
          radius: 3000,
          // strokeWeight,
          strokeColor: '#ffc500',
          // strokeColor: '#4da546',
          // strokeOpacity,
          // strokeStyle,
          fillColor: '#ffc500',
          // fillColor: '#4da546',
          // fillOpacity: 0.5,
          // zIndex,
        },
        {
          radius: 500,
          // strokeWeight,
          strokeColor: '#ff0000',
          // strokeColor: '#ffc500',
          // strokeOpacity,
          // strokeStyle,
          fillColor: '#ff0000',
          // fillColor: '#ffc500',
          // fillOpacity: 0.5,
          // zIndex,
        },
      ];

      if (args.LKNTS_NM === '고병원성 조류인플루엔자') {
        return [radiusList[0], radiusList[1]];
      } else {
        return [radiusList[1], radiusList[2]];
      }
    },

    setRoadview() {
      // const { gisLat, gisLng } = this.selectDisease;
      let gisLat = 35.5;
      let gisLng = 129.0;
      if (this.selectDisease.eventId) {
        // farmName = this.selectDisease.farmName;
        gisLat = this.selectDisease.gisLat;
        gisLng = this.selectDisease.gisLng;
      } else {
        // farmName = this.selectFclt.fcltName;
        gisLat = this.selectFclt.gisLat;
        gisLng = this.selectFclt.gisLng;
      }

      this.$refs.dkGis.setRoadview({ lat: gisLat, lng: gisLng });
    },

    selectNavigation() {
      this.isNaviSelect = true;
    },

    // kakaoNavi() {
    //   const { farmName, gisLat, gisLng } = this.selectDisease;
    //   window.JSBridge.kakaoNavi(farmName, gisLng, gisLat);
    // },

    checkMobileWeb() {
      return this.$isMobile && !window.modbileDeviceId;
    },

    kakaoNavi() {
      // const { farmName = '.', gisLat, gisLng } = this.selectDisease;
      let farmName = '.';
      let gisLat = 35.5;
      let gisLng = 129.0;
      if (this.selectDisease.eventId) {
        farmName = this.selectDisease.farmName;
        gisLat = this.selectDisease.gisLat;
        gisLng = this.selectDisease.gisLng;
      } else {
        farmName = this.selectFclt.fcltName;
        gisLat = this.selectFclt.gisLat;
        gisLng = this.selectFclt.gisLng;
      }

      if (!this.checkMobileWeb()) {
        window.JSBridge.kakaoNavi(farmName, gisLng, gisLat);
      } else {
        if (Kakao) {
          Kakao.Navi.start({
            name: farmName,
            x: gisLng,
            y: gisLat,
            coordType: 'wgs84',
          });
        }
      }
    },

    tmapNavi() {
      let farmName = '.';
      let gisLat = 35.5;
      let gisLng = 129.0;
      if (this.selectDisease.eventId) {
        farmName = this.selectDisease.farmName;
        gisLat = this.selectDisease.gisLat;
        gisLng = this.selectDisease.gisLng;
      } else {
        farmName = this.selectFclt.fcltName;
        gisLat = this.selectFclt.gisLat;
        gisLng = this.selectFclt.gisLng;
      }
      // const { farmName = '.', gisLat, gisLng } = this.selectDisease;
      window.JSBridge.tmapNavi(farmName, gisLng, gisLat);
      // window.JSBridge.tmapNavi(this.selectFcltMarkerData.fcltName, this.selectFcltMarkerData.gisLng, this.selectFcltMarkerData.gisLat);
    },
    backgroundOff() {
      this.isNaviSelect = false;
    },

    mapClickEvent() {
      if (this.selectDisease.eventId || this.selectFclt.fcltId) {
        this.selectClear();
      } else {
        // this.isUIView = !this.isUIView;
      }
    },

    setBasicMapClickEvent() {
      this.$refs.dkGis.setClickEvent(this.mapClickEvent);
    },

    setDiseaseNearFarm() {
      const me = this;
      const { gisLat, gisLng } = me.selectDisease;
      const dstcList = me.fcltList.map((fcltInfo) => {
        const { lat, lng, properties } = fcltInfo;
        const distance = me.$refs.dkGis.getDistance(
          {
            lat: gisLat,
            lng: gisLng,
          },
          {
            lat,
            lng,
          }
        );
        return {
          fcltId: properties.fcltId,
          fcltName: properties.fcltName,
          distance: Math.round(distance),
        };
      });

      const farmList = dstcList.sort((a, b) => {
        return a.distance - b.distance;
      });

      me.selectDisease.farmList = farmList;
    },

    onClickFarmDistance(fcltInfo) {
      const { fcltId } = fcltInfo;
    },
  },
  watch: {
    diseaseList(n = [], o) {
      if (o.length > 0) {
        this.markerLayer.removeAllMarker();
      }
      if (n.length > 0) {
        this.markerLayer.addMarkerList(n);
      }
    },

    fcltList(n = [], o) {
      if (o.length > 0) {
        this.fcltMarkerLayer.removeAllMarker();
      }
      if (n.length > 0) {
        this.fcltMarkerLayer.addMarkerList(n);
      }
    },

    circleList(n = [], o) {
      if (o.length > 0) {
        this.circleLayer.removeAllCircle();
      }
      if (n.length > 0) {
        this.circleLayer.addCircleList(n);
      }
    },

    coverageFlag(n, o) {
      const me = this;
      me.circleList = me.circleList.map((elm) => {
        return {
          ...elm,
          circleInfo: {
            ...elm.circleInfo,
            visible: n,
          },
        };
      });
    },

    markerViewFlag(n, o) {
      this.diseaseList = this.diseaseList.map((elm) => {
        return {
          ...elm,
          visible: n === 0,
        };
      });

      this.fcltList = this.fcltList.map((elm) => {
        return {
          ...elm,
          visible: n === 1,
        };
      });
      if (this.messageInstance) {
        this.messageInstance.close();
      }
      if (n === 2) {
        this.convertClusterMarkerType();
        this.messageInstance = this.$message.info('법정동 보기');
      } else {
        this.clusterData = [];
        if (n === 0) {
          this.messageInstance = this.$message.info('질병지점 보기');
        } else if (n === 1) {
          this.messageInstance = this.$message.info('모든농장 보기');
        }
      }
    },
  },
};
</script>
<style>
.gis-wrap {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
</style>
