<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
  >
    <div>
      <el-steps :active="step" finish-status="success" align-center style="margin: 5px 0 10px 0;">
        <el-step v-for="(s, index) in stepData" :key="index" :title="s.title"></el-step>
      </el-steps>
      <div v-show="step === 0">
        <el-form
          :model="formData"
          label-width="160px"
          size="medium"
          :rules="rules"
          ref="form0"
          :label-position="$isMobile ? 'top' : 'right'"
        >
          <el-row>
            <el-col :xs="24" :sm="18" :md="18">
              <el-form-item label="사업명" prop="serviceTitle">
                <el-input v-model="formData.serviceTitle" placeholder="사업명을 입력하세요"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="18" :md="18">
              <el-form-item label="수행사" prop="company">
                <el-input v-model="formData.company" placeholder="수행사 명칭을 입력하세요"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="18" :md="18">
              <el-form-item label="사업 시작일" prop="workStartDate">
                <el-date-picker type="date" v-model="formData.workStartDate" placeholder="사업 시작일"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="18" :md="18">
              <el-form-item label="사업 종료일" prop="workEndDate">
                <el-date-picker
                  type="date"
                  v-model="formData.workEndDate"
                  :picker-options="pickerOptions1"
                  placeholder="사업 종료일"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="18" :md="18">
              <el-form-item label="내용" prop="text">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 10 }"
                  v-model="formData.text"
                  placeholder="사업관리 자료 내용"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div v-show="step === 1">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12">
            <h3>사업 관련 사진</h3>
            <dk-el-file-upload
              ref="uploadImage"
              uploaderType="image"
              listType="picture-card"
              path="serviceManage"
              :fileList="formData.images"
            >
              <div>
                <i class="el-icon-plus"></i>
              </div>
            </dk-el-file-upload>
            <!-- <el-upload
                        :action="uploadPath"
                        list-type="picture-card"                        
                        name="uploadFile"
                        :data="{uploadPath:'serviceManage'}"
                        :before-upload="checkUploadImage"
                        :on-change="procUploadImage"
                        :on-remove="procRemoveImage"
                        :file-list="formData.images"
                        >                        
                        <i class="el-icon-plus"></i>
            </el-upload>-->
          </el-col>
          <el-col :xs="24" :sm="12" :md="12">
            <h3>사업 관련 자료</h3>
            <dk-el-file-upload
              ref="uploadFile"
              path="serviceManage"
              :fileList="formData.files"
              :drag="true"
              :multiple="true"
            >
              <div>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  이곳에 업로드할 파일을 드롭하시거나
                  <br />
                  <em>클릭해서 업로드하세요</em>
                </div>
              </div>
            </dk-el-file-upload>
            <!-- <el-upload
                        :action="uploadPath"
                        name="uploadFile"
                        :data="{uploadPath:'serviceManage'}"
                        :on-change="procUploadFile"
                        :on-remove="procRemoveFile"
                        :file-list="formData.files"
                        drag
                        multiple>                        
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">이곳에 업로드할 파일을 드롭하시거나 <br><em>클릭해서 업로드하세요</em></div>                        
            </el-upload>-->
          </el-col>
        </el-row>
      </div>
      <div class="form-bottom">
        <el-button @click="prev" style="float: left;" v-show="step > 0">
          <i class="fas fa-angle-left"></i>&nbsp;&nbsp;이전
        </el-button>
        <el-button @click="next" style="float: right;" v-show="step < stepData.length - 1">
          다음&nbsp;&nbsp;
          <i class="fas fa-angle-right"></i>
        </el-button>
        <el-button @click="save" style="float: right;" v-show="step === stepData.length - 1">
          저장&nbsp;&nbsp;
          <i class="fas fa-angle-right"></i>
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import dkElFileUpload from '@/components/dkElFileUpload.vue';
import moment from 'moment';

export default {
  components: {
    'dk-el-file-upload': dkElFileUpload,
  },
  data() {
    return {
      formDialog: false,
      formTitle: '',
      step: 0,
      stepData: [
        { idx: 0, title: '사업 정보' },
        { idx: 1, title: '관련 파일' },
      ],
      formData: {},
      rules: {
        serviceTitle: [
          {
            required: true,
            message: '사업명을 입력해 주세요',
            trigger: 'blur',
          },
        ],
        company: [
          {
            required: true,
            message: '수행사 명칭을 입력해 주세요',
            trigger: 'blur',
          },
        ],
      },
      pickerOptions1: {
        disabledDate: this.disabledDate,
      },
    };
  },
  created() {},
  methods: {
    next() {
      if (this.$refs[`form${this.step}`]) {
        this.$refs[`form${this.step}`].validate((valid) => {
          if (valid) {
            this.step++;
          } else {
            return false;
          }
        });
      } else {
        this.step++;
      }

      if (this.step == 1) {
      }
    },
    prev() {
      if (this.step == 1) {
      }
      this.step--;
    },
    async save() {
      try {
        this.step++;
        this.formData.files = this.$refs.uploadFile.getFileData();
        this.formData.images = this.$refs.uploadImage.getFileData();

        let saveFunc = 'serviceManage/add';
        if (this.updateFlag) {
          saveFunc = 'serviceManage/update';
        }
        let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, {
          data: this.formData,
        });

        this.$parent.$emit('procDataComplete', this.formData);
        this.hideDialog();
        this.$message({
          type: 'success',
          message: `사업관리 자료 등록 완료`,
        });
      } catch (error) {
        this.hideDialog();
        this.$message.error(`사업관리 자료 등록 중 에러 발생`);
      }
    },
    showDialog(row) {
      if (row) {
        this.updateFlag = true;
        this.formTitle = '사업관리 자료 수정';
        this.formData = this.$lodash.cloneDeep(row);
      } else {
        this.updateFlag = false;
        this.formTitle = '사업관리 자료 추가';
        this.resetData();
      }
      this.formDialog = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        if (row) {
          this.$refs.uploadFile.setFileData(row.files);
          this.$refs.uploadImage.setFileData(row.images);
        } else {
          this.$refs.uploadFile.setFileData(null);
          this.$refs.uploadImage.setFileData(null);
        }
      });
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    resetData() {
      this.formData = {
        serviceTitle: '',
        writerId: '',
        company: '',
        workStartDate: '',
        workEndDate: '',
        text: '',
        images: [],
        files: [],
      };
      this.step = 0;
      if (this.$refs.form0) {
        this.$refs.form0.resetFields();
      }
    },

    changeIsPopup(val) {
      if (val) {
        //if (!this.formData.endDate) {
        //  this.formData.endDate = new Date();
        //}
      }
    },
    disabledDate(time) {
      // console.log(this.formData.workStartDate)
      if (this.formData.workStartDate) {
        return moment(time).isBefore(moment(this.formData.workStartDate));
      }
    },
  },
};
</script>
