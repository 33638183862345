var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-width-height", attrs: { id: "pageConfigure" } },
    [
      _c(
        "el-row",
        { staticClass: "page-body content-wrap" },
        [
          _c("dk-page-header"),
          _c(
            "el-col",
            { staticClass: "page-content", attrs: { span: 24 } },
            [
              _c(
                "el-row",
                { staticClass: "center-align" },
                [
                  _c("el-col", { attrs: { md: 16, sm: 24 } }, [
                    _c("div", { staticClass: "dk-card logo-wrap" }, [
                      _c("img", { attrs: { src: _vm.getLogo } })
                    ])
                  ]),
                  _c("el-col", { attrs: { md: 16, sm: 24 } }, [
                    _c("div", [
                      _c("table", { staticClass: "dk-table" }, [
                        _c("colgroup", [
                          _c("col", { staticStyle: { width: "20%" } }),
                          _c("col", { staticStyle: { width: "20%" } }),
                          _c("col", { staticStyle: { width: "auto" } })
                        ]),
                        _c("tbody", [
                          _c("tr", [
                            _c("th", { attrs: { scople: "row" } }, [
                              _vm._v("기관 명칭")
                            ]),
                            _c("td", { attrs: { colspan: "2" } }, [
                              _vm._v(_vm._s(_vm.configure.orgName))
                            ])
                          ]),
                          _c("tr", [
                            _c("th", { attrs: { scople: "row" } }, [
                              _vm._v("플랫폼 타이틀")
                            ]),
                            _c("td", { attrs: { colspan: "2" } }, [
                              _vm._v(_vm._s(_vm.configure.title))
                            ])
                          ]),
                          _c("tr", [
                            _c("th", { attrs: { scople: "row" } }, [
                              _vm._v("센터 연락처")
                            ]),
                            _c("td", { attrs: { colspan: "2" } }, [
                              _vm._v(_vm._s(_vm.configure.phone))
                            ])
                          ]),
                          _c("tr", [
                            _c(
                              "th",
                              { attrs: { rowspan: "4", scople: "rowgroup" } },
                              [_vm._v("GIS 정보")]
                            ),
                            _c(
                              "th",
                              {
                                staticClass: "th-sub",
                                attrs: { scope: "row" }
                              },
                              [_vm._v("OPEN API 종류")]
                            ),
                            _c("td", [
                              _vm._v(_vm._s(_vm.configure.gis.gisName))
                            ])
                          ]),
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass: "th-sub",
                                attrs: { scope: "row" }
                              },
                              [_vm._v("API KEY")]
                            ),
                            _c("td", [_vm._v(_vm._s(_vm.configure.gis.apiKey))])
                          ]),
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass: "th-sub",
                                attrs: { scope: "row" }
                              },
                              [_vm._v("기본 위도")]
                            ),
                            _c("td", [
                              _vm._v(_vm._s(_vm.configure.gis.geometry.lat))
                            ])
                          ]),
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass: "th-sub",
                                attrs: { scope: "row" }
                              },
                              [_vm._v("기본 경도")]
                            ),
                            _c("td", [
                              _vm._v(_vm._s(_vm.configure.gis.geometry.lng))
                            ])
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        "margin-top": "30px",
                        "text-align": "center"
                      },
                      attrs: { md: 16, sm: 24 }
                    },
                    [
                      _c("el-button", { on: { click: _vm.editConfigure } }, [
                        _c("h3", [_vm._v("기관 정보 수정")])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("form-configure", { ref: "formDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }