var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.formTitle,
        visible: _vm.formDialog,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.formDialog = $event
        },
        close: _vm.hideDialog
      }
    },
    [
      _c(
        "el-form",
        {
          ref: "form0",
          attrs: {
            model: _vm.formData,
            "label-width": "160px",
            size: "medium",
            rules: _vm.rules,
            "label-position": _vm.$isMobile ? "top" : "right"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "LED 라이브러리 이름", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "LED 라이브러리 이름을 입력하세요"
                        },
                        model: {
                          value: _vm.formData.ledLibraryName,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "ledLibraryName", $$v)
                          },
                          expression: "formData.ledLibraryName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "라이브러리 이미지" } },
                    [
                      _c(
                        "dk-el-file-upload",
                        {
                          ref: "uploadImage",
                          attrs: {
                            uploaderType: "image",
                            listType: "picture",
                            path: "ledLibrary",
                            fileList: _vm.formData.images,
                            checkUploadFile: _vm.checkUploadFile,
                            limit: 1
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "text-align": "left" } },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("라이브러리 이미지 업로드")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip"
                                },
                                [
                                  _vm._v(
                                    "\n                이미지 파일만 업로드할 수 있습니다\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "이미지 유형", prop: "title" } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.formData.imageType) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("저장")]
          ),
          _c("el-button", { on: { click: _vm.hideDialog } }, [_vm._v("취소")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }