import { render, staticRenderFns } from "./pageVmsTextSchedule.vue?vue&type=template&id=1045b736&scoped=true&"
import script from "./pageVmsTextSchedule.vue?vue&type=script&lang=js&"
export * from "./pageVmsTextSchedule.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1045b736",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/dk-platform-frontend/dk-platform-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1045b736')) {
      api.createRecord('1045b736', component.options)
    } else {
      api.reload('1045b736', component.options)
    }
    module.hot.accept("./pageVmsTextSchedule.vue?vue&type=template&id=1045b736&scoped=true&", function () {
      api.rerender('1045b736', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/traffic/pageVmsTextSchedule.vue"
export default component.exports