<template>
  <el-container class="fill-width-height" ref="gisContainer">
    <el-container class="gis-wrap">
      <!-- <dk-info-panel
        ref="infoPanel"
        :afterLeave="changeInfoPanel"
        :afterEnter="changeInfoPanel"
        width="320px"
        :initShow="true"
        :showCloseButton="false"
      > -->
      <div class="gis-search-panel">
        <!-- 시설물 목록 -->

        <el-row>
          <el-col :span="16" style="margin-left: 11px; margin-top: 12px;">
            <el-input size="medium" v-model="keyword" @keyup.enter.native="searchFcltList" placeholder="시설물 검색"></el-input>
          </el-col>
          <el-col :span="2" style="margin-top: 12px; padding-left: 4px;" v-show="isSearch">
            <el-button type="primary" @click="resetSearch" size="medium" style="padding: 10px 0; width: 100%;">
              <i class="fas fa-times"></i>
            </el-button>
          </el-col>
          <el-col :span="5" style="margin-top: 12px; padding-left: 3px;">
            <el-button type="primary" @click="searchFcltList" size="medium" style="width: 100%;">
              <i class="fas fa-search"></i>
            </el-button>
          </el-col>
        </el-row>
        <el-tabs class="dk-card gis-data-wrap" v-model="activeGisDataTab">
          <el-tab-pane label="시설물 정보" name="tab0" class="gis-search-result">
            <h3 v-if="gisParam.clusterLv1 < beforeZoomLevel && this.gisParam.clusterEnable">
              지역별 시설물 수량 정보
            </h3>
            <h3 v-else>시설물 정보</h3>

            <ul v-if="gisParam.clusterLv1 < beforeZoomLevel && this.gisParam.clusterEnable">
              <li v-for="(item, idx) in clusterData" :key="idx" @click="moveGisCenter(item.geometry)">
                <div class="search-list-text">
                  <span class="title"
                    >{{ item.properties.name }} <el-tag type="primary">{{ item.properties.count }}</el-tag></span
                  >
                </div>
                <div class="search-list-button">
                  <i class="fas fa-arrows-alt button-move"></i>
                </div>
              </li>
            </ul>
            <ul v-else>
              <li v-for="(item, idx) in markerData" :key="idx">
                <div class="search-list-text">
                  <div>
                    <span class="title"
                      >{{ item.properties.fcltName }}<span>{{ item.properties.fcltTypeName }}</span></span
                    >
                  </div>
                  <div>{{ item.properties.setNumberAddr }}</div>
                </div>
                <div class="search-list-button">
                  <i class="fas fa-map-marker-alt" @click="selectFclt(item)"></i>
                </div>
              </li>
            </ul>
          </el-tab-pane>
          <!-- <el-tab-pane label="지역별" name="tab0" class="gis-search-result gis-group-list">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="gis-breadcrumb">
              <el-breadcrumb-item v-if="stateDepth.depth1">
                <a @click="clickStateHome()">
                  <i class="fas fa-home"></i>
                </a>
              </el-breadcrumb-item>
              <el-breadcrumb-item v-if="stateDepth.depth1">
                <a @click="clickState(stateDepth.depth1)">{{ stateDepth.depth1.name }}</a>
              </el-breadcrumb-item>
              <el-breadcrumb-item v-if="stateDepth.depth1 && stateDepth.depth2">
                <a @click="clickSigungu(stateDepth.depth2)">{{ stateDepth.depth2.name }}</a>
              </el-breadcrumb-item>
              <el-breadcrumb-item v-if="stateDepth.depth1 && stateDepth.depth2 && stateDepth.depth3">
                <a>{{ stateDepth.depth3.name }}</a>
              </el-breadcrumb-item>
            </el-breadcrumb>

            <div class="gis-list-box gis-list-form2" v-if="!stateDepth.depth1">
              <h3>
                <i class="icon-arrow-color"></i>
                행정구역
              </h3>
              <ul>
                <li v-for="(item, idx) in stateInfo" :key="idx" @click="clickState(item)">
                  <i class="icon-marker"></i> {{ item.name }} ({{ item.count }})
                </li>
              </ul>
            </div>
            <div class="gis-list-box gis-list-form2" v-else-if="stateDepth.depth1 && !stateDepth.depth2">
              <h3>
                <i class="icon-arrow-color"></i>
                {{ stateDepth.depth1.name }}
              </h3>
              <ul>
                <li v-for="(item, idx) in stateInfo" :key="idx" @click="clickSigungu(item)">
                  <i class="icon-marker"></i> {{ item.name }} ({{ item.count }})
                </li>
              </ul>
            </div>
            <div class="gis-list-box gis-list-form2" v-else-if="stateDepth.depth1 && stateDepth.depth2">
              <h3>
                <i class="icon-arrow-color"></i>
                {{ stateDepth.depth2.name }}
              </h3>
              <ul>
                <li v-for="(item, idx) in stateInfo" :key="idx" @click="clickEmd(item)">
                  <i class="icon-marker"></i> {{ item.name }} ({{ item.count }})
                </li>
              </ul>
            </div>
          </el-tab-pane> -->
          <el-tab-pane label="검색결과" name="tab1" class="gis-search-result">
            <h3>주소 정보</h3>
            <ul v-if="searchPlaceData.length > 0">
              <li v-for="(item, idx) in searchPlaceData" :key="idx">
                <div class="search-list-text">
                  <span class="title">{{ item.addressName }}</span>
                </div>
                <div class="search-list-button">
                  <i class="fas fa-arrows-alt button-move" @click="moveGisCenter(item)"></i>
                </div>
              </li>
            </ul>
            <div class="no-result" v-else>검색된 주소정보가 없습니다.</div>
            <h3>시설물 정보</h3>
            <ul v-if="searchTempMarkerData.length > 0">
              <li v-for="(item, idx) in searchTempMarkerData" :key="idx">
                <div class="search-list-text">
                  <div>
                    <span class="title">{{ item.properties.fcltName }}</span>
                  </div>
                  <div>{{ item.properties.fcltTypeName }}</div>
                  <div>{{ item.properties.setNumberAddr }}</div>
                </div>
                <div class="search-list-button">
                  <i class="fas fa-map-marker-alt" @click="applySearchMarkerData(item)"></i>
                </div>
              </li>
            </ul>
            <div class="no-result" v-else>검색된 시설물정보가 없습니다.</div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-container>
        <el-main>
          <div class="gis-toolbar">
            <ul class="gis-toolbar-menu">
              <li>
                <el-button
                  size="small"
                  class="fclt-btn"
                  @click="gisMenuSelect('setFcltManageOnGis')"
                  v-if="activeUser.group === 'manager' || activeUser.group === 'operator'"
                >
                  <i class="fas fa-map-marker-alt"></i>시설물추가
                </el-button>
              </li>
              <li>
                <el-button
                  size="small"
                  class="fclt-btn"
                  @click="toggleGisInfoWindow()"
                  :type="isOpenMarkerPopup || isGisOpenInfoWindow ? 'primary' : ''"
                >
                  <i class="fas fa-tag"></i>시설물라벨
                </el-button>
              </li>

              <li>
                <el-button size="small" class="fclt-btn" @click="gisMenuSelect('setTrafficLayer')">
                  <i class="fas fa-traffic-light"></i>교통정보
                </el-button>
              </li>

              <li>
                <el-button size="small" class="fclt-btn" @click="gisCapture" v-if="isGisCapture"> <i class="fas fa-image"></i>지도캡쳐 </el-button>
              </li>

              <li>
                <el-button size="small" class="fclt-btn" @click="toggleLineDraw" :type="lineDrawFlag ? 'primary' : ''">
                  <i class="icon-ruler"></i>거리측정
                </el-button>
              </li>

              <li>
                <el-button size="small" class="fclt-btn" @click="toggleCluster" :type="gisParam.clusterEnable ? 'primary' : ''">
                  <i class="fas fa-info-circle"></i>법정동 그룹
                </el-button>
              </li>

              <li>
                <el-button size="small" @click="showGisLegend = !showGisLegend" class="fclt-btn">
                  <i class="fas fa-arrow-alt-circle-up" v-if="!showGisLegend"></i>
                  <i class="fas fa-arrow-alt-circle-down" v-if="showGisLegend"></i>범례
                </el-button>

                <ul class="gis-legend hidden-sm-only" v-show="showGisLegend">
                  <li
                    v-for="item in fcltType"
                    :key="item.fcltTypeId"
                    :class="[checkDisplayFcltType(item.fcltTypeId) ? 'selected' : '']"
                    @click="toggleFcltType(item.fcltTypeId)"
                  >
                    <img :src="item.gisMarkerImage.normal" />
                    {{ item.fcltTypeName }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <dk-gis
            fill-height
            :gisApiInfo="gisApiInfo"
            :markerData="markerData"
            :clickMarker="onClickMarker"
            :clickCluster="selectCluster"
            :fcltManagerOnGis="fcltManagerOnGis"
            :apiLoadComplete="apiLoadComplete"
            :incidentOnGis="incidentOnGis"
            :clusterData="clusterData"
            :searchData="searchPlaceData"
            @moveEnd="searchFcltGis"
            @zoomEnd="zoomEnd"
            ref="dkGis"
            id="dkGis"
          ></dk-gis>
          
          <div ref="gisSelectBox" class="gis-select-box-modal" v-if="selectMarkerList.length > 0">
            <span>마커 선택</span>
            <ul ref="gisSelectBoxList">
              <li v-for="marker in selectMarkerList" :key="marker.properties.fcltId">
                <button @click="onClickSelectBoxItem(marker)" @blur="onBlurSelectBox">
                  {{marker.properties.fcltName}}
                </button>
              </li>
            </ul>
          </div>
          <div class="geolocation-addr" v-if="geolocationAddr!=='정보 없음'">
            <strong class="location">{{ geolocationAddr }}</strong>
          </div>
        </el-main>
      </el-container>
      <!-- isShowInfoPanel -->
      <dk-info-panel
        ref="fcltInfoPanel"
        :afterLeave="changeInfoPanel"
        :afterEnter="changeInfoPanel"
        width="451px"
        :initShow="false"
        :class="[isShowFcltInfo ? 'is-show-fclt' : 'is-show-list']"
      >
        <!-- 시설물 상세 페이지 -->
        <div v-show="selectFcltMarkerData.fcltId" class="d-flex" style="height: 100%;">
          <ul class="side-detail-tabs">
            <li @click="clearFclt" >
              <div class="tab-header">
                <i class="fas fa-list-ul"></i>
                <span>목록으로</span>
              </div>
            </li>
            <li v-if="isFcltPlugin" @click="buttonAction('tab0', true)" v-bind:class="[getCurrentSideTabClass('tab0')]">
              <div class="tab-header">
                <i class="fas fa-toolbox"></i>
                <span>{{fcltPluginLabel}}</span>
              </div>
            </li>
            <li @click="buttonAction('tab1', true)" v-bind:class="[getCurrentSideTabClass('tab1')]">
              <div class="tab-header">
                <i class="fas fa-wrench"></i>
                <span>시설물 정보</span>
              </div>
            </li>
            <li @click="maintnce">
              <div class="tab-header">
                <i class="fas fa-wrench"></i>
                <span>유지 관리</span>
              </div>
            </li>
            <li @click="setRoadview" v-if="currentGisApi.gisName && currentGisApi.gisName == 'kakao'">
              <div class="tab-header">
                <i class="fas fa-map-marker-alt"></i>
                <span>거리뷰</span>
              </div>
            </li>
          </ul>
          <div class="gis-info-panel-wrap d-lfe" style=" height: 100%; width: 100%;">
            <div v-if="currentTab === 'tab0'">               
              <component :is="selectFcltPlugin" :fcltData="selectFcltMarkerData" ref="fcltPlugin"></component>              
            </div>
            <div v-if="currentTab === 'tab1'">
              <el-carousel height="150px" v-if="selectFcltMarkerData.images && selectFcltMarkerData.images.length > 0">
                <el-carousel-item v-for="(file, index) in selectFcltMarkerData.images" :key="index">
                    <div class="dk-carousel-item">
                      <img :src="$config.getServerConfig().image.url + file.serverFilePath" />
                    </div>
                </el-carousel-item>
              </el-carousel>
              <div v-if="this.activeUser.group === 'manager' || this.activeUser.group === 'operator'">
                  <!-- <el-dropdown trigger="click" @command="fcltInfoCommand">
                    <span class="el-dropdown-link">
                      <i class="fas fa-cogs"></i>시설물 정보 변경
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item icon="fas fa-wrench" command="update">시설물 정보 수정</el-dropdown-item>
                      <el-dropdown-item icon="fas fa-trash-alt" command="remove">시설물 정보 삭제</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown> -->
                  <div class="fclt-info-edit" v-if="editAbleFlag">
                    <el-button plain @click="updateFclt"><i class="fas fa-wrench"></i><span>시설물 정보 수정</span></el-button>
                    <el-button type="danger" plain @click="removeFclt"><i class="fas fa-trash-alt"></i><span>시설물 정보 삭제</span></el-button>
                  </div>
                </div>
                <div class="dk-card" style="padding-top: 0;">
                  <table class="el-table el-table--mini">
                    <tr>
                      <th style="white-space: nowrap;">시설물 명칭</th>
                      <td>{{ selectFcltMarkerData.fcltName }}</td>
                    </tr>
                    <tr>
                      <th>시설물 유형</th>
                      <td>{{ selectFcltMarkerData.fcltTypeName }}</td>
                    </tr>
                    <tr>
                      <th>도로명 주소</th>
                      <td>{{ selectFcltMarkerData.setRoadAddr }}</td>
                    </tr>
                    <tr>
                      <th>지번 주소</th>
                      <td>{{ selectFcltMarkerData.setNumberAddr }}</td>
                    </tr>
                    <tr>
                      <th>경도</th>
                      <td>{{ selectFcltMarkerData.gisLng }}</td>
                    </tr>
                    <tr>
                      <th>위도</th>
                      <td>{{ selectFcltMarkerData.gisLat }}</td>
                    </tr>
                    <tr v-for="(ftData, index) in selectFcltMarkerData.fcltTypeData" :key="index">
                      <th>{{ ftData.label }}</th>
                      <td>{{ getFcltDataValue(ftData) }}</td>
                    </tr>
                  </table>
                </div>
            </div>
            <div v-if="currentTab === 'tab2'" >
                        <div class="dk-card">
                  <div class="dk-card-title">시설물 장비 정보</div>
                  <el-collapse v-if="selectFcltMarkerData.equip && selectFcltMarkerData.equip.length > 0" accordion>
                    <!-- <el-collapse-item
                      v-for="(eq, index) in selectFcltMarkerData.equip"
                      :key="index"
                      :name="index"
                      v-if="eq && eq.materialName"
                    >-->
                    <el-collapse-item v-for="(eq, index) in equipList" :key="index" :name="index">
                      <template slot="title">
                        <span style="font-size: 13px; font-weight: bold; margin-left: 5px;">{{ eq.materialName }}</span>
                        <span style="font-size: 12px; margin-left: 7px;">{{ eq.materialTypeName }}</span>
                      </template>
                      <table class="el-table el-table--mini dk-el-table-collapse">
                        <tr v-for="(eqd, index) in eq.equipData" :key="index">
                          <th style="width: 120px;">{{ eqd.label }}</th>
                          <td>{{ eqd.value }}</td>
                        </tr>
                        <tr v-for="(mtd, index) in eq.materialTypeData" :key="index">
                          <th style="width: 120px;">{{ mtd.label }}</th>
                          <td>{{ mtd.value }}</td>
                        </tr>
                      </table>
                    </el-collapse-item>
                  </el-collapse>
                  <h5 v-else style="text-align: center;">등록된 장비 정보가 없습니다.</h5>
                </div>
            </div>
            <div v-if="currentTab === 'tab3'">
                <div class="dk-card">
                  <el-table
                    :data="selectFcltMarkerData.files"
                    empty-text="등록된 시설물 관련 자료가 없습니다."
                    size="mini"
                    stripe
                    @row-click="downloadReference"
                  >
                    <el-table-column label="파일 명">
                      <template slot-scope="scope">
                        <span class="file-download-label">{{ scope.row.name }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="파일 크기" width="120px">
                      <template slot-scope="scope">{{ getHumanFileSize(scope.row.size) }}</template>
                    </el-table-column>
                  </el-table>
                </div>
            </div>
          </div>
        </div>
      </dk-info-panel>
    </el-container>
    <form-fclt-info ref="formFcltDialog" :gisApiInfo="gisApiInfo" :geometry="geometry"></form-fclt-info>
    <form-maintnce ref="formMaintnce"></form-maintnce>
  </el-container>
</template>

<script>
// DK components
import _ from 'lodash';
import dkgismain from '@/components/dkGis/dkGisMain.vue';
import dkinfopanel from '@/components/dkInfoPanel.vue';
import formFcltInfo from '@/core/forms/formFcltInfo.vue';
import DkFileUtil from '@/components/dkFileUtil.js';
import dkelpagination from '@/components/dkElPagination.vue';
import formMaintnce from '@/core/forms/formMaintnce.vue';
import html2canvas from 'html2canvas';
import { mapGetters } from 'vuex';

export default {
  components: {
    'dk-gis': dkgismain,
    'form-fclt-info': formFcltInfo,
    'form-maintnce': formMaintnce,
    'dk-info-panel': dkinfopanel,
    'dk-el-pagination': dkelpagination,
  },
  data: () => ({
    currentTab: 'tab1',
    isTab: true,
    systemLogInfo : {},
    pageInit: false,
    // infoPanelOpen: true,
    gisApiInfo: [],
    currentGisApi: {},
    platformType: 'fclt',
    totalFcltCount: 0,
    beforeZoomLevel: -1,
    beforeSearchFcltTypeId: [],
    markerData: [],
    clusterData: [],
    activeGisDataTab: 'tab0',
    searchPlaceData: [],

    selectFcltMarkerData: {
      fcltId: '',
      fcltName: '',
      fcltTypeName: '',
      fcltTypeId: '',
      regionId: '',
      regionName: '',
      setDate: '',
      setNumberAddr: '',
      setRoadAddr: '',
      gisLat: '',
      gisLng: '',
      equip: [],
      fcltTypeData: {},
      images: [],
      files: [],
    },
    isShowFcltInfo: false,
    fcltPluginLabel: '',
    activeFcltTab: 'tab1',
    isOnTrafficLayer: true,
    isOnCoverageArea: true,
    isShowInfoPanel: true,
    geometry: {},
    fcltType: [],
    keyword: '',
    searchFcltTypeId: [],
    // manageGroup: '',

    gisMenuItems1: [
      // {
      //   index:"0",
      //   label: "카카오",
      //   clickEvent: "changeKakao",
      //   icon: "fas fa-map"
      // },
      // {
      //   index:"1",
      //   label: "OSM",
      //   clickEvent: "changeOpenStreetMap",
      //   icon: "fas fa-map"
      // }
    ],
    // gisMenuItems2: [
    //   {
    //     index: "2",
    //     label: "시설물추가",
    //     clickEvent: "setFcltManageOnGis",
    //     icon: "fas fa-map-marker-alt",
    //     type: 'global'
    //   },
    //   {
    //     index: "3",
    //     label: "교통정보",
    //     clickEvent: "setTrafficLayer",
    //     icon: "fas fa-traffic-light",
    //     type: 'global'
    //   }
    //   {
    //     index: "4",
    //     label: "커버리지",
    //     clickEvent: "getCoverageArea",
    //     icon: "fas fa-traffic-light",
    //     type: 'global'
    //   }
    // ],
    activeUser: null,
    geolocationAddr: '정보 없음',
    showGisLegend: true,

    isNoSearchByGisMove: false,
    searchTempMarkerData: [],
    isSearch: false,
    loading: null,
    gisParam: {
      clusterLv0: 0,
      clusterLv1: 4,
      clusterLv2: 8,
      clusterEnable: true,
    },
    isGisCapture: false,
    stateInfo: [],
    sigInfo: [],
    emdInfo: [],
    stateDepth: {
      depth1: null,
      depth2: null,
      depth3: null,
    },
    isOpenMarkerPopup: false,
    lineDrawFlag: false,
    editAbleFlag: true,
    selectMarkerList: [],
  }),

  created() {
    window.html2canvas = html2canvas;
    this.activeUser = this.$dkRouter.getUserInfo();
    // GIS API 모듈 로딩정보
    let me = this;
    this.gisParam.clusterEnable = this.$config.getSiteConfig().defaultCluster;
    this.isGisCapture = this.$config.getSiteConfig().gisCapture;

    //this.$store.dispatch('gis/actGisApi', {}).then(() => {
    me.gisApiInfo = me.$store.getters['gis/getGisApi'];
    me.platformType = me.gisApiInfo[0].platformType || 'fclt';

    // 카카오 내비 연동용 API 초기화
    // 나중에 어떻게 할지 고민좀 해봐야함
    // 모바일에서나 쓸거같으니 모바일만 초기화 함
    // 모바일 페이지는 어쩌징?
    // 막상 다시할라니 반응형이 지랄같겠는디..

    if (me.$isMobile) {
      Kakao.init(me.$config.getSiteConfig().gis.apiKey);
    }
    //});

    // if (this.$config.getSiteConfig().platformType === 'utis') {
    //   this.gisMenuItems2.push({
    //     index: "4",
    //     label: "사고/돌발 등록",
    //     clickEvent: "setIncidentEvent",
    //     icon: "fas fa-car-crash"
    //   })
    // }

    const { options = [] } = this.$config.getSiteConfig();
    const ck = options.findIndex((o) => o === 'fclt-read-only');
    if (ck !== -1) {
      me.editAbleFlag = false;
    }
  },
  mounted() {
    this.$refs.gisContainer.$on('procDataComplete', (fcltData) => { 
      this.markerData = [];     
      this.getFclt().then(() => {
        this.clearFclt();
        //this.$refs.dkGis.refresh();
        // let selectedMarker = this.markerData.find((marker) => {
        //   return (marker.fcltId = fcltData.fcltId);
        // });
        // this.selectFclt(selectedMarker);
      });
    });
    this.$nextTick(() => {
      let api = this.gisApiInfo.find((apiInfo) => {
        return apiInfo.initGis;
      });
      if (api && api.gisName) {
        this.$refs.dkGis.gisModuleLoad(api.gisName);
      }
      this.selectFclt(this.markerData[0]);
    });
  },
  computed: {
    isFcltPlugin() {      
      if (this.selectFcltMarkerData && this.selectFcltMarkerData.fcltTypeId) {
        let plugin = this.$dkPlugin.getPlugin(this.selectFcltMarkerData.fcltTypeId);

        if (plugin) {
          plugin.then((val) => {
            this.fcltPluginLabel = val.default.label;
          });
          this.activeFcltTab = 'tab0';
          return true;
        } else {
          this.activeFcltTab = 'tab1';
          return false;
        }
      } else {
        return false;
      }
    },
    selectFcltPlugin() {
      if (this.selectFcltMarkerData.fcltTypeId) {
        return () => {
          return this.$dkPlugin.getPlugin(this.selectFcltMarkerData.fcltTypeId);
        };
      } else {
        return false;
      }
    },
    equipList() {
      if (this.selectFcltMarkerData.equip && typeof this.selectFcltMarkerData.equip === 'object') {
        let result = this.selectFcltMarkerData.equip.map((eq) => {
          if (eq && eq.materialName) {
            return eq;
          }
        });
        return result;
      } else {
        return [];
      }
    },
    ...mapGetters({
      isGisOpenInfoWindow: 'gis/getIsOpenInfoWindow',
    }),

  },
  destroyed() {
    if ('geolocation' in navigator) {
      // navigator.geolocation.clearWatch(this.watchID);
    }
  },
  watch: {},
  methods: {
    buttonAction(tabId, isTab) {
      this.currentTab = tabId;
      this.isTab = isTab;
    },
    async searchFcltGis(args) {
      let data = await this.$refs.dkGis.getAddrFromCenterCoord(true);
      this.geolocationAddr = data.addr && data.addr.num_addr ? data.addr.num_addr : '정보 없음';

      if (this.isNoSearchByGisMove) {
        return;
      }

      // param.fcltTypeId = this.searchFcltTypeId;
      // xthis.markerData = []
      await this.getGisFcltMarker();

      for (var i = 0; i < this.markerData.length; i++) {
        this.markerData[i].zoomLevel = args.level;
      }
      // this.$refs.pagination.setPage(1)
      // this.pagingProc(1)
      if (!this.pageInit) {
        this.pageInit = true;
      }
    },
    async getFclt() {
      this.$nextTick(async () => {
        await this.getGisFcltMarker();
        if (!this.pageInit) {
          this.pageInit = true;
        }
      });
    },

    // searchFcltEvt() {
    //   this.getFclt()
    //   .then(()=> {
    //     if (this.$isMobile) {
    //         if (this.markerData.length > 0 && this.markerData.length == 1) {
    //           this.selectFclt(this.markerData[0])
    //         }
    //         else {
    //             document.getElementById("mainView").scrollTop = this.$refs['dkGis'].$el.clientHeight - ((this.$refs['dkGis'].$el.clientHeight/2)+120)
    //         }
    //     }
    //   })
    // },

    async searchFcltList() {
      let param = {};
      this.isSearch = true;
      this.searchTempMarkerData = [];
      this.searchPlaceData = [];

      param.keyword = this.keyword;

      // 장소 데이터는 검색하고 적용 했을 시, 그 부근으로 지도를 이동시키는 기능으로 실행한다.
      const placeData = await this.$refs.dkGis.getCoordFromAddr(param.keyword);      
      this.searchPlaceData = placeData;
      
      // 검색된 시설물 데이터는 임시 목록에 저장한 후, 적용하였을 시, markerData에 엎어씌워서
      // 시설물 마커를 rendering하고 선택한다.
      param.clusterLv0 = 0;
      param.clusterLv1 = 999;
      param.clusterLv2 = 999;
      param.fcltTypeId = this.searchFcltTypeId;
      let activeUser = this.$store.getters['auth/getActiveUser'];
      if (activeUser && activeUser.userid) {
        param.userid = activeUser.userid;
        param.group = activeUser.group;
      }
      let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/gis/getFcltMarker`, {
        params: param,
      });

      this.searchTempMarkerData = result.data.markerData;
      this.markerData = result.data.markerData;
      this.clusterData = [];
      this.activeGisDataTab = 'tab1';
    },
    async moveGisCenter(placeData) {
      if (!placeData || !placeData.lat || !placeData.lng) {
        this.$message({
          type: 'warning',
          message: `선택한 지점의 경/위도 정보가 유효하지 않습니다.`,
        });
        return;
      }
      // let data = await this.$refs.dkGis.actGeolocation({
      //       lat:placeData.lat,
      //       lng:placeData.lng
      //   })
      // this.geolocationAddr = placeData.addressName;
      this.$refs.dkGis.setCenter({
        lat: placeData.lat,
        lng: placeData.lng,
      });
      setTimeout(() => {
        // this.$refs.dkGis.setLevel(3);
      }, 100);
      // this.geolocationAddr = (data.addr && data.addr.num_addr) ? data.addr.num_addr : "정보 없음"
    },
    async applySearchMarkerData(fcltData) {
      // document.getElementById('mainView').scrollTop = 0;
      this.isNoSearchByGisMove = true;
      this.clusterData = [];
      // this.markerData = [fcltData];
      this.selectFclt(fcltData);
    },
    resetSearch() {
      this.isSearch = false;
      this.isNoSearchByGisMove = false;
      this.keyword = '';
      this.searchPlaceData = [];
      this.searchTempMarkerData = [];
      this.activeGisDataTab = 'tab0';
      this.getFclt();
      this.clearFclt();
    },

    async getGisFcltMarker(param) {
      let args = {};
      // this.markerData = [];
      // this.clusterData = [];
      let zoomLevel = this.$refs.dkGis.getLevel();
      if (this.beforeZoomLevel !== this.$refs.dkGis.getLevel()) {
        this.markerData = [];
        this.clusterData = [];
        this.searchPlaceData = [];
      }
      // if (param && param.fcltTypeId) {
      //     if (!this.$lodash.isEqual(this.beforeSearchFcltTypeId, param.fcltTypeId)) {
      //         this.markerData = []
      //         this.clusterData = []
      //         this.searchPlaceData = []
      //     }
      //     this.beforeSearchFcltTypeId = this.$lodash.cloneDeep(param.fcltTypeId)
      // }
      this.beforeZoomLevel = zoomLevel;

      // if (param) {
      //   args.bounds = this.$refs.dkGis.getBounds()
      //   args.zoomLevel = zoomLevel
      //   args.centerCoords = this.$refs.dkGis.getCenter()
      // }
      // else {
      //   args = {
      //     bounds:  this.$refs.dkGis.getBounds(),
      //     zoomLevel : zoomLevel,
      //     centerCoords : this.$refs.dkGis.getCenter()
      //   }
      // }
      if (this.keyword !== '') {
        args.keyword = this.keyword;
        this.searchPlaceData = await this.$refs.dkGis.getCoordFromAddr(args.keyword);
        // if (this.searchPlaceData.length > 0) {
        //   this.activeGisDataTab = 'tab0'
        //   delete args.keyword
        // }
      }

      args.bounds = this.$refs.dkGis.getBounds();
      args.zoomLevel = zoomLevel;
      args.centerCoords = this.$refs.dkGis.getCenter();
      args.clusterLv0 = this.gisParam.clusterLv0 || 0;
      args.clusterLv1 = this.gisParam.clusterLv1 || 4;
      args.clusterLv2 = this.gisParam.clusterLv2 || 8;
      args.clusterEnable = this.gisParam.clusterEnable;
      args.fcltTypeId = this.searchFcltTypeId;
      //마커가 최대한으로 표출될 줌레벨 (렉이 넘걸려.. )
      args.markerLimitLv = 6;

      let activeUser = this.$store.getters['auth/getActiveUser'];
      if (activeUser && activeUser.userid) {
        args.userid = activeUser.userid;
        args.group = activeUser.group;
      }

      // args.bounds = undefined;
      let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/gis/getFcltMarker`, {
        params: args,
      });
      /** 클러스터는 추가로딩이고 지랄이고 필요없음 */
      if (result.data.clusterData && result.data.clusterData.length > 0) {
        this.markerData = [];
        this.clusterData = result.data.clusterData;
      } else {
        this.clusterData = [];
      }
      if (result.data.markerData && result.data.markerData.length > 0) {
        this.markerData = [];

        let newMarkerData = this.$lodash.map(result.data.markerData, (item) => {
          let chk = _.find(this.markerData, function (o) {
            if (o && o.properties) {
              return item.properties.fcltId === o.properties.fcltId;
            } else {
              return false;
            }
          });
          if (!chk) {
            return item;
          } else {
            return null;
          }
        });
        this.markerData = this.markerData.concat(this.$lodash.compact(newMarkerData));
      } else {
        this.markerData = [];
      }
      // if (response.data.keyword) {
      //   this.searchPlaceData = await this.$refs.dkGis.getCoordFromAddr(response.data.keyword)
      //   this.totalFcltCount = this.markerData.length

      //   if (this.searchPlaceData.length > 0) {
      //     this.activeGisDataTab = 'tab0'
      //   }
      // }

      /*
        if (this.markerData.length > 0) {
          // if (this.searchPlaceData.length > 0) {
          //   this.activeGisDataTab = 'tab0'
          // }
          // else {
            this.activeGisDataTab = 'tab1'
          // }
          this.totalFcltCount = this.markerData.length
        }
        else if (this.clusterData.length > 0) {
          // if (this.searchPlaceData.length > 0) {
          //   this.activeGisDataTab = 'tab0'
          // }
          // else {
            this.activeGisDataTab = 'tab2'
          // }
          this.totalFcltCount = 0
          this.clusterData.forEach((c)=> {
            this.totalFcltCount += parseInt(c.properties.fcltCount)
          })
        }
*/
    },
    selectFcltDataGrid(row, event, column) {
      this.selectFclt(row);
    },
    async onClickMarker(args) {
      if( 
        !args || !args.properties ||
        !args.properties.gisLat ||
        !args.properties.gisLng
      ) {
        return;
      }

      const {gisLat, gisLng} = args.properties;

      const markerFilterList = this.markerData.filter(m => {
        return m.properties.gisLat === gisLat && m.properties.gisLng === gisLng
      });

      if(markerFilterList.length > 1) {
        this.selectMarkerList = markerFilterList;
        this.isShowFcltInfo = false;
        await this.$nextTick();
        this.$refs.gisSelectBoxList.firstChild.firstChild.focus();
        return;
      } else {
        this.selectMarkerList = [];
        this.selectFclt(args);
      }
    },

    onClickSelectBoxItem(marker) {
        this.selectMarkerList = [];
        this.selectFclt(marker, true);
    },

    onBlurSelectBox(e) {
      setTimeout(()=>{
        this.selectMarkerList = [];
      }, 100);
    },

    selectFclt(args, disableMarkerClickEvent) {
      if (!args) {
        return false;
      }
      // isShowInfoPanel
      this.isShowFcltInfo = true;
      if (!this.$refs.fcltInfoPanel.isOpened()) {
        this.$refs.fcltInfoPanel.open();
      }
      this.$refs.dkGis.setCenter({
        lat: args.properties.gisLat,
        lng: args.properties.gisLng,
      });
      setTimeout(() => {
        // this.$refs.dkGis.setLevel(3);
        this.selectFcltMarkerData = args.properties;
        if (args.$markerClickEvent && !disableMarkerClickEvent) {
          args.$markerClickEvent();
          return;
        }
      }, 100);
    },
    clearFclt() {
      if (this.$isMobile) {
        // document.getElementById('mainView').scrollTop = 0;
      }

      this.selectFcltMarkerData = {
        fcltName: '',
        fcltTypeName: '',
        fcltTypeId: '',
        regionId: '',
        regionName: '',
        setDate: '',
        setNumberAddr: '',
        setRoadAddr: '',
        gisLat: '',
        gisLng: '',
        equip: [],
        fcltTypeData: {},
        images: [],
        files: [],
      };
      this.isShowFcltInfo = false;
      if (this.$refs.dkGis) {
        this.hideRoadview();
      }
      if (this.$refs.fcltInfoPanel.isOpened()) {
        this.$refs.fcltInfoPanel.close();
      }
    },
    selectMaintnceLog(maintnceLog) {
      this.$refs.formMaintnce.showDialog(maintnceLog);
    },
    maintnce() {      
      this.$refs.formMaintnce.showDialogOnGis(this.selectFcltMarkerData);
    },
    // fcltInfoCommand(command) {
    //   if (command == 'update') {
    //     this.updateFclt();
    //   } else if (command == 'remove') {
    //     this.removeFclt();
    //   }
    // },
    async updateFclt() {
      this.geometry = {
        lat: this.selectFcltMarkerData.gisLat,
        lng: this.selectFcltMarkerData.gisLng,
      };
      let updateFlag = true;
      this.$refs.formFcltDialog.showDialog(updateFlag);
      setTimeout(() => {
        this.$refs.formFcltDialog.setFcltDataDoc(this.selectFcltMarkerData);
      }, 100);
    },
    removeFclt() {      
      this.systemLogInfo.page = `${this.$route.name} ${this.selectFcltMarkerData.fcltName}`;
      this.$confirm(`${this.selectFcltMarkerData.fcltName} 시설물을 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/fclt/delete`, { data: this.selectFcltMarkerData });
            this.clearFclt();
            this.getFclt();
            this.$message({
              type: 'success',
              message: `${this.selectFcltMarkerData.fcltName} 시설물 삭제 완료`,
            });
            this.systemLogInfo.saveType = 'delete';
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
          } catch (error) {
            this.$message({
              type: 'info',
              message: `${this.selectFcltMarkerData.fcltName} 시설물 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${this.selectFcltMarkerData.fcltName} 시설물 삭제 취소`,
          });
        });
    },
    fcltManagerOnGis(args) {
      let updateFlag = false;
      let geometry = {};
      if (!args.geometry) {
        return;
      }
      let licenseInfo = this.$store.getters['license/getLicense'];
      if (licenseInfo.currentFcltCount >= licenseInfo.limitFcltCount) {
        this.$alert(
          `등록된 ${licenseInfo.currentFcltCount}건의 시설물 정보가 있고, 현재 라이선스는 총 ${licenseInfo.limitFcltCount}건의 시설물이 등록 가능하므로, 유효한 시설물 등록 수를 초과합니다.`,
          '라이선스 등록 초과',
          {
            confirmButtonText: '확인',
            type: 'warning',
          }
        );
        this.$store.dispatch('license/setDialog', true);
        return;
      }
      this.geometry = this.$lodash.cloneDeep(args.geometry);
      this.$refs.formFcltDialog.showDialog(updateFlag);
    },

    incidentOnGis(args) {
      let geometry = {};
      if (!args.geometry) {
        return;
      }
      if (args.feature) {
        this.geometry = this.$lodash.cloneDeep(args.geometry);
        this.$refs.formTrafficIncident.showDialog(args);
      } else {
        this.$message({
          type: 'info',
          message: `선택한 위치에 도로선형이 없습니다`,
        });
      }
    },

    gisMenuSelect(eventName) {
      this[eventName]();
    },
    changeGoogle() {
      this.$refs.dkGis.gisModuleLoad('google');
    },

    changeKakao() {
      this.$refs.dkGis.gisModuleLoad('kakao');
    },

    changeOpenStreetMap() {
      this.$refs.dkGis.gisModuleLoad('openStreetMap');
    },

    setFcltManageOnGis() {
      this.$refs.dkGis.setFcltManageOnGis();
    },

    setTrafficLayer() {
      this.isOnTrafficLayer = this.$refs.dkGis.setTrafficLayer(!this.isOnTrafficLayer);
    },
    // setTrafficSelectMode(){
    //   this.trafficSelectMode = this.$refs.dkGis.setTrafficSelectMode(this.trafficSelectMode);
    // },
    // setIncidentOnGis() {
    //   this.$refs.dkGis.setIncidentOnGis();
    // },

    toggleInfoPanel() {
      this.$refs.fcltInfoPanel.toggle();
      this.isShowInfoPanel = this.$refs.fcltInfoPanel.isOpened();
    },

    changeInfoPanel(el) {
      this.$refs.dkGis.refresh();
    },

    getHumanFileSize(bytes) {
      return DkFileUtil.getHumanFileSize(bytes, true);
    },
    downloadReference(row, event, column) {
      DkFileUtil.downloadFromUrl(row.url, row.name);
    },
    getFcltDataValue(fcltData) {
      let val = fcltData.value;
      if (fcltData.formType == 'combobox') {
        if (fcltData.options) {
          let tempOption = fcltData.options.find((opt) => {
            return opt.value == fcltData.value;
          });
          if (tempOption) {
            val = tempOption.label;
          }
        }
      }
      return val;
    },
    setRoadview() {
      this.$refs.dkGis.setRoadview({
        lat: this.selectFcltMarkerData.gisLat,
        lng: this.selectFcltMarkerData.gisLng,
      });
    },
    hideRoadview() {
      this.$refs.dkGis.hideRoadview();
    },
    kakaoNavi() {
      if (Kakao) {
        Kakao.Navi.start({
          name: this.selectFcltMarkerData.fcltName,
          x: this.selectFcltMarkerData.gisLng,
          y: this.selectFcltMarkerData.gisLat,
          coordType: 'wgs84',
        });
      }
    },
    async apiLoadComplete(currentGisApi) {
      this.currentGisApi = currentGisApi;
      // this.getGisFcltMarker()

      if (this.platformType === 'its') {
        this.isOnTrafficLayer = true;
        this.$refs.dkGis.setTrafficLayer(true);
      } else {
        this.isOnTrafficLayer = false;
        this.$refs.dkGis.setTrafficLayer(false);
      }

      this.getGeolocation();
      try {
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltType`, {});
        this.fcltType = response.data.docs;

        this.searchFcltTypeId = [];
        this.fcltType.forEach((ft) => {
          if (ft.initDisplay) this.searchFcltTypeId.push(ft.fcltTypeId);
        });
        this.getGisFcltMarker();
        this.getStateInfo();
      } catch (error) {
        this.$message({
          type: 'info',
          message: `시설물 타입 로드 에러`,
        });
      }
    },
    // getCoverageArea(){
    //   console.log("asdasd")
    //   alert(123)
    //   this.isOnCoverageArea = this.$refs.dkGis.getFcltCover(!this.isOnCoverageArea);

    // }

    selectCluster(args) {},
    selectClusterOnGrid(args) {
      args.$clusterClickEvent();
    },
    // mouseEnterClusterOnGrid(row, column, cell, event) {
    //   row.$clusterFocusEvent()
    // },
    // mouseLeaveClusterOnGrid(row, column, cell, event) {
    //   row.$clusterBlurEvent()
    // },
    selectPlaceDataGrid(row) {
      this.$refs.dkGis.setCenter({
        lat: row.lat,
        lng: row.lng,
      });
      this.$refs.dkGis.setLevel(4);
    },
    getGeolocation() {
      let me = this;
      if ('geolocation' in navigator) {
        /* 지오로케이션 사용 가능 */
        navigator.geolocation.getCurrentPosition(async (position) => {
          let data = await me.$refs.dkGis.actGeolocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          me.geolocationAddr = data.addr && data.addr.num_addr ? data.addr.num_addr : '정보 없음';
        });
        // this.watchID = navigator.geolocation.watchPosition((position) => {
        //   console.log(position.coords.latitude, position.coords.longitude);

        //   this.$refs.dkGis.setCenter({
        //       lat:position.coords.latitude,
        //       lng:position.coords.longitude
        //   })
        // });
      } else {
        /* 지오로케이션 사용 불가능 */
      }
    },
    checkDisplayFcltType(fcltTypeId) {
      let result = this.searchFcltTypeId.find((ft) => {
        return ft === fcltTypeId;
      });
      return result;
    },
    toggleFcltType(fcltTypeId) {
      this.searchFcltTypeId = this.$lodash.xor(this.searchFcltTypeId, [fcltTypeId]);
      this.$forceUpdate();
      this.getFclt();
      if (this.stateInfo.depth2) {
        this.getStateInfo(this.stateInfo.depth2);
      } else if (this.stateInfo.depth1) {
        this.getStateInfo(this.stateInfo.depth1);
      } else {
        this.getStateInfo();
      }
    },
    async gisCapture() {
      this.loading = this.$loading({
        lock: true,
        text: 'GIS 이미지를 렌더링 중입니다.',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      // let proxyUrl = `${this.$config.getServerConfig().core.api}/html2canvas-proxy`;
      let proxyUrl = `/html2canvas-proxy`;
      let el = document.querySelector('#dkGis');
      let layers = el.children[0].children[0].children[0].children[0];
      layers.children[5].style.zIndex = 100;
      window
        .html2canvas(el, {
          logging: true,
          proxy: proxyUrl,
          allowTaint: false,
        })
        .then((canvas) => {
          var a = document.createElement('a');
          a.href = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
          a.download = 'gis-capture.png';
          a.click();
          this.loading.close();
        })
        .catch((error) => {
          this.loading.close();
          console.log('Erorr descargando reporte visual', error);
        });
    },
    toggleCluster() {
      // this.isSearch = false;
      let zoomLevel = this.$refs.dkGis.getLevel();
      this.gisParam.clusterEnable = !this.gisParam.clusterEnable;
      this.markerData = [];
      this.clusterData = [];
      this.searchPlaceData = [];
      this.loading = this.$loading({
        lock: true,
        text: '클러스터 처리 중입니다',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.$nextTick(async () => {
        await this.getGisFcltMarker();

        setTimeout(() => {
          this.loading.close();
        }, 200);
      });
    },
    checkFcltData() {
      if (this.selectFcltMarkerData.fcltTypeData) return this.selectFcltMarkerData.fcltTypeData;
      else [];
    },
    async getStateInfo(args) {
      let param = args || {};
      param.fcltTypeId = this.searchFcltTypeId;
      const result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fclt/stateInfo`, {
        params: param,
      });
      this.stateInfo = result.data;
    },
    clickStateHome() {
      this.stateDepth.depth1 = null;
      this.stateDepth.depth2 = null;
      this.stateDepth.depth3 = null;
      this.getStateInfo();
    },
    clickState(item) {
      this.stateDepth.depth1 = {
        stateCd: item.stateCd,
        name: item.name,
        lat: item.lat,
        lng: item.lng,
      };
      this.stateDepth.depth2 = null;
      this.stateDepth.depth3 = null;
      this.getStateInfo(item);
      this.$refs.dkGis.setCenter({
        lat: item.lat,
        lng: item.lng,
      });
      setTimeout(() => {
        this.$refs.dkGis.setLevel(10);
      }, 100);
    },

    clickSigungu(item) {
      this.stateDepth.depth2 = {
        sigCd: item.sigCd,
        name: item.name,
        lat: item.lat,
        lng: item.lng,
      };
      this.stateDepth.depth3 = null;
      this.getStateInfo(item);
      this.$refs.dkGis.setCenter({
        lat: item.lat,
        lng: item.lng,
      });
      setTimeout(() => {
        this.$refs.dkGis.setLevel(8);
      }, 100);
    },

    clickEmd(item) {
      this.stateDepth.depth3 = {
        emdCd: item.emdCd,
        name: item.name,
        lat: item.lat,
        lng: item.lng,
      };

      this.$refs.dkGis.setCenter({
        lat: item.lat,
        lng: item.lng,
      });
      setTimeout(() => {
        this.$refs.dkGis.setLevel(5);
      }, 100);
    },
    toggleGisInfoWindow() {
      this.isOpenMarkerPopup = !this.isOpenMarkerPopup;
      this.$store.dispatch('gis/actIsOpenInfoWindow', { value: this.isOpenMarkerPopup });
    },
    zoomEnd(val) {
      if (val.level <= 3) {
        this.$store.dispatch('gis/actIsOpenInfoWindow', { value: true });
      } else {
        if (this.isOpenMarkerPopup) {
          this.$store.dispatch('gis/actIsOpenInfoWindow', { value: true });
        } else {
          this.$store.dispatch('gis/actIsOpenInfoWindow', { value: false });
        }
      }
    },

    toggleLineDraw() {
      if (!this.lineDrawFlag) {
        this.lineDrawFlag = true;
        this.$refs.dkGis.startLineDraw({ getHtml: this.distanceHtml });
      } else {
        this.lineDrawFlag = false;
        this.$refs.dkGis.endLineDraw();
      }
    },

    distanceHtml(distance) {
      return `<div class="distance-wrap"><span class="distance-text">거리</span><span class="distance-count">${distance}</span>m</div>`;
    },

    getCurrentSideTabClass(tab) {
      return this.currentTab === tab ? 'tabClick' : ''
    }
  },
};
</script>
