<template>
  <div class="fill-width-height el-container">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <el-row>
              <el-col :span="24" class="grid-header-menu"></el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form ref="form" :model="formSearch" label-width="120px" size="mini">
                  <el-row>
                    <el-col :span="8">
                      <el-form-item label="진행 상태" prop="incidentStatus">
                        <el-select v-model="formSearch.incidentStatus" placeholder="진행 상태">
                          <el-option v-for="item in incidentStatus" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="돌발 유형" prop="incidentType">
                        <el-select v-model="formSearch.incidentType" placeholder="돌발 유형">
                          <el-option v-for="item in incidentType" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="8">
                      <el-form-item label="통제 여부" prop="incidentRoadCode">
                        <el-select v-model="formSearch.incidentRoadCode" placeholder="통제 여부">
                          <el-option v-for="item in incidentRoadCode" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="사고/돌발 발생일">
                        <el-col :span="11">
                          <el-date-picker
                            type="date"
                            placeholder="검색 시작일"
                            v-model="formSearch.searchStartDate"
                            style="width: 100%;"
                          ></el-date-picker>
                        </el-col>
                        <el-col :span="1" align="center">-</el-col>
                        <el-col :span="11">
                          <el-date-picker placeholder="검색 종료일" v-model="formSearch.searchEndDate" style="width: 100%;"></el-date-picker>
                        </el-col>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-col>
              <el-col :span="2">
                <el-button
                  style="margin-left: 10px; width: 90%; padding-top: 30px; padding-bottom: 30px;"
                  type="primary"
                  @click="getTrafficIncidentLog(1)"
                  >검색</el-button
                >
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="24">
            <el-table
              :data="trafficIncidentLog"
              empty-text="표출할 유지관리 이력이 없습니다"
              size="mini"
              @row-click="selectTrafficIncidentLogRow"
              stripe
            >
              <el-table-column label="도로명" prop="fcltName" width="150">
                <template slot-scope="scope">
                  {{ scope.row.linkData.roadName }}
                </template>
              </el-table-column>
              <el-table-column label="구간" prop="fcltTypeName" width="200">
                <template slot-scope="scope">
                  {{ `${scope.row.linkData.fNodeName} ~ ${scope.row.linkData.tNodeName}` }}
                </template>
              </el-table-column>
              <el-table-column label="사고/돌발 내용 요약" prop="incidentInfo"></el-table-column>
              <el-table-column label="진행 상태" align="center" width="130">
                <template slot-scope="scope">
                  <el-tag :type="getTrafficIncidentStatusType(scope.row.incidentStatus)">
                    {{ getTrafficIncidentStatusText(scope.row.incidentStatus) }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column label="사고/돌발 유형" align="center" width="100">
                <template slot-scope="scope">
                  {{ getTrafficIncidentType(scope.row.incidentType) }}
                </template>
              </el-table-column>
              <el-table-column label="통제 여부" align="center" width="100">
                <template slot-scope="scope">
                  {{ getTrafficIncidentRoadCode(scope.row.incidentRoadCode) }}
                </template>
              </el-table-column>
              <el-table-column label="시작일" align="center" width="160">
                <template slot-scope="scope">{{ getFormatDate(scope.row.occurDateTime) }}</template>
              </el-table-column>
              <el-table-column label="종료일" align="center" width="160">
                <template slot-scope="scope">{{ getFormatDate(scope.row.completeDateTime) }}</template>
              </el-table-column>
            </el-table>
            <dk-el-pagination :totalDocs="totalDocs" :limit="limit" :pagingProc="pagingProc" ref="pagination"></dk-el-pagination>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import dkelpagination from '@/components/dkElPagination.vue';
import moment from 'moment';

export const incidentType = [
  { value: '1', label: '공사' },
  { value: '2', label: '사고' },
  { value: '3', label: '행사' },
  { value: '4', label: '집회' },
  { value: '5', label: '자연재해' },
];

export const incidentVehicle = [
  { value: '0', label: '추가정보 필요없음' },
  { value: '1', label: '추가정보 필요' },
  { value: '2', label: '자전거/오토바이' },
  { value: '3', label: '승용차' },
  { value: '4', label: '버스' },
  { value: '5', label: '트럭' },
  { value: '6', label: '트레일러' },
];

export const incidentStatus = [
  { value: '1', label: '진행 중' },
  { value: '2', label: '완료' },
];

export const incidentRoadCode = [
  { value: '0', label: '없음' },
  { value: '1', label: '부분통제' },
  { value: '2', label: '전체통제' },
  { value: '3', label: '도로폐쇄' },
];

export const plannedRoadClosure = [
  { value: '0', label: '없음' },
  { value: '1', label: '기타' },
  { value: '2', label: '장기공사' },
  { value: '3', label: '단기공사' },
  { value: '4', label: '유지보수' },
  { value: '5', label: '긴급유지보수' },
];

export default {
  components: {
    'dk-el-pagination': dkelpagination,
  },
  data() {
    return {
      incidentType: incidentType,
      incidentRoadCode: incidentRoadCode,
      incidentVehicle: incidentVehicle,
      incidentStatus: incidentStatus,
      plannedRoadClosure: plannedRoadClosure,
      limit: 12,
      totalDocs: 0,
      trafficIncidentLog: [],

      formSearch: {
        incidentStatus: null,
        incidentType: null,
        incidentRoadCode: null,
        startDate: moment().add('year', -1).set('hour', 0).set('minute', 0).set('second', 0).toDate(),
        endDate: moment().set('hour', 23).set('minute', 59).set('second', 59).toDate(),
      },
    };
  },
  created() {
    this.getTrafficIncidentLog(1);
    this.$store.dispatch('fcltType/getFcltType', {}).then(() => {
      this.fcltType = this.$store.getters['fcltType/getFcltType'];
    });
  },
  methods: {
    getFormatDate(date) {
      if (date) return moment(date).format('YYYY-MM-DD LT');
      else return '';
    },
    async getTrafficIncidentLog(pageNum) {
      let param = {
        page: pageNum || this.$refs.pagination.getPage(),
        limit: this.limit,
      };
      if (this.formSearch.incidentStatus) param.incidentStatus = this.formSearch.incidentStatus;
      if (this.formSearch.incidentType) param.incidentType = this.formSearch.incidentType;
      if (this.formSearch.incidentRoadCode) param.incidentRoadCode = this.formSearch.incidentRoadCode;

      if (this.formSearch.searchStartDate) {
        param.searchStartDate = this.formSearch.searchStartDate.getTime();
      }
      if (this.formSearch.searchEndDate) {
        // param.endDate = this.formSearch.endDate.getTime()
        param.searchEndDate = moment(this.formSearch.searchEndDate).set('hour', 23).set('minute', 59).set('second', 59).toDate().getTime();
      }
      const res = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/trafficIncident`, {
        params: param,
      });
      this.trafficIncidentLog = res.data.docs;

      this.totalDocs = res.data.totalDocs;
    },
    selectTrafficIncidentLogRow(row) {},
    pagingProc() {
      this.getTrafficIncidentLog();
    },
    async downloadExportExcel() {
      let headerData = [
        { key: 'fcltTypeName', label: '시설물 유형' },
        { key: 'fcltName', label: '시설물 명칭' },
        { key: 'maintnceSummary', label: '작업 내용 요약' },
        { key: 'maintnceDesc', label: '작업 내용 설명' },
        { key: 'status', label: '진행 상태' },
        { key: 'workType', label: '작업 유형' },
        { key: 'importance', label: '중요도' },
        { key: 'startDate', label: '시작 일시' },
        { key: 'endDate', label: '종료 일시' },
        { key: 'managerName', label: '담당자' },
        { key: 'workerName', label: '작업자' },
      ];
      try {
        let param = {};

        if (this.formSearch.selectFcltTypeId && this.formSearch.selectFcltTypeId.length > 0) param.fcltTypeId = this.formSearch.selectFcltTypeId;
        if (this.formSearch.selectMaintnceSummary && this.formSearch.selectMaintnceSummary.length > 0)
          param.status = this.formSearch.selectMaintnceSummary;
        if (this.formSearch.searchStartDate) param.searchStartDate = this.formSearch.searchStartDate.getTime();
        if (this.formSearch.searchEndDate) {
          // param.endDate = this.formSearch.endDate.getTime()
          param.searchEndDate = moment(this.formSearch.searchEndDate).set('hour', 23).set('minute', 59).set('second', 59).toDate().getTime();
        }

        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/maintnceLog/excel`, {
          params: param,
        });
        var header = [];
        var label = {};
        var data = [];
        for (var i = 0; i < headerData.length; i++) {
          header.push({
            key: i,
            name: headerData[i].key,
          });
          label[headerData[i].key] = headerData[i].label;
        }
        data.push(label);
        var newData = this.$lodash.union(data, response.data.docs);
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(newData, header);
        ws['!rows'] = [];
        ws['!rows'][0] = { hidden: true };
        XLSX.utils.book_append_sheet(wb, ws);
        XLSX.writeFile(wb, `${moment().format('YYYY-MM-DD')}_유지관리이력.xlsx`, { bookType: 'xlsx', type: 'array' });
      } catch (err) {
        console.error(err);
      }
    },
    getTrafficIncidentStatusType(status) {
      if (status === '1') {
        return 'danger';
      } else if (status === '2') {
        return 'success';
      } else {
        return 'warning';
      }
    },
    getTrafficIncidentStatusText(status) {
      let r = this.incidentStatus.find((s) => {
        return s.value === status;
      });
      if (r) return r.label;
      else '';
    },
    getTrafficIncidentType(value) {
      const r = this.incidentType.find((s) => {
        return s.value === value;
      });
      if (r) return r.label;
      else '';
    },

    getTrafficIncidentRoadCode(value) {
      const r = this.incidentRoadCode.find((s) => {
        return s.value === value;
      });
      if (r) return r.label;
      else '';
    },
  },
};
</script>

<style scoped></style>
