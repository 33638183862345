export default class Page {
  constructor(axiosPath, logPath) {
    this._startDate = ''
    this._endDate = ''
    this._axiosPath = axiosPath
    this._logPath = logPath
    // this.initStartDate().checkTranslate().initEndDate().logTranslate()

    console.log(this.initStartDate().checkTranslate().initEndDate().logTranslate())
  }

  initStartDate() {
    console.log(new Date().getTime())
    this._startDate = new Date().getTime()

    return this
  }

  initEndDate() {
    console.log(new Date().getTime())
    this._endDate = new Date().getTime()

    return this
  }

  getdiff() {
    return this._endDate - this._startDate
  }


  get logPath() {
    return this._logPath;
  }

  set logPath(value) {
    this._logPath = value;
  }

  get endDate() {
    return this._endDate;
  }

  set endDate(value) {
    this._endDate = value;
  }

  get axiosPath() {
    return this._axiosPath;
  }

  set axiosPath(value) {
    this._axiosPath = value;
  }

  get startDate() {
    return this._startDate;
  }

  set startDate(value) {
    this._startDate = value;
  }

  checkTranslate() {
    new Promise((resolve, reject) => {
      axios.post(this._axiosPath).then(() => {
        resolve()
      }).catch(() => {
        reject()
      })
    }).then(() => {
      console.log('success Translate')
      return 1
    }).catch(() => {
      console.log('failed Translate')
      return 0
    })
    return this
  }

  logTranslate() {
    return new Promise((resolve, reject) => {
      axios.post(this._logPath).then(() => {
        resolve()
      }).catch(() => {
        reject()
      })
    }).then(() => {
      console.log("success")
    }).catch(() => {
      console.log("error")
    })

  }
}
