const sampleData = {
    "docs": [
        {
            "_id": "60ab5f0dcc1677455937685b",
            "writerId": "asdf",
            "worker": "작성자12",
            "workTime": "오후조",
            "occurDate": "1970-01-02T04:36:40.220Z",
            "fcltId": " cctvId",
            "controlType": "사건사고 관제",
            "controlTypeSubClass": "경범죄",
            "reportingFrom": "기타",
            "etcReportingAgency": "기타사항",
            "isOrder": true,
            "orderNum": 124342,
            "Content": "내용이 들어갈 곳내용이 들어갈 곳내용이 들어갈 곳내용이 들어갈 곳내용이 들어갈 곳",
            "file": [
                {
                    "name": "모바일시설물클릭 네비 선택.png",
                    "serverFileName": "1611051932217.png",
                    "serverFilePath": "/images//1611051932217.png",
                    "size": 185412,
                    "url": "/images//1611051932217.png"
                },
                {
                    "name": "모바일시설물클릭 네비 선택2.png",
                    "serverFileName": "1611051932217.png",
                    "serverFilePath": "/images//1611051932217.png",
                    "size": 185412,
                    "url": "/images//1611051932217.png"
                }
            ]
        },
        {
            "_id": "60ab5f09cc16774559376845",
            "writerId": "asdf",
            "worker": "작성자",
            "workTime": "오후조",
            "occurDate": "1970-01-01T05:36:40.220Z",
            "fcltId": " cctvId",
            "controlType": "사건사고 관제",
            "controlTypeSubClass": "경범죄",
            "reportingFrom": "소방",
            "etcReportingAgency": "",
            "isOrder": true,
            "orderNum": 124342,
            "Content": "내용이 들어갈 곳",
            "file": [
                {
                    "name": "모바일시설물클릭 네비 선택.png",
                    "serverFileName": "1611051932217.png",
                    "serverFilePath": "/images//1611051932217.png",
                    "size": 185412,
                    "url": "/images//1611051932217.png"
                }
            ]
        },
        {
            "_id": "60ab5f03cc1677455937681e",
            "writerId": "asdf",
            "worker": "작성자",
            "workTime": "오후조",
            "occurDate": "1970-01-01T05:36:40.220Z",
            "fcltId": " cctvId",
            "controlType": "사건사고관제",
            "controlTypeSubClass": "경범죄",
            "reportingFrom": "소방",
            "etcReportingAgency": "",
            "isOrder": true,
            "orderNum": 124342,
            "Content": "내용이 들어갈 곳",
            "file": [
               
            ]
        },
        {
            "_id": "60ab5efbcc167745593767e8",
            "writerId": "asdf",
            "worker": "작성자",
            "workTime": "오후조",
            "occurDate": "1970-01-01T05:36:40.220Z",
            "fcltId": " cctvId",
            "controlType": "사건사고관제",
            "controlTypeSubClass": "경범죄",
            "reportingFrom": "소방",
            "etcReportingAgency": "",
            "isOrder": true,
            "orderNum": 124342,
            "Content": "내용이 들어갈 곳",
            "file": [
                {
                    "name": "모바일시설물클릭 네비 선택.png",
                    "serverFileName": "1611051932217.png",
                    "serverFilePath": "/images//1611051932217.png",
                    "size": 185412,
                    "url": "/images//1611051932217.png"
                }
            ]
        },
        {
            "_id": "60ab5ef5cc167745593767d2",
            "writerId": "asdf",
            "worker": "작성자",
            "workTime": "오후조",
            "occurDate": "1970-01-01T05:36:40.220Z",
            "fcltId": " cctvId",
            "controlType": "사건사고관제",
            "controlTypeSubClass": "경범죄",
            "reportingFrom": "소방",
            "etcReportingAgency": "",
            "isOrder": true,
            "orderNum": 124342,
            "Content": "내용이 들어갈 곳",
            "file": [
                {
                    "name": "모바일시설물클릭 네비 선택.png",
                    "serverFileName": "1611051932217.png",
                    "serverFilePath": "/images//1611051932217.png",
                    "size": 185412,
                    "url": "/images//1611051932217.png"
                }
            ]
        },
        {
            "_id": "60ab5eefcc167745593767b1",
            "writerId": "asdf",
            "worker": "작성자",
            "workTime": "오후조",
            "occurDate": "1970-01-01T05:36:40.220Z",
            "fcltId": " cctvId",
            "controlType": "사건사고관제",
            "controlTypeSubClass": "경범죄",
            "reportingFrom": "소방",
            "etcReportingAgency": "",
            "isOrder": true,
            "orderNum": 124342,
            "Content": "내용이 들어갈 곳",
            "file": [
                {
                    "name": "모바일시설물클릭 네비 선택.png",
                    "serverFileName": "1611051932217.png",
                    "serverFilePath": "/images//1611051932217.png",
                    "size": 185412,
                    "url": "/images//1611051932217.png"
                }
            ]
        },
        {
            "_id": "60ab5ee6cc1677455937677e",
            "writerId": "asdf",
            "worker": "작성자",
            "workTime": "오후조",
            "occurDate": "1970-01-01T05:36:40.220Z",
            "fcltId": " cctvId",
            "controlType": "사건사고관제",
            "controlTypeSubClass": "경범죄",
            "reportingFrom": "소방",
            "etcReportingAgency": "",
            "isOrder": true,
            "orderNum": 124342,
            "Content": "내용이 들어갈 곳",
            "file": [
                {
                    "name": "모바일시설물클릭 네비 선택.png",
                    "serverFileName": "1611051932217.png",
                    "serverFilePath": "/images//1611051932217.png",
                    "size": 185412,
                    "url": "/images//1611051932217.png"
                }
            ]
        },
        {
            "_id": "60ab5ee0cc1677455937675b",
            "writerId": "asdf",
            "worker": "작성자",
            "workTime": "오후조",
            "occurDate": "1970-01-01T05:36:40.220Z",
            "fcltId": " cctvId",
            "controlType": "사건사고관제",
            "controlTypeSubClass": "경범죄",
            "reportingFrom": "소방",
            "etcReportingAgency": "",
            "isOrder": true,
            "orderNum": 124342,
            "Content": "내용이 들어갈 곳",
            "file": [
                {
                    "name": "모바일시설물클릭 네비 선택.png",
                    "serverFileName": "1611051932217.png",
                    "serverFilePath": "/images//1611051932217.png",
                    "size": 185412,
                    "url": "/images//1611051932217.png"
                }
            ]
        },
        {
            "_id": "60ab5ed9cc16774559376743",
            "writerId": "asdf",
            "worker": "작성자",
            "workTime": "오후조",
            "occurDate": "1970-01-01T05:36:40.220Z",
            "fcltId": " cctvId",
            "controlType": "사건사고관제",
            "controlTypeSubClass": "경범죄",
            "reportingFrom": "소방",
            "etcReportingAgency": "",
            "isOrder": true,
            "orderNum": 124342,
            "Content": "내용이 들어갈 곳",
            "file": [
                {
                    "name": "모바일시설물클릭 네비 선택.png",
                    "serverFileName": "1611051932217.png",
                    "serverFilePath": "/images//1611051932217.png",
                    "size": 185412,
                    "url": "/images//1611051932217.png"
                }
            ]
        },
        {
            "_id": "60ab5ed1cc1677455937670e",
            "writerId": "asdf",
            "worker": "작성자",
            "workTime": "오후조",
            "occurDate": "1970-01-01T05:36:40.220Z",
            "fcltId": " cctvId",
            "controlType": "사건사고관제",
            "controlTypeSubClass": "경범죄",
            "reportingFrom": "소방",
            "etcReportingAgency": "",
            "isOrder": true,
            "orderNum": 124342,
            "Content": "내용이 들어갈 곳",
            "file": [
                {
                    "name": "모바일시설물클릭 네비 선택.png",
                    "serverFileName": "1611051932217.png",
                    "serverFilePath": "/images//1611051932217.png",
                    "size": 185412,
                    "url": "/images//1611051932217.png"
                }
            ]
        }
    ],
    "totalDocs": 12,
    "limit": 10,
    "totalPages": 2,
    "page": 1,
    "pagingCounter": 1,
    "hasPrevPage": false,
    "hasNextPage": true,
    "prevPage": null,
    "nextPage": 2
}
  
export default sampleData