<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
  >
    <el-form
      :model="formData"
      label-width="160px"
      size="medium"
      :rules="rules"
      ref="form0"
      :label-position="$isMobile ? 'top' : 'right'"
    >
      <el-row>
        <el-col :span="18">
          <el-form-item label="VMS 라이브러리 이름" prop="title">
            <el-input v-model="formData.vmsLibraryName" placeholder="VMS 라이브러리 이름을 입력하세요"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="18">
          <el-form-item label="VMS 카테고리" prop="title">
            <el-select v-model="formData.vmsCategory" placeholder="센터 장비 유형을 선택하세요">
              <el-option label="교통" value="traffic"></el-option>
              <el-option label="그외" value="etc"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="18">
          <el-form-item label="관련사진">
            <dk-el-file-upload
              ref="uploadImage"
              uploaderType="image"
              listType="picture"
              path="vmsLibrary"
              :fileList="formData.images"
              :checkUploadFile="checkUploadFile"
              :limit="1"
            >
              <div style="text-align: left;">
                <el-button size="small" type="primary">관련사진 업로드</el-button>
                <div slot="tip" class="el-upload__tip">이미지 파일만 업로드할 수 있습니다</div>
              </div>
            </dk-el-file-upload>
          </el-form-item>
        </el-col>

        <el-col :span="18">
          <el-form-item label="이미지 유형" prop="title">{{ formData.imageType }}</el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">저장</el-button>
      <el-button @click="hideDialog">취소</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dkElFileUpload from '@/components/dkElFileUpload.vue';

export default {
  components: {
    'dk-el-file-upload': dkElFileUpload,
  },
  data() {
    return {
      formDialog: false,
      formTitle: '',
      formData: {
        vmsLibraryId: null,
        vmsLibraryName: '',
        vmsCategory: '',
        images: [],
        imageType: '',
      },
      rules: {
        vmsLibraryName: [{ required: true, message: 'VMS 라이브러리 이름을 입력해 주세요', trigger: 'blur' }],
        vmsCategory: [{ required: true, message: 'VMS 유형을 선택해 주세요', trigger: 'blur' }],
      },
    };
  },
  methods: {
    save() {
      this.$refs.form0.validate(async (valid) => {
        let me = this;
        if (valid) {
          this.formData.images = this.$refs.uploadImage.getFileData();
          let saveFunc = '/core/api/vmsLibrary/add';
          if (this.updateFlag) {
            saveFunc = '/core/api/vmsLibrary/update';
          }
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}${saveFunc}`, {
              data: this.formData,
            });
            me.$parent.$emit('procDataComplete', this.formData);
            me.resetData();
            me.hideDialog();
            me.$message({
              type: 'success',
              message: `VMS 라이브러리 등록 완료`,
            });
          } catch (err) {
            me.resetData();
            me.hideDialog();
            me.$message.error(`VMS 라이브러리 등록 중 에러 발생`);
          }
        } else {
          return false;
        }
      });
    },
    showDialog(row) {
      if (row) {
        this.updateFlag = true;
        this.formTitle = 'VMS 라이브러리 수정';
        this.formData = this.$lodash.cloneDeep(row);
      } else {
        this.updateFlag = false;
        this.formTitle = 'VMS 라이브러리 추가';
        this.resetData();
      }
      this.formDialog = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        if (row) {
          this.$refs.uploadImage.setFileData(row.images);
        }
      });
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    resetData() {
      this.formData = {
        vmsLibraryId: null,
        vmsLibraryName: '',
        vmsCategory: '',
        images: [],
        imageType: '',
      };
    },
    checkUploadFile(file) {
      this.formData.imageType = file.type;
      console.log(this.formData.imageType);
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
.editor-canvas {
  width: 100%;
  height: auto;
}
</style>
