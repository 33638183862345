<template>
  <el-dialog :title="formTitle" :visible.sync="formDialog" @close="hideDialog" :close-on-click-modal="false" top="5vh" id="formConfigure">
    <div>
      <el-form :model="formData" label-width="120px" size="medium" ref="form0" :label-position="$isMobile ? 'top' : 'right'">
        <el-tabs :tab-position="tabPosition" style="height: 600px;">
          <el-tab-pane label="정보 수정">
            <el-col :sm="24">
              <h3>기관 정보 입력</h3>
            </el-col>
            <el-col :md="18" :sm="24">
              <el-form-item
                label="기관명"
                prop="orgName"
                :rules="[
                  {
                    required: true,
                    message: '기관 명을 입력해 주세요',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model="formData.orgName" placeholder="기관명"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="18" :sm="24">
              <el-form-item label="플랫폼 타이틀" prop="name">
                <el-input v-model="formData.title" placeholder="타이틀"></el-input>
                <span>플랫폼 이용시 웹브라우저 상단에 노출되는 타이틀명을 입력합니다.</span>
              </el-form-item>
            </el-col>
            <el-col :md="18" :sm="24">
              <el-form-item label="센터 연락처" prop="phone">
                <el-input v-model="formData.phone" placeholder="연락처"></el-input>
              </el-form-item>
            </el-col>

            <!-- <el-col :md="18" :sm="24">
              <el-form-item label="OPEN GIS 정보">
                <el-radio-group v-model="formData.gis.gisName" @change="changeGisApi">
                  <el-radio-button label="kakao">카카오</el-radio-button> -->
            <!-- <el-radio-button label="openStreetMap" disabled>OSM</el-radio-button>
                        <el-radio-button label="vworld" disabled >VWorld</el-radio-button>
                  <el-radio-button label="google" disabled >구글</el-radio-button>-->
            <!-- <el-radio-button label="none">사용안함</el-radio-button>
                </el-radio-group>
                <el-button @click="poiRefresh">POI 재생성</el-button>
              </el-form-item>
            </el-col> -->
            <el-col :md="18" :sm="24" v-if="checkGisApi">
              <el-form-item
                label="GIS API 키"
                prop="gis.apiKey"
                :rules="[
                  {
                    required: true,
                    message: '선택한 OpenGIS API의 API KEY를 입력해 주세요',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model="formData.gis.apiKey" placeholder="Open GIS API KEY"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="22" :sm="24" v-show="checkGisApi" class="el-col-md-offset-1 el-col-sm-offset-0">
              <span>GIS 기본 위치를 설정합니다. 마우스 휠+드래그를 이용하여 원하시는 위치와 스케일을 설정하여 주세요.</span>
              <dk-gis
                ref="dkgis"
                style="height: 350px;"
                :gisApiInfo="gisApiInfo"
                :centerChanged="centerChanged"
                :apiLoadComplete="apiLoadComplete"
              ></dk-gis>
            </el-col>
          </el-tab-pane>
          <el-tab-pane label="이미지 업로드">
            <el-col :sm="24">
              <h3>이미지 업로드</h3>
            </el-col>
            <el-col :sm="24" style="position: relative;">
              <!-- <img src="../../assets/img/logobg.png" /> -->
              <el-form-item label="플랫폼 로고 이미지" label-width="130px" label-position="left">
                <div class="logo-enroll-bg w-100"></div>
                <el-upload
                  :action="uploadPath"
                  name="uploadFile"
                  :data="{ uploadPath: 'logo' }"
                  :before-upload="checkUploadImage"
                  :on-change="procUploadImage"
                  :on-remove="procRemoveImage"
                  :file-list="formData.logo"
                  :show-file-list="false"
                  class="avatar-uploader"
                  ref="uploadLogo"
                >
                  <img v-if="imageUrl" :src="$config.getServerConfig().image.url + imageUrl" class="avatar" />
                  <span v-else>
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </span>
                </el-upload>
                <div class="logo-about">
                  <div>플랫폼 상단에 표출할 로고 이미지입니다.</div>
                  <div>최적화 사이즈(123X123) / 파일 형식 (png, jpg, gif)</div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :sm="24" style="position: relative;">
              <!-- <img src="../../assets/img/logobg.png" /> -->
              <el-form-item label="모바일 커버 이미지" label-width="130px" label-position="left">
                <!-- <div class="logo-enroll-bg w-100"></div> -->
                <div class="w-100 mb-img">
                  <el-upload
                    :action="uploadPath"
                    name="uploadFile"
                    :data="{ uploadPath: 'mobile' }"
                    :before-upload="checkUploadImage"
                    :on-change="mobileProcUploadImage"
                    :on-remove="procRemoveImage"
                    :file-list="formData.mobile"
                    :show-file-list="false"
                    class="avatar-uploader avatar-uploader-mb"
                    ref="uploadMobile"
                  >
                    <img v-if="mobileImageUrl" :src="$config.getServerConfig().image.url + mobileImageUrl" class="avatar" />
                    <span v-else>
                      <i class="el-icon-plus avatar-uploader-icon"></i>
                    </span>
                  </el-upload>
                </div>
                <div class="logo-about">
                  <div>플랫폼의 모바일 메인 페이지 커버 이미지입니다.</div>
                  <div>최적화 사이즈(460X350) / 파일 형식 (png, jpg, gif)</div>
                </div>
              </el-form-item>
            </el-col>
          </el-tab-pane>
        </el-tabs>
      </el-form>
      <div class="form-bottom">
        <el-button @click="hideDialog" style="float: right;">취소</el-button>
        <el-button type="primary" @click="save" style="float: right; margin-right: 5px;">저장</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import userAxios from '@/components/module/userAxios';
import dkgismain from '@/components/dkGis/dkGisMain.vue';
import DkFileUtil from '@/components/dkFileUtil.js';

export default {
  components: {
    'dk-gis': dkgismain,
  },
  data() {
    var checkApiKey = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('선택한 OpenGIS API의 API KEY를 입력해 주세요'));
      } else {
        callback();
      }
    };
    return {
      systemLogInfo: {},
      tabPosition: 'left',
      imageUrl: '',
      mobileImageUrl: '',
      gisInit: false,
      formDialog: false,
      formTitle: '',
      tempUploadImages: [],
      mobileTempUploadImages: [],
      uploadPath: `${this.$config.getServerConfig().core.api}/core/api/file/uploadFile`,
      formData: {
        configureId: '',
        orgName: '',
        logo: [],
        mobileCoverImage: [],
        gis: {
          gisName: 'kakao',
          apiKey: '',
          geometry: {
            lng: 127.0989949,
            lat: 37.4886317,
          },
        },
        title: '',
        anchor: '',
        phone: '',
        install: {},
      },
      rules: {
        orgName: [],
        apiKey: [
          {
            required: true,
            message: '선택한 OpenGIS API의 API KEY를 입력해 주세요',
            trigger: 'blur',
          },
        ],
      },
      gisApiInfo: [
        {
          gisName: 'kakao',
          apiKey: '9ef4e2b28db0934aa702f4086d97f1cd',
          geometry: {
            lng: 127.0989949,
            lat: 37.4886317,
          },
          level: 5,
          gisOption: {
            libraries: 'services,drawing',
          },
          initGis: true,
        },
      ],
    };
  },
  created() {
    this.resetData();
  },
  computed: {
    checkGisApi() {
      if (this.formData.gis.gisName === 'none' || this.formData.gis.gisName === '') {
        return false;
      } else {
        return true;
      }
    },
  },
  updated() {
    this.$nextTick(() => {
      if (this.gisInit) {
        return;
      }
      if (this.formDialog && this.$refs.dkgis) {
        this.$refs.dkgis.gisModuleLoad('kakao');
        this.gisInit = true;
      }
    });
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.mobileImageUrl = URL.createObjectURL(file.raw);
    },

    procUploadImage(file, fileList) {
      // if(this.tempUploadImages === )
      this.tempUploadImages = [];
      let fl = fileList[fileList.length - 1];
      console.log(fl);
      // fileList.forEach((fl) => {
      if (fl.status === 'success') {
        if (fl.response) {
          this.tempUploadImages.push({
            name: fl.name,
            serverFileName: fl.response.result.serverFileName,
            serverFilePath: fl.response.result.serverFilePath,
            size: fl.size,
            url: `${fl.response.result.serverFilePath}`,
          });
          this.imageUrl = URL.createObjectURL(fl.raw);
        } else {
          this.tempUploadImages.push(fl);
        }
      }
      // });
    },

    mobileProcUploadImage(file, fileList) {
      this.mobileTempUploadImages = [];
      let fl = fileList[fileList.length - 1];
      console.log(fl);
      // fileList.forEach((fl) => {
      if (fl.status === 'success') {
        if (fl.response) {
          this.mobileTempUploadImages.push({
            name: fl.name,
            serverFileName: fl.response.result.serverFileName,
            serverFilePath: fl.response.result.serverFilePath,
            size: fl.size,
            url: `${fl.response.result.serverFilePath}`,
          });
          this.mobileImageUrl = URL.createObjectURL(fl.raw);
        } else {
          this.mobileTempUploadImages.push(fl);
        }
      }
      // });
    },

    save() {
      this.$refs.form0.validate(async (valid) => {
        try {
          let me = this;
          if (valid) {
            me.formData.logo = me.tempUploadImages;
            me.formData.mobileCoverImage = me.mobileTempUploadImages;
            let saveFunc = 'configure/add';
            if (this.updateFlag) {
              saveFunc = 'configure/update';
            }
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, { data: me.formData });
            me.$parent.$emit('procDataComplete', this.formData);
            me.resetData();
            me.hideDialog();
            me.$message({
              type: 'success',
              message: `기관 정보 저장 완료`,
            });

            this.systemLogInfo.page = this.$route.name;
            this.systemLogInfo.saveType = saveFunc;
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
          } else {
            return false;
          }
        } catch (error) {
          this.resetData();
          this.hideDialog();
          this.$message.error(`기관 정보 저장 중 에러 발생`);
        }
      });
    },
    showDialog(configure) {
      if (configure) {
        if (configure.logo && configure.logo.length > 0 && configure.logo[0]) {
          this.imageUrl = `${configure.logo[0].serverFilePath}`;
        }
        if (configure.mobileCoverImage && configure.mobileCoverImage.length > 0 && configure.mobileCoverImage[0]) {
          this.mobileImageUrl = `${configure.mobileCoverImage[0].serverFilePath}`;
        }
        this.updateFlag = true;
        this.formTitle = '기관 정보 수정';
        this.formData = this.$lodash.cloneDeep(configure);
        this.tempUploadImages = configure.logo;
        this.mobileTempUploadImages = configure.mobileCoverImage;
      } else {
        this.formTitle = '기관 정보 등록';
      }
      this.formDialog = true;
      this.$forceUpdate();
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    resetData() {
      this.formData = {
        configureId: '',
        orgName: '',
        logo: [],
        gis: {
          gisName: 'kakao',
          apiKey: '',
          geometry: {
            lng: 127.0989949,
            lat: 37.4886317,
          },
        },
        title: '',
        anchor: '',
        phone: '',
        install: {},
      };
    },
    procRemoveImage(file, fileList) {
      this.tempUploadImages = [];
      fileList.forEach((fl) => {
        this.tempUploadImages.push(fl);
      });
    },
    procUploadPcFile(file, fileList) {
      const me = this;
      me.tempUploadPc = [];
      let fl = fileList[fileList.length - 1];

      if (fl.status === 'success') {
        if (fl.response) {
          this.tempUploadPc.push({
            name: fl.name,
            serverFileName: fl.response.result.serverFileName,
            serverFilePath: fl.response.result.serverFilePath,
            size: fl.size,
            url: `${fl.response.result.serverFilePath}`,
          });
          me.pcFileName = fl.name;
        } else {
          this.tempUploadPc.push(fl);
        }
      }
    },
    procRemovePcFile(file, fileList) {
      this.tempUploadPc = [];
      fileList.forEach((fl) => {
        this.tempUploadPc.push(fl);
      });
    },
    procUploadMobileFile(file, fileList) {
      const me = this;
      me.tempUploadMobile = [];
      let fl = fileList[fileList.length - 1];

      if (fl.status === 'success') {
        if (fl.response) {
          this.tempUploadMobile.push({
            name: fl.name,
            serverFileName: fl.response.result.serverFileName,
            serverFilePath: fl.response.result.serverFilePath,
            size: fl.size,
            url: `${fl.response.result.serverFilePath}`,
          });
          me.mobileFileName = fl.name;
        } else {
          this.tempUploadMobile.push(fl);
        }
      }
    },
    procRemoveMobileFile(file, fileList) {
      this.tempUploadMobile = [];
      fileList.forEach((fl) => {
        this.tempUploadMobile.push(fl);
      });
    },
    checkUploadImage(file) {
      if (!DkFileUtil.isImage(file.name)) {
        this.$message.error(`이미지 파일만 업로드 할 수 있습니다.`);
        return false;
      }
    },
    changeGisApi() {
      this.formData.gis.apiKey = '';
    },
    centerChanged(args) {
      this.formData.gis.geometry = args.geometry;
      this.formData.gis.level = args.level;
    },
    apiLoadComplete() {
      if (this.updateFlag) {
        this.$refs.dkgis.setCenter(this.formData.gis.geometry);
        this.$refs.dkgis.setLevel(this.formData.gis.level);
      }
    },
    async poiRefresh() {
      await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/gis/testGisFclt`);
    },
  },
};
</script>
<style scoped></style>
