var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    {
      ref: "gisContainer",
      staticClass: "fill-width-height",
      attrs: { id: "aiDashboard" }
    },
    [
      _c("section", { staticClass: "dashboard-grid" }, [
        _c(
          "div",
          { staticClass: "dashboard-grid__item" },
          [
            _c("div", { staticClass: "gis-toolbar" }, [
              _c("ul", { staticClass: "gis-toolbar-menu" }, [
                _c(
                  "li",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "fclt-btn",
                        attrs: { size: "small" },
                        on: { click: _vm.setFcltManageOnGis }
                      },
                      [
                        _c("i", { staticClass: "fas fa-map-marker-alt" }),
                        _vm._v("AI카메라 추가 ")
                      ]
                    )
                  ],
                  1
                )
              ])
            ]),
            _c("dk-gis", {
              ref: "dkGis",
              staticClass: "ai-dashboard-gis",
              attrs: {
                "fill-height": "",
                gisApiInfo: _vm.gisApiInfo,
                markerData: _vm.markerData,
                clickMarker: _vm.clickMarker,
                fcltManagerOnGis: _vm.fcltManagerOnGis,
                apiLoadComplete: _vm.apiLoadComplete,
                id: "dkGis"
              },
              on: { dkgisMounted: _vm.dkgisMounted }
            })
          ],
          1
        ),
        !_vm.selectFcltMarkerData.fcltId
          ? _c(
              "div",
              {
                staticClass: "dashboard-grid__item",
                attrs: { id: "seoulAreaMap" }
              },
              [
                _c("div", { staticClass: "image-background" }, [
                  _c("span", { staticClass: "seoularea-title" }, [
                    _vm._v("서울시 한강공원 출입 현황")
                  ]),
                  _c("span", { staticClass: "seoularea-date" }, [
                    _vm._v(_vm._s(_vm.today()))
                  ]),
                  _c(
                    "div",
                    {
                      class: [
                        "banpo__marker",
                        "hangang-marker",
                        _vm.selectParkId === "banpo" ? "on" : ""
                      ],
                      on: {
                        click: function($event) {
                          return _vm.selectPark("banpo")
                        }
                      }
                    },
                    [
                      _c("h5", [_vm._v("반포한강공원")]),
                      _c("div", { staticClass: "marker-body" }, [
                        _c("div", { staticClass: "marker-body__item" }, [
                          _c("i", { staticClass: "fas fa-user-alt" }),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.parkEntranceData.banpo["1"].human)
                              )
                            ]),
                            _vm._v("\n                /\n                "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.parkEntranceData.banpo["0"].human)
                              )
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "marker-body__item" }, [
                          _c("i", { staticClass: "fas fa-bicycle" }),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.parkEntranceData.banpo["1"].bicycle)
                              )
                            ]),
                            _vm._v(" / "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.parkEntranceData.banpo["0"].bicycle)
                              )
                            ])
                          ])
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    {
                      class: [
                        "jamwon__marker",
                        "hangang-marker",
                        _vm.selectParkId === "jamwon" ? "on" : ""
                      ],
                      on: {
                        click: function($event) {
                          return _vm.selectPark("jamwon")
                        }
                      }
                    },
                    [
                      _c("h5", [_vm._v("잠원한강공원")]),
                      _c("div", { staticClass: "marker-body" }, [
                        _c("div", { staticClass: "marker-body__item" }, [
                          _c("i", { staticClass: "fas fa-user-alt" }),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.parkEntranceData.jamwon["1"].human)
                              )
                            ]),
                            _vm._v(" / "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.parkEntranceData.jamwon["0"].human)
                              )
                            ])
                          ])
                        ]),
                        _c("div", { staticClass: "marker-body__item" }, [
                          _c("i", { staticClass: "fas fa-bicycle" }),
                          _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.parkEntranceData.jamwon["1"].bicycle)
                              )
                            ]),
                            _vm._v(" / "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.parkEntranceData.jamwon["0"].bicycle)
                              )
                            ])
                          ])
                        ])
                      ])
                    ]
                  )
                ])
              ]
            )
          : _vm._e(),
        _vm.selectFcltMarkerData.fcltId
          ? _c(
              "div",
              {
                staticClass: "dashboard-grid__item",
                attrs: { id: "cameraInfoWrap" }
              },
              [
                _c(
                  "div",
                  { staticClass: "dashboard-grid__item__body camera-info" },
                  [
                    _c("div", { staticClass: "camera-info__title" }, [
                      _vm._v(_vm._s(_vm.selectFcltMarkerData.fcltName))
                    ]),
                    _c(
                      "div",
                      { staticClass: "camera-info__menu" },
                      [
                        _c(
                          "el-menu",
                          {
                            attrs: {
                              "default-active": _vm.activeIndex,
                              mode: "horizontal"
                            }
                          },
                          [
                            _c(
                              "el-menu-item",
                              {
                                attrs: { index: "1" },
                                on: {
                                  click: function($event) {
                                    return _vm.clearFclt()
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "el-icon-back" }),
                                _c(
                                  "span",
                                  { attrs: { slot: "title" }, slot: "title" },
                                  [_vm._v("공원목록으로")]
                                )
                              ]
                            ),
                            _c(
                              "el-submenu",
                              { attrs: { index: "2" } },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("i", { staticClass: "fas fa-bars" }),
                                  _c("span", [_vm._v(" AI카메라 정보관리")])
                                ]),
                                _c(
                                  "el-menu-item",
                                  {
                                    attrs: { index: "1-1" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openFormFcltDialog(
                                          _vm.selectFcltMarkerData
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("AI 카메라 정보수정")]
                                ),
                                _c(
                                  "el-menu-item",
                                  {
                                    attrs: { index: "1-2" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openFormAiDetect()
                                      }
                                    }
                                  },
                                  [_vm._v("AI영상 분석설정")]
                                )
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "camera-info__chart" }, [
                      _c("div", { staticClass: "camera-info__chart__body" }, [
                        _c(
                          "h6",
                          {
                            staticStyle: { "border-top": "2px solid #121820" }
                          },
                          [_vm._v("사람 / 자전거 통행량")]
                        ),
                        _c(
                          "div",
                          { staticStyle: { height: "calc(100% - 40px)" } },
                          [
                            _vm.chartData.objectPass.length !== 0
                              ? [
                                  _c("column-time-chart", {
                                    attrs: {
                                      dateExpression: _vm.chartExpression,
                                      cols: _vm.chartOption.objectPass.cols,
                                      data: _vm.chartData.objectPass,
                                      vMin: 0
                                    }
                                  })
                                ]
                              : [
                                  _c("div", { staticClass: "nodata" }, [
                                    _vm._v("데이터 없음.")
                                  ])
                                ]
                          ],
                          2
                        )
                      ]),
                      _c("div", { staticClass: "camera-info__chart__body" }, [
                        _c("h6", [_vm._v("사람 / 자전거 비율")]),
                        _c(
                          "div",
                          { staticStyle: { height: "calc(100% - 40px)" } },
                          [
                            _vm.chartData.objectRate.length !== 0
                              ? [
                                  _c("pie-chart", {
                                    attrs: {
                                      cols: _vm.chartOption.objectRate.cols,
                                      data: _vm.chartData.objectRate
                                    }
                                  })
                                ]
                              : [
                                  _c("div", { staticClass: "nodata" }, [
                                    _vm._v("데이터 없음.")
                                  ])
                                ]
                          ],
                          2
                        )
                      ]),
                      _c("div", { staticClass: "camera-info__chart__body" }, [
                        _c("h6", [_vm._v("탈 것별 통행량")]),
                        _c(
                          "div",
                          { staticStyle: { height: "calc(100% - 40px)" } },
                          [
                            _vm.chartData.vehiclePass.length !== 0
                              ? [
                                  _c("column-time-chart", {
                                    attrs: {
                                      dateExpression: _vm.chartExpression,
                                      cols: _vm.chartOption.vehiclePass.cols,
                                      data: _vm.chartData.vehiclePass
                                    }
                                  })
                                ]
                              : [
                                  _c("div", { staticClass: "nodata" }, [
                                    _vm._v("데이터 없음.")
                                  ])
                                ]
                          ],
                          2
                        )
                      ])
                    ]),
                    _c("div", { staticClass: "camera-info__data" }, [
                      _c(
                        "table",
                        { staticClass: "el-table el-table--mini" },
                        [
                          _c("tr", [
                            _c("th", [_vm._v("설치주소")]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.selectFcltMarkerData.setNumberAddr)
                              )
                            ])
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("경도")]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.selectFcltMarkerData.gisLat))
                            ])
                          ]),
                          _c("tr", [
                            _c("th", [_vm._v("위도")]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.selectFcltMarkerData.gisLng))
                            ])
                          ]),
                          _vm._l(
                            _vm.selectFcltMarkerData.templateData.getData(),
                            function(item, idx) {
                              return _vm.templateRenderFinish
                                ? _c("tr", { key: idx }, [
                                    _c("th", [_vm._v(_vm._s(item.label))]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectFcltMarkerData.templateData.getPrintValue(
                                            item.label
                                          )
                                        )
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            }
                          )
                        ],
                        2
                      )
                    ])
                  ]
                )
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            ref: "cameraListGrid",
            staticClass: "dashboard-grid__item camera-list"
          },
          [
            _c("h6", [_c("span", [_vm._v("통행량 수집현황")])]),
            _c(
              "section",
              { staticClass: "camera-list-gird" },
              _vm._l(_vm.markerData, function(item, idx) {
                return _c(
                  "div",
                  {
                    key: idx,
                    ref: item.properties.fcltId,
                    refInFor: true,
                    class: [
                      "camera-list-gird__item",
                      item.class ? item.class : ""
                    ],
                    on: {
                      click: function($event) {
                        return _vm.selectFclt(item)
                      }
                    }
                  },
                  [
                    _c(
                      "h6",
                      {
                        staticClass: "camera-list-title",
                        class: [_vm.isClick(item)]
                      },
                      [_vm._v(_vm._s(_vm.printCameraData(item)))]
                    ),
                    _c(
                      "div",
                      { staticClass: "camera-list-gird__item__body" },
                      [
                        _vm.fcltChartData[item.properties.fcltId]
                          ? _c("doughnut-chart", {
                              ref: item.properties.fcltId + "_chart",
                              refInFor: true,
                              attrs: {
                                "chart-data":
                                  _vm.fcltChartData[item.properties.fcltId],
                                width: 90,
                                height: 90,
                                options: {
                                  responsive: true,
                                  maintainAspectRatio: false,
                                  legend: {
                                    display: false
                                  },
                                  plugins: {
                                    labels: {
                                      render: "value",
                                      fontSize: 14,
                                      fontStyle: "bold",
                                      fontColor: "#fff"
                                    }
                                  }
                                }
                              }
                            })
                          : _vm._e(),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.fcltChartData[
                                  item.properties.fcltId
                                ],
                                expression:
                                  "!fcltChartData[item.properties.fcltId]"
                              }
                            ]
                          },
                          [_vm._v("정보없음")]
                        )
                      ],
                      1
                    )
                  ]
                )
              }),
              0
            )
          ]
        ),
        !_vm.selectFcltMarkerData.fcltId
          ? _c("div", { staticClass: "dashboard-grid__item" }, [
              _c("div", { staticClass: "dashboard-grid__item__body" }, [
                _c("h6", [_vm._v("시간대별 공원 출입 정보")]),
                _c(
                  "div",
                  { staticStyle: { height: "calc(100% - 27px)" } },
                  [
                    _vm.chartData.parkTotal.length !== 0
                      ? [
                          _c("line-time-chart", {
                            attrs: {
                              height: 250,
                              dateExpression: _vm.chartExpression,
                              cols: _vm.chartOption.parkTotal.cols,
                              data: _vm.chartData.parkTotal
                            }
                          })
                        ]
                      : [
                          _c("div", { staticClass: "nodata" }, [
                            _vm._v("데이터 없음")
                          ])
                        ]
                  ],
                  2
                )
              ])
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "dashboard-wrap" }),
      _c("form-ai-camera", {
        ref: "formAiCamera",
        attrs: { gisApiInfo: _vm.gisApiInfo, geometry: _vm.geometry },
        on: {
          saved: function($event) {
            return _vm.getFclt()
          }
        }
      }),
      _c("form-ai-camera-detect", { ref: "formAiCameraDetect" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }