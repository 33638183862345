<template>
  <div class="fill-width-height">
    <el-row class="page-body content-wrap">
      <dk-page-header></dk-page-header>
      <el-row style="padding: 4px 0;" :gutter="4">
        <el-col :span="4">
          <el-input placeholder="정보제공구간 ID" v-model="input"></el-input>
        </el-col>

        <el-col :span="4">
          <el-input placeholder="정보제공구간명" v-model="input"></el-input>
        </el-col>

        <el-col :span="4">
          <el-date-picker placeholder="시작생성일시" v-model="input" style="width: 100%;"></el-date-picker>
        </el-col>

        <el-col :span="4">
          <el-date-picker placeholder="종료생성일시" v-model="input" style="width: 100%;"></el-date-picker>
        </el-col>

        <el-col :span="2">
          <el-select v-model="value" placeholder="요일">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <el-button type="default" icon="el-icon-search">조회</el-button>
        </el-col>
      </el-row>
      <el-col :span="24" class="page-content" style="height: calc(100% - 90px);">
        <el-row style="border-top: 1px solid #dedede; height: 100%;">
          <el-col :span="10" style="border-right: 1px solid #dedede; height: 100%;">
            <el-table :data="fcltData" empty-text="시설물 정보가 없습니다." size="mini" @row-click="selectFcltRow" stripe>
              <el-table-column prop="fcltName" label="정보제공구간ID"></el-table-column>

              <el-table-column prop="fcltTypeName" label="정보제공구간명"></el-table-column>
              <el-table-column prop="fcltTypeName" label="요일"></el-table-column>
              <el-table-column prop="fcltTypeName" label="혼잡 시작시간"></el-table-column>
              <el-table-column prop="fcltTypeName" label="혼잡 종료시간"></el-table-column>
              <el-table-column prop="fcltTypeName" label="생성 일자"></el-table-column>
            </el-table>
            <dk-el-pagination :totalDocs="fcltTotalDocs" :limit="rcsLimit" :pagingProc="fcltPagingProc" ref="fcltPagination"></dk-el-pagination>
          </el-col>
          <el-col :span="14" style="height: 100%;">
            <el-main>
              <dk-gis fill-height :gisApiInfo="gisApiInfo" :markerData="markerData" ref="dkGis"></dk-gis>
            </el-main>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <form-maintnce ref="formMaintnce"></form-maintnce>
  </div>
</template>

<script>
import dkelpagination from '@/components/dkElPagination.vue';
import formmaintnce from '@/core/forms/formMaintnce.vue';
import dkgismain from '@/components/dkGis/dkGisMain.vue';
import moment from 'moment';

export default {
  components: {
    'dk-el-pagination': dkelpagination,
    'dk-gis': dkgismain,
    'form-maintnce': formmaintnce,
  },
  data() {
    return {
      gisApiInfo: [],
      limit: 15,
      totalDocs: 0,
      maintnceLogs: [],
      rcsLimit: 10,
      rcsTotalDocs: 0,
      rcsData: [],
      aaa: [],
      maintnceStepImg: [
        require('../assets/img/maintnce_step_0.png'),
        require('../assets/img/maintnce_step_1.png'),
        require('../assets/img/maintnce_step_2.png'),
        require('../assets/img/maintnce_step_2.png'),
      ],
      selectFcltData: null,
      selectMaintnceLog: null,
      props: {
        fcltData: {
          required: true,
          default: {},
        },
      },
    };
  },
  created() {
    this.getFclts(1);
    this.gisApiInfo = this.$store.getters['gis/getGisApi'];
    this.getRcsInfo();
  },
  mounted() {
    let api = this.gisApiInfo.find((apiInfo) => {
      return apiInfo.initGis;
    });
    this.$refs.dkGis.gisModuleLoad(api.gisName);
  },
  methods: {
    getFormatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    getRcsInfo(pageNum) {
      let me = this;
      let param = {
        page: pageNum || this.$refs.pagination.getPage(),
        limit: this.rcsLimit,
      };
      if (this.keyword !== '' && this.keyword) {
        param.keyword = this.keyword;
      }
      this.$store.dispatch('reptCngsSect/getRcs', param).then(() => {
        me.rcsData = me.$store.getters['reptCngsSect/getRcs'];
        me.rcsTotalDocs = me.$store.getters['reptCngsSect/getPagination'].totalDocs;
      });
    },
    async getFclts(pageNum) {
      let me = this;
      let param = {
        page: pageNum || this.$refs.pagination.getPage(),
        limit: this.limit,
      };
      if (this.keyword !== '' && this.keyword) {
        param.keyword = this.keyword;
      } else {
        param.keyword = 'CCTV';
      }
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fclt`, {
        params: param,
      });
      me.fcltData = response.data.docs;
      me.totalDocs = response.data.totalDocs;
      // this.$store.dispatch("fclt/getFclt", param)
      // .then(() => {
      //     me.fcltList = me.$store.getters['fclt/getFclt']
      //     me.totalDocs = me.$store.getters['fclt/getPagination'].totalDocs
      // });
    },
  },
};
</script>

<style scoped>
.maintnce-log-message {
  font-size: 20px;
  height: 48px;
  line-height: 46px;
}
</style>
