var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "el-dialog-aside vms-editor",
      attrs: {
        title: _vm.formTitle,
        visible: _vm.formDialog,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        width: "1000px",
        top: "5vh"
      },
      on: {
        "update:visible": function($event) {
          _vm.formDialog = $event
        },
        close: _vm.hideDialog
      }
    },
    [
      _c(
        "el-container",
        { staticClass: "dialog-container" },
        [
          _c(
            "el-container",
            [
              _c(
                "el-form",
                {
                  ref: "form0",
                  attrs: {
                    model: _vm.formData,
                    "label-width": "120px",
                    size: "medium",
                    "label-position": _vm.$isMobile ? "top" : "right"
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-main",
                            { staticStyle: { "background-color": "#acacac" } },
                            [
                              _c("vms-message-editor", {
                                ref: "vmsMessageEditor",
                                attrs: {
                                  vmsMessageSize: _vm.formData.vmsMessageSize,
                                  type: "figure"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("el-aside", { attrs: { width: "360px" } }, [
            _c(
              "div",
              {
                staticClass: "info-panel",
                staticStyle: { "background-color": "#fff" }
              },
              [
                _c(
                  "el-tabs",
                  {
                    model: {
                      value: _vm.activeTab,
                      callback: function($$v) {
                        _vm.activeTab = $$v
                      },
                      expression: "activeTab"
                    }
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: " 라이브러리 ", name: "tab0" } },
                      [
                        _c(
                          "ul",
                          { staticClass: "vms-library" },
                          _vm._l(_vm.vmsLibraries, function(item, index) {
                            return _c("li", { key: index }, [
                              _c("img", {
                                staticClass: "vmsLibrary-thumb-img",
                                attrs: { src: _vm.getThumbImg(item) },
                                on: {
                                  dblclick: function($event) {
                                    return _vm.addImageObject(item)
                                  }
                                }
                              })
                            ])
                          }),
                          0
                        )
                      ]
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "VMS 메시지", name: "tab1" } },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: {
                              width: "100%",
                              "border-top": "1px solid #dedede"
                            },
                            attrs: {
                              data: _vm.vmsMessages,
                              "empty-text": "등록된 VMS 메시지가 없습니다.",
                              size: "mini",
                              height: "600",
                              stripe: ""
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "", "min-width": "80px" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c("img", {
                                        staticStyle: {
                                          height: "80px",
                                          width: "auto"
                                        },
                                        attrs: { src: scope.row.thumbImageFile }
                                      })
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "VMS 메시지 이름",
                                prop: "vmsMessageName",
                                "header-align": "center",
                                align: "left"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { label: "", width: "80px" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.applyTemplete(
                                                scope.row
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("복사")]
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.openSaveDialog } },
            [_vm._v("저장")]
          ),
          _c("el-button", { on: { click: _vm.hideDialog } }, [_vm._v("취소")])
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "close-on-click-modal": false,
            "append-to-body": "",
            title: "임시 메시지 등록",
            visible: _vm.formSaveDialog,
            top: "5vh"
          },
          on: {
            "update:visible": function($event) {
              _vm.formSaveDialog = $event
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 14 } }, [
                _c(
                  "ul",
                  { staticClass: "schedule-preview" },
                  _vm._l(_vm.currentSchedule.vmsMessages, function(item, idx) {
                    return _c("li", { key: idx }, [
                      _c("img", { attrs: { src: item.thumbImageFile } })
                    ])
                  }),
                  0
                )
              ]),
              _c("el-col", { attrs: { span: 10 } }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      { on: { click: _vm.saveCurrentSchedule } },
                      [_vm._v("현재 스케줄에 추가")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      { on: { click: _vm.saveInstantSchedule } },
                      [_vm._v("임시 메시지만 표출")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }