<template>
  <el-dialog :title="formTitle" :visible.sync="formDialog" @close="hideDialog" :close-on-click-modal="false" append-to-body class="small" top="20vh">
    <div>
      <div>
        <p>비밀번호 인증이 필요한 기능입니다.</p>
      </div>
      <!-- <el-form :model="formData" label-width="140px" size="medium" :rules="rules" ref="form0"> -->
      <el-row>
        <el-col :span="22" style="height: 63px;">
          <input type="password" v-model="formData.password" placeholder="비밀번호" class="confirm-password-input" @keyup.enter="checkPassword" />
          <!-- <el-form-item label="비밀번호" prop="password">
                    <el-input type="password" v-model="formData.password" placeholder="비밀번호"  autocomplete="off"></el-input>
                </el-form-item> -->
        </el-col>
      </el-row>
      <!-- </el-form> -->
      <div class="form-bottom">
        <el-button @click="hideDialog" style="float: right;">
          취소
        </el-button>
        <el-button type="primary" @click="checkPassword" style="float: right; margin-right: 5px;">
          확인
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import CryptoJS from 'crypto-js';

export default {
  data() {
    return {
      formDialog: false,
      formTitle: '',
      formData: {
        password: '',
      },
      activeUser: null,
      confirmTarget: '',
      rules: {
        password: [
          {
            required: true,
            message: '비밀번호를 입력하여 주십시오',
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
  created() {
    this.activeUser = this.$dkRouter.getUserInfo();
  },
  methods: {
    async checkPassword(event) {
      let me = this;
      try {
        // this.$refs.form0.validate((valid) => {
        //   if (valid) {
        let userData = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/auth/getUserSalt`, {
          params: {
            // userid: this.$parent.selectUserInfo.userid,
            userid: this.activeUser.userid,
          },
        });
        let password = CryptoJS.SHA256(userData.data.salt + this.formData.password);
        let param = {
          // userid: this.$parent.selectUserInfo.userid,
          userid: this.activeUser.userid,
          password: password.toString(),
        };
        let result = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/auth/passwordConfirm`, { data: param });

        me.hideDialog();
        me.$message({
          type: 'success',
          message: `비밀번호 확인 완료`,
        });
        if (this.confirmTarget == 'userInfo') {
          this.$emit('updateUserInfo');
        } else if (this.confirmTarget == 'password') {
          this.$emit('updatePassword');
        }
      } catch (err) {
        me.$message.error(`비밀번호 확인 실패했습니다.`);
      }
      //   // }
      //   // else {
      //   //   return false;
      //   // }
      // });
    },
    showDialog(confirmData, confirmTarget) {
      this.updateFlag = true;
      this.formTitle = '비밀번호 확인';
      this.confirmTarget = confirmTarget;
      this.formDialog = true;
      this.$forceUpdate();
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    resetData() {
      this.formData = {
        userid: '',
        password: '',
        salt: '',
      };
    },
  },
};
</script>

<style scoped>
.admin-notice {
  margin-bottom: 30px;
  text-align: center;
}

.confirm-password-input {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
</style>
