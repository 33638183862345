import { render, staticRenderFns } from "./pageCovid19.vue?vue&type=template&id=60a360aa&"
import script from "./pageCovid19.vue?vue&type=script&lang=js&"
export * from "./pageCovid19.vue?vue&type=script&lang=js&"
import style0 from "./pageCovid19.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/dk-platform-frontend/dk-platform-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('60a360aa')) {
      api.createRecord('60a360aa', component.options)
    } else {
      api.reload('60a360aa', component.options)
    }
    module.hot.accept("./pageCovid19.vue?vue&type=template&id=60a360aa&", function () {
      api.rerender('60a360aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/mobile/pageCovid19.vue"
export default component.exports