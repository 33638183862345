<template>
  <div>
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item title="회" name="1">
        <template>
          <div v-for="(i, index) in value2" :key="index">
            {{data2[i]}}
          </div>
          <el-transfer
            filterable
            :filter-method="filterMethod"
            filter-placeholder="Auth"
            :titles="[' ',' ']"
            v-model="value2"
            :data="data2">
          </el-transfer>
        </template>
      </el-collapse-item>
      <el-collapse-item title="Feedback" name="2">
        {{data2}}
      </el-collapse-item>
      <el-collapse-item title="Efficiency" name="3">
        <div>Simplify the process: keep operating process simple and intuitive;</div>
        <div>Definite and clear: enunciate your intentions clearly so that the users can quickly understand and make
          decisions;
        </div>
        <div>Easy to identify: the interface should be straightforward, which helps the users to identify and frees them
          from memorizing and recalling.
        </div>
      </el-collapse-item>
      <el-collapse-item title="Controllability" name="4">
        <div>Decision making: giving advices about operations is acceptable, but do not make decisions for the users;
        </div>
        <div>Controlled consequences: users should be granted the freedom to operate, including canceling, aborting or
          terminating current operation.
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>
  export default {
    data() {
      const generateData2 = _ => {
        const data = [];
        const states = ['read','create','update','delete'];
        const initials = ['R','C','U','D'];
        states.forEach((city, index) => {
          data.push({
            label: city,
            key: index,
            initial: initials[index]
          });
        });
        return data;
      };
      const genCollapseItem = _ => {
        return;
      };
      return {
        data2: generateData2(),
        value2: [],
        filterMethod(query, item) {
          return item.initial.toLowerCase().indexOf(query.toLowerCase()) > -1;
        },

        collapseItem: genCollapseItem(),
        activeName: '1'
      };
    }
  };
</script>
