<template>
  <div class="page-set">
    <div class="top-bar-space" style="bottom: 0;">
      <ul class="disease-type-container">
        <li
          class=""
          v-for="menuInfo in menuList"
          :key="menuInfo.key"
          @click="
            (e) => {
              onClickMenu(e, menuInfo);
            }
          "
        >
          <span>
            {{ menuInfo.name }}
          </span>
        </li>
      </ul>
      <h3 class="middle-title">
        전국 현황 <span class="middle-title-date">({{ totalDataDate ? totalDataDate + ' 기준' : '' }})</span>
      </h3>
      <div class="content-click-container flex-box mb-both-mg" style="height: 35%; max-height: 35%;">
        <div class="content-click-box" v-for="countryCovidInfo in countryCovidList" :key="countryCovidInfo.key">
          <div class="flex-center covid-info">
            <span> {{ countryCovidInfo.name }} </span>
            <span> {{ countryCovidInfo.count }} </span>
            <span> {{ countryCovidInfo.upCount }} </span>
          </div>
        </div>
      </div>
      <h3 class="middle-title">
        울산 현황 <span class="middle-title-date">({{ ulsanDataDate ? ulsanDataDate + ' 기준' : '' }})</span>
      </h3>
      <div class="content-click-container flex-box mb-both-mg" style="height: 35%; max-height: 35%;">
        <div class="content-click-box" v-for="ulsanCovidInfo in ulsanCovidList" :key="ulsanCovidInfo.key">
          <div class="flex-center covid-info">
            <span> {{ ulsanCovidInfo.name }} </span>
            <span> {{ ulsanCovidInfo.count }} </span>
            <span> {{ ulsanCovidInfo.upCount }} </span>
          </div>
        </div>
      </div>
      <div class="covid-graph">
        <covid-chart
          chartHeight="250px"
          :chartWidth="chartWidth"
          :columnNameList="columnNameList"
          :columnColorList="columnColorList"
          :dataList="chartDataList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import covidChart from './component/covidChart';
import moment from 'moment';

export default {
  components: {
    'covid-chart': covidChart,
  },
  props: {
    checkBackAction: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    totalDataDate: '',
    ulsanDataDate: '',

    menuList: [
      {
        key: 'diseaseInfo',
        name: '가축질병',
        path: '/diseaseInfo',
      },
      {
        key: 'covid19',
        name: '코로나',
        // path:'/covid19',
      },
    ],
    countryCovidList: [
      {
        key: 'defCnt',
        name: '누적확진환자',
        count: '-',
        upCount: '-',
      },
      {
        key: 'isolIngCnt',
        name: '격리중',
        count: '-',
        upCount: '-',
      },
      {
        key: 'isolClearCnt',
        name: '격리해체',
        count: '-',
        upCount: '-',
      },
      {
        key: 'deathCnt',
        name: '사망자',
        count: '-',
        upCount: '-',
      },
    ],
    ulsanCovidList: [
      {
        key: 'defCnt',
        name: '누적확진환자',
        count: '-',
        upCount: '-',
      },
      {
        key: 'isolIngCnt',
        name: '격리중',
        count: '-',
        upCount: '-',
      },
      {
        key: 'isolClearCnt',
        name: '격리해체',
        count: '-',
        upCount: '-',
      },
      {
        key: 'deathCnt',
        name: '사망자',
        count: '-',
        upCount: '-',
      },
    ],

    chartDataLength: 7,
    columnNameList: ['인원수'],
    columnColorList: ['437ce5'],
    chartDataList: [
      //   {
      //   key:'2014', valueList:[10],
      // },{
      //   key:'2014', valueList:[5],
      // },
    ],
  }),
  created() {
    // this.backAction();
  },
  mounted() {
    this.backAction();
    this.getCovideTotalData();
    this.getCovideUlsanData();
  },
  computed: {},
  methods: {
    backAction() {
      const me = this;
      window.backAction = () => {
        try {
          if (window.JSBridge && window.JSBridge.wvStatus) {
            window.JSBridge.wvStatus('using');
          }
          if (this.checkBackAction()) {
            return;
          } else {
            this.$router.go(-1);
          }
        } catch (err) {
          this.$message('뒤로가기 에러');
        }
      };
    },

    async requestEvent(args) {
      try {
        const { search = {}, sort, limit } = args;

        const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/ulsanBukgu/api/event`, { search, sort, limit });
        if (!response || response.status !== 200) {
          throw new Error();
        }
        return response.data;
      } catch (err) {
        // this.$message({
        //   type: 'error',
        //   message: 'requestEvent faile',
        // });
        return [];
      }
    },

    async getCovideTotalData() {
      const covidDataList = await this.requestEvent({
        search: {
          eventTypeId: 'COVID-19',
          'eventData.gubunEn': 'Total',
        },
        sort: { collectDateTime: -1 },
        limit: 2,
      });

      if (!Array.isArray(covidDataList) || covidDataList.length === 0) {
        // throw new Error('');
        return;
      }

      const covidTotalData = covidDataList[0];
      const { eventData = {} } = covidTotalData;
      const {
        defCnt, // 확진자 수
        deathCnt, // 사망자 수
        isolIngCnt, // 격리중 환자수
        isolClearCnt, // 격리 해제 수
        incDec, // 전일대비 증감 수
        stdDay,
      } = eventData;

      const covidTotalData2 = covidDataList[1] || {};
      const { eventData: eventData2 = {} } = covidTotalData2;

      const { countryCovidList } = this;

      this.totalDataDate = stdDay;

      this.countryCovidList = countryCovidList.map((countryCovid) => {
        const { key, name, count /* upCount */ } = countryCovid;
        let upCount = '-';

        if (eventData2[key]) {
          upCount = eventData[key] - eventData2[key];
          upCount = upCount || '-';
        }

        return {
          ...countryCovid,
          count: eventData[key],
          upCount,
        };
      });
    },

    async getCovideUlsanData() {
      const mn = moment();
      const dt = moment(mn.format('YYYYMMDD235959999'), 'YYYYMMDDHHmmssSSS').toDate();
      dt.setDate(dt.getDate() - 7);

      const covidDataList = await this.requestEvent({
        search: {
          eventTypeId: 'COVID-19',
          'eventData.gubunEn': 'Ulsan',
          startDate: { $gte: dt },
        },
        sort: { startDate: 1 },
        // limit: 7,
      });

      if (!Array.isArray(covidDataList) || covidDataList.length === 0) {
        // throw new Error('');
        return;
      }
      this.setChartData(covidDataList);

      const covidUlsanData = covidDataList[covidDataList.length - 1];
      const { eventData = {} } = covidUlsanData;
      const {
        defCnt, // 확진자 수
        deathCnt, // 사망자 수
        isolIngCnt, // 격리중 환자수
        isolClearCnt, // 격리 해제 수
        incDec, // 전일대비 증감 수
        stdDay,
      } = eventData;

      let covidTotalData2 = {};
      if (covidDataList.length >= 2) {
        covidTotalData2 = covidDataList[covidDataList.length - 2] || {};
      }

      const { eventData: eventData2 = {} } = covidTotalData2;

      const { ulsanCovidList } = this;

      this.ulsanDataDate = stdDay;

      this.ulsanCovidList = ulsanCovidList.map((countryCovid) => {
        const { key, name, count /* upCount */ } = countryCovid;
        let upCount = '-';

        if (eventData2[key]) {
          upCount = eventData[key] - eventData2[key];
          upCount = upCount || '-';
        }

        return {
          ...countryCovid,
          count: eventData[key],
          upCount,
        };
      });
    },

    setChartData(covidDataList) {
      const chartDataList = [];

      for (let i = 0; i < this.chartDataLength - covidDataList.length; i++) {
        chartDataList.push({
          key: ' ',
          valueList: [0],
        });
      }

      covidDataList.forEach((covidTotalData) => {
        const { eventData = {} } = covidTotalData;
        const {
          createDt, // 등록날짜
          defCnt, // 확진자 수
          deathCnt, // 사망자 수
          isolIngCnt, // 격리중 환자수
          isolClearCnt, // 격리 해제 수
          incDec, // 전일대비 증감 수
        } = eventData;

        const dt = new Date(createDt);
        dt.setDate(dt.getDate() - 1);
        const fildName = moment(dt).format('MM-DD');

        // return {
        //   key: fildName,
        //   valueList: [incDec],
        // }

        chartDataList.push({
          key: fildName,
          // valueList: [incDec],
          valueList: [Number(incDec)],
        });
      });

      this.chartDataList = chartDataList;
    },

    onClickMenu(e, menuInfo) {
      if (menuInfo && menuInfo.path) {
        this.$router.push(menuInfo.path);
      } else {
        // this.$alert(menuInfo.name);
      }
    },
  },

  computed: {
    chartWidth: () => {
      return window.innerWidth + 38 + 'px';
    },
  },
};
</script>
<style>
.disease-menu-test {
  border-left: solid 1px #ddd;
  border-right: solid 1px #ddd;
}
</style>
