<template>
  <div class="fill-width-height el-container" id="vmsTextScheduleDownload">
    <el-container class="page-body">
      <el-container>
        <el-main>
          <div class="content-wrap vms-schedule-content-wrap">
            <dk-page-header class="vms-page-header"></dk-page-header>

            <div class="page-content vms-schedule-content">
              <vms-object
                v-for="(vmsItem, idx) in vmsLists"
                :key="idx"
                :vmsObject="vmsItem"
                :selectVmsRow="selectVmsRow"
                :vmsSize="vmsItem.vmsSize"
              ></vms-object>
            </div>
            <!-- <el-table
                            :data="vmsLists"
                            style="width:100%; border-top:1px solid #dedede"
                            empty-text="등록된 VMS 장비가 없습니다."
                            size="mini"
                            @row-click="selectVmsRow"
                            stripe                            
                        >
                            <el-table-column
                                label="제어기번호"                                    
                                header-align="left"
                                align="left"
                                width="105px"
                            >
                                <template slot-scope="scope">
                                    {{scope.row.fcltTypeData.vmsSpotId.value}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="제어기명"
                                header-align="left"
                                align="left"
                            >
                                <template slot-scope="scope">
                                    {{scope.row.fcltName}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="표출중인 메시지"

                            >
                                <template slot-scope="scope">
                                    {{scope.row.vmsSchedules}}
                                </template>
                            </el-table-column>
            </el-table>-->
          </div>
        </el-main>
      </el-container>
      <el-aside width="40%" class="schedule-info-panel">
        <div class="info-panel-wrap">
          <div v-if="!selectVms.fcltId">
            <h3>VMS 장비를 선택하세요</h3>
          </div>
          <div v-else>
            <el-row>
              <el-col :span="24">
                <table class="dk-table">
                  <colgroup>
                    <col style="width: 30%;" />
                    <col style="width: 70%;" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scople="row">제어기 ID</th>
                      <td>{{ selectVms.vmsSpotId }}</td>
                    </tr>
                    <tr>
                      <th scople="row">설치 주소</th>
                      <td>{{ selectVms.fcltName }}</td>
                    </tr>
                    <tr>
                      <th scople="row">VMS 크기</th>
                      <td>{{ getVmsSize(selectVms.vmsSize) }}</td>
                    </tr>
                  </tbody>
                </table>
              </el-col>
              <el-col :span="24">
                <el-table
                  :data="selectVms.vmsSchedules"
                  style="border-top: 1px solid #dedede; width: 100%;"
                  empty-text="제어기에 등록된 스케줄이 없습니다."
                  size="mini"
                  @row-click="selectVmsSchedule"
                  stripe
                  height="400px"
                >
                  <!-- <el-table-column
                                    label="스케줄 ID"
                                    header-align="left"
                                    align="left"
                                    width="80px"
                                    prop="vmsScheduleId"
                                >                                
                  </el-table-column>-->
                  <el-table-column label="스케줄 명칭" header-align="left" align="left" prop="vmsScheduleName"></el-table-column>
                  <el-table-column label="현재 표출중" header-align="left" align="left">
                    <template slot-scope="scope">
                      <el-tag v-if="scope.row.vmsScheduleId == selectVms.currentScheduleId" size="mini">표출중</el-tag>

                      <el-tooltip
                        v-if="scope.row.isAvailableDate && scope.row.vmsScheduleId == selectVms.currentScheduleId"
                        :content="dispVmsCheduleDateText(scope.row)"
                        placement="top"
                      >
                        <el-tag size="mini" type="warning">일정표출</el-tag>
                      </el-tooltip>
                    </template>
                  </el-table-column>

                  <el-table-column label width="250px">
                    <template slot-scope="scope">
                      <el-button @click="setVmsScheduleByDateDialog(scope.row)" size="mini">표출설정</el-button>
                      <el-button @click="openFormDialog(scope.row)" size="mini">수정</el-button>
                      <el-button @click="removeVmsSchedule(scope.row)" size="mini" type="danger">삭제</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
              <el-col :span="24" style="text-align: right;">
                <el-button size="medium" @click="defaultMessageDownload">
                  <i class="fas fa-plus-circle">&nbsp;기본메시지 다운로드</i>
                </el-button>
                <el-button type="primary" size="medium" @click="addScheduleDownload">
                  <i class="fas fa-plus-circle">&nbsp;스케줄 추가</i>
                </el-button>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- <div class="info-panel-wrap">
                <div class="dk-card" 
                    v-for="(item, index) in selectedVmsSchedule.vmsMessages"
                    :key="index"
                >
                    <img :src="item.thumbImageFile" style="height:auto; width:100%;"/>
                </div>                
        </div>-->
      </el-aside>
    </el-container>
    <form-vms-text-schedule-download ref="formDialog"></form-vms-text-schedule-download>
    <el-dialog title="VMS 스케줄 표출 일정 설정" :visible.sync="vmsScheduleByDateDialog" append-to-body @close="closeVmsScheduleByDateDialog">
      <h4>스케줄 VMS 메시지 구성</h4>
      <el-row :gutter="2">
        <el-col :span="6" v-for="(item, index) in selectedVmsSchedule.vmsMessages" :key="index">
          <img :src="item.thumbImageFile" style="height: auto; width: 100%;" />
        </el-col>
      </el-row>
      <el-form label-width="140px" label-position="right" style="margin-right: 100px;">
        <el-form-item label="표출 일정">
          <el-checkbox v-model="tempVmsScheduleByDateData.isAvailableDate">표출 일정 사용</el-checkbox>
        </el-form-item>
        <el-form-item label="표출 시작일시">
          <el-date-picker
            v-model="tempVmsScheduleByDateData.startDate"
            type="datetime"
            placeholder="VMS 스케줄 표출 시작일시"
            style="width: 100%;"
            :disabled="!tempVmsScheduleByDateData.isAvailableDate"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="표출 종료일시">
          <el-date-picker
            v-model="tempVmsScheduleByDateData.endDate"
            type="datetime"
            placeholder="VMS 스케줄 표출 종료일시"
            style="width: 100%;"
            :disabled="!tempVmsScheduleByDateData.isAvailableDate"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveVmsScheduleByDateDialog">저장</el-button>
        <el-button @click="closeVmsScheduleByDateDialog">취소</el-button>
      </div>
    </el-dialog>
    <form-default-message-download ref="formDefaultMessageDownload"></form-default-message-download>
  </div>
</template>

<script>
import moment from 'moment';
import dkelpagination from '@/components/dkElPagination.vue';
import dkeltableheader from '@/components/dkElTableHeader.vue';
import formVmsTextScheduleDownload from './form/formVmsTextScheduleDownload.vue';
import formDefaultMessageDownload from './form/formDefaultMessageDownload.vue';

let vmsObject = {
  template: `
        <div 
            class="vms-list-item"
            v-show="getVmsSize"
            @click="selectVms"
            style=""
        >
          <div style="display:flex; flex-direction:column; height:100%; width:100%;"">
            <div class="title" style="background-color:#494a56; padding:5px 8px; color:#fff; weight:bold;">
                {{vmsObject.fcltName}}
            </div>
            
            <div class="vms-message" ref="vmsMessageImageWrap" 
              style="background-color:#000; flex:1; flex-direction: column; display: flex; justify-content: center;"
            >
                <img v-if="vmsMessageImage" :src="vmsMessageImage" :style="vmsImageStyle"/>
                <span v-else style="color:#fff; text-align:center;">
                    등록된 VMS스케줄이 없습니다.
                </span>
            </div>                        
          </div>
        </div>
    `,
  mounted() {
    let size = this.vmsObject.vmsSize.split('x');
    this.width = parseInt(size[1]);
    this.height = parseInt(size[0]);
    let idx = this.vmsObject.vmsMessageRolling.targetVmsScheduleIdx || 0;
    if (this.vmsObject.vmsSchedules && this.vmsObject.vmsSchedules.length > 0) {
      this.vmsMessageSize = this.vmsObject.vmsSchedules[idx].vmsMessages.length || 0;
    }
    this.intervalId = setInterval(() => {
      if (this.vmsMessageIdx >= this.vmsMessageSize - 1) {
        this.vmsMessageIdx = 0;
      } else {
        this.vmsMessageIdx++;
      }
    }, 1000);

    this.$nextTick(() => {
      window.addEventListener('resize', this.setVmsMessageSize);
      this.setVmsMessageSize();
    });
  },
  computed: {
    vmsMessageImage() {
      let idx = this.vmsObject.vmsMessageRolling.targetVmsScheduleIdx || 0;
      if (this.vmsObject.vmsSchedules && this.vmsObject.vmsSchedules.length > 0) {
        if (!this.vmsObject.vmsSchedules[idx].vmsMessages) {
          console.log(this.vmsObject);
        }
        return this.vmsObject.vmsSchedules[idx].vmsMessages[this.vmsMessageIdx].thumbImageFile;
      } else {
        return null;
      }
    },
    getVmsSize() {
      if (this.vmsObject.vmsSize == this.vmsSize) {
        return true;
      } else {
        return false;
      }
    },
    vmsImageStyle() {
      return {
        width: '100%',
        display: 'inline-block',
        height: 'auto',
      };
    },
  },
  data() {
    return {
      vmsMessageIdx: 0,
      vmsMessageSize: 0,
      intervalId: null,
      width: 0,
      height: 0,
      vmsMessageHeight: 80,
    };
  },
  destroyed() {
    window.removeEventListener('resize', this.setVmsMessageSize);
  },
  props: {
    vmsObject: {
      default: null,
      type: Object,
    },
    selectVmsRow: {
      default: function (vmsObject) {},
      type: Function,
    },
    vmsSize: {
      type: String,
    },
  },
  methods: {
    selectVms() {
      return this.selectVmsRow(this.vmsObject);
    },
    getAvailableVmsSchedule() {
      let idx = this.vmsObject.vmsMessageRolling.targetVmsScheduleIdx || 0;
      if (this.vmsObject.vmsSchedules && this.vmsObject.vmsSchedules.length > 0) {
        // 현재 유효한 스케줄 체크 로직
        // 일자별 혹은 우선순위
        // 일단은 그냥 있는거 리턴
        return this.vmsObject.vmsSchedules[idx];
      } else {
        return false;
      }
    },
    setVmsMessageSize() {
      this.vmsMessageHeight = Math.round((this.$refs.vmsMessageImageWrap.clientWidth / this.width) * this.height);
    },
  },
};

export default {
  components: {
    'dk-el-table-header': dkeltableheader,
    'dk-el-pagination': dkelpagination,
    'form-vms-text-schedule-download': formVmsTextScheduleDownload,
    'vms-object': vmsObject,
    'form-default-message-download': formDefaultMessageDownload,
  },
  created() {
    this.$on('procDataComplete', (data) => {
      this.refreshVmsList();
      this.selectVmsRow(this.selectVms);
    });
  },
  mounted() {
    this.getLists();
  },
  data() {
    return {
      limit: 10,
      totalDocs: 0,
      vmsLists: [],
      activeFcltTab: '2x12',
      vmsSizes: [
        {
          label: '2단12열',
          value: '2x12',
        },
        {
          label: '2단10열',
          value: '2x10',
        },
        {
          label: '2단13열',
          value: '2x13',
        },
      ],
      selectVms: {
        _id: null,
        fcltId: '',
        fcltTypeData: {
          vmsSpotId: {
            value: '',
          },
        },
      },
      vmsDownloadedSchedules: [],
      selectedVmsSchedule: {
        vmsScheduleId: -1,
        vmsScheduleName: '',
        vmsMessageSize: '',
        vmsCategory: '',
        vmsMessages: [],
        vmsType: '',
        seq: -1,
        startDate: null,
        endDate: null,
        isAvailableDate: false,
      },
      vmsScheduleByDateDialog: false,
      tempVmsScheduleByDateData: {
        startDate: null,
        endDate: null,
        isAvailableDate: false,
      },
    };
  },
  methods: {
    async getLists() {
      let me = this;
      try {
        const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsCurrentMessageList`, { params: { vmsType: '1' } });
        me.vmsLists = response.data.docs;
        me.vmsLists.forEach((vmsObject) => {
          this.makeVmsMessageRolling(vmsObject);
        });
      } catch (err) {
        console.error(err);
      }
    },
    async refreshVmsMessageRollingOne(fcltId) {
      let me = this;
      this.vmsLists.forEach((vmsObject) => {
        if (fcltId) {
          if (vmsObject.fcltId == fcltId && vmsObject.vmsMessageRolling && vmsObject.vmsMessageRolling.intervalId) {
            // clearInterval(vmsObject.vmsMessageRolling.intervalId)
          }
        }
      });
      try {
        const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsCurrentMessageList`, { params: { vmsType: '1' } });
        me.vmsLists = response.data.docs;
        me.vmsLists.forEach((vmsObject) => {
          if (vmsObject.fcltId == fcltId) {
            this.makeVmsMessageRolling(vmsObject);
          }
        });
      } catch (err) {
        console.error(err);
      }
    },

    removeVmsSchedule(args) {
      this.$confirm(`${args.vmsScheduleName} VMS 문자식 스케줄를 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(() => {
          let param = {
            fcltId: this.selectVms.fcltId,
            vmsScheduleId: args.vmsScheduleId,
          };
          this.$http
            .post(`${this.$config.getServerConfig().core.api}/core/api/vmsDownloadedSchedule/delete`, {
              data: param,
            })
            // this.$store
            //   .dispatch('vmsDownloadedSchedule/deleteVmsDownloadedSchedule', {
            //     data: param,
            //   })
            .then((response) => {
              this.$message({
                type: 'success',
                message: `${args.vmsScheduleName}  삭제 완료`,
              });
              this.refreshVmsList();
              this.selectVmsRow(this.selectVms);
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: `${args.vmsScheduleName} VMS 문자식 스케줄 삭제 중 에러 발생`,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${args.vmsScheduleName} VMS 문자식 스케줄 삭제 취소`,
          });
        });
    },
    getVmsSize(val) {
      let s = val.split('x');
      return `${s[0]}단 ${s[1]}열`;
    },

    selectVmsRow(row) {
      this.vmsDownloadedSchedules = [];
      this.selectedVmsSchedule = {
        vmsScheduleId: -1,
        vmsScheduleName: '',
        vmsMessageSize: '',
        vmsCategory: '',
        vmsMessages: [],
        vmsType: '',
        seq: -1,
      };
      this.selectVms = row;
      this.$http
        .get(`${this.$config.getServerConfig().core.api}/core/api/vmsDownloadedSchedule`, {
          params: {
            fcltId: row.fcltId,
          },
        })
        // this.$store
        //   .dispatch('vmsDownloadedSchedule/getVmsDownloadedSchedule', {
        //     fcltId: row.fcltId,
        //   })
        .then((result) => {
          if (result) {
            this.selectVms = Object.assign(row, result);
            //this.vmsDownloadedSchedules = result.vmsSchedules
          } else {
            this.selectVms = row;
          }
        });
    },

    refreshVmsList() {
      this.vmsLists.forEach((vmsObject) => {
        if (vmsObject.vmsMessageRolling && vmsObject.vmsMessageRolling.intervalId) {
          // clearInterval(vmsObject.vmsMessageRolling.intervalId)
        }
      });

      let fcltId = '';
      if (this.selectVms.fcltId !== '') {
        fcltId = this.selectVms.fcltId;
      }
      this.getLists().then(() => {
        this.selectVms = this.vmsLists.find((vl) => {
          return vl.fcltId === fcltId;
        });
      });
    },

    addScheduleDownload() {
      this.$refs.formDialog.showAddDialog(this.selectVms);
    },
    openFormDialog(row) {
      this.$refs.formDialog.showUpdateDialog(this.selectVms, row);
    },
    defaultMessageDownload() {
      this.$refs.formDefaultMessageDownload.showDialog(this.selectVms);
    },
    selectVmsSchedule(row) {
      this.selectedVmsSchedule = row;
    },
    getAvailableVmsSchedule(vmsItem) {
      if (vmsItem.vmsSchedules && vmsItem.vmsSchedules.length > 0) {
        // 현재 유효한 스케줄 체크 로직
        // 일자별 혹은 우선순위
        // 일단은 그냥 있는거 리턴
        return vmsItem.vmsSchedules[0];
      } else {
        return false;
      }
    },
    makeVmsMessageRolling(vmsObject) {
      if (vmsObject.vmsSchedules && vmsObject.vmsSchedules.length > 0) {
        let idx = 0;
        for (var i = 0; i < vmsObject.vmsSchedules.length; i++) {
          idx = i;
          if (vmsObject.vmsSchedules[i].isCurrentSchedule) {
            break;
          }
        }
        vmsObject.vmsMessageRolling = {
          targetVmsScheduleIdx: idx,
        };
      } else {
        vmsObject.vmsMessageRolling = {
          targetVmsScheduleIdx: 0,
        };
      }
    },
    getVmsThumbImage(vmsObject) {
      if (vmsObject.vmsSchedules && vmsObject.vmsSchedules.length > 0) {
        return this.vmsObject.vmsSchedules[0].vmsMessages[vmsObject.vmsMessageRolling.vmsMessageIdx].thumbImageFile;
        //console.log()
      }
    },
    setVmsScheduleByDateDialog(row) {
      this.selectedVmsSchedule = row;
      this.vmsScheduleByDateDialog = true;
      this.tempVmsScheduleByDateData = {
        startDate: row.startDate || null,
        endDate: row.endDate || null,
        isAvailableDate: row.isAvailableDate || false,
      };
    },
    closeVmsScheduleByDateDialog() {
      this.vmsScheduleByDateDialog = false;
    },
    async saveVmsScheduleByDateDialog() {
      this.selectVms.vmsSchedules.forEach((vmsSchedule) => {
        vmsSchedule.isCurrentSchedule = false;
      });
      this.selectedVmsSchedule.startDate = this.tempVmsScheduleByDateData.startDate || null;
      this.selectedVmsSchedule.endDate = this.tempVmsScheduleByDateData.endDate || null;
      this.selectedVmsSchedule.isAvailableDate = this.tempVmsScheduleByDateData.isAvailableDate || false;
      this.selectedVmsSchedule.isCurrentSchedule = true;

      try {
        await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/vmsDownloadedSchedule/proc`, {
          data: this.selectVms,
        });
        this.$message({
          type: 'success',
          message: `VMS 표출설정 등록 완료`,
        });
        this.vmsScheduleByDateDialog = false;
        this.selectVmsRow(this.selectVms);
        this.refreshVmsMessageRollingOne(this.selectVms.fcltId);
      } catch (err) {
        this.$message.error(`VMS 표출설정 등록 실패`);
      }
    },
    dispVmsCheduleDateText(vmsSchedule) {
      return `${moment(vmsSchedule.startDate).format('YYYY-MM-DD')} ~ ${moment(vmsSchedule.endDate).format('YYYY-MM-DD')}`;
    },
  },
};
</script>

<style scoped></style>
