<template>
  <el-dialog :title="formTitle" :visible.sync="formDialog" @close="hideDialog" :close-on-click-modal="false">
    <div>
      <h4>엑셀로 출력할 시설물 데이터 유형을 선택해주세요</h4>
      <el-form :model="formData" label-width="120px" size="medium" ref="form0">
        <el-col :xs="24" :sm="12" :md="12">
          <el-form-item label="시설물 유형" prop="fcltTypeId">
            <el-select v-model="formData.fcltTypeId" placeholder="시설물 유형" @change="changeFcltType">
              <el-option v-for="(item, index) in fcltType" :key="index" :label="item.fcltTypeName" :value="item.fcltTypeId"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12">
          <el-button style="padding: 10px 20px;" @click="exportExcel()">다운로드</el-button>
        </el-col>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="hideDialog">
        닫기
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import XLSX from 'xlsx';

export default {
  data() {
    return {
      systemLogInfo: {},
      formTitle: '시설물 정보 일괄 출력',
      formDialog: false,
      fcltType: [],
      formData: {
        fcltTypeId: '',
      },
      selectFcltType: {},
    };
  },
  created() {
    let me = this;
    this.$store.dispatch('fcltType/getFcltType', {}).then(() => {
      me.fcltType = me.$store.getters['fcltType/getFcltType'];
      me.fcltType.push({ fcltTypeName: '전체' });
    });
  },
  methods: {
    async exportExcel() {
      if (this.formData.fcltTypeId == '') {
        this.$message({
          type: 'danger',
          message: '선택한 시설물 유형이 없습니다.',
        });
        return false;
      }
      // 나중에 기자재 정보를 추가할거면 이부분에 formData로 넘기는게 적절함
      
      const h = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltExcel/getHeader`, { params: this.formData });

      const body = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltExcel/getData`, { params: this.formData });

      const properties = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltExcel/getProperties`, { params: this.formData });

      let excelData = {
        header: h.data.header,
      };
      if (body.data.length > 0) {
        excelData.data = body.data;
      } else {
        excelData.data = h.data.sampleData;
      }

      var header = [];
      var label = {};
      var data = [];

      for (var i = 0; i < excelData.header.length; i++) {
        header.push({
          key: i,
          name: excelData.header[i].key,
        });
        label[excelData.header[i].key] = excelData.header[i].label;
      }
      data.push(label);
      var exdata = this.$lodash.union(data, excelData.data);
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(exdata, header);
      ws['!rows'] = [];
      ws['!rows'][0] = { hidden: true };

      const propertiesWs = XLSX.utils.json_to_sheet([{ fcltTypeId: properties.data.fcltTypeId, fcltTypeName: properties.data.fcltTypeName }]);
      XLSX.utils.book_append_sheet(wb, ws, 'Fclt Data');
      XLSX.utils.book_append_sheet(wb, propertiesWs, 'Fclt Properties');
      XLSX.writeFile(wb, `${this.selectFcltType.fcltTypeName}.xlsx`, { bookType: 'xlsx', type: 'array' });
      this.systemLogInfo.page = `${this.$route.name}`;
      this.systemLogInfo.saveType = 'exportExcel';
      await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
    },
    resetData() {},
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    showDialog() {
      this.formDialog = true;
    },

    changeFcltType(val) {
      this.selectFcltType = this.fcltType.find((ft) => {
        return ft.fcltTypeId == val;
      });
    },
  },
};
</script>

<style></style>
