<template>
    <v-container fill-height>
        <v-layout wrap>
            <v-flex>
                <v-dialog v-model="dialog" max-width="900px">
                    <v-stepper v-model="steps" vertical>
                        <v-toolbar dark>
                            <h2>{{formTitle}}</h2>
                                <v-spacer></v-spacer>
                            <v-btn color="primary" @click="dialogFormClose">취소</v-btn>
                        </v-toolbar>
                        <v-stepper-step :complete="steps > 1" step="1">
                            <small>기본정보</small>
                        </v-stepper-step>
                            <v-stepper-content step="1">
                                <v-card id="stepsForm" class="mb-5">
                                    <v-form v-model="valid">
                                        <v-container grid-list-md>
                                            <v-layout wrap>
                                                <v-flex md5 hidden-sm-and-down>
                                                    <v-subheader>카메라 ID</v-subheader>
                                                    </v-flex>
                                                    <v-flex md7 sm12 xs12>
                                                        <v-text-field required v-model="cameraData.cameraId" :rules="nameRules" label="카메라 ID" :disabled="disabled"></v-text-field>
                                                    </v-flex>
                                                    <v-flex md5 hidden-sm-and-down>
                                                        <v-subheader>카메라 지점명</v-subheader>
                                                    </v-flex>
                                                    <v-flex md7 sm12 xs12>
                                                        <v-text-field required v-model="cameraData.cameraPlaceName" :rules="nameRules" label="카메라 지점명" :disabled="viewDisabled"></v-text-field>
                                                    </v-flex>
                                                    <v-flex md5 hidden-sm-and-down>
                                                        <v-subheader>카메라 모델명</v-subheader>
                                                    </v-flex>
                                                    <v-flex md7 sm12 xs12>
                                                    <v-select
                                                            required                                                            
                                                            :items="cameraModelInfo"
                                                            v-model.number="cameraData.cameraModelCd"
                                                            item-text="cameraModelName"
                                                            item-value="cameraModelCd"
                                                            label="카메라 모델명"
                                                            :rules="select"
                                                            single-line
                                                            :disabled="viewDisabled"
                                                        ></v-select>
                                                    </v-flex>
                                                    <v-flex md5 hidden-sm-and-down>
                                                        <v-subheader>카메라 설치 높이</v-subheader>
                                                    </v-flex>
                                                    <v-flex md7 sm12 xs12>
                                                        <v-text-field required v-model.number="cameraData.cameraInitHeight" :rules="nameRules" type="number" label="카메라 설치 높이" :disabled="viewDisabled"></v-text-field>
                                                    </v-flex>
                                                    <v-flex md5 hidden-sm-and-down>
                                                        <v-subheader>IP</v-subheader>
                                                    </v-flex>
                                                    <v-flex md7 sm12 xs12>
                                                        <v-text-field required v-model="cameraData.ip" :rules="nameRules" label="IP" :disabled="viewDisabled"></v-text-field>
                                                    </v-flex>
                                                    <v-flex md5 hidden-sm-and-down>
                                                        <v-subheader>PORT</v-subheader>
                                                    </v-flex>
                                                    <v-flex md7 sm12 xs12>
                                                        <v-text-field required v-model.number="cameraData.port" :rules="nameRules" label="PORT" :disabled="viewDisabled"></v-text-field>
                                                    </v-flex>
                                                    <v-flex md5 hidden-sm-and-down>
                                                        <v-subheader>카메라접속 ID</v-subheader>
                                                    </v-flex>
                                                    <v-flex md7 sm12 xs12>
                                                        <v-text-field required v-model="cameraData.accessId" :rules="nameRules" label="카메라접속 ID" :disabled="viewDisabled"></v-text-field>
                                                    </v-flex>
                                                    <v-flex md5 hidden-sm-and-down>
                                                        <v-subheader>비밀번호</v-subheader>
                                                    </v-flex>
                                                    <v-flex md7 sm12 xs12>
                                                        <v-text-field required v-model="cameraData.password" :rules="nameRules" label="비밀번호" :disabled="viewDisabled"></v-text-field>
                                                    </v-flex>   
                                                    <v-flex md5 hidden-sm-and-down>

                                                        <v-subheader>행정지역</v-subheader>
                                                    </v-flex>
                                                    <v-flex md7 sm12 xs12>
                                                        <v-select
                                                            required                                                            
                                                            :items="regionId"
                                                            v-model="cameraData.regionId"
                                                            item-text="dataName"
                                                            item-value="data"
                                                            label="행정지역 명칭"
                                                            :rules="select"
                                                            single-line
                                                            :disabled="viewDisabled"
                                                        ></v-select>
                                                        <!-- <v-text-field required v-model="cameraData.regionId" :rules="nameRules" label="행정지역아이디" :disabled="viewDisabled"></v-text-field> -->
                                                    </v-flex>
                                                    <v-flex md5 hidden-sm-and-down>
                                                        <v-subheader>rtsp주소</v-subheader>
                                                    </v-flex>
                                                    <v-flex md7 sm12 xs12>
                                                        <v-text-field required v-model="cameraData.rtspUrl" :rules="nameRules" label="rtsp주소" :disabled="viewDisabled"></v-text-field>
                                                    </v-flex>
                                                    <v-flex md5 hidden-sm-and-down>
                                                        <v-subheader>mjpeg주소</v-subheader>
                                                    </v-flex>  
                                                    <v-flex md7 sm12 xs12>
                                                        <v-text-field required v-model="cameraData.mjpegUrl" :rules="nameRules" label="mjpeg주소" :disabled="viewDisabled"></v-text-field>
                                                    </v-flex>
                                            </v-layout>
                                        </v-container>
                                     </v-form>  
                                </v-card>
                                <v-toolbar dark>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="steps=next(steps)">다음</v-btn>
                                <v-btn color="primary" @click="dialogFormClose">취소</v-btn>
                                </v-toolbar>
                            </v-stepper-content>
                            <v-stepper-step :complete="steps > 2" step="2">상세정보</v-stepper-step>
                                <v-stepper-content step="2">
                                    <v-card id="stepsForm" class="mb-5">
                                        <v-container grid-list-md>
                                            <v-layout wrap>
                                                <v-flex md5 hidden-sm-and-down>
                                                    <v-subheader>카메라 기본영상 가로해상도</v-subheader>
                                                </v-flex>
                                                <v-flex md7 sm12 xs12>
                                                    <v-text-field v-model.number="cameraData.basicWidth" label="카메라 기본영상 가로해상도" required :disabled="viewDisabled"></v-text-field>
                                                </v-flex>
                                                <v-flex md5 hidden-sm-and-down>
                                                    <v-subheader>카메라 기본영상 세로해상도</v-subheader>
                                                </v-flex>
                                                <v-flex md7 sm12 xs12>
                                                    <v-text-field v-model.number="cameraData.basicHeight" label="카메라 기본영상 세로해상도" :disabled="viewDisabled"></v-text-field>
                                                </v-flex>
                                                <v-flex md5 hidden-sm-and-down>
                                                    <v-subheader>카메라 클릭설정시 가로해상도</v-subheader>
                                                </v-flex>
                                                <v-flex md7 sm12 xs12>
                                                    <v-text-field v-model.number="cameraData.clickWidth" label="카메라 클릭설정시 가로해상도" :disabled="viewDisabled"></v-text-field>
                                                </v-flex>
                                                <v-flex md5 hidden-sm-and-down>
                                                    <v-subheader>카메라 클릭설정시 세로해상도</v-subheader>
                                                </v-flex>
                                                <v-flex md7 sm12 xs12>
                                                    <v-text-field v-model.number="cameraData.clickHeight" label="카메라 클릭설정시 세로해상도" :disabled="viewDisabled"></v-text-field>
                                                </v-flex>
                                                <v-flex md5 hidden-sm-and-down>
                                                    <v-subheader>카메라 클릭x값</v-subheader>
                                                </v-flex>
                                                <v-flex md7 sm12 xs12>
                                                    <v-text-field v-model.number="cameraData.clickX" label="카메라 클릭x값" :disabled="viewDisabled"></v-text-field>
                                                </v-flex>
                                                <v-flex md5 hidden-sm-and-down>
                                                    <v-subheader>카메라 클릭y값</v-subheader>
                                                </v-flex>
                                                <v-flex md7 sm12 xs12>
                                                    <v-text-field v-model.number="cameraData.clickY" label="카메라 클릭y값" :disabled="viewDisabled"></v-text-field>
                                                </v-flex>
                                                <v-flex md5 hidden-sm-and-down>
                                                    <v-subheader>원클릭줌계산용 거리값</v-subheader>
                                                </v-flex>
                                                <v-flex md7 sm12 xs12>
                                                    <v-text-field v-model.number="cameraData.standardOneclickDistance" label="원클릭줌계산용 거리값" :disabled="viewDisabled"></v-text-field>
                                                </v-flex>
                                                <v-flex md5 hidden-sm-and-down>
                                                    <v-subheader>원클릭줌계산용 픽셀값</v-subheader>
                                                </v-flex>
                                                <v-flex md7 sm12 xs12>
                                                    <v-text-field v-model.number="cameraData.standardOneclickPixel" label="원클릭줌계산용 픽셀값" :disabled="viewDisabled"></v-text-field>
                                                </v-flex>                                                
                                            </v-layout>
                                        </v-container>
                                    </v-card>
                                    <v-toolbar dark>
                                <v-spacer></v-spacer>
                                    <v-btn color="primary" @click.native="steps = 1">이전</v-btn>
                                    <v-btn color="primary" @click="cameraSave">저장</v-btn>
                                    <v-btn color="primary" @click="dialogFormClose">취소</v-btn>
                                </v-toolbar>
                            </v-stepper-content>
                        </v-stepper>
                </v-dialog>                
                <v-btn color="primary" @click="addCameraDialog()" class="mb-2">카메라 추가</v-btn>
                <v-data-table
                    :headers="headers"
                    :items="cameraInfo"      
                    :pagination.sync="pagination"
                    hide-actions
                    class="elevation-1"
                >
                    <template slot="headerCell" slot-scope="props">
                        <v-tooltip bottom>
                            <span slot="activator">
                                {{ props.header.text }}
                            </span>
                            <span>
                                {{ props.header.text }}
                            </span>
                        </v-tooltip>
                    </template>
                    <template slot="items" slot-scope="props">
                        <tr>
                            <td  @click="showDetailInfo(props.item)">{{props.item.cameraId}}</td>
                            <td  @click="showDetailInfo(props.item)">{{props.item.cameraPlaceName}}</td>
                            <td  @click="showDetailInfo(props.item)">{{$dkEnfCodeFormatter.getCameraModel(props.item.cameraModelCd).cameraModelName}}</td>
                            <td  @click="showDetailInfo(props.item)">{{props.item.ip}}</td>                        
                                <v-btn icon @click="editCameraDialog(props.item)"><i class="material-icons">build</i></v-btn>
                                <v-btn icon @click="cameraRemove(props.item)"><i class="material-icons">delete_forever</i></v-btn>
                        </tr>
                    </template>
                </v-data-table>
                <div class="text-xs-center pt-2">
                    <dk-grid-page :pagination="pagination"></dk-grid-page>
                </div>        
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            steps:1,
            user:{
                "id" : "",
                "token" : ""
            },
            dialog: false,
            formTitle : "카메라 정보 추가",            
            cameraData: {
                "cameraId": "",                  // ⑤ 카메라아이디(string)
                "cameraPlaceName": "",           // ⑥ 카메라 지점명(string)
                "cameraModelCd": 0,              // ⑦ 카메라 모델명(number)
                "cameraInitHeight": 0,           // ⑧ 카메라 설치높이(number)
                "ip": "",                        // ⑨ 카메라 접속 ip(string)
                "port": 0,                       // ⑩ 카메라 접속 port(number)
                "accessId": "",                  // ⑪ 카메라 접속 id(string)
                "password": "",                  // ⑫ 카메라 접속 암호(string)
                "editType": 0,                   // ⑬ 카메라 설정유형선택(number)      
                "regionId":"",                   // ⑬ 행정지역아이디(string)
                "rtspUrl":"",                    // ⑭ rtsp주소(string)
                "mjpegUrl":"",                   // ⑮ mjpeg주소(string)
                "basicWidth":0,                  // ⑯ 카메라 기본영상 가로해상도(number)
                "basicHeight":0,                 // ⑰ 카메라 기본영상 세로해상도(number)
                "clickWidth":0,                  // ⑱ 카메라 클릭설정시 가로해상도(number)
                "clickHeight":0,                 // ⑲ 카메라 클릭설정시 세로해상도(number)
                "clickX":0,                      // ⑳ 카메라 클릭x값(number)
                "clickY":0,                      // ㉑ 카메라 클릭y값(number)
                "standardOneclickDistance": 0,   // ㉒ 원클릭줌계산용 거리값(number)
                "standardOneclickPixel": 0,      // ㉓ 원클릭줌계산용 픽셀값(number)
                "cameraModelName": ""
            },
            /*defaultItem: {
                name: '',
                calories: 0,
                fat: 0,
                carbs: 0,
                protein: 0
            },*/
            headers: [
                {
                    text: '지점ID',
                    align: 'left',
                    sortable: false,
                    value: 'cameraId'
                },
                { text: '지점명', value: 'cameraPlaceName' },
                { text: '카메라 모델', value: 'cameraModelCd' },
                { text: 'IP', value: 'ip' }
            ],
            cameraInfo: [],
            pagination:{
                rowsPerPage: 10
            },
            cameraModelInfo:this.$dkEnfCodeFormatter.getCameraModelInfo(),
            valid: false,
            name: '',
            nameRules: [
                v => !!v || '필수입력 값 입니다.',
            ],
             select: [v => !!v ||'필수입력 값 입니다.'], 
             disabled:false,
             viewDisabled : false,
             regionId: this.$dkEnfCodeFormatter.getCodeValue('REGION')
        }
    },
    created() {        
        this.$http.post(`${this.$config.getServerConfig().enf.api.video}/camera/db/getCameraListInfo`,{
            "user" : {
                "id" : this.user.id,
                "token" : this.user.token
            }
        })
        .then((response)=> {
            if (response.data.result.code != 1) {
                console.log("error")
            }
            this.cameraInfo = response.data.cameraInfo
        })
    },
    mounted(){
    },
    methods: {        
        addCameraDialog() {          
            this.formTitle = "카메라 정보 추가"
            Object.assign(this.cameraData, {
                "cameraId": "",
                "cameraPlaceName": "",
                "cameraModelCd": 0,
                "cameraInitHeight": 0,
                "ip": "",
                "port": 0,
                "accessId": "",
                "password": "",
                "editType": 1,  
                "regionId":"",  
                "rtspUrl":"",   
                "mjpegUrl":"",  
                "basicWidth":0, 
                "basicHeight":0,
                "clickWidth":0, 
                "clickHeight":0,
                "clickX":0,
                "clickY":0,
                "standardOneclickDistance": 0,
                "standardOneclickPixel": 0
            })            

            this.dialog = true
            this.disabled = false
            this.viewDisabled = false
        },
        editCameraDialog(item) {
            this.formTitle = "카메라 정보 수정"
            this.cameraData.editType = 2
            Object.assign(this.cameraData, item)
            this.dialog = true
            this.disabled = true
        },
        dialogFormClose() {
            this.cameraData = {
                "cameraId": "",
                "cameraPlaceName": "",
                "cameraModelCd": 0,
                "cameraInitHeight": 0,
                "ip": "",
                "port": 0,
                "accessId": "",
                "password": "",
                "regionId":"",  
                "rtspUrl":"",   
                "mjpegUrl":"",  
                "basicWidth":0, 
                "basicHeight":0,
                "clickWidth":0, 
                "clickHeight":0,
                "clickX":0,
                "clickY":0,
                "standardOneclickDistance": 0,
                "standardOneclickPixel": 0
            }
            this.dialog = false
            this.viewDisabled = false
        },
        cameraSave() {
            let params = this.cameraData
            this.$http.post(`${this.$config.getServerConfig().enf.api.video}/camera/db/setCameraInfo`, this.cameraData)
            .then(response =>{
                if(response.data.result.code != 1) {
                    console.log("error")
                }else{
                    alert("카메라정보가 저장되었습니다.")
                    window.location.reload(true)
                }
            })
        },
        cameraRemove(item){
            this.cameraData = item
            this.cameraData.editType = 3
            this.$http.post(`${this.$config.getServerConfig().enf.api.video}/camera/db/setCameraInfo` , {
                'cameraId' : this.cameraData.cameraId,
                'editType' : this.cameraData.editType
            })
            .then(response =>{
                if(response.data.result.code != 1){
                    console.log("error")
                }else{
                    alert("카메라정보가 삭제되었습니다.")
                    window.location.reload(true)
                }
            })
        },
        next(stepsVal){
            if(!this.valid){
                alert("필수값을 입력해주세요")
            }else{
                stepsVal += 1
            }
            return stepsVal;
        },
        showDetailInfo(item) {
            this.formTitle = "카메라 정보"
            Object.assign(this.cameraData, item)
            this.dialog = true
            this.disabled = true
            this.viewDisabled = true
            
        }
    }
}
</script>


<style>
#stepsForm{
    height : 900px
}
</style>
