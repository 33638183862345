import pageMain from './pageMain.vue';
import pageDisasterInfo from './pageDisasterInfo.vue';
import pageDiseaseInfo from './pageDiseaseInfo.vue';
import pageFcltGis from './pageFcltGis.vue';
import pageCctvGis from './pageCctvGis';
import pageMaintenance from './pageMaintenance.vue';
import pageCovid19 from './pageCovid19.vue';
import PageTelephonePaper from './PageTelephonePaper.vue';
import PageNotice from './PageNotice.vue';
import pageflooding from './pageflooding.vue';
import pageSpreadSituation from './pageSpreadSituation.vue';

export default [
  { component: pageMain, key: 'Home', path: '/' },
  { component: pageDisasterInfo, key: 'disasterInfo', name: '재난 데이터 서비스', path: '/disasterInfo' },
  { component: pageDiseaseInfo, key: 'diseaseInfo', name: '가축 질병', path: '/diseaseInfo' },
  { component: pageFcltGis, key: 'fcltGis', name: '시설물 시스템', path: '/fcltGis' },
  { component: pageCctvGis, key: 'cctvGis', name: '생활방범 시스템', path: '/cctvGis' },
  { component: pageMaintenance, key: 'maintenance', name: '유지 관리 내역&현황', path: '/maintenance' },
  { component: pageCovid19, key: 'covid19', name: '코로나', path: '/covid19' },
  { component: PageTelephonePaper, key: 'telephonePaper', name: '전화번호부', path: '/telephonePaper' },
  { component: PageNotice, key: 'Notice', name: '공지사항', path: '/Notice' },
  { component: pageflooding, key: 'flooding', name: '침수 지역 데이터 서비스', path: '/flooding' },
  { component: pageSpreadSituation, key: 'spreadSituation', name: '상황전파', path: '/spreadSituation' },
];
