<template>
  <div class="fill-width-height el-container" id="vmsLibrary">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <el-row>
              <el-col :xs="10" :sm="12" :md="12" class="grid-header-menu">
                <el-button type="primary" @click="openFormDialog" size="medium" style="margin-top: 7px;">
                  <i class="fas fa-plus-circle">&nbsp;추가</i>
                </el-button>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="24">
            <ul class="vms-library">
              <li v-for="(item, index) in vmsLibraries" :key="index" @click="showVmsLibrary(item)">
                <img :src="getThumbImg(item)" class="vmsLibrary-thumb-img" />
              </li>
            </ul>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <form-vms-library ref="formDialog"></form-vms-library>
    <el-dialog title="VMS 라이브러리" :visible.sync="vmsLibraryDialog" class="el-dialog-aside">
      <el-container class="dialog-container">
        <el-container>
          <img :src="getThumbImg(selectVmsLibrary)" class="vmsLibrary-select-img" />
        </el-container>
        <el-aside width="360px">
          <div class="info-panel">
            <table class="dk-table">
              <colgroup>
                <col style="width: 180px;" />
                <col style="width: auto;" />
              </colgroup>
              <tbody>
                <tr>
                  <th scople="row">라이브러리 ID</th>
                  <td>{{ selectVmsLibrary.vmsLibraryId }}</td>
                </tr>
                <tr>
                  <th scople="row">라이브러리 명칭</th>
                  <td>{{ selectVmsLibrary.vmsLibraryName }}</td>
                </tr>
                <tr>
                  <th scople="row">이미지 포멧</th>
                  <td>{{ selectVmsLibrary.imageType }}</td>
                </tr>
                <tr>
                  <th scople="row">카테고리</th>
                  <td>{{ getCategory(selectVmsLibrary.vmsCategory) }}</td>
                </tr>
                <tr>
                  <td colspan="2" style="text-align: right;">
                    <el-button size="small" @click="updateFormDialog(selectVmsLibrary)">수정</el-button>
                    <el-button size="small" type="danger" @click="remove(selectVmsLibrary)">삭제</el-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-aside>
      </el-container>
    </el-dialog>
  </div>
</template>

<script>
// import dkelpagination from '@/components/dkElPagination.vue';
import formVmsLibrary from './form/formVmsLibrary.vue';

export default {
  components: {
    // 'dk-el-pagination': dkelpagination,
    'form-vms-library': formVmsLibrary,
  },
  created() {
    this.getVmsLibraries(1);
    this.$on('procDataComplete', () => {
      this.getVmsLibraries();
      this.vmsLibraryDialog = false;
    });
  },
  data() {
    return {
      vmsLibraries: [],
      limit: 100,
      totalDocs: 0,
      shownInfoPanel: false,
      selectVmsLibrary: {
        vmsLibraryName: '',
        files: [],
      },
      vmsLibraryDialog: false,
    };
  },
  methods: {
    async getVmsLibraries(pageNum, keyword) {
      let me = this;
      let param = {};
      // let param = {
      //     page: pageNum || this.$refs.pagination.getPage(),
      //     limit: this.limit
      // }
      if (keyword !== '' && keyword) {
        param.keyword = keyword;
      }

      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsLibrary`, {
        params: param,
      });
      me.vmsLibraries = response.data.docs;
      me.totalDocs = response.data.totalDocs;

      // this.$store.dispatch('vmsLibrary/getVmsLibrary', param).then(() => {
      //   me.vmsLibraries = me.$store.getters['vmsLibrary/getVmsLibrary'];
      //   me.totalDocs = me.$store.getters['vmsLibrary/getPagination'].totalDocs;
      //   // me.$refs.infoPanel.close()
      // });
    },
    updateFormDialog(row) {
      this.$refs.formDialog.showDialog(row);
    },
    openFormDialog() {
      this.$refs.formDialog.showDialog();
    },
    pagingProc(pageNum) {
      this.getVmsLibraries();
    },
    getThumbImg(vmsLibrary) {
      let thumb = 'static/images/no-image.jpg';
      if (!vmsLibrary.images) {
        return thumb;
      }
      if (vmsLibrary.images.length == 0) {
        return thumb;
      }
      thumb = `${vmsLibrary.images[0].serverFilePath}`;
      return thumb;
    },
    showVmsLibrary(vmsLibrary) {
      this.selectVmsLibrary = vmsLibrary;
      this.vmsLibraryDialog = true;
    },
    remove(args) {
      this.$confirm(`${args.vmsLibraryName} VMS 라이브러리를 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/vmsLibrary/delete`, {
              data: args,
            });
            this.getVmsLibraries();
            this.$message({
              type: 'success',
              message: `${args.vmsLibraryName} VMS 라이브러리 삭제 완료`,
            });
          } catch (err) {
            this.$message({
              type: 'info',
              message: `${args.vmsLibraryName} VMS 라이브러리 삭제 중 에러 발생`,
            });
          }
          this.vmsLibraryDialog = false;
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${args.vmsLibraryName} VMS 라이브러리 삭제 취소`,
          });
          this.vmsLibraryDialog = false;
        });
    },
    getCategory(category) {
      if (category == 'traffic') {
        return '교통';
      } else {
        return '기타';
      }
    },
  },
};
</script>

<style scoped></style>
