var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.formTitle,
        visible: _vm.formDialog,
        "close-on-click-modal": false,
        top: "5vh",
        id: "formConfigure"
      },
      on: {
        "update:visible": function($event) {
          _vm.formDialog = $event
        },
        close: _vm.hideDialog
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form0",
              attrs: {
                model: _vm.formData,
                "label-width": "120px",
                size: "medium",
                "label-position": _vm.$isMobile ? "top" : "right"
              }
            },
            [
              _c(
                "el-tabs",
                {
                  staticStyle: { height: "600px" },
                  attrs: { "tab-position": _vm.tabPosition }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "정보 수정" } },
                    [
                      _c("el-col", { attrs: { sm: 24 } }, [
                        _c("h3", [_vm._v("기관 정보 입력")])
                      ]),
                      _c(
                        "el-col",
                        { attrs: { md: 18, sm: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "기관명",
                                prop: "orgName",
                                rules: [
                                  {
                                    required: true,
                                    message: "기관 명을 입력해 주세요",
                                    trigger: "blur"
                                  }
                                ]
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "기관명" },
                                model: {
                                  value: _vm.formData.orgName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "orgName", $$v)
                                  },
                                  expression: "formData.orgName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { md: 18, sm: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "플랫폼 타이틀", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "타이틀" },
                                model: {
                                  value: _vm.formData.title,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "title", $$v)
                                  },
                                  expression: "formData.title"
                                }
                              }),
                              _c("span", [
                                _vm._v(
                                  "플랫폼 이용시 웹브라우저 상단에 노출되는 타이틀명을 입력합니다."
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { md: 18, sm: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "센터 연락처", prop: "phone" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "연락처" },
                                model: {
                                  value: _vm.formData.phone,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formData, "phone", $$v)
                                  },
                                  expression: "formData.phone"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.checkGisApi
                        ? _c(
                            "el-col",
                            { attrs: { md: 18, sm: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "GIS API 키",
                                    prop: "gis.apiKey",
                                    rules: [
                                      {
                                        required: true,
                                        message:
                                          "선택한 OpenGIS API의 API KEY를 입력해 주세요",
                                        trigger: "blur"
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "Open GIS API KEY" },
                                    model: {
                                      value: _vm.formData.gis.apiKey,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.formData.gis,
                                          "apiKey",
                                          $$v
                                        )
                                      },
                                      expression: "formData.gis.apiKey"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.checkGisApi,
                              expression: "checkGisApi"
                            }
                          ],
                          staticClass: "el-col-md-offset-1 el-col-sm-offset-0",
                          attrs: { md: 22, sm: 24 }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "GIS 기본 위치를 설정합니다. 마우스 휠+드래그를 이용하여 원하시는 위치와 스케일을 설정하여 주세요."
                            )
                          ]),
                          _c("dk-gis", {
                            ref: "dkgis",
                            staticStyle: { height: "350px" },
                            attrs: {
                              gisApiInfo: _vm.gisApiInfo,
                              centerChanged: _vm.centerChanged,
                              apiLoadComplete: _vm.apiLoadComplete
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "이미지 업로드" } },
                    [
                      _c("el-col", { attrs: { sm: 24 } }, [
                        _c("h3", [_vm._v("이미지 업로드")])
                      ]),
                      _c(
                        "el-col",
                        {
                          staticStyle: { position: "relative" },
                          attrs: { sm: 24 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "플랫폼 로고 이미지",
                                "label-width": "130px",
                                "label-position": "left"
                              }
                            },
                            [
                              _c("div", {
                                staticClass: "logo-enroll-bg w-100"
                              }),
                              _c(
                                "el-upload",
                                {
                                  ref: "uploadLogo",
                                  staticClass: "avatar-uploader",
                                  attrs: {
                                    action: _vm.uploadPath,
                                    name: "uploadFile",
                                    data: { uploadPath: "logo" },
                                    "before-upload": _vm.checkUploadImage,
                                    "on-change": _vm.procUploadImage,
                                    "on-remove": _vm.procRemoveImage,
                                    "file-list": _vm.formData.logo,
                                    "show-file-list": false
                                  }
                                },
                                [
                                  _vm.imageUrl
                                    ? _c("img", {
                                        staticClass: "avatar",
                                        attrs: {
                                          src:
                                            _vm.$config.getServerConfig().image
                                              .url + _vm.imageUrl
                                        }
                                      })
                                    : _c("span", [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-plus avatar-uploader-icon"
                                        })
                                      ])
                                ]
                              ),
                              _c("div", { staticClass: "logo-about" }, [
                                _c("div", [
                                  _vm._v(
                                    "플랫폼 상단에 표출할 로고 이미지입니다."
                                  )
                                ]),
                                _c("div", [
                                  _vm._v(
                                    "최적화 사이즈(123X123) / 파일 형식 (png, jpg, gif)"
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { position: "relative" },
                          attrs: { sm: 24 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "모바일 커버 이미지",
                                "label-width": "130px",
                                "label-position": "left"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "w-100 mb-img" },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      ref: "uploadMobile",
                                      staticClass:
                                        "avatar-uploader avatar-uploader-mb",
                                      attrs: {
                                        action: _vm.uploadPath,
                                        name: "uploadFile",
                                        data: { uploadPath: "mobile" },
                                        "before-upload": _vm.checkUploadImage,
                                        "on-change": _vm.mobileProcUploadImage,
                                        "on-remove": _vm.procRemoveImage,
                                        "file-list": _vm.formData.mobile,
                                        "show-file-list": false
                                      }
                                    },
                                    [
                                      _vm.mobileImageUrl
                                        ? _c("img", {
                                            staticClass: "avatar",
                                            attrs: {
                                              src:
                                                _vm.$config.getServerConfig()
                                                  .image.url +
                                                _vm.mobileImageUrl
                                            }
                                          })
                                        : _c("span", [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-plus avatar-uploader-icon"
                                            })
                                          ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("div", { staticClass: "logo-about" }, [
                                _c("div", [
                                  _vm._v(
                                    "플랫폼의 모바일 메인 페이지 커버 이미지입니다."
                                  )
                                ]),
                                _c("div", [
                                  _vm._v(
                                    "최적화 사이즈(460X350) / 파일 형식 (png, jpg, gif)"
                                  )
                                ])
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-bottom" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  on: { click: _vm.hideDialog }
                },
                [_vm._v("취소")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-right": "5px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.save }
                },
                [_vm._v("저장")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }