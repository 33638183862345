<template>
  <el-row>
    <el-col :span="12" style="padding: 18px; box-sizing: border-box;">
      <doughnut-chart
        :chart-data="fcltInstPnstData"
        :height="200"
        :options="{
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
            position: 'bottom',
            align: 'center',
            labels: {
              padding: 10,
              boxWidth: 15,
            },
          },
        }"
        :centerText="{
          display: true,
          text: 'aaaa',
        }"
      ></doughnut-chart>
    </el-col>
    <el-col :span="12" class="label-wrap">
      <div v-for="(item, idx) in fcltInstPnstData.labels" :key="idx" class="color-label">
        <span class="color-marker" :style="getColorMarker(idx)"></span>
        {{ item }}
      </div>
    </el-col>
  </el-row>
</template>

<script>
import doughnutChart from '@/components/chart/doughnutChart.js';

export default {
  components: {
    doughnutChart,
  },
  props: {
    height: {
      type: Number,
      default: 0,
    },
    isChartOnly: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/statistics/fcltInstPnst`, {}).then((result) => {
      let data = result.data;
      this.fcltInstPnstData = {
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
        labels: [],
        total: 0,
      };
      for (var i = 0; i < data.length; i++) {
        this.fcltInstPnstData.datasets[0].data[i] = data[i].count;
        this.fcltInstPnstData.datasets[0].backgroundColor[i] = this.chartColors[i];
        this.fcltInstPnstData.labels[i] = data[i]._id;
        this.fcltInstPnstData.total += data[i].count;
      }
    });
  },
  methods: {
    getColorMarker(idx) {
      return `background-color:${this.chartColors[idx]}`;
    },
  },
  data() {
    return {
      orgName: this.$config.getSiteConfig().orgName,
      chartColors: [
        '#1e88e5',
        '#7559ff',
        '#26c6da',
        '#E91E63',
        '#ebeef3',
        '#2196F3',
        '#00BCD4',
        '#4CAF50',
        '#8BC34A',
        '#FF9800',
        '#795548',
        '#9E9E9E',
        '#607D8B',
        '#D32F2F',
        '#616161',
        '#E64A19',
      ],
      fcltInstPnstData: {
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
        labels: [],
        total: 0,
      },
    };
  },
};
</script>

<style scoped>
.fclt-inst-chart-legend-color {
  display: inline-flex;
  width: 10px;
  height: 10px;
  margin-right: 6px;
  border: 1px solid #efefef;
}
.label-wrap {
  position: absolute;
  height: 100%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.color-label {
  font-size: 12px;
  padding: 5px 0;
}

.color-marker {
  width: 20px;
  height: 12px;
  display: inline-block;
  margin-right: 4px;
}
</style>
