var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "formControlLogDetail" } },
    [
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-aside",
          attrs: {
            visible: _vm.isDetailDialog,
            "close-on-press-escape": false,
            "close-on-click-modal": false,
            top: "5vh",
            title: "[" + _vm.controlLog.seq + "]관제 일지 상세",
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.isDetailDialog = $event
            }
          }
        },
        [
          _c(
            "el-container",
            { staticClass: "dialog-container" },
            [
              _c(
                "el-row",
                { staticStyle: { padding: "30px 10px", width: "100%" } },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", [
                      _c(
                        "table",
                        { staticClass: "dk-table dk-table-no-padding" },
                        [
                          _c("colgroup", [
                            _c("col", { staticStyle: { width: "20%" } }),
                            _c("col", { staticStyle: { width: "30%" } }),
                            _c("col", { staticStyle: { width: "20%" } }),
                            _c("col", { staticStyle: { width: "30%" } })
                          ]),
                          _c("tbody", [
                            _c("tr", [
                              _c("th", { attrs: { scople: "row" } }, [
                                _vm._v("근무자")
                              ]),
                              _c("td", [_vm._v(_vm._s(_vm.controlLog.worker))]),
                              _c("th", { attrs: { scople: "row" } }, [
                                _vm._v("근무조")
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.controlLog.workTime))
                              ])
                            ]),
                            _c("tr", [
                              _c("th", { attrs: { scople: "row" } }, [
                                _vm._v("발생일자")
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.controlLog.occurDate1))
                              ]),
                              _c("th", { attrs: { scople: "row" } }, [
                                _vm._v("발생시간")
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.controlLog.occurDate2))
                              ])
                            ]),
                            _c("tr", [
                              _c("th", { attrs: { scople: "row" } }, [
                                _vm._v("CCTV명")
                              ]),
                              _c("td", { attrs: { colspan: "3" } }, [
                                _vm._v(_vm._s(_vm.controlLog.fcltId))
                              ])
                            ]),
                            _c("tr", [
                              _c("th", { attrs: { scople: "row" } }, [
                                _vm._v("관제유형")
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.controlLog.controlType))
                              ]),
                              _c("th", { attrs: { scople: "row" } }, [
                                _vm._v("관제유형 소분류")
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.controlLog.controlTypeSubClass)
                                )
                              ])
                            ]),
                            _c("tr", [
                              _c("th", { attrs: { scople: "row" } }, [
                                _vm._v("신고처")
                              ]),
                              _c("td", { attrs: { colspan: "3" } }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.controlLog.reportingFrom) +
                                    "\n                    " +
                                    _vm._s(
                                      _vm.controlLog.etcReportingAgency != ""
                                        ? "[" +
                                            _vm.controlLog.etcReportingAgency +
                                            "]"
                                        : ""
                                    ) +
                                    "\n                  "
                                )
                              ])
                            ]),
                            _c("tr", [
                              _c("th", { attrs: { scople: "row" } }, [
                                _vm._v("지령여부")
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.controlLog.isOrder ? "있음" : "없음"
                                  )
                                )
                              ]),
                              _c("th", { attrs: { scople: "row" } }, [
                                _vm._v("지령번호")
                              ]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.controlLog.orderNum))
                              ])
                            ]),
                            _c("tr", [
                              _c(
                                "th",
                                { attrs: { scople: "row", height: "100" } },
                                [_vm._v("관제사항")]
                              ),
                              _c("td", { attrs: { colspan: "3" } }, [
                                _vm._v(_vm._s(_vm.controlLog.content))
                              ])
                            ]),
                            _c("tr", [
                              _c("th", { attrs: { scople: "row" } }, [
                                _vm._v("첨부파일")
                              ]),
                              _c(
                                "td",
                                { attrs: { colspan: "3" } },
                                _vm._l(_vm.controlLog.file, function(
                                  item,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "a-tag-files" },
                                    [
                                      _c("a", { attrs: { href: item.url } }, [
                                        _c("i", {
                                          staticClass: "el-icon-document"
                                        }),
                                        _vm._v(" " + _vm._s(item.name))
                                      ])
                                    ]
                                  )
                                }),
                                0
                              )
                            ])
                          ])
                        ]
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.openUpdateDialog } }, [
                _vm._v("수정")
              ]),
              _c("el-button", { on: { click: _vm.hideDialog } }, [
                _vm._v("닫기")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }