<template>
  <el-dialog :title="formTitle" :visible.sync="formDialog" top="5vh" width="80%" @close="hideDialog" @open="openDialog" :close-on-click-modal="false">
    <div>
      <!-- <div>
        <table class="dk-table">
          <colgroup>
            <col style="width: 20%;" />
            <col style="width: auto;" />
            <col style="width: 20%;" />
            <col style="width: auto;" />
          </colgroup>
          <tbody>
            <tr>
              <th scople="row">시설물 ID</th>
              <td>{{ selectFcltMarkerData.fcltId }}</td>
              <th scople="row">시설물 명칭</th>
              <td>{{ selectFcltMarkerData.fcltName }}</td>
            </tr>
            <tr>
              <th scople="row">지번 주소</th>
              <td>{{ selectFcltMarkerData.setNumberAddr }}</td>
              <th scople="row">도로명 주소</th>
              <td>{{ selectFcltMarkerData.setRoadAddr }}</td>
            </tr>
            <tr>
              <th scople="row">경도</th>
              <td>{{ selectFcltMarkerData.gisLng }}</td>
              <th scople="row">위도</th>
              <td>{{ selectFcltMarkerData.gisLat }}</td>
            </tr>
          </tbody>
        </table>
      </div> -->
      <el-row class="form-gis-wrap">
        <el-col :span="9" class="straight-road">
          <!-- <span 
              v-for="(item,idx) in roadLinkData"
              :key="idx"
              style="position:absolute; width:240px; height:50px; border:1px solid #acacac; display:inline-block;"
            >
              <span style="">{{item.f_node_name}}</span>
              <span v-if="idx+1===roadLinkData.length">{{item.t_node_name}}</span>
          </span>-->
          <dk-straight-road
            ref="dkStraightRoad"
            :roadLinkData="roadLinkData"
            :selectLink="selectStraightRoadLink"
            @onLoadRoadLinkData="onLoadRoadLinkData"
            @updateActivateLinkData="updateActivateLinkData"
          ></dk-straight-road>
        </el-col>
        <el-col :span="15" class="map-wrap">
          <dk-gis
            fill-height
            :gisApiInfo="gisApiInfo"
            :markerData="markerData"
            :trafficLayerData="trafficLayerData"
            :selectTrafficLayer="selectTrafficLayer"
            ref="dkGis"
          ></dk-gis>
          <div class="gis-vms-info" v-show="selectFcltMarkerData.fcltName !== ''">
            <h4 class="gis-vms-info-title">{{ selectFcltMarkerData.fcltName }}</h4>
            <div class="gis-vms-info-body">{{ selectFcltMarkerData.setNumberAddr }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row style="padding: 10px 30px 0 30px;">
        <el-col :span="10">
          <el-form :rules="rules" ref="form0" :model="formData" label-width="150px" size="small">
            <el-row>
              <el-col :span="18">
                <el-form-item label="정보제공구간 ID" prop="vmsIfscId">
                  <el-input minlength="1" maxlength="5" v-model="formData.vmsIfscId" placeholder="정보제공구간 ID"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="!updateFlag">
                <el-button size="small" style="margin: 1px 5px;" @click="makeVmsNewIfscId">자동 ID 생성</el-button>
              </el-col>
              <el-col :span="24">
                <el-form-item label="정보제공구간 명칭" prop="vmsIfscName">
                  <el-input v-model="formData.vmsIfscName" placeholder="정보제공구간 명칭"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="시작 구간 명칭" prop="startNodeName">
                  <el-input v-model="formData.startNodeName" placeholder="시작 구간 명칭"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="종료 구간 명칭" prop="endNodeName">
                  <el-input v-model="formData.endNodeName" placeholder="종료 구간 명칭"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="정보제공구간 도로명칭" prop="vmsIfscRoadName">
                  <el-input v-model="formData.vmsIfscRoadName" placeholder="정보제공구간 도로명칭"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="14" class="link-list">
          <table class="el-table el-table--mini user-table">
            <tr>
              <th>seq</th>
              <th>링크ID</th>
              <th>도로명</th>
              <th>시작노드명</th>
              <th>종료노드명</th>
              <th></th>
            </tr>
            <tr v-for="(item, idx) in editIfscLinkRltn" :key="idx" @click="selectStraightRoadLink(item)" style="cursor: pointer;">
              <td>{{ item.seq }}</td>
              <td>{{ item.link_id }}</td>
              <td>{{ item.road_name }}</td>
              <td>{{ item.f_node_name }}</td>
              <td>{{ item.t_node_name }}</td>
              <td>
                <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="removeLinkData(item)"></el-button>
              </td>
            </tr>
          </table>
        </el-col>
      </el-row>
      <div class="form-bottom">
        <el-button @click="hideDialog" style="float: right;">취소</el-button>
        <el-button type="primary" @click="save" style="float: right; margin-right: 5px;">저장</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import _ from 'lodash';
import dkgismain from '@/components/dkGis/dkGisMain.vue';
// import incidentConst from '@/traffic/incidentConst.js'
import dkStraightRoad from './dkStraightRoad.vue';

export default {
  components: {
    'dk-gis': dkgismain,
    'dk-straight-road': dkStraightRoad,
  },
  data() {
    var checkVmsIfscId = async (value) => {
      try {
        let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/checkVmsIfscId`, {
          params: {
            vmsIfscId: value,
          },
        });
        if (result.data.msg) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    };

    var validateVmsIfscId = async (rule, value, callback) => {
      if (!this.updateFlag) {
        if (value === '') {
          callback(new Error('VMS 정보제공구간 ID를 입력해 주세요'));
        } else {
          let chk = await checkVmsIfscId(value);
          if (!chk) {
            callback(new Error('이미 등록된 VMS 정보제공구간 ID입니다'));
          } else {
            callback();
          }
        }
      }
    };

    return {
      formDialog: false,
      formTitle: '',
      updateFlag: false,
      formData: {
        vmsIfscId: '',
        vmsIfscName: '',
        vmsIfscRoadName: '',
        startNodeName: '',
        endNodeName: '',
      },
      selectedLinkData: null,
      editIfscLinkRltn: [],
      roadLinkData: [],
      markerData: [],
      rules: {
        vmsIfscId: [
          {
            required: true,
            validator: validateVmsIfscId,
            trigger: ['change', 'blur'],
          },
        ],
        vmsIfscName: [
          {
            required: true,
            message: '정보제공구간 명칭을 입력해 주세요',
            trigger: ['change', 'blur'],
          },
        ],
        startNodeName: [
          {
            required: true,
            message: '시작 구간 명칭을 입력해 주세요',
            trigger: ['change', 'blur'],
          },
        ],
        endNodeName: [
          {
            required: true,
            message: '종료 구간 명칭을 입력해 주세요',
            trigger: ['change', 'blur'],
          },
        ],
      },
      markerData: [
        {
          seq: 0,
          draggable: true,
          geometry: {
            lng: 127.10056150323506,
            lat: 37.489008855218195,
          },
          properties: {},
        },
      ],
      trafficLayerData: {
        base: [
          {
            name: 'link_traf',
            url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
            //params: {'LAYERS': 'cite:gimpo_ifsc_traf', 'TILED': true,  'STYLES': 'cite:gimpo_utis_deselect_style' },
            params: { LAYERS: 'cite:vms_ifsc_config', TILED: true },
            default: true,
            idKey: 'link_id',
          },
        ],
        select: {
          source: {
            url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
          },
          mode: 'Single',
        },
      },
      isGisInit: false,
      currentGisApi: null,
      trafficLayers: [
        { level: 8, layer: 'gimpo_link_level_16' },
        { level: 7, layer: 'gimpo_link_level_15' },
        { level: 6, layer: 'gimpo_link_level_14' },
        { level: 5, layer: 'gimpo_link_level_13' },
        { level: 4, layer: 'gimpo_link_level_12' },
        { level: 3, layer: 'gimpo_link_level_12' },
      ],
      geometry: {},
      selectFcltMarkerData: {
        fcltId: '',
        fcltName: '',
        fcltTypeName: '',
        fcltTypeId: '',
        regionId: '',
        regionName: '',
        setDate: '',
        setNumberAddr: '',
        setRoadAddr: '',
        gisLat: '',
        gisLng: '',
        equip: [],
        fcltTypeData: {},
        images: [],
        files: [],
      },
    };
  },
  created() {
    this.getGisVmsMarker();
  },
  props: {
    gisApiInfo: {
      type: Array,
      // gis 기본 설정값을 띄우는 요부분은 나중에 vuex에서 받아서 처리할것
      default() {
        return [];
      },
    },
    // geometry: {
    //     type: Object,
    //     default: null
    // }
  },
  methods: {
    save() {
      this.$refs.form0.validate(async (valid) => {
        if (valid) {
          let formData = _.cloneDeep(this.formData);
          // formData.fcltId = this.selectFcltMarkerData.fcltId;
          // formData.fcltName = this.selectFcltMarkerData.fcltName;
          // formData.fcltTypeId = this.selectFcltMarkerData.fcltTypeId;
          // formData.setRoadAddr = this.selectFcltMarkerData.setRoadAddr;
          // formData.setNumberAddr = this.selectFcltMarkerData.setNumberAddr;
          // formData.setDate = this.selectFcltMarkerData.setDate;
          formData.linkRltn = [];
          // formData.del_yn = 'N';
          // formData.sect_lngt = 0;
          // formData.sect_grad_cd = 'A';
          let saveFunc = this.updateFlag ? 'api/vmsIfscInfo/update' : 'api/vmsIfscInfo/add';
          this.editIfscLinkRltn.forEach((link) => {
            let linkData = link;
            linkData.link_id = parseInt(link.link_id);
            formData.linkRltn.push(linkData);
          });

          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/${saveFunc}`, {
              data: formData,
            });

            this.$message({
              type: 'success',
              message: `VMS 정보제공구간 등록 완료`,
            });
            this.$emit('procDataComplete', formData);
            this.hideDialog();
          } catch (err) {
            console.error(err);
            this.$message({
              type: 'error',
              message: 'VMS 정보제공구간 등록 실패',
            });
          }
        }
        // this.$http
        //   .post(`${this.$config.getServerConfig().core.api}/core/${saveFunc}`, {
        //     data: formData,
        //   })
        //   .then(() => {
        //     this.$message({
        //       type: 'success',
        //       message: `VMS 정보제공구간 등록 완료`,
        //     });
        //     this.$emit('procDataComplete', formData);
        //     this.hideDialog();
        //   })
        //   .error(() => {

        //   });
      });
    },
    showDialog(ifsc) {
      if (ifsc) {
        this.updateFlag = true;
        this.formTitle = 'VMS 정보제공구간 수정';
        if (ifsc.linkData && ifsc.linkData.length > 0) {
          this.getLinksByRoad(ifsc.linkData[0]);
          this.selectedLinkData = ifsc.linkData;
        }
        this.formData = _.cloneDeep(ifsc);
      } else {
        this.updateFlag = false;
        this.formTitle = 'VMS 정보제공구간 추가';
        this.resetData();
      }
      // this.selectFcltMarkerData = _.cloneDeep(vmsFcltInfo);
      this.formDialog = true;
      this.getGisVmsMarker();
      this.$forceUpdate();
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.dkGis.setTrafficLayer(true);
          // this.$refs.dkGis.setCenter({ lat: this.selectFcltMarkerData.gisLat, lng: this.selectFcltMarkerData.gisLng });
          // this.layerSourceName = this.$refs.dkGis.setTrafficLayerSource('link_traf');
          if (ifsc && ifsc.linkData && ifsc.linkData.length > 0) {
            this.$store.commit('dkStraightRoad/setActivatedLinkDataArray', ifsc.linkData);
            // this.selectStraightRoadLink(ifsc.linkData);
            this.updateActivateLinkData();
          }
        }, 100);
      });
    },
    getGisVmsMarker() {
      this.markerData = [];
      return this.$store
        .dispatch('gis/actGetFcltMarker', {
          // fcltId: this.selectFcltMarkerData.fcltId,
        })
        .then(() => {
          this.markerData = this.$store.getters['gis/getFcltMarker'];
        });
    },
    apiLoadComplete(currentGisApi) {
      this.isGisInit = true;
      this.currentGisApi = currentGisApi;
      this.$refs.dkGis.setTrafficLayer(true);
    },
    getLinkData() {},
    openDialog() {
      // this.fcltManageDialogGisInit()
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    resetData() {
      this.formData = {
        vmsIfscId: '',
        vmsIfscName: '',
        vmsIfscRoadName: '',
        startNodeName: '',
        endNodeName: '',
      };
      this.selectFcltMarkerData = {
        fcltId: '',
        fcltName: '',
        fcltTypeName: '',
        fcltTypeId: '',
        regionId: '',
        regionName: '',
        setDate: '',
        setNumberAddr: '',
        setRoadAddr: '',
        gisLat: '',
        gisLng: '',
        equip: [],
        fcltTypeData: {},
        images: [],
        files: [],
      };
      this.editIfscLinkRltn = [];
      this.selectedLinkData = null;
      this.roadLinkData = [];
      if (this.$refs.dkStraightRoad) {
        this.$store.dispatch('dkStraightRoad/resetActivatedLinkData');
      }
      this.isGisInit = false;
    },

    changeIsPopup(val) {
      if (val) {
        //if (!this.formData.endDate) {
        //  this.formData.endDate = new Date();
        //}
      }
    },

    // setCenter(geometry) {
    //   this.$refs.fcltManageDialogGis.setCenter(geometry);
    //   this.markerData[0].geometry = geometry;
    // },

    async getLinksByRoad(linkData) {
      // const size = 5;
      let rowData = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/linksByRoad`, {
        params: linkData,
      });
      if (!rowData.data || rowData.data.length == 0) {
        return false;
      }
      // let centerLinkData = rowData.data.find((d) => { return d.link_id == linkData.link_id })
      // let currentLinkData = this.$lodash.cloneDeep(centerLinkData)
      // let prevLinkData = []
      // let roadLinkData = []
      // prevLinkData.push(centerLinkData)
      // for (var i=0; i<size; i++) {
      //   let tmpLinkData = rowData.data.find((d) => {
      //     return d.t_node === currentLinkData.f_node && d.f_node !== currentLinkData.t_node
      //   })
      //   if (tmpLinkData) {      //     currentLinkData = tmpLinkData
      //     prevLinkData.push(tmpLinkData)
      //   }
      // }
      // roadLinkData = this.$lodash.reverse(prevLinkData)
      // currentLinkData = centerLinkData
      // for (var i=0; i<size; i++) {
      //   let tmpLinkData = rowData.data.find((d) => {
      //     return d.f_node === currentLinkData.t_node && d.t_node !== currentLinkData.f_node
      //   })
      //   if (tmpLinkData) {
      //     currentLinkData = tmpLinkData
      //     roadLinkData.push(tmpLinkData)
      //   }
      // }
      this.roadLinkData = rowData.data;
      // console.log(roadLinkData)
    },
    selectTrafficLayer(feature) {
      this.selectedLinkData = feature;
      this.getLinksByRoad(feature);
    },
    selectStraightRoadLink(linkData) {
      let level = parseInt(this.$refs.dkGis.getLevel());
      let ll = this.trafficLayers.find((a) => {
        return a.level === level;
      });
      this.$refs.dkGis.focusTrafficLayer('link_id', linkData.link_id, ll.layer);
    },

    updateActivateLinkData() {
      this.editIfscLinkRltn = this.$store.getters['dkStraightRoad/getActivatedLinkData'];
      this.$forceUpdate();
    },
    removeLinkData(linkData) {
      this.$refs.dkStraightRoad.removeLinkData(linkData);
    },
    onLoadRoadLinkData() {
      this.$refs.dkStraightRoad.focusLinkData(this.selectedLinkData);
    },
    async makeVmsNewIfscId() {
      let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsIfscInfo/makeNewVmsIfscId`);
      if (result.data) {
        this.formData.vmsIfscId = result.data.newVmsIfscId;
      }
    },
    numberOnly(event) {
      console.log(event);
      return event.charCode >= 48 && event.charCode <= 57;
    },
  },
};
</script>

<style scoped>
.form-gis-wrap {
  background-color: #f0f0f0;
  height: 300px;
}

.form-gis-wrap .straight-road {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.form-gis-wrap .map-wrap {
  height: 300px;
  position: relative;
}

.form-gis-wrap .map-wrap .vue-dkgis-wrap {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.link-list {
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
