<template>
  <div class="notice">
    <div v-if="totalDocs > 0">
      <div v-for="(item, index) in notices" :key="index" class="notice-card" @click="selectRow(item)">
        <div>
          <span class="notice-title">{{ item.title }}</span>
          <span class="notice-date">{{ getWriteDate(item.writeDate) }}</span>
        </div>
      </div>
      <dk-el-pagination :totalDocs="totalDocs" :limit="limit" :pagingProc="pagingProc" v-if="displayPaging" ref="pagination"></dk-el-pagination>
    </div>
    <div v-else>등록된 공지사항이 없습니다</div>
    <el-dialog :visible.sync="noticeShow" class="el-dialog-notice">
      <el-carousel v-show="selectNotice.images && selectNotice.images.length > 0" height="400px">
        <el-carousel-item v-for="(file, index) in selectNotice.images" :key="index">
          <div class="dk-carousel-item">
            <img :src="$config.getServerConfig().image.url + file.serverFilePath" />
          </div>
        </el-carousel-item>
      </el-carousel>
      <div v-if="selectNotice.images && selectNotice.images.length > 0" style="padding: 0 30px 20px 30px;">
        <h3>{{ selectNotice.title }}</h3>
        <h5>{{ selectNotice.text }}</h5>
      </div>
      <div v-else style="padding: 30px 30px 20px 30px;">
        <h2>{{ selectNotice.title }}</h2>
        <h4>{{ selectNotice.text }}</h4>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dailyNoticeShow" class="el-dialog-notice">
      <el-carousel v-show="selectNotice.images && selectNotice.images.length > 0" height="400px">
        <el-carousel-item v-for="(file, index) in selectNotice.images" :key="index">
          <div class="dk-carousel-item">
            <img :src="$config.getServerConfig().image.url + file.serverFilePath" />
          </div>
        </el-carousel-item>
      </el-carousel>
      <div v-if="selectNotice.images && selectNotice.images.length > 0" style="padding: 0 30px 20px 30px;">
        <h3>{{ selectNotice.title }}</h3>
        <h5>{{ selectNotice.text }}</h5>
      </div>
      <div v-else style="padding: 30px 30px 20px 30px;">
        <h2>{{ selectNotice.title }}</h2>
        <h4>{{ selectNotice.text }}</h4>
      </div>
      <span slot="footer" class="dialog-footer" v-if="isDailyPopup">
        <el-button @click="dailyClose" size="mini">하루동안 이 공지를 열지 않습니다</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import moment from 'moment';
import dkelpagination from '@/components/dkElPagination.vue';

export default {
  components: {
    'dk-el-pagination': dkelpagination,
  },
  created() {
    this.getNotices(1).then(() => {
      this.checkPopupNotice();
    });
  },
  computed: {
    cardTransparent() {
      let style = {};
      if (this.isTransparent) {
        style['backgroundColor'] = 'transparent';
      }
      return style;
    },
  },
  props: {
    displayPaging: {
      default: true,
      type: Boolean,
    },
    isTitleShow: {
      default: true,
      type: Boolean,
    },
    isTransparent: {
      default: false,
      type: Boolean,
    },
    noticeCount: {
      default: 5,
      type: Number,
    },
  },
  data() {
    return {
      notices: [],
      limit: this.noticeCount,
      totalDocs: 0,
      noticeShow: false,
      dailyNoticeShow: false,
      selectNotice: {
        title: '',
        text: '',
        writerId: [],
        images: [],
      },
      dataCursor: 0,
      isDailyPopup: true,
    };
  },
  methods: {
    checkPopupNotice() {
      if (this.dataCursor >= this.notices.length) {
        this.isDailyPopup = false;
        return;
      }
      let notice = this.notices[this.dataCursor];
      if (!notice.isPopup) {
        this.dataCursor++;
        return this.checkPopupNotice();
      }
      let endPopupTime = moment(notice.endDate);
      let now = moment();
      if (now.unix() > endPopupTime.unix()) {
        this.dataCursor++;
        return this.checkPopupNotice();
      }
      let cookieTime = localStorage.getItem(`notice${notice._id}`);
      if (cookieTime) {
        if (moment(parseInt(cookieTime)).date() == now.date()) {
          this.dataCursor++;
          return this.checkPopupNotice();
        }
      }
      this.selectNotice = notice;
      this.dailyNoticeShow = true;
    },
    getNotices(pageNum) {
      let me = this;
      return this.$store
        .dispatch('notice/getNotice', {
          page: pageNum || this.$refs.pagination.getPage(),
          limit: this.limit,
        })
        .then(() => {
          me.notices = me.$store.getters['notice/getNotice'];
          me.totalDocs = me.$store.getters['notice/getPagination'].totalDocs;
        });
    },
    pagingProc(pageNum) {
      this.getNotices();
    },
    getWriteDate(writeDate) {
      return moment(writeDate).format('YY.MM.DD');
    },
    selectRow(row) {
      this.selectNotice = row;
      this.noticeShow = true;
    },
    dailyClose() {
      localStorage.setItem(`notice${this.selectNotice._id}`, new Date().getTime());
      this.dailyNoticeShow = false;
      this.dataCursor++;
      this.checkPopupNotice();
    },
  },
};
</script>

<style scoped>
.notice {
  /* overflow-x:auto; */

  /* height:100%; */
}

.notice-card {
  box-sizing: border-box;
  height: 60px;
  padding: 2px 15px;
  width: 100%;
}

.notice-card > div {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  color: #5c5c5c;
  height: 100%;
  padding: 15px 0;
  width: 100%;
}
.notice-card > div::after {
  clear: both;
  content: '';
  display: block;
}

.notice-title {
  color: #5c5c5c;
  float: left;
  font-size: 14px;
  margin-left: 15px;
}

.notice-date {
  color: #afafaf;
  float: right;
  font-size: 11px;
  margin-right: 10px;
}
</style>
