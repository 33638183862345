var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "control-panel",
      attrs: { id: "cameraControlPanel" },
      on: {
        click: _vm.clickControl,
        mousedown: _vm.mousedownControl,
        mouseup: _vm.mouseupControl,
        mousemove: _vm.mousemoveControl,
        contextmenu: function($event) {
          $event.preventDefault()
          return _vm.rightClickControl($event)
        },
        mousewheel: _vm.mousewheel
      }
    },
    [
      _c("canvas", {
        ref: "control-panel-spot",
        attrs: { id: "control-panel-spot" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }