<template>
  <GChart
    ref="gChart"
    :type="chartType"
    :settings="{
      packages: ['corechart'],
      language: 'ko',
      callback: () => {
        this.drawChart();
      },
    }"
    :data="chartData"
    :options="options"
    @ready="onChartReady"
  />
</template>

<script>
import moment from 'moment';

export default {
  created() {
    this.options.height = this.height;
    this.options.colors = this.colors;
    if (this.chartType === 'ColumnChart') {
      this.options.vAxis = {
        textStyle: { color: '#FFF' },
        gridlines: {
          color: '#494f5e',
          count: 1,
        },
      };
      this.options.hAxis = {
        textStyle: { color: '#FFF' },
        format: 'MM/dd',
        ticks: {},
        gridlines: {
          color: '#2d3744',
          count: -1,
        },
      };
    } else if (this.chartType === 'LineChart') {
      this.options.vAxis = {
        textStyle: { color: '#FFF' },
        gridlines: {
          color: '#494f5e',
          // count: 1,
        },
        minorGridlines: { color: '#494f5e' },
        baselineColor: '#494f5e',
      };
      this.options.hAxis = {
        textStyle: { color: '#FFF' },
        format: 'MM/dd',
        ticks: {},
        gridlines: {
          color: '#494f5e',
          // color: '#2d3744',
          // count: -1,
        },
        gridlineColor: '#494f5e',
        minorGridlines: { color: '#494f5e' },
        baselineColor: '#494f5e',
      };
    }

    if (typeof this.vMin !== 'undefined') {
      this.options.vAxis.viewWindow = this.options.vAxis.viewWindow || {};
      this.options.vAxis.viewWindow.min = this.vMin;
    }

    if (typeof this.vMax !== 'undefined') {
      this.options.vAxis.viewWindow = this.options.vAxis.viewWindow || {};
      this.options.vAxis.viewWindow.max = this.vMax;
    }

    this.setRows(this.data);
    this.setHAxisFormat(this.dateExpression);
  },
  props: {
    height: {
      type: Number,
      default: 300,
    },
    dateExpression: {
      type: String,
      default: 'day',
      // 'day'
      // 'week'
      // 'month'
      // 'year'
    },
    cols: {
      type: Array,
      require: true,
      /*[{
                label: '필드명', 
                type: '타입',
            }]*/
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    chartType: {
      type: String,
      default: 'LineChart',
    },
    vMin: {
      typeof: Number,
    },
    vMax: {
      typeof: Number,
    },
    colors: {
      type: Array,
      default() {
        return ['#409eff', '#DF2B3C'];
      },
    },
  },
  data() {
    return {
      chartsLib: undefined,
      options: {
        // column차트 공통부분
        width: '100%',
        backgroundColor: 'transparent',
        legend: {
          textStyle: { color: '#FFF' },
          position: 'top',
          alignment: 'end',
        },
        chartArea: { backgroundColor: 'transparent', width: '80%', height: '80%' },
        animation: {
          duration: 1000,
          easing: 'out',
        },

        // DateFormat: {
        //   timeZone: -9,
        // },
        vAxis: {},
        DateFormat: {
          timeZone: -9,
        },
        explorer: {
          axis: 'horizontal',
          keepInBounds: true,
          maxZoomIn: 4.0,
          maxZoomOut: 1,
        },
        // column 차트 변수부분
        // height: 300,
      },

      rows: [],
    };
  },
  methods: {
    onChartReady(chart, google) {
      this.chartsLib = google;
    },
    drawChart() {
      this.$emit('drawChart');
    },
    getHticks(data) {
      const oneYear = 1000 * 60 * 60 * 24 * 365.25;
      const oneDay = 1000 * 60 * 60 * 24;
      // const oneMonth = 30 * 1000 * 60 * 60 * 24;
      let hAxisTicks = [];
      let dateRange = data.getColumnRange(0);
      if (this.dateExpression === 'time') {
        const now = moment().hour();
        console.log(now);
        for (let i = 0; i <= now; i++) {
          hAxisTicks.push(moment().set('hour', i).set('minute', 0).set('second', 0).toDate());
        }
      }
      if (this.dateExpression === 'day') {
        for (let i = dateRange.min.getTime(); i <= dateRange.max.getTime(); i = i + oneDay) {
          hAxisTicks.push(new Date(i));
        }
      } else if (this.dateExpression === 'week') {
        const minTimeStr = moment(dateRange.min).format('YYYYMM');

        let dt = moment(minTimeStr, 'YYYYMM').toDate();
        while (dt.getTime() <= dateRange.max.getTime()) {
          hAxisTicks.push(dt);
          dt = new Date(dt);
          dt.setMonth(dt.getMonth() + 1);
        }
        // for (let i = dateRange.min.getTime(); i <= dateRange.max.getTime(); i = i + oneDay) {
        //   hAxisTicks.push(new Date(i));
        // }
      } else if (this.dateExpression === 'year') {
        for (let i = dateRange.min.getTime(); i <= dateRange.max.getTime(); i = i + oneYear) {
          hAxisTicks.push(new Date(i));
        }
      } else if (this.dateExpression === 'month') {
        const minTimeStr = moment(dateRange.min).format('YYYYMM');

        let dt = moment(minTimeStr, 'YYYYMM').toDate();
        while (dt.getTime() <= dateRange.max.getTime()) {
          hAxisTicks.push(dt);
          dt = new Date(dt);
          dt.setMonth(dt.getMonth() + 1);
        }
      } else if (this.dateExpression === 'hour') {
      }
      this.options.hAxis.ticks = hAxisTicks;
    },
    setRows(data) {
      let rows = [];
      if (data) {
        rows = data.map((elm) => {
          const { date, value } = elm;
          let dt = date;
          switch (this.dateExpression) {
            case 'time': {
              let dateString = moment(date).format('HH');
              dt = moment(dateString, 'HH').toDate();
              break;
            }
            case 'day': {
              let dateString = moment(date).format('YYYYMMDD');
              dt = moment(dateString, 'YYYYMMDD').toDate();
              break;
            }
            case 'week': {
              let dateString = moment(date).format('YYYYMMDD');
              dt = moment(dateString, 'YYYYMMDD').toDate();
              break;
            }
            case 'month': {
              let dateString = moment(date).format('YYYYMM');
              dt = moment(dateString, 'YYYYMM').toDate();
              break;
            }
            case 'year': {
              let dateString = moment(date).format('YYYY');
              dt = moment(dateString, 'YYYY').toDate();
            }
          }
          const result = [{ v: dt }];

          value.forEach((v) => {
            result.push({ v });
          });

          return {
            c: result,
          };
        });
      }
      this.rows = rows;
    },
    setHAxisFormat(data) {
      switch (data) {
        case 'time': {
          this.options.hAxis.format = 'HH';
          break;
        }
        case 'hour': {
          this.options.hAxis.format = 'HH:mm';
          break;
        }
        case 'day': {
          this.options.hAxis.format = 'M/d';
          break;
        }
        case 'week': {
          this.options.hAxis.format = 'Y.M월의 주';
          break;
        }
        case 'month': {
          this.options.hAxis.format = 'Y.M';
          break;
        }
        case 'year': {
          this.options.hAxis.format = 'y년';
          break;
        }
      }
    },
  },

  computed: {
    chartData() {
      if (!this.chartsLib) return {};

      const chartObjectPassData = {
        cols: this.cols,
        rows: this.rows,
      };
      const data = new this.chartsLib.visualization.DataTable(chartObjectPassData);
      if (this.rows && this.rows.length > 0) {
        this.getHticks(data);
      }

      return data;
    },
  },

  watch: {
    data(n, o) {
      this.setRows(n);
    },
    dateExpression(n, o) {
      this.setHAxisFormat(n);
    },
  },
};
</script>
