<template>
    <div class="dk-card-dark shadow" :style="['widgetStyle']" v-if="fcltInstPnstData">
        <div class="dk-card-title">
            {{orgName}} 시설물 설치 현황 <span style="float:right;" v-if="fcltInstPnstData.total">전체 {{fcltInstPnstData.total}} 개소</span>
        </div>        
        <el-row>
            <el-col :xs="24" :sm="isChartOnly?12:24" :md="isChartOnly?12:24">
              <doughnut-chart
                :chart-data=" fcltInstPnstData"
                :height="200"
                :options="{
                    responsive: true, 
                    maintainAspectRatio: false,
                    legend: {
                      display:false,                      
                    }
                  }"
              >
              </doughnut-chart>
            </el-col>
            <!-- <el-col v-if="isChartOnly" :xs="24" :sm="12" :md="12" style="padding:10px 10px 0 10px">
              <table class="dk-table dk-table-dark">
                <colgroup>
                  <col style="width:65%">
                  <col style="width:35%">
                </colgroup>
                <tbody>
                <tr
                  v-for="(label, index) in  fcltInstPnstData.labels"
                  :key="index">
                  <th scope="row">
                      <span
                        class="fclt-inst-chart-legend-color"
                        :style="'background-color:'+fcltInstPnstData.datasets[0].backgroundColor[index]"></span>{{label}}
                  </th>
                  <td>
                    {{ fcltInstPnstData.datasets[0].data[index]}}
                  </td>
                </tr>
                </tbody>
              </table>
            </el-col> -->
          </el-row>
    </div>
</template>

<script>
import doughnutChart from '@/components/chart/doughnutChart.js'

export default {
    components: {
        doughnutChart
    },
    props: {
      height: {
        type: Number,
        default: 0
      },
      isChartOnly: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      widgetStyle(){
        let style = {}
        if (this.height > 0) {
          style.minHeight = this.height + 'px'
        }
        return style
      }
    },
    created() {
        this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/statistics/fcltInstPnst`, {})
        .then((result) => {
          let data = result.data;
          this.fcltInstPnstData = {
            datasets: [
              {
                data: [],
                backgroundColor: []
              }
            ],
            labels: [],
            total: 0
          }
          for (var i = 0; i < data.length; i++) {
            this.fcltInstPnstData.datasets[0].data[i] = data[i].count;
            this.fcltInstPnstData.datasets[0].backgroundColor[i] = this.chartColors[i];
            this.fcltInstPnstData.labels[i] = data[i]._id
            this.fcltInstPnstData.total += data[i].count;
          }
          
        })        
    },
    data() {
        return {
            orgName: this.$config.getSiteConfig().orgName,
            chartColors: [
                '#00897B',
                '#FFB300',
                '#3949AB',
                '#F44336',
                '#E91E63',
                '#2196F3',
                '#00BCD4',
                '#4CAF50',
                '#8BC34A',
                '#FF9800',
                '#795548',
                '#9E9E9E',
                '#607D8B',
                '#D32F2F',
                '#616161',
                '#E64A19'
                ],
            fcltInstPnstData: null,


        }
    }
}
</script>

<style scoped>
.fclt-inst-chart-legend-color {
    display: inline-flex;
    width: 10px;
    height: 10px;
    margin-right: 6px;
    border: 1px solid #efefef;
  }
  .dk-card-dark{
    height: 50%;
  }
</style>
