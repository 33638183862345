<template>
  <div class="fill-width-height el-container">
    <el-container class="page-body">
      <el-container>
        <el-main>
          <el-row class="content-wrap">
            <dk-page-header></dk-page-header>
            <dk-el-table-header :openFormDialog="openFormDialog" :isSearch="false"></dk-el-table-header>
            <el-col :span="24" class="page-content">
              <el-table
                :data="vmsSchedules"
                style="width: 100%; border-top: 1px solid #dedede;"
                empty-text="등록된 VMS 스케줄 정보가 없습니다."
                size="mini"
                @row-click="selectRow"
                stripe
              >
                <el-table-column
                  label="스케줄 ID"
                  header-align="left"
                  align="left"
                  width="80px"
                  prop="vmsScheduleId"
                ></el-table-column>
                <el-table-column
                  label="스케줄 명칭"
                  header-align="left"
                  align="left"
                  prop="vmsScheduleName"
                ></el-table-column>
                <el-table-column label="VMS크기" header-align="left" align="left">
                  <template slot-scope="scope">{{ getVmsSize(scope.row.vmsMessageSize) }}</template>
                </el-table-column>

                <el-table-column label="VMS 메시지 수" header-align="left" align="left">
                  <template slot-scope="scope">
                    <span
                      v-if="
                        scope.row.vmsMessages &&
                        scope.row.vmsMessages.length > 0
                      "
                    >{{ scope.row.vmsMessages.length }}</span>
                    <span v-else>없음</span>
                  </template>
                </el-table-column>
                <el-table-column label width="170px">
                  <template slot-scope="scope">
                    <el-button @click="openFormDialog(scope.row)" size="small">수정</el-button>
                    <el-button @click="removeVmsSchedule(scope.row)" size="small" type="danger">삭제</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <dk-el-pagination
                :totalDocs="totalDocs"
                :limit="limit"
                :pagingProc="pagingProc"
                ref="pagination"
              ></dk-el-pagination>
            </el-col>
          </el-row>
        </el-main>
      </el-container>
      <dk-info-panel ref="infoPanel" width="400px">
        <div class="info-panel-wrap">
          <h3>{{ selectedVmsSchedule.vmsScheduleName }}</h3>
          <h6>VMS크기 : {{ getVmsSize(selectedVmsSchedule.vmsMessageSize) }}</h6>
          <div
            class="dk-card"
            v-for="(item, index) in selectedVmsSchedule.vmsMessages"
            :key="index"
          >
            <el-row>
              <el-col :span="16">
                <img
                  :src="
                    
                    item.thumbImageFile
                  "
                  style="height: auto; width: 100%;"
                />
              </el-col>
              <el-col :span="8" style="text-align: right;">
                <h5 style="margin: 15px 0 0 0;">표출시간 : {{ item.displayTime }}초</h5>
              </el-col>
            </el-row>
          </div>
        </div>
      </dk-info-panel>
    </el-container>
    <form-vms-text-schedule ref="formDialog"></form-vms-text-schedule>
  </div>
</template>

<script>
import dkelpagination from '@/components/dkElPagination.vue';
import dkeltableheader from '@/components/dkElTableHeader.vue';
import dkinfopanel from '@/components/dkInfoPanel.vue';
import formVmsTextSchedule from './form/formVmsTextSchedule.vue';

export default {
  components: {
    'dk-el-table-header': dkeltableheader,
    'dk-info-panel': dkinfopanel,
    'dk-el-pagination': dkelpagination,
    'form-vms-text-schedule': formVmsTextSchedule,
  },
  created() {
    this.$on('procDataComplete', () => {
      this.getVmsSchedules();
      this.$refs.infoPanel.close();
    });
  },
  mounted() {
    this.getVmsSchedules(1);
  },
  data() {
    return {
      vmsSchedules: [],
      limit: 10,
      totalDocs: 0,
      selectedVmsSchedule: {
        vmsScheduleId: -1,
        vmsScheduleName: '',
        vmsMessageSize: '',
        vmsCategory: '',
        vmsMessages: [],
        vmsType: '',
        seq: -1,
      },
    };
  },
  methods: {
    async getVmsSchedules(pageNum) {
      let me = this;
      let param = {
        page: pageNum || this.$refs.pagination.getPage(),
        limit: this.limit,
      };
      param.vmsType = '1';
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsSchedule`, {
        params: param,
      });
      me.vmsSchedules = response.data.docs;
      me.totalDocs = response.data.totalDocs;
    },
    openFormDialog(row) {
      this.$refs.formDialog.showDialog(row);
    },
    removeVmsSchedule(args) {
      this.$confirm(`${args.vmsScheduleName} VMS 문자식 스케줄를 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/vmsSchedule/delete`, {
              data: args,
            });
            this.getVmsSchedules();
            this.$message({
              type: 'success',
              message: `${args.vmsScheduleName} 삭제 완료`,
            });
            this.$refs.infoPanel.close();
          } catch (err) {
            this.$message({
              type: 'info',
              message: `${args.vmsScheduleName} VMS 문자식 스케줄 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${args.vmsScheduleName} VMS 문자식 스케줄 삭제 취소`,
          });
        });
    },
    selectRow(row) {
      if (!this.$refs.infoPanel.isOpened()) {
        this.$refs.infoPanel.open();
      }
      this.selectedVmsSchedule = row;
    },
    search(keyword) {
      this.getVmsSchedules(null, keyword);
    },
    pagingProc() {
      this.getVmsSchedules();
    },
    getVmsSize(val) {
      let s = val.split('x');
      return `${s[0]}단 ${s[1]}열`;
    },
  },
};
</script>

<style scoped></style>
