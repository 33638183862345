<template>
  <div class="vms-message-svg">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      stroke="none"
      fill="none"
      overflow="hidden"
      preserveAspectRatio="none"
      class="editor-canvas"
      :width="canvasWrapWidth"
      :height="canvasWrapHeight"
      :viewBox="canvasViewBox"
      ref="vmsMessageSvg"
    >
      <g>
        <rect
          :width="canvasWidth"
          :height="canvasHeight"
          style="fill: #000000;"
          class="rect-background"
        />
      </g>
      <text-object
        v-for="item in vmsMessage.vmsMessageData.textData"
        :key="item.objectId"
        :inputX="item.inputX"
        :inputY="item.inputY"
        :objectId="item.objectId"
        :isFillin="item.isFillin"
        :objectData="item"
        :bmpColor="bmpColor"
        ref="textObject"
      />
      <image-object
        v-for="item in vmsMessage.vmsMessageData.imageData"
        :key="item.objectId"
        :inputX="item.inputX"
        :inputY="item.inputY"
        :objectId="item.objectId"
        :imageUrl="item.imageUrl"
        :objectData="item"
        :bmpColor="bmpColor"
        ref="imageObject"
      />
      <line-object
        v-for="item in vmsMessage.vmsMessageData.lineData"
        :key="item.objectId"
        :objectId="item.objectId"
        :objectData="item"
        :bmpColor="bmpColor"
        :tempVmsIfscInfo="item.vmsIfscInfo"
        ref="lineObject"
      />
    </svg>
    <div
      ref="msgBox"
      class="tooltip-box"
      :style="tooltipPos"
      v-show="isShowMessageBox"
    >
      {{ msg }}
    </div>
  </div>
</template>

<script>
let textObject = {
  // template: `
  //     <g :id="objectId"
  //         :transform="getMatrix"
  //     >
  //         <g :transform="getTextTransform" v-show="!isSelected">
  //             <defs>
  //                 <filter id="crispify">
  //                     <feComponentTransfer>
  //                         <feFuncA type="discrete" tableValues="0 1"/>
  //                     </feComponentTransfer>
  //                 </filter>
  //             </defs>
  //             <text
  //                 ref="generateText"
  //                 :style="[textStyle]"
  //                 text-rendering="geometricprecision"
  //                 :filter="getFilter"
  //                 :fillinValue="fillinValue"
  //             >{{text}}</text>
  //         </g>
  //     </g>
  // `,
  template: `
      <g :id="objectId"
          :transform="getMatrix"
      >          
          <g :transform="getInputTextTransform">
              <foreignObject :width="width" :height="height">
                <div xmlns="http://www.w3.org/1999/xhtml" 
                :style="[inputTextStyle]" 
                class="vms-text-editor" 
                ref="textInput" 
                v-html="text" 
                :fillinValue="fillinValue"></div>
              </foreignObject>
          </g>
      </g>`,
  mounted() {
    this.$nextTick(() => {
      // this.x = this.inputX
      // this.y = this.inputY
      this.importData(this.objectData);
    });
  },
  computed: {
    boxStyle1() {
      const style = {
        fill: 'none',
        stroke: '#efefef',
        strokeWidth: '6px',
        strokeOpacity: this.isSelected ? '0.8' : '0',
        cursor: 'pointer',
      };
      return style;
    },
    textStyle() {
      const style = {
        fill: this.fontColor,
        fontSize: this.fontSize + 'px',
        fontFamily: this.fontFamily ? this.fontFamily : 'NanumGothic',
        fontWeight: this.fontWeight,
        whiteSpace: 'pre',
        userSelect: 'none',
      };
      return style;
    },
    inputTextStyle() {
      const style = {
        width: '100%',
        fontSize: this.fontSize + 'px',
        backgroundColor: 'transparent',
        border: 'none',
        fontWeight: this.fontWeight,
        color: this.fontColor,
        fontFamily: this.fontFamily ? this.fontFamily : 'NanumGothic',
        lineHeight: '110%',
        //fontFamily:(this.fontFamily)?this.fontFamily:'NanumGothic'
      };
      return style;
    },
    getMatrix() {
      return `matrix(1, 0, 0, 1, ${this.x}, ${this.y})`;
    },
    getTextTransform() {
      return `translate(0 ${this.fontSize})`;
    },
    getInputTextTransform() {
      let boxSize = this.fontSize + Math.floor(this.fontSize / 3);
      let offset = Math.round((boxSize - this.fontSize) / 5);
      return `translate(0 ${offset})`;
    },
    getFilter() {
      if (this.bmpColor == '4bit') {
        return 'url(#crispify)';
      } else if (this.bmpColor == '24bit') {
        return '';
      }
    },
  },
  data() {
    return {
      objectType: 'text',
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      isSelected: false,
      isEdit: false,
      text: '텍스트 입력',
      isInit: false,
      fontSize: 48,
      fontFamily: 'NanumGothic',
      fontWeight: 'bold',
      fontColor: '#00ff00',
      strokeWidth: 0,
      strokeHeight: 0,
      inputWidth: 0,
      fillinValue: '',
    };
  },
  props: {
    inputX: {
      type: Number,
      default: 0,
    },
    inputY: {
      type: Number,
      default: 0,
    },
    ratio: {
      type: Number,
    },
    objectId: {
      type: String,
      default: 'textObject-0',
    },
    isFillin: {
      type: Boolean,
      default: false,
    },
    objectData: {
      type: Object,
    },
    bmpColor: {
      type: String,
      default: '24bit',
    },
  },
  methods: {
    setSize() {
      this.$nextTick(() => {
        if (this.$refs.generateText) {
          this.strokeWidth = this.$refs.generateText.getBBox().width + 40;
          this.strokeHeight = this.fontSize + Math.floor(this.fontSize / 3);
          this.inputWidth = this.strokeWidth - 30;
        }
      });
    },
    setPosition(x, y) {
      this.x = x;
      this.y = y;
    },
    getObjectId() {
      return this.objectId;
    },
    setColor(color) {
      this.fontColor = color;
      this.$forceUpdate();
      this.setSize();
    },
    setFontSize(size) {
      this.fontSize = size;
      this.$forceUpdate();
      this.setSize();
    },
    setText(text) {
      this.text = text;
      this.$forceUpdate();
      this.setSize();
    },
    setFillinValue(value, text) {
      this.fillinValue = value;
      this.text = text;
      this.$forceUpdate();
      this.setSize();
    },
    setFontFamily(fontFamily) {
      this.fontFamily = fontFamily;
      this.$forceUpdate();
      this.setSize();
    },
    setFontWeight(fontWeight) {
      this.fontWeight = fontWeight;
      this.$forceUpdate();
      this.setSize();
    },
    exportData() {
      return {
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.height,
        text: this.text,
        fontSize: this.fontSize,
        fontFamily: this.fontFamily,
        fontWeight: this.fontWeight,
        fontColor: this.fontColor,
        isFillin: this.isFillin,
        objectId: this.objectId,
      };
    },
    importData(data) {
      this.x = data.x || this.inputX;
      this.y = data.y || this.inputY;
      this.width = data.width || 0;
      this.height = data.height || 0;
      this.text = data.text || '텍스트 입력';
      this.fontSize = data.fontSize || 48;
      this.fontFamily = data.fontFamily || 'NanumGothic';
      this.fontWeight = data.fontWeight || 'bold';
      this.fontColor = data.fontColor || '#00ff00';
      this.$forceUpdate();
      this.setSize();
    },
  },
};

let imageObject = {
  template: `
        <g :id="objectId">
            <g :transform="getMatrix">
                <g transform="translate(0 0)">
                    <image x="0" y="0" :width="width" :height="height" :xlink:href="imageBynary"></image>
                </g>
            </g>
        </g>`,
  mounted() {
    this.$nextTick(() => {
      // this.x = this.inputX
      // this.y = this.inputY
      this.importData(this.objectData);
    });
  },
  computed: {
    getMatrix() {
      return `matrix(1, 0, 0, 1, ${this.x}, ${this.y})`;
    },
  },
  data() {
    return {
      objectType: 'image',
      x: 0,
      y: 0,
      width: 240,
      height: 150,
      offsetX: 0,
      offsetY: 0,
      isSelected: false,
      imageBynary: '',
      currentAction: 0,
    };
  },
  props: {
    inputX: {
      type: Number,
      default: 0,
    },
    inputY: {
      type: Number,
      default: 0,
    },
    ratio: {
      type: Number,
    },
    objectId: {
      type: String,
      default: 'imageObject-0',
    },
    imageUrl: {
      type: String,
      required: true,
    },
    objectData: {
      type: Object,
    },
    bmpColor: {
      type: String,
      default: '24bit',
    },
  },
  methods: {
    getImageBynary(imageUrl) {
      let me = this;
      this.toDataUrl(imageUrl, function (dataUrl) {
        me.imageBynary = dataUrl;
      });
    },
    toDataUrl(src, callback, outputFormat) {
      var img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
      };
      img.src = src;
      if (img.complete || img.complete === undefined) {
        img.src =
          'data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
        img.src = src;
      }
    },
    mousedown(flag, event) {
      this.currentAction = flag;
      this.offsetX = event.offsetX - this.x;
      this.offsetY = event.offsetY - this.y;
      //console.log(`x:${this.x}, y:${this.y}, offsetX: ${this.offsetX}, offsetY: ${this.offsetY}`)

      this.selectObject();
    },
    deselectObject() {
      this.isSelected = false;
    },
    setPosition(x, y) {
      this.x = x;
      this.y = y;
    },
    setSize(width, height) {
      this.width = width;
      this.height = height;
    },
    getSize() {
      return {
        width: this.width,
        height: this.height,
      };
    },
    getObjectId() {
      return this.objectId;
      this.selectObject();
    },
    exportData() {
      return {
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.height,
        offsetX: this.offsetX,
        offsetY: this.offsetY,
        imageUrl: this.imageUrl,
        objectId: this.objectId,
      };
    },
    importData(data) {
      this.x = data.x || this.inputX;
      this.y = data.y || this.inputY;
      this.width = data.width || 240;
      this.height = data.height || 150;
      this.offsetX = data.offsetX || 0;
      this.offsetY = data.offsetY || 0;
      this.getImageBynary(this.imageUrl);
    },
  },
};

let lineObject = {
  template: `       
        <g :id="objectId"           
            @mousedown="selectObject"              
        >            
            <marker :id="objectId+'-marker'" viewBox="0 0 10 10" refX="1" refY="5" 
                markerUnits="strokeWidth" orient="auto"
                markerWidth="4" markerHeight="2"
                :shape-rendering="getShareRendering"
                >
                <polyline points="0,0 7,5 0,10 0,0" :fill="strokeColor" :shape-rendering="getShareRendering"/> 
            </marker> 
            <g>                            
                <line :y1="y1" :x1="x1" :y2="y2" :x2="x2" :marker-end="getEndArrow" :stroke="strokeColor" :stroke-width="strokeWidth" :shape-rendering="getShareRendering" />
                <g v-show="isSelected">
                    <line :x1="x1" :y1="y1" :x2="x2" :y2="y2" stroke="#fff" :stroke-width="strokeWidth/2" stroke-dasharray="5,5" class="topDrawing"/>
                    <circle :cx="x1" :cy="y1" r="5" stroke="#E0E0E0" stroke-width="1" fill="#fff" class="bottomRightDrawing" @mousedown="mousedown('startPoint', $event)"/>
                    <circle :cx="x2" :cy="y2" r="5" stroke="#E0E0E0" stroke-width="1" fill="#fff" class="bottomRightDrawing" @mousedown="mousedown('endPoint', $event)"/>
                </g>
                <g v-show="isTunnel">
                    <line :y1="y1" :x1="x1" :y2="y2" :x2="x2" stroke="#ffff00" stroke-width="3" stroke-dasharray="5,5" :stroke-width="2" />
                    <line :y1="y1" :x1="x1" :y2="y2" :x2="x2" stroke="#ffff00" stroke-width="3" stroke-dasharray="5,5" :stroke-width="2" />
                </g>
                
            </g>                         
        </g>
    `,

  // <g :transform="getTextTransform">
  //             <defs>
  //                 <filter id="crispify">
  //                     <feComponentTransfer>
  //                         <feFuncA type="discrete" tableValues="0 1"/>
  //                     </feComponentTransfer>
  //                 </filter>
  //             </defs>
  //             <text
  //                 ref="generateText"
  //                 :style="[textStyle]"
  //                 text-rendering="geometricprecision"
  //                 :filter="getFilter"
  //             >{{vmsIfscInfo.vmsIfscName}}</text>
  //         </g>
  mounted() {
    this.$nextTick(() => {
      this.importData(this.objectData);
      if (this.tempVmsIfscInfo) {
        this.setVmsIfscInfo(this.tempVmsIfscInfo);
      }
    });
  },

  computed: {
    getEndArrow() {
      if (this.isEndArrow) {
        return `url(#${this.objectId}-marker)`;
      } else {
        return '';
      }
    },
    getMatrix() {
      //return `matrix(1, 0, 0, 1, ${this.x1}, ${this.y1})`
      return `matrix(1, 0, 0, 1, 0, 0)`;
    },
    getShareRendering() {
      if (this.bmpColor == '4bit') {
        return 'crispEdges';
      } else if (this.bmpColor == '24bit') {
        return 'auto';
      }
    },
    lineTextStyle() {
      return {
        position: 'absolute',
        zIndex: 200,
        left: 0,
        top: 0,
      };
    },
  },
  watch: {
    vmsIfscInfo(newVal) {
      if (newVal.vmsIfscId) {
        this.strokeColor = '#0097e6';
      } else {
        this.strokeColor = '#8a8a8a';
      }
    },
    objectData(newVal) {
      this.importData(newVal);
    },
  },
  data() {
    return {
      objectType: 'line',
      isSelected: false,
      isEdit: false,
      isEndArrow: false,
      strokeWidth: 20,
      strokeColor: '#8a8a8a',
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 0,
      currentAction: 0,
      isTunnel: false,
      vmsIfscInfo: {
        vmsIfscName: '등록된 정보 제공구간이 없습니다',
      },
    };
  },
  props: {
    objectId: {
      type: String,
      default: 'lineObject-0',
    },
    objectData: {
      type: Object,
    },
    bmpColor: {
      type: String,
      default: '24bit',
    },
    tempVmsIfscInfo: {
      type: Object,
    },
  },
  methods: {
    selectObject() {
      this.isSelected = true;
      this.isEdit = true;
      this.$parent.$emit('selectObject', this);
    },
    deselectObject() {
      this.isSelected = false;
      this.isEdit = false;
    },
    setStrokeColor(color) {
      this.strokeColor = color;
      this.$forceUpdate();
    },
    exportData() {
      return {
        x1: this.x1,
        y1: this.y1,
        x2: this.x2,
        y2: this.y2,
        strokeColor: this.strokeColor,
        strokeWidth: this.strokeWidth,
        objectId: this.objectId,
        isEndArrow: this.isEndArrow,
        isTunnel: this.isTunnel,
      };
    },
    importData(data) {
      this.x1 = data.x1 || this.x1;
      this.y1 = data.y1 || this.y1;
      this.x2 = data.x2 || this.x2;
      this.y2 = data.y2 || this.y2;
      this.strokeWidth = data.strokeWidth || 20;
      this.strokeColor = '#8a8a8a';
      this.isEndArrow = data.isEndArrow || false;
      this.isTunnel = data.isTunnel || false;
      this.$forceUpdate();
    },
    getObjectId() {
      return this.objectId;
    },
    mousedown(flag, e) {
      this.currentAction = flag;
      this.selectObject();
    },
    updateStartPoint(x, y) {
      this.x1 = x;
      this.y1 = y;
    },
    updateEndPoint(x, y) {
      this.x2 = x;
      this.y2 = y;
    },
    setVmsIfscInfo(vmsIfscInfo) {
      this.vmsIfscInfo = vmsIfscInfo;
    },
    getFilter() {
      if (this.bmpColor == '4bit') {
        return 'url(#crispify)';
      } else if (this.bmpColor == '24bit') {
        return '';
      }
    },
    showTooltipMsg() {
      // console.log(this.$parent)
      let x = (this.x1 + this.x2) / 2;
      let y = (this.y1 + this.y2) / 2;
      let msg = `할당된 정보제공구간 없음`;
      if (this.tempVmsIfscInfo && this.tempVmsIfscInfo.startNodeName) {
        msg = `${this.tempVmsIfscInfo.startNodeName} > ${this.tempVmsIfscInfo.startNodeName}`;
      }
      this.$parent.$emit('showTooltipMsg', {
        x: this.x1 / 2,
        y: this.y1 / 2,
        msg: msg,
      });
    },
    hideTooltipMsg() {
      this.$parent.$emit('hideTooltipMsg');
    },
  },
};

export default {
  components: {
    'text-object': textObject,
    'image-object': imageObject,
    'line-object': lineObject,
  },
  computed: {
    tooltipPos() {
      return {
        left: this.msgBoxX + 'px',
        top: this.msgBoxY + 'px',
      };
    },
  },

  created() {
    this.setEditorSize();
    this.$on('selectObject', (selectObject) => {
      if (this.selectObject) {
      }
      if (this.selectObject && this.selectObject.getObjectId()) {
        if (this.selectObject.getObjectId() === selectObject.getObjectId()) {
        } else {
          this.selectObject.deselectObject();
        }
      }
      this.selectObject = selectObject;
      let lineData = this.vmsMessage.vmsMessageData.lineData.find((line) => {
        return line.objectId === selectObject.getObjectId();
      });

      this.$emit('selectVmsLine', {
        data: lineData,
        obj: selectObject,
      });
    });
    this.$on('showTooltipMsg', (tooltipData) => {
      this.showTooltipMsg(tooltipData);
    });
    this.$on('hideTooltipMsg', () => {
      this.hideTooltipMsg();
    });
  },
  props: ['vmsMessage'],
  data() {
    return {
      col: 20,
      row: 15,
      canvasWrapWidth: '',
      canvasWrapHeight: '',
      canvasWidth: '320px',
      canvasHeight: '240px',
      canvasViewBox: '0 0 320 240',
      bmpColor: this.vmsMessage.vmsMessageData.bmpColor,
      selectObject: null,
      isShowMessageBox: false,
      msgBoxX: 0,
      msgBoxY: 0,
      msg: '',
    };
  },
  methods: {
    setEditorSize() {
      let size = this.vmsMessage.vmsMessageSize.split('x');
      this.row = parseInt(size[0]);
      this.col = parseInt(size[1]);
      let width = 400;
      let height = (400 / this.col) * this.row;
      this.canvasWrapWidth = `${width}px`;
      this.canvasWrapHeight = `${height}px`;
      this.canvasWidth =
        this.vmsMessage.vmsMessageData.canvasWidth || `${width}px`;
      this.canvasHeight =
        this.vmsMessage.vmsMessageData.canvasHeight || `${height}px`;
      this.canvasViewBox =
        this.vmsMessage.vmsMessageData.canvasViewBox ||
        `0 0 ${width} ${height}`;
    },
    showTooltipMsg(tooltipData) {
      this.msgBoxX = tooltipData.x;
      this.msgBoxY = tooltipData.y;
      this.isShowMessageBox = true;
      // this.isShowMessageBoxIn = true
      this.msg = tooltipData.msg;
    },
    hideTooltipMsg() {
      setTimeout(() => {
        this.isShowMessageBox = false;
      }, 100);
    },
  },
};
</script>

<style>
.vms-message-svg {
  position: relative;
}
.vms-message-svg > svg {
  /* width:320px !important;
    height:240px !important; */
}
.tooltip-box {
  position: absolute;
  z-index: 200;
  border-radius: 5px;
  padding: 5px;
  background: #acacac;
  color: #000;
  font-weight: bold;
}
</style>
