<template>
  <el-dialog :visible.sync="formDialog" :title="formTitle" width="80%" top="5vh" @close="hideDialog" :close-on-click-modal="false">
    <!-- 시설물 관리 dialog -->
    <div>
      <el-steps :active="step" finish-status="success" align-center style="margin: 5px 0 10px 0;" ref="fcltStep">
        <el-step v-for="(s, index) in stepData" :key="index" :title="s.title" @click="alert('qwer')"></el-step>
      </el-steps>
      <div v-show="step === 0" class="fclt-form-step">
        <el-form :model="fcltDataDoc" label-width="160px" size="medium" :rules="rules" ref="form0">
          <el-row>
            <el-col :span="12">
              <el-row>
                <el-col :xs="20" :sm="20" :md="18">
                  <el-form-item label="시설물 ID" prop="fcltId">
                    <el-input v-model="fcltDataDoc.fcltId" placeholder="시설물 ID" :disabled="updateFlag || autoMakeFcltId"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="4" :sm="4" :md="6">
                  <el-checkbox v-show="!updateFlag" v-model="autoMakeFcltId" @change="makeFcltId">자동 ID 생성</el-checkbox>
                </el-col>
                <el-col :span="22">
                  <el-form-item label="AI 카메라 지점 명칭" prop="fcltName">
                    <el-input v-model="fcltDataDoc.fcltName" placeholder="AI 카메라 정보 명칭"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item label="시설물 유형" prop="fcltTypeId">
                    <el-select v-model="fcltDataDoc.fcltTypeId" placeholder="시설물 유형" @change="selectFcltType">
                      <el-option v-for="item in fcltType" :key="item.fcltTypeId" :label="item.fcltTypeName" :value="item.fcltTypeId"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item label="설치 일자" prop="setDate">
                    <el-date-picker type="date" v-model="fcltDataDoc.setDate" placeholder="설치 일자" style="width: 100%;"></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item label="지번 주소" prop="setNumberAddr">
                    <el-input v-model="fcltDataDoc.setNumberAddr" placeholder="지번 주소"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item label="도로명 주소" prop="setRoadAddr">
                    <el-input v-model="fcltDataDoc.setRoadAddr" placeholder="도로명 주소"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item label="위도" prop="gisLat">
                    <el-input v-model="fcltDataDoc.gisLat" placeholder="위도"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="22">
                  <el-form-item label="경도" prop="gisLng">
                    <el-input v-model="fcltDataDoc.gisLng" placeholder="경도"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="12">
              <dk-gis
                ref="fcltManageDialogGis"
                style="height: 550px;"
                :markerData="markerData"
                :gisApiInfo="gisApiInfo"
                :apiLoadComplete="fcltManageDialogGisInit"
                :markerDragEnd="markerDragEnd"
                :markerInfoWindow="false"
                @dkgisMounted="dkgisMounted"
              ></dk-gis>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div v-show="step === 1" class="fclt-form-step">
        <el-row>
          <el-col :span="24">
            <dk-form ref="dkTemplateForm" formType="input"></dk-form>
          </el-col>
        </el-row>
      </div>

      <div v-show="step === 2" class="fclt-form-step">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12">
            <h3>관련 사진</h3>
            <dk-el-file-upload ref="uploadImage" uploaderType="image" listType="picture-card" path="fclt" :fileList="fcltDataDoc.images">
              <div>
                <i class="el-icon-plus"></i>
              </div>
            </dk-el-file-upload>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12">
            <h3>관련 자료</h3>
            <dk-el-file-upload ref="uploadFile" path="fcltDoc" :fileList="fcltDataDoc.files" :drag="true" :multiple="true">
              <div>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  이곳에 업로드할 파일을 드롭하시거나
                  <br />
                  <em>클릭해서 업로드하세요</em>
                </div>
              </div>
            </dk-el-file-upload>
          </el-col>
        </el-row>
      </div>
      <div class="form-bottom">
        <el-button @click="prev" style="float: left;" v-show="step > 0">
          <i class="fas fa-angle-left" style="margin-right: 10px;"></i>이전
        </el-button>
        <el-button @click="next" style="float: right;" v-show="step < stepData.length - 1">
          다음
          <i class="fas fa-angle-right" style="margin-left: 10px;"></i>
        </el-button>
        <el-button @click="save" style="float: right;" v-show="step === stepData.length - 1">
          저장
          <i class="fas fa-angle-right" style="margin-left: 10px;"></i>
        </el-button>
        <el-button @click="save" style="float: right;" v-show="updateFlag && step !== stepData.length - 1">저장</el-button>
      </div>
    </div>
    <!-- 시설물 관리 dialog 끝 -->
  </el-dialog>
</template>

<script>
import dkgismain from '@/components/dkGis/dkGisMain.vue';
import uuid from 'uuid/v1';
import dkElFileUpload from '@/components/dkElFileUpload.vue';
import dkTemplateForm from '@/components/dkTemplate/dkTemplateForm';
import dkTemplateUtil from '@/components/dkTemplate/dkTemplateUtil';

export default {
  components: {
    'dk-form': dkTemplateForm,
    'dk-gis': dkgismain,
    'dk-el-file-upload': dkElFileUpload,
  },
  created() {
    this.getFcltType();
  },
  data() {
    var validateFcltId = async (rule, value, callback) => {
      if (!this.autoMakeFcltId) {
        if (value === '') {
          callback(new Error('시설물 ID를 입력해 주세요'));
        }
      } else {
        callback();
      }
    };
    return {
      formDialog: false,
      formTitle: 'AI 카메라 추가',
      updateFlag: false,
      fcltType: [],
      initStepClick: false,
      step: 0,
      stepData: [
        { idx: 0, title: 'AI 카메라 기본 정보' },
        // { idx: 1, title: '장비 정보' },
        { idx: 1, title: 'AI 카메라 상세 정보' },
        { idx: 2, title: '관련 파일' },
      ],
      systemLogInfo: {},
      gisInit: false,
      autoMakeFcltId: false,
      fcltDataDoc: {
        fcltName: '',
        fcltTypeName: '',
        fcltTypeId: '',
        regionId: '',
        regionName: '',
        setDate: '',
        setNumberAddr: '',
        setRoadAddr: '',
        gisLat: '',
        gisLng: '',

        fcltTypeData: {},
        files: [],
        images: [],
        keywords: [],
        fcltId: '',
      },
      tempFcltTypeData: null,
      rules: {
        fcltId: [
          {
            required: true,
            validator: validateFcltId,
            trigger: ['change', 'blur'],
          },
        ],
        fcltName: [
          {
            required: true,
            message: 'AI 카메라 명칭을 입력해 주세요',
            trigger: ['change', 'blur'],
          },
          {
            min: 3,
            message: '3자 이상 입력해주세요',
            trigger: ['change', 'blur'],
          },
        ],
        setDate: [
          {
            required: true,
            type: 'date',
            message: '설치 일자를 선택해 주세요',
            trigger: 'change',
          },
        ],
        setNumberAddr: [
          {
            required: true,
            message: 'AI 카메라 설치 주소가 입력되야 합니다.',
            trigger: ['change', 'blur'],
          },
        ],
        setRoadAddr: [
          {
            required: true,
            message: 'AI 카메라 설치 주소가 입력되야 합니다.',
            trigger: ['change', 'blur'],
          },
        ],
        gisLat: [
          {
            required: true,
            message: 'AI 카메라 설치 좌표가 유효하지 않습니다.',
            trigger: ['change', 'blur'],
          },
        ],
        gisLng: [
          {
            required: true,
            message: 'AI 카메라 설치 좌표가 유효하지 않습니다.',
            trigger: ['change', 'blur'],
          },
        ],
      },
      markerData: [
        {
          seq: 0,
          draggable: true,
          geometry: {
            lng: 127.10056150323506,
            lat: 37.489008855218195,
          },
          properties: {},
        },
      ],
      loading: false,
    };
  },
  computed: {},
  props: {
    gisApiInfo: {
      type: Array,
      // gis 기본 설정값을 띄우는 요부분은 나중에 vuex에서 받아서 처리할것
      default() {
        return [];
      },
    },
    geometry: {
      type: Object,
      default: null,
    },
  },
  updated() {
    this.$nextTick(() => {
      if (this.$refs.fcltStep && !this.initStepClick) {
        this.initStepClick = true;
        this.$refs.fcltStep.$children.forEach((val, key) => {
          val.$el.onclick = () => {
            if (this.updateFlag) {
              this.step = key;
            }
          };
        });
      }
      if (this.gisInit) {
        return;
      }
      if (this.formDialog && this.$refs.fcltManageDialogGis) {
        let api = this.gisApiInfo.find((apiInfo) => {
          return apiInfo.initGis;
        });
        this.$refs.fcltManageDialogGis.gisModuleLoad(api.gisName);
        this.gisInit = true;
        //if (this.geometry) {
        //this.setCenter(this.geometry);
        //}
      }
    });
  },
  methods: {
    async getFcltType() {
      try {
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltType`, {
          params: {
            pluginType: 'aicamera',
          },
        });
        this.fcltType = response.data.docs;
      } catch (error) {
        this.$message({
          type: 'info',
          message: `AI 카메라유형 로드 에러`,
        });
      }
    },
    next() {
      if (this.$refs[`form${this.step}`]) {
        this.$refs[`form${this.step}`].validate((valid) => {
          if (valid) {
            this.step++;
          } else {
            return false;
          }
        });
      } else {
        this.step++;
      }

      if (this.step == 1) {
        // } else if (this.step == 2) {
        //   let options = [];
        //   for (var key in this.fcltDataDoc.fcltTypeData) {
        //     if (this.fcltDataDoc.fcltTypeData[key].valueRef && this.fcltDataDoc.fcltTypeData[key].valueRef === 'equip') {
        //       this.fcltDataDoc.fcltTypeData[key].options = this.$lodash.cloneDeep(options);
        //     }
        //   }
      }
    },
    prev() {
      if (this.step == 1) {
      }
      this.step--;
    },
    async save() {
      try {
        let saveFunc = 'fclt/add';
        if (this.updateFlag) {
          saveFunc = 'fclt/update';
        }
        this.systemLogInfo.page = `${this.$route.name} ${this.fcltDataDoc.fcltName}`;
        this.fcltDataDoc.files = this.$refs.uploadFile.getFileData();
        this.fcltDataDoc.images = this.$refs.uploadImage.getFileData();
        this.fcltDataDoc.fcltTypeData = this.$refs.dkTemplateForm.exportTemplateData();
        let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, {
          data: this.fcltDataDoc,
        });
        this.$emit('saved', this.fcltDataDoc);
        this.hideDialog();
        this.$store.dispatch('license/setCurrentFcltCount', response.data.currentFcltCount);
        this.$message({
          type: 'success',
          message: `${this.fcltDataDoc.fcltName} AI 카메라 저장 완료`,
        });
        this.systemLogInfo.saveType = saveFunc;
        await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
      } catch (err) {
        console.error(err);
        this.hideDialog();
        if (err.response.data && err.response.data.limitFcltCount) {
          const licenseInfo = this.$store.getters['license/getLicense'];
          if (licenseInfo.currentFcltCount >= licenseInfo.limitFcltCount) {
            this.$alert(
              `등록된 ${licenseInfo.currentFcltCount}건의 시설물 정보가 있고, 현재 라이선스는 총 ${licenseInfo.limitFcltCount}건의 시설물이 등록 가능하므로, 유효한 시설물 등록 수를 초과합니다.`,
              '라이선스 등록 초과',
              {
                confirmButtonText: '확인',
                type: 'warning',
              }
            );
            this.$store.dispatch('license/setDialog', true);
            return;
          }
        } else {
          this.$message.error(`${this.fcltDataDoc.fcltName} AI 카메라 저장 중 에러 발생`);
        }
      }
    },
    async fcltManageDialogGisInit() {
      let me = this;
      if (this.$refs.fcltManageDialogGis) {
        if (this.geometry) {
          console.log(this.geometry);
          this.$refs.fcltManageDialogGis.setCenter(this.geometry);
          this.markerData[0].geometry = this.geometry;
        } else {
          let latlng = this.$refs.fcltManageDialogGis.getCenter();
          this.markerData[0].geometry = latlng;
        }
        if (this.$refs.fcltManageDialogGis.getAddrFromCenterCoord) {
          const result = await this.$refs.fcltManageDialogGis.getAddrFromCenterCoord();
          if (result) {
            me.fcltDataDoc.setNumberAddr = result.addr && result.addr.num_addr ? result.addr.num_addr : me.fcltDataDoc.setNumberAddr;
            me.fcltDataDoc.setRoadAddr = result.addr && result.addr.road_addr ? result.addr.road_addr : me.fcltDataDoc.setRoadAddr;
            me.fcltDataDoc.gisLat = result.geometry.lat ? result.geometry.lat : me.fcltDataDoc.gisLat;
            me.fcltDataDoc.gisLng = result.geometry.lng ? result.geometry.lng : me.fcltDataDoc.gisLng;
          }
        }

        setTimeout(() => {
          this.$refs.fcltManageDialogGis.refresh();
        }, 100);
      } else {
        let latlng = this.$refs.fcltManageDialogGis.getCenter();
        this.markerData[0].geometry = latlng;
      }
      if (this.$refs.fcltManageDialogGis.getAddrFromCenterCoord) {
        const result = await this.$refs.fcltManageDialogGis.getAddrFromCenterCoord();
        if (result) {
          me.fcltDataDoc.setNumberAddr = result.addr && result.addr.num_addr ? result.addr.num_addr : me.fcltDataDoc.setNumberAddr;
          me.fcltDataDoc.setRoadAddr = result.addr && result.addr.road_addr ? result.addr.road_addr : me.fcltDataDoc.setRoadAddr;
          me.fcltDataDoc.gisLat = result.geometry.lat ? result.geometry.lat : me.fcltDataDoc.gisLat;
          me.fcltDataDoc.gisLng = result.geometry.lng ? result.geometry.lng : me.fcltDataDoc.gisLng;
        }
      }
      setTimeout(() => {
        this.$refs.fcltManageDialogGis.refresh();
      }, 100);
    },
    setCenter(geometry) {
      this.$refs.fcltManageDialogGis.setCenter(geometry);
      this.markerData[0].geometry = geometry;
    },
    setFcltDataDoc(data) {
      this.fcltDataDoc = this.$lodash.cloneDeep(data);
      this.fcltDataDoc.setDate = new Date(this.fcltDataDoc.setDate);
      if (this.fcltDataDoc.files && typeof this.fcltDataDoc.files === 'object') {
        this.fcltDataDoc.files.forEach((file) => {
          file.url = `${file.serverFilePath}`;
        });
      }
      if (this.fcltDataDoc.images && typeof this.fcltDataDoc.images === 'object') {
        this.fcltDataDoc.images.forEach((file) => {
          file.url = `${file.serverFilePath}`;
        });
      }
      this.tempFcltTypeData = data.fcltTypeData;
      if (this.fcltDataDoc.fcltTypeData) {
        this.selectFcltType(this.fcltDataDoc.fcltTypeId);
      }
      this.$nextTick(() => {
        if (this.fcltDataDoc.files) this.$refs.uploadFile.setFileData(this.fcltDataDoc.files);
        if (this.fcltDataDoc.images) this.$refs.uploadImage.setFileData(this.fcltDataDoc.images);
      });
    },
    gisModuleLoad(gisName) {
      this.$refs.fcltManageDialogGis.gisModuleLoad(gisName);
    },
    resetData() {
      this.step = 0;
      this.fcltDataDoc = {
        fcltName: '',
        fcltTypeName: '',
        fcltTypeId: '',
        regionId: '',
        regionName: '',
        setDate: '',
        setNumberAddr: '',
        setRoadAddr: '',
        gisLat: '',
        gisLng: '',
        fcltTypeData: {},
        files: [],
        images: [],
        fcltId: '',
      };
      this.tempUploadFiles = [];
      this.tempUploadImages = [];
      this.autoMakeFcltId = false;
      if (this.$refs.uploadFile) this.$refs.uploadFile.setFileData(null);

      if (this.$refs.uploadImage) this.$refs.uploadImage.setFileData(null);
      this.$refs.dkTemplateForm.resetForm();
    },
    markerDragEnd(result) {
      let me = this;
      me.fcltDataDoc.setNumberAddr = result.addr.num_addr || '정보없음';
      me.fcltDataDoc.setRoadAddr = result.addr.road_addr || '정보없음';
      me.fcltDataDoc.gisLat = result.geometry.lat;
      me.fcltDataDoc.gisLng = result.geometry.lng;
    },
    showDialog(updateFlag, args) {
      this.updateFlag = updateFlag;
      if (updateFlag) {
        this.formTitle = 'AI 카메라 정보 수정';
      } else {
        this.formTitle = 'AI 카메라 정보 추가';
        this.autoMakeFcltId = true;
        this.makeFcltId();
      }
      this.formDialog = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        if (args) {
          this.setFcltDataDoc(args);
        }
        this.fcltManageDialogGisInit();
      });
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    selectFcltType(val) {
      let tempFcltTypeList = this.fcltType.filter((item) => {
        return item.fcltTypeId.toLowerCase().indexOf(val.toLowerCase()) > -1;
      });
      if (tempFcltTypeList.length > 0) {
        this.fcltDataDoc.fcltTypeName = tempFcltTypeList[0].fcltTypeName;
        this.fcltDataDoc.fcltTypeData = this.$lodash.cloneDeep(tempFcltTypeList[0].fcltTypeData);
        this.templateData = tempFcltTypeList[0].fcltTypeData;
        let templateData = {
          template: this.templateData,
        };

        if (this.tempFcltTypeData) {
          (templateData.value = this.tempFcltTypeData), (this.templateData = dkTemplateUtil.setTemplateValue(templateData));
        }
        this.$refs.dkTemplateForm.createTemplateForm(this.templateData);
      }
      //this.createTemplateForm();
      // this.$lodash.map(this.tempFcltTypeData, (d) => {
      //   // if (this.fcltDataDoc.fcltTypeData[d.key]) {
      //   //   this.fcltDataDoc.fcltTypeData[d.key].value = d.value;
      //   // }
      // });
    },

    makeFcltId() {
      if (this.autoMakeFcltId) {
        this.fcltDataDoc.fcltId = uuid();
        // this.$refs.form0.clearValidate()
      } else {
        this.fcltDataDoc.fcltId = '';
      }
    },
    dkgisMounted(args) {
      //this.$refs.fcltManageDialogGis.gisModuleLoad(gisName);
      this.fcltManageDialogGisInit();
    },
    // createTemplateForm() {
    //   if (this.$refs.dkTemplateForm && this.fcltDataDoc.fcltTypeData) {
    //     this.$refs.dkTemplateForm.createTemplateForm(this.fcltDataDoc.fcltTypeData);
    //   }
    // },
  },
};
</script>

<style scoped>
.el-form-item__content {
  margin-left: 120px;
}

.fclt-form-step {
  min-height: 400px;
}
</style>
