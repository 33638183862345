<template>
  <div class="fill-width-height el-container">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <dk-el-table-header :openFormDialog="openFormDialog" :isSearch="false"></dk-el-table-header>
            <el-table
              :data="ledMessages"
              style="border-top: 1px solid #dedede; width: 100%;"
              empty-text="등록된 LED 메시지가 없습니다."
              size="mini"
              @row-click="selectRow"
              stripe
            >
              <el-table-column label="썸네일" min-width="150px">
                <template slot-scope="scope">
                  <led-preview-viewer v-if="scope.row.isFixable" :ledmessage="scope.row" :scale="3"></led-preview-viewer>
                  <img style="height: 64px; width: auto;" v-else :src="scope.row.thumbImageFile" />
                </template>
              </el-table-column>
              <el-table-column label="LED 메시지 이름" prop="ledMessageName" header-align="center" align="center"></el-table-column>

              <el-table-column label="LED 크기" prop="ledMessageSize" header-align="center" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.ledMessageSize == '1x6'">1단 6열</span>
                  <span v-else-if="scope.row.ledMessageSize == '2x6'">2단 6열</span>
                  <span v-else-if="scope.row.ledMessageSize == '2x8'">2단 8열</span>
                  <span v-else>기타</span>
                </template>
              </el-table-column>
              <el-table-column label width="170px">
                <template slot-scope="scope">
                  <el-button @click="openFormDialog(scope.row)" size="small" v-if="scope.row.isFixable">수정</el-button>
                  <el-button @click="removeLedMessage(scope.row)" v-if="scope.row.isFixable" size="small" type="danger">삭제</el-button>
                </template>
              </el-table-column>
            </el-table>
            <dk-el-pagination :totalDocs="totalDocs" :limit="limit" :pagingProc="pagingProc" ref="pagination"></dk-el-pagination>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <dk-info-panel ref="infoPanel" width="400px">
      <div class="info-panel-wrap"></div>
    </dk-info-panel>
    <form-led-message ref="formDialog"></form-led-message>
  </div>
</template>

<script>
import dkelpagination from '@/components/dkElPagination.vue';
import dkeltableheader from '@/components/dkElTableHeader.vue';
import dkinfopanel from '@/components/dkInfoPanel.vue';
import formLedMessage from '@/led/form/formLedMessage.vue';
import ledPreviewViewer from './form/ledPreviewViewer.vue';

export default {
  components: {
    'dk-el-table-header': dkeltableheader,
    'dk-info-panel': dkinfopanel,
    'dk-el-pagination': dkelpagination,
    'form-led-message': formLedMessage,
    'led-preview-viewer': ledPreviewViewer,
  },
  created() {
    this.$on('procDataComplete', (data) => {
      this.getLedMessages();
    });
  },
  mounted() {
    this.getLedMessages(1);
  },
  data() {
    return {
      ledMessages: [],
      limit: 10,
      totalDocs: 0,
      selectLedMessage: {},
    };
  },
  methods: {
    selectRow(row, event, column) {
      // if (!this.$refs.infoPanel.isOpened()) {
      //     this.$refs.infoPanel.open();
      // }
      this.selectLedMessage = row;
    },
    openFormDialog(row) {
      this.$refs.formDialog.showDialog(row);
    },
    removeLedMessage(args) {
      this.$confirm(`${args.ledMessageName} LED 메시지를 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/led/api/ledMessage/delete`, {
              data: args,
            });
            this.getLedMessages();
            this.$message({
              type: 'success',
              message: `${args.ledMessageName} LED 메시지 삭제 완료`,
            });
          } catch (err) {
            this.$message({
              type: 'info',
              message: `${args.ledMessageName} LED 메시지 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${args.ledMessageName} LED 메시지 삭제 취소`,
          });
        });
    },
    async getLedMessages(pageNum) {
      try {
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/led/api/ledMessage`, {
          params: {
            page: pageNum || this.$refs.pagination.getPage(),
            limit: this.limit,
          },
        });
        this.ledMessages = response.data.docs;
        this.totalDocs = response.data.totalDocs;
      } catch (err) {
        this.$message({
          type: 'info',
          message: `LED 메시지 로딩 중 에러발생`,
        });
      }
    },
    pagingProc(pageNum) {
      this.getLedMessages();
    },
    // scrollingProc(messageData) {
    //   const width = messageData.ledMessageData.canvasWidth * 4;
    //   const id = '#led' + messageData.ledMessageId + '';
    //   setTimeout(() => {
    //     document.querySelector(id).style.animationDuration = width / 200 + 's';
    //   }, 500);
    // },
    // getMessageScrollWrapStyle(messageData) {
    //   const size = messageData.ledMessageSize.split('x');
    //   let width = parseInt(size[1]) * 16 * 4;
    //   let height = parseInt(size[0]) * 16 * 4;
    //   if (messageData.leftLogo) {
    //     width -= messageData.leftLogo.width * 4;
    //   }
    //   if (messageData.rightLogo) {
    //     width -= messageData.rightLogo.width * 4;
    //   }
    //   return {
    //     width: width + 'px',
    //     height: height + 'px',
    //   };
    // },
    // getLogoSrc(logoData) {
    //   if (logoData.images.length > 0) {
    //     return logoData.images[0].serverFilePath;
    //   } else {
    //     return '';
    //   }
    // },
    // getLogoStyle(logoData) {
    //   return {
    //     width: logoData.width * 4 + 'px',
    //     height: logoData.height * 4 + 'px',
    //   };
    // },
  },
};
</script>
