var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "dkScrollView" } }, [
    _c("div", { staticClass: "dk-scroll-view-header" }, [_vm._t("header")], 2),
    _c("div", { staticClass: "dk-scroll-wrap" }, [
      _c("div", { staticClass: "dk-scroll-view-body" }, [_vm._t("body")], 2)
    ]),
    _c("div", { staticClass: "dk-scroll-view-footer" }, [_vm._t("footer")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }