<template>
  <div class="fill-width-height el-container" id="pageAssetManage">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap" style="height: 100%;">
          <dk-page-header>
            <el-button size="small" class="icon-button-2" @click="assetExportExcel">
              <img src="../assets/img/excel_icon.png" style="height:12px" />엑셀 출력
            </el-button>
            <el-button size="small" @click="addAutoAsset" class="icon-button-2" style="margin-right: 5px;">리얼허브 연동</el-button>
          </dk-page-header>
          <el-col :span="24" class="data-wrap">
            <div class="template-list">
              <!-- <div class="template-header">
                <h4>
                  템플릿 목록
                </h4>
                <el-button size="mini" @click="addTemplate">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </el-button>
              </div> -->

              <ul class="asset-sidemenu">
                <li class="asset-sidemenu__item" v-for="(item, idx) in templateList" :key="idx" @click="selectTemplate(item)">
                  <!-- <el-dropdown> -->
                  <div>
                    <i v-bind:class="[item.templateIcon ? item.templateIcon : '']"></i>
                    <div>{{ item.templateName }}</div>
                    <!-- <el-dropdown-menu slot="dropdown">
                            <el-button icon="el-icon-edit" @click="updateTemplate(item)" size="mini">수정</el-button>
                            <el-button icon="el-icon-delete" @click="deleteTemplate(item)" size="mini" type="danger">삭제</el-button>
                          </el-dropdown-menu> -->
                  </div>
                  <!-- </el-dropdown> -->
                </li>
              </ul>
            </div>
            <div class="asset-data-wrap">
              <div v-if="this.selectedTemplate" class="asset-data-grid" ref="assetDataGrid">
                <div class="asset-data-header">
                  <span class="title">
                    <h3>{{ this.selectedTemplate.templateName }}</h3>
                  </span>
                  <span class="button-wrap">
                    <el-button @click="addAsset" type="primary">자산정보 추가</el-button>
                  </span>
                </div>
                <el-table :data="assetList" style="width: 100%;" :height="gridHeight" lazy>
                  <el-table-column
                    v-for="(header, idx) in this.selectedTemplate.templateData"
                    :key="idx"
                    :prop="header.dataKey"
                    :label="header.label"
                  >
                    <template slot-scope="scope">
                      <div @click="viewDialog(scope.row)">
                        <span
                          v-if="
                            (scope.row[header.dataKey] && scope.row[header.dataKey].type === 'string') ||
                            (scope.row[header.dataKey] && scope.row[header.dataKey].type === 'number') ||
                            (scope.row[header.dataKey] && scope.row[header.dataKey].type === 'select')
                          "
                        >
                          {{ scope.row[header.dataKey].value }}
                        </span>
                        <span v-else-if="scope.row[header.dataKey] && scope.row[header.dataKey].type === 'date'">
                          {{ getFormatDate(scope.row[header.dataKey].value) }}
                        </span>
                        <span v-else> </span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column width="160">
                    <template slot-scope="scope">
                      <el-button @click="updateAsset(scope.row)" size="mini">수정</el-button>
                      <el-button @click="deleteAsset(scope.row)" size="mini" type="danger">삭제</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <form-asset-template ref="formAssetTemplate" @registerdTemplate="getTemplates" />
    <form-asset-dialog ref="formAssetDialog" @registeredAssetData="getAssets"></form-asset-dialog>
    <form-admin-dialog ref="formAssetAdminDialog"></form-admin-dialog>

    <!-- <el-dialog id="viewDialog" :visible.sync="formDialog" @close="hideDialog" :title="formData.templateName">
      <el-container class="dialog-container">
        <el-container>
          <el-row style="padding: 30px 10px; width: 100%;">
            <el-col :span="24">
              <div>
                <table class="dk-table dk-table-no-padding">
                  <colgroup>
                    <col style="width: 20%;" />
                    <col style="width: 30%;" />
                    <col style="width: 20%;" />
                    <col style="width: 30%;" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scople="row">시설물 명칭</th>
                      <td>asdasd</td>
                      <th scople="row">시설물 유형</th>
                      <td>asd</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </el-col>
          </el-row>
        </el-container>
        <el-aside width="180px">
          <div class="info-panel">
            <el-row>
            <el-col :span="24">
              <h4 style="margin: 10px;">유지관리 작업 관련사진</h4>
            </el-col>
            <el-col :span="24" v-if="selectMaintnceLog.files" class="maintnce-image-wrap">
              <el-row>
                <el-col :span="24" v-for="(file, index) in selectMaintnceLog.files" :key="index">
                  <img class="maintnce-image" :src="file.serverFilePath" @click="handlePictureCardPreview(file)" />
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          </div>
        </el-aside>
      </el-container>
    </el-dialog> -->
    <el-dialog id="viewDialog" :visible.sync="formDialog" @close="hideDialog" :title="formData.templateName">
      <dk-form ref="dkTemplateForm" @renderAfter="createTemplateForm"></dk-form>
      <div class="dk-form">
        <el-form label-position="left" label-width="165px" size="small">
          <el-form-item label="관련 자료">
            <div v-for="(file, index) in this.formData.files" :key="index">
              <a :href="$config.getServerConfig().image.url + file.serverFilePath"><i class="el-icon-document"></i>{{ file.name }}</a>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="formDialog = false">닫기</el-button>
      </span>
    </el-dialog>

    <el-dialog id="targetDeleteFcltDialog" :visible.sync="targetDeleteFcltDialog" title="삭제 대상 시설물 목록">
      <h4>삭제 대상 시설물 개소: {{ targetDeleteFcltList.length }}개소</h4>
      <h5>아래의 시설물 목록은 연동목록에 포함되지 않은 시설물에 해당됩니다. 삭제 버튼 클릭 시 삭제됩니다.</h5>      
      <el-table :data="targetDeleteFcltList" size="mini" stripe>
        <el-table-column prop="fcltName" label="시설물 명칭"></el-table-column>
        <el-table-column prop="fcltTypeName" label="시설물 유형" width="200"></el-table-column>        
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="targetDeleteFclt" type="primary">삭제</el-button>
        <el-button @click="targetDeleteFcltDialog = false">닫기</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import dkScrollView from '@/components/dkScrollView.vue';
import dkTemplateForm from '@/components/dkTemplate/dkTemplateForm';
// import dkTemplateTable from '@/components/dkTemplateTable.vue';
import formAssetTemplate from '@/core/forms/formAssetTemplate.vue';
import formAssetAdminDialog from '@/core/forms/formAssetAdminDialog.vue';
import formAssetDialog from '@/core/forms/formAssetDialog.vue';
import dkTemplateUtil from '@/components/dkTemplate/dkTemplateUtil';
import moment from 'moment';
import XLSX from 'xlsx';

export default {
  components: {
    'form-asset-template': formAssetTemplate,
    'dk-form': dkTemplateForm,
    'dk-scroll-view': dkScrollView,
    'form-asset-dialog': formAssetDialog,
    'form-admin-dialog': formAssetAdminDialog,
    // 'dk-template-table': dkTemplateTable,
  },
  created() {
    window.addEventListener('resize', this.resizeScreen);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeScreen);
  },
  data() {
    return {
      assetList: [],
      templateList: [],
      selectedTemplate: null,
      gridHeight: 500,
      systemLogInfo: {},
      useTab: false,
      formDialog: false,
      formData: {
        assetId: '',
        assetName: '',
        templateId: '',
        templateName: '',
        templateData: [],
        files: [],
      },
      loading: false,
      targetDeleteFcltList: [],
      targetDeleteFcltDialog: false,
    };
  },
  async mounted() {
    await this.getTemplates();
    await this.getAssets();
  },
  updated() {
    this.$nextTick(function () {
      this.resizeScreen();
    });
  },
  methods: {
    hideDialog() {
      this.formData = {
        assetId: '',
        assetName: '',
        templateId: '',
        templateName: '',
        templateData: [],
        files: [],
      };
      this.formDialog = false;
      this.$refs.dkTemplateForm.resetForm();
      this.$emit('registerdAsset');
    },
    async viewDialog(valueData) {
      this.formDialog = true;
      this.templateData = _.cloneDeep(this.selectedTemplate.templateData);
      this.formData.templateId = this.selectedTemplate.templateId;
      this.formData.templateName = this.selectedTemplate.templateName;
      if (valueData) {
        this.formData._id = valueData._id;
        this.formData.assetId = valueData.assetId;
        this.formData.assetName = valueData.assetName || '';
        this.formData.files = valueData.files;
        this.updateFlag = true;
        this.templateData = dkTemplateUtil.setFetcedTemplateValue({
          template: this.templateData,
          value: valueData,
        });
      }
      this.createTemplateForm();
    },
    createTemplateForm() {
      if (this.$refs.dkTemplateForm) {
        this.$refs.dkTemplateForm.createTemplateForm(this.templateData);
      }
    },
    async getTemplates() {
      const param = {
        featureId: this.$route.meta.featureId,
      };
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/template`, {
        params: param,
      });
      this.templateList = response.data;
      if (this.templateList && this.templateList.length > 0) {
        this.selectedTemplate = this.templateList[0];
      }
    },
    async getAssets() {
      const param = {
        templateId: this.selectedTemplate.templateId,
      };
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/asset`, {
        params: param,
      });
      this.assetList = response.data;
    },
    selectTemplate(template) {
      this.selectedTemplate = template;
      this.getAssets();
    },
    addTemplate() {
      this.$refs.formAssetTemplate.showDialog(this.$route.meta.featureId);
    },
    updateTemplate(templateData) {
      this.$refs.formAssetTemplate.showDialog(this.$route.meta.featureId, templateData);
    },
    deleteTemplate(templateData) {
      this.systemLogInfo.page = `${this.$route.name} ${templateData.templateName} 템플릿`;
      this.systemLogInfo.saveType = 'delete';
      this.$confirm(`${templateData.templateName} 템플릿을 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/template/delete`, { data: templateData });
            this.$message({
              type: 'success',
              message: `${templateData.templateName} 템플릿 삭제 완료`,
            });
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
            await this.getTemplates();
            await this.getAssets();
          } catch (err) {
            this.$message({
              type: 'info',
              message: `${templateData.templateName} 템플릿 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${templateData.templateName} 템플릿 삭제 취소`,
          });
        });
    },
    addAsset() {
      this.$refs.formAssetDialog.showDialog(null, this.selectedTemplate);
    },
    async addAutoAsset() {

      this.$confirm(`리얼허브 NVR 장비에서 시설물 정보를 연동합니다. 이 작업은 시간이 오래걸릴 수 있습니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          this.loading = this.$loading({
            lock: true,
            text: '리얼허브 NVR 시설물 정보 연동 중입니다.',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          this.$message({
            type: 'info',
            message: `리얼 허브와 연동중입니다.`,
          });
          let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/asan/api/getRestApi`, { timeout: 600000 });
          if (result.data.length > 0) {
            this.targetDeleteFcltList = result.data;
            this.targetDeleteFcltDialog = true;
          }
          this.loading.close();
        })
        .catch(() => {
          setTimeout(() => {
            this.loading.close();
            this.$message({
              type: 'info',
              message: `리얼허브 NVR 시설물 정보 연동 취소`,
            });
          }, 1000);
        });
    },
    updateAsset(assetData) {
      this.$refs.formAssetDialog.showDialog(assetData, this.selectedTemplate);
    },
    deleteAsset(assetData) {
      this.$confirm(`해당 자산정보를 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/asset/delete`, { data: assetData });
            this.$message({
              type: 'success',
              message: `자산정보 삭제 완료`,
            });
            await this.getAssets();
          } catch (err) {
            this.$message({
              type: 'info',
              message: `자산정보 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `자산정보 삭제  취소`,
          });
        });
    },
    resizeScreen() {
      this.gridHeight = this.$refs.assetDataGrid.offsetHeight - 60;
    },
    getFormatDate(timestamp) {
      return moment.unix(timestamp / 1000).format('YYYY-MM-DD');
    },
    async assetExportExcel() {
      let label = {};
      let bodyData = [];
      let headerData = [];
      let optionData = [];
      let labelData = [];
      // const header = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/asset/getHeader`, { params: this.selectedTemplate });
      for (let i = 0; i < this.selectedTemplate.templateData.length; i++) {
        headerData.push({
          key: i,
          name: this.selectedTemplate.templateData[i].dataKey,
        });
        if (this.selectedTemplate.templateData[i].options) {
          optionData.push({
            key: this.selectedTemplate.templateData[i].dataKey,
            option: this.selectedTemplate.templateData[i].options,
          });
        }
        label[this.selectedTemplate.templateData[i].dataKey] = this.selectedTemplate.templateData[i].label;
      }
      labelData.push(label);
      const body = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/asset/getExcelData`, {
        params: {
          templateId: this.selectedTemplate.templateId,
        },
      });
      body.data.forEach((n) => {
        let rowData = {};
        _.map(headerData, (filter) => {
          const key = filter.name;
          const val = _.find(n, (v, k) => {
            return k === key;
          });

          if (typeof val === 'object') {
            if (val.type === 'date') {
              rowData[key] = moment.unix(parseFloat(val.value) / 1000).format('YYYY-MM-DD');
            } else {
              rowData[key] = val.value;
            }
          } else {
            rowData[key] = val;
          }
        });
        bodyData.push(rowData);
      });
      const excelData = this.$lodash.union(labelData, bodyData);
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.json_to_sheet(excelData, headerData);
      ws['!rows'] = [];
      ws['!rows'][0] = { hidden: true };
      XLSX.utils.book_append_sheet(wb, ws, 'Data Sheet');
      XLSX.writeFile(wb, `자산관리 시트.xlsx`, { bookType: 'xlsx', type: 'array' });
      this.systemLogInfo.page = this.$route.name + '템플릿';
      this.systemLogInfo.saveType = 'exportExcel';
      await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
    },
    adminTemplate(data) {
      this.$refs.formAssetAdminDialog.showDialog(data);
    },
    targetDeleteFclt() {
      this.$confirm(`해당 시설물 목록을 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/asan/api/deleteFclt`, { data: this.targetDeleteFcltList });
            this.targetDeleteFcltDialog = false;
            this.$message({
              type: 'success',
              message: `시설물 목록 삭제 완료`,
            });
          } catch (err) {
            this.targetDeleteFcltDialog = false;
            this.$message({
              type: 'info',
              message: `시설물 목록 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.targetDeleteFcltDialog = false;
          this.$message({
            type: 'info',
            message: `시설물 목록 삭제 취소`,
          });
        });        
    }
  },
};
</script>

<style scoped></style>
