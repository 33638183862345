<template>
  <el-dialog :title="formTitle" :visible.sync="formDialog" @close="hideDialog" :close-on-click-modal="false" top="5vh">
    <div>
      <el-steps :active="step" finish-status="success" align-center style="margin: 5px 0 10px 0;">
        <el-step v-for="(s, index) in stepData" :key="index" :title="s.title"></el-step>
      </el-steps>
      <div v-show="step === 0">
        <el-form :model="formData" label-width="160px" size="medium" :rules="rules" ref="form0" :label-position="$isMobile ? 'top' : 'right'">
          <el-row>
            <el-col :xs="24" :sm="18" :md="18">
              <el-form-item label="센터 장비명" prop="centerEquipName">
                <el-input v-model="formData.centerEquipName" placeholder="센터 장비 명칭을 입력하세요"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="18" :md="18">
              <el-form-item label="센터 장비 유형" prop="centerEquipType">
                <el-select v-model="formData.centerEquipType" @change="selectCenterEquipType" placeholder="센터 장비 유형을 선택하세요">
                  <el-option v-for="(item, index) in centerEquipTypeData" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="18" :md="18">
              <el-form-item label="납품 업체명" prop="supplyCompany">
                <el-input v-model="formData.supplyCompany" placeholder="납품 업체 명칭을 입력하세요"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="18" :md="18">
              <el-form-item label="납품 업체 연락처" prop="supplyPhoneNumber">
                <el-input v-model="formData.supplyPhoneNumber" placeholder="납품 업체 연락처를 입력하세요"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="18" :md="18">
              <el-form-item label="납품 일자" prop="supplyDate">
                <el-date-picker type="date" v-model="formData.supplyDate" placeholder="납품 일자"></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="18" :md="18">
              <el-form-item label="설치 업체명" prop="installCompany">
                <el-input v-model="formData.installCompany" placeholder="설치 업체 명칭을 입력하세요"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="18" :md="18">
              <el-form-item label="설치 업체 연락처" prop="installPhoneNumber">
                <el-input v-model="formData.installPhoneNumber" placeholder="설치 업체 연락처를 입력하세요"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="18" :md="18">
              <el-form-item label="내용" prop="text">
                <el-input type="textarea" :autosize="{ minRows: 5 }" v-model="formData.text" placeholder="센터 정보 내용"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="18" :md="18">
              <el-form-item label="관련 자료">
                <dk-el-file-upload ref="uploadFile" path="centerEquip" :fileList="formData.files" :multiple="true">
                  <div style="text-align: left;">
                    <el-button size="small" type="primary">관련자료 업로드</el-button>
                    <div slot="tip" class="el-upload__tip">센터장비 구축에 관련된 자료를 업로드 할 수 있습니다.</div>
                  </div>
                </dk-el-file-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div v-show="step === 1">
        <el-row>
          <el-col :span="24">
            <el-form label-width="120px" size="medium" :label-position="$isMobile ? 'top' : 'right'">
              <el-form-item label="장비 검색" prop="equipSearch">
                <el-select
                  v-model="tempMaterialId"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="장비 모델명을 입력하세요"
                  :remote-method="searchMaterialData"
                  :loading="loading"
                >
                  <el-option v-for="(material, index) in materialList" :key="index" :label="material.materialName" :value="material.materialId">
                    <span style="float: left; text-align: center; width: 50px;" v-if="material.images && material.images.length > 0">
                      <img :src="$config.getServerConfig().image.url + material.images[0].serverFilePath" style="height: 34px; width: auto;" />
                    </span>
                    <span style="float: left;">
                      {{ material.materialName }}
                    </span>
                  </el-option>
                </el-select>
                <el-button @click="addSelectEquipList">추가</el-button>
              </el-form-item>
            </el-form>
          </el-col>

          <el-col :md="24">
            <el-table v-if="checkEmptyEquip" :data="formData.equip" style="width: 100%;" highlight-current-row @current-change="showSelectEquipForm">
              <el-table-column label width="100px" style="text-align: center;">
                <template slot-scope="scope">
                  <img
                    v-if="scope.row.images && scope.row.images.length > 0"
                    :src="$config.getServerConfig().image.url + scope.row.images[0].serverFilePath"
                    style="height: 60px; width: auto;"
                  />
                </template>
              </el-table-column>
              <el-table-column label="장비 유형" width="150px" prop="materialTypeName"></el-table-column>
              <el-table-column label="장비 명칭" prop="materialName"></el-table-column>
              <el-table-column label width="100px">
                <template slot-scope="scope">
                  <el-button type="danger" size="mini" @click.stop="deleteEquipList(scope.$index, formData.equip)">삭제</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :xs="24" :sm="18" :md="18" style="margin-top: 10px;">
            <dk-dynamic-form labelWidth="120px" :formData="selectEquipFormData"></dk-dynamic-form>
          </el-col>
        </el-row>
      </div>

      <div class="form-bottom">
        <el-button @click="prev" style="float: left;" v-show="step > 0"> <i class="fas fa-angle-left"></i>&nbsp;&nbsp;이전 </el-button>
        <el-button @click="next" style="float: right;" v-show="step < stepData.length - 1">
          다음&nbsp;&nbsp;
          <i class="fas fa-angle-right"></i>
        </el-button>
        <el-button @click="save" style="float: right;" v-show="step === stepData.length - 1">
          저장&nbsp;&nbsp;
          <i class="fas fa-angle-right"></i>
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import dkdynamicform from '@/components/dkDynamicForm.vue';
import dkElFileUpload from '@/components/dkElFileUpload.vue';
import uuid from 'uuid/v1';

export default {
  components: {
    'dk-dynamic-form': dkdynamicform,
    'dk-el-file-upload': dkElFileUpload,
  },
  data() {
    return {
      formDialog: false,
      formTitle: '',
      step: 0,
      stepData: [
        { idx: 0, title: '센터 정보 관리' },
        { idx: 1, title: '센터 장비 정보' },
      ],
      formData: {
        supplyCompany: '',
        supplyPhoneNumber: '',
        installCompany: '',
        installPhoneNumber: '',
        supplyDate: null,
        centerEquipName: '',
        text: '',
        files: [],
        equip: [],
        centerEquipType: '',
        centerEquipTypeName: '',
      },
      centerEquipTypeData: [
        { label: '서버', value: 'server' },
        { label: '네트워크', value: 'network' },
        { label: '운영PC', value: 'pc' },
      ],
      rules: {
        centerEquipName: [
          {
            required: true,
            message: '센터 장비 명칭을 입력해 주세요',
            trigger: 'blur',
          },
        ],
        centerEquipType: [
          {
            required: true,
            message: '센터 장비 유형을 선택해 주세요',
            trigger: 'blur',
          },
        ],
        supplyCompany: [
          {
            required: true,
            message: '납품 업체 명칭을 입력해 주세요',
            trigger: 'blur',
          },
        ],
        installCompany: [
          {
            required: true,
            message: '설치 업체 명칭을 입력해 주세요',
            trigger: 'blur',
          },
        ],
      },
      materialList: [],
      loading: false,
      tempMaterialId: '',
      selectEquipFormData: {},
    };
  },
  created() {},
  computed: {
    checkEmptyEquip() {
      if (!this.formData.equip) {
        return false;
      }
      if (this.formData.equip && this.formData.equip.length > 0) {
        if (this.formData.equip[0]) {
          return true;
        }
        this.formData.equip.splice(0, 1);
      }
      return false;
    },
  },
  methods: {
    next() {
      if (this.$refs[`form${this.step}`]) {
        this.$refs[`form${this.step}`].validate((valid) => {
          if (valid) {
            this.step++;
          } else {
            return false;
          }
        });
      } else {
        this.step++;
      }

      if (this.step == 1) {
      }
    },
    prev() {
      if (this.step == 1) {
      }
      this.step--;
    },
    async save() {
      this.step++;
      let me = this;
      this.formData.files = this.$refs.uploadFile.getFileData();
      let saveFunc = 'centerEquip/add';
      if (this.updateFlag) {
        saveFunc = 'centerEquip/update';
      }
      try {
        let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, {
          data: this.formData,
        });
        me.$parent.$emit('procDataComplete', this.formData);
        me.hideDialog();
        me.$message({
          type: 'success',
          message: `센터 장비 정보 등록 완료`,
        });
      } catch (error) {
        this.hideDialog();
        this.$message.error(`센터 장비 정보 등록 중 에러 발생`);
      }
    },
    showDialog(row) {
      if (row) {
        this.updateFlag = true;
        this.formTitle = '센터 장비 정보 수정';
        this.formData = this.$lodash.cloneDeep(row);
      } else {
        this.updateFlag = false;
        this.formTitle = '센터 장비 정보 추가';
        this.resetData();
      }
      this.formDialog = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        if (row) {
          this.$refs.uploadFile.setFileData(row.files);
        } else {
          this.$refs.uploadFile.setFileData(null);
        }
      });
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    resetData() {
      this.formData = {
        supplyCompany: '',
        supplyPhoneNumber: '',
        installCompany: '',
        installPhoneNumber: '',
        supplyDate: null,
        centerEquipName: '',
        text: '',
        files: [],
        equip: [],
      };
      this.step = 0;
      this.selectEquipFormData = {};
    },
    changeIsPopup(val) {
      if (val) {
        //if (!this.formData.endDate) {
        //  this.formData.endDate = new Date();
        //}
      }
    },
    searchMaterialData(query) {
      let me = this;
      let func = async function () {
        me.loading = true;
        let response = await me.$http.get(`${this.$config.getServerConfig().core.api}/core/api/material`, {
          params: {
            keyword: query,
          },
        });
        me.materialList = response.data.docs;
        me.loading = false;
      };
      if (query !== '') {
        //me.$lodash.debounce(func, 500);
        setTimeout(func, 500);
      } else {
        this.materialList = [];
      }
    },
    addSelectEquipList() {
      let me = this;
      let tempEquipList = this.materialList.filter((item) => {
        return item.materialId == me.tempMaterialId;
      });
      if (tempEquipList.length > 0) {
        let data = this.$lodash.cloneDeep(tempEquipList[0]);
        data.equipId = uuid();
        this.formData.equip.push(data);
      }
    },
    deleteEquipList(index, rows) {
      rows.splice(index, 1);
      this.selectEquipFormData = {};
    },
    showSelectEquipForm(selectEquipData) {
      //console.log(selectEquipData);
      if (selectEquipData) {
        if (selectEquipData.equipData) {
          this.selectEquipFormData = selectEquipData.equipData;
        }
      }
    },
    selectCenterEquipType(itemVal) {
      let selItem = this.centerEquipTypeData.find((data) => {
        return data.value == itemVal;
      });
      this.formData.centerEquipTypeName = selItem.label;
    },
  },
};
</script>
