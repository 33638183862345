<template>
  <div class="page-set page-flooding">
    <div class="top-bar-space" style="bottom: 0; overflow: hidden;">
      <div class="fill-width-height" ref="gisContainer">
        <dk-gis
          fill-height
          ref="dkGis"
          id="dkGis"
          :gisApiInfo="gisApiInfo"
          :apiLoadComplete="apiLoadComplete"
          :clusterData="clusterData"
          :selectBoxData="selectBoxData"
          @moveEnd="changedBounds"
          @selectMarker="selectMarker"
        />
      </div>
      <div class="legend-wrap" style="margin-top: 60px;">
        <ul>
          <li
            v-for="fcltType in fcltTypeList"
            :key="fcltType.fcltTypeId"
            :class="'flex-center ' + isSelectFcltType(fcltType)"
            @click="onClickFcltTypeSelect(fcltType)"
          >
            <!-- 시설물 범례 클릭 온오프 -->
            <img :src="fcltType.gisMarkerImage" />
            <span>{{ fcltType.fcltTypeName }}</span>
          </li>
        </ul>
      </div>

      <div class="d-flex flooding-btn">
        <div class="hamberger-box">
          <div class="hamberger mb-both-mg">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div @click="selectListDetail()">
          침수 지역 목록 보기
        </div>
      </div>
      <div class="fclt-menu-item-container flooding-container" style="bottom: 0;" ref="detail">
        <span class="fclt-info-open" @click="freeFloodginList" ref="fcltInfoOpen">
          <i class="arrow-click icon-arrow arrow-reverse"></i> 시설물 보기
        </span>
        <div class="mobile-fclt-label" ref="mobileFcltLabel">
          <!-- <span class="caution">주의</span>
            <span class="alert">경계</span>
            <span class="critical">심각</span>
            <span class="overflow">범람</span> -->
          <template v-if="floodginList && floodginList.length === 1">
            <template v-if="floodginList[0].fcltTypeId === 'waterlevel'">
              <p class="fclt-name">침수 지역 데이터 목록</p>
              <div class="fclt-type legend-circle" style="width: auto;">
                <span class="caution">심각</span>
                <span class="alert">경계</span>
                <span class="critical">주의</span>
                <span class="overflow">관심</span>
                <span class="primary">정상</span>
              </div>
            </template>
            <template v-else-if="floodginList[0].fcltTypeId === '7d9235d0-727b-11eb-8c89-ed5afab83358'">
              <p class="fclt-name">침수 지역 데이터 목록</p>
              <div class="fclt-type legend-circle" style="width: auto;">
                <span class="caution">침수</span>
                <span class="primary">정상</span>
              </div>
            </template>
            <template v-else-if="floodginList[0].fcltTypeId === 'd55690f0-74cd-11eb-a37b-379839e65cab'">
              <p class="fclt-name">침수 지역 데이터 목록</p>
              <div class="fclt-type legend-circle" style="width: auto;">
                <span class="alert">열림</span>
                <span class="primary">닫힘</span>
              </div>
            </template>
            <template v-else>
              <p class="fclt-name">{{ floodginList[0].fcltTypeName }}</p>
            </template>
          </template>
        </div>

        <div class="flooding-list" ref="floodingList">
          <div class="flooding-list-container" v-for="floodgincontent in floodginList">
            <!-- <div class="mb-bottom-mg maintenance-summary">
              <span>종류</span>
              <span>{{ floodgincontent.fcltTypeName }}</span>
            </div>
            <div class="mb-bottom-mg maintenance-summary">
              <span>명칭</span>
              <span>{{ floodgincontent.fcltName }}</span>
            </div> -->
            <!-- 수위계 -->
            <template v-if="floodgincontent.fcltTypeId === 'waterlevel'">
              <div class="mb-bottom-mg maintenance-summary">
                <span>종류</span>
                <span>{{ floodgincontent.fcltTypeName }}</span>
              </div>
              <div class="mb-bottom-mg maintenance-summary">
                <span>명칭</span>
                <span>{{ floodgincontent.fcltName }}</span>
              </div>
              <div class="d-flex">
                <div class="mb-bottom-mg w-50 maintenance-summary">
                  <span>현재 수위</span>
                  <span>{{ floodgincontent.waterLevel }}</span>
                </div>
              </div>
              <div class="mb-bottom-mg maintenance-summary stat-text-color">
                <span>수위 상태</span>
                <span :class="floodgincontent.className">{{ floodgincontent.status }}</span>
              </div>
            </template>
            <!-- 침수센서 -->
            <template v-else-if="floodgincontent.fcltTypeId === '7d9235d0-727b-11eb-8c89-ed5afab83358'">
              <div class="mb-bottom-mg maintenance-summary">
                <span>종류</span>
                <span>{{ floodgincontent.fcltTypeName }}</span>
              </div>
              <div class="mb-bottom-mg maintenance-summary">
                <span>명칭</span>
                <span>{{ floodgincontent.fcltName }}</span>
              </div>
              <div class="d-flex">
                <div class="mb-bottom-mg maintenance-summary stat-text-color">
                  <span>침수 상태</span>
                  <span :class="floodgincontent.className">{{ floodgincontent.status }}</span>
                </div>
              </div>
              <div class="d-flex">
                <div class="mb-bottom-mg maintenance-summary">
                  <span>{{ floodgincontent.updateName }}</span>
                  <span>{{ floodgincontent.updateTime }}</span>
                </div>
              </div>
            </template>
            <!-- 차단기 -->
            <template v-else-if="floodgincontent.fcltTypeId === 'd55690f0-74cd-11eb-a37b-379839e65cab'">
              <div class="mb-bottom-mg maintenance-summary">
                <span>종류</span>
                <span>{{ floodgincontent.fcltTypeName }}</span>
              </div>
              <div class="mb-bottom-mg maintenance-summary">
                <span>명칭</span>
                <span>{{ floodgincontent.fcltName }}</span>
              </div>
              <div class="d-flex">
                <div class="mb-bottom-mg w-50 maintenance-summary stat-text-color">
                  <span>상태</span>
                  <span :class="floodgincontent.className">{{ floodgincontent.status }}</span>
                </div>
              </div>
              <div class="d-flex">
                <div class="mb-bottom-mg maintenance-summary">
                  <span>상태유지시간</span>
                  <span>{{ Math.floor(floodgincontent.properties.status_duration / 60) }}분</span>
                </div>
              </div>
            </template>
            <!-- 기타 시설물 -->
            <template v-else>
              <div class="mb-bottom-mg maintenance-summary">
                <span>명칭</span>
                <span>{{ floodgincontent.fcltName }}</span>
              </div>
              <ul class="fclt-detail-view-menu">
                <li @click="setRoadview">
                  <i class="fas fa-map-marker-alt"></i>
                  <div>거리뷰</div>
                </li>
                <li @click="selectNavigation">
                  <i class="fas fa-wrench"></i>
                  <div>네비게이션</div>
                </li>
              </ul>
              <!-- <div class="d-flex">
                <div class="mb-bottom-mg w-50 maintenance-summary stat-text-color">
                  <span>상태</span>
                  <span :class="floodgincontent.className">{{ floodgincontent.status }}</span>
                </div>
              </div>
              <div class="d-flex">
                <div class="mb-bottom-mg maintenance-summary">
                  <span>상태유지시간</span>
                  <span>{{ Math.floor(floodgincontent.properties.status_duration / 60) }}분</span>
                </div>
              </div> -->
            </template>
          </div>
        </div>
      </div>
    </div>
    <div :class="'side-menu-container ' + (isNaviSelect ? 'd-block' : 'd-none')" @click="backgroundOff">
      <div class="navi-wrap d-flex">
        <div class="btn-style" @click="kakaoNavi"><img src="../assets/img/kakaonavi.png" /><span>카카오내비</span></div>
        <div class="btn-style" @click="tmapNavi"><img src="../assets/img/tmap.png" /><span>TMap</span></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import dkgismain from '@/components/dkGis/dkGisMain.vue';

export default {
  components: {
    'dk-gis': dkgismain,
  },
  props: {
    checkBackAction: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    fcltTypeList: [
      {
        fcltTypeId: '7d9235d0-727b-11eb-8c89-ed5afab83358',
        isView: false,
        isFlood: true,
        label: 'getFloodsensorLabel',
        type: 'flood',
        // fcltTypeName: '침수센서',
      },
      {
        fcltTypeId: 'd55690f0-74cd-11eb-a37b-379839e65cab',
        isView: false,
        isFlood: true,
        label: 'getGateguardLabel',
        type: 'gateguard',
        // fcltTypeName: '차단기',
      },
      {
        fcltTypeId: 'waterlevel',
        isView: false,
        isFlood: true,
        label: 'getWaterlevelLabel',
        type: 'waterlevel',
        // fcltTypeName: '수위계',
      },
      {
        fcltTypeId: '재난방재',
        isView: false,
        isFlood: false,
        label: 'getFcltLabel',
        type: 'fclt',
        // gisMarkerImage: 'static/images/gis/icon/자산 CCTV_default.svg',
        // fcltTypeName: '재난방재CCTV',
      },
    ],
    fcltList: [],

    markerLayer: undefined,

    gisApiInfo: [],

    floodginList: [],

    touchCloseFlag: false,
    startY: 0,
    moveY: 0,

    lat: 35.5830624002618,
    lng: 129.36080151001,

    waterLevelStatusInfo: [
      {
        className: 'primary',
        status: '정상',
      },
      {
        className: 'overflow',
        status: '관심',
      },
      {
        className: 'critical',
        status: '주의',
      },
      {
        className: 'alert',
        status: '경계',
      },
      {
        className: 'caution',
        status: '심각',
      },
    ],

    floodStatusInfo: {
      정상: {
        className: 'primary',
        status: '정상',
        updateName: '침수 해제 시각',
      },
      침수: {
        className: 'caution',
        status: '침수',
        updateName: '침수 발생 시각',
      },
    },

    gateguardStatusInfo: {
      '0': {
        className: 'primary',
        status: '열림',
      },
      '1': {
        className: 'caution',
        status: '닫힘',
      },
    },

    // waterLevelConfig: {},

    // intervalKey: 0,
    runRoop: true,
    intervalTime: 15 * 1000,
    remainTime: 0,

    isArrowReverse: false,

    isNaviSelect: false,

    selectBoxData: [],
  }),
  created() {
    const me = this;

    (async () => {
      me.gisApiInfo = me.$store.getters['gis/getGisApi'];
      if (!me.gisApiInfo.length || me.gisApiInfo.length.length === 0) {
        await me.$store.dispatch('gis/actGisApi', {});
        me.gisApiInfo = me.$store.getters['gis/getGisApi'];
      }
      me.gisApiInfo[0].apiKey = me.$config.getSiteConfig().gis.apiKey;
      me.gisApiInfo[0].geometry = {
        lat: me.lat,
        lng: me.lng,
      };
    })();

    // this.backAction();
  },
  destroyed() {
    this.runRoop = false;
  },
  mounted() {
    this.backAction();
    this.$refs.detail.addEventListener('touchstart', this.onTouchstart, false);
    this.$refs.detail.addEventListener('touchmove', this.onTouchmove, false);
    this.$refs.detail.addEventListener('touchend', this.onTouchEnd, false);
  },
  computed: {},
  methods: {
    backAction() {
      const me = this;
      window.backAction = () => {
        try {
          if (window.JSBridge && window.JSBridge.wvStatus) {
            window.JSBridge.wvStatus('using');
          }
          // 사이드 메뉴바 상테 확인
          if (this.checkBackAction()) {
            return;
          }
          // 네비게이션 선택창 닫기
          else if (this.isNaviSelect) {
            this.backgroundOff();
          }
          // 선택 시설물 취소
          else if (this.floodginList && this.floodginList.length > 0) {
            this.floodginList = [];
          }
          // 시설물 선택 박스 취소
          else if (this.selectBoxData && this.selectBoxData.length > 0) {
            this.selectBoxData = [];
          } else {
            this.$router.go(-1);
          }
        } catch (err) {
          this.$message('뒤로가기 에러');
        }
      };
    },

    apiLoadComplete(currentGisApi) {
      const me = this;
      this.markerLayer = this.$refs.dkGis.createMarkerLayer();
      this.cctvMarkerLayer = this.$refs.dkGis.createMarkerLayer();
      this.$refs.dkGis.setClickEvent(this.mapClickEvent);

      (async () => {
        await me.getFcltType();
        me.roopFcltType();
      })();
    },

    async sleep(time) {
      return new Promise((resolve, rejcet) => {
        setTimeout(() => {
          return resolve();
        }, time);
      });
    },

    async roopFcltType() {
      const me = this;

      while (me.runRoop) {
        await me.sleep(1000);
        if (!me.runRoop) break;
        else if (me.remainTime <= 0) {
          me.remainTime = me.intervalTime;
          me.replaceFclt();
        } else {
          me.remainTime -= 1000;
        }
      }
    },

    async replaceFclt() {
      this.remainTime = this.intervalTime;

      await this.getFclt();
      this.setIconLabel();
    },

    async requestEvent(args) {
      try {
        const { search = {}, sort, limit } = args;

        const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/ulsanBukgu/api/event`, { search, sort, limit });
        if (!response || response.status !== 200) {
          throw new Error();
        }
        return response.data;
      } catch (err) {
        // this.$message({
        //   type: 'error',
        //   message: 'requestEvent faile',
        // });
        return [];
      }
    },

    selectListDetail(key) {
      const me = this;
      // return this.openDetailData();
      let showFcltList = [];

      if (typeof key !== 'undefined') {
        const fcltInfo = this.fcltList.find((elm) => elm.key === key);
        if (!fcltInfo) {
          return;
        }
        showFcltList = [fcltInfo];
      } else {
        const fcltTypeIdObj = {};
        this.fcltTypeList.forEach((fcltTypeInfo) => {
          fcltTypeIdObj[fcltTypeInfo.fcltTypeId] = fcltTypeInfo.isFlood;
        });

        showFcltList = this.fcltList.filter((elm) => {
          return fcltTypeIdObj[elm.properties.fcltTypeId];
        });
        // showFcltList = [...this.fcltList];
      }

      this.floodginList = showFcltList.map((showFcltInfo) => {
        const { key, properties } = showFcltInfo;
        const { fcltName, fcltTypeId, fcltTypeName, fcltTypeData = {} } = properties;
        // const { waterLevelLimit = {} } = fcltTypeData;

        let result = {
          key,
          fcltName,
          fcltTypeId,
          fcltTypeName,
          status: '없음',
          className: '',
        };

        switch (fcltTypeId) {
          // 수위계
          case 'waterlevel': {
            const data = me.getWaterlevelInfo(showFcltInfo);
            result = {
              ...result,
              ...data,
            };
            break;
          }
          // 침수센서
          case '7d9235d0-727b-11eb-8c89-ed5afab83358': {
            const data = me.getFloodsensorInfo(showFcltInfo);
            result = {
              ...result,
              ...data,
            };
            break;
          }
          // 차단기
          case 'd55690f0-74cd-11eb-a37b-379839e65cab': {
            const data = me.getGateguardInfo(showFcltInfo);
            result = {
              ...result,
              ...data,
            };
            break;
          }
          default: {
            const data = me.getFcltShowDataInfo(showFcltInfo);
            result = {
              ...result,
              ...data,
            };
          }
        }

        return result;

        // return {
        //   key,
        //   fcltName,
        //   fcltTypeId,
        //   // currentLevel: '8',
        //   // level: waterLevelLimit.value || '4',
        //   // time: '2020-02-20 20:02',
        //   // sensor: '심각',
        // };
      });
    },

    freeFloodginList() {
      this.floodginList = [];
    },

    openDetailData() {
      // const calcHead = 55;
      // const calcBody = 109;
      // const calcBody = 129;
      // const calcBody = 140;

      const calcHead = this.$refs.mobileFcltLabel.offsetHeight;
      const calcBody = this.$refs.floodingList.offsetHeight / 3;

      let len = this.floodginList.length;
      if (len > 3) {
        len = 3;
      }

      this.$refs.detail.style = `top: calc( 100% - ${calcHead + len * calcBody}px ) !important`;
      // this.$refs.detail.style = `top: calc( 100% - ${calcHead + calcBody}px ) !important`;
      // this.$refs.detail.style = `top: calc( 100% - ${55 + 400}px ) !important`;
    },
    closeListDetail() {
      this.$refs.detail.style = 'top:100% !important';
    },

    onClickFcltTypeSelect(fcltType) {
      const { fcltTypeId } = fcltType;
      // const fcltTypeData = this.fcltTypeList.find((ft) => ft.fcltTypeId === fcltTypeId);
      // fcltTypeData.isView = !fcltTypeData.isView;
      this.fcltTypeList = this.fcltTypeList.map((ft) => {
        let isView = ft.isView;
        if (ft.fcltTypeId === fcltTypeId) {
          isView = !isView;
        }
        return {
          ...ft,
          isView,
        };
      });
    },

    onTouchstart(e) {
      if (this.$refs.floodingList.scrollTop === 0) {
        this.touchCloseFlag = true;
      }
      this.startY = e.touches[0].clientY;
      this.moveY = 0;
    },

    onTouchmove(e) {
      if (!this.touchCloseFlag) return;
      // console.log(this.$refs.floodingList.scrollTop);
      this.moveY = e.touches[0].clientY - this.startY;
    },

    onTouchEnd(e) {
      if (!this.touchCloseFlag) return;
      if (this.moveY > 30) {
        // 터치 다운무브
        this.closeListDetail();
      } else if (this.moveY < -30) {
        // 터치 업무브
        // this.openDetailData();
      }
      this.moveY = 0;
      this.touchCloseFlag = false;
    },

    isSelectFcltType(fcltType) {
      const { fcltTypeId } = fcltType;
      const fcltTypeData = this.fcltTypeList.find((ft) => ft.fcltTypeId === fcltTypeId);

      let result = '';

      if (fcltTypeData) {
        const { isView } = fcltTypeData;

        if (isView) {
          result = 'select-fclt-type';
        } else {
          result = 'not-select-fclt-type';
        }
      }

      return result;
    },

    async requestFcltType(args) {
      const me = this;
      try {
        const response = await me.$http.get('coreapi/core/api/fcltType', {
          params: args,
        });

        if (!response || response.status !== 200) {
          throw new Error();
        }

        return response.data.docs;
      } catch (err) {
        // me.$message.error('시설물타입 정보 읽기 실패');
      }
    },

    async getFcltType() {
      const me = this;
      try {
        const params = { fcltTypeId: [] };
        params.fcltTypeId = me.fcltTypeList.map(({ fcltTypeId }) => fcltTypeId);

        const resFcltTypeList = await me.requestFcltType(params);

        const fcltTypeList = [];

        resFcltTypeList.forEach((resFcltType) => {
          const fcltType = me.fcltTypeList.find(({ fcltTypeId }) => {
            return fcltTypeId === resFcltType.fcltTypeId;
          });
          if (!!fcltType && !!fcltType.fcltTypeId) {
            let gisMarkerImage = fcltType.gisMarkerImage;
            if (!gisMarkerImage && resFcltType.gisMarkerImage && resFcltType.gisMarkerImage.normal) {
              gisMarkerImage = resFcltType.gisMarkerImage.normal;
            }

            fcltTypeList.push({
              ...fcltType,
              fcltTypeName: resFcltType.fcltTypeName,
              gisMarkerImage,
              isView: true,
            });
          }
        });

        me.fcltTypeList = fcltTypeList;
      } catch (err) {
        // me.$message.error('시설물타입 정보 설정 실패');
      }
    },

    async requestFclt(args) {
      const me = this;
      try {
        const response = await me.$http.get('coreapi/core/api/fclt', {
          params: args,
        });

        if (!response || response.status !== 200) {
          throw new Error();
        }

        return response.data.docs;
      } catch (err) {
        // me.$message.error('시설물 정보 읽기 실패');
        return [];
      }
    },

    async getFclt() {
      const me = this;
      try {
        const params = { fcltTypeId: [] };

        const fcltTypesInfo = {};
        me.fcltTypeList.forEach((elm) => {
          const { fcltTypeId, isView } = elm;
          fcltTypesInfo[fcltTypeId] = { ...elm };

          if (isView) {
            params.fcltTypeId.push(fcltTypeId);
          }
        });

        if (params.fcltTypeId.length === 0) {
          return (me.fcltList = []);
        }

        // const resFcltList = await me.requestFclt(params);
        let resFcltList = [];
        let pm = [];

        if (Array.isArray(params.fcltTypeId)) {
          pm = params.fcltTypeId.map((fTypeId) => {
            return me.requestFclt({ fcltTypeId: [fTypeId] });
          });
        } else {
          pm = [me.requestFclt({ fcltTypeId: [params.fcltTypeId] })];
        }

        const resList = await Promise.all(pm);

        if (Array.isArray(resList)) {
          resList.forEach((res) => {
            resFcltList = [...resFcltList, ...res];
          });
        }

        me.fcltList = me.convertMakerData(resFcltList, fcltTypesInfo);
        // me.fcltList = resFcltList.map((fcltInfo) => {
        //   const fTypeInfo = fcltTypesInfo[fcltInfo.fcltTypeId] || {};
        //   const markerImage = {
        //     src: fTypeInfo.gisMarkerImage,
        //     size: { width: 36, height: 48 },
        //   };

        //   return {
        //     key: fcltInfo.fcltId,
        //     lat: fcltInfo.gisLat,
        //     lng: fcltInfo.gisLng,
        //     markerImage,
        //     event: {
        //       click: me.onClickMarker,
        //     },
        //     // infowindow: {
        //     //   iwContent: me[fcltTypesInfo[fcltInfo.fcltTypeId].label](fcltInfo),
        //     //   visibleIw: true,
        //     // },
        //     properties: fcltInfo,
        //   };
        // });
      } catch (err) {
        // me.$message.error('시설물 정보 설정 실패');
      }
    },

    convertMakerData(dataList = [], fcltTypesInfo) {
      const me = this;

      const resultObj = {};

      dataList.forEach((fcltInfo) => {
        const fTypeInfo = fcltTypesInfo[fcltInfo.fcltTypeId] || {};
        const markerImage = {
          src: fTypeInfo.gisMarkerImage,
          size: { width: 36, height: 48 },
        };

        let key = fcltInfo.fcltId;

        if (fTypeInfo.type === 'fclt') {
          const { fcltTypeData = {} } = fcltInfo;
          const { asset_id } = fcltTypeData;

          if (asset_id) {
            key = asset_id.value;
          }
        }

        if (resultObj[key]) {
          resultObj[key].properties.assetList.push(fcltInfo.fcltId);
        } else {
          resultObj[key] = {
            key,
            lat: fcltInfo.gisLat,
            lng: fcltInfo.gisLng,
            markerImage,
            event: {
              click: me.onClickMarker,
            },
            // infowindow: {
            //   iwContent: me[fcltTypesInfo[fcltInfo.fcltTypeId].label](fcltInfo),
            //   visibleIw: true,
            // },
            properties: {
              ...fcltInfo,
              assetList: [fcltInfo.fcltId],
              key,
            },
          };
        }
      });

      return Object.keys(resultObj).map((key) => resultObj[key]);
    },

    setIconLabel() {
      const me = this;
      try {
        const fcltTypesInfo = {};
        me.fcltTypeList.forEach((elm) => {
          const { fcltTypeId } = elm;
          fcltTypesInfo[fcltTypeId] = { ...elm };
        });

        me.fcltList = me.fcltList.map((fcltInfo) => {
          const { fcltId, fcltTypeId } = fcltInfo.properties;

          let iwContent = '';
          if (me[fcltTypesInfo[fcltTypeId].label]) {
            iwContent = me[fcltTypesInfo[fcltTypeId].label](fcltInfo);
          }

          return {
            ...fcltInfo,
            infowindow: {
              iwContent,
              visibleIw: true,
            },
          };
        });
      } catch (err) {
        // me.$message.error('라벨 표출 에러');
      }
    },

    onClickMarker(markerInfo, e) {
      // console.log(markerInfo.key);
      // this.selectListDetail(markerInfo.key);
      const overlapMarkerList = this.checkOverlapMarker(markerInfo);

      if (overlapMarkerList.length === 1) {
        this.selectListDetail(markerInfo.key);
      } else if (overlapMarkerList.length > 1) {
        this.openMarkerSelectBox(overlapMarkerList);
      }
    },

    checkOverlapMarker(markerInfo) {
      const me = this;
      try {
        const { gisLat, gisLng } = markerInfo;

        const overlapMarkerList = me.fcltList.filter((fcltInfo) => {
          const { lat, lng } = fcltInfo;
          return gisLat === lat && gisLng === lng;
        });
        return overlapMarkerList;
      } catch (err) {
        // me.$message.error('checkOverlapMarker Error');
        return [];
      }
    },

    openMarkerSelectBox(overlapMarkerList) {
      if (overlapMarkerList.length === 0) return;

      const key = overlapMarkerList[0].key;
      const geometry = {
        lat: overlapMarkerList[0].lat,
        lng: overlapMarkerList[0].lng,
      };

      this.selectBoxData = [
        {
          key,
          geometry,
          markerList: overlapMarkerList.map((elm) => ({
            ...elm,
            name: elm.properties.fcltName,
          })),
        },
      ];
    },

    selectMarker(args) {
      this.selectListDetail(args.key);
      this.selectBoxData = [];
    },

    getMarkerSelectLabel() {
      return (
        <div class="flooding-info" style="z-index: 100;px">
          <div class="flooding-box">
            <div>
              <strong>zzzz</strong>
            </div>
          </div>
        </div>
      );
    },

    getWaterlevelInfo(fcltInfo) {
      let status = '없음';
      let className = '';
      let waterLevel = '없음';

      const { fcltId, fcltTypeId, fcltTypeData = {} } = fcltInfo.properties || {};
      const { water_level_current, water_level_alert_status, level_value } = fcltTypeData;
      const { level1, level2, level3, level4 } = fcltTypeData;

      // const wlConfigTypes = this.waterLevelConfig[fcltTypeId] || {};
      // const { waterLevelList } = wlConfigTypes[fcltId] || {};

      // if (Array.isArray(waterLevelList) && typeof level_value !== 'undefined' && level_value !== '없음') {
      if (typeof level_value !== 'undefined' && level_value !== '없음') {
        waterLevel = level_value.value + 'cm';

        let idx = -1;
        if (level1.value > level_value.value) {
          idx = 0;
        } else if (level1.value >= level_value.value) {
          idx = 1;
        } else if (level2.value >= level_value.value) {
          idx = 2;
        } else if (level3.value >= level_value.value) {
          idx = 3;
        } else if (level4.value >= level_value.value) {
          idx = 4;
        }

        // let idx = waterLevelList.findIndex((elm) => {
        //   return level_value.value < elm;
        // });

        // idx = idx === -1 ? waterLevelList.length : idx;
        if (idx !== -1) {
          const { status: s, className: c } = this.waterLevelStatusInfo[idx];
          status = s;
          className = c;
        }
      }

      const properties = {};
      for (let key in fcltTypeData) {
        const dataObj = fcltTypeData[key];
        properties[key] = dataObj.value;
      }

      return {
        status,
        className,
        waterLevel,
        properties,
      };
    },

    getWaterlevelLabel(fcltInfo) {
      const { status, className, waterLevel } = this.getWaterlevelInfo(fcltInfo);

      return `<div class="flooding-info" style="z-index: 100;">
                <div class="flooding-box">
                  <div><strong>수위계</strong></div>
                  <div class="flooding-info-state-box stat-text-color">
                    <span>상태</span>
                    <span class="${className}">${status}</span> | 
                    <span>수위</span>
                    <span class="${className}">${waterLevel}</span>
                  </div>
                </div>
              </div>`;
    },

    getFloodsensorInfo(fcltInfo) {
      let status = '없음';
      let className = '';
      let updateTime = '';
      let updateName = '정보없음';

      const { fcltTypeData = {} } = fcltInfo.properties || {};
      const { flood_state } = fcltTypeData;
      if (typeof flood_state !== 'undefined') {
        const fInfo = this.floodStatusInfo[flood_state.value] || {};

        if (fInfo) {
          status = fInfo.status;
          className = fInfo.className;
          updateName = fInfo.updateName;
        }
      }

      const properties = {};
      for (let key in fcltTypeData) {
        const dataObj = fcltTypeData[key];
        properties[key] = dataObj.value;
      }

      if (properties.status_update_time) {
        updateTime = moment(properties.status_update_time).format('YYYY/MM/DD HH:mm:ss');
      }

      return {
        status,
        className,
        properties,
        updateName,
        updateTime,
      };
    },

    getFloodsensorLabel(fcltInfo) {
      const { status, className } = this.getFloodsensorInfo(fcltInfo);

      return `<div class="flooding-info" style="z-index: 100;">
                <div class="flooding-box">
                  <div><strong>침수센서</strong></div>
                  <div class="flooding-info-state-box stat-text-color">
                    상태
                    <span class="${className}">${status}</span>
                  </div>
                </div>
              </div>`;
    },

    getGateguardInfo(fcltInfo) {
      let status = '없음';
      let className = '';

      const { fcltTypeData = {} } = fcltInfo.properties || {};
      const { gate_status, gate_status_code } = fcltTypeData;
      if (typeof gate_status !== 'undefined') {
        const gInfo = this.gateguardStatusInfo[gate_status_code.value + ''];
        if (gInfo) {
          status = gInfo.status;
          className = gInfo.className;
        }
      }

      const properties = {};
      for (let key in fcltTypeData) {
        const dataObj = fcltTypeData[key];
        properties[key] = dataObj.value;
      }

      const statusDuration = Math.floor(properties.status_duration) / 60;

      return {
        status,
        className,
        statusDuration,
        properties,
      };
    },

    getGateguardLabel(fcltInfo) {
      const { status, className } = this.getGateguardInfo(fcltInfo);

      return `<div class="flooding-info" style="z-index: 100;">
                <div class="flooding-box">
                  <div><strong>차단기</strong></div>
                  <div class="flooding-info-state-box stat-text-color">
                    상태
                    <span class="${className}">${status}</span>
                  </div>
                </div>
              </div>`;
    },

    getFcltShowDataInfo(fcltInfo) {
      const { properties } = fcltInfo;

      return {
        fcltId: properties.fcltId,
        fcltName: properties.fcltName,
        gisLat: properties.gisLat,
        gisLng: properties.gisLng,
      };
    },

    getFcltLabel(fcltInfo) {
      const { fcltName } = this.getFcltShowDataInfo(fcltInfo);

      const len = fcltName.length;
      return `<div class="flooding-info" style="z-index: 100; width: ${len * 10}px">
                <div class="flooding-box">
                  <div><strong>CCTV</strong></div>
                  <div class="flooding-info-state-box stat-text-color">
                    <span>${fcltName}</span>
                  </div>
                </div>
              </div>`;
    },

    setRoadview() {
      const { fcltId, gisLat, gisLng } = this.floodginList[0];

      this.$refs.dkGis.setRoadview({ lat: gisLat, lng: gisLng });
    },

    selectNavigation() {
      this.isNaviSelect = true;
    },

    backgroundOff() {
      this.isNaviSelect = false;
    },

    checkMobileWeb() {
      return this.$isMobile && !window.modbileDeviceId;
    },

    kakaoNavi() {
      const { fcltName, gisLng, gisLat } = this.floodginList[0];

      if (!this.checkMobileWeb()) {
        window.JSBridge.kakaoNavi(fcltName, gisLng, gisLat);
      } else {
        if (Kakao) {
          Kakao.Navi.start({
            name: fcltName,
            x: gisLng,
            y: gisLat,
            coordType: 'wgs84',
          });
        }
      }
    },

    tmapNavi() {
      const { fcltName, gisLng, gisLat } = this.floodginList[0];

      window.JSBridge.tmapNavi(fcltName, gisLng, gisLat);
    },

    mapClickEvent() {
      // debugger;
      // this.showFcltList = [];
      this.floodginList = [];
      this.selectBoxData = [];
      // if (this.selectFcltMarkerData.fcltId) {
      //   this.clearFclt();
      // } else {
      //   this.isUIView = !this.isUIView;
      // }
      // this.selectBoxData = [];
    },
  },

  watch: {
    fcltTypeList(n, o) {
      const me = this;
      me.remainTime = me.intervalTime;

      me.replaceFclt();
      // (async () => {
      //   console.log('getFclt');
      //   await me.getFclt();
      //   me.setIconLabel();
      // })();
    },

    fcltList(n, o) {
      if (o.length > 0) {
        this.markerLayer.removeAllMarker();
      }

      this.markerLayer.addMarkerList(n);
    },

    floodginList(n, o) {
      if (n.length > 0) {
        this.openDetailData();
      } else {
        this.closeListDetail();
      }
    },
  },

  computed: {},
};
</script>
<style></style>
