<template>
  <div class="fill-width-height el-container">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>

          <el-col :span="24">
            <el-table :data="userAuthList" style="width: 100%;" size="small">
              <el-table-column label width="80" header-align="center" align="center" fixed="left">
                <template slot-scope="scope">
                  <span
                    class="user-list-thumb-img"
                    v-if="scope.row.images && scope.row.images.length > 0"
                    :style="
                      'background-image:url(' +
                      
                      scope.row.images[0].serverFilePath +
                      ')'
                    "
                  ></span>
                  <span
                    v-else
                    class="user-list-thumb-img"
                    style="background-image: url(static/images/unknown.png);"
                  ></span>
                </template>
              </el-table-column>
              <el-table-column
                prop="userid"
                label="아이디"
                width="120"
                header-align="center"
                align="center"
                sortable
              ></el-table-column>
              <el-table-column
                prop="name"
                label="이름"
                width="130"
                header-align="center"
                align="center"
                sortable
              ></el-table-column>
              <el-table-column
                prop="groupName"
                label="그룹"
                width="130"
                header-align="center"
                align="center"
                sortable
              ></el-table-column>
              <el-table-column
                prop="phone"
                label="전화번호"
                width="150"
                header-align="center"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="authority"
                label="모바일기기 인증정보"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <div
                    v-if="
                      scope.row.userLoginAuth[0].mobileDevice &&
                      scope.row.userLoginAuth[0].mobileDevice.accessid
                    "
                  >
                    <el-tag type="success">
                      인증완료
                      {{ scope.row.userLoginAuth[0].mobileDevice.model }}
                      <el-button
                        class="reset-approve-button"
                        plain
                        size="mini"
                        type="danger"
                        icon="el-icon-delete"
                        @click="resetMobileDevice(scope.row)"
                      ></el-button>
                    </el-tag>
                  </div>
                  <div v-else>
                    <el-tag type="danger">미인증</el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="authority"
                label="PC기기 인증정보"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <div
                    v-if="
                      scope.row.userLoginAuth[0].pcDevice &&
                      scope.row.userLoginAuth[0].pcDevice.macaddress
                    "
                  >
                    <el-tag type="success">
                      인증완료
                      {{ scope.row.userLoginAuth[0].pcDevice.macaddress }}
                      <el-button
                        class="reset-approve-button"
                        plain
                        size="mini"
                        type="danger"
                        icon="el-icon-delete"
                        @click="resetPcDevice(scope.row)"
                      ></el-button>
                    </el-tag>
                  </div>
                  <div v-else>
                    <el-tag type="danger">미인증</el-tag>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import dkeltableheader from '@/components/dkElTableHeader.vue';
import dkelpagination from '@/components/dkElPagination.vue';

export default {
  components: {
    'dk-el-pagination': dkelpagination,
    'dk-el-table-header': dkeltableheader,
  },
  data() {
    return {
      userAuthList: [],
    };
  },
  mounted() {},
  created() {
    this.getUserAuth(1);
  },
  methods: {
    async getUserAuth(pageNum) {
      let userAuthList = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/userAuth`);
      this.userAuthList = userAuthList.data.docs;
    },
    resetMobileDevice(args) {
      this.$confirm(`${args.name} 모바일 장비정보를 초기화 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          let result = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/userAuth/resetMovileDevice`, { data: args });
          if (result) {
            this.$message({
              type: 'success',
              message: `${args.name} 모바일 장비정보 초기화 완료`,
            });
            this.getUserAuth();
          } else {
            this.$message({
              type: 'danger',
              message: `${args.name} 모바일 장비정보 초기화 중 에러 발생`,
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: 'danger',
            message: `${args.name} 모바일 장비정보 초기화 취소`,
          });
        });
    },
    resetPcDevice(args) {
      this.$confirm(`${args.name} PC정보 초기화 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          let result = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/userAuth/resetPcDevice`, { data: args });
          if (result) {
            this.$message({
              type: 'success',
              message: `${args.name} PC정보 초기화 완료`,
            });
            this.getUserAuth();
          } else {
            this.$message({
              type: 'danger',
              message: `${args.name} PC정보 초기화 중 에러 발생`,
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: 'danger',
            message: `${args.name} PC정보 초기화 취소`,
          });
        });
    },
  },
  computed: {},
};
</script>

<style scoped>
.user-thumb-img {
  width: 100px;
  height: 100px;
  margin: 5px;
  display: inline-block;
  background-size: cover;
}

.user-list-thumb-img {
  width: 60px;
  height: 60px;
  margin: 0px;
  display: inline-block;
  background-size: cover;
}

.user-table th {
  text-align: right;
  padding-right: 20px;
  width: 100px;
}

.reset-approve-button {
  padding: 4px !important;
  font-size: 15px;
  margin-top: 2px;
  margin-left: 6px;
}
</style>
