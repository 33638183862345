<template>
    <div class="vms-status" style="background-color:#0eafdd; width:100%; height:100%; color:#fff;">
        <div class="icon-wrap" style="float:left; display:flex; flex-direction: column; justify-content: center; height:100%;">
            <img src="static/images/vmsicon.png">
        </div>
        <div class="status-text" style="float:left; display:flex; flex-direction: column; justify-content: center; height:100%;">
            <h4>VMS 상태정보</h4>
            <el-row>
              <el-col :md="24" :lg="24" :xl="12">
                <span>설치대수</span><span class="status-count">{{totalFclt}}</span>
              </el-col>
              <el-col :md="24" :lg="24" :xl="12">
                <span>통신이상</span><span class="status-count">{{error}}</span>
              </el-col>
            </el-row>
        </div>                
    </div>
</template>
<script>
import statusWidget from "@/traffic/widget/statusWidget.js";
export default statusWidget({fcltTypeId:'FCLTTYPE10001'});
// export default {
//     created() {
//         statusWidget.tTtTt();
//     },
//     data() {
//         return {
//             totalFclt: 0,
//             error: 0
//         }
//     },
// }
</script>
<style scoped>
.vms-status{
    background-color:#0eafdd;    
    width:100%;
    height:100%;
    color:#fff;
}

.vms-status > div {
    float:left;
    display:flex;
    flex-direction: column;
    justify-content: center;
    height:100%;
}

</style>