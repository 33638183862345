<template>
  <el-dialog id="formAssetDialog" :title="formTitle" :visible.sync="formDialog" @close="hideDialog" :close-on-click-modal="false">
    <dk-form ref="dkTemplateForm" formType="input" @renderAfter="createTemplateForm"></dk-form>
    <div class="dk-form">
      <el-form label-position="left" label-width="165px" size="small">
        <el-form-item label="관련 자료">
          <dk-el-file-upload ref="uploadFile" path="asset" :fileList="formData.files" :multiple="true">
            <div style="text-align: left;">
              <el-button size="small" type="primary">관련자료 업로드</el-button>
              <div slot="tip" class="el-upload__tip">자산 정보에 관련된 자료를 업로드 할 수 있습니다.</div>
            </div>
          </dk-el-file-upload>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">저장</el-button>
      <el-button @click="formDialog = false">닫기</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _ from 'lodash';
import dkTemplateForm from '@/components/dkTemplate/dkTemplateForm';
import dkElFileUpload from '@/components/dkElFileUpload.vue';
import dkTemplateUtil from '@/components/dkTemplate/dkTemplateUtil';
export default {
  components: {
    'dk-form': dkTemplateForm,
    'dk-el-file-upload': dkElFileUpload,
  },
  data() {
    return {
      formDialog: false,
      formTitle: '자산 정보 등록',
      formData: {
        assetId: '',
        assetName: '',
        templateId: '',
        templateName: '',
        templateData: [],
        files: [],
      },
      systemLogInfo: {},
      updateFlag: false,
      rules: {},
    };
  },
  methods: {
    hideDialog() {
      this.formData = {
        assetId: '',
        assetName: '',
        templateId: '',
        templateName: '',
        templateData: [],
        files: [],
      };
      this.formDialog = false;
      this.$refs.dkTemplateForm.resetForm();
      this.$emit('registerdAsset');
    },
    showDialog(valueData, template) {
      this.formDialog = true;
      this.updateFlag = false;
      this.templateData = _.cloneDeep(template.templateData);
      this.formData.templateId = template.templateId;
      this.formData.templateName = template.templateName;
      if (valueData) {
        this.formData._id = valueData._id;
        this.formData.assetId = valueData.assetId;
        this.formData.assetName = valueData.assetName || '';
        this.formData.files = valueData.files;
        this.updateFlag = true;
        this.templateData = dkTemplateUtil.setFetcedTemplateValue({
          template: this.templateData,
          value: valueData,
        });
      }
      this.createTemplateForm();
    },
    createTemplateForm() {
      if (this.$refs.dkTemplateForm) {
        this.$refs.dkTemplateForm.createTemplateForm(this.templateData);
      }
    },

    async save() {
      try {
        // this.$refs.form0.validate(async (valid) => {
        // if (valid) {
        let saveFunc = 'asset/add';
        if (this.updateFlag) {
          saveFunc = 'asset/update';
        }
        this.systemLogInfo.page = `${this.$route.name} ${this.formData.templateName}`;
        this.systemLogInfo.saveType = saveFunc;
        this.formData.templateData = this.$refs.dkTemplateForm.exportTemplateData();
        this.formData.files = this.$refs.uploadFile.getFileData();
        await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, { data: this.formData });
        this.hideDialog();
        this.$message({
          type: 'success',
          message: `자산 정보 등록 완료`,
        });
        this.$emit('registeredAssetData');
        await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
        // }
        // });
      } catch (err) {
        console.error(err);
        this.hideDialog();
        this.$message.error(`자산 정보 등록 중 에러 발생`);
      }
    },
  },
};
</script>
