import dkelpagination from '@/components/dkElPagination.vue'
import moment from 'moment'
import dkEnfImage from '@/components/dkEnfImage.vue'
export default {
  name: 'enfList',
  components: {
    'dk-enf-image': dkEnfImage,
    'dk-el-pagination': dkelpagination
  },
  data() {
    return {
      selected:[],
      cameraInfo: [],
      // regionInfo: this.$dkEnfCodeFormatter.getCodeValue("REGION"),
      // zoneTypeInfo: this.$dkEnfCodeFormatter.getCodeValue("ENF_ZONE_TYPE"),
      // carTypeInfo: this.$dkEnfCodeFormatter.getCodeValue("CAR_TYPE"),
      // detectProgressInfo: this.$dkEnfCodeFormatter.getCodeValue("ENF_PROGRESS"),
      detectProgressInfo: {
        "enforceFirst": {
            "data": "1",
            "codeDataKoName": "초도단속",
            "use": true
        },
        "enforceSeconde": {
            "data": "2",
            "codeDataKoName": "계도단속",
            "use": true
        },
        "enforceDecide": {
            "data": "3",
            "codeDataKoName": "확정단속",
            "use": true
        },        
        "enforceComplete": {
            "data": "101",
            "codeDataKoName": "대사완료",
            "use": true
        }
     },
      enfDataLimit:10,
      enfDataTotalDocs: 0,
      searchAllDataFlag: true,
      searchEnfData: {                // 단속정보 검색 조건
        cameraId: "",
        detectProgress: "3",
        // enfStartDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
        enfStartDate: moment().format("YYYY-MM-DD"),
        enfStartDateMenu: false,
        enfStartTime: "00:00",
        enfStartTimeMenu: false,
        enfEndDate: moment().format("YYYY-MM-DD"),
        enfEndDateMenu: false,
        enfEndTime: "23:59",
        enfEndTimeMenu: false,
      },
      enfDataCursor: -1,               // 현재 선택 중인 단속정보 위치 cursor
      enfDetailDialog: false,           // 단속 상세 정보 다이얼로그
      isCtrled: false,                   // ctrl키 누른 상태 확인      
      isCarPlateModify: true,           // 번호판 수정 중 여부
      isImagePixelate: false,           // 모자이크 작업 중 여부
      isSnackbarDisplay: false,         // 스낵바 (단순상테 메시지) 표출 여부
      snackbarMsg: "",                  // 스낵바 메시지
      // detectDataHeader: [               // 단속정보 grid header
      //   {
      //     text: '단속지점',
      //     align: 'left',
      //     sortable: false,
      //     value: 'cameraPlaceName'
      //   },
      //   {
      //     text: '단속일시',
      //     value: 'detectTime',
      //     sortable: false
      //   },
      //   {
      //     text: '차량번호',
      //     value: 'carNum',
      //     sortable: false
      //   }
      // ],
      detectData: [],                  // 단속정보
      pagingDetectData: [],      
      selectEnfData: {                  // 단속 상세정보
        carNum: "",        
      },
      selectEnfDataFirstImage: [],
      selectEnfDataConfirmImage: [],
      isMosaicProc: false,
      isMosaicProcMsg: "모자이크 처리 중입니다"
    }
  },
  created() {
    moment.locale('ko');
    this.$http.post(`${this.$config.getServerConfig().enf.api.detect}/ipes/apiServer/camera/db/getCameraInfo`, {      
    })
      .then(response => {
        var list = [];                
        response.data.body.forEach(items => {
          list.push({
            cameraName: items.cameraName,
            cameraId: items.cameraId
          })
        });
        console.log(list)
        this.cameraInfo = list
      })
      .catch(e => {
        me.errors.push(e)
      })
  },
  mounted() {
    window.addEventListener('keyup', this.keyUpEvents)
    window.addEventListener('keydown', this.keyDownEvents)
    //this.getEnfData()
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.keyUpEvents)
    window.removeEventListener('keydown', this.keyDownEvents)
  },
  computed: {
    computedEnfStartDate() {
      return this.searchEnfData.enfStartDate
    },
    computedEnfEndDate() {
      return this.searchEnfData.enfEndDate
    },
    pages() {
      if (this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) return 0
      return Math.ceil(parseInt(this.pagination.totalItems) / this.pagination.rowsPerPage)
    }
  },
  methods: {
    onResize() {
      var height = window.innerHeight
      this.$refs.enfListWrap.style.height = (height - 576) + "px"
      this.$refs.detectImgWrap.style.height = (height - 400) + "px"
    },
    enfDataPagingProc(pageNum) {
      this.getEnfData('all');
    },
    getEnfData(searchFlag) {
      var _this = this
      let startDateTime, endDateTime = ""
      let param = {}
      if (searchFlag == 'all') {
        this.searchAllDataFlag = true
      }
      else if (searchFlag == 'one'){
        this.searchAllDataFlag = false
      }

      // 단속자료 조회 시작일
      if (this.searchEnfData.enfStartDate) {
        startDateTime = this.searchEnfData.enfStartDate.replace(/-/gi, "")
      }
      if (this.searchEnfData.enfStartTime) {
        startDateTime += this.searchEnfData.enfStartTime.replace(/:/gi, "")
      }

      // 단속자료 조회 종료일
      if (this.searchEnfData.enfEndDate) {
        endDateTime = this.searchEnfData.enfEndDate.replace(/-/gi, "")
      }
      if (this.searchEnfData.enfEndTime) {
        endDateTime += this.searchEnfData.enfEndTime.replace(/:/gi, "")
      }

      //param['startDateTime'] = startDateTime + "00"
      //param['endDateTime'] = endDateTime + "00"      

      if (this.searchEnfData.cameraId != "" && !this.searchAllDataFlag) {        
        param['cameraId'] = this.searchEnfData.cameraId
      } 
      if (this.searchEnfData.detectCarnum != "") {
        // param['detectCarnum'] = this.searchEnfData.detectCarnum
        param['carNum'] = this.searchEnfData.detectCarnum
      }
      //param['detectProgress'] = this.searchEnfData.detectProgress
      //param['detectProgressCode'] = '1'
      this.$http.post(`${this.$config.getServerConfig().enf.api.detect}/ipes/apiServer/detect/detectData/getDetectData`, param)
        .then((response) => {          
          let list = []          
          _this.clearSelectEnfData()          
          response.data.body.forEach((data) => {
            let camera = this.cameraInfo.find((c) => {
              return c.cameraId == data.cameraId
            })            
            data.cameraName = camera.cameraName
            data.selected = false                        
            if (data.detectProgressCode == "1" || data.detectProgressCode =="3") {
              list.push(data)
            }
          })          
          _this.detectData = list
          _this.enfDataTotalDocs = list.length
          _this.$refs.enfDataPagination.setPage(1)
          _this.pagingProc(1)
          _this.selected = []
          _this.$forceUpdate()
        })
    },
    pagingProc(pageNum) {
      let startIdx = (pageNum - 1) * this.enfDataLimit;
      if (startIdx > this.enfDataTotalDocs) {
        return;
      }
      let endIdx = pageNum * this.enfDataLimit;
      if (endIdx > this.enfDataTotalDocs) {
        endIdx = this.enfDataTotalDocs;
      }
      this.pagingDetectData = [];
      for (var i = startIdx; i < endIdx; i++) {
        this.pagingDetectData.push(this.detectData[i])
      }
    },
    selectEnfDataProc(row) {      
      this.selectEnfDataFirstImage = []
      this.selectEnfDataConfirmImage = [] 
      this.selectEnfData = row
      this.selectEnfData.progressImageInfo.forEach((images)=>{
        if (images.imageDetectProgressCode=="1") {
          this.selectEnfDataFirstImage = images
        }
        else if (images.imageDetectProgressCode=="3") {
          this.selectEnfDataConfirmImage = images
        }
      });      
    },
    getDetectImageStepText(detectImageObj) {
      if (detectImageObj.imageIndex == "0") {
        return "번호판"
      }
      else if (detectImageObj.imageIndex == "1") {
        return "차량"
      }
      else if (detectImageObj.imageIndex == "2") {
        return "전경"
      }
    },
    getDetectProgressCode(progressCode) {      
      for (var key in this.detectProgressInfo) {
        if (this.detectProgressInfo[key].data == progressCode) {
          return this.detectProgressInfo[key].codeDataKoName          
        }
      }
    },
    getDetectTimeSpend(detectData) {      
      let st, diff;      
      let et = null
      for (var key in detectData.progressImageInfo) {
        if (detectData.progressImageInfo[key].imageDetectProgressCode == "3") {
          et = moment(detectData.progressImageInfo[key].imageTime)
          break;
        }
      }
      st = moment(detectData.firstDetectTime)
      if (et) {
        diff = moment.duration(et.diff(st))
        return `${st.format("LTS")} ~ ${et.format("LTS")} <br> (${diff.minutes()}분${diff.seconds()}초)`
      }
      else {
        return `${st.format("LTS")}`
      }

      
    },
    getDateFormat(timestamp) {
      return moment(timestamp).format("YYYY-MM-DD HH:mm:ss")
    },
    getDetectTimeDiff() {
      if ((!this.selectEnfData.detectImg) || this.selectEnfData.detectImg.length <= 0) {
        return ""
      }

      let startImg = this.selectEnfData.detectImg[0]
      let endImg = this.selectEnfData.detectImg[this.selectEnfData.detectImg.length - 1]
      let startTime = moment(startImg.time, "YYYYMMDDHHmmss")
      let endTime = moment(endImg.time, "YYYYMMDDHHmmss")
      let duration = moment(moment(endTime).diff(startTime))
      return duration.format("mm분 ss초")
    },
    keyUpEvents(e) {
      switch (e.keyCode) {
        // 다음 단속 데이터
        case 39:
        case 40:
          if (!this.isCarPlateModify) {
            return
          } 
          // if (this.enfDataCursor + 2 <= this.detectData.length) {
          if ((this.pagination.rowsPerPage * (this.pagination.page - 1)) + this.enfDataCursor + 1 < this.detectData.length) {
          // if ((this.pagination.rowsPerPage * this.nowPage) + this.enfDataCursor + 1 < this.detectData.length) {
          // if (idx < this.detectData.length) {
            // let idx = this.enfDataCursor + 1
            let idx = (this.pagination.rowsPerPage * (this.pagination.page - 1)) + this.enfDataCursor + 1
            //let idx = (this.pagination.rowsPerPage * this.nowPage) + this.enfDataCursor + 1

            if (this.detectData.length < idx -1) {
              return
            }
            // this.selectEnfDataProc(this.detectData[idx], idx)
            if (((this.enfDataCursor + 1) % this.pagination.rowsPerPage == 0) && this.enfDataCursor >= 0 && idx != 0) {
              this.nowPage += 1;
              this.$refs.enfDataPaging.next(e)
            }
            this.selectEnfDataProc(this.detectData[idx], idx)
          }
          break;

        // 이전 단속 데이터
        case 37:
        case 38:
          if (!this.isCarPlateModify) {
            return
          }
          // let idx = (this.pagination.rowsPerPage * (this.pagination.page - 1)) + this.enfDataCursor - 1
          // if (this.enfDataCursor > 0) {
          if ((this.pagination.rowsPerPage * (this.pagination.page - 1)) + this.enfDataCursor > 0) {
          // if ((this.pagination.rowsPerPage * this.nowPage) + this.enfDataCursor > 0) {
            // let idx = this.enfDataCursor - 1
            let idx = (this.pagination.rowsPerPage * (this.pagination.page - 1)) + this.enfDataCursor - 1
            //let idx = (this.pagination.rowsPerPage * this.nowPage) + this.enfDataCursor - 1
            // this.selectEnfDataProc(this.detectData[idx], idx)
            // if (((this.enfDataCursor + 1) % this.pagination.rowsPerPage == (this.pagination.rowsPerPage - 1))) {
            if (idx % this.pagination.rowsPerPage == this.pagination.rowsPerPage - 1) {
              this.nowPage -= 1;
              this.$refs.enfDataPaging.previous(e)
            }            
            // this.selectEnfDataProc(this.detectData[idx], idx % this.pagination.rowsPerPage)
            this.selectEnfDataProc(this.detectData[idx], idx)
          }
          break;

        // 단속 데이터 대사 처리
        case 13:
          // 선택된 대사정보가 없을시 안띄움
          if (!this.selectEnfData.detectId) {
            return
          }
          // 모자이크 편집중이면 안띄움
          if (this.isImagePixelate) {
            return
          }
          // 번호판 수정중 엔터쳤을 때
          if (!this.isCarPlateModify) {
            this.isCarPlateModify = true
            return
          }

          // 상세정보 다이얼로그 안떠있을 때
          if (!this.enfDetailDialog) {
            this.enfDetailDialog = true
          }
          // 상세정보 다이얼로그 떠잇을 시, 대사처리 완료
          else {
            this.confirmDetectData("1")
            this.enfDetailDialog = false
          }
          break;

        // 단속 데이터 취소/반려
        case 46:
          if (this.enfDetailDialog) {
            this.confirmDetectData("2")
            this.enfDetailDialog = false
          }
          break;

        // 번호판 정보 수정
        case 32:
          if (this.isCtrled) {
            this.isCarPlateModify = false
            this.$refs.carPlateNum.focus(e)
            return
          }
          if (this.enfDataCursor > -1) {   
            let idx = this.enfDataCursor + ((this.pagination.page -1) * this.pagination.rowsPerPage)
            let detectId = this.detectData[idx].detectId
            if (!this.$refs.detectDataGrid.selected[detectId]) {
              this.$refs.detectDataGrid.selected[detectId] = true
              this.selected.push(this.detectData[idx])
            }
            else {              
              let deleteidx = 0
              for (var i=0; i<this.selected.length; i++) {
                deleteidx = i                
                if (this.selected[i].detectId == detectId) {
                  break
                }                
              }
              this.selected.splice(deleteidx, 1)              
              delete this.$refs.detectDataGrid.selected[detectId]              
            }
            this.$forceUpdate()            
          }
          break;

        // 컨트롤 버튼 믹스
        case 17:
          this.isCtrled = false
          break;

        case 27:
          if (this.enfDetailDialog) {
            this.enfDetailDialog = false
          }
          break;
      }
    },
    keyDownEvents(e) {
      switch (e.keyCode) {
        case 17:
          this.isCtrled = true
          break;
      }
    },
    confirmDetectData(flag) {
      let _this = this
      let params = Object.assign({}, _this.selectEnfData)
      let confirmStatus = (flag === "1") ? "대사 처리" : "대사 취소"
      params.confirmType = flag
      params.user = {
        "id": "",
        "token": ""
      }
      _this.$http.post(`${this.$config.getServerConfig().enf.api.detect}/detect/detectData/confirmDetectData`, params)
        .then((response) => {
          _this.snackbarMsg = `[${params.carNum}] 차량 단속정보가 ${confirmStatus} 되었습니다.`
          _this.isSnackbarDisplay = true
          _this.enfDetailDialog = false
          _this.getEnfData()          
        })
        .catch(() => {

        })
    },
    setMosaic(flag) {
      let _this = this
      _this.isImagePixelate = flag
      if (!flag) {
        if (confirm("이 모자이크 설정 정보를 적용하시겠습니까?")) {
          // _this.isMosaicProcMsg = "모자이크 처리 중입니다"
          // _this.isMosaicProc = true
          let idx = 0
          let prm = Promise.resolve();
          _this.$refs.detectImg.forEach((obj, i) => {
            let imgData = obj.getImgData()
            if (obj.getRectInfo().length <= 0) {
              return
            }
            let imgNum = i + 1;
            prm = prm.then(()=>{
              _this.isMosaicProcMsg = `${imgNum}번 사진 모자이크 처리 중입니다`
              _this.isMosaicProc = true
              return new Promise((resolve, reject) => {
                let param = {
                  "user": {
                    "id": "",
                    "token": ""
                  },
                  "editType": 2,
                  "detectId": _this.selectEnfData.detectId,
                  "detectImgProgress": imgData.detectImgProgress,
                  "detectImgIndex": imgData.detectImgIndex,
                  "pixelSize": 30,
                  "mosaic": obj.getRectInfo()
                }
                obj.clearRect()

                _this.$http.post(`${this.$config.getServerConfig().enf.api.detect}/detect/detectData/imgMosaic`, param)
                  .then((response) => {
                    return resolve(response.data)
                  })
              })
            })
            .then((data) => {
              if (!data) {
                // _this.isMosaicProcMsg = "모자이크 처리 실패하였습니다."
                // setTimeout(function () {
                //   _this.isMosaicProc = false
                // }, 1000)
                return Promise.reject(`${imgNum}번 사진 모자이크 처리 실패하였습니다.`);
              }
              imgData.file = data.fileName
              obj.imgLoad(`${this.$config.getServerConfig().enf.api.snapshot}${data.fileName}`)
              _this.isMosaicProc = false
              return data
            })
            .catch((err)=>{
              _this.isMosaicProcMsg = `${err}`;
                setTimeout(function () {
                  _this.isMosaicProc = false
                }, 1000)
                return
            })
          })

        }
      }
      else {

      }
    },
    mosaicRestore() {
      if (confirm("모든 단속사진의 모자이크를 복구합니다. 진행 하시겠습니까?")) {
        this.isImagePixelate = false
        this.$refs.detectImg.forEach((obj) => {
          obj.clearRect()
          obj.mosaicRestoreNoConfirm()
        })
      }
    },
    clearRect() {
      this.isImagePixelate = false
      this.$refs.detectImg.forEach((obj) => {
        obj.clearRect()
      })
    },
    confirmAllDetectData() {
      let _this = this
      let complete = []
      if (confirm("선택한 모든 단속정보를 대사처리 합니다. 진행 하시겠습니까?")) {
        let idx = 0
        console.log(_this.selected)
        _this.selected.forEach((data) => {          
          complete[idx] = new Promise((resolve, reject) => {
            let params = Object.assign({}, data)            
            params.confirmType = "1"
            params.user = {
              "id": "",
              "token": ""
            }
            _this.$http.post(`${this.$config.getServerConfig().enf.api.detect}/detect/detectData/confirmDetectData`, params)
            .then((response) => {
              return resolve()
            })
          })
          idx++
        })

        
        // Promise.all(complete)
        let prm = Promise.resolve();
        complete.forEach((val)=>{
          prm = prm.then(()=>{
            return val;
          })
        })
        
        prm.then(()=> {
          _this.snackbarMsg = `${complete.length}건의 단속정보가 일괄 대사 처리 되었습니다.`
          _this.isSnackbarDisplay = true
          _this.enfDetailDialog = false
          _this.getEnfData()          
        })
      }
    },
    
    deleteAllDetectData() {      
      let _this = this
      let complete = []
      if (confirm("선택한 모든 단속정보를 삭제 합니다. 진행 하시겠습니까?")) {
        let idx = 0
        console.log(_this.selected)
        _this.selected.forEach((data) => {          
          complete[idx] = new Promise((resolve, reject) => {
            let params = Object.assign({}, data)            
            params.confirmType = "2"
            params.user = {
              "id": "",
              "token": ""
            }
            _this.$http.post(`${this.$config.getServerConfig().enf.api.detect}/detect/detectData/confirmDetectData`, params)
            .then((response) => {
              return resolve()
            })
          })
          idx++
        })

        Promise.all(complete)
        .then(()=> {
          _this.snackbarMsg = `${complete.length}건의 단속정보가 일괄 대사 처리 되었습니다.`
          _this.isSnackbarDisplay = true
          _this.enfDetailDialog = false
          _this.getEnfData()          
        })
      }
    },

    clearSelectEnfData() {
      this.enfDataCursor = -1
      this.selectEnfData = {
        carNum: "",
        detectImg: [
        ]
      }
    },
    toggleEnfData(props) {      
      let flag = !props.selected
      props.selected = flag
      props.item.selected = flag
    },
    toggleAllEnfData() {
      if (this.selected.length) this.selected = []
      else this.selected = this.detectData.slice()
    }
  }
}