<template>
  <GChart
    v-show="dataList.length !== 0"
    :settings="{ packages: ['bar'] }"
    :createChart="(el, google) => new google.charts.Bar(el)"
    :data="chartData"
    :options="chartOptions"
  />
  <!-- <GChart
    v-show="dataList.length !== 0"
    type="ColumnChart"
    :createChart="(el, google) => new google.charts.Bar(el)"
    :data="chartData"
    :options="chartOptions"
  /> -->
</template>
<script>
import { GChart } from 'vue-google-charts';
export default {
  name: 'App',
  components: {
    GChart,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    chartHeight: {
      type: String,
      default: '300',
    },
    chartWidth: {
      type: String,
      default: '300',
    },
    xTitle: {
      type: String,
      default: ' ',
    },
    columnNameList: {
      type: Array,
      required: true,
    },
    columnColorList: {
      type: Array,
      required: true,
    },
    dataList: {
      type: Array,
      default: [],
    },
  },
  data: () => ({
    chartData: [
      // ['Year', '112긴급출동', '119긴급출동', '사회약자지원','긴급재난'],
      // ['2014', 10, 4, 2, 5],
      // ['2015', 11, 4, 2, 5],
      // ['2016', 6, 12, 3, 5],
      // ['2017', 10, 5, 3, 5],
      // [' ', ''],
      // ['2014', 10],
      // ['2015', 11],
      // ['2016', 6],
      // ['2017', 10],
      // ['2015', 11],
      // ['2016', 6],
    ],
    chartOptions: {
      chart: {
        // title: '',
        // subtitle: ''
      },
      //   bars: 'horizontal', // Required for Material Bar Charts.
      hAxis: { format: 'decimal' },
      height: 250,
      //   width: 622 - 210,
      //   width: 622 - 150,
      // colors: ['#1b9e77', '#d95f02', '#7570b3', '#0489B1'],
      colors: ['#437ce5'],
      legend: { position: 'none' },
    },
  }),
  created() {
    const chartOptions = {
      chart: {},
    };

    chartOptions.chart.title = this.title;
    chartOptions.chart.subtitle = this.subtitle;
    chartOptions.colors = [...this.columnColorList];

    this.chartOptions = {
      ...this.chartOptions,
      ...chartOptions,
    };

    this.setchartData();
  },
  mounted() {},
  methods: {
    setchartData() {
      const chartData = [[this.xTitle, ...this.columnNameList]];

      this.dataList.forEach((data) => {
        const { key, valueList } = data;
        chartData.push([key, ...valueList]);
      });

      this.chartData = chartData;
    },
  },
  watch: {
    dataList(n, o) {
      this.setchartData();
    },
  },
};
</script>
