<template>
  <div class="fill-width-height el-container">
    <el-container class="gis-wrap">
      <el-container>
        <el-main>
          <dk-gis
            fill-height
            :gisApiInfo="gisApiInfo"
            :apiLoadComplete="apiLoadComplete"
            :trafficLayerData="trafficLayerData"
            :selectTrafficLayer="selectTrafficLayer"
            ref="dkGis"
          ></dk-gis>
        </el-main>
      </el-container>
      <el-aside width="640px" style="position: relative;">
        <div class="info-panel-wrap">
          <div class="dk-card">
            <div class="dk-card-title">정보 제공 구간 목록</div>
            <div>
              <el-row :gutter="4">
                <el-col :span="12">
                  <el-input v-model="searchParam.ifsc_id" size="mini" style="width: 100%;" placeholder="정보제공구간ID"></el-input>
                </el-col>
                <el-col :span="12">
                  <el-input v-model="searchParam.ifsc_nm" size="mini" style="width: 100%;" placeholder="정보제공구간명"></el-input>
                </el-col>
                <el-col :span="12">
                  <el-select v-model="searchParam.extr_cnct_sect_yn" size="mini" style="width: 100%;" placeholder="외부연계여부">
                    <el-option label="전체" value></el-option>
                    <el-option label="외부연계" value="Y"></el-option>
                    <el-option label="외부연계 아님" value="N"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="7">
                  <el-select v-model="searchParam.drct_cd" size="mini" style="width: 100%;" placeholder="방향">
                    <el-option label="상행" value="1"></el-option>
                    <el-option label="하행" value="2"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="5">
                  <el-button size="mini" style="width: 100%;" @click="getIfscInfo(1)" type="primary">조회</el-button>
                </el-col>
              </el-row>
            </div>
            <div>
              <el-table :data="ifscInfo" empty-text="등록된 정보제공 구간이 없습니다." size="mini" @cell-click="selectIfscRow" height="300" stripe>
                <el-table-column label="정보제공구간ID" prop="ifsc_id"></el-table-column>
                <el-table-column label="정보제공구간명" prop="ifsc_nm"></el-table-column>
                <el-table-column label="방향" width="60">
                  <template slot-scope="scope">
                    <span v-if="scope.row.drct_cd == '1'">상행</span>
                    <span v-else>하행</span>
                  </template>
                </el-table-column>
                <el-table-column label="시작구간명" prop="strt_nm"></el-table-column>
                <el-table-column label="종료구간명" prop="end_nm"></el-table-column>
              </el-table>
            </div>
            <el-row>
              <el-col :span="16">
                <h5 style=" color: #5a5a5a; margin: 5px 15px;">총 구간 수 : {{ ifscInfo.length }}</h5>
              </el-col>
              <el-col :span="8" style=" padding-right: 20px; text-align: right;">
                <el-button size="small" type="primary" @click="addIfscInfo">추가</el-button>
              </el-col>
            </el-row>
          </div>
          <div class="dk-card" v-show="this.selectIfscInfo.ifsc_id !== ''">
            <div>
              <table class="dk-table">
                <colgroup>
                  <col style="width: 25%;" />
                  <col style="width: 30%;" />
                  <col style="width: 15%;" />
                  <col style="width: 30%;" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scople="row">정보제공구간ID</th>
                    <th scople="row">정보제공구간명</th>
                    <th scople="row">방향</th>
                    <td rowspan="2">
                      <el-button @click="updateIfscInfo" size="small">수정</el-button>
                      <el-button @click="removeIfscInfo" size="small" type="danger">삭제</el-button>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ this.selectIfscInfo.ifsc_id }}</td>
                    <td>{{ this.selectIfscInfo.ifsc_nm }}</td>
                    <td>
                      <span v-if="this.selectIfscInfo.drct_cd == '1'">상행</span>
                      <span v-else>하행</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <el-table :data="ifscLinkRltn" empty-text="등록된 정보제공 구간이 없습니다." size="mini" height="300" stripe>
              <el-table-column label prop="ord" width="40"></el-table-column>
              <el-table-column label="링크ID" prop="link_id" width="110"></el-table-column>
              <el-table-column label="링크명" prop="road_name"></el-table-column>
              <el-table-column label="시작노드명" prop="f_node_name"></el-table-column>
              <el-table-column label="종료노드명" prop="t_node_name"></el-table-column>
            </el-table>
          </div>
        </div>
        <!-- <div v-else  class="info-panel-wrap">
                    <div class="dk-card">
                        <div class="dk-card-title">
                            정보 제공 구간
                        </div>
                        <el-form :model="selectIfscInfo" label-width="120px" size="medium" :rules="rules" ref="form0" :label-position="$isMobile?'top':'right'">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="정보제공구간ID" prop="ifsc_id">
                                    <el-input v-model="selectIfscInfo.ifsc_id" placeholder="정보제공구간ID" disabled></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="정보제공구간명" prop="ifsc_nm">
                                    <el-input v-model="selectIfscInfo.ifsc_nm" placeholder="정보제공구간명"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="방향" prop="drct_cd">
                                    <el-select 
                                        v-model="selectIfscInfo.drct_cd"
                                        placeholder="방향"
                                    >
                                        <el-option label="상행" value="1"></el-option>
                                        <el-option label="하행" value="2"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="외부연계여부" prop="extr_cnct_sect_yn">
                                    <el-select 
                                        v-model="selectIfscInfo.extr_cnct_sect_yn"
                                        placeholder="외부연계여부"
                                    >
                                        <el-option label="예" value="Y"></el-option>
                                        <el-option label="아니오" value="N"></el-option>                                        
                                    </el-select>
                                </el-form-item>
                            </el-col>                            
                        </el-row>
                        </el-form>
                    </div>
                    <div class="dk-card">
                        <div class="dk-card-title">
                            해당 정보 구간 링크 목록
                        </div>
                        <el-table
                            :data="editIfscLinkRltn"                            
                            empty-text="등록된 정보제공 구간이 없습니다."
                            size="mini"                            
                            height="500"
                            stripe
                        >   
                            <el-table-column
                                label=""
                                prop="ord"
                                width="40"
                            >
                            </el-table-column>
                            <el-table-column
                                label="링크ID"
                                prop="link_id"
                                width="110"
                            >                            
                            </el-table-column>                                                             
                            <el-table-column
                                label="링크명"
                                prop="road_name"                               
                            >    
                            </el-table-column>                            
                            <el-table-column
                                label="시작노드명"
                                prop="f_node_name"
                            >
                            </el-table-column>
                            <el-table-column
                                label="종료노드명"
                                prop="t_node_name"
                            >
                            </el-table-column>
                        </el-table>
                    </div>
                     <el-button
                        @click="saveIfscData"
                        type="primary"
                    >저장</el-button>
                    <el-button
                        @click="cancleIfscEdit"
                    >취소</el-button>                    
        </div>-->
      </el-aside>
    </el-container>
    <form-ifsc-manage ref="formIfscManage" :gisApiInfo="gisApiInfo" @procDataComplete="procDataComplete"></form-ifsc-manage>
  </div>
</template>

<script>
import dkgismain from '@/components/dkGis/dkGisMain.vue';
import dkelpagination from '@/components/dkElPagination.vue';
import formIfscManage from '@/traffic/form/formIfscManage.vue';
export default {
  components: {
    'dk-gis': dkgismain,
    'dk-el-pagination': dkelpagination,
    'form-ifsc-manage': formIfscManage,
  },
  created() {
    this.trafficLayerData = {
      base: [
        {
          name: 'ifsc_manage',
          url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
          params: { LAYERS: 'cite:gimpo_ifsc_offset', TILED: true },
          default: true,
          idKey: 'ifsc_id',
        },
      ],
      select: {
        source: {
          url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
        },
        mode: 'Single',
      },
    };
    this.gisApiInfo = this.$store.getters['gis/getGisApi'];

    this.getIfscInfo(1);
  },
  mounted() {
    this.$nextTick(() => {
      let api = this.gisApiInfo.find((apiInfo) => {
        return apiInfo.initGis;
      });
      if (api.gisName) {
        this.$refs.dkGis.gisModuleLoad(api.gisName);
      }
    });
  },
  data() {
    return {
      limit: 8,
      totalDocs: 0,
      gisApiInfo: [],
      searchParam: {
        ifsc_id: '',
        ifsc_nm: '',
        extr_cnct_sect_yn: '',
        drct_cd: '',
      },
      ifscLinkRltn: [],
      ifscInfo: [],

      selectIfscInfo: {
        ifsc_id: '',
        ifsc_nm: '',
        drct_cd: '1',
        extr_cnct_sect_yn: 'Y',
        del_yn: 'N',
        sect_lngt: 0,
        sect_grad_cd: 'A',
        // f_node_id,
        // t_node_id,
        // strt_nm,
        // end_nm
      },
      isUpdateFlag: false,
      isOnTrafficLayer: true,
      layerSourceName: 'ifsc_manage',
      trafficLayerData: null,
      trafficLayers: [
        { level: 8, layer: 'gimpo_ifsc_level_16' },
        { level: 7, layer: 'gimpo_ifsc_level_15' },
        { level: 6, layer: 'gimpo_ifsc_level_14' },
        { level: 5, layer: 'gimpo_ifsc_level_13' },
        { level: 4, layer: 'gimpo_ifsc_level_12' },
        { level: 3, layer: 'gimpo_ifsc_level_12' },
      ],
    };
  },
  methods: {
    async selectIfscRow(row, column, cell, event) {
      if (column.label == '') {
        return;
      }
      this.selectIfscInfo = row;
      // this.$refs.dkGis.setLevel(8);
      // let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/getLinkByIfsc`, {params:{'ifsc_id' : row.ifsc_id}});
      // console.log(result)

      let ll = this.trafficLayers.find((a) => {
        return a.level === this.$refs.dkGis.getLevel();
      });
      this.$refs.dkGis.focusTrafficLayer('ifsc_id', row.ifsc_id, ll.layer);
      this.procIfsc();
    },

    async getIfscInfo(pageNum) {
      let me = this;
      // this.searchParam['page'] = pageNum || this.$refs.pagination.getPage()
      // this.searchParam['limit'] = this.limit
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/business/api/gimpoIfscInfo`, {
        params: this.searchParam,
      });
      me.ifscInfo = response.data.docs;
    },
    apiLoadComplete(currentGisApi) {
      this.currentGisApi = currentGisApi;
      this.$refs.dkGis.setTrafficLayer(true);
    },
    setTrafficLayer() {
      this.isOnTrafficLayer = this.$refs.dkGis.setTrafficLayer(!this.isOnTrafficLayer);
    },
    setTrafficLayerIfsc() {
      this.layerSourceName = this.$refs.dkGis.setTrafficLayerSource('ifsc_manage');
      this.$refs.dkGis.setTrafficSelectMode('Single');
      this.$refs.dkGis.refresh();
    },
    // setTrafficLayerStdLink() {
    //     this.layerSourceName =  this.$refs.dkGis.setTrafficLayerSource('std_link');
    //     this.$refs.dkGis.setTrafficSelectMode("Multi");
    //     this.$refs.dkGis.refresh()
    // },
    selectTrafficLayer(feature) {
      this.selectIfscInfo = feature;
      this.procIfsc();
    },
    async procIfsc() {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/business/api/gimpoIfscLink`, {
        params: this.selectIfscInfo,
      });
      this.ifscLinkRltn = response.data;
    },
    // focusTrafficLayer() {
    //     this.getIfscInfo(1)
    //     // this.$refs.dkGis.focusTrafficLayer([
    //     //     '2060051700',
    //     //     '2060051900'
    //     // ])
    // },
    resetData() {
      this.editIfscLinkRltn = [];
      this.ifscLinkRltn = [];
      (this.selectIfscInfo = {
        ifsc_id: '',
        ifsc_nm: '',
        drct_cd: '1',
        extr_cnct_sect_yn: 'Y',
        del_yn: 'N',
        sect_lngt: 0,
        sect_grad_cd: 'A',
      }),
        // this.$refs.dkGis.focusTrafficLayer([])
        this.$refs.dkGis.trafficLayerRefresh();
      // this.$refs.dkGis.setLevel(8)
    },
    resetLayer() {
      this.$refs.dkGis.trafficLayerRefresh();
    },
    addIfscInfo() {
      this.$refs.formIfscManage.showDialog();
    },
    updateIfscInfo() {
      this.$refs.formIfscManage.showDialog(this.selectIfscInfo, this.ifscLinkRltn);
    },
    removeIfscInfo() {
      this.$confirm(`${this.selectIfscInfo.ifsc_nm} 정보 제공 구간을 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/business/api/gimpoIfscInfo/delete`, {
              data: this.selectIfscInfo,
            });
            this.getIfscInfo(1);
            this.resetData();
            this.$message({
              type: 'success',
              message: `${this.selectIfscInfo.ifsc_nm} 정보 제공 구간 삭제 완료`,
            });
          } catch (err) {
            this.$message({
              type: 'info',
              message: `${this.selectIfscInfo.ifsc_nm} 정보 제공 구간 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${this.selectIfscInfo.ifsc_nm} 정보 제공 구간 삭제 취소`,
          });
        });
    },
    refreshGis() {
      this.$refs.dkGis.trafficLayerRefresh();
    },
    procDataComplete() {
      this.getIfscInfo(1);
      this.resetData();
    },
  },
};
</script>

<style scoped>
.info-panel-wrap {
  padding: 8px 10px;
}

.el-table--mini th,
.el-table--mini td {
  padding: 2px 0 !important;
}

.el-table td {
  padding: 2px 0 !important;
}
.el-table__row {
  padding: 4px 8px;
}
</style>
