<template>
  <el-dialog :visible.sync="dialog" :close-on-click-modal="false" top="5vh" append-to-body title="통계 PDF 출력 확인">
    <div>
      <div class="button-wrap">
        <el-button @click="renderPdf" type="primary">PDF로 출력</el-button>
      </div>
      <div class="maintnce-page-wrap">
        <div v-for="(item, key) in exportData" :key="key" :id="`maintncePage${key}`" class="maintnce-page">
          <div class="maintnce-page-header">
            <h3>유지관리 작업 내역</h3>
            <span> </span>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default {
  data() {
    return {
      dialog: false,
      exportData: [],
    };
  },
  components: {},
  created() {
    let me = this;
  },
  methods: {
    async showDialog() {
      this.dialog = true;
    },
    getFormatDate(date) {
      if (date) return moment(date).format('YYYY-MM-DD LT');
      else return '';
    },
    parseHtmlContent(content) {
      if (content) return content.replace(/(\n|\r\n)/g, '<br>');
      else return null;
    },
    async renderPdf() {
      this.loading = this.$loading({
        lock: true,
        text: 'PDF문서를 렌더링 중입니다. 자료가 많으면 오래걸릴 수 있습니다.',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      let doc = new jsPDF('p', 'mm');
      let pageWidth = doc.internal.pageSize.getWidth();
      let pageHeight = doc.internal.pageSize.getHeight();
      let position = 0;
      let idx = 0;

      for (const item of this.exportData) {
        let el = document.querySelector(`#maintncePage${idx}`);
        // let proxyUrl = `${this.$config.getServerConfig().core.api}/html2canvas-proxy`;
        let proxyUrl = `/html2canvas-proxy`;
        try {
          let layers = document.querySelector(`#dkGis${idx}`).children[0].children[0].children[0].children[0];
          layers.children[5].style.zIndex = 100;
          let canvas = await window.html2canvas(el, {
            logging: true,
            proxy: proxyUrl,
            allowTaint: false,
          });
          const imgData = canvas.toDataURL('image/png');
          const imgProps = doc.getImageProperties(imgData);
          let imgWidth = pageWidth - 10;
          let imgHeight = (imgProps.height * imgWidth) / imgProps.width;
          if (idx > 0) {
            doc.addPage();
          }
          doc.addImage(imgData, 5, 10, imgWidth, imgHeight);
        } catch (err) {
          console.error(err);
        }
        idx++;
      }

      doc.save(`유지관리작업내역_${moment(new Date()).format('YYYY-MM-DD LT')}.pdf`);
      this.loading.close();
    },
  },
};
</script>

<style scoped>
.button-wrap {
  padding: 10px;
}

.maintnce-page-wrap {
  height: 600px;
  overflow-y: auto;
  width: 100%;
}

.maintnce-page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.maintnce-page-header h3 {
  font-size: 20px;
}

.maintnce-page-header span {
  display: inline-block;
  height: 30px;
  margin: 15px 0;
}

.maintnce-page-header span > img {
  height: 30px;
  margin-right: 10px;
  width: auto;
}

.maintnce-page {
  margin-bottom: 30px;
}
.maintnce-image-wrap {
  display: flex;
}
.maintnce-image {
  box-sizing: border-box;
  float: left;
  height: 200px;
  padding: 10px;
  width: 50%;
}
.maintnce-image > div {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}
</style>
