<template>
  <div class="fill-width-height el-container" id="pageFcltTypeInfo">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <dk-el-table-header :openFormDialog="openFormDialog"></dk-el-table-header>
            <el-table
              :data="fcltTypes"
              style="width: 100%;"
              empty-text="시설물 유형 정보가 없습니다."
              size="mini"
              @row-click="selectRow"
              stripe
              ref="fcltTypesGrid"
            >
              <el-table-column label fixed="left" width="60px">
                <template slot-scope="scope">
                  <span class="fclt-type-grid-icon">
                    <img :src="scope.row.gisMarkerImage.normal" />
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="fcltTypeName" label="시설물 유형 명칭" fixed="left"></el-table-column>
              <el-table-column label width="400px" align="right">
                <template slot-scope="scope">
                  <el-button @click="swapToUp(scope.row, $event)" size="small" icon="fas fa-angle-up"></el-button>
                  <el-button @click="swapToDown(scope.row, $event)" size="small" icon="fas fa-angle-down"></el-button>
                  <el-button @click="openFormDialog(scope.row)" size="small">수정</el-button>
                  <el-button v-if="!scope.row.plugin" @click="remove(scope.row)" size="small" type="danger">삭제</el-button>
                  <el-button v-if="scope.row.plugin" @click="dataRestore(scope.row)" size="small" type="info">복구</el-button>
                  <!-- <el-button type="primary" size="small" @click="maintnceTmplate(scope.row, $event)">유지관리 템플릿 설정</el-button> -->
                </template>
              </el-table-column>
            </el-table>
            <dk-el-pagination :totalDocs="totalDocs" :limit="limit" :pagingProc="pagingProc" ref="pagination"></dk-el-pagination>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <dk-info-panel ref="infoPanel" width="440px">
      <div class="info-panel-wrap">
        <h3>{{ selectFcltType.fcltTypeName }}</h3>
        <div class="dk-card">
          <div class="dk-card-title">GIS 표출 아이콘</div>
          <table class="el-table el-table--mini fclt-icon-preview" v-if="selectFcltType.gisMarkerImage">
            <tr>
              <td>
                <img :src="selectFcltType.gisMarkerImage.normal" />
              </td>
              <td>
                <img :src="selectFcltType.gisMarkerImage.selected" />
              </td>
              <td>
                <img :src="selectFcltType.gisMarkerImage.break" />
              </td>
              <td>
                <img :src="selectFcltType.gisMarkerImage.repair" />
              </td>
            </tr>
            <tr>
              <th>일반</th>
              <th>선택시</th>
              <th>고장/사고</th>
              <th>유지보수</th>
            </tr>
          </table>
        </div>

        <div class="dk-card">
          <div class="dk-card-title">시설물 유형 정의 데이터</div>
          <h5 v-if="!selectFcltType.fcltTypeData || selectFcltType.fcltTypeData.length <= 0" style="text-align: center;">
            시설물 유형 정의 데이터 없음
          </h5>
          <table v-else class="el-table el-table--mini">
            <tr>
              <th>데이터 명칭</th>
              <th>데이터 KEY</th>
              <th>데이터 유형</th>
            </tr>
            <tr v-for="(eData, index) in selectFcltType.fcltTypeData" :key="index">
              <td>{{ eData.label ? eData.label : '정보 없음' }}</td>
              <td>{{ eData.key ? eData.key : '정보 없음' }}</td>
              <td>
                <span v-if="eData.formType == 'string'">텍스트</span>
                <span v-else-if="eData.formType == 'number'">숫자</span>
                <span v-else-if="eData.formType == 'comobobox'">콤보박스</span>
                <span v-else>정보 없음</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </dk-info-panel>
    <form-ai-camera-info ref="formDialog" @saved="getFcltTypes"></form-ai-camera-info>
  </div>
</template>

<script>
import dkeltableheader from '@/components/dkElTableHeader.vue';
import dkinfopanel from '@/components/dkInfoPanel.vue';
import formAiCameraInfo from '@/ai/forms/formAiCameraInfo.vue';
import dkelpagination from '@/components/dkElPagination.vue';

export default {
  components: {
    'dk-el-table-header': dkeltableheader,
    'dk-info-panel': dkinfopanel,
    'form-ai-camera-info': formAiCameraInfo,
    'dk-el-pagination': dkelpagination,
  },
  created() {
    let me = this;
    this.getFcltTypes(1);
    this.$on('procDataComplete', (fcltType) => {
      me.getFcltTypes();
    });
  },
  data() {
    return {
      systemLogInfo: {},
      limit: 20,
      totalDocs: 0,
      fcltTypes: [],
      shownInfoPanel: false,
      selectFcltType: {
        FcltTypeName: [],
      },
    };
  },
  methods: {
    async getFcltTypes(pageNum) {
      let me = this;
      try {
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltType`, {
          params: {
            page: pageNum || this.$refs.pagination.getPage(),
            limit: this.limit,
            pluginType: 'aicamera',
          },
        });
        me.fcltTypes = response.data.docs;
        me.totalDocs = response.data.totalDocs;
        me.$refs.infoPanel.close();
      } catch (error) {
        this.$message({
          type: 'info',
          message: `시설물 유형 로드 중 에러 발생`,
        });
      }
    },
    pagingProc(pageNum) {
      this.getFcltTypes();
    },
    selectRow(row, event, column) {
      if (!this.$refs.infoPanel.isOpened()) {
        this.$refs.infoPanel.open();
      }
      this.selectFcltType = row;
    },
    openFormDialog(row) {
      this.$refs.formDialog.showDialog(row);
    },
    dataRestore(args) {
      this.$confirm(`${args.fcltTypeName} 시설물 유형 정보를 복구 합니다.<br> 이전 설정 내역은 초기화 됩니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        dangerouslyUseHTMLString: true,
        type: 'warning',
      })
        .then(async () => {
          try {
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/fcltType/restore`, { data: args });
            this.getFcltTypes();
            this.$message({
              type: 'success',
              message: `${args.fcltTypeName} 시설물 유형 복구 완료`,
            });
            this.$refs.infoPanel.close();
          } catch (error) {
            this.$message({
              type: 'info',
              message: `${args.fcltTypeName} 시설물 유형 복구 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${args.fcltTypeName} 시설물 유형 복구 취소`,
          });
        });
    },
    remove(args) {
      this.$confirm(`${args.fcltTypeName} 시설물 유형을 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            let registerFcltResponse = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/fcltType/registerFclt`, { data: args });
            this.systemLogInfo.page = `${this.$route.name} ${args.fcltTypeName}`;
            let confirm = await this.$confirm(
              `${args.fcltTypeName} 시설물 유형은 ${registerFcltResponse.data.fcltCount}개의 등록된 시설물도 같이 삭제됩니다. 정말 삭제하시겠습니까?`,
              '경고',
              {
                confirmButtonText: '확인',
                cancelButtonText: '취소',
                type: 'warning',
              }
            );

            let deleteResponse = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/fcltType/delete`, { data: args });
            if (deleteResponse) {
              this.getFcltTypes();
              this.$message({
                type: 'success',
                message: `${args.fcltTypeName} 시설물 유형 삭제 완료`,
              });
              this.systemLogInfo.saveType = 'delete';
              await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
              this.$refs.infoPanel.close();
            }
          } catch (err) {
            let message = `${args.fcltTypeName} 시설물 유형 삭제 중 에러 발생`;
            console.log(err);
            if (err === 'cancel') {
              message = `${args.fcltTypeName} 시설물 유형 삭제 취소`;
              console.log(err);
            }
            this.$message({
              type: 'info',
              message: message,
            });
            //     .then((response) => {
            //     this.getFcltTypes();
            //     this.$message({
            //         type: 'success',
            //         message: `${args.fcltTypeName} 시설물 유형 삭제 완료`
            //     });
            //     this.$refs.infoPanel.close();
            // })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${args.fcltTypeName} 시설물 유형 삭제 취소`,
          });
        });
    },
    swapData(indexA, indexB) {
      let arr = this.$lodash.cloneDeep(this.fcltTypes);
      let retArr = [];
      var temp = arr[indexA];
      var tempSeqA = arr[indexA].seq;
      var tempSeqB = arr[indexB].seq;
      arr[indexA] = arr[indexB];
      arr[indexA].seq = tempSeqA;
      retArr.push(arr[indexA]);
      arr[indexB] = temp;
      arr[indexB].seq = tempSeqB;
      retArr.push(arr[indexB]);
      this.fcltTypes = arr;
      return retArr;
    },
    async swapToUp(row, e) {
      e.preventDefault();
      e.stopPropagation();
      let prevIdx = -1;
      let targetIdx = 0;
      for (var i = 0; i < this.fcltTypes.length; i++) {
        prevIdx = i - 1;
        if (this.fcltTypes[i].fcltTypeId === row.fcltTypeId) {
          targetIdx = i;
          break;
        }
      }
      if (prevIdx > -1) {
        let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/fcltType/swap`, { data: this.swapData(prevIdx, targetIdx) });
        console.log(response);
      }
    },
    async swapToDown(row, e) {
      e.preventDefault();
      e.stopPropagation();
      let nextIdx = -1;
      let targetIdx = 0;
      for (var i = 0; i < this.fcltTypes.length; i++) {
        nextIdx = i + 1;
        if (this.fcltTypes[i].fcltTypeId === row.fcltTypeId) {
          targetIdx = i;
          break;
        }
      }
      if (nextIdx <= this.fcltTypes.length) {
        let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/fcltType/swap`, { data: this.swapData(nextIdx, targetIdx) });
        console.log(response);
      }
    },
  },
};
</script>
