<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    class="el-dialog-aside vms-editor"
    width="1100px"
    top="5vh"
    id="formVmsFigureScheduleDownload"
  >
    <div>
      <el-steps :active="step" finish-status="success" align-center style="margin: 5px 0 10px 0;">
        <el-step v-for="(s, index) in stepData" :key="index" :title="s.title"></el-step>
      </el-steps>

      <div v-show="step === 0" class="form-step-wrap">
        <div class="schedule-list-wrap">
          <div>
            <table class="dk-table">
              <colgroup>
                <col style="width: 150px;" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th scople="row">제어기 ID</th>
                  <td>{{ formData.vmsSpotId }}</td>
                </tr>
                <tr>
                  <th scople="row">설치 주소</th>
                  <td>{{ formData.fcltName }}</td>
                </tr>
                <tr>
                  <th scople="row">VMS 크기</th>
                  <td>{{ getVmsSize(formData.vmsSize) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <h4 style="margin-left: 5px;">자주 사용하던 스케줄 선택</h4>
            <el-table :data="usedVmsSchedules" height="150px" size="mini" @row-click="selectVmsSchedule" :show-header="false">
              <el-table-column label="스케줄 ID" header-align="left" align="left" width="80px" prop="vmsScheduleId"></el-table-column>
              <el-table-column label="스케줄 명칭" header-align="left" align="left" prop="vmsScheduleName"></el-table-column>
              <el-table-column label="메시지 수" header-align="left" align="left" width="140px">
                <template slot-scope="scope"> 등록 메시지 수: {{ scope.row.vmsMessages.length }} </template>
              </el-table-column>
            </el-table>
          </div>
          <div>
            <h4 style="margin-left: 5px;">스케줄 선택</h4>
            <el-table :data="vmsSchedules" size="mini" height="300px" @row-click="selectVmsSchedule" :show-header="false">
              <el-table-column label="스케줄 ID" header-align="left" align="left" width="80px" prop="vmsScheduleId"></el-table-column>
              <el-table-column label="스케줄 명칭" header-align="left" align="left" prop="vmsScheduleName"></el-table-column>
              <el-table-column label="메시지 수" header-align="left" align="left" width="140px">
                <template slot-scope="scope"> 등록 메시지 수: {{ scope.row.vmsMessages.length }} </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="vms-schedule-preview-wrap">
          <table class="dk-table">
            <colgroup>
              <col style="width: 30%;" />
              <col style="width: 70%;" />
            </colgroup>
            <tbody>
              <tr>
                <th scople="row">스케줄 명칭</th>
                <td>{{ newVmsSchedule.vmsScheduleName }}</td>
              </tr>
              <tr>
                <th scople="row">변경사항</th>
                <td>
                  <span v-if="isUpdate" style="color: limegreen; font-weight: bold;">있음</span>
                  <span v-else>없음</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div style="height: 600px; overflow-y: auto;">
            <div v-for="(item, index) in newVmsSchedule.vmsMessages" :key="index">
              <div class="vms-message-content">
                <div class="vms-message-svg-viewer">
                  <vms-message-svg-viewer :vmsMessage="item"></vms-message-svg-viewer>
                  <!-- <img :src="item.thumbImageFile"
                style="height:auto; width:100%;" />-->
                </div>
                <div class="vms-message-desc">
                  <div class="desc-0">
                    <span v-if="item.isFillin" class="vms-message-type-text fillin">필인 메시지</span>
                    <span v-else class="vms-message-type-text">일반 메시지</span>
                  </div>
                  <div class="desc-1" style="padding: 10px;">
                    <div v-if="item.vmsIfscInfo">
                      <div>정보제공구간 ID : {{ item.vmsIfscInfo.vmsIfscId }}</div>
                      <div>
                        {{ item.vmsIfscInfo.startNodeName == '' ? '정보없음' : item.vmsIfscInfo.startNodeName }}
                        ~
                        {{ item.vmsIfscInfo.endNodeName == '' ? '정보없음' : item.vmsIfscInfo.endNodeName }}
                      </div>
                    </div>
                    <div v-else-if="item.isFillin && !item.vmsIfscInfo" style="color: #fe3333;">정보제공구간 할당필요</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-show="step === 1" class="form-step-wrap">
        <div class="vms-message-wrap">
          <table class="dk-table">
            <colgroup>
              <col style="width: 30%;" />
              <col style="width: 70%;" />
            </colgroup>
            <tbody>
              <tr>
                <th scople="row">스케줄 명칭</th>
                <td>{{ newVmsSchedule.vmsScheduleName }}</td>
              </tr>
            </tbody>
          </table>
          <div style="height: 600px; overflow-y: auto;">
            <div
              :class="['vms-message', selectVmsMessageClass(item)]"
              v-for="(item, index) in newVmsSchedule.vmsMessages"
              :key="index"
              @click="checkVmsMessage(item)"
            >
              <div class="vms-message-control">
                <div>
                  <el-button size="mini" @click="swapToUp(item)">
                    <i class="fas fa-angle-up"></i>
                  </el-button>
                  <el-button size="mini" @click="swapToDown(item)">
                    <i class="fas fa-angle-down"></i>
                  </el-button>
                </div>
                <div>
                  <span class="vms-message-display-time">표출시간</span>
                  <el-input-number
                    numberOnly
                    size="mini"
                    controls-position="bottom"
                    v-model="item.displayTime"
                    style="width: 80px;"
                  ></el-input-number>
                </div>
              </div>
              <div class="vms-message-content">
                <div class="vms-message-svg-viewer">
                  <vms-message-svg-viewer :vmsMessage="item" @selectVmsLine="selectVmsLine"></vms-message-svg-viewer>
                </div>
                <div class="vms-message-desc">
                  <div class="desc-0">
                    <span v-if="item.isLineFillin" class="vms-message-type-text fillin">
                      도형식
                      <br />선형메시지
                    </span>
                    <span v-else-if="item.isFillin" class="vms-message-type-text fillin">필인 메시지</span>
                    <span v-else class="vms-message-type-text">일반 메시지</span>
                  </div>
                  <div class="desc-1">
                    <div v-if="item.vmsIfscInfo">
                      <div>정보제공구간 ID : {{ item.vmsIfscInfo.vmsIfscId }}</div>
                      <div>
                        {{ item.vmsIfscInfo.startNodeName == '' ? '정보없음' : item.vmsIfscInfo.startNodeName }}
                        ~
                        {{ item.vmsIfscInfo.endNodeName == '' ? '정보없음' : item.vmsIfscInfo.endNodeName }}
                      </div>
                    </div>
                    <div v-else-if="item.isFillin && !item.vmsIfscInfo" style="color: #fe3333;">정보제공구간 할당필요</div>
                    <div v-else-if="item.isLineFillin && selectVmsLineObject && selectVmsLineObject.data && selectVmsLineObject.data.vmsIfscInfo">
                      <div>
                        정보제공구간 ID :
                        {{ selectVmsLineObject.data.vmsIfscInfo.vmsIfscId }}
                      </div>
                      <div>
                        {{ selectVmsLineObject.data.vmsIfscInfo.startNodeName }} ~
                        {{ selectVmsLineObject.data.vmsIfscInfo.endNodeName }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="vms-ifsc-wrap">
          <div>
            <el-table :data="vmsIfscInfo" size="mini" stripe height="345px" @row-click="selectVmsIfscInfoGrid">
              <el-table-column prop="vmsIfscId" label="ID"></el-table-column>
              <el-table-column prop="vmsIfscName" label="VMS정보제공구간명" width="150px"></el-table-column>
              <el-table-column prop="startNodeName" label="시작구간"></el-table-column>
              <el-table-column prop="endNodeName" label="종료구간"></el-table-column>
              <!-- <el-table-column label="우회구간명칭">
              <template slot-scope="scope">
                <span v-if="scope.row.bypassVmsIfscData">{{ scope.row.bypassVmsIfscData.vmsIfscName }}</span>
                <span v-else>없음</span>
              </template>
            </el-table-column> -->
              <el-table-column>
                <template slot-scope="scope">
                  <el-button @click="setFillin(scope.row)" type="primary" size="small"> <i class="fas fa-plus"></i>추가 </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div style="height: 300px; position: relative;">
            <dk-gis
              :gisApiInfo="gisApiInfo"
              :apiLoadComplete="apiLoadComplete"
              :markerData="markerData"
              :trafficLayerData="trafficLayerData"
              :clickMarker="selectVmsMarker"
              :selectTrafficLayer="selectTrafficLayer"
              ref="dkGis"
            ></dk-gis>
            <div class="gis-vms-info" v-show="selectFcltMarkerData.fcltName !== ''">
              <h4 class="gis-vms-info-title">{{ selectFcltMarkerData.fcltName }}</h4>
              <div class="gis-vms-info-body">{{ selectFcltMarkerData.setNumberAddr }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-bottom" style="padding: 0 8px 8px 8px;">
        <el-button @click="prev" style="float: left;" v-show="step > 0"> <i class="fas fa-angle-left"></i>&nbsp;&nbsp;이전 </el-button>
        <el-button @click="next" style="float: right;" v-show="step < stepData.length - 1">
          다음&nbsp;&nbsp;
          <i class="fas fa-angle-right"></i>
        </el-button>
        <el-button @click="save" style="float: right;" v-show="step === stepData.length - 1">
          저장&nbsp;&nbsp;
          <i class="fas fa-angle-right"></i>
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import dkgismain from '@/components/dkGis/dkGisMain.vue';
import svgviewer from './vmsMessageSvgViewer.vue';
import _ from 'lodash';
export default {
  components: {
    'dk-gis': dkgismain,
    'vms-message-svg-viewer': svgviewer,
  },
  data() {
    return {
      step: 0,
      stepData: [
        { idx: 0, title: 'VMS 스케줄 선택' },
        { idx: 1, title: 'VMS 정보제공구간 할당' },
      ],
      usedVmsSchedules: [],
      vmsSchedules: [],
      formData: {
        fcltId: '',
        vmsSpotId: '',
        fcltName: '',
        vmsSchedules: [],
        // vmsWidth:0,
        // vmsHeight:0,
      },
      oldVmsSchedule: {},
      newVmsSchedule: {},
      rules: {
        vmsScheduleName: [
          {
            required: true,
            message: 'VMS 스케줄 이름을 입력해 주세요',
            trigger: 'blur',
          },
        ],
        vmsMessageSize: [
          {
            required: true,
            message: 'VMS 크기를 선택 해 주세요',
            trigger: 'blur',
          },
        ],
      },
      formTitle: '',
      formDialog: false,
      updateFlag: false,
      gisApiInfo: null,
      trafficLayerData: null,
      markerData: [],
      vmsIfscInfo: [],
      selectVmsIfscInfo: null,
      selectVmsMessage: null,
      selectVmsLineObject: null,
      trafficLayers: [
        { level: 8, layer: 'gimpo_ifsc_level_16' },
        { level: 7, layer: 'gimpo_ifsc_level_15' },
        { level: 6, layer: 'gimpo_ifsc_level_14' },
        { level: 5, layer: 'gimpo_ifsc_level_13' },
        { level: 4, layer: 'gimpo_ifsc_level_12' },
        { level: 3, layer: 'gimpo_ifsc_level_12' },
      ],
      selectFcltMarkerData: {
        fcltName: '',
        setNumberAddr: '',
      },
      isUpdate: false,
    };
  },
  created() {
    let me = this;
    this.trafficLayerData = {
      base: [
        {
          name: 'vms_ifsc',
          url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
          params: { LAYERS: 'cite:vms_ifsc_selected', TILED: true },
          default: true,
          idKey: 'vms_ifsc_id',
        },
      ],
      select: {
        source: {
          url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
        },
        mode: 'Single',
      },
    };
    this.gisApiInfo = this.$store.getters['gis/getGisApi'];

    this.getGisVmsMarker();
  },
  mounted() {},
  methods: {
    check(test) {
      console.log(test);
    },
    selectVmsLine(lineObject) {
      this.selectVmsLineObject = lineObject;
    },
    next() {
      if (this.step === 0) {
        if (this.newVmsSchedule.vmsScheduleId) {
          this.step++;
          setTimeout(() => {
            this.$refs.dkGis.refresh();
          }, 100);
        } else {
          this.$message({
            type: 'error',
            message: `스케줄 정보가 선택되지 않았습니다.`,
          });
        }
      } else {
        this.step++;
      }
    },
    prev() {
      // if (this.step == 1) {
      // }
      this.step--;
    },
    before() {
      if (this.step > 0) {
        this.step--;
      }
    },
    async getVmsSchedules() {
      let param = {};
      param.vmsType = '2';
      param.vmsMessageSize = this.formData.vmsSize;
      let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsSchedule`, { params: param });
      if (result.data.docs && result.data.docs.length > 0) {
        this.vmsSchedules = result.data.docs;
      } else {
        this.vmsSchedules = [];
      }
      this.getOldData();
      if (this.formData.vmsSchedules) {
        this.formData.vmsSchedules.forEach((ovs) => {
          let vs = _.find(this.vmsSchedules, (nvs) => {
            return ovs.vmsScheduleId === nvs.vmsScheduleId;
          });
          this.usedVmsSchedules.push(vs);
        });
      }
    },
    getOldData() {
      if (this.oldVmsSchedule) {
        //수정일경우 스케줄목록에서 현재 작업중인 스케줄을 찾는다
        let newVmsSchedule = _.find(this.vmsSchedules, (sch) => {
          return sch.vmsScheduleId === this.oldVmsSchedule.vmsScheduleId;
        });

        if (newVmsSchedule) {
          // 기존 스케줄과 등록된 스케줄 사이에 VMS 메시지가 변경사항이 있는지 확인한다.
          let newVmsScheduleMsg = [];
          let oldVmsScheduleMsg = [];
          this.oldVmsSchedule.vmsMessages.forEach((vm) => {
            oldVmsScheduleMsg.push(vm.vmsMessageId);
          });
          newVmsSchedule.vmsMessages.forEach((vm) => {
            newVmsScheduleMsg.push(vm.vmsMessageId);
          });

          let compare = _.isEqual(newVmsScheduleMsg, oldVmsScheduleMsg);
          if (!compare) {
            this.isUpdate = true;
          }

          const checkIdxList = [];

          this.oldVmsSchedule.vmsMessages.forEach((ovm) => {
            // let vm = _.find(newVmsSchedule.vmsMessages, (nvm) => {
            //   return ovm.vmsMessageId === nvm.vmsMessageId;
            // });

            let vm = undefined;
            for (let i = 0; i < newVmsSchedule.vmsMessages.length; i++) {
              let nvm = newVmsSchedule.vmsMessages[i];
              if (ovm.vmsMessageId === nvm.vmsMessageId) {
                if (checkIdxList.indexOf(i) === -1) {
                  checkIdxList.push(i);
                  vm = nvm;
                  break;
                }
              }
            }

            if (vm) {
              // 문자식 필인
              if (vm.isFillin) {
                if (ovm.vmsIfscInfo) vm.vmsIfscInfo = ovm.vmsIfscInfo;
              }
              // 도형식 선형 필인
              // 선형정보가 있는지 찾고, 있으면 다 까뒤집어서 매칭한다. (개귀찮)
              else if (vm.isLineFillin && vm.vmsMessageData.lineData && ovm.vmsMessageData.lineData) {
                vm.vmsMessageData.lineData.forEach((nld) => {
                  let old = _.find(ovm.vmsMessageData.lineData, (ovld) => {
                    return ovld.objectId === nld.objectId;
                  });
                  if (old.vmsIfscInfo) nld.vmsIfscInfo = old.vmsIfscInfo;
                });
              }
            }
          });
          console.log(newVmsSchedule);
          this.newVmsSchedule = newVmsSchedule;
        }
      }
    },
    showAddDialog(vmsData) {
      this.formData.vmsSpotId = vmsData.vmsSpotId;
      this.formData.vmsSize = vmsData.vmsSize;
      this.formData.fcltName = vmsData.fcltName;
      this.formData.fcltId = vmsData.fcltId;
      // this.formData.vmsWidth = vmsData.fcltTypeData.vmsWidth.value;
      // this.formData.vmsHeight = vmsData.fcltTypeData.vmsHeight.value;
      this.formData.vmsSchedules = this.$lodash.cloneDeep(vmsData.vmsSchedules);
      this.updateFlag = false;
      this.formTitle = 'VMS 스케줄 다운로드 정보 추가';
      this.getVmsSchedules();
      this.formDialog = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        this.getVmsIfscInfo({
          fcltId: vmsData.fcltId,
        });
        // let api = this.gisApiInfo.find((apiInfo) => {
        //   return apiInfo.initGis;
        // });
      });
    },
    showUpdateDialog(vmsData, vmsSchedule) {
      this.formData.vmsSpotId = vmsData.vmsSpotId;
      this.formData.vmsSize = vmsData.vmsSize;
      this.formData.fcltName = vmsData.fcltName;
      this.formData.fcltId = vmsData.fcltId;
      // this.formData.vmsWidth = vmsData.fcltTypeData.vmsWidth.value;
      // this.formData.vmsHeight = vmsData.fcltTypeData.vmsHeight.value;
      this.formData.vmsSchedules = this.$lodash.cloneDeep(vmsData.vmsSchedules);

      this.oldVmsSchedule = vmsSchedule;
      this.updateFlag = true;
      this.formTitle = 'VMS 스케줄 다운로드 정보 수정';
      this.getVmsSchedules();
      this.formDialog = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        this.getVmsIfscInfo({
          fcltId: vmsData.fcltId,
        });
        let api = this.gisApiInfo.find((apiInfo) => {
          return apiInfo.initGis;
        });
      });
    },
    hideDialog() {
      this.resetData();
      if (this.$refs.vmsMessageSvgViewerWrap) {
        this.$refs.vmsMessageSvgViewerWrap.$off('selectVmsLine');
      }
      this.formDialog = false;
    },
    resetData() {
      this.usedVmsSchedules = [];
      this.vmsSchedules = [];
      this.formData = {
        fcltId: '',
        vmsSpotId: '',
        fcltName: '',
        vmsSchedules: [],
      };
      this.newVmsSchedule = {};
      this.step = 0;
      this.selectVmsIfscInfo = null;
      this.selectVmsMessage = null;
      this.oldVmsSchedule = {};
      this.isUpdate = false;
    },
    selectVmsSchedule(row) {
      this.selectVmsLineObject = null;
      this.newVmsSchedule = row;
      // this.newVmsSchedule = this.$lodash.cloneDeep(row);
    },
    async save() {
      let me = this;
      // this.$refs.form0.validate((valid) => {
      //     if(valid) {
      if (!this.newVmsSchedule.vmsScheduleId) {
        me.$message({
          type: 'error',
          message: `스케줄 정보가 선택되지 않았습니다.`,
        });
        return;
      }
      if (this.updateFlag) {
        let idx = 0;
        for (var i = 0; i < this.formData.vmsSchedules.length; i++) {
          idx = i;
          if (this.formData.vmsSchedules[i].vmsScheduleId === this.newVmsSchedule.vmsScheduleId) {
            break;
          }
        }
        this.formData.vmsSchedules[idx] = this.newVmsSchedule;
      } else {
        if (!this.formData.vmsSchedules) {
          this.formData.vmsSchedules = [];
        }
        this.formData.vmsSchedules.push(this.newVmsSchedule);
      }

      try {
        await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/vmsDownloadedSchedule/proc`, {
          data: this.formData,
        });
        me.$parent.$emit('procDataComplete', this.formData);
        me.resetData();
        me.hideDialog();
        me.$message({
          type: 'success',
          message: `VMS 스케줄 다운로드 정보 등록 완료`,
        });
      } catch (err) {
        me.resetData();
        me.hideDialog();
        me.$message.error(`VMS 스케줄 다운로드 정보 등록 중 에러 발생`);
      }

      //     }
      // });
    },
    apiLoadComplete(currentGisApi) {
      this.$refs.dkGis.setTrafficLayer(true);
      // let latlng = this.$refs.dkGis.getCenter();
      // this.$refs.dkGis.refresh();
    },
    getGisVmsMarker() {
      this.markerData = [];
      return this.$store.dispatch('gis/actGetFcltMarker', { fcltTypeId: 'FCLTTYPE10001' }).then(() => {
        this.markerData = this.$store.getters['gis/getFcltMarker'];
      });
    },
    async getVmsIfscInfo(param) {
      this.vmsIfscInfo = [];
      const result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsIfscFcltAxisInfo`, {
        params: param,
      });
      this.vmsIfscInfo = result.data.docs || [];

      // this.vmsIfscInfo = [];
      // return this.$store.dispatch('vmsIfscInfo/getVmsIfscInfo').then(() => {
      //   this.vmsIfscInfo = this.$store.getters['vmsIfscInfo/getVmsIfscInfo']
      //     ? this.$store.getters['vmsIfscInfo/getVmsIfscInfo']
      //     : [];
      // });
    },

    checkVmsMessage(vmsMessage) {
      // if (!vmsMessage.isFillin) {
      //   return;
      // }
      // this.newVmsSchedule.vmsMessages.forEach((vm) => {
      //   vm.checked = false;
      // });
      // vmsMessage.checked = true;
      this.selectVmsMessage = vmsMessage;
      this.$forceUpdate();
    },
    setFillin(ifscInfo) {
      if (this.selectVmsMessage.isLineFillin && this.selectVmsLineObject && this.selectVmsLineObject.obj) {
        this.selectVmsLineObject.obj.setVmsIfscInfo(ifscInfo);
        this.selectVmsLineObject.data.vmsIfscInfo = ifscInfo;
        this.$forceUpdate();
      } else if (this.selectVmsMessage && this.selectVmsMessage.isFillin) {
        this.selectVmsMessage.vmsIfscInfo = ifscInfo;
        this.$forceUpdate();
      } else {
        this.$message({
          type: 'error',
          message: `정보제공 구간을 할당할 VMS 메시지를 선택하세요`,
        });
      }
    },
    getVmsSize(val) {
      if (val) {
        let s = val.split('x');
        return `${s[0]}단 ${s[1]}열`;
      }
      return '';
    },
    // focusMoveVmsIfsc(row, column, cell, event) {
    //   let focusLink = []
    //   row.linkData.forEach(ifsc => {
    //     focusLink.push(ifsc.vms_ifsc_id)
    //   })
    //   this.$refs.dkGis.setLevel(7);
    //   this.$refs.dkGis.focusTrafficLayer("vms_ifsc_id", focusLink, )
    // },
    swapToUp(vmsMessage) {
      let currentIdx = this.newVmsSchedule.vmsMessages.findIndex((msg) => {
        return msg.vmsMessageId === vmsMessage.vmsMessageId;
      });
      let targetIdx = currentIdx - 1;

      if (targetIdx >= this.newVmsSchedule.vmsMessages.length) {
        return;
      }

      var temp = this.newVmsSchedule.vmsMessages[targetIdx];
      this.newVmsSchedule.vmsMessages[targetIdx] = this.newVmsSchedule.vmsMessages[currentIdx];
      this.newVmsSchedule.vmsMessages[currentIdx] = temp;

      this.$forceUpdate();
    },
    swapToDown(vmsMessage) {
      let currentIdx = this.newVmsSchedule.vmsMessages.findIndex((msg) => {
        return msg.vmsMessageId === vmsMessage.vmsMessageId;
      });
      let targetIdx = currentIdx + 1;

      if (targetIdx >= this.newVmsSchedule.vmsMessages.length) {
        return;
      }

      var temp = this.newVmsSchedule.vmsMessages[targetIdx];
      this.newVmsSchedule.vmsMessages[targetIdx] = this.newVmsSchedule.vmsMessages[currentIdx];
      this.newVmsSchedule.vmsMessages[currentIdx] = temp;

      this.$forceUpdate();
    },
    removeMessage(vmsMessage) {
      this.$lodash.remove(this.newVmsSchedule.vmsMessages, {
        vmsMessageId: vmsMessage.vmsMessageId,
      });
      this.$forceUpdate();
    },
    selectVmsMarker(args) {
      if (args.$markerClickEvent) {
        args.$markerClickEvent();
        return;
      }
      this.selectFcltMarkerData = args.properties;
    },
    selectVmsMessageClass(vmsMessageData) {
      if (this.selectVmsMessage && this.selectVmsMessage.vmsMessageId && this.selectVmsMessage.vmsMessageId === vmsMessageData.vmsMessageId) {
        return 'selected';
      } else {
        return '';
      }
    },
    async selectVmsIfscInfoGrid(row, event, column) {
      if (column.label == '') {
        return;
      }
      this.selectVmsIfscInfo = row;

      this.$refs.dkGis.trafficLayerUpdate({
        viewparams: `VMS_IFSC_ID:${row.vmsIfscId}`,
      });
      const centerPoint = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsIfscCenterPoint`, {
        params: {
          vmsIfscId: row.vmsIfscId,
        },
      });

      this.$refs.dkGis.setCenter({
        lat: centerPoint.data.coordinates[1],
        lng: centerPoint.data.coordinates[0],
      });

      this.processedVmsIfscTraf = await this.getProcessedVmsIfscTraf({
        vms_ifsc_id: row.vmsIfscId,
      });
    },
    async getProcessedVmsIfscTraf(param) {
      //let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/business/api/processedGimpoVmsIfscTrafficInfo`, {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/business/api/gimpoVmsIfscTrafficInfo`, {
        params: { keyword: param },
      });

      return response.data;
    },
    async selectTrafficLayer(feature) {
      // this.selectVmsIfscInfo = feature;
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsIfscInfo`, {
        params: { keyword: feature['vms_ifsc_id'] },
      });

      if (response.data && response.data.docs.length > 0 && this.selectVmsIfscInfo.vmsIfscId !== response.data.docs[0].vmsIfscId) {
        this.selectVmsIfscInfo = response.data.docs[0];
        this.processedVmsIfscTraf = await this.getProcessedVmsIfscTraf(feature);
      }
    },
  },
};
</script>

<style scoped>
/*
.form-content-wrap {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.form-step-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 600px;
}

.schedule-list-wrap {
  width: 480px;
  padding: 5px;
}

.vms-schedule-preview-wrap {
  padding: 5px;
  flex: 1;
}

.vms-message-wrap {
  flex: 1;
  text-align: center;
  position: relative;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  padding: 5px;
}

.vms-ifsc-wrap {
  width: 500px;
}

.vms-message {
  background: #f4f7fd;
  margin: 10px 5px;
  padding: 0 0 10px 0;
  border-top: 3px solid #3470d3;
  cursor: pointer;
  display: flex;
}

.vms-message.selected {
  background: #81b0ee;
  color: #fff;
}

.vms-message-control {
  text-align: center;
  width: 94px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.vms-message-control button {
  margin: 5px 5px;
}

.vms-message-display-time {
  margin-bottom: 10px;
}
.vms-message-content {
  text-align: center;
  padding: 0;
  flex: 1;
}

.vms-message-svg-viewer {
  background-color: #000;
  text-align: center;
}

.vms-message-desc {
  display: flex;
  flex-direction: row;
}

.vms-message-desc .desc-0 {
  padding-top: 20px;
  width: 100px;
}

.vms-message-desc .desc-1 {
  padding-top: 20px;
  flex: 1;
}
.vms-messages {
  position: absolute;
  top: 38px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.vms-message-type-text2 {
  font-size: 14px;
  color: #5b7d9f;
  float: left;
  margin-top: 5px;
}

.vms-message-type-text {
  font-size: 14px;
  color: #5b7d9f;
  display: inline-block;
}

.vms-message-type-text_2.fillin,
.vms-message-type-text.fillin {
  color: #3470d3;
  font-weight: bold;
}
.vms-message.selected .vms-message-type-text {
  color: #ddd;
}

.vms-message.selected .vms-message-type-text.fillin {
  color: #fff;
}
*/
</style>
