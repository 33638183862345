<template>
  <div class="fill-width-height el-container" id="pageNotice">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <!-- <dk-el-table-header :openFormDialog="openFormDialog"></dk-el-table-header> -->
            <el-col :xs="10" :sm="12" :md="12" class="grid-header-menu">
              <el-button type="primary" @click="openFormDialog()" size="medium" style="margin-right: 5px;">
                <i class="fas fa-plus-circle">&nbsp;추가</i>
              </el-button>
              <el-button size="medium" class="icon-button-2" @click="exportExcel" style="margin-right: 5px;">
                <img src="../assets/img/excel_icon.png" />엑셀출력
              </el-button>
            </el-col>
            <el-form :inline="true" :model="searchForm" class="demo-form-inline" size="medium" label-width="auto">
              <el-col :span="20">
                <el-form-item label="제목 및 내용">
                  <el-input v-model="searchForm.contents" @keyup.enter.native="research"></el-input>
                </el-form-item>
                <el-form-item label="기간">
                  <el-col :span="12">
                    <el-form-item prop="startDate">
                      <el-date-picker type="date" placeholder="시작일자" v-model="searchForm.startDate" style="width: 100%;"></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="endDate">
                      <el-date-picker type="date" placeholder="종료일자" v-model="searchForm.endDate" style="width: 100%;"></el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-form-item>
                <el-form-item label="작성자">
                  <el-input v-model="searchForm.writer"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="3" :offset="1" style="text-align: right;">
                <el-button type="primary" @click="research">
                  검색
                </el-button>
                <el-button @click="resetSearch">
                  초기화
                </el-button>
              </el-col>
            </el-form>
            <el-table :data="notices" empty-text="등록된 공지사항이 없습니다." size="mini" @cell-click="selectRow" stripe>
              <el-table-column label="No" prop="seq" width="80" align="center"></el-table-column>
              <el-table-column label="제목" prop="title"></el-table-column>
              <el-table-column label="작성자" prop="writerName" width="150" header-align="center" align="center"></el-table-column>
              <el-table-column label="작성일" width="180px" header-align="center" align="center">
                <template slot-scope="scope">{{ getWriteDate(scope.row.writeDate) }}</template>
              </el-table-column>
              <el-table-column label="첨부파일" header-align="center" width="150" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="a-tag-files" v-for="(file, index) in scope.row.images" :key="index">
                    <a v-if="index === 0" :href="$config.getServerConfig().image.url + file.url" :download="file.name"><i class="el-icon-document"></i> {{ file.name }}</a>
                  </span>
                  <span v-if="scope.row.images && scope.row.images.length > 1"> 외({{ scope.row.images.length - 1 }})</span>
                </template>
              </el-table-column>
              <el-table-column label width="170px">
                <template slot-scope="scope">
                  <div :class="scope.row.writerId === nowUserId || nowUserAuth === 0 ? '' : 'd-none'">
                    <el-button @click="openFormDialog(scope.row)" size="small">수정</el-button>
                    <el-button @click="remove(scope.row)" size="small" type="danger">삭제</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <dk-el-pagination :totalDocs="totalDocs" :limit="limit" :pagingProc="pagingProc" ref="pagination"></dk-el-pagination>
          </el-col>
        </el-row>
      </el-main>
    </el-container>

    <form-notice ref="formDialog"></form-notice>
    <el-dialog :visible.sync="noticeShow" class="el-dialog-notice" top="5vh" :before-close="closeNotice">
      <div class="modal-size">
        <i class="el-icon-close close" @click="closeNotice"></i>
        <div>
          <h3>{{ selectNotice.title }}</h3>
          <div class="notice-content" v-html="getNoticeText()"></div>
        </div>
        <div>
          <div :class="[!selectNotice.images || selectNotice.images.length === 0 ? 'notice-file d-none' : 'notice-file']">
            <div style="display: table;">
              <h4>
                첨부파일
              </h4>
            </div>
            <ul>
              <li class="a-tag-files" v-for="(file, index) in selectNotice.images" :key="index">
                <a :href="file.url" :download="file.name"><i class="el-icon-document"></i> {{ file.name }}</a>
              </li>
            </ul>
          </div>
          <div class="comment-container">
            <h4>
              댓글<span> ({{ !this.selectNotice.comment ? '0' : `${this.selectNotice.comment.length}` }})</span>
            </h4>
            <ul>
              <li v-for="(item, idx) in commentList" :key="idx">
                <div class="writer-box">
                  <div class="writer">
                    <span>{{ item.writerName }}</span>
                    <div class="active-btn" :class="item.isAuth ? '' : 'd-none'">
                      <span v-if="isEdit === item.objectId" class="edit" @click="editComment(item)" style="color: #409eff;">수정완료</span>
                      <span v-else class="edit" @click="editComment(item, 'on')">수정</span>
                      <span>|</span>
                      <span v-if="isEdit === item.objectId" @click="deleteComment(item, idx)">취소</span>
                      <span v-else class="del" @click="deleteComment(item, idx, 'on')">삭제</span>
                    </div>
                  </div>
                  <span>{{ item.commentDate }}</span>
                </div>
                <div class="comment-box">
                  <el-input
                    v-if="isEdit === item.objectId"
                    type="textarea"
                    :rows="3"
                    placeholder="내용을 입력하세요."
                    v-model="commentSave"
                    style="width: 100%;"
                  >
                  </el-input>
                  <div v-else>{{ item.commentText }}</div>
                </div>
              </li>
            </ul>
            <div class="make-comment">
              <el-input type="textarea" :rows="3" placeholder="내용을 입력하세요." v-model="inputComment" style="width: 100%;"> </el-input>
              <button @click="saveComment">등록</button>
            </div>
          </div>
        </div>
      </div>
      <!-- <el-carousel v-show="selectNotice.images && selectNotice.images.length > 0" height="400px">
        <el-carousel-item v-for="(file, index) in selectNotice.images" :key="index">
          <div class="dk-carousel-item">
            <img :src="file.serverFilePath" />
          </div>
        </el-carousel-item>
      </el-carousel>
      <div v-if="selectNotice.images && selectNotice.images.length > 0" style="padding: 0 30px 20px 30px;">
        <h3>{{ selectNotice.title }}</h3>
        <h5 v-html="getNoticeText()"></h5>
      </div>
      <div v-else style="padding: 30px 30px 20px 30px;">
        <h2>{{ selectNotice.title }}</h2>
        <h4 v-html="getNoticeText()"></h4>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import dkeltableheader from '@/components/dkElTableHeader.vue';
import formnotice from '@/core/forms/formNotice.vue';
import dkelpagination from '@/components/dkElPagination.vue';
import XLSX from 'xlsx';
import uuid from 'uuid/v1';
import axios from 'axios';

export default {
  components: {
    'dk-el-table-header': dkeltableheader,
    'form-notice': formnotice,
    'dk-el-pagination': dkelpagination,
  },
  created() {
    this.getNotices(1);
    this.$on('procDataComplete', (noticeType) => {
      this.getNotices();
    });
  },
  data() {
    return {
      systemLogInfo: {},
      notices: [],
      limit: 10,
      totalDocs: 0,
      noticeShow: false,
      isEdit: '',
      isAuth: false,
      inputComment: '',
      nowUserId: '',
      nowUserAuth: '',
      selectNotice: {
        title: '',
        text: '',
        writerId: [],
        images: [],
        comment: [],
      },
      searchForm: {
        contents: '',
        writer: '',
        startDate: '',
        endDate: '',
      },
      commentInfoList: [],
      // commentList: [],
      commentSave: '',
      restApiIp: 11,
    };
  },
  methods: {
    selectRow(row, column, cell, event) {
      if (column.label == '') {
        return;
      }
      this.selectNotice = row;
      this.noticeShow = true;
      if (!row.comment) {
        row.comment = [];
      }
      this.commentInfoList = row.comment;
    },
    // notice 폼 닫을 때
    closeNotice() {
      if (this.inputComment === '') {
        this.noticeShow = false;
      } else {
        this.$confirm('작성 중인 댓글이 삭제됩니다. 닫으시겠습니까?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
          .then(() => {
            this.noticeShow = false;
            this.inputComment = '';
          })
          .catch(() => {});
      }
    },

    // 댓글 저장 시
    async saveComment() {
      try {
        let userName = this.$store.getters['auth/getActiveUser'].name;
        let userId = this.$store.getters['auth/getActiveUser'].userid;
        let newComment = {
          commentText: '',
          commentDate: '',
          writerName: '',
          writerId: '',
          objectId: uuid(),
        };
        newComment.commentText = this.inputComment;
        newComment.writerName = userName;
        newComment.writerId = userId;
        newComment.commentDate = moment().format('YYYY-MM-DD hh:mm');

        if (newComment.commentText === '') {
          this.$message('등록할 내용이 없습니다.');
        } else {
          // this.commentInfoList = [...this.commentInfoList, newComment];
          this.commentInfoList.push(newComment);

          let param = {
            _id: this.selectNotice._id,
            comment: this.commentInfoList,
          };
          await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/notice/update`, { data: param });
        }
        this.inputComment = '';
      } catch (err) {
        this.$message({
          type: 'info',
          message: `댓글 등록 에러`,
        });
      }
    },

    // 댓글 "수정"/"수정완료" 버튼 클릭 시
    async editComment(item, onOff) {
      if (onOff === 'on') {
        // "수정" 시
        this.isEdit = item.objectId;
        this.commentSave = item.commentText;
        item.commentText = this.commentSave;
      } else {
        // "수정완료" 시
        try {
          this.isEdit = '';
          let param = {
            _id: this.selectNotice._id,
            elemMatch: { objectId: item.objectId },
            content: {
              'comment.$.commentText': this.commentSave,
              'comment.$.commentDate': moment().format('YYYY-MM-DD hh:mm'),
            },
          };
          item.commentText = this.commentSave;
          await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/notice/update`, { data: param });
        } catch (err) {
          this.$message({
            type: 'info',
            message: '댓글 수정 에러',
          });
        }
      }
    },

    //댓글 "삭제"/"취소" 버튼 클릭 시
    deleteComment(item, idx, onOff) {
      if (onOff === 'on') {
        // "삭제" 시
        this.$confirm('댓글을 삭제하시겠습니까?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }).then(async () => {
          try {
            this.commentInfoList.splice(idx, 1);
            let param = {
              _id: this.selectNotice._id,
              $pull: { comment: { objectId: item.objectId } },
            };
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/notice/update`, { data: param });
          } catch (err) {
            this.$message({
              type: 'info',
              message: '댓글 삭제 에러',
            });
          }
        });
      } else {
        // "취소" 시
        this.$confirm('수정을 취소하시겠습니까?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }).then(() => {
          this.isEdit = '';
          item.commentText = this.commentSave;
        });
      }
    },

    beforeNoticeClose() {},
    research() {
      this.getNotices();
    },
    resetSearch() {
      this.searchForm = {
        contents: '',
        writer: '',
        startDate: '',
        endDate: '',
      };
    },
    async testButton() {
      let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/getRestApi`);
      
      console.log(result);
      //내부 테스트용
      // let testApi = `http://192.168.0.246:18080/coreapi/core/api/configure`;
      // let realhubApi = `http://192.168.112.97:8080/XIDE?ip=192.168.113.${this.restApiIp}&soap_port=8080&key=QWRtaW5pc3RyYXRvciBya2F3azM2OSE=&func=nvr_registernvslist`;
      // debugger;
      // axios
      //   .get(
      //     `http://192.168.112.97:8080/XIDE?ip=192.168.113.${this.restApiIp}&soap_port=8080&key=QWRtaW5pc3RyYXRvciBya2F3azM2OSE=&func=nvr_registernvslist`
      //   )
      //   .then((response) => {
      //     console.log(response);
      //   });
      // let result = await this.$http.get(realhubApi);
      // console.log(result);
    },

    getNoticeText() {
      let result = this.selectNotice.text;

      result = result.replace(/</g, '&lt;');
      result = result.replace(/>/g, '&gt;');
      result = result.replace(/\n/g, '<br>');
      return result;
    },
    openFormDialog(row) {
      this.$refs.formDialog.showDialog(row);
    },
    remove(args) {
      this.$confirm(`${args.title} 게시글을 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/notice/delete`, { data: args });
            this.getNotices();
            this.$message({
              type: 'success',
              message: `${args.title} 게시글 삭제 완료`,
            });
            this.systemLogInfo.page = `${this.$route.name} ${args.title}`;
            this.systemLogInfo.saveType = 'delete';
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
          } catch (err) {
            this.$message({
              type: 'info',
              message: `${args.title} 게시글 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${args.title} 게시글 삭제 취소`,
          });
        });
    },
    getThumbImg(notice) {
      let thumb = 'static/images/no-image.jpg';
      if (!notice.images) {
        return thumb;
      }
      if (notice.images.length == 0) {
        return thumb;
      }
      thumb = `${notice.images[0].serverFilePath}`;
      return thumb;
    },
    pagingProc(pageNum) {
      this.getNotices();
    },
    async getNotices(pageNum) {
      try {
        let me = this;

        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/notice`, {
          params: {
            ...this.searchForm,
            page: pageNum || this.$refs.pagination.getPage(),
            limit: this.limit,
          },
        });
        me.notices = response.data.docs;
        me.totalDocs = response.data.totalDocs;
        this.nowUserId = this.$store.getters['auth/getActiveUser'].userid;
        this.nowUserAuth = this.$dkRouter._menu[0].authLevel;
      } catch (err) {
        this.$message({
          type: 'info',
          message: `공지사항 표출 에러`,
        });
      }
    },
    getWriteDate(writeDate) {
      return moment(writeDate).format('YYYY-MM-DD HH:mm');
    },
    // 엑셀 출력 버튼 클릭 시
    async exportExcel() {
      let headerData = [
        { key: 'title', label: '제목' },
        { key: 'text', label: '내용' },
        { key: 'writerName', label: '작성자' },
        { key: 'writeDate', label: '작성일' },
        { key: 'images', label: '첨부파일' },
      ];
      try {
        // 엑셀에 뿌릴 데이터 가져오기
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/notice/`, {
          params: { ...this.searchForm },
        });
        if (response.data.docs.length == 0) {
          this.$message({
            type: 'info',
            message: `표출할 데이터가 없습니다.`,
          });
          return;
        }
        for (let index in response.data.docs) {
          if (!response.data.docs[index].images) {
            response.data.docs[index].images = '';
          } else {
            response.data.docs[index].images =
              response.data.docs[index].images.length == 1
                ? response.data.docs[index].images[0].name
                : `${response.data.docs[index].images[0].name} 외 ${response.data.docs[index].images.length - 1}개`;
          }
          response.data.docs[index].writeDate = moment(response.data.docs[index].writeDate).format('YYYY-MM-DD hh:mm');
        }
        // 엑셀 출력
        var header = [];
        var label = {};
        var data = [];
        for (var i = 0; i < headerData.length; i++) {
          header.push({
            key: i,
            name: headerData[i].key,
          });
          label[headerData[i].key] = headerData[i].label;
        }
        data.push(label);
        var newData = this.$lodash.union(data, response.data.docs);
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(newData, header);
        var range = XLSX.utils.decode_range(ws['!ref']);
        range.e['c'] = headerData.length - 1;
        ws['!rows'] = [];
        ws['!rows'][0] = { hidden: true };
        ws['!ref'] = XLSX.utils.encode_range(range);
        XLSX.utils.book_append_sheet(wb, ws);
        XLSX.writeFile(wb, `${moment().format('YYYY-MM-DD')}_공지사항목록.xlsx`, {
          bookType: 'xlsx',
          type: 'array',
        });
      } catch (err) {
        this.$message.error(`엑셀다운로드 중 에러 발생`);
      }
    },
  },
  computed: {
    // 댓글 쓴 유저, 로그인 한 유저 일치하는 지 판단
    commentList() {
      let userId = this.$store.getters['auth/getActiveUser'].userid;

      return this.commentInfoList.map((comment) => {
        const isAuth = userId === comment.writerId;
        return {
          ...comment,
          isAuth,
        };
      });
    },
  },
};
</script>
<style scoped></style>
