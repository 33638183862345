<template>
  <div class="fill-width-height el-container">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <el-row>
              <el-col :xs="10" :sm="12" :md="12" class="grid-header-menu">
                <el-button type="primary" @click="openFormDialog" size="medium" style="margin-right: 5px;">
                  <i class="fas fa-plus-circle">&nbsp;추가</i>
                </el-button>
                <el-button size="medium" class="icon-button-2" @click="downloadUserExportExcel" style="margin-right: 5px;">
                  <img src="../assets/img/excel_icon.png" />엑셀 출력
                </el-button>
                <el-button v-if="excelInputFlag" size="medium" class="icon-button-2" @click="showUserImportExcel">
                  <img src="../assets/img/excel_icon.png" />엑셀 입력
                </el-button>
              </el-col>
              <el-col :xs="14" :sm="12" :md="12" class="grid-header-menu" style="text-align: right;">
                <div style="margin-bottom: 5px;">
                  <el-select size="mini" v-model="selectedGroup" placeholder="사용자 그룹" @change="changeGroup">
                    <el-option v-for="(item, index) in groupList" :key="index" :label="item.name" :value="item.menuId"></el-option>
                  </el-select>
                  <el-button style="margin-left: 5px;" size="mini" v-show="selectedGroup !== ''" @click="resetGroup">선택해제</el-button>
                </div>
                <div>
                  <el-select size="mini" v-model="searchKey" placeholder="검색 키" style="margin-right: 5px; width: 100px;">
                    <el-option v-for="(item, index) in searchList" :key="index" :label="item.label" :value="item.key"></el-option>
                  </el-select>
                  <el-select
                    size="mini"
                    v-model="targetKeyword"
                    multiple
                    filterable
                    remote
                    reserve-keyword
                    placeholder="검색"
                    :remote-method="remoteMethod"
                    :loading="loading"
                  >
                    <el-option v-for="item in options" :key="item.userid" :label="getLabel(item)" :value="getLabel(item)"></el-option>
                  </el-select>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <!-- <el-col :span="6"  style="background-color: #dedede; padding:10px 5px; height:602px">
                <el-tree
                  :data="groupList"
                  show-checkbox
                  node-key="id"
                  default-expand-all
                  :expand-on-click-node="false"
                  style="background-color:transparent"
                  >
                  <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span @click="()=> printLog(node)">{{ node.label}} , {{ node.level }}</span>
                    <span>
                      <el-button
                        type="text"
                        size="mini"
                        @click="() => append(node , data)">
                        Append
                      </el-button>
                      <el-button
                        type="text"
                        size="mini"
                        @click="() => remove(node, data)">
                        Delete
                      </el-button>
                    </span>
                  </span>
                </el-tree>
          </el-col>-->
          <el-col :span="24">
            <el-table
              :data="filteredUserList"
              style="width: 100%;"
              size="small"
              @row-click="selectRow"
              :default-sort="{ prop: 'name', order: 'ascending' }"
            >
              <el-table-column label width="80" header-align="center" align="center" fixed="left">
                <template slot-scope="scope">
                  <span
                    class="user-list-thumb-img"
                    v-if="scope.row.images && scope.row.images.length > 0"
                    :style="'background-image:url(' + $config.getServerConfig().image.url + scope.row.images[0].serverFilePath + ')'"
                  ></span>
                  <span v-else class="user-list-thumb-img" style="background-image: url(static/images/unknown.png);"></span>
                </template>
              </el-table-column>
              <el-table-column prop="userid" label="아이디" width="120" header-align="center" align="center" sortable></el-table-column>
              <el-table-column prop="name" label="이름" width="130" header-align="center" align="center" sortable></el-table-column>
              <el-table-column prop="phone" label="전화번호" width="150" header-align="center" align="center" sortable></el-table-column>
              <el-table-column prop="email" label="이메일" width="180" header-align="center" sortable align="center"></el-table-column>
              <el-table-column prop="groupName" label="그룹" header-align="center" sortable align="center"></el-table-column>
              <el-table-column prop="authority" label header-align="center" align="center"></el-table-column>
            </el-table>
            <!-- <dk-el-pagination
                    :totalDocs="totalDocs"
                    :limit="limit"
                    :pagingProc="pagingProc"
                    ref="pagination"
                >
            </dk-el-pagination>-->
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <el-dialog title="사용자 정보 조회" :visible.sync="userInfoDialog">
      <div>
        <el-row v-if="selectUserInfo">
          <el-col :xs="12" :sm="8" :md="8" style="text-align: center;">
            <span
              class="user-thumb-img"
              v-if="selectUserInfo.images && selectUserInfo.images.length > 0"
              :style="'background-image:url(' + $config.getServerConfig().image.url + selectUserInfo.images[0].serverFilePath + ')'"
            ></span>
            <span v-else class="user-thumb-img" style="background-image: url(static/images/unknown.png);"></span>
          </el-col>
          <el-col :xs="12" :sm="16" :md="16">
            <h2>{{ selectUserInfo.name }}</h2>
            <p>{{ selectUserInfo.userid }}</p>
          </el-col>
          <el-col :span="18" :offset="3">
            <table class="el-table el-table--mini user-table">
              <tr>
                <th>사용자 ID</th>
                <td>{{ selectUserInfo.userid }}</td>
              </tr>
              <tr>
                <th>그룹</th>
                <td>{{ selectUserInfo.groupName }}</td>
              </tr>
              <tr>
                <th>이메일</th>
                <td>{{ selectUserInfo.email }}</td>
              </tr>
              <tr>
                <th>연락처</th>
                <td>{{ selectUserInfo.phone }}</td>
              </tr>
            </table>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="isCorrectUser" @click="passwordConfirm('password')" size="small">비밀번호 수정</el-button>
        <el-button v-if="isCorrectUser" @click="passwordConfirm('userInfo')" size="small">사용자 정보 수정</el-button>
        <el-button v-if="isCorrectUser" @click="removeUserInfo" size="small" type="danger">사용자 정보 삭제</el-button>
        <el-button @click="userInfoDialog = false" size="small">닫기</el-button>
      </span>
    </el-dialog>
    <form-user-info ref="formDialog"></form-user-info>
    <form-user-password ref="formUserPassword"></form-user-password>
    <form-password-confirm @updateUserInfo="updateUserInfo" @updatePassword="updatePassword" ref="formPasswordConfirm"></form-password-confirm>
    <form-user-import-excel ref="formUserImportExcel"></form-user-import-excel>
  </div>
</template>
<script>
import dkeltableheader from '@/components/dkElTableHeader.vue';
import dkelpagination from '@/components/dkElPagination.vue';
import formUserInfo from '@/core/forms/formUserInfo';
import formUserPassword from '@/core/forms/formUserPassword.vue';
import formPasswordConfirm from '@/core/forms/formPasswordConfirm.vue';
import formUserImportExcel from '@/core/forms/formUserImportExcel.vue';
import uuid from 'uuid';
import moment from 'moment';
import { eventbus } from '../store/eventbus';
import XLSX from 'xlsx';
import _ from 'lodash';

export default {
  components: {
    'form-user-info': formUserInfo,
    'form-user-password': formUserPassword,
    'form-password-confirm': formPasswordConfirm,
    'dk-el-pagination': dkelpagination,
    'dk-el-table-header': dkeltableheader,
    'form-user-import-excel': formUserImportExcel,
  },
  data() {
    return {
      isCorrectUser: true,
      activeUser: null,
      selectGroup: null,
      limit: 10,
      totalDocs: 0,
      groupList: [],
      userList: [],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      userInfoDialog: false,
      selectUserInfo: null,
      searchKey: '',
      searchList: [
        {
          label: '아이디',
          key: 'userid',
        },
        {
          label: '이름',
          key: 'name',
        },
        {
          label: '전화번호',
          key: 'phone',
        },
        {
          label: '이메일',
          key: 'email',
        },
        {
          label: '그룹',
          key: 'groupName',
        },
      ],
      targetKeyword: [],
      loading: false,
      options: [],
      selectedGroup: '',
      // filteredUserList: []
      excelInputFlag: true,
      systemLogInfo: {},
    };
  },
  mounted() {},
  created() {
    this.getUsers(1);
    this.$on('procDataComplete', (centerEquip) => {
      this.getUsers();
      this.userInfoDialog = false;
      this.selectedGroup = '';
      this.targetKeyword = [];
    });
    this.getGroups();
    const { options = [] } = this.$config.getSiteConfig();
    const ck = options.findIndex((o) => o === 'user-excel-noinput');
    if (ck !== -1) {
      this.excelInputFlag = false;
    }
  },
  methods: {
    async getUsers(pageNum) {
      try {
        let me = this;
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/user`, {});
        me.userList = response.data.docs;
      } catch (error) {
        this.$message({
          type: 'danger',
          message: `사용자목록 로드중 에러발생`,
        });
      }
    },
    async getGroups() {
      this.activeUser = this.$dkRouter.getUserInfo();
      let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/menus`, {
        params: this.activeUser,
      });
      if (result) {
        this.groupList = result.data;
      }
    },
    selectRow(row, event, column) {
      const authLevel = this.$dkRouter._menu[0].authLevel;
      // const userAuth = this.$store.getters['auth/getActiveUserAuthInfo'];
      if (authLevel === 0 || this.activeUser.userid === row.userid) this.isCorrectUser = true;
      else this.isCorrectUser = false;

      this.userInfoDialog = true;
      this.selectUserInfo = row;
    },
    updatePassword() {
      this.$refs.formUserPassword.showDialog(this.selectUserInfo);
    },
    updateUserInfo() {
      this.$refs.formDialog.showDialog(this.selectUserInfo);
    },
    openFormDialog() {
      this.$refs.formDialog.showDialog();
    },
    removeUserInfo() {
      this.$confirm(`${this.selectUserInfo.name} 사용자를 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/user/delete`, { data: this.selectUserInfo });
            this.getUsers();
            this.$message({
              type: 'success',
              message: `${this.selectUserInfo.name} 사용자 삭제 완료`,
            });
            this.systemLogInfo.page = `${this.$route.name} ${this.selectUserInfo.name}`;
            this.systemLogInfo.saveType = 'delete';
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
            this.userInfoDialog = false;
            this.selectUserInfo = null;
          } catch (error) {
            this.$message({
              type: 'danger',
              message: `${this.selectUserInfo.name} 사용자 삭제 중 에러 발생`,
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: 'danger',
            message: `${this.selectUserInfo.name} 사용자 삭제 취소`,
          });
        });
    },
    pagingProc(pageNum) {
      this.getUsers();
    },
    getGroupLabel() {},
    passwordConfirm(confirmTarget) {
      this.$refs['formPasswordConfirm'].showDialog(this.selectUserInfo, confirmTarget);
    },
    async downloadUserExportExcel() {
      let headerData = [
        { key: 'name', label: '사용자 이름' },
        { key: 'userid', label: '사용자 ID' },
        { key: 'phone', label: '전화번호' },
        { key: 'email', label: '이메일' },
        { key: 'group', label: '그룹 ID' },
        { key: 'groupName', label: '그룹 명칭' },
      ];
      try {
        let userDataResponse = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/userExcel/getData`, {});
        var header = [];
        var label = {};
        var data = [];
        for (var i = 0; i < headerData.length; i++) {
          header.push({
            key: i,
            name: headerData[i].key,
          });
          label[headerData[i].key] = headerData[i].label;
        }
        data.push(label);
        var data = this.$lodash.union(data, userDataResponse.data.docs);
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(data, header);
        ws['!rows'] = [];
        ws['!rows'][0] = { hidden: true };
        XLSX.utils.book_append_sheet(wb, ws);
        XLSX.writeFile(wb, `${moment().format('YYYY-MM-DD')}_사용자목록.xlsx`, {
          bookType: 'xlsx',
          type: 'array',
        });
      } catch (err) {}
    },
    showUserImportExcel() {
      this.$refs['formUserImportExcel'].showDialog();
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          let filterData = this.userList;
          if (this.selectedGroup) {
            filterData = _.filter(this.userList, (ul) => {
              return ul.group == this.selectedGroup;
            });
          }

          this.options = filterData.filter((item) => {
            if (this.searchKey !== '') {
              if (item[this.searchKey].toLowerCase().indexOf(query.toLowerCase()) > -1) {
                return {
                  label: item[this.searchKey],
                  userid: item.userid,
                };
              }
            }
          });
        }, 80);
      } else {
        this.options = [];
      }
    },
    getLabel(item) {
      return item[this.searchKey];
    },
    getValue(item) {
      return item[this.searchKey];
    },
    changeGroup(val) {
      // this.filteredUserList = _.filter(this.userList, (ul) =>{
      // return ul.group == val
      // })
    },
    resetGroup() {
      this.selectedGroup = '';
      // this.filteredUserList = this.userList
    },
  },
  computed: {
    filteredUserList() {
      let result = [];
      let data = [];
      if (this.selectedGroup !== '') {
        data = _.filter(this.userList, (ul) => {
          return ul.group == this.selectedGroup;
        });
      } else {
        data = _.clone(this.userList);
      }

      if (this.searchKey !== '' && this.targetKeyword.length > 0) {
        _.map(this.targetKeyword, (k) => {
          let a = _.find(data, (d) => {
            // console.log(`${k}, ${this.searchKey}, ${d[this.searchKey]}`)
            return k === d[this.searchKey];
          });
          result.push(a);
        });
      } else {
        result = data;
      }
      return result;
    },
  },
};
</script>

<style scoped>
.user-thumb-img {
  background-size: cover;
  display: inline-block;
  height: 100px;
  margin: 5px;
  width: 100px;
}

.user-list-thumb-img {
  background-size: cover;
  display: inline-block;
  height: 60px;
  margin: 0;
  width: 60px;
}

.user-table th {
  padding-right: 20px;
  text-align: right;
  width: 100px;
}
</style>
