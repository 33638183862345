var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "ipes-manage-console-wrap" },
    [
      _c("el-col", { staticClass: "control-panel-wrap", attrs: { span: 10 } }),
      _c(
        "el-col",
        {
          ref: "previewWrap",
          staticClass: "video-preview-wrap",
          attrs: { span: 14 }
        },
        _vm._l(_vm.previewCount, function(item, idx) {
          return _c("div", {
            key: idx,
            staticClass: "video-preview",
            style: _vm.previewStyle
          })
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }