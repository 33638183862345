<template>
  <div class="el-container">
    <div class="vms-fclt-wrap">
      <div class="dk-card">
        <el-button @click="makeVmsIfscData" v-if="masterMode">정보제공구간 생성</el-button>
        <div class="dk-card-title">VMS 시설물 정보제공구간 축 할당 목록</div>
        <el-row>
          <el-col :span="24" class="fclt-list-wrap">
            <el-table :data="vmsFcltData" size="mini" stripe @row-click="selectVmsFclt">
              <el-table-column prop="fcltId" label="ID">
                <template slot-scope="scope">
                  {{ scope.row.fcltId.length > 10 ? scope.row.fcltId.substring(0, 10) + '...' : scope.row.fcltId }}
                </template>
              </el-table-column>
              <el-table-column width="290" label="VMS 명칭">
                <template slot-scope="scope">
                  <div>{{ scope.row.fcltName }}</div>
                  <div style="color: #acacac;">{{ scope.row.setNumberAddr }}</div>
                </template>
              </el-table-column>
              <el-table-column width="90" label="등록구간 수">
                <template slot-scope="scope">
                  {{ scope.row.count }}
                </template>
              </el-table-column>
            </el-table>
            <h5 style="color: #5a5a5a; margin: 5px 15px;">총 {{ vmsFcltData.length }} 개소</h5>
          </el-col>
        </el-row>
      </div>

      <div class="dk-card" v-if="selectFcltMarkerData.fcltId">
        <el-row>
          <el-col :span="16">
            <div class="dk-card-title">{{ selectFcltMarkerData.fcltName }} <span style="font-size: 13px;">등록 구간 목록</span></div>
          </el-col>
          <el-col :span="8" style="padding-right: 20px; padding-top: 10px; text-align: right;">
            <el-button size="small" type="primary" @click="addVmsIfscFcltAxisInfo">추가</el-button>
          </el-col>
        </el-row>
        <el-row>
          <!-- <el-col>
            <el-input size="medium" v-model="vmsIfscKeyword" @keyup.enter.native="getVmsIfscInfo" placeholder="VMS정보제공구간 검색"></el-input>
          </el-col> -->
          <el-col :span="24" class="vms-ifsc-list-wrap">
            <el-table :data="vmsIfscInfo" size="mini" stripe @row-click="selectVmsIfscInfoGrid">
              <el-table-column prop="vmsIfscId" label="ID" width="120px"></el-table-column>
              <el-table-column prop="vmsIfscName" label="VMS정보제공구간명" width="170px"></el-table-column>
              <el-table-column prop="startNodeName" label="시작구간명"></el-table-column>
              <el-table-column prop="endNodeName" label="종료구간명"></el-table-column>
              <el-table-column label="">
                <template slot-scope="scope">
                  <el-button style="padding: 8px;" @click="removeFromFcltVmsIfscInfo(scope.row)" size="mini" type="danger"
                    ><i class="fas fa-trash-alt"></i
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="24">
            <h5 style="color: #5a5a5a; margin: 5px 15px;">총 구간 수 : {{ vmsIfscInfo.length }}</h5>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="vms-gis-wrap">
      <div class="gis-vms-info" v-show="selectFcltMarkerData.fcltName !== ''">
        <h4 class="gis-vms-info-title">{{ selectFcltMarkerData.fcltName }}</h4>
        <div class="gis-vms-info-body">{{ selectFcltMarkerData.setNumberAddr }}</div>
      </div>
      <div class="gis-ifsc-link-data" v-if="selectVmsIfscInfo.vmsIfscId !== ''">
        <div>
          <div>
            <h4>
              {{ this.selectVmsIfscInfo.vmsIfscName }} <span>({{ this.selectVmsIfscInfo.vmsIfscId }})</span>
            </h4>
          </div>
          <div class="traf-status-data">
            <el-tag :type="getTrafStatusTag()" size="mini">
              {{ getTrafStatusTagText() }}
            </el-tag>
            &nbsp;통과시간 :
            {{ processedVmsIfscTraf.travelTime }} 분
          </div>
        </div>
        <table>
          <colgroup>
            <col style="width: 30%;" />
            <col style="width: 70%;" />
          </colgroup>
          <tr v-for="(item, idx) in selectVmsIfscInfo.linkData" :key="idx">
            <th>{{ item.link_id }}</th>
            <td class="node-text">
              <span class="f-node-text">{{ item.f_node_name }}</span>
              <span class="t-node-text">{{ item.t_node_name }}</span>
            </td>
          </tr>
        </table>
      </div>
      <dk-gis
        fill-height
        :gisApiInfo="gisApiInfo"
        :apiLoadComplete="apiLoadComplete"
        :markerData="markerData"
        :clickMarker="selectVmsMarker"
        :trafficLayerData="trafficLayerData"
        :selectTrafficLayer="selectTrafficLayer"
        ref="dkGis"
      ></dk-gis>
    </div>

    <form-vms-ifsc-fclt-axis-manage
      ref="formVmsIfscFcltAxisManage"
      @procDataComplete="procDataComplete"
      :gisApiInfo="gisApiInfo"
    ></form-vms-ifsc-fclt-axis-manage>
  </div>
</template>

<script>
import dkgismain from '@/components/dkGis/dkGisMain.vue';
import formVmsIfscFcltAxisManage from '@/traffic/form/formVmsIfscFcltAxisManage.vue';

export default {
  components: {
    'dk-gis': dkgismain,
    'form-vms-ifsc-fclt-axis-manage': formVmsIfscFcltAxisManage,
  },
  created() {
    this.trafficLayerData = {
      base: [
        {
          name: 'vms_ifsc',
          url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
          params: { LAYERS: 'cite:vms_ifsc_selected', TILED: true },
          default: true,
          idKey: 'vms_ifsc_id',
        },
      ],
      select: {
        source: {
          url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
        },
        mode: 'Single',
      },
    };
    this.gisApiInfo = this.$store.getters['gis/getGisApi'];

    this.getGisVmsMarker();
    // this.getVmsIfscInfo();
    this.getVmsFclt();
  },
  mounted() {
    let api = this.gisApiInfo.find((apiInfo) => {
      return apiInfo.initGis;
    });
    if (api) this.$refs.dkGis.gisModuleLoad(api.gisName);
  },
  computed: {},
  data() {
    return {
      gisApiInfo: [],
      markerData: [],
      vmsIfscInfo: [],
      selectVmsIfscInfo: {
        vmsIfscId: '',
        vmsIfscName: '',
        startNodeName: '',
        endNodeName: '',
        linkData: [],
        bypassVmsIfscData: {},
        seq: -1,
      },
      selectFcltMarkerData: {
        fcltName: '',
        fcltTypeName: '',
        fcltTypeId: '',
        regionId: '',
        regionName: '',
        setDate: '',
        setNumberAddr: '',
        setRoadAddr: '',
        gisLat: '',
        gisLng: '',
        equip: [],
        fcltTypeData: {},
        images: [],
        files: [],
      },
      trafficLayerData: null,
      trafficLayers: [
        { level: 8, layer: 'vms_ifsc_level_16' },
        { level: 7, layer: 'vms_ifsc_level_15' },
        { level: 6, layer: 'vms_ifsc_level_14' },
        { level: 5, layer: 'vms_ifsc_level_13' },
        { level: 4, layer: 'vms_ifsc_level_12' },
        { level: 3, layer: 'vms_ifsc_level_12' },
      ],
      processedVmsIfscTraf: { congestionCode: 0, travelTime: 0 },
      vmsFcltData: [],
      masterMode: false,
    };
  },
  methods: {
    apiLoadComplete(currentGisApi) {
      this.currentGisApi = currentGisApi;
      this.$refs.dkGis.setTrafficLayer(true);
    },
    async getVmsFclt() {
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsListIfsc`, {});
      this.vmsFcltData = response.data.docs;
    },
    getGisVmsMarker() {
      this.markerData = [];
      return this.$store.dispatch('gis/actGetFcltMarker', { fcltTypeId: 'FCLTTYPE10001' }).then(() => {
        this.markerData = this.$store.getters['gis/getFcltMarker'];
        this.totalDocs = this.markerData.length;
      });
    },
    procDataComplete(formData) {
      this.getVmsFclt();
      this.getVmsIfscInfo({ fcltId: formData.fcltId });
    },
    async getVmsIfscInfo(param) {
      this.vmsIfscInfo = [];

      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsIfscFcltAxisInfo`, {
        params: param,
      });
      if (response.data && response.data.docs) {
        this.vmsIfscInfo = response.data.docs;
      } else {
        this.vmsIfscInfo = [];
      }
    },
    selectVmsMarker(args) {
      if (args.$markerClickEvent) {
        args.$markerClickEvent();
        return;
      }
      this.selectFcltMarkerData = args.properties;
      this.$refs.dkGis.setCenter({
        lat: this.selectFcltMarkerData.gisLat,
        lng: this.selectFcltMarkerData.gisLng,
      });
      this.getVmsIfscInfo({
        fcltId: this.selectFcltMarkerData.fcltId,
      });
    },
    async selectVmsFclt(row, event, column) {
      try {
        this.selectVmsIfscInfo = {
          vmsIfscId: '',
          vmsIfscName: '',
          startNodeName: '',
          endNodeName: '',
          linkData: [],
          bypassVmsIfscData: {},
          seq: -1,
        };
        this.selectVmsMarker({ properties: row });
        this.getVmsIfscInfo({
          fcltId: row.fcltId,
        });
      } catch (err) {}
    },
    async selectVmsIfscInfoGrid(row, event, column) {
      if (column.label == '') {
        return;
      }
      this.selectVmsIfscInfo = row;

      this.$refs.dkGis.trafficLayerUpdate({
        viewparams: `VMS_IFSC_ID:${row.vmsIfscId}`,
      });
      const centerPoint = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsIfscCenterPoint`, {
        params: {
          vmsIfscId: row.vmsIfscId,
        },
      });

      this.$refs.dkGis.setCenter({
        lat: centerPoint.data.coordinates[1],
        lng: centerPoint.data.coordinates[0],
      });

      this.processedVmsIfscTraf = await this.getProcessedVmsIfscTraf({
        vms_ifsc_id: row.vmsIfscId,
      });

      // let ll = this.trafficLayers.find((a) => {
      //   return a.level === parseInt(this.$refs.dkGis.getLevel());
      // });

      // this.$refs.dkGis.focusTrafficLayer('vms_ifsc_id', row.vmsIfscId, ll.layer);
    },
    clearFclt() {
      this.selectFcltMarkerData = {
        fcltName: '',
        fcltTypeName: '',
        fcltTypeId: '',
        regionId: '',
        regionName: '',
        setDate: '',
        setNumberAddr: '',
        setRoadAddr: '',
        gisLat: '',
        gisLng: '',
        equip: [],
        fcltTypeData: {},
        images: [],
        files: [],
      };
      // this.$refs.dkGis.setLevel(8)
    },
    clearVmsIfscData() {
      this.selectVmsIfscInfo = {
        vmsIfscId: '',
        vmsIfscName: '',
        startNodeName: '',
        endNodeName: '',
        linkData: [],
        bypassVmsIfscData: {},
        seq: -1,
      };
      this.$refs.dkGis.trafficLayerRefresh();
      this.getVmsFclt();
      // this.getVmsIfscInfo();
    },
    async getProcessedVmsIfscTraf(param) {
      //let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/business/api/processedGimpoVmsIfscTrafficInfo`, {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/business/api/gimpoVmsIfscTrafficInfo`, {
        params: { keyword: param },
      });

      return response.data;
    },
    async selectTrafficLayer(feature) {
      // this.selectVmsIfscInfo = feature;
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsIfscInfo`, {
        params: { keyword: feature['vms_ifsc_id'] },
      });

      if (response.data && response.data.docs.length > 0 && this.selectVmsIfscInfo.vmsIfscId !== response.data.docs[0].vmsIfscId) {
        this.selectVmsIfscInfo = response.data.docs[0];
        this.processedVmsIfscTraf = await this.getProcessedVmsIfscTraf(feature);
      }
    },
    async procVmsIfsc(param) {
      console.log(param);
    },
    addVmsIfscFcltAxisInfo() {
      this.$refs.formVmsIfscFcltAxisManage.showDialog(this.selectFcltMarkerData);
    },
    updatedVmsIfscFcltAxisInfo(row) {
      this.selectVmsIfscInfo = row;
      this.$refs.formVmsIfscFcltAxisManage.showDialog(this.selectFcltMarkerData, this.selectVmsIfscInfo);
    },
    removeFromFcltVmsIfscInfo(row) {
      this.selectVmsIfscInfo = row;
      this.$confirm(
        `${this.selectFcltMarkerData.fcltName} 시설물에서 ${this.selectVmsIfscInfo.vmsIfscName} VMS 정보 제공 구간을 삭제 합니다.`,
        '경고',
        {
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          type: 'warning',
        }
      )
        .then(() => {
          this.$http
            .post(`${this.$config.getServerConfig().core.api}/core/api/vmsIfscFcltAxisInfo/delete`, {
              data: this.selectVmsIfscInfo,
            })
            .then((response) => {
              //this.getIfscInfo(1);
              this.clearVmsIfscData();
              this.getGisVmsMarker();
              this.$message({
                type: 'success',
                message: `${this.selectVmsIfscInfo.vmsIfscName} VMS 정보 제공 구간 삭제 완료`,
              });
            })
            .catch((err) => {
              this.clearVmsIfscData();
              this.$message({
                type: 'info',
                message: `${this.selectVmsIfscInfo.vmsIfscName} VMS 정보 제공 구간 삭제 중 에러 발생`,
              });
            });
        })
        .catch(() => {
          this.clearVmsIfscData();
          this.$message({
            type: 'info',
            message: `${this.selectVmsIfscInfo.vmsIfscName} VMS 정보 제공 구간 삭제 취소`,
          });
        });
    },

    // saveVmsIfscData() {
    //     let formData = this.$lodash.cloneDeep(this.selectVmsIfscInfo)
    //     formData.linkData = []
    //     let saveFunc = (this.isUpdateFlag) ? "vmsIfscInfo/updateVmsIfscInfo" : "vmsIfscInfo/addVmsIfscInfo" ;
    //     this.editVmsIfscRltn.forEach((link) => {
    //         let linkData = link
    //         linkData.ifsc_id = parseInt(link.ifsc_id)
    //         formData.linkData.push(linkData)
    //     })
    //     this.$store.dispatch(saveFunc, {data:formData})
    //     .then((response) => {
    //         this.isVmsIfscInfoForm = false
    //         this.clearVmsIfscData();
    //         this.getVmsIfscInfo();
    //     })
    // },
    getTrafStatusTag() {
      let type = 'info';
      switch (parseInt(this.processedVmsIfscTraf.congestionCode)) {
        case 5:
          type = 'success';
          break;

        case 4:
          type = 'warning';
          break;

        case 3:
          type = 'warning';
          break;

        case 2:
          type = 'danger';
          break;

        case 1:
          type = 'danger';
          break;

        case 0:
          type = 'info';
          break;
      }
      return type;
    },
    getTrafStatusTagText() {
      let text = 'none';
      switch (parseInt(this.processedVmsIfscTraf.congestionCode)) {
        case 5:
          text = '소통원활';
          break;

        case 4:
          text = '부분지체';
          break;

        case 3:
          text = '지체';
          break;

        case 2:
          text = '부분정체';
          break;

        case 1:
          text = '정체';
          break;

        case 0:
          text = '정보없음';
          break;
      }
      return text;
    },
    async makeVmsIfscData() {
      await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsMakeData`, {});
    },
  },
};
</script>

<style scoped>
.vms-fclt-wrap {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px 10px;
  width: 570px;
}

.vms-gis-wrap {
  flex: 1;
  height: 100%;
  position: relative;
}

.fclt-list-wrap {
  height: 400px;
  overflow-y: auto;
}

.vms-ifsc-list-wrap {
  flex: 1;
  overflow-y: auto;
}

.gis-vms-info {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 4px;
  box-shadow: 0 1px 15px rgba(27, 31, 35, 0.15);
  font-size: 12px;
  min-height: 70px;
  padding: 0 5px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 200px;
  z-index: 1000;
}

.gis-vms-info-title {
  font-size: 15px;
  margin: 15px 0;
}

.gis-vms-info-body {
  border-top: 1px solid #e1e4e8;
  padding: 10px 0;
}

.gis-ifsc-link-data {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 4px;
  bottom: 10px;
  box-shadow: 0 1px 15px rgba(27, 31, 35, 0.15);
  max-height: 60%;
  min-height: 400px;
  overflow-y: auto;
  position: absolute;
  right: 10px;
  width: 360px;
  z-index: 1000;
}

.gis-ifsc-link-data h4 {
  margin: 15px 12px;
}

.gis-ifsc-link-data h4 span {
  color: #acacac;
  font-size: 12px;
}

.gis-ifsc-link-data .traf-status-data {
  display: flex;
  font-size: 14px;
  justify-content: flex-end;
  padding: 0 5px;
}

.gis-ifsc-link-data table {
  font-size: 12px;
  margin-top: 10px;
  width: 100%;
}
.gis-ifsc-link-data table th {
  color: #8a8a8a;
}

.gis-ifsc-link-data td.node-text {
  display: flex;
  justify-content: space-between;
  padding: 3px 10px;
}

.gis-ifsc-link-data table td span.f-node-text,
.gis-ifsc-link-data table td span.t-node-text {
}

.info-panel-wrap {
  padding: 8px 10px;
}

.el-table--mini th,
.el-table--mini td {
  padding: 2px 0 !important;
}

.el-table td {
  padding: 2px 0 !important;
}
.el-table__row {
  padding: 4px 8px;
}
</style>
