<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    top="5vh"
    class="el-dialog-aside"
    append-to-body
  >
    <div v-if="maintnceStep == -1">
      <div style="height: 200px; padding-top: 130px; text-align: center;">
        <span>
          <i class="el-icon-loading" style="font-size: 40px;"></i>
          <h3>
            <span style="color: #0073da;">[{{ fcltData.fcltName }}]</span> 시설물의 유지관리 정보를 로딩 중입니다
          </h3>
        </span>
      </div>
    </div>
    <div v-else-if="maintnceStep > -1" class="form-maintnce-dialog">
      <div>
        <img :src="getMaintnceStepImg" style="width: 100%; height: auto;" />
      </div>
      <div style="padding: 20px 10px 0px 10px;">
        <div v-if="formData.status > 0" :span="24" class="dk-card">
          <el-table :data="formData.steps" size="small">
            <el-table-column label="작업일" width="160px">
              <template slot-scope="scope">{{ getStepDate(scope.row) }}</template>
            </el-table-column>
            <el-table-column label="작업 내용" prop="desc"></el-table-column>
            <el-table-column label width="80">
              <template slot-scope="scope">
                <el-button @click="updateBeforeStep(scope.row)" size="mini">수정</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-row style="margin-top: 15px;">
            <el-col
              :span="4"
              v-for="(file, index) in maintnceImage"
              :key="index"
              class="maintnce-image"
            >
              <el-tooltip :content="file.msg" placement="top">
                <img @click="showMaintnceImage(index)" :src="$config.getServerConfig().image.url + file.image.serverFilePath" />
              </el-tooltip>
            </el-col>
          </el-row>
        </div>
      </div>
      <div style="padding: 0px 10px 12px 10px;">
        <div class="dk-card" v-if="maintnceStep >= 0 && maintnceStep <= 2">
          <div v-if="maintnceStep == 0">
            <div v-if="!formData.status || formData.status == 0" style="margin-left: 20px;">
              <h4>
                <span style="color: #0073da;">[{{ fcltData.fcltName }}]</span> 시설물의 새로운 유지관리 작업을 등록합니다.
              </h4>
            </div>

            <h4>시설물 유지관리 접수 단계 등록</h4>
            <el-form
              :model="formData"
              label-width="140px"
              size="medium"
              :rules="rules"
              ref="form0"
              :label-position="$isMobile ? 'top' : 'right'"
            >
              <el-row>
                <el-col :md="20" :sm="24">
                  <el-form-item label="발생 일자" prop="startDate">
                    <el-date-picker
                      type="date"
                      v-model="formData.startDate"
                      placeholder="유지관리 발생 일자"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :md="20" :sm="24">
                  <el-form-item label="작업 내용">
                    <el-input type="textarea" v-model="step.desc"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <h4>시설물 유지관리 처리사진</h4>
                  <dk-el-file-upload
                    ref="uploadImage"
                    uploaderType="image"
                    listType="picture-card"
                    path="maintnce"
                    :fileList="step.images"
                  >
                    <div>
                      <i class="el-icon-plus"></i>
                    </div>
                  </dk-el-file-upload>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div v-else-if="maintnceStep == 1">
            <h4>시설물 유지관리 처리 단계 등록</h4>
            <el-form
              :model="formData"
              label-width="140px"
              size="medium"
              :rules="rules"
              ref="form0"
              :label-position="$isMobile ? 'top' : 'right'"
            >
              <el-row>
                <el-col :md="20" :sm="24">
                  <el-form-item label="발생 일자" prop="startDate">
                    <el-date-picker
                      type="date"
                      v-model="formData.startDate"
                      placeholder="유지관리 발생 일자"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :md="20" :sm="24">
                  <el-form-item label="작업완료 계획일자" prop="completePlanDate">
                    <el-date-picker
                      type="date"
                      v-model="formData.completePlanDate"
                      placeholder="유지관리 작업완료 계획일자"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :md="20" :sm="24">
                  <el-form-item label="작업 내용">
                    <el-input type="textarea" v-model="step.desc"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <h4>시설물 유지관리 처리사진</h4>
                  <dk-el-file-upload
                    ref="uploadImage"
                    uploaderType="image"
                    listType="picture-card"
                    path="maintnce"
                    :fileList="step.images"
                  >
                    <div>
                      <i class="el-icon-plus"></i>
                    </div>
                  </dk-el-file-upload>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div v-else-if="maintnceStep == 2">
            <h4>시설물 유지관리 확인 단계 등록</h4>
            <el-form
              :model="formData"
              label-width="140px"
              size="medium"
              :rules="rules"
              ref="form0"
              :label-position="$isMobile ? 'top' : 'right'"
            >
              <el-row>
                <el-col :md="20" :sm="24">
                  <el-form-item label="작업 내용">
                    <el-input type="textarea" v-model="step.desc"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <h4>시설물 유지관리 처리사진</h4>
                  <dk-el-file-upload
                    ref="uploadImage"
                    uploaderType="image"
                    listType="picture-card"
                    path="maintnce"
                    :fileList="step.images"
                  >
                    <div>
                      <i class="el-icon-plus"></i>
                    </div>
                  </dk-el-file-upload>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
      <div class="form-bottom">
        <el-button
          v-if="maintnceStep > -1 && maintnceStep <= 2"
          @click="hideDialog"
          style="float: right;"
        >취소</el-button>
        <el-button
          v-if="maintnceStep > -1 && maintnceStep <= 2"
          type="primary"
          @click="save"
          style="float: right; margin-right: 5px;"
        >저장</el-button>
        <el-button v-if="maintnceStep == 3" @click="hideDialog" style="float: right;">닫기</el-button>
      </div>
    </div>
    <el-dialog title="유지관리 처리 사진" :visible.sync="maintnceImageDialog" append-to-body>
      <el-carousel
        v-show="maintnceImage && maintnceImage.length > 0"
        height="400px"
        ref="maintnceImageCarousel"
        :initial-index="activeMaintnceImageIndex"
      >
        <el-carousel-item v-for="(file, index) in maintnceImage" :key="index">
          <div class="dk-carousel-item">
            <img :src="$config.getServerConfig().image.url + file.image.serverFilePath" />
            <div class="dk-carousel-item-label">{{ file.msg }}</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </el-dialog>
</template>

<script>
import DkFileUtil from '@/components/dkFileUtil.js';
import dkElFileUpload from '@/components/dkElFileUpload.vue';

import moment from 'moment';
export default {
  components: {
    'dk-el-file-upload': dkElFileUpload,
  },
  data() {
    return {
      maintnceStepImg: [
        require('../../assets/img/maintnce_step_0.png'),
        require('../../assets/img/maintnce_step_1.png'),
        require('../../assets/img/maintnce_step_2.png'),
        require('../../assets/img/maintnce_step_3.png'),
      ],
      updateFlag: false,
      maintnceStep: -1,
      fcltData: {},
      formDialog: false,
      formTitle: '유지관리 등록',
      formData: {},
      rules: {},
      step: {
        desc: '',
        images: [],
      },
      maintnceImageDialog: false,
      activeMaintnceImageIndex: 0,
    };
  },
  created() {
    this.resetData();
  },
  computed: {
    getMaintnceStepImg() {
      return this.maintnceStepImg[this.maintnceStep];
    },
    maintnceImage() {
      let imageArr = [];
      for (let i = 0; i < this.maintnceStep; i++) {
        let stepMsg = '';
        if (i == 0) {
          stepMsg = '접수/발생 단계 사진';
        } else if (i == 1) {
          stepMsg = '처리 단계 사진';
        } else if (i == 2) {
          stepMsg = '확인 단계 사진';
        }
        if (this.formData.steps[i].images) {
          for (let j = 0; j < this.formData.steps[i].images.length; j++) {
            console.log(this.formData.steps[i].images[j]);
            let imgObj = {
              image: this.formData.steps[i].images[j],
              msg: stepMsg,
            };
            imageArr.push(imgObj);
          }
        }
      }
      return imageArr;
    },
  },
  methods: {
    getStepDate(row) {
      return moment(row.date).format('YYYY-MM-DD HH:mm:ss');
    },
    save() {
      let me = this;
      this.$refs.form0.validate((valid) => {
        let saveFunc = 'maintnce/addMaintnce';
        if (this.updateFlag) {
          saveFunc = 'maintnce/updateMaintnce';
        }
        if (valid) {
          this.step.images = this.tempUploadImages;
          this.step.stepIdx = this.maintnceStep;
          this.step.date = new Date();
          if (this.formData.status == this.maintnceStep) {
            this.formData.steps.push(this.step);
            this.formData.status++;
          }
          this.$store
            .dispatch(saveFunc, { data: this.formData })
            .then((response) => {
              me.$parent.$emit('procDataComplete', this.formData);
              me.resetData();
              me.hideDialog();
              me.$message({
                type: 'success',
                message: `${me.fcltData.fcltName} 유지관리 등록 완료`,
              });
            })
            .catch((err) => {
              me.resetData();
              me.hideDialog();
              me.$message.error(`${me.fcltData.fcltName} 유지관리 등록 중 에러 발생`);
            });
        } else {
          return false;
        }
      });
    },
    showDialog(fcltData, maintnceLog) {
      let me = this;
      this.fcltData = this.$lodash.cloneDeep(fcltData);
      if (maintnceLog) {
        this.formData = this.$lodash.cloneDeep(maintnceLog);
        this.maintnceStep = this.formData.status;
        if (this.maintnceStep > 0) {
          this.updateFlag = true;
        }
        if (this.formData.status == 3) {
          this.formTitle = '유지관리 내역 확인';
        } else {
          this.formTitle = '유지관리 등록';
        }
        this.formDialog = true;
        this.$forceUpdate();
        return;
      }
      if (fcltData) {
        this.$store
          .dispatch('maintnce/getMaintnce', { fcltId: fcltData.fcltId })
          .then((response) => {
            if (this.$store.getters['maintnce/getMaintnce'] && this.$store.getters['maintnce/getMaintnce'].length > 0) {
              this.formData = this.$store.getters['maintnce/getMaintnce'][0];
              setTimeout(() => {
                this.updateFlag = true;
                let tmpStepIdx = this.formData.steps[this.formData.steps.length - 1].stepIdx;
                this.maintnceStep = tmpStepIdx + 1;
              }, 1000);
            } else {
              setTimeout(() => {
                this.maintnceStep = 0;
                this.formData.status = 0;
                this.formData.fcltId = this.fcltData.fcltId;
                this.formData.fcltName = this.fcltData.fcltName;
                this.formData.fcltTypeId = this.fcltData.fcltTypeId;
                this.formData.fcltTypeName = this.fcltData.fcltTypeName;
              }, 1000);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              this.maintnceStep = 0;
              this.formData.status = 0;
              this.formData.fcltId = this.fcltData.fcltId;
              this.formData.fcltName = this.fcltData.fcltName;
              this.formData.fcltTypeId = this.fcltData.fcltTypeId;
              this.formData.fcltTypeName = this.fcltData.fcltTypeName;
            }, 500);
          });
      }

      this.formDialog = true;
      this.$forceUpdate();
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    updateBeforeStep(step) {
      this.$confirm(`${this.formData.fcltName} 이전 유지관리 내역을 수정 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      }).then(() => {
        this.updateFlag = true;
        this.step = step;
        this.maintnceStep = step.stepIdx;
        this.tempUploadImages = this.step.images;
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      });
    },
    resetData() {
      this.formData = {
        id: '',
        fcltId: '',
        fcltName: '',
        fcltTypeId: '',
        fcltTypeName: '',
        maintnceWorkCd: '',
        maintnceWorkName: '',
        status: -1,
        startDate: new Date(),
        endDate: null,
        completePlanDate: null,
        workerId: '',
        workDesc: '',
        maintnceDesc: '',
        steps: [],
      };
      this.fcltData = {
        fcltName: '',
      };
      this.step = {
        desc: '',
      };
      this.maintnceStep = -1;
      this.updateFlag = false;
      this.formTitle = '유지관리 등록';
    },
    procUploadImage(file, fileList) {
      this.tempUploadImages = [];
      fileList.forEach((fl) => {
        if (fl.status === 'success') {
          if (fl.response) {
            this.tempUploadImages.push({
              name: fl.name,
              serverFileName: fl.response.result.serverFileName,
              serverFilePath: fl.response.result.serverFilePath,
              size: fl.size,
              url: `${fl.response.result.serverFilePath}`,
            });
          } else {
            this.tempUploadImages.push(fl);
          }
        }
      });
    },
    procRemoveImage(file, fileList) {
      this.tempUploadImages = [];
      fileList.forEach((fl) => {
        this.tempUploadImages.push(fl);
      });
    },
    checkUploadImage(file) {
      if (!DkFileUtil.isImage(file.name)) {
        this.$message.error(`이미지 파일만 업로드 할 수 있습니다.`);
        return false;
      }
    },
    showMaintnceImage(index) {
      this.maintnceImageDialog = true;
      this.activeFcltImageIndex = index;
      if (this.$refs.maintnceImageCarousel) this.$refs.maintnceImageCarousel.setActiveItem(index);
    },
  },
};
</script>

<style scoped>
.maintnce-image {
  padding: 0px 2px 4px 2px;
  cursor: pointer;
  text-align: center;
}

.maintnce-image:hover > img {
  opacity: 0.3;
}

.maintnce-image > img {
  width: auto;
  height: auto;
  max-height: 120px;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
}

.form-maintnce-dialog {
  background-color: #edf1f2 !important;
  padding: 20px;
}
</style>
