<template>
  <v-container>
    <v-layout row wrap>
      asdf
      {{getDiff}}
      {{pageOK}}
    </v-layout>
  </v-container>
</template>
<script>
  import Page from './js/page'

  export default {
    data() {
      return {
        eventBus: {
          selectedEvent: 'select',
          pageData: ''
        },
        pageOK: {}
      }
    },
    created() {
      this.pageOK = new Page('/', '/')
    },
    computed: {
      getDiff: function () {
        return this.pageOK.getdiff()
      }
    }
  }
</script>
