var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "el-dialog-aside vms-editor",
      attrs: {
        title: _vm.formTitle,
        visible: _vm.formDialog,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        width: "1000px",
        top: "5vh"
      },
      on: {
        "update:visible": function($event) {
          _vm.formDialog = $event
        },
        close: _vm.hideDialog
      }
    },
    [
      _c(
        "el-container",
        { staticClass: "dialog-container" },
        [
          _c(
            "el-container",
            [
              _c(
                "el-form",
                {
                  ref: "form0",
                  attrs: {
                    model: _vm.formData,
                    "label-width": "120px",
                    size: "medium",
                    rules: _vm.rules,
                    "label-position": _vm.$isMobile ? "top" : "right"
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "메시지 이름",
                                prop: "vmsMessageName"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "VMS 메시지 이름" },
                                model: {
                                  value: _vm.formData.vmsMessageName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "vmsMessageName",
                                      $$v
                                    )
                                  },
                                  expression: "formData.vmsMessageName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "메시지 유형",
                                prop: "vmsMessageType"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "VMS 메시지 유형 " },
                                  model: {
                                    value: _vm.formData.vmsMessageType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "vmsMessageType",
                                        $$v
                                      )
                                    },
                                    expression: "formData.vmsMessageType"
                                  }
                                },
                                _vm._l(_vm.vmsConst.vmsMessageType, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "메시지 크기",
                                prop: "vmsMessageSize"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "VMS 메시지 크기 " },
                                  on: { change: _vm.changeVmsSize },
                                  model: {
                                    value: _vm.formData.vmsMessageSize,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "vmsMessageSize",
                                        $$v
                                      )
                                    },
                                    expression: "formData.vmsMessageSize"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "15단20열", value: "15x20" }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "10단18열", value: "10x18" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-main",
                            { staticStyle: { "background-color": "#acacac" } },
                            [
                              _c("vms-message-editor", {
                                ref: "vmsMessageEditor",
                                attrs: {
                                  vmsMessageSize: _vm.formData.vmsMessageSize,
                                  type: "figure"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("el-aside", { attrs: { width: "360px" } }, [
            _c("div", { staticClass: "info-panel" }, [
              _c(
                "ul",
                { staticClass: "vms-library" },
                _vm._l(_vm.vmsLibraries, function(item, index) {
                  return _c("li", { key: index }, [
                    _c("img", {
                      staticClass: "vmsLibrary-thumb-img",
                      attrs: { src: _vm.getThumbImg(item) },
                      on: {
                        dblclick: function($event) {
                          return _vm.addImageObject(item)
                        }
                      }
                    })
                  ])
                }),
                0
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("저장")]
          ),
          _c("el-button", { on: { click: _vm.hideDialog } }, [_vm._v("취소")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }