<template>
	<div class="fill-width-height">
		<el-row class="page-body content-wrap">
			<dk-page-header></dk-page-header>
			<el-col :span="24" class="page-content">
				<el-table :data="features" empty-text="등록된 기능모듈 정보가 없습니다." size="mini">
					<el-table-column label="기능모듈 ID" prop="featureId"></el-table-column>
					<el-table-column label="모듈 명칭" prop="moduleName"></el-table-column>
					<el-table-column label="라우팅 URL" prop="url"></el-table-column>
					<el-table-column prop="icon" label="아이콘" width="80">
						<template slot-scope="scope">
							<i :class="scope.row.icon"></i>
						</template>
					</el-table-column>
					<el-table-column label="컴포넌트 경로" prop="componentPath"></el-table-column>
					<el-table-column width="250">
						<template slot-scope="scope">
							<el-button size="mini" v-if="!scope.row.editable" @click="editRow(scope.$index)">수정</el-button>
							<el-button size="mini" type="danger" @click="deleteRow(scope.$index)">삭제</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	data() {
		return {
			features: [],
		};
	},
	created() {
		this.getFeature();
	},
	methods: {
		async getFeature() {
			let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/feature`, {});
			this.features = response.data;
		},
		editRow(index) {},
		deleteRow(index) {},
	},
};
</script>

<style scoped></style>
