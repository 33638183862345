var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fill-width-height el-container",
      attrs: { id: "pageAssetManage" }
    },
    [
      _c(
        "el-container",
        { staticClass: "page-body" },
        [
          _c(
            "el-main",
            [
              _c(
                "el-row",
                {
                  staticClass: "content-wrap",
                  staticStyle: { height: "100%" }
                },
                [
                  _c(
                    "dk-page-header",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "icon-button-2",
                          attrs: { size: "small" },
                          on: { click: _vm.assetExportExcel }
                        },
                        [
                          _c("img", {
                            staticStyle: { height: "12px" },
                            attrs: {
                              src: require("../assets/img/excel_icon.png")
                            }
                          }),
                          _vm._v("엑셀 출력\n          ")
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "icon-button-2",
                          staticStyle: { "margin-right": "5px" },
                          attrs: { size: "small" },
                          on: { click: _vm.addAutoAsset }
                        },
                        [_vm._v("리얼허브 연동")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticClass: "data-wrap", attrs: { span: 24 } },
                    [
                      _c("div", { staticClass: "template-list" }, [
                        _c(
                          "ul",
                          { staticClass: "asset-sidemenu" },
                          _vm._l(_vm.templateList, function(item, idx) {
                            return _c(
                              "li",
                              {
                                key: idx,
                                staticClass: "asset-sidemenu__item",
                                on: {
                                  click: function($event) {
                                    return _vm.selectTemplate(item)
                                  }
                                }
                              },
                              [
                                _c("div", [
                                  _c("i", {
                                    class: [
                                      item.templateIcon ? item.templateIcon : ""
                                    ]
                                  }),
                                  _c("div", [_vm._v(_vm._s(item.templateName))])
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      ]),
                      _c("div", { staticClass: "asset-data-wrap" }, [
                        this.selectedTemplate
                          ? _c(
                              "div",
                              {
                                ref: "assetDataGrid",
                                staticClass: "asset-data-grid"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "asset-data-header" },
                                  [
                                    _c("span", { staticClass: "title" }, [
                                      _c("h3", [
                                        _vm._v(
                                          _vm._s(
                                            this.selectedTemplate.templateName
                                          )
                                        )
                                      ])
                                    ]),
                                    _c(
                                      "span",
                                      { staticClass: "button-wrap" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "primary" },
                                            on: { click: _vm.addAsset }
                                          },
                                          [_vm._v("자산정보 추가")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.assetList,
                                      height: _vm.gridHeight,
                                      lazy: ""
                                    }
                                  },
                                  [
                                    _vm._l(
                                      this.selectedTemplate.templateData,
                                      function(header, idx) {
                                        return _c("el-table-column", {
                                          key: idx,
                                          attrs: {
                                            prop: header.dataKey,
                                            label: header.label
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function(scope) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.viewDialog(
                                                              scope.row
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        (scope.row[
                                                          header.dataKey
                                                        ] &&
                                                          scope.row[
                                                            header.dataKey
                                                          ].type ===
                                                            "string") ||
                                                        (scope.row[
                                                          header.dataKey
                                                        ] &&
                                                          scope.row[
                                                            header.dataKey
                                                          ].type ===
                                                            "number") ||
                                                        (scope.row[
                                                          header.dataKey
                                                        ] &&
                                                          scope.row[
                                                            header.dataKey
                                                          ].type === "select")
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    scope.row[
                                                                      header
                                                                        .dataKey
                                                                    ].value
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ])
                                                          : scope.row[
                                                              header.dataKey
                                                            ] &&
                                                            scope.row[
                                                              header.dataKey
                                                            ].type === "date"
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.getFormatDate(
                                                                      scope.row[
                                                                        header
                                                                          .dataKey
                                                                      ].value
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ])
                                                          : _c("span")
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      }
                                    ),
                                    _c("el-table-column", {
                                      attrs: { width: "160" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(scope) {
                                              return [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { size: "mini" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.updateAsset(
                                                          scope.row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("수정")]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: {
                                                      size: "mini",
                                                      type: "danger"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteAsset(
                                                          scope.row
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("삭제")]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2439916757
                                      )
                                    })
                                  ],
                                  2
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("form-asset-template", {
        ref: "formAssetTemplate",
        on: { registerdTemplate: _vm.getTemplates }
      }),
      _c("form-asset-dialog", {
        ref: "formAssetDialog",
        on: { registeredAssetData: _vm.getAssets }
      }),
      _c("form-admin-dialog", { ref: "formAssetAdminDialog" }),
      _c(
        "el-dialog",
        {
          attrs: {
            id: "viewDialog",
            visible: _vm.formDialog,
            title: _vm.formData.templateName
          },
          on: {
            "update:visible": function($event) {
              _vm.formDialog = $event
            },
            close: _vm.hideDialog
          }
        },
        [
          _c("dk-form", {
            ref: "dkTemplateForm",
            on: { renderAfter: _vm.createTemplateForm }
          }),
          _c(
            "div",
            { staticClass: "dk-form" },
            [
              _c(
                "el-form",
                {
                  attrs: {
                    "label-position": "left",
                    "label-width": "165px",
                    size: "small"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "관련 자료" } },
                    _vm._l(this.formData.files, function(file, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                _vm.$config.getServerConfig().image.url +
                                file.serverFilePath
                            }
                          },
                          [
                            _c("i", { staticClass: "el-icon-document" }),
                            _vm._v(_vm._s(file.name))
                          ]
                        )
                      ])
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.formDialog = false
                    }
                  }
                },
                [_vm._v("닫기")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            id: "targetDeleteFcltDialog",
            visible: _vm.targetDeleteFcltDialog,
            title: "삭제 대상 시설물 목록"
          },
          on: {
            "update:visible": function($event) {
              _vm.targetDeleteFcltDialog = $event
            }
          }
        },
        [
          _c("h4", [
            _vm._v(
              "삭제 대상 시설물 개소: " +
                _vm._s(_vm.targetDeleteFcltList.length) +
                "개소"
            )
          ]),
          _c("h5", [
            _vm._v(
              "아래의 시설물 목록은 연동목록에 포함되지 않은 시설물에 해당됩니다. 삭제 버튼 클릭 시 삭제됩니다."
            )
          ]),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.targetDeleteFcltList,
                size: "mini",
                stripe: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "fcltName", label: "시설물 명칭" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "fcltTypeName",
                  label: "시설물 유형",
                  width: "200"
                }
              })
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.targetDeleteFclt }
                },
                [_vm._v("삭제")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.targetDeleteFcltDialog = false
                    }
                  }
                },
                [_vm._v("닫기")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }