<template>
  <el-dialog id="formAssetAdminDialog" :visible.sync="formAdminDialog" :title="formTitle">
    <div class="data-wrap">
      <div class="admin-template-list">
        <ul class="admin-asset-sidemenu">
          <li class="admin-asset-sidemenu__item" v-for="(item, idx) in templateList" :key="idx" @click="selectTemplate(item)">
            <div>
              <i v-bind:class="[item.templateIcon ? item.templateIcon : '']"></i>
              <div>{{ item.templateName }}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="asset-data-wrap">
        <div class="asset-data-grid" ref="assetDataGrid">
          <div class="asset-data-header">
            <el-button size="mini" @click="addTemplate">템플릿 추가</el-button>
            <el-button size="mini">템플릿 수정</el-button>
            <el-button size="mini">템플릿 삭제</el-button>
          </div>
          <div v-if="assetView">
            <el-table :data="assetList" style="width: 100%;" lazy>
              <el-table-column v-for="(header, idx) in this.selectedTemplate.templateData" :key="idx" :prop="header.dataKey" :label="header.label">
                <template slot-scope="scope">
                  <div @click="viewDialog(scope.row)">
                    <span
                      v-if="
                        (scope.row[header.dataKey] && scope.row[header.dataKey].type === 'string') ||
                        (scope.row[header.dataKey] && scope.row[header.dataKey].type === 'number') ||
                        (scope.row[header.dataKey] && scope.row[header.dataKey].type === 'select')
                      "
                    >
                      {{ scope.row[header.dataKey].value }}
                    </span>
                    <span v-else-if="scope.row[header.dataKey] && scope.row[header.dataKey].type === 'date'">
                      {{ getFormatDate(scope.row[header.dataKey].value) }}
                    </span>
                    <span v-else> </span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-else>
            <div class="dk-form">
              <el-form label-position="left" :model="formData" label-width="170px" size="small" :rules="rules" ref="form0">
                <el-form-item label="템플릿 명칭" prop="templateName">
                  <el-input v-model="formData.templateName" size="mini" placeholder="템플릿 명칭"></el-input>
                </el-form-item>
                <el-form-item label="템플릿 아이콘">
                  <el-col :span="11">
                    <span v-if="!formData.templateIcon">선택된 마커가 없습니다.</span>
                    <i v-bind:class="[formData.templateIcon ? formData.templateIcon + ' fa-3x' : '']"></i>
                  </el-col>
                  <el-col :span="13">
                    <span><el-button @click="tampleteMarkerDialog = !tampleteMarkerDialog">아이콘 선택</el-button></span>
                  </el-col>
                </el-form-item>
              </el-form>
              <el-dialog width="40%" :visible.sync="tampleteMarkerDialog" append-to-body title="템플릿 아이콘 선택" class="el-dialog-inner">
                <el-row>
                  <el-button size="small" @click="selectTemplateMarker('fas fa-server')">
                    <i class="fas fa-server fa-3x"></i>
                  </el-button>
                  <el-button size="small" @click="selectTemplateMarker('fas fa-building')">
                    <i class="fas fa-building fa-3x"></i>
                  </el-button>
                  <el-button size="small" @click="selectTemplateMarker('fas fa-cogs')">
                    <i class="fas fa-cogs fa-3x"></i>
                  </el-button>
                  <el-button size="small" @click="selectTemplateMarker('fas fa-cloud')">
                    <i class="fas fa-cloud fa-3x"></i>
                  </el-button>
                  <el-button size="small" @click="selectTemplateMarker('fas fa-network-wired')">
                    <i class="fas fa-network-wired fa-3x"></i>
                  </el-button>
                  <el-button size="small" @click="selectTemplateMarker('fas fa-hdd')">
                    <i class="fas fa-hdd fa-3x"></i>
                  </el-button>
                  <el-button size="small" @click="selectTemplateMarker('fas fa-tools')">
                    <i class="fas fa-tools fa-3x"></i>
                  </el-button>
                </el-row>
              </el-dialog>
            </div>
            <dk-form ref="dkTemplateForm" formType="template" @renderAfter="createTemplateForm"></dk-form>
            <span slot="footer" class="dialog-footer">
              <el-button type="primary" @click="saveTemplateData">저장</el-button>
            </span>
          </div>
          <!-- <el-table :data="tableData" style="width: 100%;">
            <el-table-column prop="date" label="Date" width="180"> </el-table-column>
            <el-table-column prop="name" label="Name" width="180"> </el-table-column>
            <el-table-column prop="address" label="Address"> </el-table-column>
          </el-table> -->
        </div>
      </div>
      <!-- <form-asset-template ref="formAssetTemplate" @registerdTemplate="getTemplates" /> -->
    </div>
  </el-dialog>
  <!-- <div class="dk-form">
      <el-form label-position="left" label-width="165px" size="small">
        <el-form-item label="관련 자료">
          <dk-el-file-upload ref="uploadFile" path="asset" :fileList="formData.files" :multiple="true">
            <div style="text-align: left;">
              <el-button size="small" type="primary">관련자료 업로드</el-button>
              <div slot="tip" class="el-upload__tip">자산 정보에 관련된 자료를 업로드 할 수 있습니다.</div>
            </div>
          </dk-el-file-upload>
        </el-form-item>
      </el-form>
    </div> -->
</template>

<script>
import formAssetTemplate from '@/core/forms/formAssetTemplate.vue';
import dkTemplateForm from '@/components/dkTemplate/dkTemplateForm';
import moment from 'moment';
export default {
  components: {
    'form-asset-template': formAssetTemplate,
    'dk-form': dkTemplateForm,
  },
  data() {
    return {
      assetView: true,
      formTitle: '템플릿 관리',
      formAdminDialog: false,
      templateList: [],
      selectedTemplate: '',
      assetList: [],
      templateData: [],
      tampleteMarkerDialog: false,
      formData: {
        templateName: '',
        templateIcon: '',
        templateIconName: '',
        featureId: this.$route.meta.featureId,
      },
      rules: {
        templateName: [
          {
            required: false,
            message: '템플릿 명칭을 입력하여 주십시오',
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
  created() {},
  async mounted() {},
  methods: {
    showDialog(data) {
      this.formAdminDialog = true;
      this.templateList = data;
    },
    selectTemplate(templateData) {
      this.assetView = true;
      this.selectedTemplate = templateData;
      this.getAssets();
    },
    selectTemplateMarker(fcltIcon) {
      this.tampleteMarkerDialog = false;
      this.formData.templateIcon = fcltIcon;
    },
    addTemplate() {
      this.assetView = false;
    },
    saveTemplateData() {
      // console.log(this.templateData);
      try {
        this.$refs.form0.validate(async (valid) => {
          if (valid) {
            let saveFunc = 'template/add';
            if (this.updateFlag) {
              saveFunc = 'template/update';
            }
            // this.systemLogInfo.page = `${this.$route.name} ${this.formData.templateName} 템플릿`;
            // this.systemLogInfo.saveType = saveFunc;
            this.formData.templateData = this.$refs.dkTemplateForm.exportTemplateData();
            const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, { data: this.formData });
            this.hideDialog();
            this.$message({
              type: 'success',
              message: `템플릿 정보 등록 완료`,
            });
            // await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
          }
        });
      } catch (err) {
        this.hideDialog();
        this.$message.error(`템플릿 정보 등록 중 에러 발생`);
      }
    },
    async getAssets() {
      const param = {
        templateId: this.selectedTemplate.templateId,
      };
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/asset`, {
        params: param,
      });
      this.assetList = response.data;
    },
    getFormatDate(timestamp) {
      return moment.unix(timestamp / 1000).format('YYYY-MM-DD');
    },
    createTemplateForm() {
      if (this.$refs.dkTemplateForm) {
        this.$refs.dkTemplateForm.createTemplateForm(this.templateData);
      }
    },
    hideDialog() {
      this.templateData = [];
      this.formData = {
        templateName: '',
        templateData: [],
        featureId: this.$route.meta.featureId,
      };
      this.formDialog = false;
      this.assetView = true;
      this.$refs.dkTemplateForm.resetForm();
    },
  },
};
</script>
