var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drag-container" }, [
    _c(
      "ul",
      { staticClass: "drag-list" },
      _vm._l(_vm.stages, function(stage) {
        var _obj
        return _c(
          "li",
          {
            key: stage.status,
            staticClass: "drag-column",
            class:
              ((_obj = {}), (_obj["drag-column-" + stage.status] = true), _obj)
          },
          [
            _c(
              "span",
              { staticClass: "drag-column-header" },
              [
                _vm._t(stage, [_c("h2", [_vm._v(_vm._s(stage.label))])]),
                stage.status == _vm.firstStageStatus
                  ? _c(
                      "el-button",
                      {
                        staticStyle: { "margin-left": "3px" },
                        attrs: {
                          type: "primary",
                          size: "small",
                          icon: "fas fa-plus"
                        },
                        on: {
                          click: function($event) {
                            return _vm.addBlock(stage)
                          }
                        }
                      },
                      [_vm._v(" 추가")]
                    )
                  : _vm._e()
              ],
              2
            ),
            _c("div", { staticClass: "drag-options" }),
            _c(
              "ul",
              {
                ref: "list",
                refInFor: true,
                staticClass: "drag-inner-list",
                attrs: { "data-status": stage.status }
              },
              _vm._l(_vm.getBlocks(stage.status), function(block) {
                return _c(
                  "li",
                  {
                    key: block._id,
                    class: _vm.getImportanceClass(block),
                    attrs: { "data-block-id": block._id }
                  },
                  [_vm._t(block._id)],
                  2
                )
              }),
              0
            )
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }