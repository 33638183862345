<template>
  <div class="fill-width-height" id="pageConfigure">
    <el-row class="page-body content-wrap">
      <dk-page-header></dk-page-header>
      <el-col :span="24" class="page-content">
        <el-row class="center-align">
          <el-col :md="16" :sm="24" class="">
            <div class="dk-card logo-wrap">
              <img :src="getLogo" />
            </div>
          </el-col>
          <el-col :md="16" :sm="24" class="">
            <div>
              <table class="dk-table">
                <colgroup>
                  <col style="width: 20%;" />
                  <col style="width: 20%;" />
                  <col style="width: auto;" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scople="row">기관 명칭</th>
                    <td colspan="2">{{ configure.orgName }}</td>
                  </tr>
                  <tr>
                    <th scople="row">플랫폼 타이틀</th>
                    <td colspan="2">{{ configure.title }}</td>
                  </tr>
                  <tr>
                    <th scople="row">센터 연락처</th>
                    <td colspan="2">{{ configure.phone }}</td>
                  </tr>
                  <tr>
                    <th rowspan="4" scople="rowgroup">GIS 정보</th>
                    <th scope="row" class="th-sub">OPEN API 종류</th>
                    <td>{{ configure.gis.gisName }}</td>
                  </tr>
                  <tr>
                    <th scope="row" class="th-sub">API KEY</th>
                    <td>{{ configure.gis.apiKey }}</td>
                  </tr>
                  <tr>
                    <th scope="row" class="th-sub">기본 위도</th>
                    <td>{{ configure.gis.geometry.lat }}</td>
                  </tr>
                  <tr>
                    <th scope="row" class="th-sub">기본 경도</th>
                    <td>{{ configure.gis.geometry.lng }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-col>
          <el-col :md="16" :sm="24" class="" style="margin-top: 30px; text-align: center;">
            <el-button @click="editConfigure">
              <h3>기관 정보 수정</h3>
            </el-button>
          </el-col>
        </el-row>
        <!-- <div v-else style="padding-top:70px; text-align:center;">
                <div style="margin-top:40px;">
                    <el-button type="danger"
                        @click="editConfigure"
                    >
                        <h2>등록된 기관 정보가 없습니다</h2>
                        <h4>정보 입력</h4>
                    </el-button>                        
                </div>
        </div>-->
      </el-col>
    </el-row>
    <form-configure ref="formDialog"></form-configure>
  </div>
</template>

<script>
import formconfigure from '@/core/forms/formConfigure.vue';

export default {
  components: {
    'form-configure': formconfigure,
  },
  data() {
    return {
      configure: {
        orgName: '',
        phone: '',
        title: '',
        gis: {
          apiKey: '',
          gisName: '',
          geometry: {
            lat: '',
            lng: '',
          },
        },
      },
    };
  },
  computed: {
    getLogo() {
      if (this.configure.logo && this.configure.logo.length > 0)
        return this.$config.getServerConfig().image.url + this.configure.logo[0].serverFilePath;
      else 
        return "";
      
    }
  },
  created() {
    let me = this;
    this.$on('procDataComplete', (data) => {
      window.location.href = this.$config.getServerConfig().baseUrl;
    });
    this.$store.dispatch('configure/getConfigure', {}).then(() => {
      if (!me.$store.getters['configure/getConfigure']) {
        me.configure = null;
      } else {
        me.configure = me.$store.getters['configure/getConfigure'];
      }
    });
  },
  methods: {
    editConfigure() {
      if (!this.configure) {
        this.$refs.formDialog.showDialog();
      } else {
        this.$refs.formDialog.showDialog(this.configure);
      }
    },
  },
};
</script>
