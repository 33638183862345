<template>
  <el-dialog :visible.sync="dialog" :close-on-click-modal="false" top="5vh" append-to-body title="유지관리 작업 내역">
    <div>
      <div class="button-wrap">
        <el-button @click="renderPdf" type="primary">PDF로 출력</el-button>
      </div>
      <div class="maintnce-page-wrap">
        <div v-for="(item, key) in exportData" :key="key" :id="`maintncePage${key}`" class="maintnce-page">
          <div class="maintnce-page-header">
            <h3>유지관리 작업 내역</h3>
            <span>
              <img :src="$config.getServerConfig().image.url + $config.getSiteConfig().logo[0].serverFilePath" />
            </span>
          </div>
          <table class="dk-table dk-table-no-padding">
            <colgroup>
              <col style="width: 20%;" />
              <col style="width: 30%;" />
              <col style="width: 20%;" />
              <col style="width: 30%;" />
            </colgroup>
            <tbody>
              <tr>
                <th>시설물 명칭</th>
                <td>{{ item.fcltName }}</td>
                <th colspan="2" rowspan="8" style="padding: 0;">
                  <dk-gis
                    :gisApiInfo="gisApiInfo"
                    :markerData="markerData[key]"
                    :ref="`dkGis${key}`"
                    style="height: 328px;"
                    :id="`dkGis${key}`"
                    :center="center[key]"
                    :level="level"
                  ></dk-gis>
                </th>
              </tr>
              <tr>
                <th>시설물 유형</th>
                <td>{{ item.fcltTypeName }}</td>
              </tr>
              <tr>
                <th scople="row">시작일</th>
                <td>{{ getFormatDate(item.startDate) }}</td>
              </tr>
              <tr>
                <th scople="row">종료일</th>
                <td>{{ getFormatDate(item.endDate) }}</td>
              </tr>
              <tr>
                <th scople="row">작업완료 예정일</th>
                <td>{{ getFormatDate(item.completePlanDate) }}</td>
              </tr>
              <tr>
                <th scople="row">담당자</th>
                <td>{{ item.managerName }}({{ item.managerPhone }})</td>
              </tr>
              <tr>
                <th scople="row">작업자</th>
                <td>{{ item.workerName }}({{ item.workerPhone }})</td>
              </tr>
              <tr>
                <th scople="row">진행상태</th>
                <td>{{ item.statusText }}</td>
              </tr>
              <tr>
                <th scople="row">작업유형</th>
                <td>{{ item.workTypeText }}</td>
                <th scople="row">중요도</th>
                <td>{{ item.importanceText }}</td>
              </tr>
              <tr>
                <th scople="row">내용 요약</th>
                <td colspan="3">{{ item.maintnceSummary }}</td>
              </tr>
              <tr>
                <th scople="row">내용 설명</th>
                <td colspan="3" v-html="parseHtmlContent(item.maintnceDesc)"></td>
              </tr>
              <tr>
                <th scople="row">작업 사진</th>
                <td colspan="3">
                  <div class="maintnce-image-wrap">
                    <div class="maintnce-image" v-for="(file, index) in item.files" :key="index">
                      <div :style="`background-image:url(${file.serverFilePath})`"></div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import dkgismain from '@/components/dkGis/dkGisMain.vue';

export default {
  data() {
    return {
      dialog: false,
      exportData: [],
      defaultGisApiInfo: null,
      markerData: [],
      center: [],
      level: 5,
      loading: null,
    };
  },
  components: {
    'dk-gis': dkgismain,
  },
  created() {
    window.html2canvas = html2canvas;
    this.gisApiInfo = this.$store.getters['gis/getGisApi'];
  },
  methods: {
    async showDialog(param) {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/maintnceLog/pdf`, {
        params: param,
      });
      this.exportData = response.data && response.data.docs ? response.data.docs : [];

      for (const item of this.exportData) {
        let marker = [
          {
            seq: 0,
            draggable: false,
            alwaysActivate: true,
            geometry: {
              lat: item.gisLat,
              lng: item.gisLng,
            },
            properties: {
              fcltName: item.fcltName,
            },
          },
        ];
        let center = {
          lat: item.gisLat,
          lng: item.gisLng,
        };
        this.markerData.push(marker);
        this.center.push(center);
      }
      this.dialog = true;
    },
    getFormatDate(date) {
      if (date) return moment(date).format('YYYY-MM-DD LT');
      else return '';
    },
    parseHtmlContent(content) {
      if (content) return content.replace(/(\n|\r\n)/g, '<br>');
      else return null;
    },
    async renderPdf() {
      this.loading = this.$loading({
        lock: true,
        text: 'PDF문서를 렌더링 중입니다. 자료가 많으면 오래걸릴 수 있습니다.',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      let doc = new jsPDF('p', 'mm');
      let pageWidth = doc.internal.pageSize.getWidth();
      let pageHeight = doc.internal.pageSize.getHeight();
      let position = 0;
      let idx = 0;

      for (const item of this.exportData) {
        let el = document.querySelector(`#maintncePage${idx}`);
        // let proxyUrl = `${this.$config.getServerConfig().core.api}/html2canvas-proxy`;
        let proxyUrl = `/html2canvas-proxy`;
        try {
          let layers = document.querySelector(`#dkGis${idx}`).children[0].children[0].children[0].children[0];
          layers.children[5].style.zIndex = 100;
          let canvas = await window.html2canvas(el, {
            logging: true,
            proxy: proxyUrl,
            allowTaint: false,
          });
          const imgData = canvas.toDataURL('image/png');
          const imgProps = doc.getImageProperties(imgData);
          let imgWidth = pageWidth - 10;
          let imgHeight = (imgProps.height * imgWidth) / imgProps.width;
          if (idx > 0) {
            doc.addPage();
          }
          doc.addImage(imgData, 5, 10, imgWidth, imgHeight);
        } catch (err) {
          console.error(err);
        }
        idx++;
      }

      doc.save(`유지관리작업내역_${moment(new Date()).format('YYYY-MM-DD LT')}.pdf`);
      this.loading.close();
    },
  },
};
</script>

<style scoped>
.button-wrap {
  padding: 10px;
}

.maintnce-page-wrap {
  height: 600px;
  overflow-y: auto;
  width: 100%;
}

.maintnce-page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.maintnce-page-header h3 {
  font-size: 20px;
}

.maintnce-page-header span {
  display: inline-block;
  height: 30px;
  margin: 15px 0;
}

.maintnce-page-header span > img {
  height: 30px;
  margin-right: 10px;
  width: auto;
}

.maintnce-page {
  margin-bottom: 30px;
}
.maintnce-image-wrap {
  display: flex;
}
.maintnce-image {
  box-sizing: border-box;
  float: left;
  height: 200px;
  padding: 10px;
  width: 50%;
}
.maintnce-image > div {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}
</style>
