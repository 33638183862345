import axios from 'axios'
import {store} from '../../store/index'

export default class dkGroupCRUD {
  constructor() {

  }

  Mcreate(payload) {
    console.log(`groupCreate`);
    const params = {
      data: {
        ...payload
      }
    };
    return new Promise((resolve, reject) => {
      axios.post(`${store.getters.getCoreApi}/api/groupMaker`, params).then(response => {
        resolve(response.data);
      })
        .catch(error => {
          reject(error.message);
        })
    }).then(response => {
      return response;
    })
      .catch(error => {
        console.log(error);
      })
  }

  Mread() {
    console.log(`groupRead`);
    return new Promise((resolve, reject) => {
      axios.post(`${store.getters.getCoreApi}/api/groupReader`).then(response => {
        resolve(response.data);
      })
        .catch(error => {
          reject(error.message);
        })
    }).then(response => {
      return response
    }).catch(error => {
      console.log(error)
    })
  }

  Mupdate(payload) {
    console.log(`groupUpdate`);
    const params = {
      data: {
        ...payload
      }
    };
    return new Promise((resolve, reject) => {
      axios.post(`${store.getters.getCoreApi}/api/groupUpdater`, params).then(response => {
        resolve(response.data);
      })
        .catch(error => {
          reject(error.message);
        })
    }).then(response => {
      return response;
    }).catch(error => {
      console.log(error);
    })
  }

  Mdelete(payload) {
    console.log(`groupDelete`);
    const params = {
      data: {
        ...payload
      }
    };
    return new Promise((resolve, reject) => {
      axios.post(`${store.getters.getCoreApi}/api/groupDeleter`, params).then(response => {
        resolve(response.data);
      }).catch(error => {
        reject(error.message);
      })
    }).then(response => {
      return response;
    }).catch(error => {
      console.log(error);
    })
  }
}
