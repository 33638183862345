<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    class="el-dialog-aside vms-editor"
    width="1100px"
    top="5vh"
    id="vmsFigureSchedule"
  >
    <div>
      <el-container class="dialog-container">
        <el-container class="vms-schedule-wrap">
          <el-form
            :model="formData"
            label-width="120px"
            size="medium"
            :rules="rules"
            ref="form0"
            :label-position="$isMobile ? 'top' : 'right'"
            style="position: relative;"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="스케줄 이름" prop="vmsScheduleName">
                  <el-input v-model="formData.vmsScheduleName" placeholder="VMS 스케줄 이름"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="VMS 크기" prop="vmsMessageSize">
                  <el-select v-model="formData.vmsMessageSize" placeholder="VMS 크기" @change="changeVmsMessageSize">
                    <el-option label="15단20열" value="15x20"></el-option>
                    <el-option label="10단18열" value="10x18"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="vms-schedule-body">
            <div class="dk-card" v-for="(item, index) in formData.vmsMessages" :key="index">
              <el-row>
                <el-col :span="2">
                  <el-button type="primary" size="mini" class="button-swap-message" style="margin-bottom: 5px;" @click="goToUp(index, item)"
                    >▲</el-button
                  >
                  <br />
                  <el-button type="primary" size="mini" class="button-swap-message" @click="goToDown(index, item)">▼</el-button>
                </el-col>
                <el-col :span="7">
                  <img :src="item.thumbImageFile" style="height: auto; width: 100%;" />
                </el-col>
                <el-col :span="10" style="padding: 5px 8px;">
                  표출시간
                  <el-input-number
                    v-model="item.displayTime"
                    :min="1"
                    size="small"
                    controls-position="right"
                    style="margin-left: 10px; margin-top: 5px;"
                  ></el-input-number>
                </el-col>
                <el-col :span="5" style="text-align: right;">
                  <el-button type="danger" style="margin-top: 13px;" @click="removeVmsMessage(index)">삭제</el-button>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-container>
        <el-aside width="500px">
          <div class="info-panel" v-if="vmsMessagesBySize.length > 0">
            <el-table
              :data="vmsMessagesBySize"
              style="border-top: 1px solid #dedede; width: 100%;"
              empty-text="등록된 VMS 메시지가 없습니다."
              size="mini"
              stripe
              :show-header="false"
              :height="500"
            >
              <el-table-column min-width="100px">
                <template slot-scope="scope">
                  <img :src="scope.row.thumbImageFile" style="height: auto; width: 100%;" />
                </template>
              </el-table-column>
              <el-table-column prop="vmsMessageName" label="VMS 메시지 이름" min-width="100px"></el-table-column>
              <el-table-column label="VMS 종류" prop="vmsMessageSize" header-align="center" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.vmsMessageSize == '15x20'">15단 20열</span>
                  <span v-else-if="scope.row.vmsMessageSize == '10x18'">10단 18열</span>
                  <span v-else>기타</span>
                </template>
              </el-table-column>
              <el-table-column label width="80px">
                <template slot-scope="scope">
                  <el-button @click="addVmsMessage(scope.row)" size="small" type="primary">추가</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div style="height: 450px; text-align: center;" v-else>
            <h3 style="margin-top: 50px;">VMS 크기를 선택하세요</h3>
          </div>
        </el-aside>
      </el-container>
    </div>
    <div class="form-bottom" style="padding: 0 8px 8px 8px;">
      <el-button @click="prev" style="float: left;" v-show="step > 0"> <i class="fas fa-angle-left"></i>&nbsp;&nbsp;이전 </el-button>
      <el-button @click="next" style="float: right;" v-show="step < stepData.length - 1">
        다음&nbsp;&nbsp;
        <i class="fas fa-angle-right"></i>
      </el-button>
      <el-button @click="save" style="float: right;" v-show="step === stepData.length - 1">
        저장&nbsp;&nbsp;
        <i class="fas fa-angle-right"></i>
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import svgviewer from './vmsMessageSvgViewer.vue';
export default {
  components: {
    'vms-message-svg-viewer': svgviewer,
  },
  data() {
    return {
      vmsMessages: [],
      step: 0,
      stepData: [
        { idx: 0, title: 'VMS 스케줄 작성' },
        { idx: 1, title: 'VMS 정보제공구간 할당' },
      ],
      formData: {
        vmsScheduleName: '',
        vmsMessages: [],
        vmsMessageSize: '15x20',
        vmsType: '2',
      },
      //   limit: 8,
      totalDocs: 0,
      rules: {
        vmsScheduleName: [
          {
            required: true,
            message: 'VMS 스케줄 이름을 입력해 주세요',
            trigger: 'blur',
          },
        ],
        vmsMessageSize: [
          {
            required: true,
            message: 'VMS 크기를 선택 해 주세요',
            trigger: 'blur',
          },
        ],
      },
      formTitle: '',
      formDialog: false,
      updateFlag: false,
      selectVmsMessageSize: '',
      selectVmsMessage: null,
      selectVmsLineObject: null,
      vmsIfscInfo: [],
      vmsIfscKeyword: '',
    };
  },
  mounted() {
    this.getVmsMessages();
  },
  computed: {
    vmsMessagesBySize() {
      if (this.vmsMessages && typeof this.vmsMessages === 'object') {
        let result = [];
        this.vmsMessages.map((msg) => {
          if (this.formData.vmsMessageSize !== '' && !!this.formData.vmsMessageSize) {
            if (msg.vmsMessageSize === this.formData.vmsMessageSize) {
              result.push(msg);
            }
          } else {
            result.push(msg);
          }
        });
        return result;
      } else {
        return [];
      }
    },
  },
  methods: {
    async getVmsMessages() {
      //   if (this.selectVmsMessageSize !== '' && !!this.selectVmsMessageSize) {
      //     param.vmsMessageSize = this.selectVmsMessageSize;
      //   }

      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsMessage`, {
        params: {
          vmsType: '2',
        },
      });
      this.vmsMessages = response.data.docs;
    },
    pagingProc() {
      this.getVmsMessages();
    },
    async showDialog(row) {
      if (row) {
        this.updateFlag = true;
        this.formTitle = 'VMS 스케줄 수정';
        this.formData = this.$lodash.cloneDeep(row);
      } else {
        this.updateFlag = false;
        this.formTitle = 'VMS 스케줄 추가';
        this.resetData();
      }
      this.formDialog = true;
      await this.getVmsMessages();
      await this.getVmsIfscInfo();
      this.$forceUpdate();
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    resetData() {
      this.vmsMessages = [];
      this.formData = {
        vmsScheduleId: -1,
        vmsScheduleName: '',
        vmsMessageSize: '15x20',
        vmsCategory: '',
        vmsMessages: [],
        vmsType: '2',
        seq: -1,
      };
      this.selectVmsMessage = null;
      this.selectVmsLineObject = null;
      this.vmsIfscKeyword = '';
      this.step = 0;
    },
    changeVmsMessageSize(val) {
      this.$confirm(`VMS 크기를 변경하면 입력 내용이 초기화 됩니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      }).then(() => {
        // this.vmsMessages = [];
        this.formData.vmsMessages = [];
        this.formData.vmsMessageSize = val;
        // this.getVmsMessages();
      });
    },
    next() {
      if (this.$refs[`form${this.step}`]) {
        this.$refs[`form${this.step}`].validate((valid) => {
          if (valid) {
            this.step++;
          } else {
            return false;
          }
        });
      } else {
        this.step++;
      }
    },
    prev() {
      // if (this.step == 1) {
      // }
      this.step--;
    },
    save() {
      let me = this;
      this.$refs.form0.validate(async (valid) => {
        if (valid) {
          let saveFunc = '/core/api/vmsSchedule/add';
          if (this.updateFlag) {
            saveFunc = '/core/api/vmsSchedule/update';
          }
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}${saveFunc}`, {
              data: this.formData,
            });
            me.$parent.$emit('procDataComplete', this.formData);
            me.resetData();
            me.hideDialog();
            me.$message({
              type: 'success',
              message: `VMS 스케줄 등록 완료`,
            });
          } catch (err) {
            me.resetData();
            me.hideDialog();
            me.$message.error(`VMS 스케줄 등록 중 에러 발생`);
          }
        }
      });
    },
    addVmsMessage(row) {
      // let chk = this.formData.vmsMessages.find((msg) => {
      //   return msg._id == row._id;
      // });

      let newMessage = this.$lodash.cloneDeep(row);
      newMessage.displayTime = 3;
      this.formData.vmsMessages.push(newMessage);
    },
    goToUp(index) {
      if (index > 0) {
        let tmp = this.formData.vmsMessages[index - 1];
        this.formData.vmsMessages[index - 1] = this.formData.vmsMessages[index];
        this.formData.vmsMessages[index] = tmp;
        this.$forceUpdate();
      }
    },
    goToDown(index) {
      if (index < this.formData.vmsMessages.length - 1) {
        let tmp = this.formData.vmsMessages[index + 1];
        this.formData.vmsMessages[index + 1] = this.formData.vmsMessages[index];
        this.formData.vmsMessages[index] = tmp;
        this.$forceUpdate();
      }
    },
    removeVmsMessage(index) {
      this.formData.vmsMessages.splice(index, 1);
      this.$forceUpdate();
    },
    checkVmsMessage(vmsMessage) {
      this.selectVmsMessage = vmsMessage;
      this.$forceUpdate();
    },
    selectVmsMessageClass(vmsMessageData) {
      if (this.selectVmsMessage && this.selectVmsMessage.vmsMessageId && this.selectVmsMessage.vmsMessageId === vmsMessageData.vmsMessageId) {
        return 'selected';
      } else {
        return '';
      }
    },
    setFillin(ifscInfo) {
      if (this.selectVmsMessage.isLineFillin && this.selectVmsLineObject && this.selectVmsLineObject.obj) {
        this.selectVmsLineObject.obj.setVmsIfscInfo(ifscInfo);
        this.selectVmsLineObject.data.vmsIfscInfo = ifscInfo;
        this.$forceUpdate();
      } else if (this.selectVmsMessage && this.selectVmsMessage.isFillin) {
        this.selectVmsMessage.vmsIfscInfo = ifscInfo;
        this.$forceUpdate();
      } else {
        this.$message({
          type: 'error',
          message: `정보제공 구간을 할당할 VMS 메시지를 선택하세요`,
        });
      }
    },
    focusMoveVmsIfsc(row) {
      // let focusLink = [];
      // row.linkData.forEach((ifsc) => {
      //   focusLink.push(ifsc.vms_ifsc_id);
      // });
      // this.$refs.dkGis.setLevel(7);
      // this.$refs.dkGis.focusTrafficLayer(focusLink);
    },
    async getVmsIfscInfo() {
      this.vmsIfscInfo = [];
      let param = {};
      if (this.vmsIfscKeyword !== '') {
        param.keyword = this.vmsIfscKeyword;
      }

      const result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsIfscInfo`, {
        params: param,
      });
      this.vmsIfscInfo = result.data.docs || [];

      // this.vmsIfscInfo = [];

      // return this.$store.dispatch('vmsIfscInfo/getVmsIfscInfo').then(() => {
      //   this.vmsIfscInfo = this.$store.getters['vmsIfscInfo/getVmsIfscInfo']
      //     ? this.$store.getters['vmsIfscInfo/getVmsIfscInfo']
      //     : [];
      // });
    },
    selectVmsLine(lineObject) {
      this.selectVmsLineObject = lineObject;
    },
  },
};
</script>

<style scoped></style>
