var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-width-height el-container" },
    [
      _c(
        "el-container",
        { staticClass: "page-body" },
        [
          _c(
            "el-main",
            [
              _c(
                "el-row",
                { staticClass: "content-wrap" },
                [
                  _c("dk-page-header"),
                  _c(
                    "el-col",
                    { staticClass: "page-content", attrs: { span: 24 } },
                    [
                      _c("dk-el-table-header", {
                        attrs: { openFormDialog: _vm.openFormDialog }
                      }),
                      _c(
                        "el-table",
                        {
                          attrs: {
                            data: _vm.centerEquips,
                            "empty-text": "등록된 센터 정보가 없습니다.",
                            size: "mini",
                            stripe: ""
                          },
                          on: { "cell-click": _vm.selectRow }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "센터 장비 명",
                              prop: "centerEquipName",
                              "header-align": "center",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "센터 장비 유형",
                              prop: "centerEquipTypeName",
                              width: "120px",
                              "header-align": "center",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "납품 업체",
                              prop: "supplyCompany",
                              "header-align": "center",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "납품 일자",
                              width: "140px",
                              "header-align": "center",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    scope.row.workStartDate
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getWriteDate(
                                                scope.row.workStartDate
                                              )
                                            )
                                          )
                                        ])
                                      : _c("span", [_vm._v("입력되지 않음")])
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "설치 업체",
                              prop: "installCompany",
                              "header-align": "center",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: { label: "", width: "170px" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "small" },
                                        on: {
                                          click: function($event) {
                                            return _vm.openFormDialog(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("수정")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "danger"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.remove(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("삭제")]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c("dk-el-pagination", {
                        ref: "pagination",
                        attrs: {
                          totalDocs: _vm.totalDocs,
                          limit: _vm.limit,
                          pagingProc: _vm.pagingProc
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("dk-info-panel", { ref: "infoPanel", attrs: { width: "440px" } }, [
        _c(
          "div",
          { staticClass: "info-panel-wrap" },
          [
            _c(
              "el-tabs",
              {
                model: {
                  value: _vm.activeTab,
                  callback: function($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab"
                }
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "센터 장비 정보", name: "tab1" } },
                  [
                    _c("h3", [_vm._v("센터 장비 정보")]),
                    _c("div", { staticClass: "dk-card" }, [
                      _c("table", { staticClass: "el-table el-table--mini" }, [
                        _c("tr", [
                          _c("th", { staticStyle: { width: "110px" } }, [
                            _vm._v("센터 장비 명")
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.selectCenterEquip.centerEquipName)
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticStyle: { width: "110px" } }, [
                            _vm._v("센터 장비 유형")
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.selectCenterEquip.centerEquipTypeName)
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticStyle: { width: "110px" } }, [
                            _vm._v("납품 업체")
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.selectCenterEquip.supplyCompany))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticStyle: { width: "110px" } }, [
                            _vm._v("납품 업체 연락처")
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.selectCenterEquip.supplyPhoneNumber)
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticStyle: { width: "110px" } }, [
                            _vm._v("납품 일자")
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm.getWriteDate(
                                  _vm.selectCenterEquip.supplyDate
                                )
                              )
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticStyle: { width: "110px" } }, [
                            _vm._v("설치 업체")
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.selectCenterEquip.installCompany))
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticStyle: { width: "110px" } }, [
                            _vm._v("설치 업체 연락처")
                          ]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.selectCenterEquip.installPhoneNumber)
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", { staticStyle: { width: "110px" } }, [
                            _vm._v("내용")
                          ]),
                          _c("td", [_vm._v(_vm._s(_vm.selectCenterEquip.text))])
                        ])
                      ])
                    ]),
                    _c("h4", [_vm._v("장비 상세 정보")]),
                    _vm._l(_vm.selectCenterEquip.equip, function(equip, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "dk-card" },
                        [
                          _c("div", { staticClass: "dk-card-title" }, [
                            _vm._v(_vm._s(equip.materialName))
                          ]),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 10 } }, [
                                equip.images && equip.images.length > 0
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "90%",
                                        height: "auto"
                                      },
                                      attrs: {
                                        src:
                                          _vm.$config.getServerConfig().image
                                            .url +
                                          equip.images[0].serverFilePath
                                      }
                                    })
                                  : _c("span", [_vm._v(" ")])
                              ]),
                              _c("el-col", { attrs: { span: 14 } }, [
                                _c(
                                  "table",
                                  { staticClass: "el-table el-table--mini" },
                                  [
                                    _vm._l(equip.materialTypeData, function(
                                      data,
                                      index
                                    ) {
                                      return _c("tr", { key: index }, [
                                        _c(
                                          "th",
                                          { staticStyle: { width: "100px" } },
                                          [_vm._v(_vm._s(data.label))]
                                        ),
                                        _c("td", [_vm._v(_vm._s(data.value))])
                                      ])
                                    }),
                                    _vm._l(equip.equipData, function(
                                      data,
                                      index
                                    ) {
                                      return _c("tr", { key: index }, [
                                        _c("th", [_vm._v(_vm._s(data.label))]),
                                        _c("td", [_vm._v(_vm._s(data.value))])
                                      ])
                                    })
                                  ],
                                  2
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "관련 자료", name: "tab2" } },
                  [
                    _c(
                      "div",
                      { staticClass: "dk-card" },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: _vm.selectCenterEquip.files,
                              "empty-text": "등록된 사업 관련 자료가 없습니다.",
                              size: "mini",
                              stripe: ""
                            },
                            on: { "row-click": _vm.downloadReference }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "파일 명" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "file-download-label" },
                                        [_vm._v(_vm._s(scope.row.name))]
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: { label: "파일 크기", width: "120px" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getHumanFileSize(scope.row.size)
                                        )
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("form-center-equip", { ref: "formDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }