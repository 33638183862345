<template>
  <div class="fill-width-height el-container" id="pageUserGroupInfo">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap" style="height: 100%;">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="group-wrap">
            <div class="group-list">
              <div class="group-header">
                <el-button size="small" @click="addUserGroup"> <i class="fas fa-plus" style="color: #1783f0;"></i>&nbsp;그룹 추가 </el-button>
              </div>
              <div v-for="(item, index) in groupList" :key="item.menuId" class="group-cell">
                <span class="group-name" @click="selectGroup(item)" :data-text="item.name">{{ item.name }}</span>
                <span class="group-button">
                  <el-button @click="updateUserGroup(item)" size="mini">수정</el-button>
                  <el-button
                    v-if="item.authLevel"
                    :disabled="item.userList && item.userList.length > 0 ? true : false"
                    @click="removeUserGroup(item)"
                    size="mini"
                    type="danger"
                    >삭제</el-button
                  >
                  <el-button v-else size="mini" type="danger" style="visibility: hidden;">삭제</el-button>
                </span>
              </div>
            </div>
            <div class="user-list">
              <div class="user-list-group">
                <div>
                  <span>
                    {{ selectedGroupInfo.name }}
                    {{ userListByGroup.length }}명
                  </span>
                </div>
                <span>
                  <el-select
                    v-model="targetUsers"
                    multiple
                    filterable
                    remote
                    reserve-keyword
                    placeholder="그룹 추가 대상 사용자 검색"
                    :remote-method="remoteMethod"
                    :loading="loading"
                  >
                    <el-option v-for="item in options" :key="item.userid" :label="item.name" :value="item.userid"></el-option>
                  </el-select>
                  <el-button type="primary" v-if="targetUsers.length > 0" @click="procUserGroup">그룹 변경</el-button>
                </span>
              </div>
              <el-table
                :data="userListByGroup"
                class="user-list-grid"
                style="width: 100%;"
                size="small"
                :default-sort="{ prop: 'userid', order: 'descending' }"
              >
                <el-table-column label width="80" header-align="center" align="center" fixed="left">
                  <template slot-scope="scope">
                    <span
                      class="user-list-thumb-img"
                      v-if="scope.row.images && scope.row.images.length > 0"
                      :style="'background-image:url(' + $config.getServerConfig().image.url + scope.row.images[0].serverFilePath + ')'"
                    ></span>
                    <span v-else class="user-list-thumb-img" style="background-image: url(static/images/unknown.png);"></span>
                  </template>
                </el-table-column>
                <el-table-column prop="userid" label="아이디" width="120" header-align="center" align="center" sortable></el-table-column>
                <el-table-column prop="name" label="이름" width="130" header-align="center" align="center" sortable></el-table-column>
                <el-table-column prop="phone" label="전화번호" width="150" header-align="center" align="center"></el-table-column>
                <el-table-column prop="email" label="이메일" header-align="center" align="center"></el-table-column>
              </el-table>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <form-user-group-info ref="formUserGroupInfo" @procDataComplete="procDataComplete"></form-user-group-info>
  </div>
</template>
<script>
import dkeltableheader from '@/components/dkElTableHeader.vue';
import formUserGroupInfo from '@/core/forms/formUserGroupInfo.vue';
import _ from 'lodash';

export default {
  components: {
    'dk-el-table-header': dkeltableheader,
    'form-user-group-info': formUserGroupInfo,
  },
  data() {
    return {
      systemLogInfo : {},
      userList: [],
      userListByGroup: [],
      groupList: [],
      activeUser: null,
      selectedGroupInfo: {
        name: '',
      },
      loading: false,
      options: [],
      targetUsers: [],
      refreshSelectGroupId: null,
    };
  },
  mounted() {
    this.getGroup();
  },
  created() {},
  methods: {
    async getGroup(preSelectedGroupId) {
      try {
        this.activeUser = this.$dkRouter.getUserInfo();
        let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/menus`, {
          params: this.activeUser,
        });
        if (result) {
          this.groupList = result.data;
          if (this.groupList.length > 0) {
            if (preSelectedGroupId) {
              let a = _.find(this.groupList, (gl) => {
                return gl.menuId === preSelectedGroupId;
              });
              if (a) {
                this.selectedGroupInfo = a;
              } else {
                this.selectedGroupInfo = this.groupList[0];
              }
            } else {
              this.selectedGroupInfo = this.groupList[0];
            }

            await this.getUserList({});
            this.filterUserListByGroup();
            this.selectGroup(this.selectedGroupInfo);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    async getUserList(args) {
      let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/user`, {
        params: args,
      });
      if (result) {
        this.userList = result.data.docs;
      }
    },
    filterUserListByGroup() {
      this.$lodash.map(this.groupList, (g) => {
        let userData = this.$lodash.filter(this.userList, (o) => {
          return g.menuId == o.group;
        });
        g.userList = userData;
      });
    },
    addUserGroup() {
      this.$refs.formUserGroupInfo.showDialog();
    },
    updateUserGroup(row) {
      this.$refs.formUserGroupInfo.showDialog(row);
    },
    selectGroup(row) {
      this.selectedGroupInfo = row;
      this.userListByGroup = row.userList;
    },
    removeUserGroup(row) {
      this.$confirm(`${row.name} 사용자 그룹을 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            this.systemLogInfo.page = `${this.$route.name} ${row.name}`
            let postUrl = `${this.$config.getServerConfig().core.api}/core/api/menu/delete`;
            let result = await this.$http.post(postUrl, { data: row });
            this.$message({
              type: 'success',
              message: `사용자 그룹 정보 삭제 완료`,
            });
            this.systemLogInfo.saveType = "delete"
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
            this.getGroup();
          } catch (err) {
            this.$message({
              type: 'danger',
              message: `${row.name} 사용자 그룹 삭제 중 오류발생`,
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: 'danger',
            message: `${row.name} 사용자 그룹 삭제 취소`,
          });
        });
    },
    procDataComplete(args) {
      this.getGroup();
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.userList.filter((item) => {
            return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 80);
      } else {
        this.options = [];
      }
    },
    procUserGroup() {
      let userGroupData = [];
      let userNames = [];
      _.map(this.targetUsers, (item) => {
        let a = _.find(this.userList, (u) => {
          return item === u.userid;
        });
        a.group = this.selectedGroupInfo.menuId;
        a.groupName = this.selectedGroupInfo.name;
        userGroupData.push(a);
        userNames.push(a.name);
      });

      this.$confirm(`${_.join(userNames, ',')} 이 사용자들의 그룹정보를 ${this.selectedGroupInfo.name}로 변경 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      }).then(async () => {
        try {
          let result = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/user/changeGroup`, { data: userGroupData });
          let preSelectedGroupId = this.selectedGroupInfo.menuId;
          this.$message({
            type: 'success',
            message: `사용자 그룹 정보 변경 완료`,
          });
          await this.getGroup(preSelectedGroupId);
        } catch (err) {
          this.$message({
            type: 'danger',
            message: `${row.name} 사용자 그룹 변경 중 오류발생`,
          });
        }
      });
    },
  },
  computed: {},
};
</script>

<style scoped></style>
