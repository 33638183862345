var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "cameraViewAndControl" } },
    [
      _c("camera-control-panel", {
        ref: "cameraControlPanel",
        attrs: {
          cameraControlIp: _vm.cameraControlIp,
          cameraControlPort: _vm.cameraControlPort
        }
      }),
      _c("h2", { attrs: { id: "controlExplanation" } }, [
        _vm._v(_vm._s(_vm.controlExplanation))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }