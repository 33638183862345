<template>
  <!-- <div class="page-set" style="height: 100vh"> -->
  <div class="page-set" style="height: 100vh;">
    <!-- <img src="../src/assets/img/landing-img.jpg" /> -->

    <!-- <div id="main-view"> -->
    <div id="main-view" :style="`background-image: url(${$config.getServerConfig().image.url + mainImageUrl.serverFilePath})`">
    <!-- <div id="main-view" :style="`background-image: url('static/images/login-bg.jpg')`">       -->

      <div class="cover abs-full"></div>
      <!-- <img src="static/images/ulsan-w.png" /> -->
      <!-- <img :src="logoImageUrl" /> -->
      <!-- <div class="hamberger-box" @click="sideNavOn">
        <div class="hamberger mb-both-mg">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div> -->
      <div class="notice-event mb-both-pd">
        <!-- 안의 글자는 이벤트 공지사항 종류에 따라 재난/전염병/침수로 바뀜 -->
        <div :class="'notice-wrap ' + (checkRoop ? 'up-notice' : '')">
          <template v-if="rotateAlram.length > 0">
            <!-- <span class="event-text" v-for="alram in rotateAlram">{{ alram.contents }}</span> -->
            <div class="event-text d-flex" v-for="(alram, idx) in rotateAlram" :key="idx">
              <div>
                <!-- <span class="point-main point-main-wrap">전염병</span> -->
                <span class="point-main point-main-wrap">{{ alram.typeName }}</span>
              </div>
              <div>{{ alram.contents }}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="main-content-wrap">
      <div class="notice mb-both-pd flex-box" @click="moveNotice">
        <div class="d-flex" style="width: 75%;">
          <div class="point-main point-main-wrap">공지</div>
          <div class="alarm-contents">{{ viewNoticeMessage }}</div>
          <!-- <span class="point">공지</span><span>공지사항</span> -->
        </div>
        <div>전체보기<i class="icon-arrow" style="display: inline-block; transform: rotate(-90deg);"></i></div>
      </div>
      <div class="content-click-container flex-box mb-both-mg">
        <template v-for="menuInfo in menuList">
          <div
            v-if="menuInfo.key === 'maintenance'"
            class="content-click-box btn-style abs-rel"
            :key="menuInfo.key"
            @click="
              (e) => {
                onClickMenu(e, menuInfo);
              }
            "
          >
            <div class="badge flex-center" v-if="maintnceCount">{{ maintnceCount }}</div>
            <!-- 발생/접수 갯수 -->
            <div class="text-box">
              <img :src="menuInfo.image" />
              <div class="title">
                {{ menuInfo.name }}
              </div>
            </div>
          </div>
          <div
            v-else
            class="content-click-box btn-style"
            :key="menuInfo.key"
            @click="
              (e) => {
                onClickMenu(e, menuInfo);
              }
            "
          >
            <div class="text-box">
              <img :src="menuInfo.image" />
              <div class="title">
                {{ menuInfo.name }}
              </div>
            </div>
          </div>
        </template>
        <!-- <div
        class="content-click-box btn-style"
        v-for="menuInfo in menuList"
        :key="menuInfo.key"
        @click="
          (e) => {
            onClickMenu(e, menuInfo);
          }
        "
      >
        <div class="text-box">
          <img :src="menuInfo.image" />
          <div class="title">
            {{ menuInfo.name }}
          </div>
        </div>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import sideNav from './component/sideNav.vue';
import moment from 'moment';
export default {
  components: {
    'side-nav': sideNav,
  },
  props: {
    checkBackAction: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    checkRoop: false,
    mainImageUrl: '',
    logoImageUrl: '',
    menuList: [
      {
        key: 'cctvGis',
        name: '생활 방범 시스템',
        // path: '/fcltGis',
        path: '/cctvGis',
        image: 'static/images/cctv-mb.png',
        query: { fcltTypes: ['20d61430-90b2-11e9-afcb-b5143e4b15f4'] },
      },
      {
        key: 'fcltGis',
        name: '시설물 시스템',
        path: '/fcltGis',
        image: 'static/images/fclt-mb.png',
      },
      {
        key: 'diseaseInfo',
        name: '전염병 데이터 서비스',
        path: '/diseaseInfo',
        image: 'static/images/disease-mb.png',
      },
      {
        key: 'floodingInfo',
        // name: '침수 지역 데이터 서비스',
        name: '폭우/침수 대응 서비스',
        path: '/flooding',
        image: 'static/images/floading-mb.png',
      },
      {
        key: 'disasterInfo',
        name: '정부 재난 데이터 서비스',
        path: '/disasterInfo',
        image: 'static/images/disaster-mb.png',
      },
      {
        key: 'maintenance',
        name: '유지 관리 내역 현황',
        path: '/maintenance',
        image: 'static/images/sppaner-mb.png',
      },
    ],
    viewNoticeMessage: '',
    maintnceCount: 0,
    alramList: [],
    rotateAlram: [],
    rotateIndex: 0,
    intervalKey: 0,
    rotateFlag: true,
    fcltTypeIdList: [],
    reflash: false,
  }),
  created() {
    const login = localStorage.getItem('login');
    if (login === 'true') {
      localStorage.removeItem('login');
      window.location.reload();
    }

    this.requestNotice();
    // this.backAction();

    const { mobile = {}, logo } = this.$config.getSiteConfig();

    const { mainImage, logoImage } = mobile;
    this.mainImageUrl = mainImage;

    if (logo) {
      this.logoImageUrl = logo[0].serverFilePath;
    } else {
      this.logoImageUrl = logoImage;
    }
  },
  destroyed() {
    clearInterval(this.intervalKey);
    this.rotateFlag = false;
  },
  mounted() {
    if (this.reflash) {
      window.location.reload();
    }

    this.backAction();
    // this.rotateNotice();
    this.getMaintnceCount();
    this.intervalKey = setInterval(this.getMaintnceCount, 5 * 60 * 1000);

    this.getAlramData();
    this.rotateAlramMessage();
  },
  computed: {},
  methods: {
    backAction() {
      const me = this;
      window.backAction = () => {
        try {
          if (window.JSBridge && window.JSBridge.wvStatus) {
            window.JSBridge.wvStatus('using');
          }
          if (me.checkBackAction()) {
            return;
          }
          // 더 할거없으니 앱에 종료 할지를 하게함
          else {
            window.JSBridge.wvStatus('idle');
          }
        } catch (err) {
          this.$message('뒤로가기 에러');
        }
      };
    },
    onClickMenu(e, menuInfo) {
      if (menuInfo && menuInfo.path) {
        // this.$router.push(menuInfo.path);
        this.$router.push({
          path: menuInfo.path,
          query: {
            name: menuInfo.name,
            ...menuInfo.query,
          },
        });
      } else {
        // this.$alert(menuInfo.name);
      }
    },

    sideNavOn() {
      this.$parent.$emit('sideNavOn');
    },
    async requestNotice() {
      const value = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/notice`, {});
      if (value.status !== 200) {
        throw new Error('');
      }

      if (Array.isArray(value.data.docs) && value.data.docs.length > 0) {
        this.viewNoticeMessage = value.data.docs[0].title;
      }
    }, //이 함수를 created() 에서 실행하여 로딩되기 전에 완성해내도록

    sleep(time) {
      return new Promise((resolve) => {
        setTimeout(() => {
          return resolve();
        }, time);
      });
    },
    toggleClass() {
      this.checkRoop = true;
    },

    // async rotateNotice() {
    //   while (true) {
    //     await this.sleep(2000);
    //     this.$message('1');

    //     await this.sleep(2000);
    //     this.$message('2');
    //   }
    // },

    async requestFcltTypeIdList() {
      const me = this;
      try {
        const response = await me.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltType`, {});

        if (response.status !== 200) {
          throw new Error('requestFcltType faile');
        }

        return response.data.docs.map((fType) => {
          const { fcltTypeId } = fType;
          return fcltTypeId;
        });
      } catch (err) {
        // me.$message.error('requestFcltType faile');
        console.error('requestFcltType faile');
        return [];
      }
    },
    async getFcltTypeIdList() {
      const me = this;
      const fcltTypeIdList = await me.requestFcltTypeIdList();
      me.fcltTypeIdList = fcltTypeIdList;
    },

    async requestMaintnce() {
      try {
        await this.getFcltTypeIdList();

        const search = {
          status: 'step1',
          $or: [
            {
              fcltTypeId: this.fcltTypeIdList,
            },
            { fcltId: '' },
          ],
        };
        const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/maintnce`, {
          search,
        });

        if (response.status !== 200) {
          throw new Error('');
        }

        return response.data;
      } catch (err) {
        // this.$message({
        //   type: 'error',
        //   message: '유지보수 정보 로딩 실패',
        // });
        console.error('유지보수 정보 로딩 실패');
        return [];
      }
    },

    async getMaintnceCount() {
      // fcltTypeIdList
      const maintnceList = await this.requestMaintnce();
      this.maintnceCount = maintnceList.length;
    },

    async requestDisaster() {
      try {
        const dt = moment(moment().format('YYYYMMDD')).toDate();
        dt.setDate(dt.getDate() - 7);

        const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/ulsanBukgu/api/event`, {
          search: {
            eventTypeId: 'weatherSpecialReport',
            eventStat: { $ne: '99' },
            startDate: { $gte: dt },
          },
          sort: {
            startDate: -1,
          },
        });

        if (response.status !== 200) {
          throw new Error('');
        }

        return response.data;
      } catch (err) {
        return [];
      }
    },

    requestDisease() {},

    async getAlramData() {
      const disasterList = await this.requestDisaster();
      this.alramList = disasterList.map((disasterData) => {
        const { eventId, eventData } = disasterData;
        const { areaName, warnVar, warnStress } = eventData;
        return {
          key: eventId,
          contents: `${areaName} ${warnVar}${warnStress}`,
          typeName: '재난',
        };
      });

      if (this.alramList.length > 1) {
        this.rotateAlram = [this.alramList[this.rotateIndex], this.alramList[this.rotateIndex + 1]];
      } else if (this.alramList.length === 1) {
        this.rotateAlram = [this.alramList[this.rotateIndex]];
      } else {
        this.rotateAlram = [];
      }
    },

    async rotateAlramMessage(time = 4000) {
      while (this.rotateFlag) {
        await this.sleep(time);

        if (this.alramList.length < 2) {
          return;
        } else if (this.rotateIndex + 1 >= this.alramList.length - 1) {
          this.rotateIndex = -1;
        } else {
          this.rotateIndex += 1;
        }

        this.checkRoop = true;

        await this.sleep(time / 2);
        this.rotateAlram[0] = this.rotateAlram[1];
        this.checkRoop = false;

        await this.sleep(time / 2);

        this.rotateAlram[1] = this.alramList[this.rotateIndex + 1];
      }
    },

    moveNotice() {
      this.$router.push({
        path: '/Notice',
      });
    },
  },
};
</script>
<style></style>
