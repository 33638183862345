<template>
  <el-dialog :title="formTitle" :visible.sync="formDialog" top="5vh" width="80%" @close="hideDialog" :close-on-click-modal="false">
    <div>
      <div>
        <table class="dk-table">
          <colgroup>
            <col style="width: 20%;" />
            <col style="width: 30%;" />
            <col style="width: 20%;" />
            <col style="width: 30%;" />
          </colgroup>
          <tbody>
            <tr>
              <th scople="row">시설물 ID</th>
              <td>{{ selectFcltMarkerData.fcltId }}</td>
              <th scople="row">시설물 명칭</th>
              <td>{{ selectFcltMarkerData.fcltName }}</td>
            </tr>
            <tr>
              <th scople="row">지번 주소</th>
              <td>{{ selectFcltMarkerData.setNumberAddr }}</td>
              <th scople="row">도로명 주소</th>
              <td>{{ selectFcltMarkerData.setRoadAddr }}</td>
            </tr>
            <tr>
              <th scople="row">경도</th>
              <td>{{ selectFcltMarkerData.gisLng }}</td>
              <th scople="row">위도</th>
              <td>{{ selectFcltMarkerData.gisLat }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <el-row class="form-gis-wrap">
        <el-col :span="9" class="straight-road">
          <el-table :data="vmsIfscInfo" size="mini" stripe @row-click="selectVmsIfscInfoGrid">
            <el-table-column prop="vmsIfscId" label="ID" width="120px"></el-table-column>
            <el-table-column prop="vmsIfscName" label="VMS정보제공구간명" width="170px"></el-table-column>
            <el-table-column prop="startNodeName" label="시작구간명"></el-table-column>
            <el-table-column prop="endNodeName" label="종료구간명"></el-table-column>
          </el-table>
        </el-col>
        <el-col :span="15" class="map-wrap">
          <dk-gis fill-height :gisApiInfo="gisApiInfo" :markerData="markerData" :trafficLayerData="trafficLayerData" ref="dkGis"></dk-gis>
        </el-col>
      </el-row>
      <el-row style="padding: 10px 30px 0 30px;">
        <el-col :span="24">
          선택 정보제공 구간 정보
          <div>
            <table class="dk-table">
              <colgroup>
                <col style="width: 20%;" />
                <col style="width: 30%;" />
                <col style="width: 20%;" />
                <col style="width: 30%;" />
              </colgroup>
              <tbody>
                <tr>
                  <th scople="row">VMS 정보제공구간 ID</th>
                  <td>{{ selectedVmsIfscInfo.vmsIfscId }}</td>
                  <th scople="row">VMS 정보제공구간 명칭</th>
                  <td>{{ selectedVmsIfscInfo.vmsIfscName }}</td>
                </tr>
                <tr>
                  <th scople="row">시작 구간명</th>
                  <td>{{ selectedVmsIfscInfo.startNodeName }}</td>
                  <th scople="row">종료 구간명</th>
                  <td>{{ selectedVmsIfscInfo.endNodeName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-col>
      </el-row>
      <div class="form-bottom">
        <el-button @click="hideDialog" style="float: right;">취소</el-button>
        <el-button type="primary" @click="save" style="float: right; margin-right: 5px;">저장</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import _ from 'lodash';
import dkgismain from '@/components/dkGis/dkGisMain.vue';

export default {
  components: {
    'dk-gis': dkgismain,
  },
  data() {
    return {
      formDialog: false,
      formTitle: '',
      updateFlag: false,
      formData: {
        vmsIfscId: '',
        vmsIfscName: '',
        vmsIfscRoadName: '',
        startNodeName: '',
        endNodeName: '',
      },
      markerData: [],

      trafficLayerData: {
        base: [
          {
            name: 'vms_ifsc',
            url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
            params: { LAYERS: 'cite:vms_ifsc_selected', TILED: true },
            default: true,
            idKey: 'vms_ifsc_id',
          },
        ],
        select: {
          source: {
            url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
          },
          mode: 'Single',
        },
      },
      isGisInit: false,
      currentGisApi: null,

      geometry: {},
      selectFcltMarkerData: {
        fcltId: '',
        fcltName: '',
        fcltTypeName: '',
        fcltTypeId: '',
        regionId: '',
        regionName: '',
        setDate: '',
        setNumberAddr: '',
        setRoadAddr: '',
        gisLat: '',
        gisLng: '',
        equip: [],
        fcltTypeData: {},
        images: [],
        files: [],
      },
      vmsIfscInfo: [],
      selectedVmsIfscInfo: {
        vmsIfscId: '',
        vmsIfscName: '',
        startNodeName: '',
        endNodeName: '',
        linkRltn: [],
      },
    };
  },
  created() {},
  props: {
    gisApiInfo: {
      type: Array,
      // gis 기본 설정값을 띄우는 요부분은 나중에 vuex에서 받아서 처리할것
      default() {
        return [];
      },
    },
    // geometry: {
    //     type: Object,
    //     default: null
    // }
  },
  methods: {
    async getVmsIfscInfo(fcltData) {
      this.vmsIfscInfo = [];

      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsIfscInfoWithoutFclt`, {
        params: { fcltId: fcltData.fcltId },
      });

      if (response.data && response.data.docs) {
        this.vmsIfscInfo = response.data.docs;
      } else {
        this.vmsIfscInfo = [];
      }
    },
    save() {
      this.$confirm(
        `${this.selectFcltMarkerData.fcltName} 시설물에서 ${this.selectedVmsIfscInfo.vmsIfscName} VMS 정보 제공 구간 축을 할당 합니다.`,
        '알림',
        {
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          type: 'info',
        }
      )
        .then(async () => {
          let formData = {
            ...this.selectedVmsIfscInfo,
            ...this.selectFcltMarkerData,
          };
          delete formData._id;

          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/vmsIfscFcltAxisInfo/add`, {
              data: formData,
            });

            this.$message({
              type: 'success',
              message: `${this.selectFcltMarkerData.fcltName}시설물에 ${this.selectedVmsIfscInfo.vmsIfscName} VMS 정보제공구간 축 할당 완료`,
            });
            this.$emit('procDataComplete', formData);
            this.hideDialog();
          } catch (err) {
            this.$message({
              type: 'error',
              message: 'VMS 정보제공구간 축 할당 실패',
            });
          }
        })
        .catch(() => {
          this.clearVmsIfscData();
          this.$message({
            type: 'info',
            message: `${this.selectedVmsIfscInfo.vmsIfscName} VMS 정보 제공 구간 축 할당 취소`,
          });
        });
    },
    showDialog(vmsFcltInfo, ifsc) {
      this.formTitle = 'VMS 정보제공구간 시설물 축 할당';
      if (ifsc) {
        this.updateFlag = true;
        this.formData = _.cloneDeep(ifsc);
      } else {
        this.updateFlag = false;
        this.resetData();
      }
      this.selectFcltMarkerData = _.cloneDeep(vmsFcltInfo);
      this.formDialog = true;
      this.getGisVmsMarker();
      this.$forceUpdate();
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.dkGis.setTrafficLayer(true);
          this.getVmsIfscInfo(this.selectFcltMarkerData);
        }, 100);
      });
    },
    getGisVmsMarker() {
      this.markerData = [];
      return this.$store
        .dispatch('gis/actGetFcltMarker', {
          // fcltId: this.selectFcltMarkerData.fcltId,
        })
        .then(() => {
          this.markerData = this.$store.getters['gis/getFcltMarker'];
        });
    },
    apiLoadComplete(currentGisApi) {
      this.isGisInit = true;
      this.currentGisApi = currentGisApi;
      this.$refs.dkGis.setTrafficLayer(true);
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    resetData() {
      this.formData = {
        vmsIfscId: '',
        vmsIfscName: '',
        vmsIfscRoadName: '',
        startNodeName: '',
        endNodeName: '',
      };
      this.selectFcltMarkerData = {
        fcltId: '',
        fcltName: '',
        fcltTypeName: '',
        fcltTypeId: '',
        regionId: '',
        regionName: '',
        setDate: '',
        setNumberAddr: '',
        setRoadAddr: '',
        gisLat: '',
        gisLng: '',
        equip: [],
        fcltTypeData: {},
        images: [],
        files: [],
      };
      this.vmsIfscInfo = [];
      this.isGisInit = false;
      this.selectedVmsIfscInfo = {
        vmsIfscId: '',
        vmsIfscName: '',
        startNodeName: '',
        endNodeName: '',
        linkRltn: [],
      };
    },
    async selectVmsIfscInfoGrid(row) {
      this.selectedVmsIfscInfo = row;
      this.$refs.dkGis.trafficLayerUpdate({
        viewparams: `VMS_IFSC_ID:${row.vmsIfscId}`,
      });
      const centerPoint = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsIfscCenterPoint`, {
        params: {
          vmsIfscId: row.vmsIfscId,
        },
      });

      this.$refs.dkGis.setCenter({
        lat: centerPoint.data.coordinates[1],
        lng: centerPoint.data.coordinates[0],
      });
    },
  },
};
</script>

<style scoped>
.form-gis-wrap {
  background-color: #f0f0f0;
  height: 300px;
}

.form-gis-wrap .straight-road {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.form-gis-wrap .map-wrap {
  height: 300px;
  position: relative;
}

.form-gis-wrap .map-wrap .vue-dkgis-wrap {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
</style>
