<template>
  <el-container class="fill-width-height" ref="gisContainer" id="aiDashboard">
    <section class="dashboard-grid">
      <div class="dashboard-grid__item">
        <div class="gis-toolbar">
          <ul class="gis-toolbar-menu">
            <li>
              <el-button size="small" class="fclt-btn" @click="setFcltManageOnGis"> <i class="fas fa-map-marker-alt"></i>AI카메라 추가 </el-button>
            </li>
            <!-- <li>
              <el-button
                size="small"
                class="fclt-btn"
                @click="toggleGisInfoWindow()"
                :type="isOpenMarkerPopup || isGisOpenInfoWindow ? 'primary' : ''"
              >
                <i class="fas fa-tag"></i>시설물라벨
              </el-button>
            </li> -->
            <!-- <li> -->
            <!-- <el-button size="small" class="fclt-btn" @click="areaInfoCofnig()"> <i class="fas fa-tag"></i>공간정보 설정 </el-button> -->
            <!-- </li> -->
          </ul>
        </div>
        <dk-gis
          fill-height
          :gisApiInfo="gisApiInfo"
          :markerData="markerData"
          :clickMarker="clickMarker"
          :fcltManagerOnGis="fcltManagerOnGis"
          :apiLoadComplete="apiLoadComplete"
          @dkgisMounted="dkgisMounted"
          ref="dkGis"
          id="dkGis"
          class="ai-dashboard-gis"
        ></dk-gis>
      </div>
      <!-- <div class="dashboard-grid__item" id="videoPlayerWrap"> -->
      <!-- <dk-hive-webrtc-player
          :server_ip="webRtcInfo.ip"
          :server_port="webRtcInfo.port"
          :video_name="webRtcInfo.videoName"
          ref="dkHiveWebrtcPlayer"
          @onloadWebrtcPlayer="onloadWebrtcPlayer"
          :videoHeight="videoHeight"
        ></dk-hive-webrtc-player> -->
      <!-- </div> -->
      <div class="dashboard-grid__item" id="seoulAreaMap" v-if="!selectFcltMarkerData.fcltId">
        <div class="image-background">
          <span class="seoularea-title">서울시 한강공원 출입 현황</span>
          <span class="seoularea-date">{{ today() }}</span>
          <div :class="['banpo__marker', 'hangang-marker', selectParkId === 'banpo' ? 'on' : '']" @click="selectPark('banpo')">
            <h5>반포한강공원</h5>
            <div class="marker-body">
              <div class="marker-body__item">
                <i class="fas fa-user-alt"></i>
                <div>
                  <span>{{ parkEntranceData.banpo['1'].human }}</span>
                  /
                  <span>{{ parkEntranceData.banpo['0'].human }}</span>
                </div>
              </div>
              <div class="marker-body__item">
                <i class="fas fa-bicycle"></i>
                <div>
                  <span>{{ parkEntranceData.banpo['1'].bicycle }}</span> / <span>{{ parkEntranceData.banpo['0'].bicycle }}</span>
                </div>
              </div>
            </div>
          </div>
          <div :class="['jamwon__marker', 'hangang-marker', selectParkId === 'jamwon' ? 'on' : '']" @click="selectPark('jamwon')">
            <h5>잠원한강공원</h5>
            <div class="marker-body">
              <div class="marker-body__item">
                <i class="fas fa-user-alt"></i>
                <div>
                  <span>{{ parkEntranceData.jamwon['1'].human }}</span> / <span>{{ parkEntranceData.jamwon['0'].human }}</span>
                </div>
              </div>
              <div class="marker-body__item">
                <i class="fas fa-bicycle"></i>
                <div>
                  <span>{{ parkEntranceData.jamwon['1'].bicycle }}</span> / <span>{{ parkEntranceData.jamwon['0'].bicycle }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-grid__item" id="cameraInfoWrap" v-if="selectFcltMarkerData.fcltId">
        <div class="dashboard-grid__item__body camera-info">
          <div class="camera-info__title">{{ selectFcltMarkerData.fcltName }}</div>
          <div class="camera-info__menu">
            <el-menu :default-active="activeIndex" mode="horizontal">
              <el-menu-item index="1" @click="clearFclt()">
                <i class="el-icon-back"></i>
                <span slot="title">공원목록으로</span>
              </el-menu-item>
              <el-submenu index="2">
                <template slot="title"><i class="fas fa-bars"></i> <span>&nbsp;AI카메라 정보관리</span></template>
                <el-menu-item index="1-1" @click="openFormFcltDialog(selectFcltMarkerData)">AI 카메라 정보수정</el-menu-item>
                <el-menu-item index="1-2" @click="openFormAiDetect()">AI영상 분석설정</el-menu-item>
                <!-- <el-menu-item index="1-1" @click="openFormFcltDialog(selectFcltMarkerData)">AI 카메라 정보삭제</el-menu-item> -->
              </el-submenu>
            </el-menu>
          </div>
          <div class="camera-info__chart">
            <div class="camera-info__chart__body">
              <h6 style="border-top: 2px solid #121820;">사람 / 자전거 통행량</h6>
              <div style="height: calc(100% - 40px);">
                <template v-if="(chartData.objectPass.length !== 0)">
                  <column-time-chart :dateExpression="chartExpression" :cols="chartOption.objectPass.cols" :data="chartData.objectPass" :vMin="0" />
                </template>
                <template v-else>
                  <div class="nodata">데이터 없음.</div>
                </template>
              </div>
            </div>
            <!-- <div class="camera-info__chart__body">
              <h6>방향별 통행량</h6>
              <div>
                <column-time-chart :dateExpression="chartExpression" :cols="chartOption.directionPass.cols" :data="chartData.directionPass" />
              </div>
            </div> -->
            <div class="camera-info__chart__body">
              <h6>사람 / 자전거 비율</h6>
              <div style="height: calc(100% - 40px);">
                <template v-if="(chartData.objectRate.length !== 0)">
                  <pie-chart :cols="chartOption.objectRate.cols" :data="chartData.objectRate" />
                </template>
                <template v-else>
                  <div class="nodata">데이터 없음.</div>
                </template>
              </div>
            </div>
            <div class="camera-info__chart__body">
              <h6>탈 것별 통행량</h6>
              <div style="height: calc(100% - 40px);">
                <template v-if="(chartData.vehiclePass.length !== 0)">
                  <column-time-chart :dateExpression="chartExpression" :cols="chartOption.vehiclePass.cols" :data="chartData.vehiclePass" />
                </template>
                <template v-else>
                  <div class="nodata">데이터 없음.</div>
                </template>
              </div>
            </div>
          </div>
          <div class="camera-info__data">
            <table class="el-table el-table--mini">
              <tr>
                <th>설치주소</th>
                <td>{{ selectFcltMarkerData.setNumberAddr }}</td>
              </tr>
              <tr>
                <th>경도</th>
                <td>{{ selectFcltMarkerData.gisLat }}</td>
              </tr>
              <tr>
                <th>위도</th>
                <td>{{ selectFcltMarkerData.gisLng }}</td>
              </tr>
              <tr v-if="templateRenderFinish" v-for="(item, idx) in selectFcltMarkerData.templateData.getData()" :key="idx">
                <th>{{ item.label }}</th>
                <td>{{ selectFcltMarkerData.templateData.getPrintValue(item.label) }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="dashboard-grid__item camera-list" ref="cameraListGrid">
        <h6>
          <span>통행량 수집현황</span>
        </h6>
        <section class="camera-list-gird">
          <div
            :class="['camera-list-gird__item', item.class ? item.class : '']"
            v-for="(item, idx) in markerData"
            :key="idx"
            @click="selectFclt(item)"
            :ref="item.properties.fcltId"
          >
            <h6 class="camera-list-title" :class="[isClick(item)]">{{ printCameraData(item) }}</h6>
            <div class="camera-list-gird__item__body">
              <doughnut-chart
                :ref="item.properties.fcltId + '_chart'"
                v-if="fcltChartData[item.properties.fcltId]"
                :chart-data="fcltChartData[item.properties.fcltId]"
                :width="90"
                :height="90"
                :options="{
                  responsive: true,
                  maintainAspectRatio: false,
                  legend: {
                    display: false,
                  },
                  plugins: {
                    labels: {
                      render: 'value',
                      fontSize: 14,
                      fontStyle: 'bold',
                      fontColor: '#fff',
                    },
                  },
                }"
              >
              </doughnut-chart>
              <span v-show="!fcltChartData[item.properties.fcltId]">정보없음</span>
            </div>
          </div>
        </section>
      </div>
      <div class="dashboard-grid__item" v-if="!selectFcltMarkerData.fcltId">
        <div class="dashboard-grid__item__body">
          <h6>시간대별 공원 출입 정보</h6>
          <div style="height: calc(100% - 27px);">
            <template v-if="(chartData.parkTotal.length !== 0)">
              <line-time-chart :height="250" :dateExpression="chartExpression" :cols="chartOption.parkTotal.cols" :data="chartData.parkTotal" />
            </template>
            <template v-else>
              <div class="nodata">데이터 없음</div>
            </template>
          </div>
        </div>
      </div>
    </section>

    <div class="dashboard-wrap"></div>
    <form-ai-camera ref="formAiCamera" :gisApiInfo="gisApiInfo" :geometry="geometry" @saved="getFclt()"></form-ai-camera>
    <form-ai-camera-detect ref="formAiCameraDetect"></form-ai-camera-detect>
  </el-container>
</template>

<script>
// DK components
import _ from 'lodash';
import dkgismain from '@/components/dkGis/dkGisMain.vue';
import formAiCamera from '@/ai/forms/formAiCamera.vue';
import formAiCameraDetect from '@/ai/forms/formAiCameraDetect.vue';
import { mapGetters } from 'vuex';
import html2canvas from 'html2canvas';
import dkHiveWebRtcPlayer from '@/components/webrtc/dkHiveWebRtcPlayer.vue';
import dkTemplateUtil from '@/components/dkTemplate/dkTemplateUtil';
import { dkTemplateData } from '@/components/dkTemplate/dkTemplateData';
import doughnutChart from '@/components/chart/doughnutChart.js';
import lineTimeChart from '@/components/chart/lineTimeChart.vue';
import columnTimeChart from '@/components/chart/columnTimeChart.vue';
import pieChart from '@/components/chart/pieChart.vue';
import moment from 'moment-timezone';
import io from 'socket.io-client';

export default {
  components: {
    'dk-gis': dkgismain,
    'form-ai-camera': formAiCamera,
    // 'dk-hive-webrtc-player': dkHiveWebRtcPlayer,
    'form-ai-camera-detect': formAiCameraDetect,
    doughnutChart,
    'column-time-chart': columnTimeChart,
    'pie-chart': pieChart,
    'line-time-chart': lineTimeChart,
  },
  data: () => ({
    systemLogInfo: {},
    pageInit: false,
    gisApiInfo: [],
    currentGisApi: {},
    platformType: 'fclt',
    beforeZoomLevel: -1,
    markerData: [],
    activeMarkerSize: 0,
    parkData: {
      banpo: '반포한강공원',
      jamwon: '잠원한강공원',
    },
    selectFcltMarkerData: {
      fcltId: '',
      fcltName: '',
      fcltTypeName: '',
      fcltTypeId: '',
      regionId: '',
      regionName: '',
      setDate: '',
      setNumberAddr: '',
      setRoadAddr: '',
      gisLat: '',
      gisLng: '',
      equip: [],
      fcltTypeData: {},
      images: [],
      files: [],
    },
    parkSelect: null,
    geometry: {},
    fcltType: [],
    keyword: '',
    searchFcltTypeId: [],
    activeUser: null,
    loading: null,
    gisParam: {
      clusterLv0: 0,
      clusterLv1: 4,
      clusterLv2: 8,
      clusterEnable: true,
    },
    isOpenMarkerPopup: false,
    webRtcInfo: {
      ip: '',
      port: 0,
      videoName: '',
    },
    videoHeight: 100,
    activeIndex: '1',
    chartData: {
      objectPass: [],
      objectRate: [],
      vehiclePass: [],
      parkTotal: [],
    },
    chartOption: {
      objectPass: {
        cols: [
          { label: '날짜', type: 'date' },
          { label: '사람', type: 'number' },
          { label: '자전거(자전거+PM)', type: 'number' },
        ],
      },
      objectRate: {
        cols: [
          { label: '사람/자전거', type: 'string' },
          { label: 'count', type: 'number' },
        ],
      },
      vehiclePass: {
        cols: [
          { label: '날짜', type: 'date' },
          { label: '차', type: 'number' },
          { label: '오토바이', type: 'number' },
          { label: '자전거', type: 'number' },
          { label: 'PM', type: 'number' },
        ],
      },
      inoutTotal: {
        cols: [
          { label: '날짜', type: 'date' },
          { label: '입구', type: 'number' },
          { label: '출구', type: 'number' },
        ],
      },
      parkTotal: {
        cols: [
          { label: '날짜', type: 'date' },
          { label: '반포한강공원', type: 'number' },
          { label: '잠원한강공원', type: 'number' },
        ],
      },

      explorer: {
        axis: 'horizontal',
        keepInBounds: true,
        maxZoomIn: 4.0,
        maxZoomOut: 1,
      },
      vAxis: {
        viewWindow: {
          min: 0,
        },
      },
    },

    chartsLib: '',
    chartExpression: 'time',
    socketio: null,
    selectParkId: null,
    chartColors: ['#4abb14', '#27a5dc', '#b94b5b', '#6d5abe', '#c0392b', '#2980b9'],
    fcltChartData: {},
    parkName: {
      반포한강공원: 'banpo',
      잠원한강공원: 'jamwon',
    },
    parkEntranceData: {
      banpo: {
        // 입구
        '1': {
          human: 0,
          bicycle: 0,
        },
        // 출구
        '0': {
          human: 0,
          bicycle: 0,
        },
      },
      jamwon: {
        // 입구
        '1': {
          human: 0,
          bicycle: 0,
        },
        // 출구
        '0': {
          human: 0,
          bicycle: 0,
        },
      },
    },
    templateRenderFinish: false,
  }),

  created() {
    const _this = this;
    moment.tz('Asia/Seoul');
    window.html2canvas = html2canvas;
    //window.addEventListener('resize', this.onResize, false);

    this.activeUser = this.$dkRouter.getUserInfo();
    // GIS API 모듈 로딩정보

    this.gisApiInfo = this.$store.getters['gis/getGisApi'];
    this.platformType = this.gisApiInfo[0].platformType || 'fclt';
    const { options = [] } = this.$config.getSiteConfig();
    this.getFcltTypes();
  },
  mounted() {
    this.$nextTick(async () => {
      await this.requestParkCongestion();
    });
    (async () => {
      const reqParkAllCount = await this.requestParkAllCount();
      this.showParkAllCount(reqParkAllCount);
    })();
  },
  destroyed() {
    this.socketio.disconnect();
    this.socketio = null;
  },
  computed: {
    ...mapGetters({
      isGisOpenInfoWindow: 'gis/getIsOpenInfoWindow',
    }),
  },
  destroyed() {
    if ('geolocation' in navigator) {
      // navigator.geolocation.clearWatch(this.watchID);
    }
  },
  watch: {},
  methods: {
    async getFcltTypes() {
      let me = this;
      try {
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltType`, {
          params: {
            pluginType: 'aicamera',
          },
        });
        me.fcltTypes = response.data.docs;
        me.fcltTypes.forEach((f) => {
          this.searchFcltTypeId.push(f.fcltTypeId);
        });
      } catch (error) {
        console.error(error);
      }
    },
    clickMarker(args) {
      this.selectFclt(args);
    },
    async getFclt() {
      this.$nextTick(async () => {
        await this.getGisFcltMarker();
        if (!this.pageInit) {
          this.pageInit = true;
        }
      });
    },

    resetSearch() {
      this.keyword = '';
      this.getFclt();
      this.clearFclt();
    },

    async getGisFcltMarker() {
      let param = {};
      this.isSearch = true;

      param.keyword = this.keyword;

      // 장소 데이터는 검색하고 적용 했을 시, 그 부근으로 지도를 이동시키는 기능으로 실행한다.
      // const placeData = await this.$refs.dkGis.getCoordFromAddr(param.keyword);
      // this.searchPlaceData = placeData;
      // 검색된 AI카메라  데이터는 임시 목록에 저장한 후, 적용하였을 시, markerData에 엎어씌워서
      // AI카메라  마커를 rendering하고 선택한다.
      param.clusterLv0 = 0;
      param.clusterLv1 = 999;
      param.clusterLv2 = 999;
      param.fcltTypeId = this.searchFcltTypeId;
      let activeUser = this.$store.getters['auth/getActiveUser'];
      if (activeUser && activeUser.userid) {
        param.userid = activeUser.userid;
        param.group = activeUser.group;
      }
      let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/gis/getFcltMarker`, {
        params: param,
      });
      this.markerData = result.data.markerData;
      this.activeMarkerSize = this.markerData.length;
      // this.markerData.forEach((val) => {
      //   console.log(val.properties.fcltId);
      // });
    },
    selectFclt(args) {
      const _this = this;
      _this.templateRenderFinish = false;

      if (!args) {
        return false;
      }
      // isShowInfoPanel
      this.$refs.dkGis.setCenter({
        lat: args.properties.gisLat,
        lng: args.properties.gisLng,
      });
      const currentFcltType = _.find(_this.fcltTypes, (val) => {
        return val.fcltTypeId === args.properties.fcltTypeId;
      });
      this.selectedFcltTypeData = dkTemplateData({
        template: currentFcltType.fcltTypeData,
        value: args.properties.fcltTypeData,
      });
      this.selectFcltMarkerData = args.properties;
      this.selectFcltMarkerData.templateData = this.selectedFcltTypeData;
      _this.templateRenderFinish = true;
      setTimeout(() => {
        // this.$refs.dkGis.setLevel(3);
        this.selectFcltMarkerData = args.properties;
        if (args.$markerClickEvent) {
          args.$markerClickEvent();
          return;
        }
      }, 100);
      this.parkSelect = args.seq;

      this.showFcltChart();
    },
    async showFcltChart() {
      (async () => {
        const reqObjectPass = await this.requestObjectPass();
        this.showObjectPassChart(reqObjectPass);
      })();
      (async () => {
        const reqObjectRate = await this.requestObjectRate();
        this.showObjectRateChart(reqObjectRate);
      })();
      (async () => {
        const reqVehicleType = await this.requestVehicleType();
        this.showVehicleTypeChart(reqVehicleType);
      })();
    },
    clearFclt() {
      this.selectFcltMarkerData = {
        fcltName: '',
        fcltTypeName: '',
        fcltTypeId: '',
        regionId: '',
        regionName: '',
        setDate: '',
        setNumberAddr: '',
        setRoadAddr: '',
        gisLat: '',
        gisLng: '',
        equip: [],
        fcltTypeData: {},
        images: [],
        files: [],
      };
      this.templateRenderFinish = false;
      this.parkSelect = null;
    },

    async updateFclt() {
      this.geometry = {
        lat: this.selectFcltMarkerData.gisLat,
        lng: this.selectFcltMarkerData.gisLng,
      };
      let updateFlag = true;
      this.$refs.formAiCamera.showDialog(updateFlag);
      setTimeout(() => {
        if (this.selectFcltMarkerData.properties) {
          this.$refs.formAiCamera.setFcltDataDoc(this.selectFcltMarkerData.properties);
        }
      }, 100);
    },

    fcltManagerOnGis(args) {
      let updateFlag = false;
      let geometry = {};
      if (!args.geometry) {
        return;
      }
      let licenseInfo = this.$store.getters['license/getLicense'];
      if (licenseInfo.currentFcltCount >= licenseInfo.limitFcltCount) {
        this.$alert(
          `등록된 ${licenseInfo.currentFcltCount}건의 시설물 정보가 있고, 현재 라이선스는 총 ${licenseInfo.limitFcltCount}건의 시설물이 등록 가능하므로, 유효한 시설물 등록 수를 초과합니다.`,
          '라이선스 등록 초과',
          {
            confirmButtonText: '확인',
            type: 'warning',
          }
        );
        this.$store.dispatch('license/setDialog', true);
        return;
      }
      this.geometry = this.$lodash.cloneDeep(args.geometry);
      this.$refs.formAiCamera.showDialog(updateFlag);
    },

    setFcltManageOnGis() {
      this.$refs.dkGis.setFcltManageOnGis();
    },

    async apiLoadComplete(currentGisApi) {
      this.currentGisApi = currentGisApi;
      setTimeout(async () => {
        await this.getFclt();
        this.initSocket();
      }, 500);
    },

    async gisCapture() {
      this.loading = this.$loading({
        lock: true,
        text: 'GIS 이미지를 렌더링 중입니다.',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      // let proxyUrl = `${this.$config.getServerConfig().core.api}/html2canvas-proxy`;
      let proxyUrl = `/html2canvas-proxy`;
      let el = document.querySelector('#dkGis');
      let layers = el.children[0].children[0].children[0].children[0];
      layers.children[5].style.zIndex = 100;
      window
        .html2canvas(el, {
          logging: true,
          proxy: proxyUrl,
          allowTaint: false,
        })
        .then((canvas) => {
          var a = document.createElement('a');
          a.href = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
          a.download = 'gis-capture.png';
          a.click();
          this.loading.close();
        })
        .catch((error) => {
          this.loading.close();
          console.log('Erorr descargando reporte visual', error);
        });
    },

    toggleGisInfoWindow() {
      this.isOpenMarkerPopup = !this.isOpenMarkerPopup;
      this.$store.dispatch('gis/actIsOpenInfoWindow', { value: this.isOpenMarkerPopup });
    },

    today() {
      return moment().format('YYYY년 MM월 DD일');
    },
    onloadWebrtcPlayer() {
      console.log('init web rtc player');
    },
    playWebrtcPlayer() {
      //if (this.$refs.dkHiveWebrtcPlayer) this.$refs.dkHiveWebrtcPlayer.stop();

      this.webRtcInfo.port = dkTemplateUtil.getTemplateValue(this.selectFcltMarkerData.fcltTypeData, 'webRtcPort') || 0;
      this.webRtcInfo.ip = dkTemplateUtil.getTemplateValue(this.selectFcltMarkerData.fcltTypeData, 'webRtcIp') || '';
      this.webRtcInfo.videoName = dkTemplateUtil.getTemplateValue(this.selectFcltMarkerData.fcltTypeData, 'webRtcVideoName') || '';

      setTimeout(() => {
        this.$refs.dkHiveWebrtcPlayer.play(this.webRtcInfo);
      }, 50);
    },
    printCameraData(marker) {
      if (marker && marker.properties) {
        return marker.properties.fcltName;
      }
    },
    isClick(item) {
      return this.parkSelect === item.seq ? 'selectTitle' : '';
    },
    // onResize() {
    //   const videoPlayer = document.querySelector('#videoPlayerWrap');
    //   this.videoHeight = videoPlayer.offsetHeight;
    // },
    openFormFcltDialog(args) {
      var me = this;
      //this.formDialog = true;
      //let geometry = this.$lodash.cloneDeep(this.defaultGisApiInfo.geometry);
      this.geometry = {
        lat: args.gisLat,
        lng: args.gisLng,
      };
      me.$refs.formAiCamera.showDialog(true, args);
      // setTimeout(function () {
      //   if (args) {
      //     me.$refs.formAiCamera.setFcltDataDoc(args);
      //   }
      // }, 1000);
    },
    dkgisMounted(args) {},
    async requestParkCongestion(fclt) {
      const me = this;
      let promises = [];
      try {
        let promises = _.map(this.parkName, (val, key) => {
          const param = {
            keyword: key,
          };
          return new Promise(async (resolve, reject) => {
            const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/aicam/realtime/park`, {
              params: param,
            });
            resolve(response.data);
          }).catch((err) => {});
        });
        Promise.all(promises).then((val) => {
          console.log(val);
        });
      } catch (err) {
        console.error(err);
      }
    },
    openFormAiDetect() {
      console.log(this.selectFcltMarkerData.templateData);

      this.$refs.formAiCameraDetect.showDialog(this.selectFcltMarkerData);
    },
    selectPark(parkId) {
      const resetPark = () => {
        this.selectParkId = '';
        this.markerData.forEach((val) => {
          val.class = '';
        });
        this.activeMarkerSize = this.markerData.length;
      };
      if (parkId) {
        if (this.selectParkId === parkId) {
          resetPark();
          return this.$forceUpdate();
        }
        let idx = 0;
        this.selectParkId = parkId;
        this.markerData.forEach((val) => {
          const chk = _.find(val.properties.keywords, (k) => {
            return k === this.parkData[parkId];
          });
          if (chk) {
            val.class = 'on';
            idx++;
          } else val.class = 'off';
        });
        this.activeMarkerSize = idx;
        // this.markerData = this.markerData.sort((a, b) => {
        //   if (a.class === 'on') {
        //     return -1;
        //   } else if (b.class === 'on') {
        //     return 1;
        //   }
        // });
      } else {
        resetPark();
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    initSocket() {
      const _this = this;
      this.socketio = io({
        path: '/statisticsapi/dk-socket/statistics',
        transport: ['websocket'],
      });
      this.socketio.on('fclt', (response) => {
        //console.log(response);
        if (response.aggregation && Object.keys(response.aggregation).length > 0) {
          // const fcltData = _.find(_this.markerData, (val) => {
          //   return val.properties.fcltId === response.fcltId;
          // });
          // if (fcltData) {
          const fcltId = response.fcltId;
          this.fcltChartData[fcltId] = {
            labels: [],
            datasets: [
              {
                data: [],
                backgroundColor: [],
              },
            ],
          };
          this.fcltChartData[fcltId].labels.push('사람');
          this.fcltChartData[fcltId].labels.push('자전거');
          this.fcltChartData[fcltId].datasets[0].data.push(response.aggregation.peopleCounting);
          this.fcltChartData[fcltId].datasets[0].data.push(response.aggregation.bicycleCounting);
          this.fcltChartData[fcltId].datasets[0].backgroundColor.push(_this.chartColors[0]);
          this.fcltChartData[fcltId].datasets[0].backgroundColor.push(_this.chartColors[1]);
          if (this.$refs[`${response}_chart`]) {
            this.$refs[`${response}_chart`].update();
          }
          this.$forceUpdate();
        }
        // {
        //     labels: ['서울방향', '잠실방향'],
        //     datasets: [
        //       {
        //         data: [5, 4],
        //         backgroundColor: ['#e74c3c', '#f1c40f'],
        //       },
        //     ],
        //   }
      });
      this.socketio.on('park', (response) => {
        console.log(response);
        const targetParkName = this.parkName[response.park];
        const data = response.aggregation;
        //const data = _.groupBy(response.aggregation, 'entrance');
        let map = new Map();

        for (let item of data) {
          map.set(item.entrance, {
            human: item.peopleCounting,
            bicycle: item.bicycleCounting,
          });
        }
        console.log(map);
        for (var i = 0; i <= 1; i++) {
          if (map.has(i)) {
            this.parkEntranceData[targetParkName][i + ''].human = map.get(i).human;
            this.parkEntranceData[targetParkName][i + ''].bicycle = map.get(i).bicycle;
          } else {
            this.parkEntranceData[targetParkName][i + ''].human = 0;
            this.parkEntranceData[targetParkName][i + ''].bicycle = 0;
          }
        }
      });
    },
    //사람-자전거 시간별 통행량 API 연결
    async requestObjectPass() {
      const me = this;
      try {
        let params = {
          startDate: moment().startOf('day').toDate(),
          endDate: moment().toDate(),
          fcltId: me.selectFcltMarkerData.fcltId,
        };
        const response = await me.$http.get(`${this.$config.getServerConfig().core.api}/core/api/aicam/realtime/detecttype`, {
          params: params,
        });
        return response.data;
      } catch (err) {
        console.error('requestObjectPass Error');
      }
    },
    //사람-자전거 비율 API 연결
    async requestObjectRate() {
      const me = this;
      try {
        let params = {
          startDate: moment().startOf('day').toDate(),
          endDate: moment().toDate(),
          fcltId: me.selectFcltMarkerData.fcltId,
        };
        const response = await me.$http.get(`${this.$config.getServerConfig().core.api}/core/api/aicam/realtime/detecttype/ratio`, {
          params: params,
        });
        return response.data;
      } catch (err) {
        console.error('requestObjectRate Error');
      }
    },
    //탈것별 통행량 API
    async requestVehicleType() {
      const me = this;
      try {
        let params = {
          startDate: moment().startOf('day').toDate(),
          endDate: moment().toDate(),
          fcltId: me.selectFcltMarkerData.fcltId,
        };
        const response = await me.$http.get(`${this.$config.getServerConfig().core.api}/core/api/aicam/realtime/vehicleType`, {
          params: params,
        });
        return response.data;
      } catch (err) {
        console.error('requestVehicleType Error');
      }
    },
    //공원별 총 통행량 API 반포
    async requestParkAllCount() {
      const me = this;
      let promises = [];
      try {
        let promises = _.map(this.parkName, (val, key) => {
          const param = {
            keyword: ['출입검지', key],
            startDate: moment().startOf('day').toDate(),
            endDate: moment().toDate(),
            // keyword: '출입검지',
          };
          return new Promise(async (resolve, reject) => {
            const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/aicam/realtime/count`, {
              params: param,
            });
            resolve(response.data);
          }).catch((err) => {});
        });

        const val = await Promise.all(promises);
        console.log(val);
        // .then((val) => {
        //   console.log(val);
        return val;
        // });
      } catch (err) {
        console.error(err);
      }
    },
    //사람-자전거 통행량 데이터 가공
    showObjectPassChart(args) {
      const startHour = moment().startOf('day').hour();
      const currentHour = moment().hour();
      let objectPass = [];
      for (var i = startHour; i <= currentHour; i++) {
        const data = args.find((val) => {
          return val.hour === i;
        });
        if (data) {
          objectPass.push({
            date: moment().set('hour', data._id).set('minute', 0).set('second', 0).toDate(),
            value: [data.peopleCounting, data.bicycleCounting],
          });
        } else {
          objectPass.push({
            date: moment().set('hour', i).set('minute', 0).set('second', 0).toDate(),
            value: [0, 0],
          });
        }
      }
      this.chartData.objectPass = objectPass;
    },
    //사람-자전거 비율 데이터 가공
    showObjectRateChart(args) {
      let peopleCounting = args[0].peopleCounting;
      let bicycleCounting = args[0].bicycleCounting;
      this.chartData.objectRate = [
        {
          name: '사람',
          value: peopleCounting,
        },
        {
          name: '자전거',
          value: bicycleCounting,
        },
      ];
    },
    //탈것별 통행량 데이터 가공
    showVehicleTypeChart(args) {
      const startHour = moment().startOf('day').hour();
      const currentHour = moment().hour();
      const classifyData = _.groupBy(args, 'hour');
      let vehiclePass = [];
      for (let key in classifyData) {
        const dataList = classifyData[key];
        if (Array.isArray(dataList)) {
          for (var i = startHour; i <= currentHour; i++) {
            const data = args.find((val) => {
              return val.hour === i;
            });
            if (data) {
              const date = moment().set('hour', dataList[0].hour).set('minute', 0).set('second', 0).toDate();
              const value = [0, 0, 0, 0];
              dataList.find((data) => {
                switch (data.vehicleType) {
                  case 1:
                    value[0] = data.totalCounting; //차
                    break;
                  case 4:
                    value[1] = data.totalCounting; //오토바이
                    break;
                  case 5:
                    value[2] = data.totalCounting; //자전거
                    break;
                  case 6:
                    value[3] = data.totalCounting; //PM
                    break;
                }
                return 0;
              });
              vehiclePass.push({
                date,
                value,
              });
            } else {
              vehiclePass.push({
                date: moment().set('hour', i).set('minute', 0).set('second', 0).toDate(),
                value: [0, 0, 0, 0],
              });
            }
          }
        }
      }
      this.chartData.vehiclePass = vehiclePass;
    },
    showParkAllCount(args) {
      const banpo = args[0];
      const jamwon = args[1];
      const startHour = moment().startOf('day').hour();
      const currentHour = moment().hour();
      let map1 = new Map();
      for (let item of banpo) {
        map1.set(item.hour, item.totalCounting);
      }
      let map2 = new Map();
      for (let item of jamwon) {
        map2.set(item.hour, item.totalCounting);
      }
      let parkTotal = [];
      for (let i = startHour; i <= currentHour; i++) {
        let item = {};
        let count = [];
        if (map1.has(i)) {
          count.push(map1.get(i));
        } else {
          count.push(0);
        }
        if (map2.has(i)) {
          count.push(map2.get(i));
        } else {
          count.push(0);
        }
        item.date = moment().set('hour', i).set('minute', 0).set('second', 0).toDate();

        item.value = count;
        parkTotal.push(item);
      }

      this.chartData.parkTotal = parkTotal;
    },
  },
};
</script>
