<template>
  <el-dialog :title="formTitle" :visible.sync="formDialog" top="5vh" width="80%" @close="hideDialog" @open="openDialog" :close-on-click-modal="false">
    <div>
      <el-row class="form-gis-wrap">
        <el-col :span="9" class="straight-road">
          <!-- <span 
              v-for="(item,idx) in roadLinkData"
              :key="idx"
              style="position:absolute; width:240px; height:50px; border:1px solid #acacac; display:inline-block;"
            >
              <span style="">{{item.f_node_name}}</span>
              <span v-if="idx+1===roadLinkData.length">{{item.t_node_name}}</span>
            </span> -->
          <dk-straight-road
            ref="dkStraightRoad"
            :roadLinkData="roadLinkData"
            :selectLink="selectStraightRoadLink"
            @onLoadRoadLinkData="onLoadRoadLinkData"
            @updateActivateLinkData="updateActivateLinkData"
          >
          </dk-straight-road>
        </el-col>
        <el-col :span="15" class="map-wrap">
          <dk-gis fill-height :gisApiInfo="gisApiInfo" :trafficLayerData="trafficLayerData" :selectTrafficLayer="selectTrafficLayer" ref="dkGis">
          </dk-gis>
        </el-col>
      </el-row>
      <el-row style="padding: 15px 30px;">
        <el-col :span="10">
          <el-form :rules="rules" ref="form0" :model="formData" label-width="150px" size="small">
            <el-row>
              <el-col :span="18">
                <el-form-item label="정보제공구간 ID" prop="ifsc_id">
                  <el-input maxlength="10" v-model="formData.ifsc_id" placeholder="정보제공구간 ID"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-if="!updateFlag">
                <el-button size="small" style="margin: 1px 5px;" @click="makeNewIfscId">자동 ID 생성</el-button>
              </el-col>
              <el-col :span="24">
                <el-form-item label="정보제공구간 명칭" prop="ifsc_nm">
                  <el-input v-model="formData.ifsc_nm" placeholder="정보제공구간 명칭"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="방향" prop="drct_cd">
                  <el-select v-model="formData.drct_cd" placeholder="방향">
                    <el-option label="상행" value="1"></el-option>
                    <el-option label="하행" value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="외부연계여부" prop="extr_cnct_sect_yn">
                  <el-select v-model="formData.extr_cnct_sect_yn" placeholder="외부연계여부">
                    <el-option label="예" value="Y"></el-option>
                    <el-option label="아니오" value="N"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
        <el-col :span="14" class="link-list">
          <table class="el-table el-table--mini user-table">
            <tr>
              <th>seq</th>
              <th>링크ID</th>
              <th>도로명</th>
              <th>시작노드명</th>
              <th>종료노드명</th>
              <th></th>
            </tr>
            <tr v-for="(item, idx) in editIfscLinkRltn" :key="idx" @click="selectStraightRoadLink(item)" style="cursor: pointer;">
              <td>{{ item.seq }}</td>
              <td>{{ item.link_id }}</td>
              <td>{{ item.road_name }}</td>
              <td>{{ item.f_node_name }}</td>
              <td>{{ item.t_node_name }}</td>
              <td>
                <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="removeLinkData(item)"></el-button>
              </td>
            </tr>
          </table>
        </el-col>
      </el-row>
      <div class="form-bottom">
        <el-button @click="hideDialog" style="float: right;">
          취소
        </el-button>
        <el-button type="primary" @click="save" style="float: right; margin-right: 5px;">
          저장
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import dkgismain from '@/components/dkGis/dkGisMain.vue';
// import incidentConst from '@/traffic/incidentConst.js'
import dkStraightRoad from './dkStraightRoad.vue';

export default {
  components: {
    'dk-gis': dkgismain,
    'dk-straight-road': dkStraightRoad,
  },
  data() {
    var checkIfscId = async (value) => {
      try {
        let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/business/api/gimpoIfscInfo/checkIfscId`, {
          params: {
            ifscId: value,
          },
        });
        if (result.data.msg) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    };

    var validateIfscId = async (rule, value, callback) => {
      if (!this.updateFlag) {
        if (value === '') {
          callback(new Error('정보제공구간 ID를 입력해 주세요'));
        } else if (value.length <= 9) {
          callback(new Error('정보제공구간 ID는 10자 이상 입력해 주세요'));
        } else {
          let chk = await checkIfscId(value);

          if (chk) {
            callback(new Error('이미 등록된 정보제공구간 ID입니다'));
          } else {
            callback();
          }
        }
      }
    };

    return {
      formDialog: false,
      formTitle: '',
      updateFlag: false,
      formData: {
        ifsc_id: '',
        ifsc_nm: '',
        drct_cd: '1',
        extr_cnct_sect_yn: 'Y',
      },
      selectedLinkData: null,
      editIfscLinkRltn: [],
      roadLinkData: [],
      rules: {
        ifsc_id: [
          {
            required: true,
            validator: validateIfscId,
            trigger: ['change', 'blur'],
          },
        ],
        ifsc_nm: [
          {
            required: true,
            message: '정보제공구간 명칭을 입력해 주세요',
            trigger: ['change', 'blur'],
          },
        ],
      },
      markerData: [
        {
          seq: 0,
          draggable: true,
          geometry: {
            lng: 127.10056150323506,
            lat: 37.489008855218195,
          },
          properties: {},
        },
      ],
      trafficLayerData: {
        base: [
          {
            name: 'link_traf',
            url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
            //params: {'LAYERS': 'cite:gimpo_ifsc_traf', 'TILED': true,  'STYLES': 'cite:gimpo_utis_deselect_style' },
            params: { LAYERS: 'cite:gimpo_ifsc_config', TILED: true },
            default: true,
            idKey: 'link_id',
          },
        ],
        select: {
          source: {
            url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
          },
          mode: 'Single',
        },
      },
      isGisInit: false,
      currentGisApi: null,
      trafficLayers: [
        { level: 8, layer: 'gimpo_link_level_16' },
        { level: 7, layer: 'gimpo_link_level_15' },
        { level: 6, layer: 'gimpo_link_level_14' },
        { level: 5, layer: 'gimpo_link_level_13' },
        { level: 4, layer: 'gimpo_link_level_12' },
        { level: 3, layer: 'gimpo_link_level_12' },
      ],
      geometry: {},
    };
  },
  created() {},
  props: {
    gisApiInfo: {
      type: Array,
      // gis 기본 설정값을 띄우는 요부분은 나중에 vuex에서 받아서 처리할것
      default() {
        return [];
      },
    },
    // geometry: {
    //     type: Object,
    //     default: null
    // }
  },
  methods: {
    save() {
      this.$refs.form0.validate(async (valid) => {
        let formData = this.$lodash.cloneDeep(this.formData);
        formData.linkRltn = [];
        formData.del_yn = 'N';
        formData.sect_lngt = 0;
        formData.sect_grad_cd = 'A';
        let saveFunc = this.updateFlag ? '/business/api/gimpoIfscInfo/update' : '/business/api/gimpoIfscInfo/add';
        this.editIfscLinkRltn.forEach((link) => {
          let linkData = link;
          linkData.link_id = parseInt(link.link_id);
          formData.linkRltn.push(linkData);
        });
        try {
          await this.$http.post(`${this.$config.getServerConfig().core.api}${saveFunc}`, {
            data: formData,
          });
          this.$message({
            type: 'success',
            message: `정보제공구간 등록 완료`,
          });
          this.$emit('procDataComplete', formData);
          this.hideDialog();
        } catch (err) {
          this.$message({
            type: 'error',
            message: '정보제공구간 등록 실패',
          });
        }
      });
    },
    showDialog(ifsc, ifscLinkRltn) {
      if (ifsc) {
        this.updateFlag = true;
        this.formTitle = '정보제공구간 수정';
        this.formData.ifsc_id = ifsc.ifsc_id;
        this.formData.ifsc_nm = ifsc.ifsc_nm;
        this.formData.drct_cd = ifsc.drct_cd;
        this.formData.extr_cnct_sect_yn = ifsc.extr_cnct_sect_yn;
        this.selectedLinkData = ifsc;
        if (ifscLinkRltn && ifscLinkRltn.length > 0) {
          this.getLinksByRoad(ifscLinkRltn[0]);
        }
      } else {
        this.updateFlag = false;
        this.formTitle = '정보제공구간 추가';
        this.roadLinkData = [];
        this.resetData();
      }
      this.formDialog = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.dkGis.setTrafficLayer(true);
          // this.$refs.dkStraightRoad.focusLinkData(ifsc)
          if (ifscLinkRltn && ifscLinkRltn.length > 0) {
            this.$store.commit('dkStraightRoad/setActivatedLinkDataArray', ifscLinkRltn);

            // this.selectStraightRoadLink(ifscLinkRltn);
            this.updateActivateLinkData();
          }
          this.layerSourceName = this.$refs.dkGis.setTrafficLayerSource('link_traf');
        }, 100);
      });
    },
    apiLoadComplete(currentGisApi) {
      this.isGisInit = true;
      this.currentGisApi = currentGisApi;
      this.$refs.dkGis.setTrafficLayer(true);
    },
    getLinkData() {},
    openDialog() {
      // this.fcltManageDialogGisInit()
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    resetData() {
      this.formData = {
        ifsc_id: '',
        ifsc_nm: '',
        drct_cd: '1',
        extr_cnct_sect_yn: 'Y',
      };
      this.editIfscLinkRltn = [];
      this.selectedLinkData = null;
      if (this.$refs.dkStraightRoad) {
        this.$store.dispatch('dkStraightRoad/resetActivatedLinkData');
      }
      this.isGisInit = false;
    },

    setCenter(geometry) {
      this.$refs.fcltManageDialogGis.setCenter(geometry);
      this.markerData[0].geometry = geometry;
    },

    async getLinksByRoad(linkData) {
      const size = 5;
      let rowData = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/linksByRoad`, {
        params: linkData,
      });
      if (!rowData.data || rowData.data.length == 0) {
        return false;
      }
      // let centerLinkData = rowData.data.find((d) => { return d.link_id == linkData.link_id })
      // let currentLinkData = this.$lodash.cloneDeep(centerLinkData)
      // let prevLinkData = []
      // let roadLinkData = []
      // prevLinkData.push(centerLinkData)
      // for (var i=0; i<size; i++) {
      //   let tmpLinkData = rowData.data.find((d) => {
      //     return d.t_node === currentLinkData.f_node && d.f_node !== currentLinkData.t_node
      //   })
      //   if (tmpLinkData) {
      //     currentLinkData = tmpLinkData
      //     prevLinkData.push(tmpLinkData)
      //   }
      // }
      // roadLinkData = this.$lodash.reverse(prevLinkData)
      // currentLinkData = centerLinkData
      // for (var i=0; i<size; i++) {
      //   let tmpLinkData = rowData.data.find((d) => {
      //     return d.f_node === currentLinkData.t_node && d.t_node !== currentLinkData.f_node
      //   })
      //   if (tmpLinkData) {
      //     currentLinkData = tmpLinkData
      //     roadLinkData.push(tmpLinkData)
      //   }
      // }
      this.roadLinkData = rowData.data;
      // console.log(roadLinkData)
    },
    selectTrafficLayer(feature) {
      this.selectedLinkData = feature;
      this.getLinksByRoad(feature);
    },
    selectStraightRoadLink(linkData) {
      let level = parseInt(this.$refs.dkGis.getLevel());
      let ll = this.trafficLayers.find((a) => {
        return a.level === level;
      });
      this.$refs.dkGis.focusTrafficLayer('link_id', linkData.link_id, ll.layer);
    },

    updateActivateLinkData() {
      this.editIfscLinkRltn = this.$store.getters['dkStraightRoad/getActivatedLinkData'];
      this.$forceUpdate();
    },
    removeLinkData(linkData) {
      this.$refs.dkStraightRoad.removeLinkData(linkData);
    },
    onLoadRoadLinkData(roadData) {
      this.$refs.dkStraightRoad.focusLinkData(this.selectedLinkData);
    },
    async makeNewIfscId() {
      let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/business/api/gimpoIfscInfo/makeNewIfscId`);
      if (result.data) {
        this.formData.ifsc_id = result.data.newIfscId;
      }
    },
  },
};
</script>

<style scoped>
.form-gis-wrap {
  background-color: #f0f0f0;
  height: 300px;
}

.form-gis-wrap .straight-road {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.form-gis-wrap .map-wrap {
  height: 300px;
  position: relative;
}

.form-gis-wrap .map-wrap .vue-dkgis-wrap {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.link-list {
  max-height: 350px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
