var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-width-height el-container" },
    [
      _c(
        "el-container",
        { staticClass: "page-body" },
        [
          _c(
            "el-main",
            [
              _c(
                "el-row",
                { staticClass: "content-wrap" },
                [
                  _c("dk-page-header"),
                  _c(
                    "el-col",
                    { staticClass: "page-content", attrs: { span: 24 } },
                    [
                      _c("dk-el-table-header", {
                        attrs: { openFormDialog: _vm.openFormDialog }
                      }),
                      _c(
                        "el-table",
                        {
                          attrs: {
                            "empty-text": "등록된 센터관리 정보가 없습니다.",
                            size: "mini",
                            stripe: ""
                          }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "서버ID", prop: "serverId" }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "서버명",
                              prop: "serverName",
                              "header-align": "center",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "서버IP",
                              "header-align": "center",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "서버 포트",
                              "header-align": "center",
                              align: "center"
                            }
                          }),
                          _c(
                            "el-table-column",
                            [
                              _c("el-button", { attrs: { size: "small" } }, [
                                _vm._v("수정")
                              ]),
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "danger" } },
                                [_vm._v("삭제")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("dk-el-pagination", {
                        ref: "pagination",
                        attrs: {
                          totalDocs: _vm.totalDocs,
                          limit: _vm.limit,
                          pagingProc: _vm.pagingProc
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("form-server-manage", { ref: "formDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }