<template>
    <div>
        <div  id="pixelateWrap">
            <!--<canvas ref="imageCanvas" id="imageCanvas">
            </canvas>-->
            <canvas ref="controlCanvas" id="controlCanvas"
                @mousedown="onMousedown" 
                @mouseup="onMouseup"
                @mousemove="onMousemove" 
            >
            </canvas>
            <img                 
                id="image" 
                ref="image"                
            >
        </div>
    </div>    
</template>

<script>
export default {    
    props : {
        imgSrc : {
            require: true
        },        
        isImagePixelate : {
            default: false,
            type: Boolean
        },
        imgData: {
            default: {},
            type: Object
        }
    },
    data() {
        return {
            isEdit: false,            
            resizeCheckId: null,
            ratio: 0,
            offset : 10,
            rect: {
                startX:0,
                startY:0,
                width:0,
                height:0
            },
            mosaic:[],
            imgWidth:0,
            imgHeight:0            
        }
    },
    watch: {        
    },
    mounted() {
        window.addEventListener('resize', onResize, false)        
        let _this = this
        //let ctx = _this.$refs["imageCanvas"].getContext('2d')
        let ctrlCtx = _this.$refs["controlCanvas"].getContext('2d')        
        // ctx.canvas.width = _this.$el.clientWidth
        // ctx.canvas.height = Math.ceil(_this.$el.clientWidth / 16 * 9)        
        ctrlCtx.canvas.width = _this.$refs.image.width
        ctrlCtx.canvas.height = _this.$refs.image.height
        this.imgLoad(_this.imgSrc)
/*
        this.img = new Image()
        this.img.src = this.imgSrc
        this.img.setAttribute('crossOrigin', 'anonymous')
        this.img.onload = function() {            
            ctx.drawImage(_this.img, 0, 0, _this.img.width, _this.img.height, 0, 0, ctx.canvas.width, ctx.canvas.height)                        
            //_this.editedImg.src = _this.img.src
            //_this.editedImg.width = _this.img.width
            //_this.editedImg.height = _this.img.height
            _this.editedImg.setAttribute('crossOrigin', 'anonymous')
            _this.ratio = (_this.img.width / ctx.canvas.width).toFixed(3)
           
            //_this.imgData =  ctx.getImageData(0, 0, _this.img.width, _this.img.height)
            //console.log(_this.imgData)
        }        */        
    },
    methods: {
        imgLoad(src) {
            let _this = this
            let tmpImg = new Image()            
            tmpImg.src = src
            tmpImg.onload = function() {                          
                _this.$refs.image.src = tmpImg.src
                _this.imgWidth = tmpImg.width
                _this.imgHeight = tmpImg.height
                _this.ratio = (_this.imgWidth / _this.$refs.image.width).toFixed(3)             
                let ctrlCtx = _this.$refs["controlCanvas"].getContext('2d')
                ctrlCtx.canvas.width = _this.$refs.image.width
                ctrlCtx.canvas.height = _this.$refs.image.height                
            }
        },
        onResize() {
            //clearTimeout(this.resizeCheckId)
            let _this = this
            // let ctx = _this.$refs["imageCanvas"].getContext('2d')
            let ctrlCtx = _this.$refs["controlCanvas"].getContext('2d')
            ctrlCtx.canvas.width = _this.$refs.image.width
            ctrlCtx.canvas.height = _this.$refs.image.height
            if (_this.imgWidth > 0) {
                _this.ratio = (_this.imgWidth / _this.$refs.image.width).toFixed(3)                
            }            
            /*_this.resizeCheckId = setTimeout(function() {
                // ctx.canvas.width = _this.$el.clientWidth
                // ctx.canvas.height = Math.ceil(_this.$el.clientWidth / 16 * 9)
                ctrlCtx.canvas.width = _this.$el.clientWidth
                ctrlCtx.canvas.height = Math.ceil(_this.$el.clientWidth / 16 * 9)
                //if (_this.editedImg.src) {
                    //console.log(_this.editedImg.width + ", " + _this.editedImg.height)
                    // ctx.drawImage(_this.editedImg, 0, 0, _this.editedImg.width, _this.editedImg.height, 0, 0, ctx.canvas.width, ctx.canvas.height)                
                    _this.ratio = (_this.editedImg.width / ctx.canvas.width).toFixed(3)                     
                //}
            }, 300)            */
        },
        onMousedown(e) {            
            if (this.isImagePixelate) {
                this.isEdit = true
                this.rect.startX = e.layerX
                this.rect.startY = e.layerY
            }
        },
        onMouseup(e) {
            if (this.isImagePixelate) {
                this.isEdit = false
                let rect = {
                    x: ((this.rect.width < 0)?this.rect.startX + this.rect.width:this.rect.startX),
                    y: ((this.rect.height < 0)?this.rect.startY + this.rect.height:this.rect.startY),
                    width: Math.abs(this.rect.width),
                    height: Math.abs(this.rect.height)
                }
                this.mosaic.push(rect)
            }
        },
        onMousemove(e) {
            let _this = this
            if (this.isEdit) {
                let canvas = this.$refs["controlCanvas"]
                let ctx = canvas.getContext('2d')
                ctx.clearRect(0, 0, canvas.width, canvas.height)
                ctx.beginPath()
                _this.rect.width = e.layerX - _this.rect.startX
                _this.rect.height = e.layerY - _this.rect.startY
                ctx.rect(_this.rect.startX, _this.rect.startY, _this.rect.width, _this.rect.height)
                _this.mosaic.forEach((rect)=> {
                    ctx.rect(rect.x, rect.y, rect.width, rect.height)
                })
                ctx.strokeStyle = 'black';
                ctx.lineWidth = 2;
                ctx.stroke();
            }
        },
        getRectInfo() {
            let _this = this
            let realMosaicRect = []
            this.mosaic.forEach((rect)=> {                
                let newRect = {
                    x : rect.x * _this.ratio,
                    y : rect.y * _this.ratio,
                    width : rect.width * _this.ratio,
                    height : rect.height * _this.ratio
                }                
                realMosaicRect.push(newRect)
            })
            return realMosaicRect
        },
        clearRect() {
            let canvas = this.$refs["controlCanvas"]
            let ctx = canvas.getContext('2d')
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            this.mosaic = []
        },
        getImgData() {
            return this.imgData
        }
    }
}
</script>

<style scoped>
#image {
    position:relative;
    width:100%;
    height:auto;
    z-index:1;
}

#pixelateWrap{
    position:relative;
    padding:0;
    margin:5px;
    overflow:hidden;
}

#controlCanvas {
    position:absolute;     
    z-index:2;
    top:0;
    left:0;
}

</style>


