<template>
  <div id="cameraViewAndControl">
    <!-- <mjpeg-view ref="mjpegView"
    :imgBaseSrc="mjpegUrl"></mjpeg-view>-->
    <camera-control-panel
      ref="cameraControlPanel"
      :cameraControlIp="cameraControlIp"
      :cameraControlPort="cameraControlPort"
    ></camera-control-panel>
    <h2 id="controlExplanation">{{controlExplanation}}</h2>
  </div>
</template>

<script>
// import mjpegView from '@/components/camera/mjpegView.vue'
import cameraControlPanel from "./cameraControlPanel.vue";

export default {
  name: "cameraViewAndControl",
  created() {
    let _this = this;
    _this.$on("changeControl", function(args) {
      let { explanation } = args;
      _this.controlExplanation = explanation;
    });

    _this.$on("semiAutoPresetComplete", function(pData) {
      _this.$parent.$emit("semiAutoPresetComplete", pData);
    });
  },
  props: {
    mjpegUrl: {
      type: String,
      required: true
    },
    cameraControlIp: {
      type: String,
      required: true
    },
    cameraControlPort: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    controlExplanation: ""

    // mjpegUrl: '121.166.128.99:4000/getMjpeg',
    // cameraControlIp: '127.0.0.1',
    // cameraControlPort: 4000,

    // cameraId: '142',
  }),
  mounted() {
    let _this = this;
    // _this.$nextTick(function () {
    //   _this.connectCamera(_this.cameraId);
    // })
  },
  computed: {},
  methods: {
    /**
     * 카메라 연결
     * @param {string} cameraId
     */
    connectCamera(cameraId) {
      let _this = this;
      _this.playMjpeg(cameraId);
      _this.startControlCamera(cameraId);
    },

    /**
     * 카메라 연결 해제
     */
    disconnectCamera() {
      let _this = this;
      stopMjpeg();
      stopControlCamera();
    },

    /**
     * mjpeg 영상표출
     * @param {string} cameraId
     */
    playMjpeg(cameraId) {
      let _this = this;
      // let check = _this.$refs.mjpegView.stopMjpeg();
      //if (check === false) {
      // _this.$refs.mjpegView.setCamera(cameraId);
      // _this.$refs.mjpegView.playMjpeg();
      //}
    },

    /**
     * 영상 표출 중지
     */
    stopMjpeg() {
      let _this = this;
      // let check = _this.$refs.mjpegView.stopMjpeg();
      _this.$refs.cameraControlPanel.cancelCamera();
    },

    /**
     * 카메라 제어 시작
     * @param {string} cameraId
     */
    startControlCamera(cameraId) {
      let _this = this;
      _this.$refs.cameraControlPanel.setCamera(cameraId);
      let {
        explanation
      } = _this.$refs.cameraControlPanel.getCameraControlFlagInfo();
      _this.controlExplanation = explanation;
    },

    /**
     * 카메라 제어 중지
     */
    stopControlCamera() {
      let _this = this;
      _this.controlExplanation = "";
      _this.$refs.cameraControlPanel.cancelCamera();
    },

    /**
     * 반자동 단속 영역 설정
     */
    setSemiAutoPreset(flag, period) {
      let _this = this;
      _this.$refs.cameraControlPanel.setSemiAutoPreset(flag, period);
    },
    resetSemiAutoPreset() {
      this.$refs.cameraControlPanel.resetSemiAutoPreset();
    }
  },
  watch: {},
  destroyed: () => {},
  components: {
    // 'mjpeg-view' : mjpegView,
    "camera-control-panel": cameraControlPanel
  }
};
</script>

<style scoped>
#cameraViewAndControl {
  position: relative;
  margin: 0;
  padding: 0;
  border: 0;
}
#controlExplanation {
  position: absolute;
  bottom: 0;
  right: 0;
  color: rgb(255, 255, 255);
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
</style>