var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-set", staticStyle: { height: "100vh" } },
    [
      _c(
        "div",
        {
          style:
            "background-image: url(" +
            (_vm.$config.getServerConfig().image.url +
              _vm.mainImageUrl.serverFilePath) +
            ")",
          attrs: { id: "main-view" }
        },
        [
          _c("div", { staticClass: "cover abs-full" }),
          _c("div", { staticClass: "notice-event mb-both-pd" }, [
            _c(
              "div",
              { class: "notice-wrap " + (_vm.checkRoop ? "up-notice" : "") },
              [
                _vm.rotateAlram.length > 0
                  ? _vm._l(_vm.rotateAlram, function(alram, idx) {
                      return _c(
                        "div",
                        { key: idx, staticClass: "event-text d-flex" },
                        [
                          _c("div", [
                            _c(
                              "span",
                              { staticClass: "point-main point-main-wrap" },
                              [_vm._v(_vm._s(alram.typeName))]
                            )
                          ]),
                          _c("div", [_vm._v(_vm._s(alram.contents))])
                        ]
                      )
                    })
                  : _vm._e()
              ],
              2
            )
          ])
        ]
      ),
      _c("div", { staticClass: "main-content-wrap" }, [
        _c(
          "div",
          {
            staticClass: "notice mb-both-pd flex-box",
            on: { click: _vm.moveNotice }
          },
          [
            _c(
              "div",
              { staticClass: "d-flex", staticStyle: { width: "75%" } },
              [
                _c("div", { staticClass: "point-main point-main-wrap" }, [
                  _vm._v("공지")
                ]),
                _c("div", { staticClass: "alarm-contents" }, [
                  _vm._v(_vm._s(_vm.viewNoticeMessage))
                ])
              ]
            ),
            _vm._m(0)
          ]
        ),
        _c(
          "div",
          { staticClass: "content-click-container flex-box mb-both-mg" },
          [
            _vm._l(_vm.menuList, function(menuInfo) {
              return [
                menuInfo.key === "maintenance"
                  ? _c(
                      "div",
                      {
                        key: menuInfo.key,
                        staticClass: "content-click-box btn-style abs-rel",
                        on: {
                          click: function(e) {
                            _vm.onClickMenu(e, menuInfo)
                          }
                        }
                      },
                      [
                        _vm.maintnceCount
                          ? _c("div", { staticClass: "badge flex-center" }, [
                              _vm._v(_vm._s(_vm.maintnceCount))
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "text-box" }, [
                          _c("img", { attrs: { src: menuInfo.image } }),
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(menuInfo.name) +
                                "\n            "
                            )
                          ])
                        ])
                      ]
                    )
                  : _c(
                      "div",
                      {
                        key: menuInfo.key,
                        staticClass: "content-click-box btn-style",
                        on: {
                          click: function(e) {
                            _vm.onClickMenu(e, menuInfo)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "text-box" }, [
                          _c("img", { attrs: { src: menuInfo.image } }),
                          _c("div", { staticClass: "title" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(menuInfo.name) +
                                "\n            "
                            )
                          ])
                        ])
                      ]
                    )
              ]
            })
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("전체보기"),
      _c("i", {
        staticClass: "icon-arrow",
        staticStyle: { display: "inline-block", transform: "rotate(-90deg)" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }