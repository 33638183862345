<template>
  <div class="fill-width-height el-container" id="pageStatistics">
    <el-container class="page-body">
      <el-main>
        <div class="content-wrap">
          <dk-page-header></dk-page-header>
          <!-- 기간 설정 / PDF 버튼 / Excel 버튼  -->
          <div class="d-flex">
            <el-form ref="form" :model="formSearch" label-width="120px" style="width: 100%;">
              <el-col :span="20">
                <el-form-item label="기간 설정">
                  <el-col :span="4">
                    <el-date-picker
                      @change="changeDate('start')"
                      type="date"
                      placeholder="검색 시작일"
                      v-model="searchStartDate"
                      style="width: 100%;"
                    ></el-date-picker>
                  </el-col>
                  <el-col :span="1" align="center">-</el-col>
                  <el-col :span="4">
                    <el-date-picker
                      @change="changeDate('end')"
                      placeholder="검색 종료일"
                      v-model="searchEndDate"
                      style="width: 100%;"
                    ></el-date-picker>
                  </el-col>
                  <el-col :span="8" style="margin-left: 10px;">
                    <el-button type="primary" @click="selectWeek" ref="week" plain>일주일</el-button>
                    <el-button type="primary" @click="selectMonth" ref="month" plain>한달</el-button>
                    <el-button type="primary" @click="selectAll" plain>1년</el-button>
                    <el-button style="margin-left: 20px;" type="primary" @click="search">검색</el-button>
                  </el-col>
                </el-form-item>
              </el-col>
              <el-col :span="4" align="right">
                <el-button size="mini" class="icon-button-2" @click="openPdf">
                  <img src="../assets/img/pdf.png" style="height: auto; padding-right: 3px; width: 20px;" />PDF 출력
                </el-button>
                <el-button size="mini" class="icon-button-2" @click="onClickExcelDown">
                  <img src="../assets/img/excel.png" style="height: auto; padding-right: 3px; width: 20px;" />Excel 출력
                </el-button>
              </el-col>
            </el-form>
          </div>

          <!-- 데이터 표현 방식 -->
          <el-form style="position: absolute; right: 0; width: 367px; z-index: 100;">
            <el-form-item label="데이터 표현 방식" size="mini">
              <el-button :disabled="this.dayBtn" type="primary" plain @click="day" v-bind:class="[dateClick('btn0')]">일 단위</el-button>
              <!-- <el-button :disabled="this.weekBtn" type="primary" plain @click="week" v-bind:class="[dateClick('btn1')]">주간</el-button> -->
              <el-button :disabled="this.monthBtn" type="primary" plain @click="month" v-bind:class="[dateClick('btn2')]">월 단위</el-button>
              <el-button :disabled="this.yearBtn" type="primary" plain @click="year" v-bind:class="[dateClick('btn3')]">연 단위</el-button>
            </el-form-item>
          </el-form>

          <!-- 탭 부분 시작 -->
          <el-tabs class="stat-tab" @tab-click="handleClick" v-model="tab">
            <!-- 공원 입출 통계 탭 -->
            <el-tab-pane label="공원 입출 통계" class="d-flex" name="parkInoutChart">
              <ul class="park-list">
                <li>공원 목록</li>
                <li @click="choicePark(item)" v-for="item in parkInoutList" :key="item.key" :class="[isParkClick(item)]">{{ item.name }}</li>
              </ul>
              <div style="width: 100%;" :id="pdfInfo[1].id">
                <!-- 전체 진출입 통행량 차트 -->
                <el-row type="flex" justify="center">
                  <el-col :span="18">
                    <div class="box">
                      <div class="box-head">
                        <span style="color: #409eff;">{{ this.parkSelect }}</span>
                        <span> 전체 진입/진출 통행량</span>
                      </div>
                      <div style="height: calc(100% - 40px);">
                        <template v-if="(chartDate.inoutTotal.length !== 0)">
                          <column-time-chart
                            :height="300"
                            :dateExpression="chartExpression"
                            :cols="chartOption.inoutTotal.cols"
                            :data="chartDate.inoutTotal"
                            :vMin="0"
                          />
                        </template>
                        <template v-else>
                          <div class="nodata">데이터 없음.</div>
                        </template>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <!-- 개별 진출입 통행량 차트 -->
                <el-row type="flex" justify="center">
                  <el-col :span="18">
                    <el-col :span="8" v-for="(item, idx) in chartDataInoutFclt" :key="item.fcltId">
                      <div style="font-size: 14px; margin: 0 10px;">{{ item.fcltName }}</div>
                      <div class="box" style="height: 220px;">
                        <template v-if="(!item.data || item.data.length !== 0)">
                          <column-time-chart
                            :height="220"
                            :dateExpression="chartExpression"
                            :cols="chartOption.inoutFclt.cols"
                            :data="item.data"
                            :vMin="0"
                          />
                        </template>
                        <template v-else>
                          <div class="nodata">데이터 없음</div>
                        </template>
                      </div>
                    </el-col>
                  </el-col>
                </el-row>
              </div>
            </el-tab-pane>

            <!-- 자전거 도로 통계 탭 -->
            <el-tab-pane label="자전거 도로 통계" class="d-flex" name="vehicleChart">
              <ul class="park-list">
                <li>공원 목록</li>
                <li @click="choicePark(item)" v-for="item in parkbicycleList" :key="item.key" :class="[isParkClick(item)]">{{ item.name }}</li>
              </ul>
              <div style="width: 100%;" :id="pdfInfo[2].id">
                <div class="camere-select">
                  <span>카메라 선택</span>
                  <el-select v-model="fcltSelect" placeholder="카메라를 선택하세요" style="width: 250px;">
                    <el-option v-for="item in fcltList" :key="item.fcltId" :label="item.fcltName" :value="item.fcltId"></el-option>
                  </el-select>
                </div>
                <!-- 자전거 통행량 차트 -->
                <el-row type="flex" justify="center">
                  <el-col :span="11">
                    <div class="box" style="height: 535px;">
                      <div class="box-head">
                        자전거 통행량
                      </div>
                      <div style="height: calc(100% - 40px);">
                        <template v-if="(chartDate.bicycleObjectPass.length !== 0)">
                          <column-time-chart
                            :height="500"
                            :dateExpression="chartExpression"
                            :cols="chartOption.bicycleObjectPass.cols"
                            :data="chartDate.bicycleObjectPass"
                            :vMin="0"
                          />
                        </template>
                        <template v-else>
                          <div class="nodata">데이터 없음</div>
                        </template>
                      </div>
                    </div>
                  </el-col>
                  <!-- 평균속도/최고속도 차트 -->
                  <el-col :span="11">
                    <div class="box" style="height: 535px;">
                      <div class="box-head">
                        평균속도/최고속도
                      </div>
                      <div style="height: calc(100% - 40px);">
                        <template v-if="(chartDate.speed.length !== 0)">
                          <line-time-chart
                            :height="500"
                            :dateExpression="chartExpression"
                            :cols="chartOption.speed.cols"
                            :data="chartDate.speed"
                            :vMin="0"
                            :vMax="100"
                          />
                        </template>
                        <template v-else>
                          <div class="nodata">데이터 없음</div>
                        </template>
                      </div>
                    </div>
                  </el-col>
                  <!-- 방향별 자전거 통행량 차트: 안쓰임 
                    <el-col :span="11">
                    <div class="box">
                      <div class="box-head">
                        방향별 자전거 통행량
                      </div>
                      <div style="height: calc(100% - 40px);">
                        <column-time-chart
                          :height="300"
                          :dateExpression="chartExpression"
                          :cols="chartOption.directionPass.cols"
                          :data="chartDate.directionPass"
                          :vMin="0"
                        />
                      </div>
                    </div>
                  </el-col> -->
                  <!-- 평균속도랑 최고속도 합쳐짐 
                    <el-col :span="11">
                    <div class="box">
                      <div class="box-head">
                        평균속도
                      </div>
                      <div style="height: calc(100% - 40px);">
                        <line-time-chart
                          :height="300"
                          :dateExpression="chartExpression"
                          :cols="chartOption.maxSpeed.cols"
                          :data="chartDate.maxSpeed"
                          :vMin="0"
                          :vMax="100"
                        />
                      </div>
                    </div>
                  </el-col> -->
                </el-row>
              </div>
            </el-tab-pane>

            <!-- 공원별 CCTV 통계 탭 -->
            <el-tab-pane label="공원별 CCTV 통계" class="d-flex" name="parkCctvChart">
              <ul class="park-list">
                <li>공원 목록</li>
                <li @click="choicePark(item)" v-for="item in parkList" :key="item.key" :class="[isParkClick(item)]">
                  {{ item.name }}
                </li>
              </ul>
              <div style="width: 100%;" :id="pdfInfo[0].id">
                <div class="camere-select">
                  <span>카메라 선택</span>
                  <el-select v-model="fcltSelect" placeholder="카메라를 선택하세요" style="width: 250px;">
                    <el-option v-for="item in fcltList" :key="item.fcltId" :label="item.fcltName" :value="item.fcltId"></el-option>
                  </el-select>
                </div>
                <!-- 사람 통행량 차트 -->
                <el-row type="flex" justify="center">
                  <el-col :span="11">
                    <div class="box pdf">
                      <div class="box-head">
                        사람 통행량
                      </div>
                      <div style="height: calc(100% - 40px);">
                        <template v-if="(chartDate.peoplePass.length !== 0)">
                          <column-time-chart
                            :height="300"
                            :dateExpression="chartExpression"
                            :cols="chartOption.peoplePass.cols"
                            :data="chartDate.peoplePass"
                            :vMin="0"
                            :colors="chartColor.peoplePass"
                          />
                        </template>
                        <template v-else>
                          <div class="nodata">데이터 없음</div>
                        </template>
                      </div>
                    </div>
                  </el-col>
                  <!-- 자전거 통행량 차트 -->
                  <el-col :span="11">
                    <div class="box pdf">
                      <div class="box-head">
                        자전거 통행량
                      </div>
                      <div style="height: calc(100% - 40px);">
                        <template v-if="(chartDate.bicycleObjectPass.length !== 0)">
                          <column-time-chart
                            :height="300"
                            :dateExpression="chartExpression"
                            :cols="chartOption.bicycleObjectPass.cols"
                            :data="chartDate.bicycleObjectPass"
                            :vMin="0"
                          />
                        </template>
                        <template v-else>
                          <div class="nodata">데이터 없음</div>
                        </template>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <!-- 사람/자전거 비율 차트 -->
                <el-row type="flex" justify="center">
                  <el-col :span="4">
                    <div class="box pdf">
                      <div class="box-head">
                        사람/자전거 비율
                      </div>
                      <div style="height: calc(100% - 40px);">
                        <template v-if="(chartDate.objectRate.length !== 0)">
                          <pie-chart :cols="chartOption.objectRate.cols" :data="chartDate.objectRate" />
                        </template>
                        <template v-else>
                          <div class="nodata">데이터 없음</div>
                        </template>
                      </div>
                    </div>
                  </el-col>
                  <!-- 성별 타입 차트 -->
                  <el-col :span="4">
                    <div class="box pdf">
                      <div class="box-head">
                        성별 타입
                      </div>
                      <div style="height: calc(100% - 40px);">
                        <template v-if="(chartDate.gender.length !== 0)">
                          <pie-chart :cols="chartOption.gender.cols" :data="chartDate.gender" />
                        </template>
                        <template v-else>
                          <div class="nodata">데이터 없음</div>
                        </template>
                      </div>
                    </div>
                  </el-col>
                  <!-- 마스크 유무 차트 -->
                  <el-col :span="4">
                    <div class="box pdf">
                      <div class="box-head">
                        마스크 유무
                      </div>
                      <div style="height: calc(100% - 40px);">
                        <template v-if="(chartDate.mask.length !== 0)">
                          <pie-chart :cols="chartOption.mask.cols" :data="chartDate.mask" />
                        </template>
                        <template v-else>
                          <div class="nodata">데이터 없음</div>
                        </template>
                      </div>
                    </div>
                  </el-col>
                  <!-- 탈것별 통행량 차트 -->
                  <el-col :span="10">
                    <div class="box pdf">
                      <div class="box-head">
                        탈 것별 통행량
                      </div>
                      <div style="height: calc(100% - 40px);">
                        <template v-if="(chartDate.bicycleObjectPass.length !== 0)">
                          <column-time-chart
                            :height="300"
                            :dateExpression="chartExpression"
                            :cols="chartOption.vehiclePass.cols"
                            :data="chartDate.vehiclePass"
                            :vMin="0"
                            :colors="chartColor.vehiclePass"
                          />
                        </template>
                        <template v-else>
                          <div class="nodata">데이터 없음</div>
                        </template>
                      </div>
                    </div>
                  </el-col>
                  <!-- 방향별 통행량 :삭제 
                    <el-col :span="11">
                    <div class="box pdf">
                      <div class="box-head">
                        방향별 통행량
                      </div>
                      <div style="height: calc(100% - 40px);">
                        <template v-if="(chartOption.directionPass.cols.length !== 0 || chartDate.directionPass.length !== 0)">
                          <column-time-chart
                            :height="300"
                            :dateExpression="chartExpression"
                            :cols="chartOption.directionPass.cols"
                            :data="chartDate.directionPass"
                            :vMin="0"
                          />
                        </template>
                        <template v-else>
                          <div class="nodata">데이터가 존재하지 않습니다.</div>
                        </template>
                      </div>
                    </div>
                  </el-col> -->
                </el-row>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-main>
    </el-container>
    <form-statistic-pdf ref="formStatisticsPdf"></form-statistic-pdf>
  </div>
</template>

<script>
import moment from 'moment';
import formStatisticsPdf from '@/core/forms/formStatisticsPdf.vue';
import columnTimeChart from '@/components/chart/columnTimeChart.vue';
import lineTimeChart from '@/components/chart/lineTimeChart.vue';
import pieChart from '@/components/chart/pieChart.vue';
import _ from 'lodash';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import XLSX from 'xlsx';

export default {
  components: {
    'column-time-chart': columnTimeChart,
    'pie-chart': pieChart,
    'line-time-chart': lineTimeChart,
    'form-statistic-pdf': formStatisticsPdf,
  },
  data() {
    return {
      chartOption: {
        // 전체 입출
        inoutTotal: {
          cols: [
            { label: '날짜', type: 'date' },
            { label: '진입', type: 'number' },
            { label: '진출', type: 'number' },
          ],
        },
        // 개별 입출
        inoutFclt: {
          cols: [
            { label: '날짜', type: 'date' },
            { label: '진입', type: 'number' },
            { label: '진출', type: 'number' },
          ],
        },
        // 자전거 통행량
        bicycleObjectPass: {
          cols: [
            { label: '날짜', type: 'date' },
            { label: '자전거(자전거+PM)', type: 'number' },
          ],
        },
        // 속도
        speed: {
          cols: [
            { label: '날짜', type: 'date' },
            { label: '평균속도', type: 'number' },
            { label: '최고속도', type: 'number' },
          ],
        },
        // 사람 통행량
        peoplePass: {
          cols: [
            { label: '날짜', type: 'date' },
            { label: '사람', type: 'number' },
          ],
        },
        // 사람,자전거 비율
        objectRate: {
          cols: [
            { label: '사람/자전거', type: 'string' },
            { label: 'count', type: 'number' },
          ],
        },
        //성별 비율
        gender: {
          cols: [
            { label: '셩별 타입', type: 'string' },
            { label: 'count', type: 'number' },
          ],
        },
        //마스크 비율
        mask: {
          cols: [
            { label: '마스크 유무', type: 'string' },
            { label: 'count', type: 'number' },
          ],
        },
        // 탈것별 통행량
        vehiclePass: {
          cols: [
            { label: '날짜', type: 'date' },
            { label: '차', type: 'number' },
            { label: '오토바이', type: 'number' },
            { label: '자전거', type: 'number' },
            { label: 'PM', type: 'number' },
          ],
        },
        // // 방향별 통행량: 삭제
        // directionPass: {
        //   cols: [
        //     // 카메라마다 방향 설정이 다르므로 데이터 가공에서 col값 넣어줌. 아래는 형태 예시
        //     // { label: '날짜', type: 'date' },
        //     // { label: '방향명', type: 'number' }
        //   ],
        // },
        // // 평균 속도: 삭제
        // avgSpeed: {
        //   cols: [
        //     { label: '날짜', type: 'date' },
        //     { label: '평균속도', type: 'number' },
        //   ],
        // },
        // // 최고 속도: 삭제
        // maxSpeed: {
        //   cols: [
        //     { label: '날짜', type: 'date' },
        //     { label: '최고속도', type: 'number' },
        //   ],
        // },
        explorer: {
          axis: 'horizontal',
          keepInBounds: true,
          maxZoomIn: 4.0,
          maxZoomOut: 1,
        },
        vAxis: {
          viewWindow: {
            min: 0,
          },
        },
      },
      //차트 row값 초기화
      chartDate: {
        inoutTotal: [], // 전체 출입
        bicycleObjectPass: [], //자전거 통행량
        speed: [], //속도
        peoplePass: [], //사람 통행량
        objectRate: [], //사람자전거 비율
        gender: [], //성별
        mask: [], //마스크
        vehiclePass: [], //탈것별 통행량
        // directionPass: [], :삭제
        // avgSpeed: [], :삭제
        // maxSpeed: [], :삭제
      },
      chartDataInoutFclt: [], // 개별 출입 chartDate
      chartColor: {
        inoutTotal: [], // 전체 출입
        bicycleObjectPass: [], //자전거 통행량
        speed: [], //속도
        // peoplePass: ['#007be1'], //사람 통행량
        peoplePass: ['#007ce3'], //사람 통행량
        objectRate: [], //사람자전거 비율
        gender: [], //성별
        mask: [], //마스크
        vehiclePass: ['#005fb1', '#007ce3', '#52b1fb', '#c6d9f1'], //탈것별 통행량
        // vehiclePass: ['#75c9a8', '#e54a5c', '#fecd57', '#2bb9eb'], //탈것별 통행량
      },
      tab: 'parkInoutChart',
      tabName: {
        parkCctvChart: 'parkCctvChart',
        parkInoutChart: 'parkInoutChart',
        vehicleChart: 'vehicleChart',
      },

      chartExpression: 'day',
      isDataPeriod: '일간',
      expression: '$dayOfYear',
      dayBtn: false,
      weekBtn: false,
      monthBtn: false,
      yearBtn: false,

      fcltList: [],
      fcltSelect: '',
      fcltDirection: [],

      parkList: [
        { key: 'park1', name: '반포한강공원' },
        { key: 'park2', name: '잠원한강공원' },
        { key: 'park3', name: '난지한강공원' },
        { key: 'park4', name: '강서한강공원' },
        { key: 'park5', name: '양화한강공원' },
        { key: 'park6', name: '이촌한강공원' },
        { key: 'park7', name: '뚝섬한강공원' },
        { key: 'park8', name: '잠실한강공원' },
        { key: 'park9', name: '광나루한강공원' },
      ],
      parkInoutList: [
        { key: 'park1', name: '반포한강공원' },
        { key: 'park2', name: '잠원한강공원' },
      ],
      parkbicycleList: [
        { key: 'park1', name: '반포한강공원' },
        { key: 'park2', name: '잠원한강공원' },
        { key: 'park3', name: '난지한강공원' },
        { key: 'park4', name: '강서한강공원' },
        { key: 'park5', name: '양화한강공원' },
        { key: 'park7', name: '뚝섬한강공원' },
        { key: 'park8', name: '잠실한강공원' },
        { key: 'park9', name: '광나루한강공원' },
      ],
      parkSelect: '반포한강공원',

      searchStartDate: '',
      searchEndDate: '',
      searchFcltTypeId: '',

      currentBtn: 'btn0',
      isBtn: true,
      formSearch: {},

      pdfInfo: [
        {
          id: 'pdf1',
          tab: 'parkCctvChart',
          fileName: '공원별CCTV통계',
          rotate: 90,
        },
        {
          id: 'pdf2',
          tab: 'parkInoutChart',
          fileName: '공원입출통계',
        },
        {
          id: 'pdf3',
          tab: 'vehicleChart',
          fileName: '자전거도로통계',
          rotate: 90,
        },
      ],
    };
  },

  async mounted() {
    this.dateInit();
    await this.requestGetFclt();
    // await this.requestGetFcltDirection();
  },

  methods: {
    // 화면 첫 접속 시 기간 초기화 설정
    dateInit() {
      let setStart = moment().startOf('day').subtract(6, 'days').toDate();
      let setEnd = moment().set('hour', 23).set('minute', 59).set('second', 59).toDate();
      this.searchEndDate = setEnd;
      this.searchStartDate = setStart;
    },

    // 공원 입출 통계들 불러오기
    async showParkInoutChart() {
      let fcltList = this.fcltList;
      // 전체 입출 통계
      (async () => {
        const reqInoutTotal = await this.requestInoutTotal();
        this.showInoutTotal(reqInoutTotal);
      })();
      // 개별 입출 통계: fclt갯수에 따라서 넣어야하는 데이터 수가 달라지기때문에 아래와 같이 처리
      this.chartDataInoutFclt = fcltList.map(() => {
        return {};
      });
      for (let i = 0; i < fcltList.length; i++) {
        (async (idx) => {
          const reqInoutFclt = await this.requestInoutFclt(fcltList[idx].fcltId);
          const cData = this.showInoutFclt(reqInoutFclt, fcltList[idx].fcltId, fcltList[idx].fcltName);
          const chartDataInoutFclt = [...this.chartDataInoutFclt];
          chartDataInoutFclt[idx] = cData;
          this.chartDataInoutFclt = chartDataInoutFclt;
        })(i);
      }
    },
    // 자전거 도로 통계 불러오기
    async showVehicleChart() {
      (async () => {
        const reqObjectPass = await this.requestObjectPass();
        this.showBicyclePassChart(reqObjectPass);
      })();
      (async () => {
        const reqSpeed = await this.requestSpeed();
        this.showSpeed(reqSpeed);
        // this.showAvgSpeed(reqSpeed); //삭제
        // this.showMaxSpeed(reqSpeed); //삭제
      })();
      // //자전거 방향별 통행량: 삭제
      // (async () => {
      //   const reqVehicleDirectionPass = await this.requestVehicleDirectionPass();
      //   this.showDirectionPassChart(reqVehicleDirectionPass);
      // })();
    },
    // 공원별 CCTV 통계 불러오기
    async showParkCctvChart() {
      // 사람통행량, 자전거 통행량
      (async () => {
        const reqObjectPass = await this.requestObjectPass();
        this.showPeoplePassChart(reqObjectPass);
        this.showBicyclePassChart(reqObjectPass);
      })();
      // 마스크 유무
      (async () => {
        const reqMaskWear = await this.requestMaskWear();
        this.showMaskChart(reqMaskWear);
      })();
      // 성별 타입
      (async () => {
        const reqGender = await this.requestGenderType();
        this.showGenderType(reqGender);
      })();
      // 사람자전거 비율
      (async () => {
        const reqRatePass = await this.requestRatePass();
        this.showObjectRateChart(reqRatePass);
      })();
      // 탈것별 통행량
      (async () => {
        const reqVehiclePass = await this.requestVehiclePass();
        this.showVehiclePassChart(reqVehiclePass);
      })();
      // // 방향별 통행량: 삭제
      // (async () => {
      //   const reqDirectionPass = await this.requestDirectionPass();
      //   this.showDirectionPassChart(reqDirectionPass);
      // })();
    },

    /*****************************************API 호출*****************************************/
    //API 호출 - 카메라 불러오기
    async requestGetFclt() {
      const me = this;
      try {
        let params = {
          keyword: [],
        };
        if (me.tab === this.tabName.parkCctvChart) {
          params.keyword.push(this.parkSelect);
        } else if (me.tab === this.tabName.parkInoutChart) {
          params.keyword.push(this.parkSelect, '출입검지');
        } else if (me.tab === this.tabName.vehicleChart) {
          params.keyword.push(this.parkSelect, '자전거속도측정');
        }
        const response = await me.$http.post(`${this.$config.getServerConfig().core.api}/core/api/aicam/fclt`, { data: params });
        // 선택한 공원의 카메라 리스트 호출
        for (let i = 0; i < response.data.length; i++) {
          me.fcltList.push({ fcltName: response.data[i].fcltName, fcltId: response.data[i].fcltId });
        }
        // 현재 선택된 카메라로 첫번째 카메라를 넣어줌
        me.fcltSelect = me.fcltList[0].fcltId;
        return response.data;
      } catch (err) {
        console.error('requestGetFclt Error');
      }
    },
    // //API 호출 - fclt 방향 갯수 조회
    // async requestGetFcltDirection() {
    //   const me = this;
    //   try {
    //     let params = {
    //       fcltId: me.fcltSelect,
    //     };
    //     const response = await me.$http.get(/core/api/fcltDirection/search', { params: params });
    //     me.fcltDirection = [];
    //     for (let i = 0; i < response.data.length; i++) {
    //       me.fcltDirection.push({ fcltName: response.data[i].desc, fcltId: response.data[i].fcltDirectionId });
    //     }
    //     return response.data;
    //   } catch (err) {
    //     console.error('requestGetFcltDirection Error');
    //   }
    // },
    //API 호출 - 전체 출입
    async requestInoutTotal() {
      const me = this;
      try {
        let params = {
          startDate: me.searchStartDate,
          endDate: me.searchEndDate,
          keyword: [this.parkSelect, '출입검지'],
          expression: me.expression,
        };
        const response = await me.$http.post(`${this.$config.getServerConfig().core.api}/core/api/aicam/aggregate/inout/park`, {
          data: params,
        });
        /*************** response 값 ****************
         * "inCounting": 4,
         * "outCounting": 2
         ********************************************/
        return response.data;
      } catch (err) {
        console.error('requestInoutTotal Error');
      }
    },
    //API 호출 -  개별 출입
    async requestInoutFclt(fcltId) {
      const me = this;
      try {
        let params = {
          startDate: me.searchStartDate,
          endDate: me.searchEndDate,
          fcltId,
          expression: me.expression,
        };
        const response = await me.$http.post(`${this.$config.getServerConfig().core.api}/core/api/aicam/aggregate/inout/fclt`, {
          data: params,
        });
        /*************** response 값 ****************
         * "inCounting": 4,
         * "outCounting": 2
         ********************************************/
        return response.data;
      } catch (err) {
        console.error('requestInoutFclt Error');
      }
    },
    //API 호출 - 사람/자전거 통행량
    async requestObjectPass() {
      const me = this;
      try {
        let params = {
          startDate: me.searchStartDate,
          endDate: me.searchEndDate,
          fcltId: me.fcltSelect,
          expression: me.expression,
        };
        const response = await me.$http.post(`${this.$config.getServerConfig().core.api}/core/api/aicam/aggregate/detecttype`, {
          data: params,
        });
        /*************** response 값 ****************
         * "peopleCounting": 4,
         * "bicycleCounting": 2
         ********************************************/
        return response.data;
      } catch (err) {
        console.error('requestObjectPass Error');
      }
    },
    //API 호출 - 속도
    async requestSpeed() {
      const me = this;
      try {
        let params = {
          startDate: me.searchStartDate,
          endDate: me.searchEndDate,
          fcltId: me.fcltSelect,
          expression: me.expression,
        };
        const response = await me.$http.post(`${this.$config.getServerConfig().core.api}/core/api/aicam/aggregate/speed`, {
          data: params,
        });
        /*************** response 값 ****************
         * "totalCounting": 0,
         * "avgSpeed": 0,
         * "maxSpeed": 0
         ********************************************/
        return response.data;
      } catch (err) {
        console.error('requestSpeed Error');
      }
    },
    //API 호출 - 사람자전거 비율
    async requestRatePass() {
      const me = this;
      try {
        let params = {
          startDate: me.searchStartDate,
          endDate: me.searchEndDate,
          fcltId: me.fcltSelect,
        };
        const response = await me.$http.post(`${this.$config.getServerConfig().core.api}/core/api/aicam/aggregate/detecttype/ratio`, {
          data: params,
        });

        /*************** response 값 ****************
         * "peopleCounting": 34,
         * "bicycleCounting": 21
         ********************************************/
        return response.data;
      } catch (err) {
        console.error('requestRatePass Error');
      }
    },
    //API 호출 - 마스크유무
    async requestMaskWear() {
      const me = this;
      try {
        let params = {
          startDate: me.searchStartDate,
          endDate: me.searchEndDate,
          fcltId: me.fcltSelect,
        };
        const response = await me.$http.post(`${this.$config.getServerConfig().core.api}/core/api/aicam/aggregate/mask/ratio`, {
          data: params,
        });
        /*************** response 값 ****************
         * "maskOnCounting": 564,
         * "maskOffCounting": 537
         ********************************************/
        return response.data;
      } catch (err) {
        console.error('requestMaskWears Error');
      }
    },
    //API 호출 - 성별비율
    async requestGenderType() {
      const me = this;
      try {
        let params = {
          startDate: me.searchStartDate,
          endDate: me.searchEndDate,
          fcltId: me.fcltSelect,
        };
        const response = await me.$http.post(`${this.$config.getServerConfig().core.api}/core/api/aicam/aggregate/gendertype/ratio`, {
          data: params,
        });
        /*************** response 값 ****************
         * "womanCounting": 11,
         * "manCounting": 20
         ********************************************/
        return response.data;
      } catch (err) {
        console.error('requestGenderType Error');
      }
    },
    //API 호출 - 탈 것별 통행량
    async requestVehiclePass() {
      const me = this;
      try {
        let params = {
          startDate: me.searchStartDate,
          endDate: me.searchEndDate,
          fcltId: me.fcltSelect,
          expression: me.expression,
        };
        const response = await me.$http.post(`${this.$config.getServerConfig().core.api}/core/api/aicam/aggregate/vehicletype`, {
          data: params,
        });
        /*************** response 값 ****************
         * "vehicleType": 1~6,
         * "totalCounting": 20
         ********************************************/
        return response.data;
      } catch (err) {
        console.error('requestVehiclePass Error');
      }
    },
    // //API 호출 - 방향별 통행량: 삭제
    // async requestDirectionPass() {
    //   const me = this;
    //   try {
    //     let params = {
    //       startDate: me.searchStartDate,
    //       endDate: me.searchEndDate,
    //       fcltId: me.fcltSelect,
    //       expression: me.expression,
    //     };
    //     const response = await me.$http.post('/coreapi/core/api/aicam/aggregate/direction', {
    //       data: params,
    //     });
    //     return response.data;
    //   } catch (err) {
    //     console.error('requestObjectPass Error');
    //   }
    // },
    // //API 호출 - 탈것별 방향별 통행량 : 삭제
    // async requestVehicleDirectionPass() {
    //   const me = this;
    //   try {
    //     let params = {
    //       startDate: me.searchStartDate,
    //       endDate: me.searchEndDate,
    //       fcltId: me.fcltSelect,
    //       expression: me.expression,
    //       vehicleType: 1,
    //       //자전거는 5지만 데이터가 없어서 임의로 1 넣어놓음.
    //       //현재 자전거값만 필요한 상태라서 하드코딩해놓음
    //     };
    //     const response = await me.$http.post('/coreapi/core/api/aicam/aggregate/direction/vehicletype', {
    //       data: params,
    //     });
    //     return response.data;
    //   } catch (err) {
    //     console.error('requestVehicleDirectionPass Error');
    //   }
    // },

    /*****************************************데이터 가공*****************************************/
    //데이터 가공
    //데이터 가공 -  전체 출입
    showInoutTotal(args) {
      const inoutTotal = args.map((data) => {
        const { _id, aggregation } = data;
        const { inCounting, outCounting } = aggregation;
        return {
          date: new Date(_id),
          value: [inCounting, outCounting],
        };
      });
      this.chartDate.inoutTotal = inoutTotal;
    },
    //데이터 가공 -  개별 출입
    showInoutFclt(args, fcltId, fcltName) {
      const inoutFclt = args.map((data) => {
        const { _id, aggregation } = data;
        const { inCounting, outCounting } = aggregation;
        return {
          date: new Date(_id),
          value: [inCounting, outCounting],
        };
      });
      const chartDataInoutFclt = {
        fcltId,
        fcltName,
        data: inoutFclt,
      };
      return chartDataInoutFclt;
    },
    //데이터 가공 - 사람 통행량
    showPeoplePassChart(args) {
      const peoplePass = args.map((data) => {
        const { _id, aggregation } = data;
        const { peopleCounting } = aggregation;
        return {
          date: new Date(_id),
          value: [peopleCounting],
        };
      });
      this.chartDate.peoplePass = peoplePass;
    },
    //데이터 가공 - 자전거 통행량
    showBicyclePassChart(args) {
      const bicycleObjectPass = args.map((data) => {
        const { _id, aggregation } = data;
        const { bicycleCounting } = aggregation;
        return {
          date: new Date(_id),
          value: [bicycleCounting],
        };
      });
      this.chartDate.bicycleObjectPass = bicycleObjectPass;
    },
    //데이터 가공 - 속도
    showSpeed(args) {
      const speed = args.map((data) => {
        const { _id, aggregation } = data;
        const { avgSpeed, maxSpeed } = aggregation;
        return {
          date: new Date(_id),
          value: [avgSpeed, maxSpeed],
        };
      });
      this.chartDate.speed = speed;
    },
    //데이터 가공 - 사람자전거 비율
    showObjectRateChart(args) {
      if (!args[0] || (args[0].peopleCounting == 0 && args[0].bicycleCounting == 0)) {
        this.chartDate.objectRate = [];
      } else {
        let peopleCounting = args[0].peopleCounting;
        let bicycleCounting = args[0].bicycleCounting;
        this.chartDate.objectRate = [
          {
            name: '사람',
            value: peopleCounting,
          },
          {
            name: '자전거',
            value: bicycleCounting,
          },
        ];
      }
    },
    //데이터 가공 - 마스크 유무
    showMaskChart(args) {
      if (!args[0] || (args[0].maskOnCounting == 0 && args[0].maskOffCounting == 0)) {
        this.chartDate.mask = [];
      } else {
        let totalMaskOn = args[0].maskOnCounting;
        let totalMaskOff = args[0].maskOffCounting;
        this.chartDate.mask = [
          {
            name: '마스크 착용',
            value: totalMaskOn,
          },
          {
            name: '마스크 미착용',
            value: totalMaskOff,
          },
        ];
      }
    },
    //데이터 가공 - 성별 비율
    showGenderType(args) {
      if (!args[0] || (args[0].manCounting == 0 && args[0].womanCounting == 0)) {
        this.chartDate.gender = [];
      } else {
        let manCounting = args[0].manCounting;
        let womanCounting = args[0].womanCounting;
        this.chartDate.gender = [
          {
            name: '남자',
            value: manCounting,
          },
          {
            name: '여자',
            value: womanCounting,
          },
        ];
      }
    },
    //데이터 가공 - 탈 것별 통행량
    showVehiclePassChart(args) {
      args.forEach((val) => {
        val.day = moment(val._id).format('YYYY-MM-DD');
      });
      const classifyData2 = _.groupBy(args, 'day');
      const vehiclePass = [];
      for (let key in classifyData2) {
        const dataList = classifyData2[key];
        if (Array.isArray(dataList)) {
          const date = new Date(dataList[0]._id);
          const value = [0, 0, 0, 0];
          dataList.find((data) => {
            switch (data.vehicleType) {
              case 1:
                value[0] = data.totalCounting; //차
                break;
              case 4:
                value[1] = data.totalCounting; //오토바이
                break;
              case 5:
                value[2] = data.totalCounting; //자전거
                break;
              case 6:
                value[3] = data.totalCounting; //PM
                break;
            }
            return 0;
          });
          vehiclePass.push({
            date,
            value,
          });
        }
      }
      this.chartDate.vehiclePass = vehiclePass;
    },
    // //데이터 가공 - 방향별 통행량 막대 차트 : 삭제
    // showDirectionPassChart(args) {
    //   if (!args) {
    //     return;
    //   }
    //   //중복없는 방향name값을 col에 넣어줌으로써 col 생성
    //   if (this.fcltDirection.length !== 0) {
    //     this.chartOption.directionPass.cols = [{ label: '날짜', type: 'date' }];
    //   } else {
    //     this.chartOption.directionPass.cols = [];
    //   }
    //   for (let i = 0; i < this.fcltDirection.length; i++) {
    //     this.chartOption.directionPass.cols.push({ label: this.fcltDirection[i].fcltName, type: 'number' });
    //   }
    //   // row 만들기
    //   args.forEach((val) => {
    //     val.day = moment(val._id).format('YYYY-MM-DD');
    //   });
    //   const classifyData2 = _.groupBy(args, 'day');
    //   const directionPass = [];
    //   for (let key in classifyData2) {
    //     const dataList = classifyData2[key];
    //     if (Array.isArray(dataList)) {
    //       const date = new Date(dataList[0]._id);
    //       const value = this.fcltDirection.map((direction) => {
    //         const data = dataList.find((data) => {
    //           return data.directionDesc === direction.fcltName;
    //         });
    //         if (!data) {
    //           return 0;
    //         }
    //         return data.aggregation.totalCounting;
    //       });
    //       directionPass.push({
    //         date,
    //         value,
    //       });
    //     }
    //   }
    //   this.chartDate.directionPass = directionPass;
    // },
    // //데이터 가공 -  평균속도 : 통합삭제
    // showAvgSpeed(args) {
    //   const avgSpeed = args.map((data) => {
    //     const { _id, aggregation } = data;
    //     const { avgSpeed } = aggregation;
    //     return {
    //       date: new Date(_id),
    //       value: [avgSpeed],
    //     };
    //   });

    //   this.chartDate.avgSpeed = avgSpeed;
    // },
    // //데이터 가공 -  최고속도 : 통합삭제
    // showMaxSpeed(args) {
    //   const maxSpeed = args.map((data) => {
    //     const { _id, aggregation } = data;
    //     const { maxSpeed } = aggregation;
    //     return {
    //       date: new Date(_id),
    //       value: [maxSpeed],
    //     };
    //   });

    //   this.chartDate.maxSpeed = maxSpeed;
    // },
    showChart() {
      if (this.tab === this.tabName.parkCctvChart) {
        this.showParkCctvChart();
      } else if (this.tab === this.tabName.parkInoutChart) {
        this.showParkInoutChart();
      } else if (this.tab === this.tabName.vehicleChart) {
        this.showVehicleChart();
      }
    },
    //공원 선택 시
    async choicePark(item) {
      this.parkSelect = item.name;
      this.fcltList = [];
      await this.requestGetFclt();
      await this.showChart();
    },
    isParkClick(item) {
      return this.parkSelect === item.name ? 'selected' : '';
    },

    drawChart() {},

    //탭 전환 시
    async handleClick() {
      this.parkSelect = this.parkList[0].name; //공원 초기화임
      this.fcltList = [];
      await this.requestGetFclt();
      await this.showChart();
    },

    //기간 선택
    selectWeek() {
      this.searchEndDate = moment().toDate();
      this.searchStartDate = moment().subtract(6, 'days').toDate();
    },
    selectMonth() {
      this.searchEndDate = moment().toDate();
      this.searchStartDate = moment().subtract(1, 'months').toDate();
    },
    selectAll() {
      this.searchStartDate = new Date(new Date().getFullYear(), 0, 1);
      this.searchEndDate = new Date();
    },

    // 기간 변경 시
    changeDate(dateType) {
      if (dateType == 'end' && this.searchEndDate != '') {
        this.searchEndDate = moment(this.searchEndDate).set('hour', 23).set('minute', 59).set('second', 59).toDate();
      }
      if (this.searchEndDate < this.searchStartDate) {
        this.dateInit();
        this.$message({
          type: 'info',
          message: `날짜를 다시 입력해주세요`,
        });
      }
      if (dateType == 'end' && new Date() < this.searchEndDate) {
        this.dateInit();
        this.$message({
          type: 'info',
          message: `오늘 이상의 날짜는 선택하실 수 없습니다.`,
        });
      }
    },

    //데이터 표현 방식 선택
    dateClick(btn) {
      return this.currentBtn === btn ? 'selected' : '';
    },
    day() {
      this.currentBtn = 'btn0';
      this.isBtn = true;
      this.expression = '$dayOfYear';
      this.isDataPeriod = '일간';
      this.chartExpression = 'day';
      this.showChart();
    },
    week() {
      this.currentBtn = 'btn1';
      this.isBtn = true;
      this.expression = '$week';
      this.chartExpression = 'week';
      this.showChart();
    },
    month() {
      this.currentBtn = 'btn2';
      this.isBtn = true;
      this.expression = '$month';
      this.isDataPeriod = '월간';
      this.chartExpression = 'month';
      this.showChart();
    },
    year() {
      this.currentBtn = 'btn3';
      this.isBtn = true;
      this.expression = '$year';
      this.isDataPeriod = '연간';
      this.chartExpression = 'year';
      this.showChart();
    },

    // 검색창
    search() {
      // this.showChart();
      this.showChart();
    },

    //pdf 클릭
    openPdf() {
      const me = this;
      const pInfo = this.pdfInfo.find((elm) => {
        return elm.tab === me.tab;
      });

      if (!pInfo) return;
      this.makePdf(pInfo.id, pInfo.fileName, pInfo.rotate);
    },

    async makePdf(id, fileName, radian) {
      let loading = undefined;
      try {
        loading = this.$loading({
          lock: true,
          text: 'PDF문서를 렌더링 중입니다.',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });

        let doc = new jsPDF('p', 'mm');
        let el = document.querySelector(`#${id}`);

        let canvas = await html2canvas(el, {
          logging: true,
          allowTaint: false,
        });

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        let imgWidth = 0;
        let imgHeight = 0;

        let posX = 0;
        let posY = 5;

        let imgData = canvas.toDataURL('image/png');

        if (typeof radian === 'undefined') {
          const imgProps = doc.getImageProperties(imgData);
          imgWidth = pageWidth;
          imgHeight = (imgProps.height * pageWidth) / imgProps.width;
        } else {
          // create intermediate canvas
          const rotCanvas = document.createElement('canvas');

          // swap width and height
          rotCanvas.width = canvas.height;
          rotCanvas.height = canvas.width;

          // get context
          const rctx = rotCanvas.getContext('2d');

          // translate to center (rotation pivot)
          rctx.translate(rotCanvas.width * 0.5, rotCanvas.height * 0.5);

          // rotate -90° (CCW)
          rctx.rotate((radian * Math.PI) / 180);

          // draw image offset so center of image is on top of pivot
          rctx.drawImage(canvas, -canvas.width * 0.5, -canvas.height * 0.5);

          imgData = rotCanvas.toDataURL('image/png');
          // const imgProps = doc.getImageProperties(imgData);
          // imgWidth = pageWidth - 5;
          // imgHeight = imgProps.height * (imgWidth / imgProps.width);
          imgHeight = pageHeight - 5;
          imgWidth = rotCanvas.width * (pageHeight / rotCanvas.height);

          posY = 0;
          posX = (pageWidth - imgWidth) / 2;
        }

        doc.addImage(imgData, posX, posY, imgWidth, imgHeight);
        loading.close();
        doc.save(fileName);
      } catch (err) {
        console.error('makePdf error');
        if (loading) {
          loading.close();
        }
      }
    },

    onClickExcelDown() {
      let excelData;
      switch (this.tab) {
        case 'parkCctvChart': {
          excelData = this.getParkCctvChartExcelData();
          break;
        }
        case 'parkInoutChart': {
          excelData = this.getParkInoutChartExcelData();
          break;
        }
        case 'vehicleChart': {
          excelData = this.getvehicleChartExcelData();
          break;
        }
      }
      this.makeExcel(excelData);
    },

    getParkCctvChartExcelData() {
      return {
        fileName: '공원별 CCTV 통계',
        dataList: [
          {
            name: '사람 통행량',
            type: 'bar',
            cols: this.chartOption.peoplePass.cols,
            data: this.chartDate.peoplePass,
          },
          {
            name: '자전거 통행량',
            type: 'bar',
            cols: this.chartOption.bicycleObjectPass.cols,
            data: this.chartDate.bicycleObjectPass,
          },
          {
            name: '탈 것별 통행량',
            type: 'bar',
            cols: this.chartOption.vehiclePass.cols,
            data: this.chartDate.vehiclePass,
          },
          // { 삭제
          //   name: '방향별 통행량',
          //   type: 'bar',
          //   cols: this.chartOption.directionPass.cols,
          //   data: this.chartDate.directionPass,
          // },
          // {
          //   name: '사람-탈 것 비율',
          //   type: 'pie',
          //   cols: this.chartOption.objectRate.cols,
          //   data: this.chartDate.objectRate,
          // },{
          //   name: '마스크 유무',
          //   type: 'pie',
          //   cols: this.chartOption.mask.cols,
          //   data: this.chartDate.mask,
          // },
        ],
      };
    },

    getParkInoutChartExcelData() {
      const me = this;
      const dataList = this.chartDataInoutFclt.map((elm) => {
        const { fcltName: name, data } = elm;
        return {
          name,
          type: 'bar',
          cols: me.chartOption.inoutFclt.cols,
          data,
        };
      });
      return {
        fileName: '공원 입출 통계',
        dataList: [
          {
            name: '공원 전체 진입-진출 통행량',
            type: 'bar',
            cols: this.chartOption.inoutTotal.cols,
            data: this.chartDate.inoutTotal,
          },
          ...dataList,
        ],
      };
    },

    getvehicleChartExcelData() {
      return {
        fileName: '자전거 도로 통계',
        dataList: [
          {
            name: '자전거 통행량',
            type: 'bar',
            cols: this.chartOption.bicycleObjectPass.cols,
            data: this.chartDate.bicycleObjectPass,
          },
          {
            name: '속도',
            type: 'line',
            cols: this.chartOption.speed.cols,
            data: this.chartDate.speed,
          },
          // {
          //   name: '사람-탈 것 통행량',
          //   type: 'bar',
          //   cols: this.chartOption.objectPass.cols,
          //   data: this.chartDate.objectPass,
          // },
          // {
          //   name: '방향별 자전거 통행량',
          //   type: 'bar',
          //   cols: this.chartOption.directionPass.cols,
          //   data: this.chartDate.directionPass,
          // },
          // {
          //   name: '최고속도',
          //   type: 'line',
          //   cols: this.chartOption.avgSpeed.cols,
          //   data: this.chartDate.avgSpeed,
          // },
          // {
          //   name: '평균속도',
          //   type: 'line',
          //   cols: this.chartOption.maxSpeed.cols,
          //   data: this.chartDate.maxSpeed,
          // },
        ],
      };
    },

    makeExcel({ fileName, dataList }) {
      let dateFormat = 'YYYY년';
      let fileDateFormat = 'YYYY-MM-DD';

      switch (this.chartExpression) {
        case 'day': {
          dateFormat = 'YYYY-MM-DD';
          fileDateFormat = 'YYYY-MM-DD';
          break;
        }
        case 'week': {
          dateFormat = 'YYYY-MM-DD';
          fileDateFormat = 'YYYY-MM-DD';
          break;
        }
        case 'month': {
          dateFormat = 'YYYY-MM';
          fileDateFormat = 'YYYY-MM';
          break;
        }
        case 'year': {
          dateFormat = 'YYYY년';
          fileDateFormat = 'YYYY';
          break;
        }
      }

      const startDate = moment(this.searchStartDate).format(fileDateFormat);
      const endDate = moment(this.searchEndDate).format(fileDateFormat);

      const wb = XLSX.utils.book_new();

      dataList.forEach((elm) => {
        const { name, type, cols, data } = elm;
        let xData = [];

        switch (type) {
          case 'line':
          case 'bar': {
            xData = this.makeBarExcelData(cols, data, dateFormat);
            break;
          }
          case 'pie': {
            xData = this.makePieExcelData(cols, data);
            break;
          }
        }

        const ws = XLSX.utils.json_to_sheet(xData);

        XLSX.utils.book_append_sheet(wb, ws, name);
      });

      XLSX.writeFile(wb, `${fileName}(${startDate}~${endDate}).xlsx`, {
        bookType: 'xlsx',
        type: 'array',
      });
    },

    makeBarExcelData(cols, data, dateFormat) {
      if (!data || data.length === 0) {
        return cols.map((elm) => {
          return { [elm.label]: '' };
        });
      }

      return data.map((elm) => {
        const { date, value = [] } = elm;
        const dateStr = moment(date).format(dateFormat);
        const result = {
          [cols[0].label]: dateStr,
        };

        for (let i = 1; i < cols.length; i++) {
          result[cols[i].label] = value[i - 1];
        }

        return result;
      });
    },

    makePieExcelData(cols, data) {
      const result = {};

      data.forEach((elm) => {
        const { name, value = [] } = elm;
        result[name] = value;
      });

      return [result];
    },
  },
  watch: {
    //카메라 선택 시 차트 갱신
    fcltSelect(n, o) {
      // this.requestGetFcltDirection();
      this.showChart();
    },
  },
};
</script>

<style scoped></style>
