var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    [
      _c(
        "el-col",
        {
          staticStyle: { padding: "18px", "box-sizing": "border-box" },
          attrs: { span: 12 }
        },
        [
          _c("doughnut-chart", {
            attrs: {
              "chart-data": _vm.fcltInstPnstData,
              height: 200,
              options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                  display: false,
                  position: "bottom",
                  align: "center",
                  labels: {
                    padding: 10,
                    boxWidth: 15
                  }
                }
              },
              centerText: {
                display: true,
                text: "aaaa"
              }
            }
          })
        ],
        1
      ),
      _c(
        "el-col",
        { staticClass: "label-wrap", attrs: { span: 12 } },
        _vm._l(_vm.fcltInstPnstData.labels, function(item, idx) {
          return _c("div", { key: idx, staticClass: "color-label" }, [
            _c("span", {
              staticClass: "color-marker",
              style: _vm.getColorMarker(idx)
            }),
            _vm._v("\n      " + _vm._s(item) + "\n    ")
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }