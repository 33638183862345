<template>
  <div class="fill-width-height el-container">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <dk-el-table-header :openFormDialog="openFormDialog" :isSearch="false"></dk-el-table-header>
            <el-table
              :data="vmsMessages"
              style="width: 100%; border-top: 1px solid #dedede;"
              empty-text="등록된 VMS 메시지가 없습니다."
              size="mini"
              @row-click="selectRow"
              stripe
            >
              <el-table-column label="썸네일" min-width="150px">
                <template slot-scope="scope">
                  <img :src="scope.row.thumbImageFile" style="height: 64px; width: auto;" />
                </template>
              </el-table-column>
              <el-table-column
                label="VMS 메시지 이름"
                prop="vmsMessageName"
                header-align="center"
                align="center"
              ></el-table-column>
              <el-table-column label="VMS 메시지 유형" header-align="center" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.vmsMessageType == '1'">홍보</span>
                  <span v-else-if="scope.row.vmsMessageType == '2'">교통정보</span>
                  <span v-else-if="scope.row.vmsMessageType == '3'">돌발</span>
                  <span v-else-if="scope.row.vmsMessageType == '4'">공사/행사</span>
                  <span v-else-if="scope.row.vmsMessageType == '5'">우회도로</span>
                </template>
              </el-table-column>
              <el-table-column
                label="VMS 종류"
                prop="vmsMessageSize"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.vmsMessageSize == '2x10'">2단 10열</span>
                  <span v-else-if="scope.row.vmsMessageSize == '2x12'">2단 12열</span>
                  <span v-else>기타</span>
                </template>
              </el-table-column>
              <el-table-column label width="170px">
                <template slot-scope="scope">
                  <el-button @click="openFormDialog(scope.row)" size="small">수정</el-button>
                  <el-button @click="removeVmsMessage(scope.row)" size="small" type="danger">삭제</el-button>
                </template>
              </el-table-column>
            </el-table>
            <dk-el-pagination
              :totalDocs="totalDocs"
              :limit="limit"
              :pagingProc="pagingProc"
              ref="pagination"
            ></dk-el-pagination>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <dk-info-panel ref="infoPanel" width="400px">
      <div class="info-panel-wrap"></div>
    </dk-info-panel>
    <form-vms-message ref="formDialog"></form-vms-message>
  </div>
</template>

<script>
import dkelpagination from '@/components/dkElPagination.vue';
import dkeltableheader from '@/components/dkElTableHeader.vue';
import dkinfopanel from '@/components/dkInfoPanel.vue';
import formVmsMessage from '@/traffic/form/formVmsTextMessage.vue';

export default {
  components: {
    'dk-el-table-header': dkeltableheader,
    'dk-info-panel': dkinfopanel,
    'dk-el-pagination': dkelpagination,
    'form-vms-message': formVmsMessage,
  },
  created() {
    this.$on('procDataComplete', (data) => {
      this.getVmsMessages();
    });
  },
  mounted() {
    this.getVmsMessages(1);
  },
  data() {
    return {
      vmsMessages: [],
      limit: 10,
      totalDocs: 0,
      selectVmsMessage: {},
    };
  },
  methods: {
    selectRow(row, event, column) {
      // if (!this.$refs.infoPanel.isOpened()) {
      //     this.$refs.infoPanel.open();
      // }
      this.selectVmsMessage = row;
    },
    openFormDialog(row) {
      this.$refs.formDialog.showDialog(row);
    },
    removeVmsMessage(args) {
      this.$confirm(`${args.vmsMessageName} VMS 메시지를 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/vmsMessage/delete`, { data: args });
            this.getVmsMessages();
            this.$message({
              type: 'success',
              message: `${args.vmsMessageName} VMS 메시지 삭제 완료`,
            });
          } catch (error) {
            this.$message({
              type: 'warning',
              message: `${args.vmsMessageName} VMS 메시지 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${args.vmsMessageName} VMS 메시지 삭제 취소`,
          });
        });
    },
    async getVmsMessages(pageNum) {
      let me = this;
      let param = {
        page: pageNum || this.$refs.pagination.getPage(),
        limit: this.limit,
      };
      param.vmsType = '1';
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsMessage`, {
        params: param,
      });
      me.vmsMessages = response.data.docs;
      me.totalDocs = response.data.totalDocs;
      // this.$store.dispatch("vmsMessage/getVmsMessage", param)
      // .then(() => {
      //     me.vmsMessages = me.$store.getters['vmsMessage/getVmsMessage']
      //     me.totalDocs = me.$store.getters['vmsMessage/getPagination'].totalDocs
      //     //me.$refs.infoPanel.close()
      // });
    },
    pagingProc(pageNum) {
      this.getVmsMessages();
    },
    testMakeVmsMessage(data) {
      this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsMessage/test`, { params: data }).then((result) => {
        console.log(result);
      });
    },
  },
};
</script>

<style scoped></style>
