<template>
    <div id="cameraControlPanel" class="control-panel" 
    v-on:click="clickControl"
    v-on:mousedown="mousedownControl"
    v-on:mouseup="mouseupControl"
    v-on:mousemove="mousemoveControl"
    v-on:contextmenu.prevent="rightClickControl"
    v-on:mousewheel="mousewheel">
      <!-- <div class="control-panel-spot">
      </div> -->
      <!-- <canvas id="control-panel-spot" ref="control-panel-spot" width="720" height="480"></canvas> -->
      <canvas id="control-panel-spot" ref="control-panel-spot"></canvas>
    </div>
</template>

<script>
export default {
  name: 'camera-control-panel',
  props: {
    /*변수명: {
      type:     변수타입
      default:  
      required: 필수여부
      validator: 함수, 값이 유효한지 판별(리턴 true/false로 판별)
    }*/
    cameraControlIp: {    // 카메라컨트롤 API Ip
      type: String,
      required: true,
    },
    cameraControlPort: {  // 카메라컨트롤 API port
      type: Number,
      required: true,
    },
  },
  data: () => ({
    cameraId: '',               // 제어 할 카메라 ID
    cameraControlFlag: 0,       // 현재 카메라 제어 모드 플래그
    cameraControlFlagList: [    // 카메라 제어 모드 리스트
      'moveContinuous',
      'movePoint',
      'moveOneClickZoom',
      // 'moveAreaZoom',
    ],
    mouseFlag:{                 // 마우스 동작 구분 플래그
      click: 0,
      down: 1,
      up: 2,
      move: 3,
      wheel: 4,
    },
    cameraControlFuncInfo: {    // 카메라 동작제어함수 정보
      moveContinuous: {
        flag:[false, true, true, true, false],
        path: '/video/camera/control/moveContinuous',
        explanation: '카메라 지속제어',
      },
      movePoint: {
        flag:[true, false, false, false, false],
        path: '/video/camera/control/movePoint',
        explanation: '카메라 포인트줌',
      },
      moveOneClickZoom: {
        flag:[true, false, false, false, false],
        path: '/video/camera/control/moveOneClickZoom',
        explanation: '카메라 원클릭줌',
      },
      moveAreaZoom: {
        flag:[false, true, true, false, false],
        path: '/video/camera/control/moveAreaZoom',
        explanation: '카메라 영역줌',
      },
      zoomChangeWheel: {
        flag:[false, false, false, false, true],
        path: '/video/camera/control/zoomChangeWheel',
        explanation: '카메라 영역줌',
      },
    },
    mouseStatInfo: {
      up: true,
      moveInfo:{
        interval: 300,
        on: true,
      },
      wheelInfo: {
        flag: false,
        interval:500,
        zoomValue: 0,
      },
    },

    centerPointInfo: {
      size: 7,
      color: "#C62828"
    },

    controlAbleFlag: false,

    semiAutoConfig : {
      isSemiAutoPreset: false,
      isDrawRect: false,
      isDrawLine: false,
      period: 0,
      semiAutoStartRect : {
        x:0,
        y:0,
        width:0,
        height:0
      },
      startPos: {
        x:0,
        y:0
      },
      endPos:{
        x:0,
        y:0
      },     
      semiAutoPresetRects :[]
    }
  }),
  created () {
    
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.resizeCameraControl)
      this.resizeCameraControl()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeCameraControl)
  },
  computed: {

  },
  methods: {
    /**
     * 카메라 접속정보 설정
     * @param {*} args = {ip, port}
     */
    setCamera(cameraId) {
      let _this = this;
      // let {cameraId} = args;
      _this.cameraId = cameraId;
      _this.controlAbleFlag = true;
    },

    cancelCamera() {
      _this.cameraId = '';
      _this.controlAbleFlag = true;
    },

    /**
    * 마우스 우클릭 이벤트
    */
    rightClickControl(e) {
      let _this = this;
      _this.cameraControlFlag++;
      if(_this.cameraControlFlag >= _this.cameraControlFlagList.length) {
        _this.cameraControlFlag = 0;
      }
    },

    /**
     * 마우스 좌클릭 이벤트
     */
    clickControl(e) {
      let _this = this;
      if (_this.semiAutoConfig.isSemiAutoPreset) {
        return
      }
      const flag = _this.mouseFlag.click;
      let funcName = _this.cameraControlFlagList[_this.cameraControlFlag];
      _this.mouseControl(funcName, flag, e);
    },

    /**
     * 마우스 다운 이벤트
     */
    mousedownControl(e) {
      let _this = this;
      // console.log(e);
      // console.log(e.button);
      // console.log(e.buttons);
      if (_this.semiAutoConfig.isSemiAutoPreset) {
        _this.semiAutoPresetEvent(e, "mousedown")
        return;
      }

      if (e.buttons !== 1) {
        return;
      }
      const flag = _this.mouseFlag.down;
      _this.mouseStatInfo.up = false;
      let funcName = _this.cameraControlFlagList[_this.cameraControlFlag];
      _this.mouseControl(funcName, flag, e);
    },

    /**
     * 마우스 업 이벤트
     */
    mouseupControl(e) {
      let _this = this;
      if (_this.semiAutoConfig.isSemiAutoPreset) {
        _this.semiAutoPresetEvent(e, "mouseup")
        return;
      }

      const flag = _this.mouseFlag.up;
      _this.mouseStatInfo.up = true;
      let funcName = _this.cameraControlFlagList[_this.cameraControlFlag];
      _this.mouseControl(funcName, flag, e);
    },

    /**
     * 마우스 무브 이벤트
     */
    mousemoveControl(e) {
      let _this = this;
      if (_this.semiAutoConfig.isSemiAutoPreset) {
        _this.semiAutoPresetEvent(e, "mousemove")
        return;
      }

      let moveInfo = _this.mouseStatInfo.moveInfo;
      if (_this.mouseStatInfo.up) {
        return;
      } else if(moveInfo.on !== true) {
        return;
      } else {
        moveInfo.on = false;
      
        setTimeout(()=>{
          moveInfo.on = true;
        }, moveInfo.interval);

        const flag = _this.mouseFlag.move;
        let funcName = _this.cameraControlFlagList[_this.cameraControlFlag];
        _this.mouseControl(funcName, flag, e);
      }
    },

    /**
     * 마우스 휠 이벤트
     */
    mousewheel(e) {
      let _this = this;
      if (_this.controlAbleFlag !== true)  {return}
      
      if(_this.cameraId == '') {
        return;
      }
      
      if (_this.mouseStatInfo.wheelInfo.flag === false) {
        _this.mouseStatInfo.wheelInfo.flag = true;
        setTimeout(()=>{
          const flag = _this.mouseFlag.wheel;
          let funcName = 'zoomChangeWheel';
          let params = {
            zoomChangeValue: _this.mouseStatInfo.wheelInfo.zoomValue,
          };
          let path = _this.cameraControlFuncInfo[funcName].path;
          _this[funcName](params, path, flag);

          _this.mouseStatInfo.wheelInfo.zoomValue = 0;
          _this.mouseStatInfo.wheelInfo.flag = false;

          return;
        }, _this.mouseStatInfo.wheelInfo.interval);
      }

      let zoomChangeValue = (e.wheelDelta > 0)? 1 : -1;
      _this.mouseStatInfo.wheelInfo.zoomValue += zoomChangeValue;

      return;

      // let params = {
      //   zoomChangeValue: (e.wheelDelta > 0)? 1 : -1,
      // };

      // let path = _this.cameraControlFuncInfo[funcName].path;

      // return _this[funcName](params, path, flag);
    },

    /**
     * 마우스 이벤트 연계
     */
    mouseControl(funcName, flag, e) {
      let _this = this;
      if (_this.controlAbleFlag !== true)  {return}
      if(_this.cameraId == '') {
        return;
      } else if(!_this.cameraControlFuncInfo[funcName]['flag'][flag]) {
        return;
      }

      let params = {
        screen: {
          width: e.currentTarget.clientWidth,
          height: e.currentTarget.clientHeight,
        },
        mouse: {
          x: e.offsetX,
          y: e.offsetY,
        },
      };

      let path = _this.cameraControlFuncInfo[funcName].path;

      return _this[funcName](params, path, flag);
    },

    /**
     * 카메라 지속제어 요청
     * @param {*} args = {mouse, screen}
     * @param {string} path
     * @param {number} flag
     */
    moveContinuous(args, path, flag) {
      let _this = this;
      let {cameraId} = _this;
      let screenWidthHalf = args.screen.width/2;
      let screenHeightHalf = args.screen.height/2;
      let pan = 0;
      let tilt = 0;
      let zoom = 0;
      
      switch(flag) {
        case _this.mouseFlag.down:
        case _this.mouseFlag.move:
          pan = Math.round(((args.mouse.x - screenWidthHalf)/(screenWidthHalf))*100);
          tilt = Math.round(((args.mouse.y - screenHeightHalf)/(screenHeightHalf))*(-100));
          zoom = Math.round(0);
        break;
        case _this.mouseFlag.up:
          pan = 0;
          tilt = 0;
          zoom = 0;
        break;
        break;
        default:
        break;
      }
      
      let url = `http://${_this.cameraControlIp}:${_this.cameraControlPort}${path}`
      let params = {
        cameraId: cameraId,
        pan: pan,
        tilt: tilt,
        zoom: zoom,
      };

      return Promise.resolve()
      .then(()=>{
        return _this.reqCameraControl({url, params});
      })
      .then((pData)=>{

      })
      .catch((err)=>{
        return console.error(err);
      })
    },

    /**
     * 카메라 포인트줌 요청
     * @param {*} args = {mouse, screen}
     * @param {string} path
     * @param {number} flag
     */
    movePoint(args, path, flag) {
      let _this = this;
      console.log('포인트줌')
      let {cameraId} = _this;
      let {screen, mouse} = args;

      let url = `http://${_this.cameraControlIp}:${_this.cameraControlPort}${path}`
      let params = {
        cameraId: cameraId,
        point: mouse,
        screen: screen,
      };

      return Promise.resolve()
      .then(()=>{
        return _this.reqCameraControl({url, params});
      })
      .then((pData)=>{

      })
      .catch((err)=>{
        return console.error(err);
      })
    },

    /**
     * 카메라 원클릭줌 요청
     * @param {*} args = {mouse, screen}
     * @param {string} path
     * @param {number} flag
     */
    moveOneClickZoom(args, path, flag) {
      let _this = this;
      console.log('원클릭줌')
      let {cameraId} = _this;
      let {screen, mouse} = args;

      let url = `http://${_this.cameraControlIp}:${_this.cameraControlPort}${path}`
      let params = {
        cameraId: cameraId,
        point: mouse,
        screen: screen,
      };

      return Promise.resolve()
      .then(()=>{
        return _this.reqCameraControl({url, params});
      })
      .then((pData)=>{

      })
      .catch((err)=>{
        return console.error(err);
      })
    },

    /**
     * 카메라 휠줌 요청
     * @param {*} args = {mouse, screen}
     * @param {string} path
     * @param {number} flag
     */
    zoomChangeWheel(args, path, flag) {
      let _this = this;
      console.log('원클릭줌')
      let {cameraId} = _this;
      let {zoomChangeValue} = args;

      let url = `http://${_this.cameraControlIp}:${_this.cameraControlPort}${path}`
      let params = {
        cameraId: cameraId,
        zoomChangeValue: zoomChangeValue,
      };

      return Promise.resolve()
      .then(()=>{
        return _this.reqCameraControl({url, params});
      })
      .then((pData)=>{

      })
      .catch((err)=>{
        return console.error(err);
      })
    },

    // /**
    //  * 
    //  * @param {*} args = {mouse, screen}
    //  */
    // moveAreaZoom(args, path, flag) {
    //   let _this = this;
    //   let {cameraId, box, screen} = args;

    //   let url = `http://${_this.cameraControlIp}:${_this.cameraControlPort}${path}`
    //   let params = {
    //     cameraId,
    //     box,
    //     screen,
    //   };

    //   return Promise.resolve()
    //   .then(()=>{
    //     return _this.reqCameraControl({url, params});
    //   })
    //   .then((pData)=>{

    //   })
    //   .catch((err)=>{
    //     return console.error(err);
    //   })
    // },

    /**
     * 카메라 제어요청
     * @param {*} args = {url, params}
     * @param {*} result = {success, failure}
     */
    reqCameraControl(args, result) {
      let _this = this;
      let {url, params} = args;
      
      return _this.$http.post(url, params)
      .then((res) => {
        return (result && result.success) ? result.success(res.data) : res.data;
      })
      .catch((err) => {
        return (result && result.failure) ? result.failure(err) : Promise.reject(err);
      })
    },

    getCameraControlFlagInfo() {
      let _this = this;
      return _this.cameraControlFuncInfo[_this.cameraControlFlagList[_this.cameraControlFlag]];
    },

    /**
     *  컨트롤 패널 리사이즈
     *  windows리사이즈 이벤트에 canvas크기를 재정렬한다
     */
    resizeCameraControl() {
      let _this = this;
      let ctx =_this.$refs["control-panel-spot"].getContext('2d');
      ctx.canvas.width = _this.$el.clientWidth;
      ctx.canvas.height = _this.$el.clientHeight;       

      if (_this.semiAutoConfig.isSemiAutoPreset) {
        return
      }
      ctx.fillStyle = _this.centerPointInfo.color;
      ctx.fillRect(
        (ctx.canvas.width - _this.centerPointInfo.size)/2, 
        (ctx.canvas.height - _this.centerPointInfo.size)/2,
        _this.centerPointInfo.size, 
        _this.centerPointInfo.size
      );
    },
    /**
     *  반자동 단속영역 설정
     *  기존 camera control 이벤트를 intercept해서 발생시킨다
     */
    setSemiAutoPreset(flag, period) {
      let _this = this
      _this.semiAutoConfig.isSemiAutoPreset = flag      
      _this.semiAutoConfig.isDrawRect = false
      _this.semiAutoConfig.isDrawLine = false
      _this.semiAutoConfig.period = period
      
      let canvas = _this.$refs["control-panel-spot"]
      let ctx = canvas.getContext('2d')

      // 화면에 점을 없앤다
      if (flag) {
        ctx.clearRect(0, 0, canvas.width, canvas.height)
      }
      // 화면 중앙에 점을 다시 찍는다
      else {        
        ctx.clearRect(0, 0, canvas.width, canvas.height)
        ctx.fillStyle = _this.centerPointInfo.color;
        ctx.fillRect(
          (ctx.canvas.width - _this.centerPointInfo.size)/2, 
          (ctx.canvas.height - _this.centerPointInfo.size)/2,
          _this.centerPointInfo.size, 
          _this.centerPointInfo.size
        )
      }
    },
    semiAutoPresetEvent(e, eType) {      
      let _this = this
      let canvas = _this.$refs["control-panel-spot"]
      let ctx = canvas.getContext('2d')          
      switch(eType) {
        case "mousedown" :
          if (!_this.semiAutoConfig.isDrawRect 
                && !_this.semiAutoConfig.isDrawLine)
          {
            _this.semiAutoConfig.isDrawRect = true
            _this.semiAutoConfig.startPos.x = e.layerX
            _this.semiAutoConfig.startPos.y = e.layerY

            ctx.strokeStyle = _this.centerPointInfo.color
            ctx.lineWidth = 3
            ctx.fillStyle = _this.centerPointInfo.color            
          } 
          else if (_this.semiAutoConfig.isDrawLine) {
            _this.semiAutoConfig.endPos.x = e.layerX
            _this.semiAutoConfig.endPos.y = e.layerY

            ctx.beginPath();
		        ctx.moveTo(_this.semiAutoConfig.startPos.x, _this.semiAutoConfig.startPos.y);
		        ctx.lineTo(_this.semiAutoConfig.endPos.x, _this.semiAutoConfig.endPos.y, 6);
		        ctx.strokeStyle = _this.centerPointInfo.color
            ctx.stroke();
                         
            ctx.fillRect(
              _this.semiAutoConfig.endPos.x - (_this.centerPointInfo.size /2),
              _this.semiAutoConfig.endPos.y - (_this.centerPointInfo.size /2),
              _this.centerPointInfo.size, 
              _this.centerPointInfo.size
            )
          }         
        break
        case "mousemove" :                  
          if (_this.semiAutoConfig.isDrawRect) {
            let x = _this.semiAutoConfig.semiAutoStartRect.x
            let y = _this.semiAutoConfig.semiAutoStartRect.y
            let width = _this.semiAutoConfig.semiAutoStartRect.width
            let height = _this.semiAutoConfig.semiAutoStartRect.height          
            ctx.clearRect(0, 0, canvas.width, canvas.height)
            ctx.fillRect(
              _this.semiAutoConfig.startPos.x - (_this.centerPointInfo.size /2),
              _this.semiAutoConfig.startPos.y - (_this.centerPointInfo.size /2),
              _this.centerPointInfo.size, 
              _this.centerPointInfo.size
            )

            width = Math.abs(e.layerX - _this.semiAutoConfig.startPos.x) * 2
            let plusMinus = 0
            if (e.layerY < _this.semiAutoConfig.startPos.y) {
                plusMinus = -1
            }
            else if (e.layerY > _this.semiAutoConfig.startPos.y) {
                plusMinus = 1
            }
            height = Math.abs(Math.round(width / 16 * 9)) * plusMinus
            x = _this.semiAutoConfig.startPos.x - Math.abs(width / 2)
            y = _this.semiAutoConfig.startPos.y - (height / 2)            
            ctx.strokeRect(x, y, width, height)

            _this.semiAutoConfig.semiAutoStartRect.x = x
            _this.semiAutoConfig.semiAutoStartRect.y = ((height < 0)? y + height : y)
            _this.semiAutoConfig.semiAutoStartRect.width = Math.abs(width)
            _this.semiAutoConfig.semiAutoStartRect.height = Math.abs(height)
          }          
        break
        case "mouseup" :
          if (_this.semiAutoConfig.isDrawRect 
                && !_this.semiAutoConfig.isDrawLine) {
            _this.semiAutoConfig.isDrawRect = false
            _this.semiAutoConfig.isDrawLine = true
          }
          else if (_this.semiAutoConfig.isDrawLine) {
            _this.semiAutoConfig.isDrawLine = false
            // 파라메터 만들어서 위로 보냄
            if (confirm("이대로 자동 단속영역 설정을 진행하겠습니까?")) {
              let {cameraId} = _this              
              let url = `http://${_this.cameraControlIp}:${_this.cameraControlPort}/video/camera/calculation/setAutoPreset`
              let params = {
                cameraId: cameraId,
                period: _this.semiAutoConfig.period,
                screen: {
                  width: _this.$el.clientWidth,
                  height: _this.$el.clientHeight
                },
                rect: {  
                  x: _this.semiAutoConfig.semiAutoStartRect.x,
                  y: _this.semiAutoConfig.semiAutoStartRect.y,
                  width: _this.semiAutoConfig.semiAutoStartRect.width,
                  height: _this.semiAutoConfig.semiAutoStartRect.height
                },
                point: []
              }
              params.point.push(_this.semiAutoConfig.startPos)
              params.point.push(_this.semiAutoConfig.endPos)              
                                         
              return Promise.resolve({
                cameraId : cameraId,
                url : url,
                params: params
              })
              .then((pData)=>{
                let {url, params} = pData;
                return _this.reqCameraControl({url, params});
              })
              .then((pData)=>{                                
                new Promise((resolve2, reject2) => {
                  let idx = 0
                  for (var i=0; i<pData.length; i++) {
                    let presetData = pData[i]
                    ctx.strokeRect(presetData.x, presetData.y, presetData.width, presetData.height)                    
                  }                                                                            
                  return resolve2()
                })
                .then(()=>{                    
                    setTimeout(function() {
                      _this.$parent.$emit('semiAutoPresetComplete', pData)                
                    }, 500)
                    
                })                
              })
              .catch((err)=>{
                return console.error(err);
              })
            }
            else {
              let canvas = _this.$refs["control-panel-spot"]
              let ctx = canvas.getContext('2d')
              ctx.clearRect(0, 0, canvas.width, canvas.height)
            }
          }
        break
      }
      return
    },
    resetSemiAutoPreset() {
      let _this = this
      let canvas = _this.$refs["control-panel-spot"]
      let ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, canvas.width, canvas.height)
    }
  },
  watch: {
    cameraControlFlag: function (newVal, oldVal) {
      let _this = this;
      let cameraControlInfo = _this.getCameraControlFlagInfo();
      _this.$parent.$emit('changeControl', cameraControlInfo);
    }
  },
  destroyed: () => {

  }
}


</script>

<style scoped>
  .control-panel {
    position:absolute;
    width:100%;
    height:100%;    
    /*top: 0;
    left: 0;
    bottom:0;
    right:0;*/
    border: 1px solid black;
    margin: 0;
    padding: 0;
  }
</style>