<template>
  <div id="formControllLog">
    <el-dialog
      :visible.sync="isFormDialog"
      :close-on-click-modal="false"
      top="6vh"
      :close-on-press-escape="false"
      class="el-dialog-aside"
      :title="formTitle"
      @close="hideDialog"
    >
      <el-container class="dialog-container">
        <el-row style="padding: 30px 10px; width: 100%;">
          <el-col :span="24">
            <el-form :model="formData" ref="form0" label-width="160px" size="medium" :rules="rules">
              <el-row>
                <el-col :span="20">
                  <el-form-item label="발생일자" prop="occurDate" required>
                    <el-date-picker
                      :default-value="new Date()"
                      v-model="formData.occurDate"
                      type="datetime"
                      placeholder="발생일자를 선택해주세요"
                      format="yyyy-MM-dd HH:mm"
                      style="width: 100%;"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="20">
                  <el-form-item label="CCTV명" prop="fcltId">
                    <el-autocomplete
                      popper-class="my-autocomplete"
                      v-model="formData.fcltId"
                      :fetch-suggestions="querySearch"
                      placeholder="CCTV명을 입력해주세요"
                      @select="handleSelect"
                      style="width: 400px;"
                    >
                      <template slot-scope="{ item }">
                        <div class="value">{{ item.fcltName }}</div>
                      </template>
                    </el-autocomplete>
                  </el-form-item>
                </el-col>
                <el-col :span="20">
                  <el-form-item label="관제유형" required>
                    <!--에러-->
                    <el-col :span="10" style="padding-right: 10px;">
                      <el-form-item prop="controlType">
                        <el-select v-model="formData.controlType" placeholder="관제 유형" @change="changeControlType" style="width: 100%;">
                          <el-option v-for="(item, key) in controlType" :key="key" :label="item" :value="item"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="14">
                      <el-form-item prop="controlTypeSubClass" ref="controlTypeSubClass">
                        <el-select v-model="formData.controlTypeSubClass" placeholder="소분류" style="width: 100%;">
                          <el-option v-for="(item, key) in controlTypeSubClass" :key="key" :label="item.sub" :value="item.sub" style="height: 100%;">
                            <p class="search-title">
                              <span>{{ item.sub }}</span>
                            </p>
                            <p class="search-content">
                              {{ item.desc }}
                            </p>
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                </el-col>
                <el-col :span="20">
                  <el-form-item label="신고처">
                    <el-col :span="10" style="padding-right: 10px;">
                      <el-form-item prop="reportingFrom" ref="reportingFrom">
                        <el-select v-model="formData.reportingFrom" placeholder="신고처" @change="changeReportingFrom" clearable style="width: 100%;">
                          <el-option v-for="(item, key) in reportingFrom" :key="key" :label="item" :value="item"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="14">
                      <el-form-item prop="etcReportingAgency" ref="etcReportingAgency">
                        <el-input v-model="formData.etcReportingAgency" style="width: 100%;" :disabled="isEtcReportingAgency"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                </el-col>
                <el-col :span="20" v-if="isOrder">
                  <el-form-item label="지령여부">
                    <el-col :span="9">
                      <el-form-item prop="orderNum" style="padding-right: 10px;">
                        <el-input v-model="formData.orderNum" placeholder="지령번호" style="width: 100%;" :disabled="isOrderNum"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="1">
                      <el-form-item prop="isOrder">
                        <el-checkbox v-model="formData.isOrder" style="width: 100%;" @change="changeOrder"></el-checkbox>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                </el-col>
                <el-col :span="20">
                  <el-form-item label="관제사항" prop="content">
                    <el-input type="textarea" v-model="formData.content" rows="5" placeholder="관제사항 내용을 서술해주세요"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="20">
                  <el-form-item label="첨부파일">
                    <dk-el-file-upload ref="uploadFile" path="controlLog" :fileList="formData.file">
                      <div align="left" style="padding-left: 2px;">
                        <el-button size="small" type="primary">첨부파일 업로드</el-button>
                        <div slot="tip" class="el-upload__tip">관련된 파일을 업로드 할 수 있습니다.</div>
                      </div>
                    </dk-el-file-upload>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div class="form-bottom">
              <el-button @click="hideDialog" style="float: right;">취소</el-button>
              <el-button type="primary" @click="save" style="float: right; margin-right: 5px;">저장</el-button>
            </div>
          </el-col>
        </el-row>
      </el-container>
    </el-dialog>
  </div>
</template>
<script>
import dkElFileUpload from '@/components/dkElFileUpload.vue';

export default {
  data() {
    return {
      isFormDialog: false,
      updateFlag: false,
      formTitle: '',
      formData: {
        writerId: '',
        occurDate: '',
        fcltId: '',
        controlType: '',
        controlTypeSubClass: '',
        reportingFrom: '',
        etcReportingAgency: '',
        isOrder: false,
        orderNum: '',
        content: '',
        file: [],
      },
      controlType: ['사건사고관제', '일반관제'],
      controlTypeSubClass: [],
      reportingFrom: [],
      fcltId: '',
      rules: {
        occurDate: [{ required: true, message: '발생일자는 필수기재입니다.' }],
        fcltId: [{ required: true, message: 'CCTV명은 필수기재입니다.', trigger: 'blur' }],
        controlType: [{ required: true, message: '관제유형은 필수기재입니다.', trigger: 'change' }],
        controlTypeSubClass: [{ required: true, message: '관제유형 소분류는 필수기재입니다.', trigger: 'change' }],
        reportingFrom: [],
      },
      isOrder: false,
      isOrderNum: true,
      isEtcReportingAgency: true,
    };
  },
  watch: {},
  components: {
    'dk-el-file-upload': dkElFileUpload,
  },
  props: {
    controlLog: Object,
  },
  methods: {
    //save() 관제 일지 수정 및 추가 실행 함수
    save() {
      //지령여부 false면 지령번호 지우기
      if (typeof this.formData.isOrder === 'undefined' || !this.formData.isOrder) {
        this.formData.orderNum = '';
      }
      //신고처 '기타' 아니면 기타 사항 지우기
      if (!this.formData.reportingFrom == '기타') {
        this.formData.etcReportingAgency == '';
      }
      let url = `${this.$config.getServerConfig().core.api}/core/api/controlLogs/${this.updateFlag ? 'update' : 'add'}`;
      let me = this;
      this.$refs.form0.validate((valid) => {
        try {
          if (valid) {
            this.formData.file = this.$refs.uploadFile.getFileData();
            this.$http
              .post(url, { data: { ...this.formData } })
              .then(function (response) {
                me.$message({
                  type: 'success',
                  message: `관제일지 ${me.updateFlag ? '수정' : '추가'} 완료`,
                });
                me.$emit('successInsertControlLog');
                //me.$refs.uploadFile.setFileData([]);
                me.hideDialog();
              })
              .catch(function (error) {
                me.hideDialog();
                me.$message.error(`관제일지 ${me.updateFlag ? '수정' : '추가'}중 에러발생`);
              });
          } else {
            return false;
          }
        } catch (error) {
          this.hideDialog();
          this.$message.error(`관제일지 등록 중 에러 발생`);
        }
      });
    },
    //showDialog(row) row: 존재 유무에 따라 수정인지 추가인지 판단.
    showDialog(row) {
      // dialog창 수정창인지 추가창인지 확인
      if (row) {
        this.updateFlag = true;
        this.formTitle = '관제 일지 수정';
        this.formData = this.$lodash.cloneDeep(row);
        //지령여부 판단.
        if (this.formData.isOrder) {
          this.isOrder = true;
          this.isOrderNum = false;
        } else {
          this.isOrder = false;
          this.isOrderNum = true;
        }
        //신고처 '기타' 여부 판단
        if (this.formData.reportingFrom == '기타') {
          this.isEtcReportingAgency = false;
        } else {
          this.isEtcReportingAgency = true;
        }
        this.settingControlType(row.controlType);
      } else {
        this.updateFlag = false;
        this.formTitle = '관제 일지 추가';
        this.formData.writerId = this.$store.getters['auth/getActiveUser'].userid;
      }
      this.isFormDialog = true;
      this.$nextTick(() => {
        this.$refs.uploadFile.setFileData(row ? this.formData.file : []);
      });
    },
    hideDialog() {
      this.isFormDialog = false;
      this.resetForm();
    },
    //querySearch(queryString, cb) queryString: 검색단어, cb([]): select박스에 표출 될 값이 담긴 배열,  cctv명 검색 함수 2글자 이상부터
    querySearch(queryString, cb) {
      if (queryString.length < 1) {
        cb([]);
        return;
      }
      this.$http
        .post(`${this.$config.getServerConfig().core.api}/core/api/fclt`, { search: { fcltName: { $regex: queryString, $options: 'i' } } })
        .then(function (response) {
          cb(response.data);
        })
        .catch(function () {
          cb([]);
        });
    },
    //handleSelect(item) cctv명 검색 후 select박스 클릭시 formData.fcltId 업데이트
    handleSelect(item) {
      this.formData.fcltId = item.fcltName;
    },
    //changeControlType(item)사건관제가 바뀌었을 시 영향 받는 값들 초기화
    changeControlType(item) {
      if (this.formData.controlTypeSubClass != '') {
        this.formData.controlTypeSubClass = '';
      }
      this.formData.etcReportingAgency = '';
      this.formData.isOrder = false;
      this.formData.orderNum = '';
      this.formData.reportingFrom = '';
      this.settingControlType(item);
    },
    //settingControlType(item) 사건관제 변화에 따른 값들 세팅
    settingControlType(item) {
      if (item === '사건사고관제') {
        this.isOrder = true;
        this.controlTypeSubClass = [
          { sub: '강력범죄', desc: '살인, 강도, 강간, 폭력, 절도 등' },
          { sub: '경범죄', desc: '빈집침입, 쓰레기투기, 음주소란, 물건던지기 등 위험행위 등(경범죄 처벌법 제3조 참고)' },
          { sub: '청소년비위', desc: '청소년 선도(성폭력, 폭력, 실종, 청소년 비행 등)' },
          { sub: '재난/화재대응', desc: '재난‧재해, 방화, 화재‧폭발 등' },
          { sub: '교통사고 안전대응', desc: '교통사고, 도주차량 등' },
          { sub: '기타범죄', desc: '' },
        ];
        this.reportingFrom = ['경찰', '소방', '시청', '도시가스', '미신고', '기타'];
        if (this.rules.reportingFrom.length == 0)
          this.rules.reportingFrom.push({ required: true, message: '사건사고 관제에서 신고처는 필수기재입니다.', trigger: 'change' });
      } else {
        this.isOrder = false;
        this.controlTypeSubClass = [
          { sub: '아동', desc: '' },
          { sub: '여성', desc: '' },
          { sub: '노인', desc: '' },
          { sub: '기타', desc: '' },
        ];
        this.reportingFrom = ['경찰', '소방', '시청', '도시가스', '기타'];
        this.rules.reportingFrom = [];
        //this.$refs.reportingFrom.resetField();
      }
    },
    //changeReportingFrom(item) 신고처가 바뀌었을 시 '기타' 값일 경우 input 박스 활성화 반대의 경우 초기화
    changeReportingFrom(item) {
      if (item === '기타') {
        this.isEtcReportingAgency = false;
      } else {
        this.isEtcReportingAgency = true;
        this.formData.etcReportingAgency = '';
      }
    },
    //changeOrder(item) 지령여부 체크시에 지령번호 입력칸 활성화
    changeOrder(item) {
      if (item) this.isOrderNum = false;
      else this.isOrderNum = true;
    },
    resetForm() {
      this.rules.reportingFrom = [];
      this.controlTypeSubClass = [];
      this.reportingFrom = [];
      this.formData = {
        writerId: '',
        occurDate: '',
        fcltId: '',
        controlType: '',
        controlTypeSubClass: '',
        reportingFrom: '',
        etcReportingAgency: '',
        isOrder: false,
        orderNum: '',
        content: '',
        file: [],
      };

      this.$refs['form0'].resetFields();
      this.isOrder = false;
      this.isOrderNum = true;
      this.isEtcReportingAgency = true;
    },
  },
};
</script>

<style scoped>
.search-title {
  display: block;
  font-size: 14px;
  line-height: 1;
  margin: 6px 0;
  margin-block-end: 1em;
  margin-block-start: 1em;
  margin-inline-end: 0;
  margin-inline-start: 0;
}
.search-content {
  color: #c0c4cc;
  display: block;
  font-size: 12px;
  line-height: 2.4;
  margin: 6px 0;
  margin-block-end: 1em;
  margin-block-start: 1em;
  margin-inline-end: 0;
  margin-inline-start: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.control-log-totaldocs {
  color: #606266;
  font-size: 12px;
  margin: 5px;
}
.control-log-totaldocs .highlight {
  color: #409eff;
}
.my-autocomplete li {
  line-height: normal;
  padding: 7px;
}
.my-autocomplete .value {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
