var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-width-height" },
    [
      _c(
        "el-row",
        { staticClass: "page-body content-wrap" },
        [
          _c("el-col", { staticClass: "page-subject", attrs: { span: 24 } }, [
            _c("h3", [_vm._v(_vm._s(this.$route.name))])
          ]),
          _c(
            "el-col",
            { staticClass: "grid-header-menu", attrs: { span: 24 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.addFormDialog }
                },
                [
                  _c("i", { staticClass: "fas fa-plus" }),
                  _vm._v(" 새 데이터셋 추가\n      ")
                ]
              )
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "page-content", attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.datasets,
                    "empty-text": "등록된 데이터셋 정보가 없습니다.",
                    size: "mini"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "데이터 명칭", prop: "label", width: "220" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "데이터 KEY", prop: "key", width: "200" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "데이터 유형", prop: "formType" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.formType == "string"
                              ? _c("span", [_vm._v("텍스트")])
                              : scope.row.formType == "number"
                              ? _c("span", [_vm._v("숫자")])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { width: "250" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.updateFormDialog(scope.row)
                                  }
                                }
                              },
                              [_vm._v("수정")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteRow(scope.row)
                                  }
                                }
                              },
                              [_vm._v("삭제")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("form-dataset", {
        ref: "formDialog",
        on: { procDataComplete: _vm.procDataComplete }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }