var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fill-width-height el-container",
      attrs: { id: "pageAssetManage" }
    },
    [
      _c(
        "el-container",
        { staticClass: "page-body" },
        [
          _c(
            "el-main",
            [
              _c(
                "el-row",
                {
                  staticClass: "content-wrap",
                  staticStyle: { height: "100%" }
                },
                [
                  _c("dk-page-header"),
                  _c(
                    "el-col",
                    { staticClass: "page-content", attrs: { span: 24 } },
                    [
                      _c("dk-el-table-header", {
                        attrs: {
                          openFormDialog: _vm.addAsset,
                          isSearch: true,
                          search: _vm.search
                        }
                      }),
                      this.selectedTemplate
                        ? _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.assetList,
                                height: _vm.gridHeight,
                                lazy: ""
                              }
                            },
                            [
                              _vm._l(
                                this.selectedTemplate.templateData,
                                function(header, idx) {
                                  return _c("el-table-column", {
                                    key: idx,
                                    attrs: {
                                      prop: header.dataKey,
                                      label: header.label
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              (scope.row[header.dataKey] &&
                                                scope.row[header.dataKey]
                                                  .type === "string") ||
                                              (scope.row[header.dataKey] &&
                                                scope.row[header.dataKey]
                                                  .type === "number") ||
                                              (scope.row[header.dataKey] &&
                                                scope.row[header.dataKey]
                                                  .type === "select")
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          scope.row[
                                                            header.dataKey
                                                          ].value
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ])
                                                : scope.row[header.dataKey] &&
                                                  scope.row[header.dataKey]
                                                    .type === "date"
                                                ? _c("span", [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.getFormatDate(
                                                            scope.row[
                                                              header.dataKey
                                                            ].value
                                                          )
                                                        ) +
                                                        "\n                "
                                                    )
                                                  ])
                                                : _c("span")
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                }
                              ),
                              _c("el-table-column", {
                                attrs: { width: "160" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { size: "mini" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.updateAsset(
                                                    scope.row
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("수정")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "danger"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteAsset(
                                                    scope.row
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v("삭제")]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2439916757
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      _c("dk-el-pagination", {
                        ref: "pagination",
                        attrs: {
                          totalDocs: 100,
                          limit: 10,
                          pagingProc: _vm.getAssets
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "test dialog", visible: _vm.visible },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c("div", { staticClass: "template-header" }, [
                _c("h4", [_vm._v("템플릿 목록")]),
                _c(
                  "div",
                  { staticClass: "template-button-wrap" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small" },
                        on: { click: _vm.addTemplate }
                      },
                      [_vm._v("템플릿 추가")]
                    )
                  ],
                  1
                )
              ]),
              _vm._l(_vm.templateList, function(item, idx) {
                return _c(
                  "div",
                  { key: idx, staticClass: "template-list-cell" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "template-name",
                        on: {
                          click: function($event) {
                            return _vm.selectTemplate(item)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.templateName) +
                            "\n        "
                        )
                      ]
                    ),
                    _c(
                      "span",
                      { staticClass: "template-button" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini" },
                            on: {
                              click: function($event) {
                                return _vm.updateTemplate(item)
                              }
                            }
                          },
                          [_vm._v("수정")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.deleteTemplate(item)
                              }
                            }
                          },
                          [_vm._v("삭제")]
                        )
                      ],
                      1
                    )
                  ]
                )
              })
            ],
            2
          )
        ]
      ),
      _c("form-asset-template", {
        ref: "formAssetTemplate",
        on: { registerdTemplate: _vm.getTemplates }
      }),
      _c("form-asset-dialog", {
        ref: "formAssetDialog",
        on: { registeredAssetData: _vm.getAssets }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }