import { render, staticRenderFns } from "./dkElMenu origin.vue?vue&type=template&id=63268a39&scoped=true&"
import script from "./dkElMenu origin.vue?vue&type=script&lang=js&"
export * from "./dkElMenu origin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63268a39",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/dk-platform-frontend/dk-platform-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63268a39')) {
      api.createRecord('63268a39', component.options)
    } else {
      api.reload('63268a39', component.options)
    }
    module.hot.accept("./dkElMenu origin.vue?vue&type=template&id=63268a39&scoped=true&", function () {
      api.rerender('63268a39', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dkElMenu origin.vue"
export default component.exports