<template>
  <div class="fill-width-height el-container">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <el-row>
              <el-col :xs="10" :sm="12" :md="12" class="grid-header-menu">
                <el-button type="primary" @click="addUserInfo" size="medium" style="margin-right: 5px;">
                  <i class="fas fa-plus-circle">&nbsp;추가</i>
                </el-button>
              </el-col>
              <el-col :xs="14" :sm="12" :md="12" class="grid-header-menu" style="text-align: right;"> </el-col>
            </el-row>
          </el-col>
          <el-col :span="24">
            <el-table :data="userList" style="width: 100%;" size="small">
              <el-table-column prop="user_id" label="아이디" header-align="center" align="center" sortable></el-table-column>
              <el-table-column prop="name" label="이름" header-align="center" align="center" sortable></el-table-column>
              <el-table-column prop="tel" label="전화번호" header-align="center" align="center" sortable></el-table-column>
              <el-table-column prop="emal" label="이메일" header-align="center" sortable align="center"></el-table-column>
              <el-table-column>
                <template slot-scope="scope">
                  <el-button style="padding: 8px;" @click="updateUserInfo(scope.row)" size="mini">정보수정</el-button>
                  <el-button style="padding: 8px;" @click="updateUserPasswordInfo(scope.row)" size="mini">비밀번호 변경</el-button>

                  <el-button style="padding: 8px;" @click="removeUserInfo(scope.row)" size="mini" type="danger">삭제</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- <dk-el-pagination
                    :totalDocs="totalDocs"
                    :limit="limit"
                    :pagingProc="pagingProc"
                    ref="pagination"
                >
            </dk-el-pagination>-->
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <el-dialog :title="formTitle" :visible.sync="formDialog" @close="resetFormData" :close-on-click-modal="false">
      <div>
        <el-form :model="formData" label-width="160px" size="medium" :rules="rules" ref="form0">
          <el-row>
            <el-col :span="18">
              <el-form-item label="사용자 ID" prop="user_id">
                <el-input v-model="formData.user_id" placeholder="사용자 ID" v-if="!updateFlag"></el-input>
                <span v-else>{{ formData.user_id }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="이름" prop="name">
                <el-input v-model="formData.name" placeholder="이름"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="18" v-if="!updateFlag">
              <el-form-item label="비밀번호" prop="pwd">
                <el-input type="password" v-model="formData.pwd" placeholder="비밀번호" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="18">
              <el-form-item label="전화번호" prop="tel">
                <el-input v-model="formData.tel" placeholder="전화번호"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="이메일" prop="emal">
                <el-input v-model="formData.emal" placeholder="이메일"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="form-bottom">
          <el-button @click="resetFormData" style="float: right;">취소</el-button>
          <el-button type="primary" @click="save" style="float: right; margin-right: 5px;">저장</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="formPasswordDialog" @close="resetFormData" :close-on-click-modal="false">
      <el-form :model="formData" label-width="140px" size="medium" :rules="rules2" ref="form1">
        <el-row>
          <el-col :span="22" style="height: 68px;">
            <el-form-item label="비밀번호" prop="pwd">
              <el-input type="password" v-model="formData.pwd" placeholder="비밀번호" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22" style="height: 63px;">
            <el-form-item label="비밀번호 확인" prop="checkPwd">
              <el-input type="password" v-model="formData.checkPwd" placeholder="비밀번호 확인" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="form-bottom">
        <el-button @click="resetFormData" style="float: right;">취소</el-button>
        <el-button type="primary" @click="savePassword" style="float: right; margin-right: 5px;">저장</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import dkeltableheader from '@/components/dkElTableHeader.vue';

import _ from 'lodash';

export default {
  components: {
    'dk-el-table-header': dkeltableheader,
  },
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('비밀번호를 확인하여 주십시오'));
      } else if (value !== this.formData.pwd) {
        callback(new Error('비밀번호가 일치하지 않습니다'));
      } else {
        callback();
      }
    };
    return {
      userList: [],

      loading: false,
      formData: {},
      updateFlag: false,
      formTitle: false,
      formDialog: false,
      formPasswordDialog: false,
      rules: {
        user_id: [
          // { required: true, message: '사용자 아이디를 입력하여 주십시오', trigger: ['blur', 'change'] }
          {
            required: true,
            message: '아이디를 입력하세요',
            trigger: ['blur', 'change'],
          },
        ],
        name: [
          {
            required: true,
            message: '이름을 입력하여 주십시오',
            trigger: ['blur', 'change'],
          },
        ],
        pwd: [
          {
            required: true,
            message: '비밀번호를 입력하여 주십시오',
            trigger: ['blur', 'change'],
          },
        ],
        emal: [
          {
            type: 'email',
            message: '이메일 주소형태가 유효하지 않습니다.',
            trigger: ['blur', 'change'],
          },
          {
            required: true,
            message: '이메일 주소를 입력하여 주십시오',
            trigger: 'blur',
          },
        ],
      },
      rules2: {
        pwd: [
          {
            required: true,
            message: '비밀번호를 입력하여 주십시오',
            trigger: ['blur', 'change'],
          },
        ],
        checkPwd: [
          {
            required: true,
            validator: validatePass2,
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
  mounted() {},
  created() {
    this.resetFormData();
    this.getUsers(1);
    this.$on('procDataComplete', (centerEquip) => {
      this.getUsers();
      this.selectedGroup = '';
    });
  },
  methods: {
    async getUsers() {
      try {
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/business/admin`, {});
        this.userList = response.data;
        console.log(this.userList);
      } catch (error) {
        this.$message({
          type: 'danger',
          message: `사용자목록 로드중 에러발생`,
        });
      }
    },
    resetFormData() {
      this.formData = {
        user_id: '',
        name: '',
        pwd: '',
        tel: '',
        emal: '',
      };
      this.updateFlag = false;
      this.formTitle = '';
      this.formDialog = false;
      this.formPasswordDialog = false;
    },

    async save() {
      this.$refs.form0.validate(async (valid) => {
        if (!valid) return;

        let url = '';
        if (!this.updateFlag) url = `${this.$config.getServerConfig().core.api}/business/admin/add`;
        else url = `${this.$config.getServerConfig().core.api}/business/admin/update`;

        try {
          await this.$http.post(url, { data: this.formData });
          this.getUsers();
          this.resetFormData();
          this.$message({
            type: 'success',
            message: `${this.formData.name} 사용자 정보 등록 완료`,
          });
        } catch (error) {
          this.$message({
            type: 'danger',
            message: `${this.formData.name} 사용자 정보 등록 중 에러 발생`,
          });
          this.resetFormData();
        }
      });
    },
    addUserInfo() {
      this.formTitle = '김포 사용자 정보 추가';
      this.formDialog = true;
      this.updateFlag = false;
    },
    updateUserInfo(row) {
      this.formData = row;
      this.formTitle = '김포 사용자 정보 수정';
      this.formDialog = true;
      this.updateFlag = true;
    },

    updateUserPasswordInfo(row) {
      this.formData = row;
      this.formPasswordDialog = true;
    },
    savePassword() {
      this.$refs.form1.validate(async (valid) => {
        if (!valid) return;

        this.$confirm(`${this.formData.name} 비밀번호를 변경합니다.`, '확인', {
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          type: 'info',
        })
          .then(async () => {
            try {
              await this.$http.post(`${this.$config.getServerConfig().core.api}/business/admin/updatePassword`, { data: this.formData });
              this.getUsers();
              this.resetFormData();
              this.$message({
                type: 'success',
                message: `${this.formData.name} 사용자 비밀번호 변경 완료`,
              });
            } catch (error) {
              this.$message({
                type: 'danger',
                message: `${this.formData.name} 사용자 비밀번호 변경 중 에러 발생`,
              });
              this.resetFormData();
            }
          })
          .catch((err) => {
            this.$message({
              type: 'danger',
              message: `${this.formData.name} 사용자 비밀번호 변경 취소`,
            });
            this.resetFormData();
          });
      });
    },
    removeUserInfo(row) {
      this.formData = row;
      this.$confirm(`${this.formData.name} 사용자를 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/business/admin/delete`, { data: this.formData });
            this.getUsers();
            this.resetFormData();
            this.$message({
              type: 'success',
              message: `${this.formData.name} 사용자 삭제 완료`,
            });
          } catch (error) {
            this.$message({
              type: 'danger',
              message: `${this.formData.name} 사용자 삭제 중 에러 발생`,
            });
            this.getUsers();
            this.resetFormData();
          }
        })
        .catch((err) => {
          this.$message({
            type: 'danger',
            message: `${this.formData.name} 사용자 삭제 취소`,
          });
          this.getUsers();
          this.resetFormData();
        });
    },
  },
};
</script>

<style scoped>
.user-thumb-img {
  width: 100px;
  height: 100px;
  margin: 5px;
  display: inline-block;
  background-size: cover;
}

.user-list-thumb-img {
  width: 60px;
  height: 60px;
  margin: 0px;
  display: inline-block;
  background-size: cover;
}

.user-table th {
  text-align: right;
  padding-right: 20px;
  width: 100px;
}
</style>
