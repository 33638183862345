<template>
  <div class="fill-width-height" id="pageMaintnceBoard">
    <el-row style="height: 100%;" class="fclt-dashboard-content-wrap">
      <dk-page-header class="fclt-dashboard-title" style="margin-bottom: 3px;">
        <el-select v-model="searchFcltTypeId" placeholder="시설물 유형" :multiple="true" size="small" @change="changeFcltType" style="width: 250px;">
          <el-option v-for="item in fcltType" :key="item.fcltTypeId" :label="item.fcltTypeName" :value="item.fcltTypeId"></el-option>
        </el-select>
        <el-button size="small" class="icon-button-2" @click="maintnceExportExcel" style="margin-right: 5px;">
          <img src="../assets/img/excel_icon.png" />엑셀 출력
        </el-button>
        <!-- <el-button type="primary" @click="maintnceTemplateDialog">유지관리 템플릿 설정 </el-button> -->
      </dk-page-header>
      <el-col :span="16" class="fclt-dashboard-content">
        <maintnce-board-widget class="inbox" :maintnceSteps="maintnceSteps" :fcltTypeId="searchFcltTypeId"></maintnce-board-widget>
      </el-col>
      <el-col :span="8" class="fclt-dashboard-content">
        <el-row style="height: 100%;">
          <!--  background-color: #f8fafc; -->
          <el-col :sm="24" :md="24" :xs="24" class="dashboard-card fclt-status-wrap inbox" style="margin-bottom: 11px;">
            <div>
              <h4>{{ orgName }} 시설물 설치 현황</h4>

              <fclt-install-status-widget class="chart-wrap"></fclt-install-status-widget>
            </div>
          </el-col>
          <el-col :span="24" class="dashboard-card maintnce-log-wrap inbox">
            <div>
              <h4>주간 시설물 유지관리 내역</h4>
              <!-- <notice-widget
                :isTransparent="false"
                :isTitleShow="false"
                :noticeCount="7"
                style="margin:20px 10px;"
              ></notice-widget>-->
              <weekly-maintnce-status-widget class="chart-wrap"></weekly-maintnce-status-widget>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import _ from 'lodash';
import maintnceLogWidget from './widget/maintnceLogWidget.vue';
import weeklyMaintnceStatusWidget from './widget/weeklyMaintnceStatus.vue';
import fcltInstallStatusWidget from './widget/fcltInstallStatusNoCard.vue';
import maintnceBoardWidget from './widget/maintnceBoardWidget.vue';
import XLSX from 'xlsx';

export default {
  components: {
    'maintnce-log-widget': maintnceLogWidget,
    'weekly-maintnce-status-widget': weeklyMaintnceStatusWidget,
    'fclt-install-status-widget': fcltInstallStatusWidget,
    'maintnce-board-widget': maintnceBoardWidget,
  },
  created() {
    let ll = this.$config.getSiteConfig().maintnceStatus;
    this.maintnceSteps = _.filter(ll, (item) => {
      return item.status !== 'finish';
    });
  },
  mounted() {
    this.getFcltType();
  },
  data() {
    return {
      systemLogInfo: {},
      orgName: this.$config.getSiteConfig().orgName,
      maintnceSteps: [],
      searchFcltTypeId: [],
      fcltType: [],
    };
  },
  methods: {
    async getFcltType() {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltType`);
      this.fcltType = response.data.docs;
    },
    changeFcltType() {
      console.log(this.searchFcltTypeId);
    },

    excelDataConvert(value, name) {
      let temp;
      for (let i = 0; i < value.length; i++) {
        if (value[i].name[0] == name && value[i].name[0] != undefined) {
          temp = value[i].count;
        }
      }
      return temp;
    },
    async maintnceExportExcel() {
      let sheetData1 = [];
      let sheetData2 = [];
      let sheetData3 = [];
      let header1 = [];
      let header2 = [];
      let header3 = [];
      let temp = {};
      let sheetLengthSize1 = [];
      let sheetLengthSize2 = [];
      let sheetLengthSize3 = [];
      const header = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/maintnce/autoReportExcelHeader`, {});
      const body = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/maintnce/autoReportExcel`, {});
      for (let a = 0; a < header.data.autoReportExcelHeaderS1.length; a++) {
        sheetLengthSize1.push({ wch: header.data.autoReportExcelHeaderS1[a].name.length + 3 });
      }

      for (let b = 0; b < header.data.autoReportExcelHeaderS1.length; b++) {
        sheetLengthSize2.push({ wch: header.data.autoReportExcelHeaderS1[b].name.length + 3 });
      }

      for (let c = 0; c < header.data.autoReportExcelHeaderS1.length; c++) {
        sheetLengthSize3.push({ wch: header.data.autoReportExcelHeaderS1[c].name.length + 3 });
      }
      for (let i = 0; i < body.data.fcltTypeCount.length; i++) {
        let tempData1 = {};
        tempData1.fcltTypeName = body.data.fcltTypeCount[i].name[0];
        tempData1.fcltTypeCount = body.data.fcltTypeCount[i].count;
        tempData1.todayMaintnceProcess = this.excelDataConvert(body.data.todayMaintnceProcess, tempData1.fcltTypeName);
        tempData1.todayMaintnceBroken = this.excelDataConvert(body.data.todayMaintnceBroken, tempData1.fcltTypeName);
        tempData1.weekMaintnceBroken = this.excelDataConvert(body.data.weekMaintnceBroken, tempData1.fcltTypeName);
        tempData1.monthMaintnceBroken = this.excelDataConvert(body.data.monthMaintnceBroken, tempData1.fcltTypeName);
        if (tempData1.todayMaintnceBroken == undefined) {
          tempData1.todayMaintnceBroken = 0;
        }
        if (tempData1.todayMaintnceProcess == undefined) {
          tempData1.todayMaintnceProcess = 0;
        }
        tempData1.operateCamera = tempData1.fcltTypeCount - (tempData1.todayMaintnceBroken - tempData1.todayMaintnceProcess);
        if (tempData1.operateCamera < 0) {
          tempData1.operateCamera = 0;
        }
        sheetData1.push(tempData1);
      }

      for (let j = 0; j < body.data.emdFcltCameraCount.length; j++) {
        let tempData2 = {};
        tempData2.emdName = body.data.emdFcltCameraCount[j].name[0];
        tempData2.emdFcltCameraCount = body.data.emdFcltCameraCount[j].count;
        tempData2.emdTodayMaintnceProcess = this.excelDataConvert(body.data.emdTodayMaintnceProcess, tempData2.emdName);
        tempData2.emdTodayMaintnceBroken = this.excelDataConvert(body.data.emdTodayMaintnceBroken, tempData2.emdName);
        tempData2.emdWeekMaintnceBroken = this.excelDataConvert(body.data.emdWeekMaintnceBroken, tempData2.emdName);
        tempData2.emdMonthMaintnceBroken = this.excelDataConvert(body.data.emdMonthMaintnceBroken, tempData2.emdName);
        if (tempData2.emdTodayMaintnceProcess == undefined) {
          tempData2.emdTodayMaintnceProcess = 0;
        }
        if (tempData2.emdTodayMaintnceBroken == undefined) {
          tempData2.emdTodayMaintnceBroken = 0;
        }
        tempData2.emdOperateCamera = tempData2.emdFcltCameraCount - (tempData2.emdTodayMaintnceBroken - tempData2.emdTodayMaintnceProcess);
        if (tempData2.emdOperateCamera < 0) {
          tempData2.emdOperateCamera = 0;
        }
        sheetData2.push(tempData2);
      }

      for (let k = 0; k < body.data.fcltTypeMaintnce.length; k++) {
        if (k + 1 < body.data.fcltTypeMaintnce.length) {
          temp = this.$lodash.union(body.data.fcltTypeMaintnce[0].fcltData, body.data.fcltTypeMaintnce[k + 1].fcltData);
        }
        for (let l = 0; l < temp.length; l++) {
          let tempData3 = {};
          tempData3.fcltTypeName = temp[l].fcltTypeName;
          tempData3.startDate = temp[l].startDate;
          tempData3.endDate = temp[l].endDate;
          tempData3.UID = temp[l].UID;
          tempData3.fcltTypeName = temp[l].fcltTypeName;
          tempData3.setNumberAddr = temp[l].setNumberAddr;
          tempData3.managerName = temp[l].managerName;
          tempData3.fcltName = temp[l].fcltName;
          sheetData3.push(tempData3);
        }
      }
      header1.push(header.data.autoReportExcellabel1);
      header2.push(header.data.autoReportExcellabel2);
      header3.push(header.data.autoReportExcellabel3);

      let excelData1 = this.$lodash.union(header1, sheetData1);
      let excelData2 = this.$lodash.union(header2, sheetData2);
      let excelData3 = this.$lodash.union(header3, sheetData3);

      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.json_to_sheet(excelData1, header.data.autoReportExcelHeaderS1);
      let ww = XLSX.utils.json_to_sheet(excelData2, header.data.autoReportExcelHeaderS2);
      let wz = XLSX.utils.json_to_sheet(excelData3, header.data.autoReportExcelHeaderS3);
      let wscols = sheetLengthSize1;
      let wwcols = sheetLengthSize2;
      let wzcols = sheetLengthSize3;

      ws['!cols'] = wscols;
      ws['!rows'] = [];
      ws['!rows'][0] = { hidden: true };
      ww['!cols'] = wwcols;
      ww['!rows'] = [];
      ww['!rows'][0] = { hidden: true };
      wz['!cols'] = wzcols;
      wz['!rows'] = [];
      wz['!rows'][0] = { hidden: true };
      XLSX.utils.book_append_sheet(wb, ws, '카메라 현황(목적별)');
      XLSX.utils.book_append_sheet(wb, ww, '카메라 현황(지역별)');
      XLSX.utils.book_append_sheet(wb, wz, '장애 처리 현황');
      XLSX.writeFile(wb, `자산관리 시트.xlsx`, { bookType: 'xlsx', type: 'array' });

      this.systemLogInfo.page = `${this.$route.name}`;
      this.systemLogInfo.saveType = 'exportExcel';
      await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
    },
  },
};
</script>
