var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.formTitle,
        visible: _vm.formDialog,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.formDialog = $event
        },
        close: _vm.hideDialog
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form0",
              attrs: {
                model: _vm.formData,
                "label-width": "160px",
                size: "medium",
                rules: _vm.rules,
                "label-position": _vm.$isMobile ? "top" : "right"
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "데이터 명칭", prop: "label" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "데이터 명칭을 입력하세요" },
                            model: {
                              value: _vm.formData.label,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "label", $$v)
                              },
                              expression: "formData.label"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "데이터 키", prop: "key" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "데이터 키를 입력하세요" },
                            model: {
                              value: _vm.formData.key,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "key", $$v)
                              },
                              expression: "formData.key"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "데이터 유형", prop: "formType" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "데이터 유형을 입력하세요"
                              },
                              model: {
                                value: _vm.formData.formType,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "formType", $$v)
                                },
                                expression: "formData.formType"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "텍스트", value: "string" }
                              }),
                              _c("el-option", {
                                attrs: { label: "숫자", value: "number" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-bottom" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  on: { click: _vm.hideDialog }
                },
                [_vm._v("취소")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-right": "5px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.save }
                },
                [_vm._v("저장")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }