

function HiVeWebRtcPlayer(module_id, server_ip, server_port, video_name, video_port) {
    "use strict";

    var pc = null;
    var ws = null;
    var state = -1;
    var latest_stop_time = 0;
    var is_connected = false;
    var video_module = document.getElementById(module_id);
    var show_controls = video_module.controls;
    var media_port = video_port;
	
    this.Play = function Play() {
        var cur_time = new Date().getTime();

        if ((state == -1) && (cur_time - latest_stop_time > 1000)) {
            video_module.srcObject = null;
            state = 0;
            is_connected = false;
            video_module.setAttribute('style', 'background: black url(loader.gif) center no-repeat;');
            show_controls = video_module.controls;
            video_module.controls = false;
            DoSignaling();
        }

        if (cur_time - latest_stop_time <= 1000)
            video_module.pause();
    }

    this.Stop = function Stop() {
        show_controls = video_module.controls;
        Terminate();
    }

    this.DoPtzControl = function DoPtzControl(cmd, action, direction, speed, num, continuous) {
        if( cmd == null || action == null || video_name == null)
            return false;
        
        var jsonObj = {
            type: cmd,
            action: action,
            videoname: video_name,
            direction: direction,
            speed: speed,
            num: num,
			continuous : continuous
        };
        
        ws.send(JSON.stringify(jsonObj));
        
        ws.onmessage = function(evt) {
            var recv = evt.data;
            var data = JSON.parse(recv);
            
            if(data.result != 200)
                console.error("Request Failed:", message);
        }

        ws.onerror = function(evt) {
            if (!is_connected)
            {
                Terminate();
                console.error("Error disconnected:", evt);
            }
        }
    }

    video_module.onplay = this.Play;
    video_module.onpause = this.Stop;
    video_module.onloadedmetadata = this.OnLoadedMetadata

    function gotRemoteStream(e) {
        video_module.srcObject = e.streams[0];
        video_module.setAttribute('style', 'background-color:black');
        video_module.setAttribute('object-fit', 'fill');

        if (show_controls)
            video_module.controls = true;
       
    }

    function Terminate() {
        latest_stop_time = new Date().getTime();

        state = -1;
        video_module.setAttribute('style', 'background-color:black');

        if (show_controls)
            video_module.controls = true;

        if (pc != null) {
            pc.onconnectionstatechange = null;
            pc.close();
            pc = null;
        }

        if (ws != null) {
            ws.onerror = null;
            ws.close();
            ws = null;
        }

        is_connected = false;

        video_module.pause();
    }

    function DoSignaling() {
        var offer_url = "ws://" + server_ip + ":" + server_port + "/hive_api/webrtc_stream/" + video_name;

        try
        {
            ws = new WebSocket(offer_url);
        }
        catch (error)
        {
            Terminate();
            console.error("Error creating websocket: " + error);
        }
		
		ws.onmessage = function(evt) {
			var response = evt.data;
			var json_obj = JSON.parse(response);

			is_connected = true;

			if (json_obj.result == 200)
			{
				if (json_obj.type == "join")
				{
					var servers = null;
					var offerOptions = { offerToReceiveAudio: 0, offerToReceiveVideo: 1 };

					pc = new RTCPeerConnection(servers);
					pc.onicecandidate = onIceCandidate;
					pc.onconnectionstatechange = onConnStateChange;
					pc.ontrack = gotRemoteStream;
			
					pc.createOffer(offerOptions).then(
						onCreateOfferSuccess,
						function() { 
							Terminate();
							console.error("createOffer Error :" + error.toString());
						}
					);
				}
				else if (json_obj.type == "answer")
				{
					var server_sdp = json_obj.sdp;
					var server_endpoint = json_obj.endpoint;

					server_endpoint.candidate = EnsureValidCandidate(server_endpoint.candidate);

					pc.setRemoteDescription(new RTCSessionDescription({type: "answer", sdp: server_sdp}));
					var candidate = new RTCIceCandidate({sdpMLineIndex: server_endpoint.sdpMLineIndex, candidate: server_endpoint.candidate});
					pc.addIceCandidate(candidate);
				}
			}
			else
			{
				Terminate();
				console.error(response);
			}
		}

		ws.onerror = function(evt) {
			if (!is_connected)
			{
				Terminate();
				console.error("Connecting error:", evt);
			}
		}
    }

    function onCreateOfferSuccess(desc) {
        desc.sdp = setCodec(desc.sdp, "video", "H264", 90000);

        //Fix for some browsers and/or adapter incorrect behavior
        desc.sdp = desc.sdp.replace("a=sendrecv", "a=recvonly");
        desc.sdp = desc.sdp.replace("a=sendrecv", "a=recvonly");

        var json_msg = desc.toJSON();
        json_msg["mediaport"] = media_port;

        //Signal the SDP to the server
        ws.send(JSON.stringify(json_msg));

        pc.setLocalDescription(desc).then(
            function() { console.log("setLocalDescription Succeess"); },
            function() { 
                Terminate();
                console.error("setLocalDescription Error :" + error.toString());
            }
        );
    }

    function onCreateSessionDescriptionError(error) {
        Terminate();
        console.error("Failed to create session description: " + error.toString());
    }

    function EnsureValidCandidate(candidate) {
        if ((candidate.search(server_ip) !== -1) || (server_ip == "127.0.0.1") || !ValidateIPaddress(server_ip)) {
            return candidate;
        }

        //In case the server is behind the NAT router, replace private IP with public IP in the candidate
        var candLines = candidate.split(" ");
        var ipIndex = 4;
        for (var i = 0; i < candLines.length; i++) {
            if (candLines[i] === "typ") {
                ipIndex = i - 2;
                break;
            }
        }

        candLines[ipIndex] = server_ip;
        candidate = candLines.join(" ");
        return candidate;
    }

    function ValidateIPaddress(ipaddr) {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddr)) {
            return true;
        }

        return false;
    }

    function onIceCandidate(event) {
        //Do nothing! We only need one endpoint from server; browser is going to connect to it
    }

    function onConnStateChange(event) {
        if (pc.connectionState === "failed") {
            Terminate();
            console.error("Connection failed - playback stop");
        }
    }

    function setCodec(sdp, type, codec, clockRate) {
        var sdpLines = sdp.split("\r\n");

        for (var i = 0; i < sdpLines.length; i++) {
            if (sdpLines[i].search("m=" + type) !== -1) {
                var mLineIndex = i;
                break;
            }
        }

        if (mLineIndex === null) return sdp;

        var codecPayload = null;
        var re = new RegExp(":(\\d+) " + codec + "\/" + clockRate);

        for (var i = mLineIndex; i < sdpLines.length; i++) {
            if (sdpLines[i].search(codec + "/" + clockRate) !== -1) {
                codecPayload = extractPayloadType(sdpLines[i], re);
                if (codecPayload && (EnsureSupportedProfile(codec, sdpLines, mLineIndex, codecPayload))) {
                    sdpLines[mLineIndex] = setDefaultCodec(sdpLines[mLineIndex], codecPayload);
                    break;
                }
            }
        }

        if (codecPayload === null) return sdp;

        var rtmpmap = "a=rtpmap:";
        var rtcp = "a=rtcp-fb:";
        var fmptp = "a=fmtp:";
        var rtmpmapThis = "a=rtpmap:" + codecPayload;
        var rtcpThis = "a=rtcp-fb:" + codecPayload;
        var fmptpThis = "a=fmtp:" + codecPayload;
        var bAddAll = false;
        var resSDPLines = new Array();

        for (var i = 0; i < sdpLines.length; i++) {
            if (i <= mLineIndex) {
                resSDPLines.push(sdpLines[i]);
            }
            else {
                if (sdpLines[i].search("m=") === 0)
                    bAddAll = true;

                var bNotToAdd = ((sdpLines[i].search(rtmpmap) === 0) && (sdpLines[i].search(rtmpmapThis) !== 0)) || ((sdpLines[i].search(rtcp) === 0) && (sdpLines[i].search(rtcpThis) !== 0)) || ((sdpLines[i].search(fmptp) === 0) && (sdpLines[i].search(fmptpThis) !== 0));

                if (bAddAll || !bNotToAdd)
                    resSDPLines.push(sdpLines[i]);
            }

        }

        sdp = resSDPLines.join("\r\n");
        return sdp;
    };
    
    function extractPayloadType(sdpLine, pattern) {
        var result = sdpLine.match(pattern);
        return (result && result.length == 2) ? result[1] : null;
    };

    function EnsureSupportedProfile(codec, sdpLines, mLineIndex, codecPayload) {
        if (codec != "H264")
            return true;

        //Server can send any profile/level H264, but SDP has to specify supported one
        for (var i = mLineIndex; i < sdpLines.length; i++) {
            if ((sdpLines[i].search("a=fmtp:" + codecPayload) === 0) && (sdpLines[i].search("profile-level-id=42") !== -1))
                return true;
        }

        return false;
    };

    function setDefaultCodec(mLine, payload) {
        var elements = mLine.split(" ");
        var newLine = new Array();
        var index = 0;
        for (var i = 0; i < elements.length; i++) {
            if (index === 3) {
                newLine[index++] = payload;
                break;
            }
            if (elements[i] !== payload) newLine[index++] = elements[i];
        }
        return newLine.join(" ");
    };
};

