<template>
  <div class="maintnce-board-container" id="maintnceBoard">
    <div class="maintnce-board-wrap">
      <dk-kanban-board
        :stages="maintnceSteps"
        :blocks="maintnceBoardItem"
        @update-block="updateMaintnceBoardItem"
        @add-block="addMaintnceBoardItem"
        firstStageStatus="step1"
      >
        <div v-for="(item, index) in maintnceBoardItem" :key="index" :slot="item._id" @mouseup="updateModalMaintnceItem(item)">
          <p class="maintnce-item-fclt-name">
            {{ item.fcltName }}
            <span>{{ item.fcltTypeName }}</span>
          </p>
          <p class="maintnce-item-content">{{ item.maintnceSummary }}</p>
          <div class="maintnce-tag-wrap">
            <span v-if="item.startDate" class="maintnce-tag-text date" style="float: right;">{{ getStartDateText(item.startDate) }}</span>
            <span v-if="item.managerName" class="maintnce-tag-text" style="float: right;">{{ item.managerName }}</span>
            <span style="float: right;">{{ item.setNumberAddr }}</span>

            <span v-if="item.workType" class="workType-tag">
              <el-tag size="small">{{ getWorkTypeIcon(item.workType) }}</el-tag>
            </span>
            <!-- <span v-if="item.importance" v-html="getImportanceIcon(item.importance)" class="importance-icon"></span> -->
          </div>
        </div>
      </dk-kanban-board>
    </div>
    <!-- <el-dialog
            title="유지관리 작업 등록"
            :visible.sync="boardItemDialog"
            :close-on-click-modal="false"
        >
            <el-form>
                <el-form-item label="작업내용" prop="workDesc"
             
                >
                    <el-input v-model="formData.workDesc" placeholder="유지관리 작업내용 작성"></el-input>
                </el-form-item>
            </el-form>
             <div class="form-bottom">            
            <el-button 
              @click="boardItemDialog=false" 
              style="float:right;">
                취소
            </el-button>
            <el-button 
              type="primary"
              @click="saveMaintnceWork" 
              style="float:right; margin-right:5px;">
                저장
            </el-button>
        </div>   
    </el-dialog>-->
    <form-maintnce ref="formMaintnce" @procDataComplete="getMaintnceBoardItem"></form-maintnce>
  </div>
</template>

<script>
/* import한 kanban 모듈이 너무 쓰레기라 새로만듬 */
import formMaintnce from '../forms/formMaintnce.vue';
import dkKanbanBoard from '@/components/dkKanbanBoard.vue';
import moment from 'moment';

export default {
  components: {
    'dk-kanban-board': dkKanbanBoard,
    'form-maintnce': formMaintnce,
  },
  created() {
    this.getMaintnceBoardItem();
  },
  props: {
    fcltTypeId: {
      type: Array,
      default: null,
    },
    maintnceSteps: {
      default: [
        {
          status: 'step1',
          label: '발생 / 접수',
        },
        {
          status: 'step2',
          label: '담당자 배정',
        },
        {
          status: 'step3',
          label: '담당자 이동중',
        },
        {
          status: 'progress',
          label: '처리중',
        },
        {
          status: 'complete',
          label: '처리완료',
        },
      ],
    },
  },
  data() {
    return {
      boardItemDialog: false,
      // stages: ["step1", "step2", "step3", "step4", "step5"],
      // stageLabel: [
      //     {"step1": '발생 / 접수'},
      //     {"step2": '담당자 배정'},
      //     {"step3": '담당자 이동중'},
      //     {"step4": '처리중'},
      //     {"step5": '처리완료'},
      // ],
      maintnceBoardItem: [],
      workTypes: [
        { value: '1', label: '점검' },
        { value: '2', label: '수리' },
        { value: '3', label: '교체' },
        { value: '4', label: '기타' },
      ],
      importances: [
        { value: '1', label: '매우높음' },
        { value: '2', label: '높음' },
        { value: '3', label: '보통' },
        { value: '4', label: '낮음' },
        { value: '5', label: '매우낮음' },
      ],
    };
  },
  watch: {
    fcltTypeId(newVal) {
      this.getMaintnceBoardItem();
    },
  },
  methods: {
    async getMaintnceBoardItem() {
      let param = {};
      if (this.fcltTypeId && this.fcltTypeId.length > 0) {
        param.fcltTypeId = this.fcltTypeId;
      }
      let res = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/maintnceLog`, {
        params: param,
      });
      this.maintnceBoardItem = res.data.docs;
    },
    openBoardItemDialog() {},
    getStageLabel(stage) {
      return this.stageLabel[stage];
    },
    async updateMaintnceBoardItem(item, status) {
      let formData = this.$lodash.cloneDeep(item);
      formData.status = status;
      let saveFunc = 'maintnce/update';

      let response = await this.$http
        .post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, { data: formData })
        .then((response) => {
          this.$message({
            type: 'success',
            message: `${formData.fcltName} 유지관리 상태 업데이트 완료`,
          });
          this.getMaintnceBoardItem();
        })
        .catch((err) => {
          this.$message.error(`${formData.fcltName} 유지관리 상태 업데이트 중 에러 발생`);
        });
    },
    addMaintnceBoardItem(stage) {
      this.$refs['formMaintnce'].showDialog(null);
    },
    updateModalMaintnceItem(item) {
      this.$refs['formMaintnce'].showDialog(item);
    },
    getManagerInfo(info) {
      return '';
    },
    getWorkTypeIcon(item) {
      if (item === '1') return `점검`;
      else if (item === '2') return `수리`;
      else if (item === '3') return `교체`;
      else if (item === '4') return `기타`;
    },
    getImportanceIcon(item) {
      if (item === '1') return `<i class="fas fa-caret-square-up" style="color:#E53935;font-size:20px; margin-top:2px;"></i>`;
      else if (item === '2') return `<i class="fas fa-caret-square-up" style="color:#E53935;font-size:20px; margin-top:2px;"></i>`;
      else if (item === '3') return `<i class="fas fa-caret-square-right" style="color:#FFB300;font-size:20px; margin-top:2px;"></i>`;
      else if (item === '4') return `<i class="fas fa-caret-square-down" style="color:#7CB342;font-size:20px; margin-top:2px;"></i>`;
      else if (item === '5') return `<i class="fas fa-caret-square-down" style="color:#1E88E5;font-size:20px; margin-top:2px;"></i>`;
      else return '';
    },
    getStartDateText(startDate) {
      return moment(startDate).format('MM-DD');
    },
  },
};
</script>

<style scoped>
/*
.workType-tag, .importance-icon {
  float:left; margin-left:6px;
} */
</style>
