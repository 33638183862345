var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "vmsMessageEditor" } },
    [
      _c("div", { staticClass: "vms-editor-toolbar" }, [
        _c(
          "div",
          { staticClass: "toolbar-panel" },
          [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: "비트맵 컬러",
                  placement: "top-start"
                }
              },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { float: "left" },
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.bmpColor,
                      callback: function($$v) {
                        _vm.bmpColor = $$v
                      },
                      expression: "bmpColor"
                    }
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "4비트 (4색)", value: "4bit" }
                    }),
                    _c("el-option", {
                      attrs: { label: "24비트 (풀컬러)", value: "24bit" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "toolbar-panel" },
          [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: { effect: "dark", content: "미리보기", placement: "top" }
              },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "toolbar-button",
                    attrs: { size: "mini", icon: "fas fa-desktop" },
                    on: { click: _vm.preview }
                  },
                  [_vm._v("미리보기")]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "vms-editor-toolbar" }, [
        _c(
          "div",
          { staticClass: "toolbar-panel" },
          [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: "텍스트 추가",
                  placement: "top-start"
                }
              },
              [
                _c("el-button", {
                  staticClass: "toolbar-button",
                  attrs: { size: "mini", icon: "fas fa-pen" },
                  on: {
                    click: function($event) {
                      return _vm.setMode(_vm.ADD_BLOCK_TEXT_OBJECT)
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: "텍스트 라인",
                  placement: "top-start"
                }
              },
              [
                _c("el-button", {
                  staticClass: "toolbar-button",
                  attrs: { size: "mini", icon: "fas fa-edit" },
                  on: {
                    click: function($event) {
                      return _vm.setMode(_vm.ADD_LINE_TEXT_OBJECT)
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.type == "figure",
                expression: "type == 'figure'"
              }
            ],
            staticClass: "toolbar-panel"
          },
          [
            _c(
              "el-tooltip",
              {
                staticClass: "item",
                attrs: {
                  effect: "dark",
                  content: "선형 추가",
                  placement: "top-start"
                }
              },
              [
                _c("el-button", {
                  staticClass: "toolbar-button",
                  attrs: { size: "mini", icon: "fas fa-grip-lines" },
                  on: {
                    click: function($event) {
                      return _vm.setMode(_vm.ADD_LINE_OBJECT)
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm.showLineObjectToolbar
          ? _c(
              "div",
              { ref: "lineObjectToolbar", staticClass: "toolbar-panel" },
              [
                this.bmpColor == "24bit"
                  ? _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "선형 색상",
                          placement: "top"
                        }
                      },
                      [
                        _c("el-color-picker", {
                          staticStyle: { float: "left", "margin-right": "6px" },
                          attrs: { size: "mini" },
                          on: { change: _vm.changeStrokeColor },
                          model: {
                            value: _vm.selectObject.strokeColor,
                            callback: function($$v) {
                              _vm.$set(_vm.selectObject, "strokeColor", $$v)
                            },
                            expression: "selectObject.strokeColor"
                          }
                        })
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticStyle: { float: "left" } },
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "4색 빨강",
                              placement: "top"
                            }
                          },
                          [
                            _c("div", { staticClass: "color-box" }, [
                              _c("span", {
                                staticStyle: { "background-color": "#f00" },
                                on: {
                                  click: function($event) {
                                    return _vm.changeStrokeColor("#ff0000")
                                  }
                                }
                              })
                            ])
                          ]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "4색 노랑",
                              placement: "top"
                            }
                          },
                          [
                            _c("div", { staticClass: "color-box" }, [
                              _c("span", {
                                staticStyle: { "background-color": "#ff0" },
                                on: {
                                  click: function($event) {
                                    return _vm.changeStrokeColor("#ffff00")
                                  }
                                }
                              })
                            ])
                          ]
                        ),
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "4색 녹색",
                              placement: "top"
                            }
                          },
                          [
                            _c("div", { staticClass: "color-box" }, [
                              _c("span", {
                                staticStyle: { "background-color": "#0f0" },
                                on: {
                                  click: function($event) {
                                    return _vm.changeStrokeColor("#00ff00")
                                  }
                                }
                              })
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "화살표 추가",
                      placement: "top-start"
                    }
                  },
                  [
                    _c("el-button", {
                      staticClass: "toolbar-button",
                      attrs: { size: "mini", icon: "fas fa-arrow-right" },
                      on: { click: _vm.setIsEndArrow }
                    })
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "터널 선형",
                      placement: "top-start"
                    }
                  },
                  [
                    _c("el-button", {
                      staticClass: "toolbar-button",
                      attrs: { size: "mini", icon: "fas fa-archway" },
                      on: { click: _vm.setIsTunnel }
                    })
                  ],
                  1
                ),
                _c(
                  "el-select",
                  {
                    staticStyle: {
                      float: "left",
                      "margin-right": "5px",
                      width: "80px"
                    },
                    attrs: { size: "mini" },
                    model: {
                      value: _vm.selectObject.strokeWidth,
                      callback: function($$v) {
                        _vm.$set(_vm.selectObject, "strokeWidth", $$v)
                      },
                      expression: "selectObject.strokeWidth"
                    }
                  },
                  [
                    _c("el-option", { attrs: { label: "4px", value: 4 } }),
                    _c("el-option", { attrs: { label: "8px", value: 8 } }),
                    _c("el-option", { attrs: { label: "12px", value: 12 } }),
                    _c("el-option", { attrs: { label: "16px", value: 16 } }),
                    _c("el-option", { attrs: { label: "20px", value: 20 } }),
                    _c("el-option", { attrs: { label: "26px", value: 26 } }),
                    _c("el-option", { attrs: { label: "30px", value: 30 } }),
                    _c("el-option", { attrs: { label: "36px", value: 36 } })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.showTextObjectToolbar
          ? _c(
              "div",
              { ref: "textObjectToolbar", staticClass: "toolbar-panel" },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "필인 텍스트 선택",
                      placement: "top"
                    }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.selectObject &&
                              _vm.selectObject.objectType === "text" &&
                              _vm.selectObject.isFillin,
                            expression:
                              "selectObject && selectObject.objectType === 'text' && selectObject.isFillin"
                          }
                        ],
                        staticStyle: { float: "left", width: "120px" },
                        attrs: { size: "mini" },
                        on: { change: _vm.changeFillinText },
                        model: {
                          value: _vm.selectObject.text,
                          callback: function($$v) {
                            _vm.$set(_vm.selectObject, "text", $$v)
                          },
                          expression: "selectObject.text"
                        }
                      },
                      _vm._l(_vm.fillinTextData, function(item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.label, value: item.value }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                this.bmpColor == "24bit"
                  ? _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "텍스트 색상",
                          placement: "top"
                        }
                      },
                      [
                        _c("el-color-picker", {
                          staticStyle: { float: "left", "margin-right": "6px" },
                          attrs: { size: "mini" },
                          on: {
                            change: _vm.changeTextColor,
                            mousedown: _vm.selectColorPicker
                          },
                          model: {
                            value: _vm.selectObject.fontColor,
                            callback: function($$v) {
                              _vm.$set(_vm.selectObject, "fontColor", $$v)
                            },
                            expression: "selectObject.fontColor"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticStyle: { float: "left" } },
                  [
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "4색 빨강",
                          placement: "top"
                        }
                      },
                      [
                        _c("div", { staticClass: "color-box" }, [
                          _c("span", {
                            staticStyle: { "background-color": "#f00" },
                            on: {
                              click: function($event) {
                                return _vm.changeTextColor("#ff0000")
                              },
                              mousedown: _vm.preventDefault
                            }
                          })
                        ])
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "4색 노랑",
                          placement: "top"
                        }
                      },
                      [
                        _c("div", { staticClass: "color-box" }, [
                          _c("span", {
                            staticStyle: { "background-color": "#ff0" },
                            on: {
                              click: function($event) {
                                return _vm.changeTextColor("#ffff00")
                              },
                              mousedown: _vm.preventDefault
                            }
                          })
                        ])
                      ]
                    ),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: "4색 녹색",
                          placement: "top"
                        }
                      },
                      [
                        _c("div", { staticClass: "color-box" }, [
                          _c("span", {
                            staticStyle: { "background-color": "#0f0" },
                            on: {
                              click: function($event) {
                                return _vm.changeTextColor("#00ff00")
                              },
                              mousedown: _vm.preventDefault
                            }
                          })
                        ])
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "el-select",
                  {
                    staticStyle: {
                      float: "left",
                      "margin-right": "5px",
                      width: "80px"
                    },
                    attrs: { size: "mini" },
                    on: { change: _vm.changeTextSize },
                    model: {
                      value: _vm.selectObject.fontSize,
                      callback: function($$v) {
                        _vm.$set(_vm.selectObject, "fontSize", $$v)
                      },
                      expression: "selectObject.fontSize"
                    }
                  },
                  [
                    _c("el-option", { attrs: { label: "24px", value: 24 } }),
                    _c("el-option", { attrs: { label: "32px", value: 32 } }),
                    _c("el-option", { attrs: { label: "36px", value: 36 } }),
                    _c("el-option", { attrs: { label: "40px", value: 40 } }),
                    _c("el-option", { attrs: { label: "48px", value: 48 } }),
                    _c("el-option", { attrs: { label: "56px", value: 56 } }),
                    _c("el-option", { attrs: { label: "64px", value: 64 } }),
                    _c("el-option", { attrs: { label: "70px", value: 70 } })
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: { effect: "dark", content: "글꼴", placement: "top" }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: {
                          float: "left",
                          "margin-right": "5px",
                          width: "120px"
                        },
                        attrs: { size: "mini" },
                        on: { change: _vm.changeFontFamily },
                        model: {
                          value: _vm.selectObject.fontFamily,
                          callback: function($$v) {
                            _vm.$set(_vm.selectObject, "fontFamily", $$v)
                          },
                          expression: "selectObject.fontFamily"
                        }
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "나눔고딕", value: "NanumGothic" }
                        }),
                        _c("el-option", {
                          attrs: { label: "돋움", value: "dotum" }
                        }),
                        _c("el-option", {
                          attrs: { label: "고딕", value: "gothic" }
                        }),
                        _c("el-option", {
                          attrs: { label: "바탕", value: "batang" }
                        }),
                        _c("el-option", {
                          attrs: { label: "궁서", value: "gungsuh" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "폰트 굵기",
                      placement: "top"
                    }
                  },
                  [
                    _c("el-button", {
                      staticClass: "toolbar-button",
                      style:
                        _vm.selectObject.fontWeight == "bold"
                          ? "color:#8a8a8a"
                          : "color:#bfbfbf",
                      attrs: { size: "mini", icon: "fas fa-bold" },
                      on: { click: _vm.changeFontWeight }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.showLineTextObjectToolbar
          ? _c(
              "div",
              { ref: "textAlignToolbar", staticClass: "toolbar-panel" },
              [
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "좌측 정렬",
                      placement: "top-start"
                    }
                  },
                  [
                    _c("el-button", {
                      staticClass: "toolbar-button",
                      attrs: { size: "mini", icon: "fas fa-align-left" },
                      on: { click: _vm.setTextAlignLeft }
                    })
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "가운데 정렬",
                      placement: "top-start"
                    }
                  },
                  [
                    _c("el-button", {
                      staticClass: "toolbar-button",
                      attrs: { size: "mini", icon: "fas fa-align-center" },
                      on: { click: _vm.setTextAlignCenter }
                    })
                  ],
                  1
                ),
                _c(
                  "el-tooltip",
                  {
                    staticClass: "item",
                    attrs: {
                      effect: "dark",
                      content: "우측 정렬",
                      placement: "top-start"
                    }
                  },
                  [
                    _c("el-button", {
                      staticClass: "toolbar-button",
                      attrs: { size: "mini", icon: "fas fa-align-right" },
                      on: { click: _vm.setTextAlignRight }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "div",
        {
          ref: "canvasWrap",
          staticClass: "canvas-wrap",
          on: { mouseup: _vm.mouseup }
        },
        [
          _c("canvas", {
            ref: "dummyCanvas",
            staticClass: "dummy-canvas",
            attrs: {
              width: _vm.canvasWidth,
              height: _vm.canvasHeight,
              viewBox: _vm.canvasViewBox
            }
          }),
          _c(
            "svg",
            {
              ref: "vmsMessageSvg",
              staticClass: "editor-canvas",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                stroke: "none",
                fill: "none",
                overflow: "hidden",
                preserveAspectRatio: "none",
                width: _vm.canvasWidth,
                height: _vm.canvasHeight,
                viewBox: _vm.canvasViewBox
              },
              on: {
                click: _vm.clickEditor,
                mousedown: _vm.mousedownEditor,
                mouseup: _vm.mouseupEditor,
                mousemove: _vm.mousemoveEditor
              }
            },
            [
              _c("g", [
                _c("rect", {
                  staticClass: "rect-background",
                  staticStyle: { fill: "#000" },
                  attrs: { width: _vm.canvasWidth, height: _vm.canvasHeight }
                })
              ]),
              _vm._l(_vm.vmsMessageData.textData, function(item) {
                return _c("text-object", {
                  key: item.objectId,
                  ref: "textObject",
                  refInFor: true,
                  attrs: {
                    inputX: item.inputX,
                    inputY: item.inputY,
                    objectId: item.objectId,
                    isFillin: item.isFillin,
                    objectData: item,
                    bmpColor: _vm.bmpColor
                  }
                })
              }),
              _vm._l(_vm.vmsMessageData.textBlockData, function(item) {
                return _c("text-block-object", {
                  key: item.objectId,
                  ref: "textBlockObject",
                  refInFor: true,
                  attrs: {
                    inputX: item.inputX,
                    inputY: item.inputY,
                    objectId: item.objectId,
                    isFillin: item.isFillin,
                    objectData: item,
                    bmpColor: _vm.bmpColor
                  }
                })
              }),
              _vm._l(_vm.vmsMessageData.imageData, function(item) {
                return _c("image-object", {
                  key: item.objectId,
                  ref: "imageObject",
                  refInFor: true,
                  attrs: {
                    inputX: item.inputX,
                    inputY: item.inputY,
                    objectId: item.objectId,
                    imageUrl: item.imageUrl,
                    objectData: item,
                    bmpColor: _vm.bmpColor
                  }
                })
              }),
              _vm._l(_vm.vmsMessageData.lineData, function(item) {
                return _c("line-object", {
                  key: item.objectId,
                  ref: "lineObject",
                  refInFor: true,
                  attrs: {
                    objectId: item.objectId,
                    objectData: item,
                    bmpColor: _vm.bmpColor
                  }
                })
              }),
              _c("line", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isLineDraw,
                    expression: "isLineDraw"
                  }
                ],
                attrs: {
                  x1: _vm.tempLineObject.x1,
                  y1: _vm.tempLineObject.y1,
                  x2: _vm.tempLineObject.x2,
                  y2: _vm.tempLineObject.y2,
                  stroke: "#00ff00",
                  "stroke-width": "10"
                }
              })
            ],
            2
          ),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isDragObject,
                expression: "isDragObject"
              }
            ],
            staticClass: "drag-mask",
            on: { mousemove: _vm.mousemove }
          }),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.isDragObject && this.selectObject.objectType === "text",
                expression:
                  "isDragObject && this.selectObject.objectType === 'text'"
              }
            ],
            ref: "dragBox",
            staticClass: "drag-box",
            style: [_vm.dragBoxStyle]
          })
        ]
      ),
      _vm.showTextObjectToolbar
        ? _c(
            "div",
            _vm._l(_vm.fillinTextData, function(data) {
              return _c(
                "el-tag",
                {
                  key: data.value,
                  staticStyle: { margin: "4px 2px" },
                  attrs: { type: "info", size: "small" }
                },
                [_vm._v(_vm._s(data.label))]
              )
            }),
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "VMS 메시지 미리보기",
            visible: _vm.previewDialog,
            "append-to-body": ""
          },
          on: {
            "update:visible": function($event) {
              _vm.previewDialog = $event
            },
            open: _vm.procPreviewImage
          }
        },
        [
          _c("img", {
            ref: "previewImage",
            staticStyle: { height: "auto", width: "100%" }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }