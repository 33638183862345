var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cctv-status",
      staticStyle: {
        "background-color": "#3c6db0",
        width: "100%",
        height: "100%",
        color: "#fff"
      }
    },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "status-text",
          staticStyle: {
            float: "left",
            display: "flex",
            "flex-direction": "column",
            "justify-content": "center",
            height: "100%"
          }
        },
        [
          _c("h4", [_vm._v("CCTV 상태정보")]),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { md: 24, lg: 24, xl: 12 } }, [
                _c("span", [_vm._v("설치대수")]),
                _c("span", { staticClass: "status-count" }, [
                  _vm._v(_vm._s(_vm.totalFclt))
                ])
              ]),
              _c("el-col", { attrs: { md: 24, lg: 24, xl: 12 } }, [
                _c("span", [_vm._v("통신이상")]),
                _c("span", { staticClass: "status-count" }, [
                  _vm._v(_vm._s(_vm.error))
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "icon-wrap",
        staticStyle: {
          float: "left",
          display: "flex",
          "flex-direction": "column",
          "justify-content": "center",
          height: "100%"
        }
      },
      [_c("img", { attrs: { src: "static/images/cctvicon.png" } })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }