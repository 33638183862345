import { render, staticRenderFns } from "./pageEnfData.vue?vue&type=template&id=2dbe7677&"
import script from "./pageEnfData.js?vue&type=script&lang=js&"
export * from "./pageEnfData.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/dk-platform-frontend/dk-platform-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2dbe7677')) {
      api.createRecord('2dbe7677', component.options)
    } else {
      api.reload('2dbe7677', component.options)
    }
    module.hot.accept("./pageEnfData.vue?vue&type=template&id=2dbe7677&", function () {
      api.rerender('2dbe7677', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ipes/pageEnfData.vue"
export default component.exports