<template>
	<el-dialog
		:title="formTitle"
		:visible.sync="formDialog"
		@close="hideDialog"
		:close-on-click-modal="false"
	>
		<div>
			<el-form
				:model="formData"
				label-width="160px"
				size="medium"
				:rules="rules"
				ref="form0"
				:label-position="$isMobile ? 'top' : 'right'"
			>
				<el-row>
					<el-col :span="18">
						<el-form-item label="데이터 명칭" prop="label">
							<el-input v-model="formData.label" placeholder="데이터 명칭을 입력하세요"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="18">
						<el-form-item label="데이터 키" prop="key">
							<el-input v-model="formData.key" placeholder="데이터 키를 입력하세요"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="18">
						<el-form-item label="데이터 유형" prop="formType">
							<el-select v-model="formData.formType" placeholder="데이터 유형을 입력하세요">
								<el-option label="텍스트" value="string"></el-option>
								<el-option label="숫자" value="number"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div class="form-bottom">
				<el-button @click="hideDialog" style="float: right;">취소</el-button>
				<el-button type="primary" @click="save" style="float: right; margin-right: 5px;">저장</el-button>
			</div>
		</div>
	</el-dialog>
</template>

<script>
import moment from 'moment';

export default {
	data() {
		var checkDataKey = async (value) => {
			try {
				let result = await this.$http.get(
					`${this.$config.getServerConfig().core.api}/core/api/dataset/checkDatasetKey`,
					{ params: { key: value } }
				);
				return result.data.isExist;
			} catch (err) {
				console.error(err);
				return true;
			}
		};
		var validateKey = async (rule, value, callback) => {
			if (value === '') {
				callback(new Error('데이터 키를 입력해 주세요'));
			} else if (value == this.originalKey && this.updateFlag) {
				callback();
			} else {
				let chk = await checkDataKey(value);
				if (chk) {
					callback(new Error('이미 등록된 데이터 키입니다'));
				} else {
					callback();
				}
			}
		};
		return {
			systemLogInfo : {},
			updateFlag: false,
			formDialog: false,
			formTitle: '',
			originalKey: '',
			formData: {},
			rules: {
				label: [
					{
						required: true,
						message: '데이터 명칭을 입력해 주세요',
						trigger: ['change', 'blur'],
					},
				],
				key: [
					{
						required: true,
						validator: validateKey,
						trigger: ['change', 'blur'],
					},
				],
				formType: [
					{
						required: true,
						message: '데이터 유형을 입력해 주세요',
						trigger: ['change', 'blur'],
					},
				],
			},
			disabledDates: [],
		};
	},
	created() {},
	methods: {
		save() {
			this.$refs.form0.validate(async (valid) => {
				try {
					let me = this;
					if (valid) {
						let saveFunc = 'dataset/add';
						if (this.updateFlag) {
							saveFunc = 'dataset/update';
						}
						// this.$store
						// 	.dispatch(saveFunc, { data: this.formData })
						let response = await this.$http.post(
							`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`,
							{ data: this.formData }
						);
						me.$emit('procDataComplete', this.formData);
						me.hideDialog();
						let msg = '';
						if (this.updateFlag) {
							msg = '데이터셋 수정 완료';
						} else {
							msg = '데이터셋 등록 완료';
						}
						me.$message({
							type: 'success',
							message: msg,
						});
						
						me.systemLogInfo.page = `${me.$route.name}`
						me.systemLogInfo.saveType = saveFunc
						await me.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: me.systemLogInfo });
					} else {
						return false;
					}
				} catch (error) {
					console.log(error);
					this.hideDialog();
					let msg = '';
					if (this.updateFlag) {
						msg = '데이터셋 수정 중 에러 발생';
					} else {
						msg = '데이터셋 등록 중 에러 발생';
					}
					this.$message.error(msg);
				}
			});
		},
		showDialog(row) {
			if (row) {
				this.updateFlag = true;
				this.formTitle = '데이터셋 수정';
				this.originalKey = row.key;
				this.formData = this.$lodash.cloneDeep(row);
			} else {
				this.updateFlag = false;
				this.formTitle = '데이터셋 추가';
				this.resetData();
			}
			this.formDialog = true;
			this.$forceUpdate();
			this.$nextTick(() => {});
		},
		hideDialog() {
			this.resetData();
			this.formDialog = false;
		},
		resetData() {
			this.formData = {
				label: '',
				key: '',
				formType: '',
			};
			this.originalKey = '';
			if (this.$refs.form0) {
				this.$refs.form0.resetFields();
			}
		},

		changeIsPopup(val) {
			if (val) {
				//if (!this.formData.endDate) {
				//  this.formData.endDate = new Date();
				//}
			}
		},
		test() {
			this.$refs['popupEndDate'];
		},
	},
};
</script>
