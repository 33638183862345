var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-set page-flooding" }, [
    _c(
      "div",
      {
        staticClass: "top-bar-space",
        staticStyle: { bottom: "0", overflow: "hidden" }
      },
      [
        _c(
          "div",
          { ref: "gisContainer", staticClass: "fill-width-height" },
          [
            _c("dk-gis", {
              ref: "dkGis",
              attrs: {
                "fill-height": "",
                id: "dkGis",
                gisApiInfo: _vm.gisApiInfo,
                apiLoadComplete: _vm.apiLoadComplete,
                clusterData: _vm.clusterData,
                selectBoxData: _vm.selectBoxData
              },
              on: { moveEnd: _vm.changedBounds, selectMarker: _vm.selectMarker }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "legend-wrap", staticStyle: { "margin-top": "60px" } },
          [
            _c(
              "ul",
              _vm._l(_vm.fcltTypeList, function(fcltType) {
                return _c(
                  "li",
                  {
                    key: fcltType.fcltTypeId,
                    class: "flex-center " + _vm.isSelectFcltType(fcltType),
                    on: {
                      click: function($event) {
                        return _vm.onClickFcltTypeSelect(fcltType)
                      }
                    }
                  },
                  [
                    _c("img", { attrs: { src: fcltType.gisMarkerImage } }),
                    _c("span", [_vm._v(_vm._s(fcltType.fcltTypeName))])
                  ]
                )
              }),
              0
            )
          ]
        ),
        _c("div", { staticClass: "d-flex flooding-btn" }, [
          _vm._m(0),
          _c(
            "div",
            {
              on: {
                click: function($event) {
                  return _vm.selectListDetail()
                }
              }
            },
            [_vm._v("\n        침수 지역 목록 보기\n      ")]
          )
        ]),
        _c(
          "div",
          {
            ref: "detail",
            staticClass: "fclt-menu-item-container flooding-container",
            staticStyle: { bottom: "0" }
          },
          [
            _c(
              "span",
              {
                ref: "fcltInfoOpen",
                staticClass: "fclt-info-open",
                on: { click: _vm.freeFloodginList }
              },
              [
                _c("i", {
                  staticClass: "arrow-click icon-arrow arrow-reverse"
                }),
                _vm._v(" 시설물 보기\n      ")
              ]
            ),
            _c(
              "div",
              { ref: "mobileFcltLabel", staticClass: "mobile-fclt-label" },
              [
                _vm.floodginList && _vm.floodginList.length === 1
                  ? [
                      _vm.floodginList[0].fcltTypeId === "waterlevel"
                        ? [
                            _c("p", { staticClass: "fclt-name" }, [
                              _vm._v("침수 지역 데이터 목록")
                            ]),
                            _vm._m(1)
                          ]
                        : _vm.floodginList[0].fcltTypeId ===
                          "7d9235d0-727b-11eb-8c89-ed5afab83358"
                        ? [
                            _c("p", { staticClass: "fclt-name" }, [
                              _vm._v("침수 지역 데이터 목록")
                            ]),
                            _vm._m(2)
                          ]
                        : _vm.floodginList[0].fcltTypeId ===
                          "d55690f0-74cd-11eb-a37b-379839e65cab"
                        ? [
                            _c("p", { staticClass: "fclt-name" }, [
                              _vm._v("침수 지역 데이터 목록")
                            ]),
                            _vm._m(3)
                          ]
                        : [
                            _c("p", { staticClass: "fclt-name" }, [
                              _vm._v(_vm._s(_vm.floodginList[0].fcltTypeName))
                            ])
                          ]
                    ]
                  : _vm._e()
              ],
              2
            ),
            _c(
              "div",
              { ref: "floodingList", staticClass: "flooding-list" },
              _vm._l(_vm.floodginList, function(floodgincontent) {
                return _c(
                  "div",
                  { staticClass: "flooding-list-container" },
                  [
                    floodgincontent.fcltTypeId === "waterlevel"
                      ? [
                          _c(
                            "div",
                            { staticClass: "mb-bottom-mg maintenance-summary" },
                            [
                              _c("span", [_vm._v("종류")]),
                              _c("span", [
                                _vm._v(_vm._s(floodgincontent.fcltTypeName))
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "mb-bottom-mg maintenance-summary" },
                            [
                              _c("span", [_vm._v("명칭")]),
                              _c("span", [
                                _vm._v(_vm._s(floodgincontent.fcltName))
                              ])
                            ]
                          ),
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mb-bottom-mg w-50 maintenance-summary"
                              },
                              [
                                _c("span", [_vm._v("현재 수위")]),
                                _c("span", [
                                  _vm._v(_vm._s(floodgincontent.waterLevel))
                                ])
                              ]
                            )
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "mb-bottom-mg maintenance-summary stat-text-color"
                            },
                            [
                              _c("span", [_vm._v("수위 상태")]),
                              _c("span", { class: floodgincontent.className }, [
                                _vm._v(_vm._s(floodgincontent.status))
                              ])
                            ]
                          )
                        ]
                      : floodgincontent.fcltTypeId ===
                        "7d9235d0-727b-11eb-8c89-ed5afab83358"
                      ? [
                          _c(
                            "div",
                            { staticClass: "mb-bottom-mg maintenance-summary" },
                            [
                              _c("span", [_vm._v("종류")]),
                              _c("span", [
                                _vm._v(_vm._s(floodgincontent.fcltTypeName))
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "mb-bottom-mg maintenance-summary" },
                            [
                              _c("span", [_vm._v("명칭")]),
                              _c("span", [
                                _vm._v(_vm._s(floodgincontent.fcltName))
                              ])
                            ]
                          ),
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mb-bottom-mg maintenance-summary stat-text-color"
                              },
                              [
                                _c("span", [_vm._v("침수 상태")]),
                                _c(
                                  "span",
                                  { class: floodgincontent.className },
                                  [_vm._v(_vm._s(floodgincontent.status))]
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "div",
                              {
                                staticClass: "mb-bottom-mg maintenance-summary"
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(floodgincontent.updateName))
                                ]),
                                _c("span", [
                                  _vm._v(_vm._s(floodgincontent.updateTime))
                                ])
                              ]
                            )
                          ])
                        ]
                      : floodgincontent.fcltTypeId ===
                        "d55690f0-74cd-11eb-a37b-379839e65cab"
                      ? [
                          _c(
                            "div",
                            { staticClass: "mb-bottom-mg maintenance-summary" },
                            [
                              _c("span", [_vm._v("종류")]),
                              _c("span", [
                                _vm._v(_vm._s(floodgincontent.fcltTypeName))
                              ])
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "mb-bottom-mg maintenance-summary" },
                            [
                              _c("span", [_vm._v("명칭")]),
                              _c("span", [
                                _vm._v(_vm._s(floodgincontent.fcltName))
                              ])
                            ]
                          ),
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "mb-bottom-mg w-50 maintenance-summary stat-text-color"
                              },
                              [
                                _c("span", [_vm._v("상태")]),
                                _c(
                                  "span",
                                  { class: floodgincontent.className },
                                  [_vm._v(_vm._s(floodgincontent.status))]
                                )
                              ]
                            )
                          ]),
                          _c("div", { staticClass: "d-flex" }, [
                            _c(
                              "div",
                              {
                                staticClass: "mb-bottom-mg maintenance-summary"
                              },
                              [
                                _c("span", [_vm._v("상태유지시간")]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      Math.floor(
                                        floodgincontent.properties
                                          .status_duration / 60
                                      )
                                    ) + "분"
                                  )
                                ])
                              ]
                            )
                          ])
                        ]
                      : [
                          _c(
                            "div",
                            { staticClass: "mb-bottom-mg maintenance-summary" },
                            [
                              _c("span", [_vm._v("명칭")]),
                              _c("span", [
                                _vm._v(_vm._s(floodgincontent.fcltName))
                              ])
                            ]
                          ),
                          _c("ul", { staticClass: "fclt-detail-view-menu" }, [
                            _c("li", { on: { click: _vm.setRoadview } }, [
                              _c("i", { staticClass: "fas fa-map-marker-alt" }),
                              _c("div", [_vm._v("거리뷰")])
                            ]),
                            _c("li", { on: { click: _vm.selectNavigation } }, [
                              _c("i", { staticClass: "fas fa-wrench" }),
                              _c("div", [_vm._v("네비게이션")])
                            ])
                          ])
                        ]
                  ],
                  2
                )
              }),
              0
            )
          ]
        )
      ]
    ),
    _c(
      "div",
      {
        class:
          "side-menu-container " + (_vm.isNaviSelect ? "d-block" : "d-none"),
        on: { click: _vm.backgroundOff }
      },
      [
        _c("div", { staticClass: "navi-wrap d-flex" }, [
          _c(
            "div",
            { staticClass: "btn-style", on: { click: _vm.kakaoNavi } },
            [
              _c("img", {
                attrs: { src: require("../assets/img/kakaonavi.png") }
              }),
              _c("span", [_vm._v("카카오내비")])
            ]
          ),
          _c("div", { staticClass: "btn-style", on: { click: _vm.tmapNavi } }, [
            _c("img", { attrs: { src: require("../assets/img/tmap.png") } }),
            _c("span", [_vm._v("TMap")])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hamberger-box" }, [
      _c("div", { staticClass: "hamberger mb-both-mg" }, [
        _c("span"),
        _c("span"),
        _c("span")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "fclt-type legend-circle",
        staticStyle: { width: "auto" }
      },
      [
        _c("span", { staticClass: "caution" }, [_vm._v("심각")]),
        _c("span", { staticClass: "alert" }, [_vm._v("경계")]),
        _c("span", { staticClass: "critical" }, [_vm._v("주의")]),
        _c("span", { staticClass: "overflow" }, [_vm._v("관심")]),
        _c("span", { staticClass: "primary" }, [_vm._v("정상")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "fclt-type legend-circle",
        staticStyle: { width: "auto" }
      },
      [
        _c("span", { staticClass: "caution" }, [_vm._v("침수")]),
        _c("span", { staticClass: "primary" }, [_vm._v("정상")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "fclt-type legend-circle",
        staticStyle: { width: "auto" }
      },
      [
        _c("span", { staticClass: "alert" }, [_vm._v("열림")]),
        _c("span", { staticClass: "primary" }, [_vm._v("닫힘")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }