<template>
  <div class="fill-width-height el-container" id="pageControlLog">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <el-form ref="form1" :model="formSearch" label-width="120px" size="medium">
              <el-row>
                <el-col :span="24" class="grid-header-menu"></el-col>
              </el-row>
              <el-row>
                <el-col :span="20">
                  <el-row type="flex">
                    <!-- <el-col :span="16"> -->
                    <el-form-item label="발생일자" label-width="80px">
                      <el-col :span="11">
                        <el-form-item prop="startDate" ref="startDate">
                          <el-date-picker
                            type="datetime"
                            placeholder="검색 시작일"
                            v-model="formSearch.startDate"
                            style="width: 100%;"
                            @change="changeDate('start')"
                            format="dd/MM/yyyy HH:mm"
                          ></el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col :span="2" align="center">-</el-col>
                      <el-col :span="11">
                        <el-form-item prop="endDate" ref="endDate">
                          <el-date-picker
                            type="datetime"
                            placeholder="검색 종료일"
                            v-model="formSearch.endDate"
                            style="width: 100%;"
                            @change="changeDate()"
                            format="dd/MM/yyyy HH:mm"
                          ></el-date-picker>
                        </el-form-item>
                      </el-col>
                    </el-form-item>
                    <!-- </el-col>
                    <el-col :span="8"> -->
                    <el-form-item label="근무조" prop="workTime" label-width="80px">
                      <el-select v-model="formSearch.workTime" placeholder="근무조" :multiple="true" style="width: 100%;">
                        <el-option v-for="(item, key) in workTime" :key="key" :label="item.name" :value="item.menuId"></el-option>
                      </el-select>
                    </el-form-item>
                    <!-- </el-col> -->
                    <!-- <el-col :span="8"> -->
                    <el-form-item label="근무자" prop="writer" label-width="80px">
                      <el-input v-model="formSearch.worker"></el-input>
                    </el-form-item>
                    <!-- </el-col> -->
                    <!-- <el-col :span="16"> -->
                    <el-form-item label="관제유형" label-width="80px">
                      <el-col :span="12">
                        <el-form-item prop="controlType">
                          <el-select
                            v-model="formSearch.controlType"
                            placeholder="시설물 유형"
                            @change="changeControlType"
                            style="margin-right: 10px;"
                            clearable
                          >
                            <el-option v-for="(item, key) in controlType" :key="key" :label="item" :value="item"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                        <el-form-item prop="controlTypeSubClass" ref="controlTypeSubClass">
                          <el-select v-model="formSearch.controlTypeSubClass" placeholder="소분류" :multiple="true">
                            <el-option
                              v-for="(item, key) in controlTypeSubClass"
                              :key="key"
                              :label="item.sub"
                              :value="item.sub"
                              style="height: 100%;"
                            >
                              <p class="search-title">
                                <span>{{ item.sub }}</span>
                              </p>
                              <p class="search-content">
                                {{ item.desc }}
                              </p>
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>
                    </el-form-item>
                    <!-- </el-col> -->
                  </el-row>
                </el-col>
                <el-col :span="3" :offset="1" style="display: flex; justify-content: flex-end;">
                  <!-- <el-col :span="12"> -->
                  <el-button type="primary" @click="reSearch" style="width: 89px;">
                    <!-- style="margin-left: 10px; padding-bottom: 30px; padding-top: 30px; width: 90%;"  -->
                    검색
                  </el-button>
                  <!-- </el-col> -->
                  <!-- <el-col :span="12"> -->
                  <el-button @click="resetForm('form1')">
                    <!-- style="margin-left: 10px; padding-bottom: 30px; padding-top: 30px; width: 90%;" -->
                    검색초기화
                  </el-button>
                  <!-- </el-col> -->
                </el-col>
              </el-row>
              <!--검색 하단-->
              <el-row>
                <el-col :span="16">
                  <label class="control-log-totaldocs"
                    >전체 : <span class="highlight">{{ totalDocs }}건</span></label
                  >
                  <el-select v-model="limit" size="mini" style="width: 75px;" @change="changeLimit">
                    <el-option v-for="(item, index) in limitList" :key="index" :label="item" :value="item"></el-option>
                  </el-select>
                </el-col>
                <el-col :span="8" class="grid-header-menu" style="padding-right: 0; text-align: right;">
                  <el-button @click="openFormDialog()" type="primary" size="medium" style="margin-right: 5px;">
                    <i class="fas fa-plus-circle">&nbsp;추가</i>
                  </el-button>
                  <el-button size="medium" class="icon-button-2" @click="exportExcel">
                    <img src="../assets/img/excel_icon.png" />엑셀 출력
                  </el-button>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
          <!--테이블-->
          <el-col :span="24">
            <el-table
              :data="controlLogs"
              empty-text="표출할 관제일지가 없습니다"
              size="mini"
              @row-click="selectRow"
              stripe
              style="overflow-x: hidden;"
            >
              <el-table-column label="No" prop="seq" width="80" align="center"></el-table-column>
              <el-table-column label="발생일자" prop="occurDate1" width="130" align="center"></el-table-column>
              <el-table-column label="발생시간" prop="occurDate2" width="80" align="center"></el-table-column>
              <el-table-column label="근무조" prop="workTime" align="center" width="110"></el-table-column>
              <el-table-column label="근무자" prop="worker" align="center" width="110"></el-table-column>
              <el-table-column label="자원명칭" prop="fcltId" align="center" width="220" show-overflow-tooltip></el-table-column>
              <el-table-column label="관제유형1" prop="controlType" align="center" width="140"></el-table-column>
              <el-table-column label="관제유형2" prop="controlTypeSubClass" align="center" width="140"></el-table-column>
              <el-table-column label="관제사항" prop="content" header-align="center" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column label="첨부" header-align="center" width="150" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span class="a-tag-files" v-for="(item, index) in controlLogs[scope.$index].file" :key="index">
                    <a v-if="index === 0" :href="$config.getServerConfig().image.url + item.url"><i class="el-icon-document"></i> {{ item.name }}</a>
                  </span>
                  <span v-if="controlLogs[scope.$index].file.length > 1"> 외({{ controlLogs[scope.$index].file.length - 1 }})</span>
                  <!--
                  <div class="a-tag-files" v-for="(item, index) in controlLogs[scope.$index].file" :key="index">
                    <a v-if="index === 0" :href="item.url"><i class="el-icon-document"></i> {{item.name}}</a>
                    <span v-if="index === item"> 외 ({{index}})</span>
                  </div>
                  -->
                </template>
              </el-table-column>
              <el-table-column label width="170px">
                <template slot-scope="scope">
                  <div :class="scope.row.writerId === nowUserId || nowUserAuth === 0 ? '' : 'd-none'">
                    <el-button @click="openFormDialog(scope.row)" size="small">수정</el-button>
                    <el-button @click="remove(scope.row)" size="small" type="danger">삭제</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <dk-el-pagination :totalDocs="totalDocs" :limit="limit" :pagingProc="pagingProc" ref="pagination"></dk-el-pagination>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <form-control-log ref="formDialog" @successInsertControlLog="successInsertControlLog"></form-control-log>
    <form-control-log-detail
      :isDetailDialog="isDetailDialog"
      ref="formDialogDetail"
      :controlLog="selectControlLog"
      @openUpdateDialog="openUpdateDialog"
      @closeDetailDialog="closeDetailDialog"
    ></form-control-log-detail>
  </div>
</template>

<script>
import dkelpagination from '@/components/dkElPagination.vue';
import moment from 'moment';
import formControlLogDetail from './forms/formControlLogDetail';
import formControlLog from './forms/formControlLog';
import XLSX from 'xlsx-js-style';
import _ from 'lodash';

export default {
  components: {
    'dk-el-pagination': dkelpagination,
    'form-control-log-detail': formControlLogDetail,
    'form-control-log': formControlLog,
  },
  data() {
    return {
      controlLogs: [],
      limitList: [10, 20, 30, 50, 100],
      selectControlLog: {},
      isDetailDialog: false,
      formSearch: {
        startDate: '',
        endDate: '',
        worker: '',
        workTime: [],
        controlType: '',
        controlTypeSubClass: [],
      },
      nowUserId: '',
      nowUserAuth: '',
      searchData: {},
      limit: 10,
      totalDocs: 0,
      page: 1,
      workTime: [],
      controlType: ['사건사고관제', '일반관제'],
      controlTypeSubClass: [],
      headerData: [
        { key: 'seq', label: 'NO' },
        { key: 'worker', label: '근무자' },
        { key: 'workTime', label: '근무조' },
        { key: 'occurDate1', label: '발생날짜' },
        { key: 'occurDate2', label: '발생시간' },
        { key: 'fcltId', label: 'CCTV명' },
        { key: 'controlType', label: '관제유형' },
        { key: 'controlTypeSubClass', label: '관제유형 소분류' },
        { key: 'reportingFrom', label: '신고처' },
        { key: 'etcReportingAgency', label: '신고처 기타사항' },
        { key: 'isOrder', label: '지령여부' },
        { key: 'orderNum', label: '지령번호' },
        { key: 'content', label: '관제사항' },
        { key: 'isFile', label: '파일' },
      ],
    };
  },
  created() {
    this.getWorkTime();
    this.getControlLogs();
  },

  methods: {
    //getWorkTime() 근무조 가져오는 함수
    getWorkTime() {
      this.$http
        .post(`${this.$config.getServerConfig().core.api}/core/api/menus`, { search: { authLevel: 9 } })
        .then((response) => {
          this.workTime = response.data;
        })
        .catch(() => {
          this.$message.error(`화면 로딩 중 에러가 발생했습니다.`);
        });
    },
    //getControlLogs() 화면하단 테이블에 표시되는 관제일지 배열을 가져오는 함수. searchData의 경우
    //검색기능 클릭시 reSearch() 함수에서 실행된다.
    getControlLogs() {
      this.nowUserId = this.$store.getters['auth/getActiveUser'].userid;
      this.nowUserAuth = this.$dkRouter._menu[0].authLevel;
      this.$http
        .get(`${this.$config.getServerConfig().core.api}/core/api/controlLogs`, {
          params: { ...this.searchData, page: this.page, limit: this.limit },
        })
        .then((response) => {
          this.page = response.data.page;
          this.totalDocs = response.data.totalDocs;
          for (let index in response.data.docs) {
            //날짜 일, 시 분리 표현
            response.data.docs[index].occurDate1 = moment(response.data.docs[index].occurDate).format('YYYY-MM-DD');
            response.data.docs[index].occurDate2 = moment(response.data.docs[index].occurDate).format('HH:mm');
          }
          this.controlLogs = this.$lodash.cloneDeep(response.data.docs);
        })
        .catch(() => {
          this.$message.error(`화면 로딩 중 에러가 발생했습니다.`);
        });
    },
    //reSearch() 검색 클릭시 실행되는 함수
    reSearch() {
      this.page = 1;
      this.searchData = this.$lodash.cloneDeep(this.formSearch);
      this.getControlLogs();
      this.$refs.pagination.setPage(this.page);
    },
    //changeLimit() 한 화면에 표시되는 관제일지 수 가 바뀌었을 때 실행되는 함수
    changeLimit() {
      this.page = 1;
      this.getControlLogs();
      this.$refs.pagination.setPage(this.page);
    },
    //changeControlType(item) item : 선택된 값 , 관제 유형이 바뀌었을 때 실행되는 함수 관제 유형 변경시에 관제 소분류도 자동으로 바뀐다.
    changeControlType(item) {
      //this.formSearch.controlTypeSubClass = ""
      this.$refs.controlTypeSubClass.resetField();
      if (item === '사건사고관제') {
        this.controlTypeSubClass = [
          { sub: '강력범죄', desc: '살인, 강도, 강간, 폭력, 절도 등' },
          { sub: '경범죄', desc: '빈집침입, 쓰레기투기, 음주소란, 물건던지기 등 위험행위 등(경범죄 처벌법 제3조 참고)' },
          { sub: '청소년비위', desc: '청소년 선도(성폭력, 폭력, 실종, 청소년 비행 등)' },
          { sub: '재난/화재대응', desc: '재난‧재해, 방화, 화재‧폭발 등' },
          { sub: '교통사고 안전대응', desc: '교통사고, 도주차량 등' },
          { sub: '위험방지', desc: '위험한 사태가 발생한 경우에 행하는 조치' },
          { sub: '기타범죄', desc: '' },
        ];
      } else if (item === '일반관제') {
        this.controlTypeSubClass = [
          { sub: '아동', desc: '' },
          { sub: '여성', desc: '' },
          { sub: '노인', desc: '' },
          { sub: '기타', desc: '' },
        ];
      } else {
        this.controlTypeSubClass = [];
      }
    },
    //changeDate(dateType) dateType: start/end , 날짜 입력시 startDate가 endDate보다 뒤에 있지 않도록 점검한다. 또한 endDate에 23:59:59를 더한다.
    changeDate(dateType) {
      if ((dateType = 'end' && this.formSearch.endDate != '')) {
        this.formSearch.endDate = moment(this.formSearch.endDate).toDate();
      }
      if (this.formSearch.endDate != '' && this.formSearch.startDate != '' && this.formSearch.endDate < this.formSearch.startDate) {
        if (dateType == 'start') {
          this.$refs.startDate.resetField();
        } else {
          this.$refs.endDate.resetField();
        }
        this.$message({
          type: 'info',
          message: `날짜를 확인해주세요`,
        });
      }
    },
    //resetForm(formName) formName: 타겟form의 ref 값, 상단의 form의 초기화 함수
    resetForm(formName) {
      //this.formSearch = {}
      this.controlTypeSubClass = [];
      this.$refs[formName].resetFields();
      this.reSearch();
    },
    //pagingProc() 페이징 함수
    pagingProc() {
      this.page = this.$refs.pagination.getPage();
      this.getControlLogs();
    },
    //successInsertControlLog() 수정과 추가가 완료되었을 때 실행되는 함수 모든검색값을 초기화 시킨 후 재검색한다.
    successInsertControlLog() {
      this.resetForm('form1');
      this.reSearch();
    },
    //openFormDialog(row) row: 테이블 한 행의 정보 , 수정과 추가 Dialog창을 열 수 있는 함수 row가 있을 시에는 수정창이 없을 시 에는 추가 창이 열린다.
    openFormDialog(row) {
      if (row) {
        if (row.writerId != this.$store.getters['auth/getActiveUser'].userid && this.$store.getters['auth/getActiveUser'].group != 'manager') {
          this.$message({
            type: 'info',
            message: `작성자만 글을 수정할 수 있습니다.`,
          });
          return;
        }
      }
      this.$refs.formDialog.showDialog(row);
    },
    //selectRow() 테이블을 클릭 했을 시  상세보기 Dialog 창을 열 수 있는 함수
    selectRow(row, column) {
      if (column.label == '' || column.label == '첨부') {
        return;
      }
      this.selectControlLog = row;
      this.isDetailDialog = true;
    },
    //closeDetailDialog() 상세보기 Dialog 창을 닫는 함수
    closeDetailDialog() {
      this.isDetailDialog = false;
    },
    //openUpdateDialog() 상세보기 Dialog 창을 닫고 수정Dialog 창을 여는 함수, 상세 Dialog 창에서 수정을 눌렀을 시 실행
    openUpdateDialog() {
      this.closeDetailDialog();
      this.openFormDialog(this.selectControlLog);
    },
    //remove(args) args: 삭제 대상의 정보 ,  삭제 버튼 클릭시 작성자인지 확인 후 삭제 진행.
    remove(args) {
      if (args.writerId != this.$store.getters['auth/getActiveUser'].userid && this.$store.getters['auth/getActiveUser'].group != 'manager') {
        this.$message({
          type: 'info',
          message: `작성자만 글을 삭제할 수 있습니다.`,
        });
        return;
      }
      this.$confirm(`해당 게시글을 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(() => {
          this.$http
            .post(`${this.$config.getServerConfig().core.api}/core/api/controlLogs/delete`, { data: { _id: args._id } })
            .then(() => {
              this.$message({
                type: 'success',
                message: `게시글 삭제 완료`,
              });
              this.getControlLogs();
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: `게시글 삭제 중 에러 발생`,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `게시글 삭제 취소`,
          });
        });
    },
    //exportExcel()
    async exportExcel() {
      try {
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/controlLogs/excel`, {
          params: { ...this.searchData },
        });
        if (response.data.docs.length == 0) {
          this.$message({
            type: 'info',
            message: `표출할 데이터가 없습니다.`,
          });
          return;
        }
        for (let index in response.data.docs) {
          response.data.docs[index].occurDate1 = moment(response.data.docs[index].occurDate).format('YYYY-MM-DD');
          response.data.docs[index].occurDate2 = moment(response.data.docs[index].occurDate).format('hh:mm');
          response.data.docs[index].isFile = response.data.docs[index].file.length == 0 ? '없음' : `있음(${response.data.docs[index].file.length})`;
          response.data.docs[index].isOrder = response.data.docs[index].isOrder ? '있음' : '없음';
        }
        //
        let range = { s: { c: 0, r: 0 }, e: { c: this.headerData.length - 1, r: response.data.docs.length + 1 } };
        let header = [];
        let label = {};
        let data = [];
        //출력할 데이터만 따로 목록을 뽑기
        let excelheaderData = [
          //{ key: 'seq', label: 'NO' },
          { key: 'occurDate1', label: '발생날짜' },
          { key: 'occurDate2', label: '발생시간' },
          { key: 'worker', label: '근무자' },
          { key: 'workTime', label: '근무조' },
          { key: 'fcltId', label: 'CCTV명' },
          { key: 'controlType', label: '관제유형' },
          { key: 'controlTypeSubClass', label: '관제유형 소분류' },
          //{ key: 'reportingFrom', label: '신고처' },
          //{ key: 'etcReportingAgency', label: '신고처 기타사항' },
          //{ key: 'isOrder', label: '지령여부' },
          //{ key: 'orderNum', label: '지령번호' },
          { key: 'content', label: '관제사항' },
          //{ key: 'isFile', label: '파일' },
        ];

        //목록을 구성할 header와, 내용물을 결정지을 key의 역할을 할 lebel을 구성
        for (let i = 0; i < excelheaderData.length; i++) {
          header.push({
            key: i,
            name: excelheaderData[i].key,
          });
          label[excelheaderData[i].key] = excelheaderData[i].label;
        }
        data.push(label);

        //출력할 데이터만 골라서 exceldocs를 구성
        let exceldocs = [];
        for (let i = 0; i < response.data.docs.length; i++) {
          let instantobj = {};
          for (const [key, value] of Object.entries(response.data.docs[i])) {
            if (_.hasIn(data[0], key)) {
              Object.assign(instantobj, { [key] : value})
            }
          }
          exceldocs.push(instantobj);
        }

        // data(헤더-목록)와 exceldocs(내용물)을 합침
        let newData = this.$lodash.union(data, exceldocs);
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.json_to_sheet(newData, header);
        ws['!rows'] = [];
        ws['!rows'][0] = { hidden: true };
        //ws 배열의 객체들 안에 형식에 대한 프로퍼티를 각각 집어넣어주기
        for (const [key, value] of Object.entries(ws)) {
          await Object.assign(value, {
            "s": {
              alignment: { wrapText: true, vertical: "center", horizontal: "center" }, 
              font: { name: "Malgun Gothic", sz: "8" },
              border: { top: { style: "thin", color: "#000000" }, bottom: { style: "thin", color: "#000000" }, left: { style: "thin", color: "#000000" }, right: { style: "thin", color: "#000000" } }
            }
          })
        }
        
        ws['!ref'] = XLSX.utils.encode_range(range);
        //cell의 크기를 순서대로 설정해주기
        ws['!cols'] = [{ width:10 }, { width: 8 }, { width: 10 }, { width: 10 }, { width: 10 }, { width: 10 }, { width: 8 }, { width: 30 } ];
        debugger;
        XLSX.utils.book_append_sheet(wb, ws, '관제일지');
        XLSX.writeFile(wb, `${moment().format('YYYY-MM-DD')}_관제일지목록.xlsx`, { bookType: 'xlsx', type: 'array' });
      } catch (err) {
        this.$message.error(`엑셀다운로드 중 에러 발생`);
      }
    },
  },
};
</script>

<style scoped>
.search-title {
  display: block;
  font-size: 14px;
  line-height: 1;
  margin: 6px 0;
  margin-block-end: 1em;
  margin-block-start: 1em;
  margin-inline-end: 0;
  margin-inline-start: 0;
}

.search-content {
  color: #c0c4cc;
  display: block;
  font-size: 12px;
  line-height: 2.4;
  margin: 6px 0;
  margin-block-end: 1em;
  margin-block-start: 1em;
  margin-inline-end: 0;
  margin-inline-start: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.control-log-totaldocs {
  color: #606266;
  font-size: 12px;
  margin: 5px;
}

.control-log-totaldocs .highlight {
  color: #1d56caef;
}
</style>
