import { render, staticRenderFns } from "./pageReptCngsSect.vue?vue&type=template&id=1f0d71eb&scoped=true&"
import script from "./pageReptCngsSect.vue?vue&type=script&lang=js&"
export * from "./pageReptCngsSect.vue?vue&type=script&lang=js&"
import style0 from "./pageReptCngsSect.vue?vue&type=style&index=0&id=1f0d71eb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f0d71eb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/dk-platform-frontend/dk-platform-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f0d71eb')) {
      api.createRecord('1f0d71eb', component.options)
    } else {
      api.reload('1f0d71eb', component.options)
    }
    module.hot.accept("./pageReptCngsSect.vue?vue&type=template&id=1f0d71eb&scoped=true&", function () {
      api.rerender('1f0d71eb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/traffic/pageReptCngsSect.vue"
export default component.exports