var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "el-dialog-aside",
      attrs: {
        title: "VMS 기본메시지 다운로드",
        visible: _vm.isShowDialog,
        "append-to-body": "",
        closeOnClickModal: false,
        top: "5vh",
        width: "1100px"
      },
      on: {
        "update:visible": function($event) {
          _vm.isShowDialog = $event
        },
        close: _vm.closeModal
      }
    },
    [
      _c(
        "el-container",
        [
          _c(
            "el-row",
            {
              staticStyle: {
                width: "100%",
                "border-top": "1px solid rgb(222, 222, 222)"
              }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { height: "260px" },
                          attrs: { span: 24 }
                        },
                        [
                          _c("h4", [_vm._v("현재 기본 메시지")]),
                          _vm.currentDefaultMessage
                            ? _c("div", { staticClass: "vms-message-body" }, [
                                _c("img", {
                                  attrs: {
                                    src:
                                      _vm.currentDefaultMessage.vmsMessage
                                        .thumbImageFile
                                  }
                                }),
                                _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.currentDefaultMessage.vmsMessage
                                          .vmsMessageName
                                      ) +
                                      "\n              "
                                  )
                                ])
                              ])
                            : _c("div", { staticClass: "vms-message-body" }, [
                                _vm._v("현재 설정된 기본메시지가 없습니다.")
                              ])
                        ]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { height: "260px" },
                          attrs: { span: 24 }
                        },
                        [
                          _c("h4", [_vm._v("설정 예정 기본 메시지")]),
                          _vm.selectedVmsMessage
                            ? _c("div", { staticClass: "vms-message-body" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.selectedVmsMessage.thumbImageFile
                                  }
                                }),
                                _c("div", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.selectedVmsMessage.vmsMessageName
                                      ) +
                                      "\n              "
                                  )
                                ])
                              ])
                            : _c("div", { staticClass: "vms-message-body" }, [
                                _vm._v("설정할 메시지를 선택해주세요.")
                              ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "vms-message-wrap", attrs: { span: 12 } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: {
                        width: "100%",
                        "border-top": "1px solid #dedede"
                      },
                      attrs: {
                        data: _vm.vmsMessages,
                        "empty-text": "등록된 VMS 메시지가 없습니다.",
                        size: "mini",
                        stripe: "",
                        height: 550
                      },
                      on: { "row-click": _vm.selectVmsMessage }
                    },
                    [
                      _c("el-table-column", {
                        attrs: { "min-width": "150px" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                _c("img", {
                                  staticStyle: {
                                    height: "auto",
                                    width: "100%"
                                  },
                                  attrs: { src: scope.row.thumbImageFile }
                                })
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "vmsMessageName",
                          label: "VMS 메시지 이름",
                          "min-width": "80px"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { staticClass: "vms-button-wrap", attrs: { span: 24 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.clickDownload }
                    },
                    [_vm._v("기본메시지 다운로드")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }