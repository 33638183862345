<template>  
  <el-row class="ipes-manage-console-wrap">    
    <el-col :span="10" class="control-panel-wrap">

    </el-col>
    <el-col :span="14" class="video-preview-wrap" ref="previewWrap">      
      <div
        v-for="(item, idx) in previewCount"
        :key="idx"
        :style="previewStyle"
        class="video-preview"
      >

      </div>      
    </el-col>    
  </el-row>      
</template>

<script>
export default {
  computed: {
    previewCount() {
      return this.previewScale.col * this.previewScale.row
    },
    previewCols() {
      return 24 / this.previewScale.col;
    },
    previewStyle() {
      let width = (100 / this.previewScale.col).toFixed(5)
      let height = (100 / this.previewScale.row).toFixed(5)
      return {
        'height': `calc(${height}%)`,
        'width': `calc(${width}%)`
      }
    }
  },
  data() {
    return {
      previewScale : {
        col : 3,
        row : 5
      }
    }
  }
}
</script>

<style>
.ipes-manage-console-wrap {
  height:100%;
  position:relative;
}

.video-preview-wrap, .control-panel-wrap{
  height:100%;
  position:relative;
}

.video-preview {
  float:left;
  background-color:black;
  box-sizing: border-box;
  border: 1px solid #fff;
}
</style>