import moment from 'moment'
import axios from 'axios'

export default {
    initCodeData() {    
        let _this = this
        return new Promise((resolve, reject) => {     
            axios.post(`${_this.enf.api.detect}/code/getCodeData`,{
                "user" : {
                    "id" : "",
                    "token" : ""
                }
            })
            .then((response) => {
                console.log(response)
                if (response.data.result.code == 1) {
                    _this.codeDataList = response.data.codeList
                    
                    response.data.forEach((val, i)=>{
                        for(var key in _this.enf.code) {
                            if(_this.enf.code[key],codeId === val.codeId) {
                                _this.codeDataInfo[key] = val;
                                break;
                            }
                        }
                    })
                    return resolve()
                }
                else {
                    return reject(response.data.result.message)
                }                    
            })
            .catch((err)=> {
                return reject(err)
            })
        })        
        .catch((err) => {
            console.error(err)
        })
    },
    data() {
        return {
            codeDataInfo : {},
        }
    },
    getCodeValue(codeId, value) {
        let _this = this;
        if(!_this.codeDataInfo) {
            return false;
        }
        
        let data = _this.codeDataInfo[codeId];

        if(value) {
            for (var j=0; j<data.codeData.length; j++) {
                if (data.codeData[j].data == value) {
                    return data.codeData[j].dataName
                }                         
            }
        } else {
            return data;
        }
        return null;
    },
    getDateFormat(dateString, targetFormat) {
        return moment(dateString, "YYYYMMDDHHmmss").format(targetFormat)
    },
    getCameraModelInfo() {
        return this.cameraModelList
    }
}
