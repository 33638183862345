<template>
  <div id="formControlLogDetail">
    <el-dialog
      :visible.sync="isDetailDialog"
      :close-on-press-escape="false" 
      :close-on-click-modal="false"
      top="5vh"
      class="el-dialog-aside"
      :title="`[${controlLog.seq}]관제 일지 상세`"
      :show-close = "false"
    >
      <el-container class="dialog-container">
        <el-row style="padding: 30px 10px; width: 100%;">
          <el-col :span="24">
            <div>
              <table class="dk-table dk-table-no-padding">
                <colgroup>
                  <col style="width: 20%;" />
                  <col style="width: 30%;" />
                  <col style="width: 20%;" />
                  <col style="width: 30%;" />
                </colgroup>
                <tbody>
                  <tr>
                    <th scople="row">근무자</th>
                    <td>{{controlLog.worker}}</td>
                    <th scople="row">근무조</th>
                    <td>{{controlLog.workTime}}</td>
                  </tr>
                  <tr>
                    <th scople="row">발생일자</th>
                    <td>{{controlLog.occurDate1}}</td>
                    <th scople="row">발생시간</th>
                    <td>{{controlLog.occurDate2}}</td>
                  </tr>
                  <tr>
                    <th scople="row">CCTV명</th>
                    <td colspan="3">{{controlLog.fcltId}}</td>
                  </tr>
                  <tr>
                    <th scople="row">관제유형</th>
                    <td>{{controlLog.controlType}}</td>
                    <th scople="row">관제유형 소분류</th>
                    <td>{{controlLog.controlTypeSubClass}}</td>
                  </tr>
                  <tr>
                    <th scople="row">신고처</th>
                    <td colspan="3">
                      {{controlLog.reportingFrom}}
                      {{controlLog.etcReportingAgency != "" ? "["+controlLog.etcReportingAgency+"]" : ""}}
                    </td>
                  </tr>
                  <tr>
                    <th scople="row">지령여부</th>
                    <td>{{controlLog.isOrder ? "있음": "없음" }}</td>
                    <th scople="row">지령번호</th>
                    <td>{{controlLog.orderNum}}</td>
                  </tr>
                  <tr>
                    <th scople="row" height="100">관제사항</th>
                    <td colspan="3">{{controlLog.content}}</td>
                  </tr>
                  <tr>
                    <th scople="row">첨부파일</th>
                    <td colspan="3">
                      <div class="a-tag-files" v-for="(item, index) in controlLog.file" :key="index">
                        <a :href="item.url"><i class="el-icon-document"></i> {{item.name}}</a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </el-col>
        </el-row>
      </el-container>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openUpdateDialog">수정</el-button>
        <el-button @click="hideDialog">닫기</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default ({
  data(){
    return{
      
    }
  },
  props : {
    isDetailDialog : {
      type: Boolean,
      default: false
    }
    ,
    controlLog : {
      type: Object,
      default: {}
    }
  },
  methods:{
    hideDialog(){
      this.$emit("closeDetailDialog")
    },
    openUpdateDialog(){
      this.$emit("openUpdateDialog")
    }
  }
})
</script>
<style>
  .a-tag-files a{
    text-decoration: none;
  }
  .a-tag-files a:link:hover{
    color:#003cff;
  } 
  .a-tag-files a:link {
    color:#606266;
  }
  .a-tag-files a:visited {
    color:#606266;
  }
</style>