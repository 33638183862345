<template>
  <div :class="'abs-full ' + (navOnOff ? 'navOn' : 'navOff')" style="z-index: 999 !important;">
    <div :class="'side-menu-container ' + (navOnOff ? 'd-block' : 'd-none')" @click="navOff" />
    <div :class="'side-menu-box ' + (navOnOff ? 'navOn' : 'navOff')">
      <div class="login-area mb-both-pd">
        <div class="profile-img">
          <img v-if="userImg" :src="userImg" />
          <img v-else src="static/images/unknown.png" />
          <!-- <span @click="backAction">
            <i class="icon-arrow" />
          </span> -->
        </div>
        <div class="login-text-box flex-box">
          <div class="login-user">
            <span>{{ userName }}</span>
            <i class="icon-pencle" @click="UserInfoModify"></i>
            <div>{{ `${userId}(${groupName})` }}</div>
          </div>
          <div class="logout-mb" @click="logout">
            <i class="icon-exit"></i>
          </div>
        </div>
      </div>

      <div class="event-one-btn-container">
        <div class="one-btn flex-center btn-style" @click="onClickHome">
          <i class="icon-empty-home"></i>
          <span>홈</span>
        </div>
        <div class="one-btn flex-center btn-style" @click="maintnce">
          <i class="icon-megaphone"></i>
          <span>상황전파</span>
        </div>
        <div class="one-btn flex-center btn-style" @click="Setting">
          <i class="icon-empty-setting"></i>
          <span>환경설정</span>
        </div>
      </div>
      <div class="menu-list">
        <ul>
          <li class="menu-depth1-container" v-for="depth1 in navList" :key="depth1.key">
            <div class="menu-depth1 btn-style" v-if="depth1.navUlList" @click="depth1Click($event, depth1)">
              <i :class="depth1.icon"></i>
              <span>{{ depth1.label }}</span>
              <div class="float-right"><i :class="depth1.arrow"></i></div>
            </div>
            <div class="menu-depth1 btn-style" v-else @click="depth1ClickMove($event, depth1)">
              <i :class="depth1.icon"></i>
              <span>{{ depth1.label }}</span>
              <div class="float-right"><i :class="depth1.arrow"></i></div>
            </div>
            <ul class="depth2-ul">
              <li v-for="depth2 in depth1.navUlList" :key="depth2.key" @click="onClickMenu(depth2)">{{ depth2.label }}</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <form-User-info-modify ref="formUserInfoModify"></form-User-info-modify>
    <form-setting ref="formSetting"></form-setting>
    <!-- <form-spread-situation v-if="formSpreadSituationFlag" ref="formSpreadSituation"></form-spread-situation> -->
  </div>
</template>

<script>
import formUserInfoModify from '../forms/formUserInfoModify.vue';
import formSetting from '../forms/formSetting.vue';
// import formSpreadSituation from '../forms/formSpreadSituation';

export default {
  components: {
    'form-User-info-modify': formUserInfoModify,
    'form-setting': formSetting,
    // 'form-spread-situation': formSpreadSituation,
  },
  data: () => ({
    navOnOff: false,
    navList: [
      {
        key: 'contents',
        icon: 'icon-paper-one',
        label: '메인 컨텐츠',
        arrow: 'icon-arrow',
        navUlList: [
          {
            key: 'fcltGis',
            label: '- 생활 방범 시스템',
            path: '/cctvGis',
          },
          {
            key: 'fcltGis',
            label: '- 시설물 시스템',
            path: '/fcltGis',
          },
          {
            key: 'diseaseInfo',
            label: '- 전염병 데이터 서비스',
            path: '/diseaseInfo',
          },
          {
            key: 'water',
            label: '- 폭우/침수 대응 서비스',
            path: '/flooding',
          },
          {
            key: 'disaster',
            label: '- 정부 재난 데이터 서비스',
            path: '/disasterInfo',
          },
          {
            key: 'maintenance',
            label: '- 유지 관리 내역 현황',
            path: '/maintenance',
          },
        ],
      },
      {
        key: 'phoneDirectory',
        icon: 'icon-paper-one',
        label: '전화번호부',
        arrow: '',
        path: '/telephonePaper',
      },
      {
        key: 'notice',
        icon: 'icon-paper-one',
        label: '공지사항',
        arrow: '',
        path: '/Notice',
      },
    ],
    selectFcltMarkerData: {
      fcltId: 'VMS2000',
      fcltName: '',
      fcltTypeName: '',
      fcltTypeId: '',
      regionId: '',
      regionName: '',
      setDate: '',
      setNumberAddr: '',
      setRoadAddr: '',
      gisLat: '',
      gisLng: '',
      equip: [],
      fcltTypeData: {},
      images: [],
      files: [],
    },
    // formSpreadSituationFlag: false,
    userId: '',
    userName: '',
    groupName: '',
    userImg: '',
  }),
  created() {
    const me = this;
    // this.$on('closeFormSpreadSituation', () => {
    //   me.formSpreadSituationFlag = false;
    // });
    const userInfo = this.$dkRouter.getUserInfo();
    const { userid, name, groupName, images } = userInfo;
    this.userId = userid;
    this.userName = name;
    this.groupName = groupName;

    if (Array.isArray(images) && images.length > 0) {
      const { url } = images[0];
      this.userImg = url;
    }
  },
  methods: {
    checkBackAction() {
      try {
        let result = false;
        // if (this.formSpreadSituationFlag && this.$refs.formSpreadSituation.isOpenDialog()) {
        //   this.$refs.formSpreadSituation.hideDialog();
        //   result = true;
        // } else
        if (this.$refs.formUserInfoModify.isOpenDialog()) {
          this.$refs.formUserInfoModify.hideDialog();
          result = true;
        } else if (this.$refs.formSetting.isOpenDialog()) {
          this.$refs.formSetting.hideDialog();
          result = true;
        } else if (this.navOnOff) {
          this.navOff();
          result = true;
        }
        return result;
      } catch (err) {
        return false;
      }
    },
    navOn() {
      this.navOnOff = true;
      this.requestUserid();
    },
    navOff() {
      this.navOnOff = false;
    },
    getNavOnOff() {
      return this.navOnOff;
    },
    depth1Click(event, depth1) {
      if (
        !event.currentTarget.parentElement.children[1].style.height ||
        event.currentTarget.parentElement.children[1].style.height === 0 ||
        event.currentTarget.parentElement.children[1].style.height === '0' ||
        event.currentTarget.parentElement.children[1].style.height === '0px'
      ) {
        event.currentTarget.parentElement.children[1].style.height = event.currentTarget.parentElement.children[1].scrollHeight + 'px';
      } else {
        event.currentTarget.parentElement.children[1].style.height = '0px';
      }
    },
    depth1ClickMove(event, depth1) {
      this.$router.push(depth1.path);
    },
    onClickMenu(depth2) {
      this.$router.push(depth2.path);
    },
    onClickHome() {
      this.navOff();
      // this.$router.push(this.$config.getServerConfig().baseUrl);
      this.$router.push('/');
    },
    UserInfoModify() {
      this.navOff();
      this.$refs.formUserInfoModify.showDialog();
    },
    Setting() {
      this.navOff();
      this.$refs.formSetting.showDialogOnGis();
    },
    maintnce() {
      this.navOff();
      // this.$router.push('/spreadSituation');
      // this.formSpreadSituationFlag = true;
      // this.$refs.formSpreadSituation.showDialog(this.selectFcltMarkerData);
      this.$router.push('/spreadSituation');
    },

    logout() {
      let check = confirm('시스템에서 로그아웃 합니다');
      if (check) {
        this.$store.dispatch('auth/actRefreshToken', { refresh_payload: '' });
        this.$store.dispatch('auth/actAccessToken', { access_payload: '' });
        window.location.href = this.$config.getServerConfig().baseUrl;
      }
    },

    backAction() {
      try {
        this.$router.go(-1);
      } catch (err) {
        this.$message('뒤로가기 에러');
      }
    },

    async requestUserid() {
      const me = this;
      try {
        const response = await me.$http.get(`${this.$config.getServerConfig().core.api}/core/api/user`, {
          params: {
            userid: me.userId,
          },
        });
        if (response.status !== 200 || response.data.docs.length === 0) {
          throw new Error('requestUserid faile');
        }

        const { images = [] } = response.data.docs[0];
        const { url } = images[0] || {};
        this.userImg = url;
      } catch (err) {
        // me.$message.error('requestUserid faile');
      }
    },
  },
};
</script>
<style scoped>
.icon-pencle::before {
  color: #a0b8d3 !important;
}
</style>
