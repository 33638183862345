<template>
  <div class="fill-width-height el-container">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <el-row>
              <el-col :md="12" :sm="12" :xs="24" class="grid-header-menu">
                <template>
                  <el-button type="primary" @click="openFormFcltDialog(null)" size="medium" style="margin-right: 5px;">
                    <i class="fas fa-plus-circle">&nbsp;추가</i>
                  </el-button>
                </template>
                <!-- <el-button
                                    size="medium"
                                    class="icon-button"
                                >
                                    <i class="fas fa-file-pdf"></i>
                </el-button>-->
                <el-button size="medium" class="icon-button-2" @click="showFcltExportExcel" style="margin-right: 5px;">
                  <img src="../assets/img/excel_icon.png" />엑셀 출력
                </el-button>
                <template>
                  <el-button size="medium" class="icon-button-2" style="margin-right: 5px;" @click="showFcltImportExcel">
                    <img src="../assets/img/excel_icon.png" />엑셀 입력
                  </el-button>
                  <el-button size="medium" @click="showFcltAddressGenerate" style="margin-right: 5px;">
                    <i class="fas fa-map-marker-alt"></i>
                    &nbsp;주소 일괄 갱신
                  </el-button>
                  <el-button size="medium" @click="stateGenerate" style="margin-right: 5px;">
                    <i class="fas fa-map-marker-alt"></i>
                    &nbsp;시/구/군 정보 갱신
                  </el-button>
                  <el-button size="medium" @click="openLinkedData">
                    <!-- <i class="fas fa-map-marker-alt"></i> -->
                    <i class="el-icon-setting"></i>
                    &nbsp;연계시설물 설정
                  </el-button>
                </template>
              </el-col>
              <el-col :md="12" :sm="12" :xs="24" class="grid-header-menu" style="text-align: right;">
                <div style="margin: 5px;">
                  <el-select v-model="searchFcltTypeId" placeholder="시설물 유형" :multiple="true">
                    <el-option v-for="item in fcltType" :key="item.fcltTypeId" :label="item.fcltTypeName" :value="item.fcltTypeId"></el-option>
                  </el-select>
                </div>
                <div>
                  <el-input v-model="keyword" placeholder="검색" style="width: 200px;" size="medium" @keyup.enter.native="searchFclt"></el-input>
                  <el-button size="medium" class="icon-button" @click="searchFclt">
                    <i class="fas fa-search"></i>
                  </el-button>
                </div>
              </el-col>
              <el-col :span="24">
                <el-table :data="fcltData" style="width: 100%;" empty-text="시설물 정보가 없습니다." size="mini" @row-click="selectRow" stripe>
                  <el-table-column prop="fcltName" label="시설물 명칭" fixed="left"></el-table-column>
                  <el-table-column prop="fcltTypeName" label="시설물 유형"></el-table-column>
                  <el-table-column prop="setNumberAddr" label="설치 주소"></el-table-column>
                  <el-table-column label width="160">
                    <template slot-scope="scope">
                      <el-button @click="openFormFcltDialog(scope.row)" size="mini">수정</el-button>
                      <el-button @click="removeFclt(scope.row)" size="mini" type="danger">삭제</el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <dk-el-pagination :totalDocs="totalDocs" :limit="limit" :pagingProc="pagingProc" ref="pagination"></dk-el-pagination>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-main>
    </el-container>

    <dk-info-panel ref="infoPanel" :afterEnter="shownInfoPanel" width="460px">
      <div class="info-panel-wrap">
        <el-tabs v-model="activeFcltTab" @tab-click="clickFcltTab">
          <el-tab-pane v-if="isFcltPlugin" :label="fcltPluginLabel" name="tab0">
            <component :is="selectFcltPlugin" :fcltData="selectFcltData" ref="fcltPlugin"></component>
          </el-tab-pane>
          <el-tab-pane label="시설물 정보" name="tab1">
            <div style="height: 230px;">
              <dk-gis :gisApiInfo="gisApiInfo" :markerData="markerData" :clickMarker="selectFclt" ref="dkGis"></dk-gis>
            </div>
            <div class="dk-card" style="margin-bottom: 8px; margin-top: 6px;">
              <table class="el-table el-table--mini dk-el-table-collapse">
                <tr>
                  <th style="width: 145px;">시설물 명칭</th>
                  <td>{{ selectFcltData.fcltName }}</td>
                </tr>
                <tr>
                  <th>시설물 유형</th>
                  <td>{{ selectFcltData.fcltTypeName }}</td>
                </tr>
                <tr>
                  <th>도로명 주소</th>
                  <td>{{ selectFcltData.setRoadAddr }}</td>
                </tr>
                <tr>
                  <th>지번 주소</th>
                  <td>{{ selectFcltData.setNumberAddr }}</td>
                </tr>
                <tr>
                  <th>경도</th>
                  <td>{{ selectFcltData.gisLng }}</td>
                </tr>
                <tr>
                  <th>위도</th>
                  <td>{{ selectFcltData.gisLat }}</td>
                </tr>
                <tr v-for="(ftData, index) in selectFcltData.fcltTypeData" :key="index">
                  <th>{{ ftData.label }}</th>
                  <td>{{ getFcltDataValue(ftData) }}</td>
                </tr>
              </table>
            </div>
            <el-row>
              <el-col :span="6" v-for="(file, index) in selectFcltData.images" :key="index" v-if="isFcltImage(file)" class="fclt-image">
                <img @click="showFcltImage(index)" :src="$config.getServerConfig().image.url + file.serverFilePath" />
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="관련 자료" name="tab4">
            <div class="dk-card">
              <el-table
                :data="selectFcltData.files"
                empty-text="등록된 시설물 관련 자료가 없습니다."
                size="mini"
                stripe
                @row-click="downloadReference"
              >
                <el-table-column label="파일 명">
                  <template slot-scope="scope">
                    <span class="file-download-label">{{ scope.row.name }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="파일 크기" width="120px">
                  <template slot-scope="scope">{{ getHumanFileSize(scope.row.size) }}</template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </dk-info-panel>

    <form-fclt-info ref="formFcltDialog" :gisApiInfo="gisApiInfo" :geometry="geometry"></form-fclt-info>

    <el-dialog title="시설물 사진" :visible.sync="fcltImageDialog">
      <el-carousel
        v-show="selectFcltData.images && selectFcltData.images.length > 0"
        height="400px"
        ref="fcltImageCarousel"
        :initial-index="activeFcltImageIndex"
      >
        <el-carousel-item v-for="(file, index) in selectFcltData.images" :key="index">
          <div class="dk-carousel-item">
            <img :src="$config.getServerConfig().image.url + file.serverFilePath" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
    <!-- <form-fclt-excel
    ref="formFcltExcel">
    </form-fclt-excel>-->
    <form-fclt-export-excel ref="formFcltExportExcel"></form-fclt-export-excel>
    <form-fclt-import-excel ref="formFcltImportExcel"></form-fclt-import-excel>
    <form-fclt-address-generate ref="formFcltAddressGenerate" @procDataComplete="procDataComplete"></form-fclt-address-generate>
    <form-linked-data ref="formLinkedData" />
  </div>
</template>

<script>
import dkgismain from '@/components/dkGis/dkGisMain.vue';
import dkinfopanel from '@/components/dkInfoPanel.vue';
import formFcltInfo from '@/core/forms/formFcltInfo.vue';
import DkFileUtil from '@/components/dkFileUtil.js';
import dkelpagination from '@/components/dkElPagination.vue';
// import formFcltExcel from '@/core/forms/formExcelInfo.vue'
import formFcltExportExcel from '@/core/forms/formFcltExportExcel.vue';
import formFcltImportExcel from '@/core/forms/formFcltImportExcel.vue';
import formFcltAddressGenerate from '@/core/forms/formFcltAddressGenerate.vue';
import formLinkedData from './forms/formLinkedData.vue';

export default {
  name: 'pageFcltInfo',
  components: {
    'dk-gis': dkgismain,
    'form-fclt-info': formFcltInfo,
    'dk-info-panel': dkinfopanel,
    'dk-el-pagination': dkelpagination,
    // 'form-fclt-excel':formFcltExcel,
    'form-fclt-export-excel': formFcltExportExcel,
    'form-fclt-import-excel': formFcltImportExcel,
    'form-fclt-address-generate': formFcltAddressGenerate,
    'form-linked-data': formLinkedData,
  },
  data() {
    return {
      limit: 10,
      totalDocs: 0,
      gisApiInfo: [],
      defaultGisApiInfo: null,
      markerData: [],
      fcltData: [],
      geometry: null,
      infoPanelFlag: false,
      selectFcltData: {},
      fcltPluginLabel: '',
      activeFcltTab: 'tab1',
      fcltImageDialog: false,
      activeFcltImageIndex: 0,
      keyword: '',
      searchFcltTypeId: '',
      fcltType: [],
      loading: null,
      editAbleFlag: true,
      systemLogInfo: {},
    };
  },
  created() {
    let me = this;
    //this.$store.dispatch('gis/actGisApi', {}).then(() => {
    me.gisApiInfo = me.$store.getters['gis/getGisApi'];
    for (var i = 0; i < me.gisApiInfo.length; i++) {
      if (me.gisApiInfo[i].initGis) {
        me.defaultGisApiInfo = me.gisApiInfo[i];
        break;
      }
    }
    //});
    this.getFclts(1);
    this.$on('procDataComplete', (fcltData) => {
      this.getFclts();
    });

    this.$store.dispatch('fcltType/getFcltType', {}).then(() => {
      me.fcltType = me.$store.getters['fcltType/getFcltType'];
    });

    const { options = [] } = this.$config.getSiteConfig();
    const ck = options.findIndex((o) => o === 'fclt-read-only');
    if (ck !== -1) {
    }
  },
  computed: {
    isFcltPlugin() {
      if (this.selectFcltData.fcltTypeId) {
        let plugin = this.$dkPlugin.getPlugin(this.selectFcltData.fcltTypeId);

        if (plugin) {
          plugin.then((val) => {
            this.fcltPluginLabel = val.default.label;
          });
          this.activeFcltTab = 'tab0';
          return true;
        } else {
          this.activeFcltTab = 'tab1';
          return false;
        }
      } else {
        return false;
      }
    },
    selectFcltPlugin() {
      if (this.selectFcltData.fcltTypeId) {
        return () => {
          return this.$dkPlugin.getPlugin(this.selectFcltData.fcltTypeId);
        };
      } else {
        return false;
      }
    },
  },
  methods: {
    async getFclts(pageNum) {
      let me = this;
      let param = {
        page: pageNum || this.$refs.pagination.getPage(),
        limit: this.limit,
      };
      if (this.keyword !== '' && this.keyword) {
        param.keyword = this.keyword;
      }
      if (this.searchFcltTypeId !== '' && this.searchFcltTypeId) {
        // if(this.searchFcltTypeId == "FCLTTYPE10002"){
        //     param.keyword = "김포 CCTV"
        // }else if(this.searchFcltTypeId == "FCLTTYPE10001"){
        //     param.keyword = "도로 전광판"
        // }
        param.fcltTypeId = this.searchFcltTypeId;
      }
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fclt`, {
        params: param,
      });
      me.fcltData = response.data.docs;
      me.totalDocs = response.data.totalDocs;
      me.$refs.infoPanel.close();
      // this.$store.dispatch('fclt/getFclt', param).then(() => {
      //   me.fcltData = me.$store.getters['fclt/getFclt'];
      //   me.totalDocs = me.$store.getters['fclt/getPagination'].totalDocs;
      //   me.$refs.infoPanel.close();
      // });
    },
    selectFclt(args) {},
    queryForKeywords: function (event) {
      this.$nextTick(() => {
        console.log(event);
      });
    },
    searchFclt(event) {
      this.getFclts();    
    },
    pagingProc(pageNum) {
      this.getFclts();
    },
    openFormFcltDialog(args) {
      var me = this;
      //this.formDialog = true;
      let geometry = this.$lodash.cloneDeep(this.defaultGisApiInfo.geometry);
      let updateFlag = false;
      if (args) {
        geometry = {
          lat: args.gisLat,
          lng: args.gisLng,
        };
        updateFlag = true;
      } else {
        let licenseInfo = this.$store.getters['license/getLicense'];
        if (licenseInfo.currentFcltCount >= licenseInfo.limitFcltCount) {
          this.$alert(
            `등록된 ${licenseInfo.currentFcltCount}건의 시설물 정보가 있고, 현재 라이선스는 총 ${licenseInfo.limitFcltCount}건의 시설물이 등록 가능하므로, 유효한 시설물 등록 수를 초과합니다.`,
            '라이선스 등록 초과',
            {
              confirmButtonText: '확인',
              type: 'warning',
            }
          );
          this.$store.dispatch('license/setDialog', true);
          return;
        }
      }
      this.geometry = geometry;
      me.$refs.formFcltDialog.showDialog(updateFlag);
      setTimeout(function () {
        if (args) {
          me.$refs.formFcltDialog.setFcltDataDoc(args);
        }
      }, 100);
    },
    removeFclt(args) {
      this.$confirm(`${args.fcltName} 시설물을 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/fclt/delete`, {
              data: args,
            });
            this.systemLogInfo.page = `${this.$route.name} ${args.fcltName}`;
            this.getFclts();
            if (response.data.currentFcltCount) this.$store.dispatch('license/setCurrentFcltCount', response.data.currentFcltCount);

            this.$message({
              type: 'success',
              message: `${args.fcltName} 시설물 삭제 완료`,
            });
            this.systemLogInfo.saveType = 'delete';
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
          } catch (err) {
            this.$message({
              type: 'info',
              message: `${args.fcltName} 시설물 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${args.fcltName} 시설물 삭제 취소`,
          });
        });
    },
    selectRow(row) {
      //
      if (this.isFcltPlugin) {
        this.activeFcltTab = 'tab0';
      } else {
        this.activeFcltTab = 'tab1';
      }
      if (!this.$refs.infoPanel.isOpened()) {
        this.$refs.infoPanel.open();
      }
      this.selectFcltData = row;
      this.markerData = [
        {
          seq: 0,
          draggable: false,
          geometry: {
            lat: row.gisLat,
            lng: row.gisLng,
          },
          properties: {},
        },
      ];
      this.geometry = {
        lat: row.gisLat,
        lng: row.gisLng,
      };
      this.$refs.dkGis.refresh();
      this.$refs.dkGis.setCenter(this.geometry);
    },
    getFcltDataValue(fcltData) {
      let val = fcltData.value;
      if (fcltData.formType == 'combobox') {
        if (fcltData.options) {
          let tempOption = fcltData.options.find((opt) => {
            return opt.value == fcltData.value;
          });
          if (tempOption) {
            val = tempOption.label;
          }
        }
      }
      return val;
    },
    isFcltImage(file) {
      return DkFileUtil.isImage(file.name);
    },
    showFcltImage(index) {
      this.fcltImageDialog = true;
      this.activeFcltImageIndex = index;
      if (this.$refs.fcltImageCarousel) this.$refs.fcltImageCarousel.setActiveItem(index);
    },
    getHumanFileSize(bytes) {
      return DkFileUtil.getHumanFileSize(bytes, true);
    },
    downloadReference(row) {
      DkFileUtil.downloadFromUrl(row.url, row.name);
    },
    shownInfoPanel() {
      this.$refs.dkGis.refresh();
      if (this.geometry) {
        this.$refs.dkGis.setCenter(this.geometry);
      }
    },
    clickFcltTab(tab) {
      if (tab.name == 'tab1') {
        this.$nextTick(() => {
          this.$refs.dkGis.refresh();
          this.$refs.dkGis.setCenter(this.geometry);
        });
      }
    },
    showExcelDialog() {
      this.$refs.formFcltExcel.showDialog();
    },
    showFcltExportExcel() {
      this.$refs.formFcltExportExcel.showDialog();
    },
    showFcltImportExcel() {
      this.$refs.formFcltImportExcel.showDialog();
    },
    showFcltAddressGenerate() {
      this.$refs.formFcltAddressGenerate.showDialog();
    },
    procDataComplete() {
      this.getFclts();
    },
    stateGenerate() {
      this.$confirm(`시/구/군 클러스터 정렬을 위해 시설물 정보를 일괄 갱신합니다. 이 작업은 오래걸릴 수 있습니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          this.loading = this.$loading({
            lock: true,
            text: '시/구/군 클러스터를 정렬 중입니다.',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/fclt/stateGenerate`, {});
          this.loading.close();
          this.$message({
            type: 'success',
            message: `시/구/군 정보 갱신 완료`,
          });
        })
        .catch(() => {
          this.loading.close();
          this.$message({
            type: 'info',
            message: `시/구/군 정보 갱신 취소`,
          });
        });
    },

    openLinkedData() {
      this.$refs.formLinkedData.showDialog();
    },
  },
};
</script>

<style scoped>
.fclt-image {
  cursor: pointer;
  padding: 0 2px 4px 2px;
}

.fclt-image:hover > img {
  opacity: 0.3;
}

.fclt-image > img {
  height: 70px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  width: 100%;
}

.file-download-label {
  cursor: pointer;
}

.file-download-label:hover {
  color: #4d627b;
}
</style>
