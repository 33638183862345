<template>
  <div>
    <line-chart :chart-data="datacollection" :height="height" :options="chartDarkOption"></line-chart>
  </div>
</template>

<script>
import lineChart from '@/components/chart/lineChart.js';

export default {
  components: {
    lineChart,
  },
  props: {
    height: {
      type: Number,
      default: 340,
    },
  },
  created() {
    this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/statistics/weeklyMaintnceLog`, {}).then((result) => {
      let tmpData = result.data;
      for (var i = 0; i < tmpData.datasets.length; i++) {
        tmpData.datasets[i].backgroundColor = this.chartColors[i];
        tmpData.datasets[i].borderColor = this.chartColors[i];
        tmpData.datasets[i].fill = false;
        tmpData.datasets[i].borderWidth = 5;
      }
      this.datacollection = tmpData;
    });
  },
  data() {
    return {
      chartColors: [
        '#1e88e5',
        '#7559ff',
        '#26c6da',
        '#E91E63',
        '#ebeef3',
        '#2196F3',
        '#00BCD4',
        '#4CAF50',
        '#8BC34A',
        '#FF9800',
        '#795548',
        '#9E9E9E',
        '#607D8B',
        '#D32F2F',
        '#616161',
        '#E64A19',
      ],
      datacollection: null,
      chartDarkOption: {
        responsive: true,
        maintainAspectRatio: false,
        fill: false,
        elements: {
          line: {
            tension: 0, // disables bezier curves
          },
        },
        legend: {
          labels: {
            fontColor: '#2a2a2a',
            fontSize: 12,
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: '#303030',
                fontSize: 12,
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontColor: '#303030',
                fontSize: 12,
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  },
};
</script>

<style></style>
