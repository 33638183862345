<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :closeOnClickModal="false"
    :closeOnPressEscape="false"
    class="el-dialog-aside vms-editor"
    width="1000px"
    top="5vh"
  >
    <el-container class="dialog-container">
      <el-container>
        <el-form
          :model="formData"
          label-width="120px"
          size="medium"
          ref="form0"
          :label-position="$isMobile ? 'top' : 'right'"
        >
          <el-row>
            <el-col :span="24">
              <el-main style="background-color: #acacac;">
                <vms-message-editor
                  :vmsMessageSize="formData.vmsMessageSize"
                  type="figure"
                  ref="vmsMessageEditor"
                ></vms-message-editor>
              </el-main>
            </el-col>
          </el-row>
        </el-form>
      </el-container>
      <el-aside width="360px">
        <div class="info-panel" style="background-color: #fff;">
          <el-tabs v-model="activeTab">
            <el-tab-pane label=" 라이브러리 " name="tab0">
              <ul class="vms-library">
                <li v-for="(item, index) in vmsLibraries" :key="index">
                  <img
                    :src="getThumbImg(item)"
                    @dblclick="addImageObject(item)"
                    class="vmsLibrary-thumb-img"
                  />
                </li>
              </ul>
            </el-tab-pane>
            <el-tab-pane label="VMS 메시지" name="tab1">
              <el-table
                :data="vmsMessages"
                style="width: 100%; border-top: 1px solid #dedede;"
                empty-text="등록된 VMS 메시지가 없습니다."
                size="mini"
                height="600"
                stripe
              >
                <el-table-column label min-width="80px">
                  <template slot-scope="scope">
                    <img :src="scope.row.thumbImageFile" style="height: 80px; width: auto;" />
                  </template>
                </el-table-column>
                <el-table-column
                  label="VMS 메시지 이름"
                  prop="vmsMessageName"
                  header-align="center"
                  align="left"
                ></el-table-column>
                <el-table-column label width="80px">
                  <template slot-scope="scope">
                    <el-button @click="applyTemplete(scope.row)" size="mini" type="primary">복사</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <!-- <ul>
                          <li v-for="(item, idx) in vmsMessages"
                            :key="idx"
                          >
                            {{item.vmsMessageName}}
                          </li>
                        </ul>
              -->
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-aside>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="openSaveDialog">저장</el-button>
      <el-button @click="hideDialog">취소</el-button>
    </span>
    <el-dialog
      :close-on-click-modal="false"
      append-to-body
      title="임시 메시지 등록"
      :visible.sync="formSaveDialog"
      top="5vh"
    >
      <el-row>
        <el-col :span="14">
          <ul class="schedule-preview">
            <li v-for="(item, idx) in currentSchedule.vmsMessages" :key="idx">
              <img :src="item.thumbImageFile" />
            </li>
          </ul>
        </el-col>
        <el-col :span="10">
          <div>
            <el-button @click="saveCurrentSchedule">현재 스케줄에 추가</el-button>
          </div>
          <div>
            <el-button @click="saveInstantSchedule">임시 메시지만 표출</el-button>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </el-dialog>
</template>

<script>
import vmsMessageEditor from './vmsMessageEditor.vue';
import moment from 'moment';

export default {
  components: {
    'vms-message-editor': vmsMessageEditor,
  },
  data() {
    return {
      formTitle: '임시 메시지 등록',
      formData: {
        vmsMessageSize: '15x20',
      },
      formDialog: false,
      formSaveDialog: false,
      vmsMessages: [],
      vmsLibraries: [],
      activeTab: 'tab0',
      vmsScheduleData: null,
      vmsType: '2',
      currentSchedule: null,
    };
  },
  methods: {
    async getVmsMessages(vms) {
      let me = this;
      let param = {
        vmsType: this.vmsType,
        vmsMessageSize: vms.vmsSize,
      };
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsMessage`, {
        params: param,
      });
      me.vmsMessages = response.data.docs;
      // me.totalDocs = response.data.totalDocs;
      // this.$store.dispatch('vmsMessage/getVmsMessage', param).then(() => {
      //   me.vmsMessages = me.$store.getters['vmsMessage/getVmsMessage'];
      // });
    },
    async getVmsLibraries() {
      let me = this;
      let param = {};

      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsLibrary`, {
        params: param,
      });
      me.vmsLibraries = response.data.docs;

      // this.$store.dispatch('vmsLibrary/getVmsLibrary', param).then(() => {
      //   me.vmsLibraries = me.$store.getters['vmsLibrary/getVmsLibrary'];
      // });
    },
    showDialog(row) {
      this.getVmsMessages(row);
      this.getVmsLibraries(row);
      this.vmsScheduleData = row;
      this.currentSchedule = this.vmsScheduleData.vmsSchedules.find((sch) => {
        return this.vmsScheduleData.currentScheduleId === sch.vmsScheduleId;
      });

      this.formDialog = true;
      this.activeTab = 'tab0';
    },
    hideDialog() {
      this.formDialog = false;
      this.resetData();
    },
    openSaveDialog() {
      this.formSaveDialog = true;
    },
    async saveInstantSchedule() {
      let vmsMessage = await this.$refs.vmsMessageEditor.exportData();
      let instantScheduleId = `instant_${new Date().getTime()}`;
      let instantSchedule = {
        vmsCategory: '',
        vmsMessageSize: this.vmsScheduleData.vmsSize,
        vmsMessages: [
          {
            vmsMessageData: vmsMessage.vmsMessageData,
            vmsType: this.vmsType,
            pixelate: vmsMessage.vmsMessageData.bmpColor,
            vmsMessageSvg: vmsMessage.imgSvg,
            isLineFillin: false,
          },
        ],
        vmsScheduleId: instantScheduleId,
        vmsScheduleName: `${moment().format('YYYY-MM-DD')} 임시문안 스케줄`,
        vmsType: this.vmsType,
      };
      this.vmsScheduleData.vmsSchedules.push(instantSchedule);
      this.vmsScheduleData.currentScheduleId = instantScheduleId;
      try {
        await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/vmsDownloadedSchedule/proc`, {
          data: this.vmsScheduleData,
        });
        this.$emit('complete', {});
        this.$message({
          type: 'success',
          message: `${this.vmsScheduleData.fcltName}  임시 문안 등록 완료`,
        });
      } catch (err) {
        this.$message({
          type: 'error',
          message: `${this.vmsScheduleData.fcltName}  임시 문안 등록 실패`,
        });
      }
    },
    async saveCurrentSchedule() {
      let vmsMessage = await this.$refs.vmsMessageEditor.exportData();

      this.currentSchedule.vmsMessages.push({
        vmsMessageData: vmsMessage.vmsMessageData,
        vmsType: this.vmsType,
        pixelate: vmsMessage.vmsMessageData.bmpColor,
        vmsMessageSvg: vmsMessage.imgSvg,
        isLineFillin: false,
      });
      try {
        await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/vmsDownloadedSchedule/proc`, {
          data: this.vmsScheduleData,
        });
        this.$emit('complete', {});
        this.$message({
          type: 'success',
          message: `${this.vmsScheduleData.fcltName}  임시 문안 등록 완료`,
        });
      } catch (err) {
        this.$message({
          type: 'error',
          message: `${this.vmsScheduleData.fcltName}  임시 문안 등록 실패`,
        });
      }
    },
    resetData() {},
    applyTemplete(templete) {
      this.$refs.vmsMessageEditor.resetData();
      setTimeout(() => {
        this.$refs.vmsMessageEditor.importData(templete.vmsMessageData);
      }, 100);
    },
    getThumbImg(vmsLibrary) {
      let thumb = 'static/images/no-image.jpg';
      if (!vmsLibrary.images) {
        return thumb;
      }
      if (vmsLibrary.images.length == 0) {
        return thumb;
      }
      thumb = `${vmsLibrary.images[0].serverFilePath}`;
      return thumb;
    },
    addImageObject(row) {
      this.$refs.vmsMessageEditor.addImageObject(0, 0, 64, 64, `${row.images[0].serverFilePath}`);
    },
  },
};
</script>

<style>
.schedule-preview {
  margin: 0;
  padding: 0;
}

.schedule-preview li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.schedule-preview li img {
  width: 100%;
  display: inline-block;
}
</style>
