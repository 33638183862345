<template>
	<div class="fill-width-height el-container">
		<el-container class="page-body">
			<el-main>
				<el-row class="content-wrap">
					<dk-page-header></dk-page-header>
					<el-col :span="24" class="page-content">
						<el-row>
							<el-col :span="10">
								<el-container>
									<el-container>
										<el-main>
											<el-table
												:data="features"
												empty-text="등록된 기능모듈 정보가 없습니다."
												size="mini"
												@selection-change="featureSelect"
											>
												>
												<el-table-column type="selection" width="55"></el-table-column>
												<el-table-column label="모듈 명칭" prop="moduleName"></el-table-column>
												<el-table-column label="라우팅 URL" prop="url"></el-table-column>
											</el-table>
										</el-main>
									</el-container>
									<el-aside
										width="80px"
										style="
											display: flex;
											align-items: center;
											justify-content: center;
										"
									>
										<div class="transfer-button">
											<el-button type="info" icon="el-icon-arrow-right" circle @click="assignData"></el-button>
											<br />
											<el-button type="info" icon="el-icon-arrow-left" circle @click="removeData"></el-button>
										</div>
									</el-aside>
								</el-container>
							</el-col>
							<el-col :span="14">
								<el-row>
									<el-col :span="20">
										<el-form label-width="120px" :mode="menuData" size="medium">
											<el-form-item label="메뉴 이름">
												<el-input v-model="menuData.menuName"></el-input>
											</el-form-item>
										</el-form>
									</el-col>
									<el-col :span="24">
										<el-tree
											:data="menuData.items"
											show-checkbox
											default-expand-all
											node-key="_id"
											draggable
											ref="menuTree"
										>
											<span slot-scope="{ node, data }">
												<span>{{ data.moduleName }}</span>
											</span>
										</el-tree>
									</el-col>
								</el-row>
							</el-col>
						</el-row>
					</el-col>
				</el-row>
			</el-main>
		</el-container>
	</div>
</template>

<script>
export default {
	data() {
		return {
			features: [],
			filterFeatures: [],
			menuData: {
				menuName: '',
				items: [],
			},
		};
	},
	created() {
		this.getFeature();
	},
	methods: {
		async getFeature() {
			let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/feature`, {});
			this.features = response.data;
		},
		featureSelect(row) {
			console.log(row);
			this.filterFeatures = row;
		},
		assignData() {
			this.menuData.items = this.filterFeatures;
		},
		removeData() {
			let nodes = this.$refs.menuTree.getCheckedNodes();
			nodes.forEach((node) => {
				this.$refs.menuTree.remove(node);
			});
		},
	},
};
</script>

<style scoped>
.transfer-button > .el-button {
	margin: 0 0 10px 0;
	font-size: 20px;
	padding: 10px;
}
</style>
