<template>
  <GChart
    type="PieChart"
    :settings="{
      packages: ['corechart'],
      language: 'ko',
      callback: () => {
        this.drawChart();
      },
    }"
    :data="chartData"
    :options="options"
    @ready="onChartReady"
  />
</template>

<script>
export default {
  created() {
    this.options.colors = this.colors;
    this.setRows(this.data);
  },
  props: {
    cols: {
      type: Array,
      require: true,
      /*[{
          label: '필드명', 
          type: '타입',
      }]*/
    },
    data: {
      type: Array,
    },
    colors: {
      type: Array,
      default() {
        return ['#409eff', '#e54a5c'];
      },
    },
  },
  data() {
    return {
      options: {
        width: '100%',
        height: 300,
        backgroundColor: 'transparent',
        legend: {
          textStyle: { color: '#FFF' },
          position: 'top',
          alignment: 'end',
        },
        chartArea: { backgroundColor: 'transparent', width: '80%', height: '80%' },
      },
      rows: [],
    };
  },
  methods: {
    onChartReady(chart, google) {
      this.chartsLib = google;
    },
    drawChart() {
      this.$emit('drawChart');
    },
    setRows(data) {
      const rows = data.map((elm) => {
        const { name, value } = elm;
        const result = {
          c: [
            {
              v: name,
            },
            {
              v: value,
            },
          ],
        };
        return result;
      });
      this.rows = rows;
    },
  },

  computed: {
    chartData() {
      return {
        cols: this.cols,
        rows: this.rows,
      };
    },
  },

  watch: {
    data(n, o) {
      this.setRows(n);
      // const rows = n.map((elm) => {
      //   const { name, value } = elm;
      //   const result = {
      //     c: [
      //       {
      //         v: name,
      //       },
      //       {
      //         v: value,
      //       },
      //     ],
      //   };
      //   return result;
      // });
      // this.rows = rows;
    },
  },
};
</script>
