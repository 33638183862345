<template>
  <div>
    <el-container class="gis-wrap fill-width-height">
      <div>
        <ul class="event-side-menu">
          <li @click="onClickFlood">폭우/침수 데이터</li>
          <li @click="onClickDisease">가축 질병 데이터</li>
          <li @click="onClickWeatherList">정부 재난 데이터</li>
          <li @click="onClickCovid">코로나</li>
        </ul>
      </div>
      <dk-info-panel
        ref="infoPanel"
        :afterLeave="changeInfoPanel"
        :afterEnter="changeInfoPanel"
        width="320px"
        :initShow="isPanelOpen"
        :showCloseButton="false"
      >
        <!-- 침수 목록 -->
        <!-- <div class="gis-info-panel-wrap" style="padding: 0 10px;" v-if="floodList.length > 0"> -->
        <div class="gis-info-panel-wrap" style="padding: 0 10px;" v-if="showFlag === 1">
          <div class="flood-frame" v-for="floodContents in floodList" :key="floodContents.key">
            <h4>{{ floodContents.title }}</h4>
            <table class="el-table el-table--mini table-th">
              <tr>
                <th>종류</th>
                <td>{{ floodContents.type }}</td>
              </tr>
              <tr class="stat-text-color">
                <th>상태</th>
                <!-- <td style="color: #25d35e;">{{ floodContents.status }}</td> -->
                <td :class="floodContents.className">{{ floodContents.status }}</td>
              </tr>
              <tr v-if="floodContents.currentWaterLevel">
                <th>현재 수위</th>
                <td>{{ floodContents.currentWaterLevel }}</td>
              </tr>
              <tr v-if="floodContents.renewTime">
                <th>갱신 시간</th>
                <td>{{ floodContents.renewTime }}</td>
              </tr>
              <tr v-if="floodContents.statusUpdateName">
                <th>{{ floodContents.statusUpdateName }}</th>
                <td>{{ floodContents.statusUpdateTime }}</td>
              </tr>
              <tr v-if="floodContents.statusDuration">
                <th>상태유지시간</th>
                <td>{{ floodContents.statusDuration }}</td>
              </tr>
            </table>
          </div>
        </div>

        <!-- 가축질병 -->
        <!-- <div class="gis-info-panel-wrap" style="padding: 0 10px;" v-if="diseaseList.length > 0"> -->
        <div class="gis-info-panel-wrap" style="padding: 0 10px;" v-if="showFlag === 2">
          <div class="distance-legend" style="font-size: 17px; margin: 10px;">
            <div><span>500mm</span><span class="color-circle" style="background-color: #eb6637;"></span></div>
            <div><span>3km</span><span class="color-circle" style="background-color: #f0d473;"></span></div>
            <div><span>10km</span><span class="color-circle" style="background-color: #9cc593;"></span></div>
          </div>
          <div v-if="!diseaseList || diseaseList.length === 0" class="container-box mb-both-mg disaster-msg">
            <span>가축질병이 발생하지 않았습니다.</span>
          </div>
          <template v-else>
            <div class="flood-frame" v-for="diseaseContents in diseaseList" :key="diseaseContents.key">
              <h4>{{ diseaseContents.title }}</h4>
              <table class="el-table el-table--mini table-th" v-if="Array.isArray(diseaseContents.dataList)">
                <tr class="stat-text-color" v-for="data in diseaseContents.dataList">
                  <th>{{ data.name }}</th>
                  <td :class="data.className">{{ data.value }}</td>
                </tr>
              </table>
            </div>
          </template>
        </div>

        <!-- 정부재난 -->
        <!-- <div class="" style="padding: 0 10px;" v-if="weatherList.length > 0"> -->
        <div class="" style="padding: 0 10px;" v-if="showFlag === 3">
          <h3 class="middle-title">
            울산 북구 날씨
            <span class="middle-title-date">{{ weatherbaseTime }}</span>
          </h3>
          <div class="container-box mb-both-mg">
            <ul class="table-title-ul">
              <li class="table-title table-align">
                <span></span>
                <span>날씨</span>
                <span>온도</span>
                <span>강수량</span>
              </li>
              <li v-for="weatherContent in weatherList" :key="weatherContent.key" class="table-align">
                <span>{{ weatherContent.name }}</span>
                <span><img :src="weatherContent.weather" style="width: 40px;" /></span>
                <span>{{ weatherContent.temp }}</span>
                <span>{{ weatherContent.rain }}</span>
              </li>
            </ul>
          </div>
          <h3 class="middle-title" style="">재난 발생 현황(지진, 태풍)</h3>
          <div class="container-box mb-both-mg disaster-msg" v-if="specialReport">
            <span>{{ specialReport }}</span>
          </div>
          <div class="container-box mb-both-mg disaster-msg" v-else>
            <span>현 지역에는 재난이 발생하지 않았습니다.</span>
          </div>
        </div>

        <!-- 코로나 -->
        <!-- <div v-if="countryCovidList.length > 0"> -->
        <div v-if="showFlag === 4">
          <h3 class="middle-title">
            전국 <span class="middle-title-date">{{ totalDataDate }}</span>
          </h3>
          <div class="content-click-container flex-box mb-both-mg" style="height: 35%; max-height: 35%;">
            <div class="content-click-box" v-for="countryCovidInfo in countryCovidList" :key="countryCovidInfo.key">
              <!-- <div class="content-click-box"> -->
              <div class="flex-center covid-info">
                <span> {{ countryCovidInfo.name }} </span>
                <span> {{ countryCovidInfo.count }} </span>
                <span> {{ countryCovidInfo.upCount }} </span>
              </div>
            </div>
          </div>
          <h3 class="middle-title">울산</h3>
          <div class="content-click-container flex-box mb-both-mg" style="height: 35%; max-height: 35%;">
            <div class="content-click-box" v-for="ulsanCovidInfo in ulsanCovidList" :key="ulsanCovidList.key">
              <!-- <div class="content-click-box"> -->
              <div class="flex-center covid-info">
                <span> {{ ulsanCovidInfo.name }} </span>
                <span> {{ ulsanCovidInfo.count }} </span>
                <span> {{ ulsanCovidInfo.upCount }} </span>
              </div>
            </div>
          </div>
          <div class="covid-graph"></div>
        </div>
      </dk-info-panel>
      <dk-gis
        fill-height
        ref="dkGis"
        id="dkGis"
        :gisApiInfo="gisApiInfo"
        :apiLoadComplete="apiLoadComplete"
        :clusterData="clusterData"
        :changedBounds="changedBounds"
      />
    </el-container>
  </div>
</template>
<script>
import dkgismain from '@/components/dkGis/dkGisMain.vue';
import dkinfopanel from '@/components/dkInfoPanel.vue';
import moment from 'moment';

export default {
  components: {
    'dk-gis': dkgismain,
    'dk-info-panel': dkinfopanel,
  },
  data: () => ({
    ulsanCode: '31',
    bukguCode: '31200',
    activeGisDataTab: 'tab0',
    isPanelOpen: false,
    gisApiInfo: [],

    weatherDefine: {
      sun: {
        name: '맑음',
        img: 'static/images/weather/w-sun.png',
      },
      cloudSome: {
        name: '구름맑음',
        img: 'static/images/weather/w-cloud-some.png',
      },
      cloud: {
        name: '흐림',
        img: 'static/images/weather/w-cloud.png',
      },
      rain: {
        name: '비',
        img: 'static/images/weather/w-rain.png',
      },
      snowy: {
        name: '눈',
        img: 'static/images/weather/w-snowy.png',
      },
      sleet: {
        name: '눈비',
        img: 'static/images/weather/w-sleet.png',
      },
      error: {
        name: ' ',
        img: 'static/images/dot-and-circle_1.png',
      },
    },
    areaCodeList: ['L1080300', 'S1131100', 'S2110200', 'S2120400'],
    weatherbaseTime: '',
    weatherList: [],
    specialReport: '',

    floodList: [],

    countryCovidList: [],

    ulsanCovidList: [],
    totalDataDate: '',
    ulsanDataDate: '',

    diseaseList: [],

    clusterData: [],
    markerLayer: undefined,
    floodTypeList: [
      {
        fcltTypeId: 'waterlevel',
        convertFunc: 'convertWaterlevelData',
        labelFunc: 'getWaterlevelLabel',
      },
      {
        fcltTypeId: '7d9235d0-727b-11eb-8c89-ed5afab83358',
        convertFunc: 'convertFloodsensor',
        labelFunc: 'getFloodsensorLabel',
      },
      {
        fcltTypeId: 'd55690f0-74cd-11eb-a37b-379839e65cab',
        convertFunc: 'convertGateguard',
        labelFunc: 'getGateguardLabel',
      },
    ],
    markerImage: {
      돼지열병: {
        1: 'static/images/gis/icon/pig-r.png',
        99: 'static/images/gis/icon/pig-b.png',
        돼지: 'static/images/gis/icon/foot-pig.png',
        소: 'static/images/gis/icon/foot-cow.png',
        사슴: 'static/images/gis/icon/foot-dear.png',
        기타: 'static/images/gis/icon/foot-etc.png',
      },
      구제역: {
        1: 'static/images/gis/icon/hoof-r.png',
        99: 'static/images/gis/icon/hoof-b.png',
        돼지: 'static/images/gis/icon/pigdisease-pig.png',
        기타: 'static/images/gis/icon/pigdisease-pig.png',
      },
      '고병원성 조류인플루엔자': {
        1: 'static/images/gis/icon/chicken-r.png',
        99: 'static/images/gis/icon/chicken-b.png',
        닭: 'static/images/gis/icon/ai-chick.png',
        기타: 'static/images/gis/icon/ai-chick.png',
      },
    },

    markerSize: {
      width: 48,
      height: 70,
      // width:36,
      // height:54,
      // width:24,
      // height:35,
    },
    changedBoundsTimer: 0,

    lat: 35.6100624002618,
    lng: 129.37980151001,

    loading: undefined,

    waterLevelStatusInfo: [
      {
        className: 'primary',
        status: '정상',
      },
      {
        className: 'overflow',
        status: '관심',
      },
      {
        className: 'critical',
        status: '주의',
      },
      {
        className: 'alert',
        status: '경계',
      },
      {
        className: 'caution',
        status: '심각',
      },
    ],

    floodStatusInfo: {
      '0': {
        className: 'primary',
        status: '정상',
        updateName: '침수 해제 시각',
      },
      '1': {
        className: 'caution',
        status: '침수',
        updateName: '침수 발생 시각',
      },
    },

    gateguardStatusInfo: {
      '0': {
        className: 'primary',
        status: '열림',
      },
      '1': {
        className: 'caution',
        status: '닫힘',
      },
    },

    floodMarkerLayer: [],
    floodMarkerList: [],

    diseaseMarkerLayer: [],
    diseaseMarkerList: [],

    showFlag: -1,
  }),
  created() {
    const me = this;
    me.gisApiInfo = me.$store.getters['gis/getGisApi'];
    if (me.gisApiInfo.length > 0) {
      me.gisApiInfo[0].apiKey = me.$config.getSiteConfig().gis.apiKey;
    }
    me.gisApiInfo[0].geometry.lat = me.lat;
    me.gisApiInfo[0].geometry.lng = me.lng;
  },

  methods: {
    async apiLoadComplete(currentGisApi) {
      this.setLoading();

      this.markerLayer = this.$refs.dkGis.createMarkerLayer();
      this.floodMarkerLayer = this.$refs.dkGis.createMarkerLayer();
      this.diseaseMarkerLayer = this.$refs.dkGis.createMarkerLayer();

      // this.getFlood();
      // this.getDiseaseMarkerData();
      await Promise.all([this.getFlood(), this.getDiseaseMarkerData()]);

      this.clearLoading();
    },

    requestDisease() {},

    async requestEvent(args) {
      try {
        const { search = {}, sort, limit } = args;

        const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/ulsanBukgu/api/event`, { search, sort, limit });
        if (!response || response.status !== 200) {
          throw new Error();
        }
        return response.data;
      } catch (err) {
        this.$message({
          type: 'error',
          message: 'requestEvent faile',
        });
        return [];
      }
    },

    async getFlood() {
      const me = this;
      try {
        const fcltTypeList = await me.requestFcltType({
          fcltTypeId: me.floodTypeList.map((floodTypeInfo) => floodTypeInfo.fcltTypeId),
        });
        if (!fcltTypeList || fcltTypeList.length === 0) return;

        me.floodTypeList = me.floodTypeList.map((floodTypeInfo) => {
          const data = fcltTypeList.find((fcltTypeInfo) => fcltTypeInfo.fcltTypeId === floodTypeInfo.fcltTypeId) || {};

          return {
            ...floodTypeInfo,
            data: { ...data },
          };
        });

        const response = await Promise.all(
          fcltTypeList.map((fcltTypeInfo) => {
            const { fcltTypeId } = fcltTypeInfo;
            return me.requestFclt({ fcltTypeId });
          })
        );

        let floodMarkerList = [];
        response.forEach((elm) => {
          if (!elm || elm.length === 0) return;
          const floodTypeInfo = me.floodTypeList.find((floodTypeInfo) => floodTypeInfo.fcltTypeId === elm[0].fcltTypeId);
          if (!floodTypeInfo) return;

          const markerList = me.makeFloodMarker(elm, floodTypeInfo);
          floodMarkerList = [...floodMarkerList, ...markerList];
        });

        me.floodMarkerList = floodMarkerList;
      } catch (err) {
        me.$message.error('폭우/침수 정보 조회 에러');
        me.floodList = [];
      }
    },

    makeFloodMarker(floodList, fcltTypeInfo) {
      const me = this;
      try {
        return floodList.map((elm) => {
          const { gisMarkerHeight, gisMarkerWidth, gisMarkerImage = {} } = fcltTypeInfo.data || {};

          const markerImage = {
            src: gisMarkerImage.normal,
            size: { width: gisMarkerWidth, height: gisMarkerHeight },
          };

          const properties = { ...elm };
          let infowindow = undefined;

          if (fcltTypeInfo.labelFunc && me[fcltTypeInfo.labelFunc]) {
            const iwContent = me[fcltTypeInfo.labelFunc](properties);

            infowindow = {
              iwContent,
              visibleIw: true,
            };
          }

          return {
            key: elm.fcltId,
            lat: elm.gisLat,
            lng: elm.gisLng,
            markerImage,
            infowindow,
            // event: {
            //   click: me.onClickMarker,
            // },
            properties,
            fcltTypeInfo,
          };
        });
      } catch (err) {
        me.$message.error('폭우/침수 마커 생성 에러');
        return [];
      }
    },

    getWaterlevelLabel(fcltInfo) {
      const convertData = this.convertWaterlevelData([fcltInfo]);
      const { className, status, currentWaterLevel, type } = convertData[0];

      return `<div class="flooding-info" style="z-index: 100;">
                <div class="flooding-box">
                  <div><strong>${type}</strong></div>
                  <div class="flooding-info-state-box stat-text-color">
                    <span>상태</span>
                    <span class="${className}">${status}</span> |
                    <span>수위</span>
                    <span class="${className}">${currentWaterLevel}</span>
                  </div>
                </div>
              </div>`;
    },

    getFloodsensorLabel(fcltInfo) {
      const convertData = this.convertFloodsensor([fcltInfo]);
      const { className, status, type } = convertData[0];

      return `<div class="flooding-info" style="z-index: 100;">
                <div class="flooding-box">
                  <div><strong>${type}</strong></div>
                  <div class="flooding-info-state-box stat-text-color">
                    상태
                    <span class="${className}">${status}</span>
                  </div>
                </div>
              </div>`;
    },

    getGateguardLabel(fcltInfo) {
      const convertData = this.convertGateguard([fcltInfo]);
      const { className, status, type } = convertData[0];

      return `<div class="flooding-info" style="z-index: 100;">
                <div class="flooding-box">
                  <div><strong>${type}</strong></div>
                  <div class="flooding-info-state-box stat-text-color">
                    상태
                    <span class="${className}">${status}</span>
                  </div>
                </div>
              </div>`;
    },

    showFloodData() {
      const me = this;
      try {
        const { floodMarkerList = [] } = me;

        const floodList = floodMarkerList.map((elm) => {
          const { properties, fcltTypeInfo: floodTypeInfo } = elm;

          if (!floodTypeInfo) return;
          else if (!floodTypeInfo.convertFunc || !me[floodTypeInfo.convertFunc]) return;

          const result = me[floodTypeInfo.convertFunc]([properties]);
          return result[0];
        });

        me.floodList = floodList;
      } catch (err) {
        me.$message.error('폭우/침수 데이터  표출 에러');
        me.floodList = [];
      }
    },

    showDiseaseData() {
      let diseaseList = [];
      try {
        if (!this.diseaseMarkerList || this.diseaseMarkerList.length === 0) return;
        diseaseList = this.diseaseMarkerList.map((mData) => {
          const { key, properties = {} } = mData;
          const { eventData = {} } = properties;
          const { FARM_NM, LKNTS_NM, LVSTCKSPC_NM, OCCRRNC_LVSTCKCNT, OCCRRNC_DE } = eventData;

          const dataList = [
            {
              name: '병명',
              value: LKNTS_NM,
            },
            {
              name: '가축종류',
              value: LVSTCKSPC_NM,
            },
            {
              name: '발생일시',
              value: moment(OCCRRNC_DE).format('YYYY-MM-DD'),
            },
            {
              name: '가축두수',
              value: OCCRRNC_LVSTCKCNT,
            },
          ];

          return {
            key,
            title: FARM_NM,
            dataList,
          };
        });

        this.diseaseList = diseaseList;
      } catch (err) {
        this.$message.error('가축질병 표출 에러');
      }
    },

    convertMakerData(dataList = [], markerLayer) {
      const me = this;
      try {
        markerLayer.addMarkerList(dataList);
      } catch (err) {
        me.$message.error('');
        return [];
      }
    },

    async requestFclt(args) {
      const me = this;
      try {
        const response = await me.$http.get('coreapi/core/api/fclt', {
          params: args,
        });

        if (!response || response.status !== 200) {
          throw new Error();
        }

        return response.data.docs;
      } catch (err) {
        me.$message.error('시설물 정보 읽기 실패');
        return [];
      }
    },

    convertWaterlevelData(args) {
      return args.map((elm) => {
        const { fcltId, fcltName, fcltTypeName, fcltTypeData = {} } = elm;
        const {
          level_status = {},
          level_value = {},
          upd_date = {},
          reg_date = {},
          level1 = {},
          level2 = {},
          level3 = {},
          level4 = {},
        } = fcltTypeData;

        const renewTimeDate = upd_date.value || reg_date.value;
        let renewTime = '없음';
        if (renewTimeDate) {
          renewTime = moment(renewTimeDate).format('YYYY-MM-DD HH:mm');
        }

        let currentWaterLevel = '없음';
        let idx = -1;
        let className = '';
        if (typeof level_value.value !== 'undefined') {
          currentWaterLevel = level_value.value + 'cm';

          const levelList = [level1.value, level2.value, level3.value, level4.value];
          idx = 4;
          for (let i = 0; i < levelList.length - 1; i++) {
            if (levelList[i] > level_value.value) {
              idx = i;
              break;
            }
          }

          if (idx !== -1) {
            const { className: c } = this.waterLevelStatusInfo[idx];
            className = c;
          }
        }

        return {
          key: fcltId,
          title: fcltName,
          type: fcltTypeName,
          status: level_status.value || '없음',
          className,
          currentWaterLevel,
          renewTime,
        };
      });
    },

    convertFloodsensor(args) {
      return args.map((elm) => {
        const { fcltId, fcltName, fcltTypeName, fcltTypeData = {} } = elm;
        const { status_update_time = {}, flood_state_code = {}, flood_state = {} } = fcltTypeData;

        const statusUpdateTimeDate = status_update_time.value;
        let statusUpdateTime = '없음';
        let statusUpdateName = '';

        let className = '';
        if (flood_state_code.value === '0') {
          statusUpdateName = '침수해제시각';
          className = this.floodStatusInfo[flood_state_code.value].className;
        } else if (flood_state_code.value === '0') {
          statusUpdateName = '침수시각';
          className = this.floodStatusInfo[flood_state_code.value].className;
        }

        if (statusUpdateTimeDate) {
          statusUpdateTime = moment(statusUpdateTimeDate).format('YYYY-MM-DD HH:mm');
        }

        return {
          key: fcltId,
          title: fcltName,
          type: fcltTypeName,
          status: flood_state.value || '없음',
          className,
          statusUpdateName,
          statusUpdateTime,
        };
      });
    },

    convertGateguard(args) {
      return args.map((elm) => {
        const { fcltId, fcltName, fcltTypeName, fcltTypeData = {} } = elm;
        const { gate_status = {}, gate_status_code = {}, status_duration = {} } = fcltTypeData;

        let statusDuration = '없음';

        if (typeof status_duration.value !== 'undefined') {
          statusDuration = Math.floor(status_duration.value / 60) + '분';
        }

        let className = '';
        const gInfo = this.gateguardStatusInfo[gate_status_code.value + ''];
        if (gInfo) {
          className = gInfo.className;
        }

        return {
          key: fcltId,
          title: fcltName,
          type: fcltTypeName,
          status: gate_status.value || '없음',
          className,
          statusDuration,
        };
      });
    },

    async getDisease() {
      const me = this;

      const search = {
        eventTypeId: 'livestockDisease',
        'eventData.LKNTS_NM': { $in: ['돼지열병', '구제역', '고병원성 조류인플루엔자'] },
        // $and: [
        //   { $or: [{ 'eventData.LKNTS_NM': '돼지열병' }, { 'eventData.LKNTS_NM': '구제역' }, { 'eventData.LKNTS_NM': '고병원성 조류인플루엔자' }] },
        // ],
      };

      // 기간 조건 추가
      const dt = new Date();
      search.startDate = { $gt: dt.setFullYear(dt.getFullYear() - 1) };

      // 지역 조건 추가
      search['eventData.legaldong.sigunguCode'] = me.bukguCode;

      const responseData = await me.requestEvent({ search });

      if (!Array.isArray(responseData) || responseData.length === 0) {
        // throw new Error('');
        return;
      }

      const diseaseList = [];

      responseData.forEach((diseaseInfo) => {
        const { eventId, eventStat, eventData, gisLat, gisLng } = diseaseInfo;

        if (!gisLat || !gisLng) return;

        const { LKNTS_NM, legaldong, LVSTCKSPC_NM } = eventData;

        const mouseEvent = {};
        // mouseEvent.click = me.onClickMarker;

        let circleList = me.makeCircleInfo(eventData);

        const markerImage = {};
        markerImage.size = me.markerSize;

        let livestockShape = '기타';

        // markerImage.src = me.markerImage[LKNTS_NM][eventStat];
        if (typeof LVSTCKSPC_NM === 'string') {
          livestockShape = LVSTCKSPC_NM.split('-')[0];
        }
        const diseaseIconInfo = me.markerImage[LKNTS_NM];
        if (diseaseIconInfo) {
          let iconSrc = diseaseIconInfo[livestockShape];
          if (!iconSrc) {
            iconSrc = diseaseIconInfo['기타'];
          }

          markerImage.src = iconSrc;
        } else {
          return;
        }

        diseaseList.push({
          key: eventId,
          lat: gisLat,
          lng: gisLng,
          markerImage,
          event: mouseEvent,
          // visible:false,
          // infowindow,
          circleList,
          properties: diseaseInfo,
        });
      });

      return diseaseList;
    },

    async getDiseaseMarkerData() {
      // const diseaseList = await this.getDisease();
      // this.diseaseList = diseaseList;
      const diseaseMarkerList = await this.getDisease();
      this.diseaseMarkerList = diseaseMarkerList;
    },

    makeCircleInfo(args) {
      const {} = args;

      const radiusList = [
        {
          radius: 10000,
          // strokeWeight,
          strokeColor: '#00ff30',
          // strokeOpacity,
          // strokeStyle,
          fillColor: '#00ff30',
          // fillOpacity: 0.5,
          // zIndex,
        },
        {
          radius: 3000,
          // strokeWeight,
          strokeColor: '#ffc500',
          // strokeColor: '#4da546',
          // strokeOpacity,
          // strokeStyle,
          fillColor: '#ffc500',
          // fillColor: '#4da546',
          // fillOpacity: 0.5,
          // zIndex,
        },
        {
          radius: 500,
          // strokeWeight,
          strokeColor: '#ff0000',
          // strokeColor: '#ffc500',
          // strokeOpacity,
          // strokeStyle,
          fillColor: '#ff0000',
          // fillColor: '#ffc500',
          // fillOpacity: 0.5,
          // zIndex,
        },
      ];

      if (args.LKNTS_NM === '고병원성 조류인플루엔자') {
        return [radiusList[0], radiusList[1]];
      } else {
        return [radiusList[1], radiusList[2]];
      }
    },

    changedBounds() {},

    async getWeather() {
      const weatherList = await this.requestEvent({
        search: {
          eventTypeId: 'weatherNormalReport',
        },
        sort: { startDate: -1, eventId: 1 },
        // limit: 5,
      });

      const weatherListTemp = [];
      let newTime = 0;

      weatherList.forEach((weatherInfo) => {
        const { eventId, eventData = {}, gisLat, gisLng, startDate } = weatherInfo;

        const startDt = new Date(startDate);
        if (newTime < startDt.getTime()) {
          newTime = startDt.getTime();
        } else if (newTime > startDt.getTime()) {
          return;
        }

        const { collectionPlace = {}, LGT, PTY, RN1, SKY, T1H, REH, UUU, VVV, VEC, WSD } = eventData;
        const { emd } = collectionPlace;

        const rain = RN1 && !!RN1.value && RN1.value !== '0' && RN1.value !== 0 ? RN1.value + 'mm' : '-';

        let weather = '';
        if (PTY === '없음') {
          switch (SKY) {
            case '맑음': {
              weather = this.weatherDefine.sun.img;
              break;
            }
            case '구름맑음': {
              weather = this.weatherDefine.cloudSome.img;
              break;
            }
            case '흐림': {
              weather = this.weatherDefine.cloud.img;
              break;
            }
            default: {
              weather = this.weatherDefine.error.img;
            }
          }
        } else {
          switch (PTY) {
            case '비':
            case '소나기':
            case '빗방울': {
              weather = this.weatherDefine.rain.img;
              break;
            }
            case '눈':
            case '눈날림': {
              weather = this.weatherDefine.snowy.img;
              break;
            }
            case '비/눈':
            case '빗방울/눈날림': {
              weather = this.weatherDefine.sleet.img;
              break;
            }
            default: {
              weather = this.weatherDefine.error.img;
            }
          }
        }

        weatherListTemp.push({
          key: eventId,
          name: emd,
          weather,
          temp: typeof T1H !== 'undefined' ? T1H + '℃' : '-',
          rain,
        });
      });

      this.weatherList = weatherListTemp;
      if (weatherList.length > 0) {
        this.weatherbaseTime = moment(weatherList[0].startDate).format('YYYY/MM/DD HH:mm');
      }
    },

    async getDisaster() {
      const search = { eventTypeId: 'weatherSpecialReport' };

      search.$and = [];

      const orCode = this.areaCodeList.map((areaCode) => {
        return { 'eventData.areaCode': areaCode };
      });
      search.$and.push({ $or: orCode });

      const orEndDate = [
        {
          eventStat: '1',
        } /* {
        eventStat: '99',
        endDate:{$lte:''},
       },*/,
      ];
      search.$and.push({ $or: orEndDate });

      const weatherList = await this.requestEvent({
        search,
        sort: { collectDateTime: -1 },
      });

      if (!Array.isArray(weatherList) || weatherList.length === 0) {
        this.specialReport = '';
        return;
      }

      const specialReportList = weatherList.map((weatherInfo) => {
        const { eventData } = weatherInfo;

        let stateStr = '발령';
        if (eventData.command === '해제') {
          stateStr = '해제';
        }

        let specialReport = `${eventData.areaName} ${eventData.warnVar}${eventData.warnStress} ${stateStr}`;

        return specialReport;
      });

      this.specialReportList = specialReportList;

      const { eventData } = weatherList[0];

      let stateStr = '발령';
      if (eventData.command === '해제') {
        stateStr = '해제';
      }

      let specialReport = `${eventData.areaName} ${eventData.warnVar}${eventData.warnStress} ${stateStr}`;

      this.specialReport = specialReport;
    },

    async getCovideTotalData() {
      const covidDataList = await this.requestEvent({
        search: {
          eventTypeId: 'COVID-19',
          'eventData.gubunEn': 'Total',
        },
        sort: { collectDateTime: -1 },
        limit: 2,
      });

      if (!Array.isArray(covidDataList) || covidDataList.length === 0) {
        // throw new Error('');
        return;
      }

      const covidTotalData = covidDataList[0];
      const { eventData = {} } = covidTotalData;
      const {
        defCnt, // 확진자 수
        deathCnt, // 사망자 수
        isolIngCnt, // 격리중 환자수
        isolClearCnt, // 격리 해제 수
        incDec, // 전일대비 증감 수
        stdDay,
      } = eventData;

      const covidTotalData2 = covidDataList[1] || {};
      const { eventData: eventData2 = {} } = covidTotalData2;

      this.totalDataDate = stdDay;

      this.countryCovidList = [
        {
          key: 'defCnt',
          name: '누적확진환자',
          count: defCnt,
          upCount: defCnt - eventData2.defCnt || '-',
        },
        {
          key: 'isolIngCnt',
          name: '격리중',
          count: isolIngCnt,
          upCount: isolIngCnt - eventData2.isolIngCnt || '-',
        },
        {
          key: 'isolClearCnt',
          name: '격리해체',
          count: isolClearCnt,
          upCount: isolClearCnt - eventData2.isolClearCnt || '-',
        },
        {
          key: 'deathCnt',
          name: '사망자',
          count: deathCnt,
          upCount: deathCnt - eventData2.deathCnt || '-',
        },
      ];
    },

    async getCovideUlsanData() {
      const covidDataList = await this.requestEvent({
        search: {
          eventTypeId: 'COVID-19',
          'eventData.gubunEn': 'Ulsan',
        },
        sort: { collectDateTime: -1 },
        limit: 2,
      });

      if (!Array.isArray(covidDataList) || covidDataList.length === 0) {
        // throw new Error('');
        return;
      }

      const covidTotalData = covidDataList[0];
      const { eventData = {} } = covidTotalData;
      const {
        defCnt, // 확진자 수
        deathCnt, // 사망자 수
        isolIngCnt, // 격리중 환자수
        isolClearCnt, // 격리 해제 수
        incDec, // 전일대비 증감 수
        stdDay,
      } = eventData;

      const covidTotalData2 = covidDataList[1] || {};
      const { eventData: eventData2 = {} } = covidTotalData2;

      this.ulsanDataDate = stdDay;

      this.ulsanCovidList = [
        {
          key: 'defCnt',
          name: '누적확진환자',
          count: defCnt,
          upCount: defCnt - eventData2.defCnt || '-',
        },
        {
          key: 'isolIngCnt',
          name: '격리중',
          count: isolIngCnt,
          upCount: isolIngCnt - eventData2.isolIngCnt || '-',
        },
        {
          key: 'isolClearCnt',
          name: '격리해체',
          count: isolClearCnt,
          upCount: isolClearCnt - eventData2.isolClearCnt || '-',
        },
        {
          key: 'deathCnt',
          name: '사망자',
          count: deathCnt,
          upCount: deathCnt - eventData2.deathCnt || '-',
        },
      ];
    },

    async getCovid() {
      return Promise.all([this.getCovideTotalData(), this.getCovideUlsanData()]);
    },

    async onClickFlood() {
      // this.setLoading();

      // await this.getFlood();
      this.showFloodData();
      this.weatherList = [];
      this.countryCovidList = [];
      this.diseaseList = [];

      // this.clearLoading();
      this.showFlag = 1;
      this.$refs.infoPanel.open();
    },

    async onClickDisease() {
      // this.setLoading();

      this.floodList = [];
      this.weatherList = [];
      this.countryCovidList = [];

      this.showDiseaseData();
      this.showFlag = 2;

      // this.clearLoading();
      this.$refs.infoPanel.open();
    },

    async onClickWeatherList() {
      this.setLoading();
      this.$refs.infoPanel.close();

      // this.getWeather();
      // this.getDisaster();
      this.floodList = [];
      this.countryCovidList = [];
      this.diseaseList = [];
      await Promise.all([this.getWeather(), this.getDisaster()]);
      this.showFlag = 3;

      this.clearLoading();
      this.$refs.infoPanel.open();
    },

    async onClickCovid() {
      this.setLoading();
      this.$refs.infoPanel.close();

      try {
        this.floodList = [];
        this.weatherList = [];
        this.diseaseList = [];

        await this.getCovid();
        this.showFlag = 4;
      } catch (err) {
        this.$message.error('코로나 정보 표출 에러');
      }
      this.clearLoading();
      this.$refs.infoPanel.open();
    },

    setLoading(lockFlag = true) {
      if (this.loading) return;

      this.loading = this.$loading({
        lock: lockFlag,
        text: '로딩중',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
    },

    clearLoading() {
      if (!this.loading) return;
      this.loading.close();
      this.loading = undefined;
    },

    async requestFcltType(args) {
      const me = this;
      try {
        const response = await me.$http.get('coreapi/core/api/fcltType', {
          params: args,
        });

        if (!response || response.status !== 200) {
          throw new Error();
        }

        return response.data.docs;
      } catch (err) {
        me.$message.error('시설물타입 정보 읽기 실패');
      }
    },
  },
  watch: {
    diseaseMarkerList(n, o) {
      if (o.length > 0) {
        this.markerLayer.removeAllMarker();
      }
      this.markerLayer.addMarkerList(n);
    },
    floodMarkerList(n, o) {
      if (o.length > 0) {
        this.floodMarkerLayer.removeAllMarker();
      }
      this.convertMakerData(n, this.floodMarkerLayer);
    },
  },
};
</script>
<style scoped>
.gis-search-result {
  background-color: #fff;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}
.gis-data-wrap {
  background-color: #fff;
  bottom: 15px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.gis-group-list {
  padding: 0 10px;
}
.table-th th {
  width: 30%;
}
</style>
