<template>    
    <line-chart
        :chart-data="datacollection"
        :height="320"
        :options="chartOption"
    ></line-chart>    
</template>
<script>
import lineChart from  '@/components/chart/lineChart.js'
import moment from 'moment'
export default {
    components: {
        lineChart
    },
    data() {
        return {
            datacollection:{
                labels: [this.newDate(-6), this.newDate(-5), this.newDate(-4), this.newDate(-3), this.newDate(-2), this.newDate(-1), this.newDate(0)],
                datasets: [{
                    label: "돌발",
                    data: [8, 9, 7, 3, 10, 3, 4],
                    borderColor: '#1e88e5',
                    spanGaps: true
                },
                {
                    label: "사고",
                    data: [3, 4, 1, 5, 7, 8, 6],
                    borderColor: '#7559ff',
                    spanGaps: true
                }]
            },
            chartOption:{
                responsive: true,
                maintainAspectRatio: false,
                fill:false,          
                elements: {
                    line: {
                        tension: 0, // disables bezier curves
                    }
                },
                legend: {
                    position:'bottom',
                    align: 'center',
                    labels: {
                        fontColor: "#9a9a9a",
                        fontSize: 12
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            fontColor: "#e0e0e0",
                            fontSize: 12,
                            beginAtZero: true
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            fontColor: "#9a9a9a",
                            fontSize: 12,
                            beginAtZero: true
                        }
                    }]
                }
            },
            incidents:null
        }
    },
    methods: {
        newDate(days) {
            return moment().add(days, 'd').format('MM/DD');
        },
        getWeeklyIncident() {
            this.$store.dispatch("incident/setWeeklyIncident")
            .then((data)=> {
                this.incidents = data
            })
        }
    }
}
</script>
<style>

</style>