<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    top="5vh"
    class="el-dialog-height-scroll"
  >
    <div id="formUserGroupInfo">
      <el-form :model="formData" label-width="100px" size="medium" :rules="rules" ref="form0" style="height: 60px;">
        <div style="display: flex;">
          <div style="width: 50%;">
            <el-form-item label="그룹 명칭" prop="name" style="margin-bottom: 10px;">
              <el-input v-model="formData.name" placeholder="그룹 명칭" style="margin-left: 15px; width: 300px;"></el-input>
            </el-form-item>
          </div>
          <div style="width: 50%;">
            <el-form-item label="그룹 레벨" prop="authLevel" style="margin-bottom: 10px;">
              <!-- <el-input v-model="formData.authLevel" placeholder="그룹 레벨" style="margin-left: 15px; width: 300px;"></el-input> -->
              <el-select v-if="formData.authLevel === 0" v-model="formData.authLevel" placeholder="그룹 레벨" :disabled="true">
                <el-option :label="formData.authLevel + '레벨'" :value="formData.authLevel" />
              </el-select>
              <el-select v-else v-model="formData.authLevel" placeholder="그룹 레벨">
                <el-option v-for="item in authLevelList" :key="item" :label="item + '레벨'" :value="item" />
              </el-select>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <div class="form-user-group-body">
        <ul class="tab-button">
          <li v-for="(item, idx) in tabList" :key="idx" :class="currentTabId === item.id ? 'active' : ''" @click="tabSelect(item.id)">
            <span></span>
            {{ item.label }}
          </li>
        </ul>
        <div class="form-user-group-tab-body">
          <el-tree
            v-show="currentTabId === 1"
            :data="featureList"
            show-checkbox
            node-key="featureId"
            :props="treeProps"
            default-expand-all
            highlight-current
            ref="tree"
          ></el-tree>
          <div v-show="currentTabId === 2" class="fclt-type-auth-list">
            <ul>
              <li v-for="(item, idx) in fcltTypes" :key="idx">
                <span class="fclt-type-grid-icon"><img :src="item.gisMarkerImage.normal" /></span>
                <span class="fclt-type-name">{{ item.fcltTypeName }}</span>
                <span class="auth-check">
                  <el-checkbox v-model="item.checked" @change="checkedValue">{{ authCheckboxText(item) }}</el-checkbox>
                </span>
              </li>
            </ul>
          </div>
          <div v-show="currentTabId === 3" class="fclt-type-auth-list">
            <ul>
              <li v-for="(item, idx) in pushList" :key="idx">
                <!-- <span class="fclt-type-grid-icon"></span> -->
                <span class="push-type-name">{{ item.korValue }}</span>
                <span class="push-check">
                  <el-checkbox v-model="item.checked" @change="checkedValue">{{ authCheckboxText(item) }}</el-checkbox>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="form-bottom">
      <el-button @click="hideDialog" style="float: right;">
        취소
      </el-button>
      <el-button type="primary" @click="save" style="float: right; margin-right: 5px;">
        저장
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import _ from 'lodash';

export default {
  data() {
    return {
      systemLogInfo: {},
      formTitle: '',
      formDialog: false,
      formData: {
        name: '',
        authLevel: 9,
      },
      authLevelList: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      tabList: [],
      currentTabId: 1,
      fcltTypes: [],
      pushList: [],
      selectList: [],
      featureList: [],
      treeProps: {
        children: 'children',
        label: 'label',
      },
      rules: {
        name: [
          {
            required: true,
            message: '그룹 명칭을 입력하여 주십시오',
            trigger: ['blur', 'change'],
          },
        ],
      },
      updateFeatureId: [],
      fcltTypeAuth: {},
      pushSelect: {},
      siteConfig: {},
      pushShow: false,
    };
  },
  methods: {
    async showDialog(row) {
      this.tabList = [];
      this.tabList.push({ id: 1, label: '기능 권한 설정' });
      this.tabList.push({ id: 2, label: '시설물 권한 설정' });
      this.siteConfig = this.$config.getSiteConfig();
      console.log(this.siteConfig);
      if (this.siteConfig.mobileAble && this.siteConfig.platformType !== 'fclt') {
        this.getPushList();
        this.tabList.push({ id: 3, label: 'push 알람 설정' });
      }
      this.getFcltTypes();
      if (row) {
        this.updateFlag = true;
        this.formTitle = '사용자 그룹 수정';
        this.formData = _.cloneDeep(row);
        this.updateFeatureId = [];
        const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/auth/getFcltTypeAuth`, { params: { group: row.menuId } });
        if (response.data.length > 0) {
          this.fcltTypeAuth = response.data[0].fcltTypeAuth;
        }
        _.map(this.formData.items, (i) => {
          // this.updateFeatureId.push({
          //     "featureId" : i.featureId
          // })
          if (i.children) {
            _.map(i.children, (ii) => {
              this.updateFeatureId.push({
                featureId: ii.featureId,
              });
            });
          }
        });
        if (this.siteConfig.mobileAble) {
          const pushResponse = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/pushAlram/getPushSelect`, { params: { group: row.menuId } });
          if (pushResponse.data.length > 0) {
            this.pushSelect = pushResponse.data[0].pushSelect;
          }
          await this.getPushSelection(row.menuId);
        }
      } else {
        this.updateFlag = false;
        this.formTitle = '사용자 그룹 추가';
      }
      this.formDialog = true;
      await this.getFeature();
      this.$forceUpdate();
      if (this.updateFeatureId.length > 0) {
        setTimeout(() => {
          this.setFcltTypeAuthData();
          this.$refs.tree.setCheckedNodes(this.updateFeatureId);
        }, 100);
      }
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    async getFcltTypes() {
      this.fcltTypes = [];
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltType`, {});
      this.fcltTypes = response.data.docs;
    },
    async getPushList() {
      this.pushList = [];
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/pushAlram/getPushList`, {});
      this.pushList = response.data;
    },
    resetData() {
      this.currentTabId = 1;
      this.formData = {
        name: '',
        authLevel: 9,
      };
      this.featureList = [];
      this.updateFeatureId = [];
      this.fcltTypeAuth = {};
      (this.pushList = []), (this.selectList = []), (this.siteConfig = {}), (this.tabList = []), this.$forceUpdate();
    },
    async getFeature() {
      let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/feature`, {
        params: {},
      });
      let feature = result.data;

      _.map(feature, (f) => {
        if (!f.parentFeatureId) {
          this.featureList.push(f);
        }
      });

      this.featureList = _.sortBy(this.featureList, (o) => o.featureId);
      _.map(this.featureList, (fl) => {
        let rawData = _.filter(feature, (f) => {
          return fl.featureId == f.parentFeatureId;
        });
        let featureList = _.sortBy(rawData, (o) => o.featureId);
        fl.children = featureList;
      });
    },

    async getPushSelection(menuId) {
      let result = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/pushAlram/getPushSelect`, {
        params: { group: menuId },
      });
      if (result.data.length > 0) {
        let select = result.data[0].pushSelect;
        for (let i = 0; i < this.pushList.length; i++) {
          Object.keys(select).forEach((push) => {
            if (this.pushList[i].value == push) {
              let sel = false;
              if (select[push] == 1) {
                sel = true;
              } else {
                sel = false;
              }
              this.pushList[i].checked = sel;
            }
          });
        }
      }
    },

    save() {
      this.$refs.form0.validate(async (valid) => {
        if (valid) {
          this.fcltTypeAuth = this.getFcltTypeAuthData();
          let saveFeature = [];
          let rawData = this.$refs.tree.getCheckedNodes();
          let filter = _(rawData)
            .groupBy('parentFeatureId')
            .map(function (items, id) {
              if (id !== 'undefined') {
                return id;
              }
            })
            .value();
          filter = _.compact(filter);
          _.map(filter, (f) => {
            let aa = _.find(this.featureList, (fl) => {
              return f == fl.featureId;
            });
            saveFeature.push(aa);
          });
          saveFeature = _.sortBy(saveFeature, (o) => o.featureId);

          _.map(saveFeature, (fl) => {
            let rawData2 = _.filter(rawData, (f) => {
              return fl.featureId == f.parentFeatureId;
            });
            let featureList = _.sortBy(rawData2, (o) => o.featureId);
            fl.children = featureList;
          });

          this.formData.items = saveFeature;
          this.formData.fcltTypeAuth = this.fcltTypeAuth;
          this.formData.pushSelect = {};
          for (let i = 0; i < this.pushList.length; i++) {
            if (this.pushList[i].checked) {
              this.pushList[i].checked = 1;
            } else {
              this.pushList[i].checked = 0;
            }
            this.formData.pushSelect[this.pushList[i].value] = this.pushList[i].checked;
          }
          let postUrl = `${this.$config.getServerConfig().core.api}/core/api/menu/add`;
          if (this.updateFlag) {
            postUrl = `${this.$config.getServerConfig().core.api}/core/api/menu/update`;
          }
          let result = await this.$http.post(postUrl, { data: this.formData });
          this.$emit('procDataComplete', this.formData);
          this.hideDialog();
          this.$message({
            type: 'success',
            message: `사용자 그룹 정보 등록 완료`,
          });
          this.systemLogInfo.page = `${this.$route.name}`;
          this.systemLogInfo.saveType = postUrl;
          await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
        }
      });
    },
    setFcltTypeAuthData() {
      this.fcltTypes.forEach((f) => {
        // console.log(this.fcltTypeAuth[f.fcltTypeId]);
        if (this.fcltTypeAuth[f.fcltTypeId]) {
          if (this.fcltTypeAuth[f.fcltTypeId] > 0) {
            f.checked = true;
          } else {
            f.checked = false;
          }
        }
      });
    },
    getFcltTypeAuthData() {
      let result = {};
      this.fcltTypes.forEach((f) => {
        result[f.fcltTypeId] = 0;
        if (f.checked) {
          result[f.fcltTypeId] = 1;
        }
      });
      return result;
    },
    authCheckboxText(item) {
      if (item.checked) {
        return '사용 함';
      } else {
        return '사용 안함';
      }
    },
    checkedValue(val) {
      console.log(val);
      console.log(this.fcltTypes);
      this.$forceUpdate();
    },
    tabSelect(tabId) {
      this.currentTabId = tabId;
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
  },
};
</script>
