<template>
  <div class="fill-width-height el-container">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <dk-el-table-header
              :openFormDialog="openFormDialog"
            ></dk-el-table-header>
            <el-table
              :data="materialTypes"
              style="width: 100%;"
              empty-text="기자재 유형 정보가 없습니다."
              size="mini"
              @row-click="selectRow"
              stripe
            >
              <el-table-column
                prop="materialTypeName"
                label="기자재 유형 명칭"
                fixed="left"
              ></el-table-column>
              <el-table-column
                prop="metaKeyword"
                label="메타 키워드"
              ></el-table-column>
              <el-table-column label width="170px">
                <template slot-scope="scope">
                  <el-button @click="openFormDialog(scope.row)" size="small"
                    >수정</el-button
                  >
                  <el-button
                    @click="remove(scope.row)"
                    size="small"
                    type="danger"
                    >삭제</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <dk-el-pagination
              :totalDocs="totalDocs"
              :limit="limit"
              :pagingProc="pagingProc"
              ref="pagination"
            ></dk-el-pagination>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <dk-info-panel ref="infoPanel" width="440px">
      <div class="info-panel-wrap">
        <h3>{{ selectMaterialType.materialTypeName }}</h3>
        <div class="dk-card">
          <div class="dk-card-title">기자재 유형 정의 데이터</div>
          <table class="el-table el-table--mini">
            <tr>
              <th>데이터 명칭</th>
              <th>데이터 KEY</th>
              <th>데이터 유형</th>
            </tr>
            <tr
              v-for="(eData, index) in selectMaterialType.materialTypeData"
              :key="index"
            >
              <td>{{ eData.label ? eData.label : '정보 없음' }}</td>
              <td>{{ eData.key ? eData.key : '정보 없음' }}</td>
              <td>
                <span v-if="eData.formType == 'string'">텍스트</span>
                <span v-else-if="eData.formType == 'number'">숫자</span>
                <!-- <span v-else-if="eData.formType=='comobobox'">콤보박스</span> -->
                <span v-else>정보 없음</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </dk-info-panel>
    <form-material-type ref="formDialog"></form-material-type>
  </div>
</template>

<script>
// dataexample : {
//     "_id" : ObjectId("5bb9a2507451889cfd6a34d2"),
//     "materialTypeId" : "cctvtype1",
//     "materialTypeName" : "PTZ카메라",
//     "image1Url" : "http://localhost:3010/img/snp6320h.png",
//     "materialTypeData" : {
//         "resolution" : {
//             "key" : "resolution",
//             "value" : "",
//             "dataType" : "string",
//             "icon" : "fas fa-home"
//         },
//         "maker" : {
//             "key" : "maker",
//             "label" : "제조사",
//             "value" : "",
//             "dataType" : "string",
//             "icon" : "fas fa-home"
//         }
//     }
// }

import dkeltableheader from '@/components/dkElTableHeader.vue';
import dkinfopanel from '@/components/dkInfoPanel.vue';
import formmaterialtype from '@/core/forms/formMaterialType.vue';
import dkelpagination from '@/components/dkElPagination.vue';

export default {
  components: {
    'dk-el-table-header': dkeltableheader,
    'dk-info-panel': dkinfopanel,
    'form-material-type': formmaterialtype,
    'dk-el-pagination': dkelpagination,
  },
  created() {
    let me = this;
    this.getMaterialTypes(1);

    this.$on('procDataComplete', (materialType) => {
      this.getMaterialTypes();
    });
  },
  data() {
    return {
      materialTypes: [],
      limit: 12,
      totalDocs: 0,
      shownInfoPanel: false,
      selectMaterialType: {
        materialTypeName: [],
      },
    };
  },
  methods: {
    selectRow(row, event, column) {
      if (!this.$refs.infoPanel.isOpened()) {
        this.$refs.infoPanel.open();
      }
      this.selectMaterialType = row;
    },
    openFormDialog(row) {
      this.$refs.formDialog.showDialog(row);
    },
    remove(args) {
      this.$confirm(
        `${args.materialTypeName} 기자재 유형을 삭제 합니다.`,
        '경고',
        {
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          type: 'warning',
        }
      )
        .then(async () => {
          try {
            let response = await this.$http.post(
              `${this.$config.getServerConfig().core.api}/core/api/materialType/delete`,
              { data: args }
            );
            this.getMaterialTypes();
            this.$message({
              type: 'success',
              message: `${args.materialTypeName} 기자재 유형 삭제 완료`,
            });
            this.$refs.infoPanel.close();
          } catch (error) {
            this.$message({
              type: 'info',
              message: `${args.materialTypeName} 기자재 유형 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${args.materialTypeName} 기자재 유형 삭제 취소`,
          });
        });
    },
    pagingProc(pageNum) {
      this.getMaterialTypes();
    },
    async getMaterialTypes(pageNum) {
      try {
        let me = this;
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/materialType`, {
          params: {
            page: pageNum || this.$refs.pagination.getPage(),
            limit: this.limit,
          },
        });
        me.materialTypes = response.data.docs;
        me.totalDocs = response.data.totalDocs;
        me.$refs.infoPanel.close();
      } catch (error) {
        this.$message({
          type: 'info',
          message: `기자재유형표출 에러`,
        });
      }
    },
  },
};
</script>
