<template>
  <el-dialog
    id="formAssetTemplate"
    class="template-form-dialog"
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    @opened="createTemplateForm"
  >
    <div class="dk-form">
      <el-form label-position="left" :model="formData" label-width="170px" size="small" :rules="rules" ref="form0">
        <el-form-item label="템플릿 명칭" prop="templateName">
          <el-input v-model="formData.templateName" size="mini" placeholder="템플릿 명칭"></el-input>
        </el-form-item>
        <el-form-item label="템플릿 아이콘">
          <el-col :span="11">
            <span v-if="!formData.templateIcon">선택된 마커가 없습니다.</span>
            <img v-else :src="formData.templateIcon" style="height: 70px; margin-left: 15px; width: auto;" />
          </el-col>
          <el-col :span="13">
            <span><el-button @click="tampleteMarkerDialog = !tampleteMarkerDialog">아이콘 선택</el-button></span>
          </el-col>
        </el-form-item>
      </el-form>
      <el-dialog width="40%" :visible.sync="tampleteMarkerDialog" append-to-body title="템플릿 아이콘 선택" class="el-dialog-inner">
        <el-row>
          <el-col :span="4" v-for="(templeteIcon, index) in templeteIcons" :key="index">
            <span class="fclt-icon-grid-cell">
              <img :src="templeteIcon.templeteImage" />

              <el-popover placement="top" trigger="hover" ref="fcltIconPopover">
                <div>
                  <table class="el-table el-table--mini fclt-icon-preview">
                    <tr>
                      <td>
                        <!-- <img :src="templeteIcon.templeteImage" /> -->
                        asdasd
                      </td>
                    </tr>
                    <tr>
                      <th>ddd</th>
                    </tr>
                  </table>
                </div>
                <img class="fclt-icon-grid" slot="reference" :src="templeteIcons.templeteImage" />
              </el-popover>
            </span>
          </el-col>
        </el-row>
      </el-dialog>
    </div>
    <dk-form ref="dkTemplateForm" formType="template" @renderAfter="createTemplateForm"></dk-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="saveTemplateData">저장</el-button>
      <el-button @click="formDialog = false">닫기</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _ from 'lodash';
import dkTemplateForm from '@/components/dkTemplate/dkTemplateForm';

export default {
  components: {
    'dk-form': dkTemplateForm,
  },
  created() {
    this.getTempleteIcon();
  },
  data() {
    return {
      formDialog: false,
      formTitle: '자산 정보 템플릿 관리',
      templateData: [],
      templeteIcons: [],
      formData: {
        templateName: '',
        templateIcon: '',
        featureId: this.$route.meta.featureId,
      },
      updateFlag: false,
      rules: {
        templateName: [
          {
            required: true,
            message: '템플릿 명칭을 입력하여 주십시오',
            trigger: ['blur', 'change'],
          },
        ],
      },
      systemLogInfo: {},
      tampleteMarkerDialog: false,
    };
  },
  methods: {
    async getTempleteIcon() {
      let response = await this.$http.get(`coreapi/core/api/templeteIcon`, {});
      this.templeteIcons = response.data;
      debugger;
    },
    hideDialog() {
      this.templateData = [];
      this.formData = {
        templateName: '',
        templateData: [],
        featureId: this.$route.meta.featureId,
      };
      this.formDialog = false;
      this.$refs.dkTemplateForm.resetForm();
      this.$emit('registerdTemplate');
    },

    showDialog(featureId, updateData) {
      this.formDialog = true;
      if (updateData) {
        this.updateFlag = true;
        this.templateData = _.cloneDeep(updateData.templateData);
        this.formData = _.cloneDeep(updateData);
        // this.createTemplateForm();
        this.formData.templateData = [];
      }
    },
    createTemplateForm() {
      if (this.$refs.dkTemplateForm) {
        this.$refs.dkTemplateForm.createTemplateForm(this.templateData);
      }
    },
    saveTemplateData() {
      // console.log(this.templateData);
      try {
        this.$refs.form0.validate(async (valid) => {
          if (valid) {
            let saveFunc = 'template/add';
            if (this.updateFlag) {
              saveFunc = 'template/update';
            }
            this.systemLogInfo.page = `${this.$route.name} ${this.formData.templateName} 템플릿`;
            this.systemLogInfo.saveType = saveFunc;
            this.formData.templateData = this.$refs.dkTemplateForm.exportTemplateData();
            const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, { data: this.formData });
            this.hideDialog();
            this.$message({
              type: 'success',
              message: `템플릿 정보 등록 완료`,
            });
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
          }
        });
      } catch (err) {
        this.hideDialog();
        this.$message.error(`템플릿 정보 등록 중 에러 발생`);
      }
    },
  },
};
</script>
