<template>
  <div class="fill-width-height el-container">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <el-table
              :data="vmsLists"
              style="width: 100%; border-top: 1px solid #dedede;"
              empty-text="등록된 VMS 장비가 없습니다."
              size="mini"
              @row-click="selectRow"
              stripe
            >
              <el-table-column prop="fcltName" label="VMS 지점명" min-width="180px"></el-table-column>
              <el-table-column label="통신 상태" header-align="center" align="center" width="80px">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" content="통신 상태" placement="top">
                    <el-tag type="success">정상</el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="모듈 전원" header-align="center" align="center" width="80px">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" content="모듈 전원" placement="top">
                    <el-tag type="success">정상</el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="팬 동작" header-align="center" align="center" width="80px">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" content="팬 동작" placement="top">
                    <el-tag type="success">정상</el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="히터 동작" header-align="center" align="center" width="80px">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" content="히터 동작" placement="top">
                    <el-tag type="success">정상</el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="함체 팬" header-align="center" align="center" width="80px">
                <template slot-scope="scope">
                  <el-tooltip class="item" effect="dark" content="함체 팬 동작" placement="top">
                    <el-tag type="success">정상</el-tag>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
            <dk-el-pagination
              :totalDocs="totalDocs"
              :limit="limit"
              :pagingProc="pagingProc"
              ref="pagination"
            ></dk-el-pagination>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <dk-info-panel ref="infoPanel" width="400px">
      <div class="info-panel-wrap"></div>
    </dk-info-panel>
  </div>
</template>

<script>
import dkelpagination from '@/components/dkElPagination.vue';
import dkeltableheader from '@/components/dkElTableHeader.vue';
import dkinfopanel from '@/components/dkInfoPanel.vue';

export default {
  components: {
    'dk-el-table-header': dkeltableheader,
    'dk-info-panel': dkinfopanel,
    'dk-el-pagination': dkelpagination,
  },
  mounted() {
    this.getVmsLists(1);
  },
  data() {
    return {
      vmsLists: [],
      limit: 10,
      totalDocs: 0,
      selectVmsMonitoring: {},
    };
  },
  methods: {
    selectRow(row, event, column) {
      if (!this.$refs.infoPanel.isOpened()) {
        this.$refs.infoPanel.open();
      }
      this.selectVmsMonitoring = row;
    },
    openFormDialog(row) {
      this.$refs.formDialog.showDialog(row);
    },
    removeVmsMonitoring(args) {
      this.$confirm(`${args.vmsMonitoringName} VMS 스케줄을 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            await this.$http.post(`/core/api/vmsMonitoring/delete`, {
              data: args,
            });
            this.getVmsLibraries();
            this.$message({
              type: 'success',
              message: `${args.vmsMonitoringName} VMS 스케줄을 삭제 완료`,
            });
          } catch (err) {
            this.$message({
              type: 'info',
              message: `${args.vmsMonitoringName} VMS 스케줄을 삭제 중 에러 발생`,
            });
          }
        })

        .catch(() => {
          this.$message({
            type: 'info',
            message: `${args.vmsMonitoringName} VMS 스케줄 삭제 취소`,
          });
        });
    },
    async getVmsLists(pageNum, keyword) {
      let me = this;
      let param = {
        page: pageNum || this.$refs.pagination.getPage(),
        limit: this.limit,
      };
      if (keyword !== '' && keyword) {
        param.keyword = keyword;
      }
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsMonitoring`, {
        params: param,
      });
      me.vmsLists = response.data.docs;
      me.totalDocs = response.data.totalDocs;
      me.$refs.infoPanel.close();

      //   this.$store.dispatch('vmsMonitoring/getVmsMonitoring', param).then(() => {
      //     me.vmsLists = me.$store.getters['vmsMonitoring/getVmsMonitoring'];
      //     me.totalDocs = me.$store.getters['vmsMonitoring/getPagination'].totalDocs;
      //     me.$refs.infoPanel.close();
      //   });
    },
    search(keyword) {
      this.getVmsLists(null, keyword);
    },
    pagingProc(pageNum) {
      this.getVmsLists();
    },
  },
};
</script>

<style scoped></style>
