<template>
  <el-dialog
    id="formAiCameraInfo"
    class="template-form-dialog"
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    @opened="createTemplateForm"
  >
    <div class="dk-form">
      <el-form label-position="left" :model="formData" label-width="170px" size="small" :rules="rules" ref="form0">
        <el-form-item label="AI 카메라 명칭" prop="fcltTypeName">
          <el-input v-model="formData.fcltTypeName" size="mini" placeholder="AI 카메라 명칭"></el-input>
        </el-form-item>
        <el-form-item label="GIS 표출 아이콘">
          <el-col :span="11">
            <span v-if="!formData.gisMarkerImage">선택된 마커가 없습니다</span>
            <img v-else :src="formData.gisMarkerImage.normal" style="height: 70px; margin-left: 15px; width: auto;" />
          </el-col>
          <el-col :span="13">
            <el-button @click="gisMarkerDialog = !gisMarkerDialog">아이콘 선택</el-button>
          </el-col>
        </el-form-item>
      </el-form>
    </div>
    <dk-form ref="dkTemplateForm" formType="template" @renderAfter="createTemplateForm"></dk-form>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="saveTemplateData">저장</el-button>
      <el-button @click="formDialog = false">닫기</el-button>
    </span>
    <el-dialog width="40%" :visible.sync="gisMarkerDialog" append-to-body title="시설물 아이콘 선택" class="el-dialog-inner">
      <el-row>
        <el-col :span="4" v-for="(fcltIcon, index) in fcltIcons" :key="index">
          <span class="fclt-icon-grid-cell" @click="selectGisMarker(fcltIcon)">
            <el-popover placement="top" trigger="hover" ref="fcltIconPopover">
              <div>
                <table class="el-table el-table--mini fclt-icon-preview">
                  <tr>
                    <td>
                      <img :src="fcltIcon.gisMarkerImage.normal" />
                    </td>
                    <td>
                      <img :src="fcltIcon.gisMarkerImage.selected" />
                    </td>
                    <td>
                      <img :src="fcltIcon.gisMarkerImage.break" />
                    </td>
                    <td>
                      <img :src="fcltIcon.gisMarkerImage.repair" />
                    </td>
                  </tr>
                  <tr>
                    <th>일반</th>
                    <th>선택시</th>
                    <th>고장/사고</th>
                    <th>유지보수</th>
                  </tr>
                </table>
              </div>
              <img class="fclt-icon-grid" slot="reference" :src="fcltIcon.gisMarkerImage.normal" />
            </el-popover>
          </span>
        </el-col>
      </el-row>
    </el-dialog>
  </el-dialog>
</template>

<script>
import _ from 'lodash';
import dkTemplateForm from '@/components/dkTemplate/dkTemplateForm';

export default {
  components: {
    'dk-form': dkTemplateForm,
  },
  created() {
    this.getFcltIcon();
  },
  data() {
    return {
      formDialog: false,
      formTitle: '자산 정보 템플릿 관리',
      fcltTypeData: [],
      formData: {
        fcltTypeName: '',
        featureId: this.$route.meta.featureId,
        pluginType: 'aicamera',
      },
      updateFlag: false,
      rules: {
        fcltTypeName: [
          {
            required: true,
            message: '템플릿 명칭을 입력하여 주십시오',
            trigger: ['blur', 'change'],
          },
        ],
      },
      systemLogInfo: {},
      fcltIcons: [],
      gisMarkerDialog: false,
    };
  },
  methods: {
    async getFcltIcon() {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltIcon`, {});
      this.fcltIcons = response.data;
    },
    hideDialog() {
      this.fcltTypeData = [];
      this.formData = {
        fcltTypeName: '',
        fcltTypeData: [],
        featureId: this.$route.meta.featureId,
      };
      this.formDialog = false;
      this.$refs.dkTemplateForm.resetForm();
      this.$emit('registerdTemplate');
    },

    showDialog(updateData) {
      this.formDialog = true;
      if (updateData) {
        this.updateFlag = true;
        this.fcltTypeData = _.cloneDeep(updateData.fcltTypeData);
        this.formData = _.cloneDeep(updateData);
        // this.createTemplateForm();
        this.formData.fcltTypeData = [];
      }
    },
    createTemplateForm() {
      if (this.$refs.dkTemplateForm) {
        this.$refs.dkTemplateForm.createTemplateForm(this.fcltTypeData);
      }
    },
    saveTemplateData() {
      // console.log(this.fcltTypeData);
      try {
        this.$refs.form0.validate(async (valid) => {
          if (valid) {
            let saveFunc = 'fcltType/add';
            if (this.updateFlag) {
              saveFunc = 'fcltType/update';
            }
            this.systemLogInfo.page = `${this.$route.name} ${this.formData.fcltTypeName} 템플릿`;
            this.systemLogInfo.saveType = saveFunc;
            this.formData.fcltTypeData = this.$refs.dkTemplateForm.exportTemplateData();
            const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, { data: this.formData });
            this.hideDialog();
            this.$message({
              type: 'success',
              message: `AI 카메라 정보 등록 완료`,
            });
            this.$emit('saved', this.formData);
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
          }
        });
      } catch (err) {
        this.hideDialog();
        this.$message.error(`AI 카메라 정보 등록 중 에러 발생`);
      }
    },
    selectGisMarker(fcltIcon) {
      this.gisMarkerDialog = false;
      this.formData.gisMarkerHeight = fcltIcon.gisMarkerHeight;
      this.formData.gisMarkerImage = fcltIcon.gisMarkerImage;
      this.formData.gisMarkerOffsetX = fcltIcon.gisMarkerOffsetX;
      this.formData.gisMarkerOffsetY = fcltIcon.gisMarkerOffsetY;
      this.formData.gisMarkerWidth = fcltIcon.gisMarkerWidth;
    },
  },
};
</script>

<style scoped>
.fclt-icon-grid-cell {
  display: inline-block;
  height: 80px;
  padding: 3px 0;
  text-align: center;
  width: 100%;
}

.fclt-icon-grid-cell:hover {
  background-color: rgba(192, 192, 192, 0.3);
}

.fclt-icon-grid {
  display: inline-block;
  height: 68px;
  width: auto;
}

.fclt-icon-preview td,
.fclt-icon-preview th {
  text-align: center;
  width: 60px;
}

.fclt-icon-preview td > img {
  display: inline-block;
  height: 42px;
  width: auto;
}

.fclttype-form-wrap {
  box-sizing: border-box;
  min-height: 300px;
  padding-top: 20px;
}
</style>
