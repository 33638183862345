var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-layout", { attrs: { row: "", wrap: "" } }, [
        _vm._v(
          "\n    asdf\n    " +
            _vm._s(_vm.getDiff) +
            "\n    " +
            _vm._s(_vm.pageOK) +
            "\n  "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }