var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-set", staticStyle: { height: "100vh" } },
    [
      _c(
        "div",
        { staticClass: "top-bar-space" },
        [
          _c(
            "h3",
            {
              staticClass: "middle-title",
              staticStyle: { "margin-top": "65px" }
            },
            [
              _vm._v("\n      울산 북구 날씨\n      "),
              _c("span", { staticClass: "middle-title-date" }, [
                _vm._v(
                  _vm._s(_vm.baseTime ? "(" + _vm.baseTime + " 기준)" : "")
                )
              ])
            ]
          ),
          _c("div", { staticClass: "container-box mb-both-mg" }, [
            _c(
              "ul",
              { staticClass: "table-title-ul" },
              [
                _vm._m(0),
                _vm._l(_vm.weatherList, function(weatherContent) {
                  return _c(
                    "li",
                    { key: weatherContent.key, staticClass: "table-align" },
                    [
                      _c("span", [_vm._v(_vm._s(weatherContent.name))]),
                      _c("span", [
                        _c("img", {
                          staticStyle: { width: "40px" },
                          attrs: { src: weatherContent.weather }
                        })
                      ]),
                      _c("span", [_vm._v(_vm._s(weatherContent.temp))]),
                      _c("span", [_vm._v(_vm._s(weatherContent.rain))])
                    ]
                  )
                })
              ],
              2
            )
          ]),
          _c("div", { staticClass: "flex-box" }, [
            _c("h3", { staticClass: "middle-title" }, [
              _vm._v("울산 재난 발생 현황")
            ]),
            _c(
              "button",
              { staticClass: "btn-log", on: { click: _vm.disasterLog } },
              [_vm._v("지난 로그 보기")]
            )
          ]),
          _vm.specialReportList.length > 0
            ? _vm._l(_vm.specialReportList, function(report, idx) {
                return _c(
                  "div",
                  {
                    key: idx,
                    staticClass: "container-box mb-both-mg disaster-msg"
                  },
                  [
                    _c("div", { staticClass: "disaster-text" }, [
                      _c("span", { staticClass: "point" }, [_vm._v("알림")]),
                      _vm._v(_vm._s(report.msg))
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "disaster-text",
                        staticStyle: { color: "#999", "margin-left": "48px" }
                      },
                      [
                        _c("span", {}, [_vm._v("발효시각: ")]),
                        _vm._v(_vm._s(report.time))
                      ]
                    )
                  ]
                )
              })
            : [_vm._m(1)]
        ],
        2
      ),
      _c("form-disaster-log", { ref: "formDisasterLog" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "table-title table-align" }, [
      _c("span"),
      _c("span", [_vm._v("날씨")]),
      _c("span", [_vm._v("온도")]),
      _c("span", [_vm._v("강수량")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container-box mb-both-mg disaster-msg" }, [
      _c("span", { staticClass: "disaster-text" }, [
        _vm._v("현 지역에는 재난이 발생하지 않았습니다.")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }