var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-width-height el-container" },
    [
      _c(
        "el-container",
        { staticClass: "page-body" },
        [
          _c(
            "el-main",
            [
              _c(
                "el-row",
                { staticClass: "content-wrap" },
                [
                  _c("dk-page-header"),
                  _c(
                    "el-col",
                    { staticClass: "page-content", attrs: { span: 24 } },
                    [
                      _c("dk-el-table-header", {
                        attrs: {
                          openFormDialog: _vm.openFormDialog,
                          isSearch: true,
                          search: _vm.search
                        }
                      }),
                      _c(
                        "el-table",
                        {
                          staticStyle: {
                            width: "100%",
                            "border-top": "1px solid #dedede"
                          },
                          attrs: {
                            data: _vm.vmsMessages,
                            "empty-text": "등록된 VMS 메시지가 없습니다.",
                            size: "mini",
                            stripe: ""
                          },
                          on: { "row-click": _vm.selectRow }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "", "min-width": "150px" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c("img", {
                                      staticStyle: {
                                        height: "160px",
                                        width: "auto"
                                      },
                                      attrs: { src: scope.row.thumbImageFile }
                                    })
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "VMS 메시지 이름",
                              prop: "vmsMessageName",
                              "header-align": "center",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "VMS 메시지 유형",
                              "header-align": "center",
                              align: "center"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    scope.row.vmsMessageType == "1"
                                      ? _c("span", [_vm._v("홍보")])
                                      : scope.row.vmsMessageType == "2"
                                      ? _c("span", [_vm._v("교통정보")])
                                      : scope.row.vmsMessageType == "3"
                                      ? _c("span", [_vm._v("돌발")])
                                      : scope.row.vmsMessageType == "4"
                                      ? _c("span", [_vm._v("공사/행사")])
                                      : scope.row.vmsMessageType == "5"
                                      ? _c("span", [_vm._v("우회도로")])
                                      : _vm._e()
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "VMS 카테고리",
                              prop: "vmsCategory",
                              "header-align": "center",
                              align: "center"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: { label: "", width: "170px" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "small" },
                                        on: {
                                          click: function($event) {
                                            return _vm.openFormDialog(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("수정")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "danger"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.removeVmsMessage(
                                              scope.row
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("삭제")]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c("dk-el-pagination", {
                        ref: "pagination",
                        attrs: {
                          totalDocs: _vm.totalDocs,
                          limit: _vm.limit,
                          pagingProc: _vm.pagingProc
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("dk-info-panel", { ref: "infoPanel", attrs: { width: "400px" } }, [
        _c("div", { staticClass: "info-panel-wrap" })
      ]),
      _c("form-vms-message", { ref: "formDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }