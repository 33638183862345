var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize"
        }
      ],
      attrs: { wrap: "", row: "" }
    },
    [
      _c("v-flex", { attrs: { xs12: "", sm12: "" } }, [
        _c(
          "div",
          { staticClass: "detect-img-toolbar d-flex text-xs-right" },
          [
            _c("span", { staticClass: "detect-toolbar-title" }, [
              _vm._v(
                "\n              " +
                  _vm._s(_vm.detectProgressText) +
                  "\n            "
              )
            ]),
            _c(
              "v-tooltip",
              { attrs: { top: "" } },
              [
                _c(
                  "v-btn",
                  {
                    staticStyle: { margin: "0" },
                    attrs: { slot: "activator", icon: "" },
                    on: {
                      click: function($event) {
                        return _vm.mosaicRestore()
                      }
                    },
                    slot: "activator"
                  },
                  [
                    _c("v-icon", { attrs: { color: "white" } }, [
                      _vm._v("replay")
                    ])
                  ],
                  1
                ),
                _c("span", [_vm._v("모자이크 복구")])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("v-flex", { attrs: { xs12: "", id: "pixelateWrap", sm12: "" } }, [
        _c("canvas", {
          ref: "controlCanvas",
          attrs: { id: "controlCanvas" },
          on: {
            mousedown: _vm.onMousedown,
            mouseup: _vm.onMouseup,
            mousemove: _vm.onMousemove
          }
        }),
        _c("img", { ref: "image", attrs: { id: "image" } })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }