<template>
  <div class="fill-width-height el-container" id="pageFcltTypeInfo">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-button @click="addTemplate" type="primary"><i class="fas fa-plus-circle">&nbsp;추가</i></el-button>
          <el-col :span="24" class="page-content">
            <dk-el-table-header :openFormDialog="openFormDialog"></dk-el-table-header>
            <el-table
              :data="templateList"
              style="width: 100%;"
              empty-text="시설물 유형 정보가 없습니다."
              size="mini"
              @row-click="selectRow"
              stripe
              ref="fcltTypesGrid"
            >
              <el-table-column label fixed="left" width="60px">
                <template slot-scope="scope">
                  <span class="fclt-type-grid-icon">
                    <i v-bind:class="[scope.row.templateIcon ? scope.row.templateIcon + ' fa-3x' : '']"></i>
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="templateName" label="템플릿 명칭" fixed="left"></el-table-column>
              <el-table-column label width="400px" align="right">
                <template slot-scope="scope">
                  <el-button @click="updateTemplate(scope.row)" size="small">수정</el-button>
                  <el-button @click="deleteTemplate(scope.row)" size="small" type="danger">삭제</el-button>
                  <!-- <el-button v-if="scope.row.plugin" @click="dataRestore(scope.row)" size="small" type="info">복구</el-button> -->
                  <!-- <el-button type="primary" size="small" @click="maintnceTmplate(scope.row, $event)">유지관리 템플릿 설정</el-button> -->
                </template>
              </el-table-column>
            </el-table>
            <!-- <dk-el-pagination :totalDocs="totalDocs" :limit="limit" :pagingProc="pagingProc" ref="pagination"></dk-el-pagination> -->
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <dk-info-panel ref="infoPanel" width="440px">
      <div class="info-panel-wrap">
        <h3>{{ selectedTemplate.templateName }}</h3>
        <div class="dk-card">
          <div class="dk-card-title">템플릿 유형</div>
          <table class="el-table el-table--mini fclt-icon-preview">
            <tr>
              <td>템플릿 데이터</td>
            </tr>
            <tr v-for="(item, idx) in selectedTemplate.templateData" :key="idx">
              <th>
                {{ item.label }}
              </th>
              <th>
                {{ item.type }}
              </th>
            </tr>
          </table>
        </div>
      </div>
    </dk-info-panel>
    <form-asset-template ref="formAssetTemplate" @registerdTemplate="getTemplates" />
    <form-asset-dialog ref="formAssetDialog" @registeredAssetData="getAssets"></form-asset-dialog>
  </div>
</template>

<script>
import formAssetTemplate from '@/core/forms/formAssetTemplate.vue';
import formAssetDialog from '@/core/forms/formAssetDialog.vue';
import dkinfopanel from '@/components/dkInfoPanel.vue';
export default {
  components: {
    'form-asset-template': formAssetTemplate,
    'form-asset-dialog': formAssetDialog,
    'dk-info-panel': dkinfopanel,
  },
  created() {
    this.getTemplates();
  },
  destroyed() {},
  data() {
    return {
      systemLogInfo: {},
      assetList: [],
      templateList: [],
      selectedTemplate: null,
      formDialog: false,
      formData: {
        assetId: '',
        assetName: '',
        templateId: '',
        templateName: '',
        templateData: [],
        files: [],
        featureId: 'F43000',
      },
    };
  },
  mounted() {},
  updated() {},
  methods: {
    async getTemplates() {
      const param = {
        featureId: 'F43000',
      };
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/template`, {
        params: param,
      });
      this.templateList = response.data;
      if (this.templateList && this.templateList.length > 0) {
        this.selectedTemplate = this.templateList[0];
      }
      console.log(this.templateList);
    },
    selectRow(row, event, column) {
      if (!this.$refs.infoPanel.isOpened()) {
        this.$refs.infoPanel.open();
      }
      this.selectedTemplate = row;
    },
    addTemplate() {
      this.$refs.formAssetTemplate.showDialog(this.featureId);
    },
    updateTemplate(templateData) {
      this.$refs.formAssetTemplate.showDialog(this.$route.meta.featureId, templateData);
    },
    deleteTemplate(templateData) {
      this.systemLogInfo.page = `${this.$route.name} ${templateData.templateName} 템플릿`;
      this.systemLogInfo.saveType = 'delete';
      this.$confirm(`${templateData.templateName} 템플릿을 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/template/delete`, { data: templateData });
            this.$message({
              type: 'success',
              message: `${templateData.templateName} 템플릿 삭제 완료`,
            });
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
            await this.getTemplates();
            await this.getAssets();
          } catch (err) {
            this.$message({
              type: 'info',
              message: `${templateData.templateName} 템플릿 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${templateData.templateName} 템플릿 삭제 취소`,
          });
        });
    },
  },
};
</script>

<style></style>
