var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.formDialog,
        title: _vm.formTitle,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.formDialog = $event
        },
        close: _vm.hideDialog
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form0",
              attrs: {
                model: _vm.materialTypeDoc,
                "label-width": "160px",
                size: "medium",
                rules: _vm.rules,
                "label-position": _vm.$isMobile ? "top" : "right"
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "기자재 유형 명칭",
                            prop: "materialTypeName"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "기자재 유형 명칭" },
                            model: {
                              value: _vm.materialTypeDoc.materialTypeName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.materialTypeDoc,
                                  "materialTypeName",
                                  $$v
                                )
                              },
                              expression: "materialTypeDoc.materialTypeName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c("el-transfer", {
                attrs: {
                  data: _vm.datasets,
                  titles: ["데이터셋", "기자재 유형 데이터"]
                },
                model: {
                  value: _vm.tempMaterialTypeData,
                  callback: function($$v) {
                    _vm.tempMaterialTypeData = $$v
                  },
                  expression: "tempMaterialTypeData"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "form-bottom dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("저장")]
          ),
          _c("el-button", { on: { click: _vm.hideDialog } }, [_vm._v("취소")])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }