var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-width-height el-container" },
    [
      _c(
        "el-container",
        { staticClass: "page-body" },
        [
          _c(
            "el-container",
            [
              _c(
                "el-main",
                [
                  _c(
                    "el-row",
                    { staticClass: "content-wrap" },
                    [
                      _c("dk-page-header"),
                      _c("dk-el-table-header", {
                        attrs: {
                          openFormDialog: _vm.openFormDialog,
                          isSearch: false
                        }
                      }),
                      _c(
                        "el-col",
                        { staticClass: "page-content", attrs: { span: 24 } },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: {
                                width: "100%",
                                "border-top": "1px solid #dedede"
                              },
                              attrs: {
                                data: _vm.vmsSchedules,
                                "empty-text":
                                  "등록된 VMS 스케줄 정보가 없습니다.",
                                size: "mini",
                                stripe: ""
                              },
                              on: { "row-click": _vm.selectRow }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "스케줄 ID",
                                  "header-align": "left",
                                  align: "left",
                                  width: "80px",
                                  prop: "vmsScheduleId"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "스케줄 명칭",
                                  "header-align": "left",
                                  align: "left",
                                  prop: "vmsScheduleName"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "VMS크기",
                                  "header-align": "left",
                                  align: "left"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getVmsSize(
                                              scope.row.vmsMessageSize
                                            )
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "VMS 메시지 수",
                                  "header-align": "left",
                                  align: "left"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        scope.row.vmsMessages &&
                                        scope.row.vmsMessages.length > 0
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.vmsMessages.length
                                                )
                                              )
                                            ])
                                          : _c("span", [_vm._v("없음")])
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _c("el-table-column", {
                                attrs: { label: "", width: "170px" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { size: "small" },
                                            on: {
                                              click: function($event) {
                                                return _vm.openFormDialog(
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("수정")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              size: "small",
                                              type: "danger"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeVmsSchedule(
                                                  scope.row
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("삭제")]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _c("dk-el-pagination", {
                            ref: "pagination",
                            attrs: {
                              totalDocs: _vm.totalDocs,
                              limit: _vm.limit,
                              pagingProc: _vm.pagingProc
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("dk-info-panel", { ref: "infoPanel", attrs: { width: "480px" } }, [
            _c(
              "div",
              { staticClass: "info-panel-wrap" },
              [
                _c("h3", [
                  _vm._v(_vm._s(_vm.selectedVmsSchedule.vmsScheduleName))
                ]),
                _c("h6", [
                  _vm._v(
                    "VMS크기 : " +
                      _vm._s(
                        _vm.getVmsSize(_vm.selectedVmsSchedule.vmsMessageSize)
                      )
                  )
                ]),
                _vm._l(_vm.selectedVmsSchedule.vmsMessages, function(
                  item,
                  index
                ) {
                  return _c(
                    "div",
                    { key: index, staticClass: "dk-card" },
                    [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("img", {
                              staticStyle: { height: "auto", width: "100%" },
                              attrs: { src: item.thumbImageFile }
                            })
                          ]),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "text-align": "right" },
                              attrs: { span: 12 }
                            },
                            [
                              _c(
                                "h5",
                                { staticStyle: { margin: "15px 0 0 0" } },
                                [
                                  _vm._v(
                                    "표출시간 : " +
                                      _vm._s(item.displayTime) +
                                      "초"
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                })
              ],
              2
            )
          ])
        ],
        1
      ),
      _c("form-vms-figure-schedule", { ref: "formDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }