import { render, staticRenderFns } from "./pageEventManagement.vue?vue&type=template&id=53bf0157&scoped=true&"
import script from "./pageEventManagement.vue?vue&type=script&lang=js&"
export * from "./pageEventManagement.vue?vue&type=script&lang=js&"
import style0 from "./pageEventManagement.vue?vue&type=style&index=0&id=53bf0157&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53bf0157",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/dk-platform-frontend/dk-platform-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53bf0157')) {
      api.createRecord('53bf0157', component.options)
    } else {
      api.reload('53bf0157', component.options)
    }
    module.hot.accept("./pageEventManagement.vue?vue&type=template&id=53bf0157&scoped=true&", function () {
      api.rerender('53bf0157', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/core/pageEventManagement.vue"
export default component.exports