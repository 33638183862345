<template>
  <div class="fill-width-height el-container">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <el-row>
              <el-col :span="24" class="grid-header-menu" style="display: flex; justify-content: space-between;">
                <div>
                  <el-select v-model="searchKey" placeholder="검색 키" style="margin-right: 5px; width: 150px;">
                    <el-option v-for="(item, index) in searchList" :key="index" :label="item.label" :value="item.key"></el-option>
                  </el-select>

                  <el-input
                    placeholder="검색어를 입력해주세요"
                    v-model="searchInput"
                    style="margin-right: 5px; width: 350px;"
                    :class="{ logInput: islogInput }"
                    @keyup.enter.native="getSystemLogPageData(1)"
                  ></el-input>

                  <!-- <el-date-picker
                    :class="{ logDatePicker: islogDatePicker }"
                    v-model="searchDate"
                    type="daterange"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                  ></el-date-picker> -->
                  <el-button size="medium" class="icon-button-2" @click="getSystemLogPageData(1)" style="margin-right: 5px;">검색</el-button>
                </div>
                <!-- <el-button size="medium" class="icon-button-2" @click="downloadExcel" style="margin-right: 5px;">
                  <img src="../assets/img/excel_icon.png" />엑셀 출력
                </el-button> -->
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="24">
            <el-table :data="systemLog" empty-text="표출할 시스템 로그가 없습니다" size="mini" stripe>
              <el-table-column label="로그 내용" prop="logMsg"></el-table-column>
              <el-table-column label="사용자 명칭" prop="name" width="160" align="center" header-align="center"></el-table-column>
              <!-- <el-table-column label="로그일시" prop="logDate" width="160" align="center" header-align="center"></el-table-column> -->
            </el-table>
            <dk-el-pagination :totalDocs="totalDocs" :limit="limit" :pagingProc="pagingProc" ref="pagination"></dk-el-pagination>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import moment from 'moment';
import dkelpagination from '@/components/dkElPagination.vue';
import XLSX from 'xlsx';

export default {
  components: {
    'dk-el-pagination': dkelpagination,
  },
  data() {
    return {
      limit: 15,
      totalDocs: 0,
      systemLog: [],

      islogInput: false,
      islogDatePicker: true,
      searchKey: 'logContents',
      searchList: [
        {
          label: '로그 내용',
          key: 'logContents',
        },
        {
          label: '사용자 명칭',
          key: 'userName',
        },
      ],
      searchInput: '',
      pickerOptions: {
        shortcuts: [
          {
            text: 'Last week',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last month',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: 'Last 3 months',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      searchDate: '',
    };
  },
  created() {
    // this.getSystemLog();
    this.getSystemLogPageData(1);
  },
  methods: {
    getFormatDate(date) {
      if (date) return moment(date).format('YYYY-MM-DD LT');
      else return '';
    },
    getSystemLog(args = {}) {
      const params = {
        params: {
          ...args,
        },
      };
      switch (this.searchKey) {
        case 'userName': {
          this.searchInput === '' || (params.params.name = this.searchInput);
          break;
        }
        case 'logContents': {
          this.searchInput === '' || (params.params.logMsg = this.searchInput);
          break;
        }
        case 'logDate': {
          params.params.logDateMin = this.searchDate[0];
          params.params.logDateMax = this.searchDate[1];
          break;
        }
      }
      if(params.params.logMsg ||params.params.name){
        return this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/statistics/searchSystemlog`, params)
        .then((response) => {
          this.systemLog = response.data.docs;
          this.totalDocs = response.data.totalDocs;
          console.log(response);
        });
      }else{
        return this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/statistics/systemlog`, params)
        .then((response) => {
          this.systemLog = response.data.docs;
          this.totalDocs = response.data.totalDocs;
          console.log(response);
        });
      }
    },

    getSystemLogPageData(pageNum) {
      const params = {
        page: pageNum || this.$refs.pagination.getPage(),
        limit: this.limit,
      };
      return this.getSystemLog(params)
      // .then((response) => {
      //   debugger
      //   this.systemLog = response.data.docs;
      //   this.totalDocs = response.data.totalDocs;
      //   console.log(response);
      // });
    },
    pagingProc(pageNum) {
      this.getSystemLogPageData();
    },

    async downloadExcel() {
      // 데이터 조회
      const logData = await this.getSystemLog();

      //
      const dt = new Date();
      const fileName = `systemLog_${dt.getFullYear()}${dt.getMonth() + 1}${dt.getDate()}${dt.getHours()}${dt.getMinutes()}${dt.getSeconds()}.xlsx`;

      // 엑셀
      // const excelData = logData.data.docs.map((data) => {
      //   const { userid, name, logMsg, logDate } = data;
      //   return {
      //     유저아이디: userid,
      //     유저명: name,
      //     내용: logMsg,
      //     시각: logDate,
      //   };
      // });

      // const wb = XLSX.utils.book_new();
      // // XLSX.utils.json_to_sheet(excelData, header);
      // XLSX.utils.json_to_sheet(excelData);
      // // XLSX.utils.json_to_sheet(excelData);
      // // let ws = XLSX.utils.json_to_sheet(excelData, header);
      // let ws = XLSX.utils.json_to_sheet(excelData);
      // XLSX.utils.book_append_sheet(wb, ws);
      // XLSX.writeFile(wb, fileName, { bookType: 'xlsx', type: 'array' });
    },
  },
  watch: {
    searchKey(val) {
      if (val === 'logDate') {
        this.islogInput = true;
        this.islogDatePicker = false;
      } else {
        this.islogInput = false;
        this.islogDatePicker = true;
      }
    },
  },
};
</script>
