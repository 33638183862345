var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-width-height el-container" },
    [
      _c(
        "el-container",
        { staticClass: "page-body" },
        [
          _c(
            "el-main",
            [
              _c(
                "el-row",
                { staticClass: "content-wrap" },
                [
                  _c("dk-page-header"),
                  _c(
                    "el-col",
                    { staticClass: "page-content", attrs: { span: 24 } },
                    [
                      _c("dk-el-table-header", {
                        attrs: { openFormDialog: _vm.openFormDialog }
                      }),
                      _c(
                        "el-table",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.materialTypes,
                            "empty-text": "기자재 유형 정보가 없습니다.",
                            size: "mini",
                            stripe: ""
                          },
                          on: { "row-click": _vm.selectRow }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "materialTypeName",
                              label: "기자재 유형 명칭",
                              fixed: "left"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "metaKeyword", label: "메타 키워드" }
                          }),
                          _c("el-table-column", {
                            attrs: { label: "", width: "170px" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "small" },
                                        on: {
                                          click: function($event) {
                                            return _vm.openFormDialog(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("수정")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "danger"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.remove(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("삭제")]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c("dk-el-pagination", {
                        ref: "pagination",
                        attrs: {
                          totalDocs: _vm.totalDocs,
                          limit: _vm.limit,
                          pagingProc: _vm.pagingProc
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("dk-info-panel", { ref: "infoPanel", attrs: { width: "440px" } }, [
        _c("div", { staticClass: "info-panel-wrap" }, [
          _c("h3", [_vm._v(_vm._s(_vm.selectMaterialType.materialTypeName))]),
          _c("div", { staticClass: "dk-card" }, [
            _c("div", { staticClass: "dk-card-title" }, [
              _vm._v("기자재 유형 정의 데이터")
            ]),
            _c(
              "table",
              { staticClass: "el-table el-table--mini" },
              [
                _c("tr", [
                  _c("th", [_vm._v("데이터 명칭")]),
                  _c("th", [_vm._v("데이터 KEY")]),
                  _c("th", [_vm._v("데이터 유형")])
                ]),
                _vm._l(_vm.selectMaterialType.materialTypeData, function(
                  eData,
                  index
                ) {
                  return _c("tr", { key: index }, [
                    _c("td", [
                      _vm._v(_vm._s(eData.label ? eData.label : "정보 없음"))
                    ]),
                    _c("td", [
                      _vm._v(_vm._s(eData.key ? eData.key : "정보 없음"))
                    ]),
                    _c("td", [
                      eData.formType == "string"
                        ? _c("span", [_vm._v("텍스트")])
                        : eData.formType == "number"
                        ? _c("span", [_vm._v("숫자")])
                        : _c("span", [_vm._v("정보 없음")])
                    ])
                  ])
                })
              ],
              2
            )
          ])
        ])
      ]),
      _c("form-material-type", { ref: "formDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }