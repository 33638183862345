<template>
  <div class="fill-width-height el-container">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <el-row>
              <el-col :span="24" class="grid-header-menu"></el-col>
            </el-row>

            <el-row>
              <el-col :span="12">
                <el-form ref="form" :model="formSearch" label-width="120px" size="mini">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="시설물 유형">
                        <el-select v-model="formSearch.selectFcltTypeId" placeholder="시설물 유형" :multiple="true" style="width: 260px;">
                          <el-option v-for="item in fcltType" :key="item.fcltTypeId" :label="item.fcltTypeName" :value="item.fcltTypeId"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="진행상태">
                        <el-select v-model="formSearch.selectMaintnceSummary" placeholder="유지관리 진행상태" :multiple="true" style="width: 100%;">
                          <el-option v-for="(item, key) in maintnceSteps" :key="key" :label="item.label" :value="item.status"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="유지관리 시작일">
                        <el-col :span="11">
                          <el-date-picker
                            type="date"
                            placeholder="검색 시작일"
                            v-model="formSearch.searchStartDate"
                            style="width: 100%;"
                          ></el-date-picker>
                        </el-col>
                        <el-col :span="1" align="center">-</el-col>
                        <el-col :span="11">
                          <el-date-picker placeholder="검색 종료일" v-model="formSearch.searchEndDate" style="width: 100%;"></el-date-picker>
                        </el-col>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </el-col>
              <el-col :span="2">
                <el-button style="margin-left: 10px; padding-bottom: 30px; padding-top: 30px; width: 90%;" type="primary" @click="getMaintnceLog(1)"
                  >검색</el-button
                >
              </el-col>
              <el-col :span="8" class="grid-header-menu">
                <el-button size="medium" class="icon-button-2" @click="downloadExportExcel" style="margin-right: 5px; margin-top: 30px;">
                  <img src="../assets/img/excel.png" style="height: auto; width: 30px;" />엑셀 출력
                </el-button>
                <el-button size="medium" class="icon-button-2" @click="openMaintnceLogPdf" style="margin-top: 30px;">
                  <img src="../assets/img/pdf.png" style="height: auto; width: 30px;" />PDF 출력
                </el-button>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="24">
            <el-table :data="maintnceLogs" empty-text="표출할 유지관리 이력이 없습니다" size="mini" @row-click="selectMaintnceLogRow" stripe>
              <el-table-column label="시설물 명칭" prop="fcltName" width="200"></el-table-column>
              <el-table-column label="시설물 유형" prop="fcltTypeName" width="160"></el-table-column>
              <el-table-column label="작업내용 요약" prop="maintnceSummary"></el-table-column>
              <el-table-column label="진행 상태" align="center" width="130">
                <template slot-scope="scope">
                  <el-tag :type="getMaintnceStatusType(scope.row.status)">
                    {{ getMaintnceStatusText(scope.row.status) }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column label="시작일" align="center" width="160">
                <template slot-scope="scope">{{ getFormatDate(scope.row.startDate) }}</template>
              </el-table-column>
              <el-table-column label="종료일" align="center" width="160">
                <template slot-scope="scope">{{ getFormatDate(scope.row.endDate) }}</template>
              </el-table-column>
            </el-table>
            <dk-el-pagination :totalDocs="totalDocs" :limit="limit" :pagingProc="pagingProc" ref="pagination"></dk-el-pagination>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import XLSX from 'xlsx';
import dkelpagination from '@/components/dkElPagination.vue';
import formmaintnce from '@/core/forms/formMaintnce.vue';
import formmaintncepdf from '@/core/forms/formMaintncePdf.vue';
import moment from 'moment';

export default {
  components: {
    'dk-el-pagination': dkelpagination,
    'form-maintnce': formmaintnce,
    'form-maintnce-pdf': formmaintncepdf,
  },
  data() {
    return {
      limit: 12,
      totalDocs: 0,
      maintnceLogs: [],
      maintnceLogDialog: false,
      selectMaintnceLog: {},
      imagePreviewDialog: false,
      imagePreviewUrl: '',
      formSearch: {
        selectFcltTypeId: '',
        selectMaintnceSummary: '',
        startDate: moment().add('year', -1).set('hour', 0).set('minute', 0).set('second', 0).toDate(),
        endDate: moment().set('hour', 23).set('minute', 59).set('second', 59).toDate(),
      },
      maintnceSteps: this.$config.getSiteConfig().maintnceStatus,
      fcltType: [],
    };
  },
  created() {
    this.getMaintnceLog(1);
    this.$store.dispatch('fcltType/getFcltType', {}).then(() => {
      this.fcltType = this.$store.getters['fcltType/getFcltType'];
    });
  },
  methods: {
    getFormatDate(date) {
      if (date) return moment(date).format('YYYY-MM-DD LT');
      else return '';
    },
    async getMaintnceLog(pageNum) {
      // formSearch: {
      //   selectFcltTypeId:"",
      //   selectMaintnceSummary: "",
      //   startDate:moment().year(2019),
      //   endDate:moment()
      // },
      let param = {
        page: pageNum || this.$refs.pagination.getPage(),
        limit: this.limit,
      };
      // if (this.formSearch.selectFcltTypeId && this.formSearch.selectFcltTypeId.length > 0) param.fcltTypeId = this.formSearch.selectFcltTypeId;
      if (this.formSearch.selectFcltTypeId && this.formSearch.selectFcltTypeId.length > 0) param.fcltTypeId = this.formSearch.selectFcltTypeId[0];
      if (this.formSearch.selectMaintnceSummary && this.formSearch.selectMaintnceSummary.length > 0)
        param.status = this.formSearch.selectMaintnceSummary;
      if (this.formSearch.searchStartDate) param.searchStartDate = this.formSearch.searchStartDate.getTime();
      if (this.formSearch.searchEndDate) {
        // param.endDate = this.formSearch.endDate.getTime()
        param.searchEndDate = moment(this.formSearch.searchEndDate).set('hour', 23).set('minute', 59).set('second', 59).toDate().getTime();
      }
      let res = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/maintnceLog`, {
        params: param,
      });
      this.maintnceLogs = res.data.docs;
      this.totalDocs = res.data.totalDocs;
    },
    openFormDialog(row) {
      this.$refs.formDialog.showDialog(row);
    },

    selectMaintnceLogRow(row) {
      // this.maintnceLogDialog = true;
      // this.selectMaintnceLog = row;
      this.$http
        .get(`${this.$config.getServerConfig().core.api}/core/api/maintnceDetailLog`, {
          params: {
            id: row.id,
          },
        })
        .then((response) => {
          this.maintnceLogDialog = true;
          this.selectMaintnceLog = response.data;
        });
    },
    pagingProc() {
      this.getMaintnceLog();
    },
    updateModalMaintnceLog() {
      this.maintnceLogDialog = false;
      // console.log(this.selectMaintnceLog)
      if (this.selectMaintnceLog && this.selectMaintnceLog.id) {
        this.$refs['formMaintnce'].showDialog(this.selectMaintnceLog);
      }
    },
    parseHtmlContent(content) {
      if (content) return content.replace(/(\n|\r\n)/g, '<br>');
      else return null;
    },
    handlePictureCardPreview(file) {
      this.imagePreviewUrl = file.url;
      this.imagePreviewDialog = true;
    },
    getManagerInfo(managerInfo) {
      if (managerInfo) {
        return `${managerInfo.name} (${managerInfo.phone})`;
      } else {
        return '';
      }
    },
    getWorkerInfo(workerInfo) {
      if (workerInfo) {
        return `${workerInfo.name} (${workerInfo.phone})`;
      } else {
        return '';
      }
    },
    getMaintnceStatusType(status) {
      if (status === 'step1') {
        return 'danger';
      } else if (status === 'complete') {
        return '';
      } else if (status === 'finish') {
        return 'success';
      } else {
        return 'warning';
      }
    },
    getMaintnceStatusText(status) {
      let r = this.maintnceSteps.find((s) => {
        return s.status === status;
      });
      if (r) return r.label;
      else '';
    },
    async downloadExportExcel() {
      let headerData = [
        { key: 'fcltTypeName', label: '시설물 유형' },
        { key: 'fcltName', label: '시설물 명칭' },
        { key: 'maintnceSummary', label: '작업 내용 요약' },
        { key: 'maintnceDesc', label: '작업 내용 설명' },
        { key: 'status', label: '진행 상태' },
        { key: 'workType', label: '작업 유형' },
        { key: 'importance', label: '중요도' },
        { key: 'startDate', label: '시작 일시' },
        { key: 'endDate', label: '종료 일시' },
        { key: 'managerName', label: '담당자' },
        { key: 'workerName', label: '작업자' },
      ];
      try {
        let param = {};

        if (this.formSearch.selectFcltTypeId && this.formSearch.selectFcltTypeId.length > 0) param.fcltTypeId = this.formSearch.selectFcltTypeId;
        if (this.formSearch.selectMaintnceSummary && this.formSearch.selectMaintnceSummary.length > 0)
          param.status = this.formSearch.selectMaintnceSummary;
        if (this.formSearch.searchStartDate) param.searchStartDate = this.formSearch.searchStartDate.getTime();
        if (this.formSearch.searchEndDate) {
          // param.endDate = this.formSearch.endDate.getTime()
          param.searchEndDate = moment(this.formSearch.searchEndDate).set('hour', 23).set('minute', 59).set('second', 59).toDate().getTime();
        }

        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/maintnceLog/excel`, {
          params: param,
        });
        var header = [];
        var label = {};
        var data = [];
        for (var i = 0; i < headerData.length; i++) {
          header.push({
            key: i,
            name: headerData[i].key,
          });
          label[headerData[i].key] = headerData[i].label;
        }
        data.push(label);
        var newData = this.$lodash.union(data, response.data.docs);
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(newData, header);
        ws['!rows'] = [];
        ws['!rows'][0] = { hidden: true };
        XLSX.utils.book_append_sheet(wb, ws);
        XLSX.writeFile(wb, `${moment().format('YYYY-MM-DD')}_유지관리이력.xlsx`, { bookType: 'xlsx', type: 'array' });
      } catch (err) {
        console.error(err);
      }
    },
    openMaintnceLogPdf() {
      let param = {};
      if (this.formSearch.selectFcltTypeId && this.formSearch.selectFcltTypeId.length > 0) param.fcltTypeId = this.formSearch.selectFcltTypeId;
      if (this.formSearch.selectMaintnceSummary && this.formSearch.selectMaintnceSummary.length > 0)
        param.status = this.formSearch.selectMaintnceSummary;
      if (this.formSearch.searchStartDate) param.searchStartDate = this.formSearch.searchStartDate.getTime();
      if (this.formSearch.searchEndDate) {
        // param.endDate = this.formSearch.endDate.getTime()
        param.searchEndDate = moment(this.formSearch.searchEndDate).set('hour', 23).set('minute', 59).set('second', 59).toDate().getTime();
      }

      this.$refs.formMaintncePdf.showDialog(param);
    },
  },
};
</script>

<style scoped>
.maintnce-log-message {
  font-size: 20px;
  height: 48px;
  line-height: 46px;
}

.maintnce-image-wrap {
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}

.maintnce-image {
  box-sizing: border-box;
  height: auto;
  padding: 3px 5px;
  width: 100%;
}
</style>
