<template>
  <div id="vmsMessageEditor">
    <div class="vms-editor-toolbar">
      <div class="toolbar-panel">
        <el-tooltip class="item" effect="dark" content="비트맵 컬러" placement="top-start">
          <el-select v-model="bmpColor" size="mini" style="float: left;">
            <el-option label="4비트 (4색)" value="4bit"></el-option>
            <el-option label="24비트 (풀컬러)" value="24bit"></el-option>
          </el-select>
        </el-tooltip>
      </div>
      <div class="toolbar-panel">
        <el-tooltip class="item" effect="dark" content="미리보기" placement="top">
          <el-button size="mini" icon="fas fa-desktop" class="toolbar-button" @click="preview">미리보기</el-button>
        </el-tooltip>
      </div>
    </div>
    <div class="vms-editor-toolbar">
      <div class="toolbar-panel">
        <el-tooltip class="item" effect="dark" content="텍스트 추가" placement="top-start">
          <el-button size="mini" icon="fas fa-pen" class="toolbar-button" @click="setMode(ADD_BLOCK_TEXT_OBJECT)"></el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="텍스트 라인" placement="top-start">
          <el-button size="mini" icon="fas fa-edit" class="toolbar-button" @click="setMode(ADD_LINE_TEXT_OBJECT)"></el-button>
        </el-tooltip>
      </div>
      <div class="toolbar-panel" v-show="type == 'figure'">
        <el-tooltip class="item" effect="dark" content="선형 추가" placement="top-start">
          <el-button size="mini" icon="fas fa-grip-lines" class="toolbar-button" @click="setMode(ADD_LINE_OBJECT)"></el-button>
        </el-tooltip>
      </div>

      <div class="toolbar-panel" ref="lineObjectToolbar" v-if="showLineObjectToolbar">
        <el-tooltip v-if="this.bmpColor == '24bit'" class="item" effect="dark" content="선형 색상" placement="top">
          <el-color-picker
            v-model="selectObject.strokeColor"
            @change="changeStrokeColor"
            size="mini"
            style="float: left; margin-right: 6px;"
          ></el-color-picker>
        </el-tooltip>
        <div v-else style="float: left;">
          <el-tooltip class="item" effect="dark" content="4색 빨강" placement="top">
            <div class="color-box">
              <span @click="changeStrokeColor('#ff0000')" style="background-color: #f00;"></span>
            </div>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="4색 노랑" placement="top">
            <div class="color-box">
              <span @click="changeStrokeColor('#ffff00')" style="background-color: #ff0;"></span>
            </div>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="4색 녹색" placement="top">
            <div class="color-box">
              <span @click="changeStrokeColor('#00ff00')" style="background-color: #0f0;"></span>
            </div>
          </el-tooltip>
        </div>

        <el-tooltip class="item" effect="dark" content="화살표 추가" placement="top-start">
          <el-button size="mini" icon="fas fa-arrow-right" class="toolbar-button" @click="setIsEndArrow"></el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="터널 선형" placement="top-start">
          <el-button size="mini" icon="fas fa-archway" class="toolbar-button" @click="setIsTunnel"></el-button>
        </el-tooltip>
        <!-- <el-select 
                    v-model="selectObject.strokeWidth"
                    @change="changeLineWidth"
                    size="mini"           
                    style="width:80px; float:left; margin-right:5px;"
        >-->
        <el-select v-model="selectObject.strokeWidth" size="mini" style="float: left; margin-right: 5px; width: 80px;">
          <el-option label="4px" :value="4"></el-option>
          <el-option label="8px" :value="8"></el-option>
          <el-option label="12px" :value="12"></el-option>
          <el-option label="16px" :value="16"></el-option>
          <el-option label="20px" :value="20"></el-option>
          <el-option label="26px" :value="26"></el-option>
          <el-option label="30px" :value="30"></el-option>
          <el-option label="36px" :value="36"></el-option>
        </el-select>
      </div>
      <!-- <div
        class="toolbar-panel"
        ref="lineObjectToolbar"
        v-if="showLineObjectGroupToolbar"
      >
        <el-tooltip
          class="item"
          effect="dark"
          content="선형 그룹"
          placement="top-start"
        >
          <el-button
            size="mini"
            icon="far fa-object-group"
            class="toolbar-button"
            @click="setMakeLineGroup"
          >
          </el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="선형 그룹 취소"
          placement="top-start"
        >
          <el-button
            size="mini"
            icon="far fa-object-ungroup"
            class="toolbar-button"
            @click="setCancleLineGroup"
          >
          </el-button>
        </el-tooltip>
      </div>-->
      <div class="toolbar-panel" ref="textObjectToolbar" v-if="showTextObjectToolbar">
        <el-tooltip class="item" effect="dark" content="필인 텍스트 선택" placement="top">
          <el-select
            v-model="selectObject.text"
            @change="changeFillinText"
            v-show="selectObject && selectObject.objectType === 'text' && selectObject.isFillin"
            size="mini"
            style="float: left; width: 120px;"
          >
            <el-option v-for="(item, index) in fillinTextData" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-tooltip>
        <el-tooltip v-if="this.bmpColor == '24bit'" class="item" effect="dark" content="텍스트 색상" placement="top">
          <el-color-picker
            v-model="selectObject.fontColor"
            @change="changeTextColor"
            size="mini"
            style="float: left; margin-right: 6px;"
            @mousedown="selectColorPicker"
          ></el-color-picker>
        </el-tooltip>
        <div style="float: left;">
          <el-tooltip class="item" effect="dark" content="4색 빨강" placement="top">
            <div class="color-box">
              <span @click="changeTextColor('#ff0000')" style="background-color: #f00;" @mousedown="preventDefault"></span>
            </div>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="4색 노랑" placement="top">
            <div class="color-box">
              <span @click="changeTextColor('#ffff00')" style="background-color: #ff0;" @mousedown="preventDefault"></span>
            </div>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="4색 녹색" placement="top">
            <div class="color-box">
              <span @click="changeTextColor('#00ff00')" style="background-color: #0f0;" @mousedown="preventDefault"></span>
            </div>
          </el-tooltip>
        </div>

        <el-select v-model="selectObject.fontSize" @change="changeTextSize" size="mini" style="float: left; margin-right: 5px; width: 80px;">
          <el-option label="24px" :value="24"></el-option>
          <el-option label="32px" :value="32"></el-option>
          <el-option label="36px" :value="36"></el-option>
          <el-option label="40px" :value="40"></el-option>
          <el-option label="48px" :value="48"></el-option>
          <el-option label="56px" :value="56"></el-option>
          <el-option label="64px" :value="64"></el-option>
          <el-option label="70px" :value="70"></el-option>
        </el-select>

        <el-tooltip class="item" effect="dark" content="글꼴" placement="top">
          <el-select v-model="selectObject.fontFamily" @change="changeFontFamily" size="mini" style="float: left; margin-right: 5px; width: 120px;">
            <el-option label="나눔고딕" value="NanumGothic"></el-option>
            <el-option label="돋움" value="dotum"></el-option>
            <el-option label="고딕" value="gothic"></el-option>
            <el-option label="바탕" value="batang"></el-option>
            <el-option label="궁서" value="gungsuh"></el-option>
            <!-- <el-option label="명조" value="myeongjo"></el-option> -->
          </el-select>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="폰트 굵기" placement="top">
          <el-button
            size="mini"
            icon="fas fa-bold"
            class="toolbar-button"
            :style="selectObject.fontWeight == 'bold' ? 'color:#8a8a8a' : 'color:#bfbfbf'"
            @click="changeFontWeight"
          ></el-button>
        </el-tooltip>
      </div>
      <div class="toolbar-panel" ref="textAlignToolbar" v-if="showLineTextObjectToolbar">
        <el-tooltip class="item" effect="dark" content="좌측 정렬" placement="top-start">
          <el-button size="mini" icon="fas fa-align-left" class="toolbar-button" @click="setTextAlignLeft"></el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="가운데 정렬" placement="top-start">
          <el-button size="mini" icon="fas fa-align-center" class="toolbar-button" @click="setTextAlignCenter"></el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" content="우측 정렬" placement="top-start">
          <el-button size="mini" icon="fas fa-align-right" class="toolbar-button" @click="setTextAlignRight"></el-button>
        </el-tooltip>
      </div>

      <!-- <el-tooltip class="item" effect="dark" content="미리보기" placement="top">
                    <el-button              
                    size="mini"                                  
                    class="toolbar-button"
                    @click="exportData"
                    >
                        데이터 출력
                    </el-button>
      </el-tooltip>-->
    </div>
    <div ref="canvasWrap" class="canvas-wrap" @mouseup="mouseup">
      <canvas class="dummy-canvas" ref="dummyCanvas" :width="canvasWidth" :height="canvasHeight" :viewBox="canvasViewBox"></canvas>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        stroke="none"
        fill="none"
        overflow="hidden"
        preserveAspectRatio="none"
        class="editor-canvas"
        :width="canvasWidth"
        :height="canvasHeight"
        :viewBox="canvasViewBox"
        ref="vmsMessageSvg"
        @click="clickEditor"
        @mousedown="mousedownEditor"
        @mouseup="mouseupEditor"
        @mousemove="mousemoveEditor"
      >
        <g>
          <rect :width="canvasWidth" :height="canvasHeight" style="fill: #000;" class="rect-background" />
        </g>
        <text-object
          v-for="item in vmsMessageData.textData"
          :key="item.objectId"
          :inputX="item.inputX"
          :inputY="item.inputY"
          :objectId="item.objectId"
          :isFillin="item.isFillin"
          :objectData="item"
          :bmpColor="bmpColor"
          ref="textObject"
        />

        <text-block-object
          v-for="item in vmsMessageData.textBlockData"
          :key="item.objectId"
          :inputX="item.inputX"
          :inputY="item.inputY"
          :objectId="item.objectId"
          :isFillin="item.isFillin"
          :objectData="item"
          :bmpColor="bmpColor"
          ref="textBlockObject"
        />

        <image-object
          v-for="item in vmsMessageData.imageData"
          :key="item.objectId"
          :inputX="item.inputX"
          :inputY="item.inputY"
          :objectId="item.objectId"
          :imageUrl="item.imageUrl"
          :objectData="item"
          :bmpColor="bmpColor"
          ref="imageObject"
        />
        <line-object
          v-for="item in vmsMessageData.lineData"
          :key="item.objectId"
          :objectId="item.objectId"
          :objectData="item"
          :bmpColor="bmpColor"
          ref="lineObject"
        />
        <line
          :x1="tempLineObject.x1"
          :y1="tempLineObject.y1"
          :x2="tempLineObject.x2"
          :y2="tempLineObject.y2"
          stroke="#00ff00"
          stroke-width="10"
          v-show="isLineDraw"
        />
      </svg>
      <div v-show="isDragObject" class="drag-mask" @mousemove="mousemove"></div>
      <div v-show="isDragObject && this.selectObject.objectType === 'text'" class="drag-box" :style="[dragBoxStyle]" ref="dragBox"></div>
    </div>
    <div v-if="showTextObjectToolbar">
      <el-tag v-for="data in fillinTextData" :key="data.value" type="info" size="small" style="margin: 4px 2px;">{{ data.label }}</el-tag>
    </div>
    <el-dialog title="VMS 메시지 미리보기" :visible.sync="previewDialog" @open="procPreviewImage" append-to-body>
      <img ref="previewImage" style="height: auto; width: 100%;" />
    </el-dialog>
  </div>
</template>

<script>
import uuid from 'uuid/v1';

export const NONE = 0;
export const ADD_BLOCK_TEXT_OBJECT = 1;
export const ADD_LINE_TEXT_OBJECT = 11;
export const ADD_FILLIN_TEXT_OBJECT = 2;
export const ADD_IMAGE_OBJECT = 3;
export const ADD_LINE_OBJECT = 4;
export const ADD_ARROW_LINE_OBJECT = 5;

export const actionsEnum = {
  None: 0,
  LeftResize: 1,
  TopResize: 2,
  RightResize: 3,
  BottomResize: 4,
  TopLeftResize: 5,
  BottomLeftResize: 6,
  TopRightResize: 7,
  BottomRightResize: 8,
  Move: 9,
};

export const fillinTextData = [
  { value: '@01', label: '@시점명' },
  { value: '@02', label: '@종점명' },
  { value: '@03', label: '@소통상황' },
  { value: '@11', label: '@도로명' },
  { value: '@04', label: '@시간' },
  { value: '@05', label: '@우회도로' },
  { value: '@06', label: '@돌발유형' },
  { value: '@07', label: '@발생장소' },
  { value: '@08', label: '@통제시점' },
  { value: '@09', label: '@통제종점' },
  { value: '@10', label: '@OO차로 차단' },
];

let textBlockObject = {
  template: `
        <g :id="objectId"
            :transform="getMatrix"
            @mousedown="selectObject"            
        >
            <g @mousedown="dragStart" transform="translate(-15 0)">
                <rect 
                    :style="[boxStyle1]"
                    :width="strokeWidth"
                    :height="strokeHeight" 
                    ry="10" rx="0"
                ></rect>
            </g>
            <g :transform="getTextTransform" v-show="!isSelected">
                <text 
                    ref="generateText"
                    :style="[textStyle]"
                    text-rendering="geometricprecision"                    
                >{{text}}</text>                
            </g>            
            <g :transform="getInputTextTransform" v-show="isEdit">
                <foreignObject :width="inputWidth" :height="strokeHeight" :font-family="fontFamily" style="-webkit-font-smoothing: none;">
                    <div xmlns="http://www.w3.org/1999/xhtml">
                        <input :style="[inputTextStyle]" v-model="text" @keyup="setSize"></input>
                    </div>
                </foreignObject>
            </g>
        </g>`,
  mounted() {
    this.$nextTick(() => {
      // this.x = this.inputX
      // this.y = this.inputY
      this.importData(this.objectData);
    });
  },
  computed: {
    boxStyle1() {
      const style = {
        fill: 'none',
        stroke: '#efefef',
        strokeWidth: '6px',
        strokeOpacity: this.isSelected ? '0.8' : '0',
        cursor: 'pointer',
      };
      return style;
    },
    textStyle() {
      const style = {
        fill: this.fontColor,
        fontSize: this.fontSize + 'px',
        fontFamily: this.fontFamily ? this.fontFamily : 'NanumGothic',
        fontWeight: this.fontWeight,
        whiteSpace: 'pre',
        userSelect: 'none',
      };
      return style;
    },
    inputTextStyle() {
      const style = {
        width: '100%',
        fontSize: this.fontSize + 'px',
        backgroundColor: 'transparent',
        border: 'none',
        fontWeight: this.fontWeight,
        color: this.fontColor,
        fontFamily: this.fontFamily ? this.fontFamily : 'NanumGothic',
        //fontFamily:(this.fontFamily)?this.fontFamily:'NanumGothic'
      };
      return style;
    },
    getMatrix() {
      return `matrix(1, 0, 0, 1, ${this.x}, ${this.y})`;
    },
    getTextTransform() {
      return `translate(0 ${this.fontSize})`;
    },
    getInputTextTransform() {
      let boxSize = this.fontSize + Math.floor(this.fontSize / 3);
      let offset = Math.round((boxSize - this.fontSize) / 5);
      return `translate(0 ${offset})`;
    },
    getFilter() {
      if (this.bmpColor == '4bit') {
        return 'url(#crispify)';
      } else if (this.bmpColor == '24bit') {
        return '';
      }
    },
  },
  data() {
    return {
      objectType: 'textBlock',
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      isSelected: false,
      isEdit: false,
      text: '텍스트 입력',
      isInit: false,
      fontSize: 32,
      fontFamily: 'NanumGothic',
      fontWeight: 'normal',
      fontColor: '#ffffff',
      strokeWidth: 0,
      strokeHeight: 0,
      inputWidth: 0,
    };
  },
  props: {
    inputX: {
      type: Number,
      default: 0,
    },
    inputY: {
      type: Number,
      default: 0,
    },
    ratio: {
      type: Number,
    },
    objectId: {
      type: String,
      default: 'textBlockObject-0',
    },
    isFillin: {
      type: Boolean,
      default: false,
    },
    objectData: {
      type: Object,
    },
    bmpColor: {
      type: String,
      default: '24bit',
    },
  },
  methods: {
    setSize() {
      this.$nextTick(() => {
        if (this.$refs.generateText) {
          this.strokeWidth = this.$refs.generateText.getBBox().width + 40;
          this.strokeHeight = this.fontSize + Math.floor(this.fontSize / 3);
          this.inputWidth = this.strokeWidth - 30;
        }
      });
    },
    selectObject() {
      this.isSelected = true;
      this.isEdit = true;
      this.$parent.$emit('selectObject', this);
    },
    deselectObject() {
      this.isSelected = false;
      this.isEdit = false;
    },
    dragStart(event) {
      this.selectObject();
      // let param = {
      //   obj: this,
      //   width: this.strokeWidth,
      //   height: this.strokeHeight,
      //   x: this.x - 20,
      //   y: this.y,
      // };
      // this.$parent.$emit('dragStart', param);
      this.$parent.$emit('dragStart', this);
      this.isEdit = false;
    },
    setPosition(x, y) {
      this.x = x;
      this.y = y;
    },
    getObjectId() {
      return this.objectId;
    },
    setColor(color) {
      this.fontColor = color;
      this.$forceUpdate();
      this.setSize();
    },
    setFontSize(size) {
      this.fontSize = size;
      this.$forceUpdate();
      this.setSize();
    },
    setText(text) {
      this.text = text;
      this.$forceUpdate();
      this.setSize();
    },
    setFontFamily(fontFamily) {
      this.fontFamily = fontFamily;
      this.$forceUpdate();
      this.setSize();
    },
    setFontWeight(fontWeight) {
      this.fontWeight = fontWeight;
      this.$forceUpdate();
      this.setSize();
    },
    exportData() {
      return {
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.height,
        text: this.text,
        fontSize: this.fontSize,
        fontFamily: this.fontFamily,
        fontWeight: this.fontWeight,
        fontColor: this.fontColor,
        isFillin: this.isFillin,
        objectId: this.objectId,
      };
    },
    importData(data) {
      this.x = data.x || this.inputX;
      this.y = data.y || this.inputY;
      this.width = data.width || 0;
      this.height = data.height || 0;
      this.text = data.text || '텍스트 입력';
      this.fontSize = data.fontSize || 32;
      this.fontFamily = data.fontFamily || 'NanumGothic';
      this.fontWeight = data.fontWeight || 'bold';
      this.fontColor = data.fontColor || '#ffffff';
      this.$forceUpdate();
      this.setSize();
    },
  },
};

let textObject = {
  template: `
        <g :id="objectId"
            :transform="getMatrix"
            @mousedown="selectObject"
        >
            <g @mousedown="dragStart" transform="translate(-15 0)">
                <rect 
                    :style="[boxStyle1]"
                    :width="strokeWidth"
                    :height="strokeHeight" 
                    ry="10" rx="0"
                ></rect>
            </g>
           
            <g :transform="getInputTextTransform">
                <foreignObject :width="width" :height="height" :font-family="fontFamily" style="-webkit-font-smoothing: none;">
                  <div xmlns="http://www.w3.org/1999/xhtml" :style="[inputTextStyle]" class="vms-text-editor" contentEditable="true" ref="textInput" @keyup="keyEvt" v-html="text"></div>
                </foreignObject>
            </g>
        </g>`,
  mounted() {
    this.$nextTick(() => {
      // this.x = this.inputX
      // this.y = this.inputY
      this.importData(this.objectData);
    });
  },
  computed: {
    boxStyle1() {
      const style = {
        fill: '#000000',
        stroke: '#efefef',
        strokeWidth: '6px',
        strokeOpacity: this.isSelected ? '0.8' : '0',
        cursor: 'pointer',
      };
      return style;
    },
    textStyle() {
      const style = {
        fill: this.fontColor,
        fontSize: this.fontSize + 'px',
        fontFamily: this.fontFamily ? this.fontFamily : 'NanumGothic',
        fontWeight: this.fontWeight,
        whiteSpace: 'pre',
        userSelect: 'none',
      };
      return style;
    },
    inputTextStyle() {
      const style = {
        width: '100%',
        fontSize: this.fontSize + 'px',
        backgroundColor: 'transparent',
        border: 'none',
        fontWeight: this.fontWeight,
        color: this.fontColor,
        fontFamily: this.fontFamily ? this.fontFamily : 'NanumGothic',
        lineHeight: '110%',
        textAlign: this.textAlign,
        //fontFamily:(this.fontFamily)?this.fontFamily:'Dotum'
      };
      return style;
    },
    getMatrix() {
      return `matrix(1, 0, 0, 1, ${this.x}, ${this.y})`;
    },
    getTextTransform() {
      return `translate(0 ${this.fontSize})`;
    },
    getInputTextTransform() {
      let boxSize = this.fontSize + Math.floor(this.fontSize / 3);
      let offset = Math.round((boxSize - this.fontSize) / 5);
      return `translate(0 ${offset})`;
    },
    getFilter() {
      if (this.bmpColor == '4bit') {
        return 'url(#crispify)';
      } else if (this.bmpColor == '24bit') {
        return '';
      }
    },
  },
  data() {
    return {
      objectType: 'text',
      x: 0,
      y: 0,
      isSelected: false,
      isEdit: false,
      text: '텍스트 입력',
      isInit: false,
      fontSize: 48,
      fontFamily: 'NanumGothic',
      fontWeight: 'normal',
      fontColor: '#00ff00',
      strokeWidth: 230,
      strokeHeight: 100,
      width: 200,
      height: 100,
      fillinValue: '',
      textArr: ['텍스트 입력'],
      isLineText: false,
      textAlign: 'center',
    };
  },
  props: {
    inputX: {
      type: Number,
      default: 0,
    },
    inputY: {
      type: Number,
      default: 0,
    },
    ratio: {
      type: Number,
    },
    objectId: {
      type: String,
      default: 'textObject-0',
    },
    isFillin: {
      type: Boolean,
      default: false,
    },
    objectData: {
      type: Object,
    },
    bmpColor: {
      type: String,
      default: '24bit',
    },
  },
  methods: {
    setSize() {
      this.$nextTick(() => {
        if (!this.isLineText) {
          let widthLength = Math.max(...this.textArr.map((el) => el.length));
          let width = Math.max.apply(
            Math,
            this.textArr.map((el) => {
              let spaceCount = el.split(' ').length - 1;
              let strCount = el.length - spaceCount;

              return strCount * this.fontSize + spaceCount * (this.fontSize / 5);
            })
          );
          this.strokeWidth = width + 30;
          this.width = this.strokeWidth - 30;
        }

        this.strokeHeight = (this.fontSize + 8) * this.textArr.length;
        this.height = this.strokeHeight;
      });
    },
    mouseBlockTest(e) {
      // document.execCommand('backColor', false, "rgba(128,0,0,0.5)");
    },
    setColorA() {
      document.execCommand('styleWithCSS', false, true);
      document.execCommand('backColor', false, 'rgba(128,0,0,0.5)');
    },
    selectObject(evt) {
      this.isSelected = true;
      this.isEdit = true;
      if (evt && evt.target.className == 'text-field') {
        setTimeout(() => {
          this.$refs.textInput.focus();
        }, 0);
      }
      //this.getInputText()
      this.$parent.$emit('selectObject', this);
      // this.$parent.$emit("selectObject", {
      //     obj: this,
      //     objectType: this.objectType,
      //     fontColor: this.fontColor,
      //     fontSize: this.fontSize,
      //     fontFamily: this.fontFamily,
      //     fontWeight: this.fontWeight,
      //     x:this.x,
      //     y:this.y,
      //     isFillin: this.isFillin,
      //     text:this.text
      // })
    },
    deselectObject() {
      this.isSelected = false;
      this.isEdit = false;
    },
    dragStart(event) {
      this.selectObject();
      // let param = {
      //   obj: this,
      //   width: this.strokeWidth,
      //   height: this.strokeHeight,
      //   x: this.x - 20,
      //   y: this.y,
      // };
      // this.$parent.$emit('dragStart', param);
      this.$parent.$emit('dragStart', this);

      this.isEdit = false;
    },
    setPosition(x, y) {
      this.x = x;
      this.y = y;
    },
    getObjectId() {
      return this.objectId;
    },
    setColor(color) {
      // this.fontColor = color
      document.execCommand('styleWithCSS', false, true);
      document.execCommand('foreColor', false, color);
      this.$forceUpdate();
      this.setSize();
    },
    setBold(isBold) {
      document.execCommand('bold', false, null);
      this.$forceUpdate();
      this.setSize();
    },
    getCaret() {
      // Range 설정
      const selection = window.getSelection();

      return selection;
    },
    setFontSize(size) {
      this.fontSize = size;
      this.$forceUpdate();
      this.setSize();
    },
    setText(text) {
      this.text = text;
      this.$forceUpdate();
      this.setSize();
    },
    setFillinValue(value, text) {
      this.fillinValue = value;
      this.text = text;
      this.$forceUpdate();
      this.setSize();
    },
    setFontFamily(fontFamily) {
      this.fontFamily = fontFamily;
      this.$forceUpdate();
      this.setSize();
    },
    // setFontWeight(fontWeight) {
    //   this.fontWeight = fontWeight;
    //   this.$forceUpdate();
    //   this.setSize();
    // },
    exportData() {
      let textDom = this.$refs.textInput.innerHTML;

      return {
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.height,
        text: textDom,
        fontSize: this.fontSize,
        fontFamily: this.fontFamily,
        fontWeight: this.fontWeight,
        fontColor: this.fontColor,
        isFillin: this.isFillin,
        objectId: this.objectId,
        isLineText: this.isLineText,
        textAlign: this.textAlign,
      };
    },
    importData(data) {
      this.isLineText = data.isLineText || false;
      if (this.isLineText) {
        this.x = 15;
        this.width = this.$parent.editorWidth - 30;
        this.strokeWidth = this.$parent.editorWidth;
      } else {
        this.x = data.x || this.inputX;
        this.width = data.width || 200;
        this.strokeWidth = data.width + 30 || 230;
      }
      this.y = data.y || this.inputY;
      this.height = data.height || 100;
      this.strokeHeight = data.height || 100;
      this.text = data.text || '텍스트 입력';
      this.fontSize = data.fontSize || 48;
      this.fontFamily = data.fontFamily || 'NanumGothic';
      this.fontWeight = data.fontWeight || 'normal';
      this.fontColor = data.fontColor || '#00ff00';
      this.textAlign = data.textAlign || 'center';
      this.$forceUpdate();
      this.applyText();
      this.setSize();
    },
    setText(text) {
      console.log(text);
    },
    applyText() {
      this.$nextTick(() => {
        this.textArr = [];
        var parser = new DOMParser();
        var htmlDoc = parser.parseFromString(this.$refs.textInput.innerHTML, 'text/html');
        let body = htmlDoc.getElementsByTagName('body');
        this.textArr[0] = '';
        if (body[0].childNodes) {
          for (var i = 0; i < body[0].childNodes.length; i++) {
            let child = body[0].childNodes[i];
            if (child.nodeName.toLowerCase() === '#text') {
              this.textArr[0] = child.nodeValue;
            } else if (child.nodeName.toLowerCase() === 'span') {
              this.textArr[0] += child.innerText;
            }
          }
        }
        let divs = htmlDoc.getElementsByTagName('div');
        for (var i = 0; i < divs.length; i++) {
          this.textArr[i + 1] = '';
          for (var j = 0; j < divs[i].childNodes.length; j++) {
            let child = divs[i].childNodes[j];
            if (child.nodeName.toLowerCase() === '#text') {
              this.textArr[i + 1] += child.nodeValue;
            } else if (child.nodeName.toLowerCase() === 'span') {
              this.textArr[i + 1] += child.innerText;
            }
          }
        }
      });
    },
    keyEvt() {
      this.applyText();
      this.$forceUpdate();
      this.setSize();
    },
    getInputText() {
      let inputText = '';
      // if (this.textArr[0]) {
      // inputText = this.textArr[0];
      // }
      for (var i = 0; i < this.textArr.length; i++) {
        inputText += `<div class="text-field">${this.textArr[i]}</div>`;
      }
      var parser = new DOMParser();
      var htmlDoc = parser.parseFromString(inputText, 'text/html');
      let divs = htmlDoc.getElementsByTagName('div');
      for (var i = 0; i < divs.length; i++) {
        let line = divs[i];
        line.innerText = line.innerText.replace(/ {2}/g, '&nbsp; ');
        for (var j = 0; j < line.children.length; j++) {
          line.children[j].innerText = line.children[j].innerText.replace(/ {2}/g, '&nbsp; ');
        }
      }
      this.$refs.textInput.innerHTML = htmlDoc.getElementsByTagName('body')[0].innerHTML;
      return inputText;
    },
    setTextAlign(align) {
      this.textAlign = align;
    },
  },
};

let imageObject = {
  template: `
        <g :id="objectId">
            <g :transform="getMatrix">
                <g transform="translate(0 0)">
                    <image x="0" y="0" :width="width" :height="height" @mousedown="mousedown(actionsEnum.Move, $event)" :xlink:href="imageBynary"></image>
                </g>
                <g transform="translate(0 0)" v-show="isSelected">
                    <line x1="0" y1="0" :x2="width" y2="0" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="topDrawing" @mousedown="mousedown(actionsEnum.TopResize, $event)"/>
                    <line x1="0" :y1="height" :x2="width" :y2="height" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="bottomDrawing" @mousedown="mousedown(actionsEnum.BottomResize, $event)"/>
                    <line x1="0" y1="0" x2="0" :y2="height" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="leftDrawing" @mousedown="mousedown(actionsEnum.LeftResize, $event)"/>
                    <line :x1="width" y1="0" :x2="width" :y2="height" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="rightDrawing" @mousedown="mousedown(actionsEnum.RightResize, $event)"/>
                    <circle cx="0" cy="0" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="topLeftDrawing" @mousedown="mousedown(actionsEnum.TopLeftResize, $event)" />
                    <circle :cx="width" cy="0" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="topRightDrawing" @mousedown="mousedown(actionsEnum.TopRightResize, $event)"/>
                    <circle cx="0" :cy="height" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="bottomLeftDrawing"  @mousedown="mousedown(actionsEnum.BottomLeftResize, $event)"/>
                    <circle :cx="width" :cy="height" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="bottomRightDrawing" @mousedown="mousedown(actionsEnum.BottomRightResize, $event)"/>                
                </g>
            </g>
        </g>`,
  mounted() {
    this.$nextTick(() => {
      this.importData(this.objectData);
    });
  },
  computed: {
    getMatrix() {
      return `matrix(1, 0, 0, 1, ${this.x}, ${this.y})`;
    },
  },
  data() {
    return {
      objectType: 'image',
      x: 0,
      y: 0,
      width: 240,
      height: 150,
      offsetX: 0,
      offsetY: 0,
      isSelected: false,
      imageBynary: '',
      actionsEnum: actionsEnum,
      currentAction: 0,
    };
  },
  props: {
    inputX: {
      type: Number,
      default: 0,
    },
    inputY: {
      type: Number,
      default: 0,
    },
    ratio: {
      type: Number,
    },
    objectId: {
      type: String,
      default: 'imageObject-0',
    },
    imageUrl: {
      type: String,
      required: true,
    },
    objectData: {
      type: Object,
    },
    bmpColor: {
      type: String,
      default: '24bit',
    },
  },
  methods: {
    getImageBynary(imageUrl) {
      let me = this;
      this.toDataUrl(imageUrl, function (dataUrl) {
        me.imageBynary = dataUrl;
      });
    },
    toDataUrl(src, callback, outputFormat) {
      var img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
      };
      img.src = src;
      if (img.complete || img.complete === undefined) {
        img.src = 'data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
        img.src = src;
      }
    },
    mousedown(flag, event) {
      this.currentAction = flag;
      this.offsetX = event.offsetX - this.x;
      this.offsetY = event.offsetY - this.y;
      //console.log(`x:${this.x}, y:${this.y}, offsetX: ${this.offsetX}, offsetY: ${this.offsetY}`)

      this.selectObject();
    },
    deselectObject() {
      this.isSelected = false;
    },
    setPosition(x, y) {
      this.x = x;
      this.y = y;
    },
    setSize(width, height) {
      this.width = width;
      this.height = height;
    },
    getSize() {
      return {
        width: this.width,
        height: this.height,
      };
    },
    getObjectId() {
      return this.objectId;
      this.selectObject();
    },
    selectObject() {
      this.isSelected = true;
      this.isEdit = true;
      this.$parent.$emit('selectObject', this);
      // this.$parent.$emit("selectObject", {
      //     obj: this,
      //     objectType: this.objectType,
      //     x:this.x,
      //     y:this.y,
      //     offsetX: this.offsetX,
      //     offsetY: this.offsetY,
      //     width:this.width,
      //     height:this.height,
      //     currentAction: this.currentAction
      // })
    },
    exportData() {
      return {
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.height,
        offsetX: this.offsetX,
        offsetY: this.offsetY,
        imageUrl: this.imageUrl,
        objectId: this.objectId,
      };
    },
    importData(data) {
      this.x = data.x || this.inputX;
      this.y = data.y || this.inputY;
      this.width = data.width || 240;
      this.height = data.height || 150;
      this.offsetX = data.offsetX || 0;
      this.offsetY = data.offsetY || 0;
      this.getImageBynary(this.imageUrl);
    },
  },
};

let lineObject = {
  template: `       
        <g :id="objectId"           
            @mousedown="selectObject"            
        >
            <marker :id="objectId+'-marker'" viewBox="0 0 10 10" refX="1" refY="5" 
                markerUnits="strokeWidth" orient="auto"
                markerWidth="4" markerHeight="2"
                :shape-rendering="getShareRendering"
                >
                <polyline points="0,0 7,5 0,10 0,0" :fill="strokeColor" :shape-rendering="getShareRendering"/> 
            </marker> 
            <g>                            
                <line :y1="y1" :x1="x1" :y2="y2" :x2="x2" :marker-end="getEndArrow" :stroke="strokeColor" :stroke-width="strokeWidth" :shape-rendering="getShareRendering"/>
                <g v-show="isSelected">
                    <line :x1="x1" :y1="y1" :x2="x2" :y2="y2" stroke="#90A4AE" :stroke-width="strokeWidth/2" stroke-dasharray="5,5" class="topDrawing"/>
                    <circle :cx="x1" :cy="y1" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="bottomRightDrawing" @mousedown="mousedown('startPoint', $event)"/>
                    <circle :cx="x2" :cy="y2" r="5" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="bottomRightDrawing" @mousedown="mousedown('endPoint', $event)"/>
                </g>
                <g v-if="isTunnel">
                    <line :y1="ly1" :x1="lx1" :y2="ly2" :x2="lx2" stroke="#ffff00" stroke-width="10" stroke-dasharray="10,5"/>
                    <line :y1="ry1" :x1="rx1" :y2="ry2" :x2="rx2" stroke="#ffff00" stroke-width="10" stroke-dasharray="10,5"/>
                </g>
            </g>           
        </g>
    `,
  //                     <line :x1="x2" :y1="y1" :x2="x2" :y2="y2" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="bottomDrawing"/>
  // <line :x1="x2" :y1="y1" :x2="x1" :y2="y1" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="leftDrawing"/>
  // <line :x1="x1" :y1="y1" :x2="x1" :y2="y2" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="rightDrawing"/>
  // <line :x1="x1-((this.strokeWidth /2) + 5)" :y1="y1" :x2="x2-((this.strokeWidth /2) + 5)" :y2="y2" stroke="#00ff00" stroke-width="3" stroke-dasharray="5,5" />
  // <line :x1="x1+((this.strokeWidth /2) + 5)" :y1="y1" :x2="x2+((this.strokeWidth /2) + 5)" :y2="y2" stroke="#ff0000" stroke-width="3" stroke-dasharray="5,5" />
  mounted() {
    this.$nextTick(() => {
      this.importData(this.objectData);
    });
  },
  computed: {
    getEndArrow() {
      if (this.isEndArrow) {
        return `url(#${this.objectId}-marker)`;
      } else {
        return '';
      }
    },
    getMatrix() {
      //return `matrix(1, 0, 0, 1, ${this.x1}, ${this.y1})`
      return `matrix(1, 0, 0, 1, 0, 0)`;
    },
    getShareRendering() {
      if (this.bmpColor == '4bit') {
        return 'crispEdges';
      } else if (this.bmpColor == '24bit') {
        return 'auto';
      }
    },
    getLeftGuide() {
      return `translate(-${this.strokeWidth / 2 + 8}, 0)`;
    },
    getRightGuide() {
      return `translate(${this.strokeWidth / 2 + 8}, 0)`;
    },
  },
  watch: {
    x1(newVal) {
      if (this.isTunnel) {
        this.refeshTunnel();
      }
    },
    y1(newVal) {
      if (this.isTunnel) {
        this.refeshTunnel();
      }
    },
    x2(newVal) {
      if (this.isTunnel) {
        this.refeshTunnel();
      }
    },
    y2(newVal) {
      if (this.isTunnel) {
        this.refeshTunnel();
      }
    },
    strokeWidth(newVal) {
      if (this.isTunnel) {
        this.refeshTunnel();
      }
    },
  },
  data() {
    return {
      objectType: 'line',
      isSelected: false,
      isEdit: false,
      isEndArrow: false,
      strokeWidth: 20,
      strokeColor: '#00ff00',
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 0,
      currentAction: 0,
      isTunnel: false,
      lineGroupId: '',
    };
  },
  props: {
    objectId: {
      type: String,
      default: 'lineObject-0',
    },
    objectData: {
      type: Object,
    },
    bmpColor: {
      type: String,
      default: '24bit',
    },
  },
  methods: {
    selectObject() {
      this.isSelected = true;
      this.isEdit = true;
      this.$parent.$emit('selectObject', this);
    },
    deselectObject() {
      this.isSelected = false;
      this.isEdit = false;
    },
    setStrokeColor(color) {
      this.strokeColor = color;
      this.$forceUpdate();
    },
    exportData() {
      return {
        x1: this.x1,
        y1: this.y1,
        x2: this.x2,
        y2: this.y2,
        strokeColor: this.strokeColor,
        strokeWidth: this.strokeWidth,
        objectId: this.objectId,
        isEndArrow: this.isEndArrow,
        isTunnel: this.isTunnel,
      };
    },
    importData(data) {
      this.x1 = data.x1 || this.x1;
      this.y1 = data.y1 || this.y1;
      this.x2 = data.x2 || this.x2;
      this.y2 = data.y2 || this.y2;
      this.strokeWidth = data.strokeWidth || 20;
      this.strokeColor = data.strokeColor || '#00ff00';
      this.isEndArrow = data.isEndArrow || false;
      this.isTunnel = data.isTunnel || false;
      this.$forceUpdate();
    },
    getObjectId() {
      return this.objectId;
    },
    mousedown(flag, e) {
      this.currentAction = flag;
      this.selectObject();
    },
    updateStartPoint(x, y) {
      this.x1 = x;
      this.y1 = y;
    },
    updateEndPoint(x, y) {
      this.x2 = x;
      this.y2 = y;
    },
    setStrokeWidth(strokeWidth) {
      this.strokeWidth = strokeWidth;
    },
    setIsTunnel(isTunnel) {
      this.isTunnel = isTunnel;
      this.refeshTunnel();
    },
    setIsEndArrow(isEndArrow) {
      this.isEndArrow = isEndArrow;
    },
    setLineGroupId(lineGroupId) {
      this.lineGroupId = lineGroupId;
    },
    setStrokeColor(color) {
      this.strokeColor = color;
    },
    refeshTunnel() {
      let canvasWidth = this.$parent.canvasWidth;
      canvasWidth = canvasWidth.split('p')[0];
      canvasWidth = canvasWidth * 1;
      let canvasHeight = this.$parent.canvasHeight;
      canvasHeight = canvasHeight.split('p')[0];
      canvasHeight = canvasHeight * 1;
      //중점에서 점선까지의 거리
      let lengthLine = this.strokeWidth / 2 + 8;
      //중심선 시점
      let startPoint = {
        x: this.x1,
        y: canvasHeight - this.y1,
      };
      //중심선 종점
      let endPoint = {
        x: this.x2,
        y: canvasHeight - this.y2,
      };
      //시점에서 종점까지의 각도
      let gradient = Math.atan((endPoint.y - startPoint.y) / (endPoint.x - startPoint.x)) * (180 / Math.PI);
      //빗면
      let bp = lengthLine;
      //y변화값 = 밑변
      let dy = Math.cos(gradient * (Math.PI / 180)) * bp;
      //x변화값 = 높이
      let dx = Math.sin(gradient * (Math.PI / 180)) * bp;

      //오른쪽 점선 시점
      this.rx1 = startPoint.x + dx;
      this.ry1 = canvasHeight - (startPoint.y - dy);
      //오른쪽 점선 종점
      this.rx2 = endPoint.x + dx;
      this.ry2 = canvasHeight - (endPoint.y - dy);
      //왼쪽 점선 시점
      this.lx1 = startPoint.x - dx;
      this.ly1 = canvasHeight - (startPoint.y + dy);
      //왼쪽 점선 종점
      this.lx2 = endPoint.x - dx;
      this.ly2 = canvasHeight - (endPoint.y + dy);
    },
  },
};

export default {
  components: {
    'text-object': textObject,
    'text-block-object': textBlockObject,
    'image-object': imageObject,
    'line-object': lineObject,
  },
  data() {
    return {
      NONE,
      ADD_BLOCK_TEXT_OBJECT,
      ADD_LINE_TEXT_OBJECT,
      ADD_FILLIN_TEXT_OBJECT,
      ADD_IMAGE_OBJECT,
      ADD_LINE_OBJECT,
      ADD_ARROW_LINE_OBJECT,
      fillinTextData,
      mode: 0,
      vmsMessageData: {
        textData: [],
        textBlockData: [],
        imageData: [],
        lineData: [],
      },
      editorWidth: 0,
      editorHeight: 0,
      canvasWidth: '0px',
      canvasHeight: '0px',
      canvasViewBox: '0 0 0 0',
      mouseX: 0,
      mouseY: 0,
      isDragObject: false,
      tempImageSize: {},
      selectObject: null,
      selectObjectGroup: [],
      previewDialog: false,
      isLineDraw: false,
      tempLineObject: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
      },
      bmpColor: '24bit',
      isShiftPress: false,
    };
  },
  computed: {
    dragBoxStyle() {
      const style = {
        border: '2px solid #00ff00',
      };
      return style;
    },
    showTextObjectToolbar() {
      if (this.selectObject && this.selectObject.objectId && this.selectObjectGroup.length <= 1) {
        if (this.selectObject.objectType == 'text' || this.selectObject.objectType == 'textBlock') {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    showLineTextObjectToolbar() {
      if (this.selectObject && this.selectObject.objectId && this.selectObject.objectType == 'text' && this.selectObject.isLineText) {
        return true;
      } else {
        return false;
      }
    },
    showLineObjectToolbar() {
      if (this.selectObject && this.selectObject.objectId && this.selectObject.objectType == 'line' && this.selectObjectGroup.length <= 1) {
        return true;
      } else {
        return false;
      }
    },
    showLineObjectGroupToolbar() {
      let chk = 0;
      this.selectObjectGroup.forEach((s) => {
        if (s.objectType !== 'line') {
          chk++;
        }
      });
      if (this.selectObjectGroup.length > 1 && chk == 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    var procSelectObjectGroup = (selectObject) => {
      let idx = this.selectObjectGroup.findIndex((s) => {
        return s.objectId === selectObject.objectId;
      });
      if (idx > -1) {
        this.$lodash.remove(this.selectObjectGroup, {
          objectId: selectObject.objectId,
        });
        selectObject.deselectObject();
      } else {
        this.selectObjectGroup.push(selectObject);
      }
    };
    this.$on('selectObject', (selectObject) => {
      // if (!this.isShiftPress) {
      this.selectObjectGroup[0] = selectObject;
      if (this.selectObject && this.selectObject.getObjectId()) {
        if (this.selectObject.getObjectId() === selectObject.getObjectId()) {
        } else {
          this.selectObject.deselectObject();
        }
      }
      // }
      // else {
      //   procSelectObjectGroup(selectObject)
      // }
      this.selectObject = selectObject;

      // this.selectObject = selectObject.obj
      // this.selectObject.text = selectObject.text || ''
      // this.selectObject.fontColor = selectObject.fontColor || null
      // this.selectObject.fontSize = selectObject.fontSize || null
      // this.selectObject.fontFamily = selectObject.fontFamily || null
      // this.selectObject.fontWeight = selectObject.fontWeight || 'bold'
      // this.selectObject.x = selectObject.x
      // this.selectObject.y = selectObject.y
      // this.selectObject.offsetX = selectObject.offsetX || 0
      // this.selectObject.offsetY = selectObject.offsetY || 0
      // this.selectObject.width = selectObject.width || 0
      // this.selectObject.height = selectObject.height || 0
      // this.selectObject.currentAction = selectObject.currentAction || 0
      // this.selectObject.objectType = selectObject.objectType
      // this.selectObject.isFillin = selectObject.isFillin || false
      if (this.selectObject.objectType === 'image') {
        this.tempImageSize = this.selectObject.getSize();
        this.isDragObject = true;
      }
      if (this.selectObject.objectType === 'line') {
        this.isLineDraw = true;
      }
      this.$forceUpdate();
    });
    this.$on('dragStart', (dragObject) => {
      this.selectObject = dragObject;
      this.isDragObject = true;
      this.$refs.dragBox.style.width = dragObject.width + 'px';
      this.$refs.dragBox.style.height = dragObject.height + 'px';
      this.$refs.dragBox.style.top = dragObject.y + 'px';
      this.$refs.dragBox.style.left = dragObject.x + 'px';
    });
    window.addEventListener('keydown', this.keyPressEvent);
    window.addEventListener('keyup', this.keyUpEvent);
  },
  mounted() {
    this.$nextTick(() => {
      if (this.vmsMessageSize == '') {
        this.changeMessageSize('2x12');
      } else {
        console.log(this.vmsMessageSize);
        this.changeMessageSize(this.vmsMessageSize);
      }
    });
  },
  destroyed() {
    this.resetData();
    window.removeEventListener('keydown', this.keyPressEvent);
    window.removeEventListener('keyup', this.keyUpEvent);
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    vmsMessageSize: {
      type: String,
    },
  },
  methods: {
    keyPressEvent(e) {
      if (e.shiftKey) {
        this.isShiftPress = true;
      }
    },
    keyUpEvent(e) {
      // Up: 38
      // Down: 40
      // Right: 39
      // Left: 37
      this.isShiftPress = false;
      if ((e.keyCode == 27 && this.isLineDraw && this.mode == ADD_LINE_OBJECT) || this.mode == ADD_ARROW_LINE_OBJECT) {
        this.isLineDraw = false;
        this.tempLineObject.x1 = 0;
        this.tempLineObject.y1 = 0;
        this.tempLineObject.x2 = 0;
        this.tempLineObject.y2 = 0;
        this.mode = NONE;
      }

      if (e.target.tagName.toLowerCase() === 'input' || e.target.tagName.toLowerCase() === 'div') {
        return;
      }
      if (this.selectObject && this.selectObject.objectId) {
        let offset = 1;
        if (e.shiftKey) {
          offset *= 10;
        }
        switch (e.keyCode) {
          case 38:
            this.selectObject.y -= offset;
            this.selectObject.setPosition(this.selectObject.x, this.selectObject.y);
            break;
          case 40:
            this.selectObject.y += offset;
            this.selectObject.setPosition(this.selectObject.x, this.selectObject.y);
            break;
          case 37:
            this.selectObject.x -= offset;
            this.selectObject.setPosition(this.selectObject.x, this.selectObject.y);
            break;
          case 39:
            this.selectObject.x += offset;
            this.selectObject.setPosition(this.selectObject.x, this.selectObject.y);
            break;

          case 46:
            this.removeObject();
            break;
        }
      }
    },
    clickEditor(e) {
      switch (this.mode) {
        case NONE:
          break;
        case ADD_BLOCK_TEXT_OBJECT:
          return this.addBlockTextObject(e.offsetX, e.offsetY);
        case ADD_LINE_TEXT_OBJECT:
          return this.addLineTextObject(e.offsetX, e.offsetY);
        case ADD_FILLIN_TEXT_OBJECT:
          return this.addFillinTextObject(e.offsetX, e.offsetY);
        case ADD_IMAGE_OBJECT:
          //return this.addImageObject(e.offsetX, e.offsetY)
          break;
        case ADD_LINE_OBJECT:
          break;
        case ADD_ARROW_LINE_OBJECT:
          break;
      }
    },
    mousedownEditor(e) {
      if (this.mode == ADD_LINE_OBJECT || this.mode == ADD_ARROW_LINE_OBJECT) {
        if (!this.isLineDraw) {
          this.isLineDraw = true;
          this.tempLineObject.x1 = e.offsetX;
          this.tempLineObject.y1 = e.offsetY;
          this.tempLineObject.x2 = e.offsetX;
          this.tempLineObject.y2 = e.offsetY;
        } else {
          let idx = 0;
          if (!this.vmsMessageData.lineData || this.vmsMessageData.lineData.length == 0) {
            this.vmsMessageData.lineData = [];
            idx = 1;
          } else {
            let lastId = this.vmsMessageData.lineData[this.vmsMessageData.lineData.length - 1].objectId;
            idx = parseInt(lastId.substring(lastId.indexOf('-') + 1, lastId.length)) + 1;
          }
          let lineObj = {
            objectId: `lineObject-${idx}`,
            x1: this.tempLineObject.x1,
            y1: this.tempLineObject.y1,
            x2: this.tempLineObject.x2,
            y2: this.tempLineObject.y2,
            isArrow: this.mode == ADD_ARROW_LINE_OBJECT ? true : false,
          };
          this.vmsMessageData.lineData.push(lineObj);
          this.tempLineObject.x1 = e.offsetX;
          this.tempLineObject.y1 = e.offsetY;
          this.tempLineObject.x2 = e.offsetX;
          this.tempLineObject.y2 = e.offsetY;
        }
      }
    },
    mousemoveEditor(e) {
      if ((this.mode == ADD_LINE_OBJECT && this.isLineDraw) || (this.mode == ADD_ARROW_LINE_OBJECT && this.isLineDraw)) {
        this.tempLineObject.x2 = e.offsetX;
        this.tempLineObject.y2 = e.offsetY;
      }
      if (this.isLineDraw && this.selectObject && this.selectObject.objectType == 'line') {
        return this.lineMouseMove(event);
      }
    },
    lineMouseMove(e) {
      if (this.selectObject.currentAction == 'startPoint') {
        this.selectObject.updateStartPoint(e.offsetX, e.offsetY);
      } else if (this.selectObject.currentAction == 'endPoint') {
        this.selectObject.updateEndPoint(e.offsetX, e.offsetY);
      }
    },
    mouseupEditor(e) {
      // if (this.mode == ADD_LINE_OBJECT || this.mode == ADD_ARROW_LINE_OBJECT) {
      //     let idx = 0;
      //     if ((!this.vmsMessageData.lineData) || this.vmsMessageData.lineData.length == 0) {
      //         this.vmsMessageData.lineData = []
      //         idx = 1;
      //     }
      //     else {
      //         let lastId = this.vmsMessageData.lineData[this.vmsMessageData.lineData.length - 1].objectId
      //         idx = parseInt(lastId.substring(lastId.indexOf('-')+1, lastId.length)) + 1
      //     }
      //     this.tempLineObject.x2 = e.offsetX
      //     this.tempLineObject.y2 = e.offsetY
      //     this.isLineDraw = false
      //     let lineObj = {
      //         objectId: `lineObject-${idx}`,
      //         x1: this.tempLineObject.x1,
      //         y1: this.tempLineObject.y1,
      //         x2: this.tempLineObject.x2,
      //         y2: this.tempLineObject.y2,
      //         isArrow: (this.mode == ADD_ARROW_LINE_OBJECT) ? true : false
      //     }
      //     this.vmsMessageData.lineData.push(lineObj)
      //     this.tempLineObject.x1 = 0
      //     this.tempLineObject.y1 = 0
      //     this.tempLineObject.x2 = 0
      //     this.tempLineObject.y2 = 0
      //     this.mode = NONE
      // }
      if (this.isLineDraw && this.selectObject && this.selectObject.objectType == 'line') {
        this.isLineDraw = false;
      }
    },
    resetData() {
      this.vmsMessageData = {
        textData: [],
        textBlockData: [],
        imageData: [],
      };
      if (this.type == 'figure') {
        this.vmsMessageData.lineData = [];
      }
      this.$forceUpdate();
    },
    setEditorSize() {
      let width = this.$refs.canvasWrap.clientWidth;
      let height = Math.ceil(width / 16) * 9;
      this.editorWidth = width;
      this.editorHeight = height;
      this.canvasWidth = width + 'px';
      this.canvasHeight = height + 'px';
      this.canvasViewBox = `0 0 ${width} ${height}`;
      this.ratio = width / 1920;
    },
    setMode(mode) {
      if (this.selectObject && this.selectObject.objectId) {
        this.selectObject.deselectObject();
        this.selectObject = null;
      }
      this.isLineDraw = false;
      this.isDragObject = false;
      this.mode = mode;
    },
    addBlockTextObject(x, y) {
      let idx = new Date().getTime();
      // if (this.vmsMessageData.textData.length == 0) {
      //     idx = 1;
      // }
      // else {
      //     // let lastId = this.vmsMessageData.textData[this.vmsMessageData.textData.length - 1].objectId
      //     // idx = parseInt(lastId.substring(lastId.indexOf('-')+1, lastId.length)) + 1
      // }

      let textBlockObj = {
        objectId: `textBlockObject-${idx}`,
        inputX: x,
        inputY: y,
      };
      this.vmsMessageData.textBlockData.push(textBlockObj);
      this.mode = NONE;
    },
    addLineTextObject(x, y) {
      let idx = new Date().getTime();
      let textObj = {
        objectId: `textObject-${idx}`,
        inputX: x,
        inputY: y,
        isLineText: true,
      };
      this.vmsMessageData.textData.push(textObj);
      this.mode = NONE;
    },
    addFillinTextObject(x, y) {
      let idx = new Date().getTime();
      // if (this.vmsMessageData.textData.length == 0) {
      //     idx = 1;
      // }
      // else {
      //     let lastId = this.vmsMessageData.textData[this.vmsMessageData.textData.length - 1].objectId
      // }

      let fillInTextObj = {
        objectId: `textObject-${idx}`,
        inputX: x,
        inputY: y,
        isFillin: true,
        text: '필인 코드 선택',
      };
      this.vmsMessageData.textData.push(fillInTextObj);
      this.mode = NONE;
    },
    addImageObject(x, y, width, height, imageUrl) {
      //let idx = this.vmsMessageData.imageData.length
      let idx = new Date().getTime();
      let imageObj = {
        objectId: `imageObject-${idx}`,
        inputX: x,
        inputY: y,
        width: width,
        height: height,
      };
      if (imageUrl) {
        imageObj.imageUrl = imageUrl;
      }
      this.vmsMessageData.imageData.push(imageObj);
      this.mode = NONE;
    },
    removeObject() {
      let delIdx = 0;
      if (this.selectObject.objectType === 'text') {
        delIdx = this.vmsMessageData.textData.findIndex((ele) => {
          return ele.objectId === this.selectObject.getObjectId();
        });
        this.vmsMessageData.textData.splice(delIdx, 1);
      } else if (this.selectObject.objectType === 'textBlock') {
        delIdx = this.vmsMessageData.textBlockData.findIndex((ele) => {
          return ele.objectId === this.selectObject.getObjectId();
        });
        this.vmsMessageData.textBlockData.splice(delIdx, 1);
      } else if (this.selectObject.objectType === 'image') {
        delIdx = this.vmsMessageData.imageData.findIndex((ele) => {
          return ele.objectId === this.selectObject.getObjectId();
        });
        this.vmsMessageData.imageData.splice(delIdx, 1);
      } else if (this.selectObject.objectType === 'line') {
        delIdx = this.vmsMessageData.lineData.findIndex((ele) => {
          return ele.objectId === this.selectObject.getObjectId();
        });
        this.vmsMessageData.lineData.splice(delIdx, 1);
      }
    },
    mousemove(event) {
      if (this.isDragObject && this.selectObject && this.selectObject.objectType === 'text') {
        // && event.target.tagName.toLowerCase() === "div" && event.target.className=="drag-mask")  {
        if (!event.offsetX || !event.offsetY) {
          return;
        }
        if (this.selectObject.isLineText) {
          this.$refs.dragBox.style.left = 0;
          this.selectObject.x = 15;
        } else {
          this.$refs.dragBox.style.left = event.offsetX + 'px';
          this.selectObject.x = event.offsetX + 20;
        }
        this.$refs.dragBox.style.top = event.offsetY + 'px';
        this.selectObject.y = event.offsetY;
      }
      if (this.isDragObject && this.selectObject && this.selectObject.objectType === 'textBlock') {
        // && event.target.tagName.toLowerCase() === "div" && event.target.className=="drag-mask")  {
        if (!event.offsetX || !event.offsetY) {
          return;
        }
        if (this.selectObject.isLineText) {
          this.$refs.dragBox.style.left = 0;
          this.selectObject.x = 15;
        } else {
          this.$refs.dragBox.style.left = event.offsetX + 'px';
          this.selectObject.x = event.offsetX + 20;
        }
        this.$refs.dragBox.style.top = event.offsetY + 'px';
        this.selectObject.y = event.offsetY;
      }
      if (this.isDragObject && this.selectObject && this.selectObject.objectType === 'image') {
        return this.imageMouseMoveEvent(event);
      }

      event.preventDefault();
      event.stopPropagation();
    },
    imageMouseMoveEvent(event) {
      // let deltaX = event.offsetX;
      // let deltaY = event.offsetY;
      let deltaX = event.offsetX - this.selectObject.offsetX;
      let deltaY = event.offsetY - this.selectObject.offsetY;
      let deltaWidth = 0;
      let deltaHeight = 0;
      let deltaLeft = 0;
      let deltaTop = 0;
      if (
        this.selectObject.currentAction == actionsEnum.RightResize ||
        this.selectObject.currentAction == actionsEnum.TopRightResize ||
        this.selectObject.currentAction == actionsEnum.BottomRightResize
      ) {
        deltaWidth = deltaX;
      }

      if (
        this.selectObject.currentAction == actionsEnum.LeftResize ||
        this.selectObject.currentAction == actionsEnum.TopLeftResize ||
        this.selectObject.currentAction == actionsEnum.BottomLeftResize
      ) {
        deltaWidth = -deltaX;
        deltaLeft = deltaX;
      }

      if (
        this.selectObject.currentAction == actionsEnum.BottomResize ||
        this.selectObject.currentAction == actionsEnum.BottomLeftResize ||
        this.selectObject.currentAction == actionsEnum.BottomRightResize
      ) {
        deltaHeight = deltaY;
      }

      if (
        this.selectObject.currentAction == actionsEnum.TopResize ||
        this.selectObject.currentAction == actionsEnum.TopLeftResize ||
        this.selectObject.currentAction == actionsEnum.TopRightResize
      ) {
        deltaHeight = -deltaY;
        deltaTop = deltaY;
      }
      if (this.selectObject.currentAction == actionsEnum.Move) {
        deltaLeft = deltaX;
        deltaTop = deltaY;
        this.selectObject.x = deltaLeft;
        this.selectObject.y = deltaTop;
        this.selectObject.setPosition(deltaLeft, deltaTop);
      } else if (this.selectObject.currentAction !== actionsEnum.None) {
        // console.log(`${event.offsetX - this.selectObject.x - this.selectObject.offsetX}, ${event.offsetY - this.selectObject.y - this.selectObject.offsetY}`)
        let targetWidth = this.tempImageSize.width + (event.offsetX - this.selectObject.x - this.selectObject.offsetX);
        let targetHeight = this.tempImageSize.height + (event.offsetY - this.selectObject.y - this.selectObject.offsetY);
        this.selectObject.setSize(targetWidth, targetHeight);
      }
    },
    mouseup(event) {
      if (this.isDragObject && this.selectObject && this.selectObject.objectType === 'text') {
        this.isLineDraw = false;
        this.isDragObject = false;
        this.selectObject.setPosition(this.selectObject.x, this.selectObject.y);
      }
      if (this.isDragObject && this.selectObject && this.selectObject.objectType === 'textBlock') {
        this.isLineDraw = false;
        this.isDragObject = false;
        this.selectObject.setPosition(this.selectObject.x, this.selectObject.y);
      }
      if (this.isDragObject && this.selectObject && this.selectObject.objectType === 'image') {
        this.isDragObject = false;
        this.isLineDraw = false;
        if (this.selectObject.currentAction === actionsEnum.Move) {
          this.selectObject.setPosition(this.selectObject.x, this.selectObject.y);
        }
      }

      if (event.target.tagName.toLowerCase() === 'rect' && event.target._prevClass === 'rect-background') {
        // console.log(this.selectObjectGroup)
        this.selectObjectGroup.forEach((a) => {
          a.deselectObject();
        });
        this.selectObjectGroup = [];
        if (this.selectObject && this.selectObject.objectId) {
          this.selectObject.deselectObject();
          this.selectObject = null;
        }
      }
    },
    selectColorPicker(e) {
      if (this.selectObject && this.selectObject.objectId) {
        this.selectObject.getCaret();
      }
      e.preventDefault();
    },
    changeTextColor(color) {
      this.selectObject.getCaret();
      if (this.selectObject && this.selectObject.objectId) {
        this.selectObject.setColor(color);
      }
    },
    preventDefault(e) {
      e.preventDefault();
    },
    changeTextColorA() {
      if (this.selectObject && this.selectObject.objectId) {
        this.selectObject.setColorA();
      }
    },
    changeStrokeColor(color) {
      if (this.selectObject && this.selectObject.objectId && this.selectObject.objectType === 'line') {
        this.selectObject.setStrokeColor(color);
      }
    },
    changeTextSize(val) {
      if (this.selectObject && this.selectObject.objectId) {
        this.selectObject.setFontSize(val);
      }
    },
    changeFillinText(val) {
      if (this.selectObject && this.selectObject.objectId) {
        let a = this.fillinTextData.find((dt) => {
          return dt.value === val;
        });
        this.selectObject.setFillinValue(val, a.label);
      }
    },
    changeFontFamily(val) {
      if (this.selectObject && this.selectObject.objectId) {
        this.selectObject.setFontFamily(val);
      }
    },
    changeFontWeight() {
      let val = this.selectObject.fontWeight == 'bold' ? 'normal' : 'bold';
      // this.selectObject.fontWeight = val;
      if (this.selectObject && this.selectObject.objectId) {
        // this.selectObject.setFontWeight(val);
        this.selectObject.setBold(false);
      }
    },
    setTextAlignLeft() {
      if (this.selectObject && this.selectObject.objectId) {
        this.selectObject.setTextAlign('left');
      }
    },
    setTextAlignCenter() {
      if (this.selectObject && this.selectObject.objectId) {
        this.selectObject.setTextAlign('center');
      }
    },
    setTextAlignRight() {
      if (this.selectObject && this.selectObject.objectId) {
        this.selectObject.setTextAlign('right');
      }
    },
    setIsTunnel() {
      if (this.selectObject && this.selectObject.objectId && this.selectObject.objectType == 'line') {
        this.selectObject.setIsTunnel(!this.selectObject.isTunnel);
      }
    },
    setIsEndArrow() {
      if (this.selectObject && this.selectObject.objectId && this.selectObject.objectType == 'line') {
        this.selectObject.setIsEndArrow(!this.selectObject.isEndArrow);
      }
    },
    changeMessageSize(val) {
      switch (val) {
        case '2x10':
          this.canvasWidth = '640px';
          this.canvasHeight = '128px';
          this.canvasViewBox = `0 0 640 128`;
          this.editorWidth = 640;
          this.editorHeight = 128;
          this.$forceUpdate();
          break;
        case '2x12':
          this.canvasWidth = '768px';
          this.canvasHeight = '128px';
          this.canvasViewBox = `0 0 768 128`;
          this.editorWidth = 768;
          this.editorHeight = 128;
          this.$forceUpdate();
          break;
        // case '2x13':
        //   this.canvasWidth = '832px';
        //   this.canvasHeight = '128px';
        //   this.canvasViewBox = `0 0 832 128`;
        //   this.editorWidth = 832;
        //   this.editorHeight = 128;
        //   this.$forceUpdate();
        //   break;
        case '3x3':
          this.canvasWidth = '480px';
          this.canvasHeight = '480px';
          this.canvasViewBox = '0 0 480 480';
          this.editorWidth = 480;
          this.editorHeight = 480;
          this.$forceUpdate();
          break;
        case '10x18':
          this.canvasWidth = '576px';
          this.canvasHeight = '256px';
          this.canvasViewBox = '0 0 576 256';
          this.editorWidth = 576;
          this.editorHeight = 256;
          this.$forceUpdate();
          break;
        case '15x20':
          this.canvasWidth = '640px';
          this.canvasHeight = '480px';
          this.canvasViewBox = '0 0 640 480';
          this.editorWidth = 640;
          this.editorHeight = 480;
          this.$forceUpdate();
          break;
      }
    },
    setMakeLineGroup() {
      let groupId = uuid();
      this.selectObjectGroup.forEach((s) => {
        s.setLineGroupId(groupID);
      });
    },
    setCancleLineGroup() {
      this.selectObjectGroup.forEach((s) => {
        s.setLineGroupId('');
      });
    },
    preview() {
      if (this.selectObject && this.selectObject.objectId) {
        this.selectObject.deselectObject();
        this.selectObject = null;
      }
      this.previewDialog = true;
    },
    procPreviewImage() {
      setTimeout(() => {
        let me = this;
        let svg_xml = new XMLSerializer().serializeToString(this.$refs.vmsMessageSvg);
        /*console.log(typeof svg_xml)
                svgson.parse(svg_xml)
                .then(json=> {
                    console.log(JSON.stringify(json, null, 2))
                    let newSvg = stringify(json)
                    var img = new Image();                            
                    img.src = "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(newSvg)));

                    img.onload = function() {
                        me.$refs.previewImage.src = img.src
                        me.$refs.previewImage.width = img.width
                        me.$refs.previewImage.height = img.height                
                    }                
                })  */

        var img = new Image();
        img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svg_xml)));

        img.onload = function () {
          me.$refs.previewImage.src = img.src;
          me.$refs.previewImage.width = img.width;
          me.$refs.previewImage.height = img.height;
        };
      }, 100);
    },
    exportData() {
      return (
        new Promise((resolve, reject) => {
          if (this.selectObject && this.selectObject.objectId) {
            this.selectObject.deselectObject();
            this.selectObject = null;
            // this.selectObject.objectType =""
          }
          setTimeout(() => {
            let me = this;
            let svg_xml = new XMLSerializer().serializeToString(this.$refs.vmsMessageSvg);
            var img = new Image();
            img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svg_xml)));

            img.onload = () => {
              var ctx = this.$refs.dummyCanvas.getContext('2d');
              ctx.drawImage(img, 0, 0);
              return resolve({
                img: this.$refs.dummyCanvas.toDataURL('image/png'),
                imgSvg: svg_xml,
              });
            };
          }, 100);
        })
          // .then((svg) => {
          //     return new Promise((resolve, reject) => {
          //         canvg(this.$refs.dummyCanvas, svg, {renderCallback: () => {
          //             //return resolve(this.$refs.dummyCanvas.toDataURL("image/png"))
          //         }})
          //     })
          // })
          .then((imgData) => {
            let textData = [];
            let textBlockData = [];
            let imageData = [];
            let lineData = [];
            if (this.$refs.textObject) {
              this.$refs.textObject.forEach((obj) => {
                textData.push(obj.exportData());
              });
            }
            if (this.$refs.textBlockObject) {
              this.$refs.textBlockObject.forEach((obj) => {
                textBlockData.push(obj.exportData());
              });
            }
            if (this.$refs.imageObject) {
              this.$refs.imageObject.forEach((obj) => {
                imageData.push(obj.exportData());
              });
            }
            if (this.$refs.lineObject) {
              this.$refs.lineObject.forEach((obj) => {
                lineData.push(obj.exportData());
              });
            }
            return {
              vmsMessageData: {
                vmsSize: 0,
                textData: textData,
                textBlockData: textBlockData,
                imageData: imageData,
                lineData: lineData,
                bmpColor: this.bmpColor,
                canvasWidth: this.canvasWidth,
                canvasHeight: this.canvasHeight,
                canvasViewBox: this.canvasViewBox,
              },
              img: imgData.img,
              imgSvg: imgData.imgSvg,
            };
          })
      );
    },
    importData(vmsMessageData) {
      this.vmsMessageData = vmsMessageData;
    },
    showEditor(value) {
      if (value) {
        this.bmpColor = value.pixelate;
      } else {
        this.bmpColor = '24bit';
      }
    },
  },
};
</script>

<style scoped></style>

<style></style>
