var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dk-el-menu" }, [
    _c(
      "ul",
      _vm._l(_vm.item, function(data, index) {
        return _c("menu-item", {
          key: index,
          attrs: { data: data, itemIndex: index, selectItem: _vm.selectItem }
        })
      }),
      1
    ),
    _c(
      "div",
      {
        class: [
          _vm.selectDepth1Item && _vm.selectDepth1ItemData.children ? "on" : "",
          "depth2-panel"
        ]
      },
      [
        _c("h4", [_vm._v(_vm._s(_vm.selectDepth1ItemData.label))]),
        _c(
          "ul",
          _vm._l(_vm.selectDepth1ItemData.children, function(children, index) {
            return _c(
              "li",
              {
                key: index,
                on: {
                  click: function($event) {
                    return _vm.selectItem(children)
                  }
                }
              },
              [
                _c("span", { staticClass: "depth2-list-label" }, [
                  _vm._v(_vm._s(children.label))
                ])
              ]
            )
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }