var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vms-message-svg" }, [
    _c(
      "svg",
      {
        ref: "vmsMessageSvg",
        staticClass: "editor-canvas",
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          stroke: "none",
          fill: "none",
          overflow: "hidden",
          preserveAspectRatio: "none",
          width: _vm.canvasWrapWidth,
          height: _vm.canvasWrapHeight,
          viewBox: _vm.canvasViewBox
        }
      },
      [
        _c("g", [
          _c("rect", {
            staticClass: "rect-background",
            staticStyle: { fill: "#000000" },
            attrs: { width: _vm.canvasWidth, height: _vm.canvasHeight }
          })
        ]),
        _vm._l(_vm.vmsMessage.vmsMessageData.textData, function(item) {
          return _c("text-object", {
            key: item.objectId,
            ref: "textObject",
            refInFor: true,
            attrs: {
              inputX: item.inputX,
              inputY: item.inputY,
              objectId: item.objectId,
              isFillin: item.isFillin,
              objectData: item,
              bmpColor: _vm.bmpColor
            }
          })
        }),
        _vm._l(_vm.vmsMessage.vmsMessageData.imageData, function(item) {
          return _c("image-object", {
            key: item.objectId,
            ref: "imageObject",
            refInFor: true,
            attrs: {
              inputX: item.inputX,
              inputY: item.inputY,
              objectId: item.objectId,
              imageUrl: item.imageUrl,
              objectData: item,
              bmpColor: _vm.bmpColor
            }
          })
        }),
        _vm._l(_vm.vmsMessage.vmsMessageData.lineData, function(item) {
          return _c("line-object", {
            key: item.objectId,
            ref: "lineObject",
            refInFor: true,
            attrs: {
              objectId: item.objectId,
              objectData: item,
              bmpColor: _vm.bmpColor,
              tempVmsIfscInfo: item.vmsIfscInfo
            }
          })
        })
      ],
      2
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowMessageBox,
            expression: "isShowMessageBox"
          }
        ],
        ref: "msgBox",
        staticClass: "tooltip-box",
        style: _vm.tooltipPos
      },
      [_vm._v("\n    " + _vm._s(_vm.msg) + "\n  ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }