<template>
  <div id="dkScrollView">
    <div class="dk-scroll-view-header">
      <slot name="header"></slot>
    </div>
    <div class="dk-scroll-wrap">
      <div class="dk-scroll-view-body">
        <slot name="body"></slot>
      </div>
    </div>
    <div class="dk-scroll-view-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>
