var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { staticClass: "page-body content-wrap" },
        [
          _c("dk-page-header"),
          _c(
            "el-col",
            { staticClass: "page-content", attrs: { span: 24 } },
            [
              _c(
                "el-row",
                {
                  staticStyle: {
                    "border-top": "1px solid #dedede",
                    height: "100%"
                  }
                },
                [
                  _c(
                    "el-col",
                    { staticStyle: { height: "100%" }, attrs: { span: 11 } },
                    [
                      _c(
                        "el-card",
                        { attrs: { "body-style": { padding: "0px" } } },
                        [
                          _c(
                            "el-button",
                            { staticClass: "button", attrs: { type: "text" } },
                            [_vm._v("Operating button")]
                          ),
                          _c("img", {
                            staticClass: "image",
                            staticStyle: { height: "500px" },
                            attrs: { src: require("../ipes/201810231000.jpg") }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: {
                        height: "100%",
                        padding: "20px",
                        "border-right": "1px solid #dedede"
                      },
                      attrs: { span: 7 }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "el-dropdown",
                            [
                              _c("el-button", { attrs: { type: "primary" } }, [
                                _vm._v(
                                  "\n                                카메라 선택"
                                ),
                                _c("i", {
                                  staticClass:
                                    "el-icon-arrow-down el-icon--right"
                                })
                              ]),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown"
                                },
                                [
                                  _c("el-dropdown-item", [_vm._v("Action 1")]),
                                  _c("el-dropdown-item", [_vm._v("Action 2")]),
                                  _c("el-dropdown-item", [_vm._v("Action 3")]),
                                  _c("el-dropdown-item", [_vm._v("Action 4")]),
                                  _c("el-dropdown-item", [_vm._v("Action 5")])
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { data: _vm.tableData, height: "500" }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "date",
                                  label: "No",
                                  width: "70"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "name",
                                  label: "프리셋 명",
                                  width: "100"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "state",
                                  label: "단속지역명",
                                  width: "100"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: { fixed: "right", width: "120" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small"
                                            },
                                            on: { click: _vm.handleClick }
                                          },
                                          [_vm._v("수정")]
                                        ),
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small"
                                            }
                                          },
                                          [_vm._v("삭제")]
                                        )
                                      ]
                                    }
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { height: "100%", padding: "20px" },
                      attrs: { span: 6 }
                    },
                    [
                      _c("el-text", [_vm._v("오토 프리셋")]),
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949"
                        },
                        model: {
                          value: _vm.value2,
                          callback: function($$v) {
                            _vm.value2 = $$v
                          },
                          expression: "value2"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }