<!--
    메뉴인데.. element쓸려고 했는데 막상 쓸 부분이 없음.
-->
<template>
  <div class="dk-el-menu">
    <ul>
      <menu-item
        v-for="(data, index) in item"
        :key="index"
        :data="data"
        :itemIndex="index"
        :selectItem="selectItem"
      ></menu-item>
    </ul>
    <div
      :class="[
        selectDepth1Item && selectDepth1ItemData.children ? 'on' : '',
        'depth2-panel',
      ]"
    >
      <h4>{{ selectDepth1ItemData.label }}</h4>
      <ul>
        <li
          v-for="(children, index) in selectDepth1ItemData.children"
          :key="index"
          @click="selectItem(children)"
        >
          <!-- <span><i :class="[children.icon, 'depth2-list-icon']"></i></span> -->
          <span class="depth2-list-label">{{ children.label }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
var menuItem = {
  template: `
        <li
            @click="selectDepth1"
            :style="[isActive]"            
        >
            <span><i :style="[depth1Icon]" :class="[data.icon]"></i></span>
            <span :style="[depth1Label]">{{data.label}}</span>
        </li>
    `,
  props: {
    data: {
      type: Object,
    },
    itemIndex: {
      type: Number,
    },
    selectItem: {
      type: Function,
    },
  },
  data() {
    return {
      selected: false,
    };
  },
  computed: {
    depth1Icon() {
      const style = {
        fontSize: '20px',
        width: '56px',
        textAlign: 'center',
        color: '#fff',
      };
      return style;
    },
    depth1Label() {
      const style = {
        fontSize: '13px',
        display: 'inline-block',
        height: '24px',
        lineHeight: '24px',
        marginLeft: '10px',
      };
      return style;
    },
    isActive() {
      if (this.selected) {
        return {
          backgroundColor: 'rgba(29, 128, 185, 0.3)',
          borderLeft: `3px solid #ffffff`,
        };
      } else {
        return {};
      }
    },
  },
  methods: {
    selectDepth1() {
      this.selected = true;
      this.$parent.$emit('select-item', this);
      this.$forceUpdate();
      this.selectItem(this.data);
    },
    deactivate() {
      this.selected = false;
      this.$forceUpdate();
      return true;
    },
    getItemIndex() {
      return this.itemIndex;
    },
    getItemData() {
      return this.data;
    },
  },
};

export default {
  components: {
    'menu-item': menuItem,
  },
  created() {
    this.$on('select-item', (item) => {
      if (this.selectDepth1Item) {
        this.selectDepth1Item.deactivate();
        if (this.selectDepth1Item.getItemIndex() === item.getItemIndex()) {
          this.selectDepth1Item = null;
          return;
        }
      }
      this.selectDepth1Item = item;
      this.selectDepth1ItemData = item.getItemData();
    });
  },
  mounted: function () {},
  watch: {
    item(newVal) {
      this.$nextTick(() => {
        for (var i = 0; i < this.$children.length; i++) {
          let itemData = this.$children[i].getItemData();
          if (itemData.url) {
          }
          if (itemData.children) {
            let childLoopFlag = false;
            for (var j = 0; j < itemData.children.length; j++) {
              if (
                itemData.children[j].url &&
                this.$route.path.match(itemData.children[j].url)
              ) {
                this.$children[i].selectDepth1();
                childLoopFlag = true;
                break;
              }
            }
            if (childLoopFlag) break;
          }
        }
      });
    },
  },
  computed: {
    depth2Icon() {
      return {};
    },
    depth2Label() {
      return {
        fontSize: '13px',
        display: 'inline-block',
      };
    },
  },
  data() {
    return {
      selectDepth1Item: null,
      selectDepth1ItemData: {
        label: '',
        children: [],
      },
    };
  },
  props: {
    item: {
      type: Array,
      default: [],
    },
    selectItem: {
      type: Function,
      default: function (item) {},
    },
  },
  methods: {},
};
</script>

<style scoped></style>
