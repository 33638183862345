<template>
  <el-dialog class="form-linked-data" title="연계데이터 설정" :visible.sync="isFormDialog" width="65%" top="5vh">
    <div class="search-header">
      <el-row style="display: flex; align-items: center; margin-bottom: 15px;">
        <el-col :span="10">
          <el-input v-model="keyword" placeholder="검색" style="width: 250px;" size="small" @keyup.enter.native="searchFclt"></el-input>
          <el-button size="small" class="icon-button" @click="searchFclt">
            <i class="fas fa-search"></i>
          </el-button>
        </el-col>
        <el-col :span="7"
          ><el-select v-model="fcltTypeData" multiple placeholder="연계제외유형선택">
            <el-option v-for="item in fcltType" :key="item.fcltTypeId" :label="item.fcltTypeName" :value="item.fcltTypeId"> </el-option> </el-select
        ></el-col>
        <el-col :span="7">연계할 시설물 개수 {{ totalDocs - checkedData.length }} / {{ totalDocs }}</el-col>
      </el-row>
    </div>
    <el-container class="dialog-container maintnce-container">
      <el-row style="width: 100%;">
        <el-col :span="14">
          <el-table :data="fcltData" :height="600" highlight-current-row @current-change="handleCurrentChange">
            <el-table-column prop="fcltName" label="시설물 명칭" align="center"></el-table-column>
            <el-table-column prop="fcltTypeName" label="시설물 유형 명칭" align="center"></el-table-column>
          </el-table>
          <dk-el-pagination :totalDocs="totalDocs" :limit="limit" :pagingProc="pagingProc" ref="pagination"></dk-el-pagination>
        </el-col>
        <el-col :span="10">
          <ul class="filtered-list">
            <li v-for="(data, seq) in checkedData" :key="seq">
              {{ data.fcltName }}
              <span @click="removeCheckData(data)">삭제</span>
            </li>
          </ul>
        </el-col>
      </el-row>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">저장</el-button>
      <el-button @click="hideDialog">취소</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _ from 'lodash';
import dkelpagination from '@/components/dkElPagination.vue';
export default {
  components: {
    'dk-el-pagination': dkelpagination,
  },
  data: () => ({
    isFormDialog: false,
    selectSendOrgKey: '아산시청',
    keyword: '',
    limit: 10,
    totalDocs: 0,
    fcltData: [],
    checkedData: [],
    fcltTypeData: [],
    fcltTypeLength: 0,
    fcltType: [],
    value: [],
  }),
  async mounted() {
    let userId = this.$store.getters['auth/getActiveUser'].userid;
    const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltType`, {});
    let selectFclts = await this.$http.post(`${this.$config.getServerConfig().core.api}/asan/api/getExceptData`, { userId: userId });
    this.checkedData = selectFclts.data.exceptLinkData;
    this.fcltTypeData = selectFclts.data.exceptTypeData;
    this.fcltType = response.data.docs;
  },
  watch: {
    async fcltTypeData(newVal, oldVal) {
      let me = this;
      let response;
      let oldData = oldVal;
      let removeData;
      if (oldVal.length > newVal.length) {
        if (newVal.length == 0) {
          me.checkedData = [];
          return;
        }
        for (let i = 0; i < newVal.length; i++) {
          let index = oldVal.findIndex((ele) => ele == newVal[i]);
          if (index != -1) {
            oldData.splice(index, 1);
          }
        }
        for (let i = 0; i < oldData.length; i++) {
          response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/fclt`, { search: { fcltTypeId: oldData[i] } });
          this.removeData = response.data;
        }
        for (let i = 0; i < me.checkedData.length; i++) {
          for (let j = 0; j < this.removeData.length; j++) {
            if (me.checkedData[i].fcltName == this.removeData[j].fcltName) {
              me.checkedData.splice(i, 1);
            }
          }
        }
        return;
      }
      if (newVal.length > 0) {
        if (newVal.length == 1) {
          response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/fclt`, { search: { fcltTypeId: newVal[0] } });
        } else if (newVal.length > 1) {
          response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/fclt`, { search: { fcltTypeId: newVal[newVal.length - 1] } });
        }
        if (this.checkedData.length != 0) {
          let tempData = [...me.checkedData, ...response.data];
          let result = tempData.filter((v, i) => tempData.findIndex((x) => x.fcltName === v.fcltName) === i);
          me.checkedData = [...result];
        }
        if (this.checkedData.length == 0) {
          me.checkedData = response.data;
        }
      }
    },
  },
  methods: {
    async showDialog() {
      // sendDataSet 설정 불러오기
      this.getFclts(1);
      this.isFormDialog = true;
      // console.log(this.fcltTypeData);
    },
    handleCurrentChange(val) {
      if (val) {
        let a = _.findIndex(this.checkedData, (cd) => {
          return cd.fcltId == val.fcltId;
        });
        if (a <= -1) {
          this.checkedData.push(val);
        }
      }
    },

    async getFclts(pageNum) {
      let me = this;
      let param = {
        page: pageNum || this.$refs.pagination.getPage(),
        limit: this.limit,
      };
      if (this.keyword !== '' && this.keyword) {
        param.keyword = this.keyword;
      }
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fclt`, {
        params: param,
      });
      me.fcltData = response.data.docs;
      me.totalDocs = response.data.totalDocs;
      me.fcltData.forEach((fd) => {
        let idx = _.findIndex(this.checkedData, (val) => {
          fd.fcltId === val.fcltId;
        });
        if (idx > -1) {
          this.$refs[fd.fcltId].checked = true;
        } else {
          if (this.$refs[fd.fcltId]) this.$refs[fd.fcltId].checked = false;
        }
      });
    },
    pagingProc(pageNum) {
      this.getFclts(pageNum);
    },
    searchFclt(event) {
      this.getFclts();
    },
    async save() {
      try {
        let userId = this.$store.getters['auth/getActiveUser'].userid;
        const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/asan/api/saveLinkData`, {
          selectFcltType: this.fcltTypeData,
          checkedData: this.checkedData,
          userId: userId,
        });
        this.$message({
          type: 'success',
          message: `연계 데이터 설정완료`,
        });
        this.hideDialog();
      } catch (error) {
        this.$message({
          type: 'error',
          message: `연계 데이터 설정 에러`,
        });
      }
    },
    hideDialog() {
      this.page = 1;
      this.isFormDialog = false;
    },
    removeCheckData(fcltData) {
      _.remove(this.checkedData, (val) => {
        return val.fcltId === fcltData.fcltId;
      });
      console.log(this.checkedData);
      this.$forceUpdate();
    },
  },
};
</script>
