<template>
  <el-dialog :title="formTitle" :visible.sync="formDialog" @close="hideDialog" :close-on-click-modal="false" top="5vh">
    <div>
      <el-form :model="formData" label-width="80px" size="medium" :rules="rules" ref="form0" :label-position="$isMobile ? 'top' : 'top'">
        <el-row type="flex" align="middle" style="flex-direction: column;">
          <el-col :span="22">
            <el-form-item label="제목" prop="title">
              <el-input v-model="formData.title" placeholder="공지사항 제목을 입력하세요"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="22">
            <el-form-item label="내용" prop="text">
              <el-input type="textarea" :autosize="{ minRows: 10 }" v-model="formData.text" placeholder="공지사항 내용"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="팝업 여부">
              <el-switch v-model="formData.isPopup" @change="changeIsPopup" style="margin-right: 10px;"></el-switch>
              <el-date-picker
                type="date"
                v-model.lazy="formData.endDate"
                placeholder="팝업 종료 일자"
                ref="popupEndDate"
                :disabled="!formData.isPopup"
                :picker-options="pickerOptions1"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="22">
            <el-form-item label="첨부파일">
              <dk-el-file-upload ref="uploadImage" path="notice" :fileList="formData.images">
                <div style="text-align: left;">
                  <el-button size="small" type="primary">첨부파일 업로드</el-button>
                  <!-- <div slot="tip" class="el-upload__tip">
                    이미지 파일만 업로드할 수 있습니다
                  </div> -->
                </div>
              </dk-el-file-upload>
              <!--
                <el-upload
                    :action="uploadPath"
                    list-type="picture"                        
                    name="uploadFile"
                    :data="{uploadPath:'notice'}"
                    :before-upload="checkUploadImage"
                    :on-change="procUploadImage"
                    :on-remove="procRemoveImage"
                    :file-list="formData.images"
                    >                        
                    <el-button size="small" type="primary">관련사진 업로드</el-button>
                    <div slot="tip" class="el-upload__tip">이미지 파일만 업로드할 수 있습니다</div>
              </el-upload>-->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="form-bottom">
        <el-button @click="hideDialog" style="float: right;">취소</el-button>
        <el-button type="primary" @click="save" style="float: right; margin-right: 5px;">저장</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import dkElFileUpload from '@/components/dkElFileUpload.vue';
import moment from 'moment';

export default {
  components: {
    'dk-el-file-upload': dkElFileUpload,
  },
  data() {
    return {
      systemLogInfo: {},
      formDialog: false,
      formTitle: '',
      formData: {},
      rules: {
        title: [
          {
            required: true,
            message: '공지사항 제목을 입력해 주세요',
            trigger: ['change', 'blur'],
          },
        ],
      },
      disabledDates: [],
      pickerOptions1: {
        disabledDate(time) {
          return moment(time).isBefore(moment().add(-1, 'days'));
        },
      },
    };
  },
  created() {},
  methods: {
    async save() {
      this.$refs.form0.validate(async (valid) => {
        try {
          let me = this;
          if (valid) {
            this.formData.images = this.$refs.uploadImage.getFileData();
            let saveFunc = 'notice/add';
            if (this.updateFlag) {
              saveFunc = 'notice/update';
            }
            let getName = this.$store.getters['auth/getActiveUser'].name;
            let getUserId = this.$store.getters['auth/getActiveUser'].userid;
            this.formData.writerName = getName;
            this.formData.writerId = getUserId;
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, { data: me.formData });
            me.$parent.$emit('procDataComplete', me.formData);
            me.hideDialog();
            me.$message({
              type: 'success',
              message: `공지사항 등록 완료`,
            });
            me.systemLogInfo.page = `${me.$route.name}`;
            me.systemLogInfo.saveType = saveFunc;
            await me.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: me.systemLogInfo });
          } else {
            return false;
          }
        } catch (error) {
          this.hideDialog();
          this.$message.error(`공지사항 등록 중 에러 발생`);
        }
      });
    },
    showDialog(row) {
      if (row) {
        this.updateFlag = true;
        this.formTitle = '공지사항 수정';
        this.formData = this.$lodash.cloneDeep(row);
      } else {
        this.updateFlag = false;
        this.formTitle = '공지사항 추가';
        this.resetData();
      }
      this.formDialog = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        if (row) {
          this.$refs.uploadImage.setFileData(row.images);
        } else {
          this.$refs.uploadImage.setFileData(null);
        }
      });
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    resetData() {
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      this.formData = {
        title: '',
        text: '',
        images: [],
        endDate: tomorrow,
        isPopup: false,
      };
      if (this.$refs.form0) {
        this.$refs.form0.resetFields();
      }
    },

    changeIsPopup(val) {
      if (val) {
        //if (!this.formData.endDate) {
        //  this.formData.endDate = new Date();
        //}
      }
    },
    test() {
      this.$refs['popupEndDate'];
    },
  },
};
</script>
