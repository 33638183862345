<template>
  <el-dialog
    id="formMaintnceTemplate"
    class="template-form-dialog"
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
    @opened="createTemplateForm"
    width="1200px"
    top="8vh"
  >
    <div class="wrap">
      <div class="template-list">
        <dk-scroll-view>
          <template v-slot:header>
            <div class="template-header">
              <h4>템플릿 목록</h4>
            </div>
          </template>
          <template v-slot:body>
            <div v-for="(item, idx) in templateList" :key="idx" class="template-list-cell">
              <span class="template-name">
                {{ item.templateName }}
              </span>
              <span class="template-button">
                <el-button @click="updateTemplate(item)" size="mini">수정</el-button>
                <el-button @click="deleteTemplate(item)" size="mini" type="danger">삭제</el-button>
              </span>
            </div>
            <div class="template-button-wrap">
              <el-button size="small" @click="addTemplate">템플릿 추가</el-button>
            </div>
          </template>
        </dk-scroll-view>
      </div>
      <div class="form-wrap">
        <div class="dk-form" style="padding-top: 30px;" v-if="templateData">
          <el-form label-position="left" :model="formData" label-width="170px" size="small" :rules="rules" ref="form0">
            <el-form-item label="템플릿 명칭" prop="templateName">
              <el-input v-model="formData.templateName" size="mini" placeholder="유지관리 작업 템플릿 명칭"></el-input>
            </el-form-item>
            <!-- <el-form-item lbel="관련 시설물 유형" prop="relatedFcltTypes">
          <el-select v-model="relatedFcltTypes" multiple allow-create default-first-option size="mini" placeholder="옵션 추가" style="width: 100%;">
            <el-option v-for="item in fcltTypesOptions" :key="item.fcltTypeId" :label="item.fcltTypeName" :value="item.fcltTypeId"> </el-option>
          </el-select>
        </el-form-item> -->
          </el-form>
        </div>
        <dk-form ref="dkTemplateForm" formType="template" @renderAfter="createTemplateForm" v-show="templateData"></dk-form>
        <div v-show="!templateData" class="no-template-data">
          유지관리 템플릿을 추가하거나 등록된 템플릿을 선택해주세요
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="saveTemplateData">저장</el-button>
      <el-button @click="formDialog = false">닫기</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _ from 'lodash';
import dkTemplateForm from '@/components/dkTemplate/dkTemplateForm';
import dkTemplateUtil from '@/components/dkTemplate/dkTemplateUtil';
import dkScrollView from '@/components/dkScrollView.vue';

export default {
  components: {
    'dk-form': dkTemplateForm,
    'dk-scroll-view': dkScrollView,
  },
  data() {
    return {
      systemLogInfo : {},
      formDialog: false,
      formTitle: '시설물 유지관리 작업 템플릿 관리',
      formData: {
        templateName: '',
        keyword: '',
        featureId: this.$route.meta.featureId,
      },
      fcltTypeData: null,
      updateFlag: false,
      rules: {
        templateName: [
          {
            required: true,
            message: '유지관리 작업 템플릿 명칭을 입력하여 주십시오',
            trigger: ['blur', 'change'],
          },
        ],
      },
      templateData: null,
      templateList: [],
      selectedTemplate: null,
      // fcltTypesOptions: [],
    };
  },
  mounted() {},
  methods: {
    resetData() {
      this.formData = {
        templateName: '',
        templateData: [],
        featureId: this.$route.meta.featureId,
      };
      this.$refs.dkTemplateForm.resetForm();
      this.fcltTypeData = null;
    },
    hideDialog() {
      this.formDialog = false;
      this.templateData = null;
      this.resetData();
      this.$emit('registerdTemplate');
    },

    // async getFcltTypes() {
    //   this.fcltTypesOptions = [];
    //   let response = await this.$http.get('/coreapi/core/api/fcltType');
    //   this.fcltTypesOptions = response.data.docs;
    // },

    showDialog(fcltTypeData) {
      // console.log(this.$route.meta.featureId);
      this.formDialog = true;
      if (!fcltTypeData) {
        return;
      }
      this.fcltTypeData = fcltTypeData;
      this.getTemplates();
    },
    createTemplateForm() {
      if (this.$refs.dkTemplateForm && this.templateData && this.templateData.length > 0) {
        this.$refs.dkTemplateForm.createTemplateForm(this.templateData);
      }
    },
    async getTemplates() {
      const param = {
        templateId: this.fcltTypeData.templates,
        featureId: this.$route.meta.featureId,
      };

      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/templateByFeatureId`, {
        params: param,
      });
      this.templateList = response.data;
      if (this.templateList && this.templateList.length > 0) {
        this.selectedTemplate = this.templateList[0];
      }
    },
    saveTemplateData() {
      // console.log(this.templateData);
      let me = this;
      try {
        this.$refs.form0.validate(async (valid) => {
          if (valid) {
            let saveFunc = 'template/add';
            if (this.updateFlag) {
              saveFunc = 'template/update';
            }
            this.formData.templateData = this.$refs.dkTemplateForm.exportTemplateData();
            const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, { data: this.formData });
            this.systemLogInfo.page = `${this.$route.name} ${response.data.templateName} 유지관리 템플릿`
            if (me.fcltTypeData.templates) {
              if (response.data && response.data.templateId) {
                const idx = this.fcltTypeData.templates.findIndex((val) => {
                  return val === response.data.templateId;
                });
                if (idx <= -1) {
                  this.fcltTypeData.templates.push(response.data.templateId);
                }
              }
            } else {
              this.fcltTypeData.templates = [];
              if (response.data && response.data.templateId) {
                this.fcltTypeData.templates.push(response.data.templateId);
              }
            }

            const res = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/fcltType/update`, { data: this.fcltTypeData });
            this.fcltTypeData = res.data;

            this.hideDialog();
            this.$message({
              type: 'success',
              message: `템플릿 정보 등록 완료`,
            });
            this.systemLogInfo.saveType = saveFunc
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
          }
        });
      } catch (err) {
        this.hideDialog();
        this.$message.error(`템플릿 정보 등록 중 에러 발생`);
      }
    },
    addTemplate() {
      this.$refs.dkTemplateForm.resetForm();
      this.updateFlag = false;
      this.templateData = [];
      this.formData = {
        templateName: '',
        templateData: [],
        featureId: this.$route.meta.featureId,
      };
      this.createTemplateForm();
    },
    updateTemplate(item) {
      this.$refs.dkTemplateForm.resetForm();
      this.updateFlag = true;
      this.templateData = item.templateData || [];
      this.formData = item;
      this.createTemplateForm();
    },
    deleteTemplate(item) {
      this.$confirm(`${item.templateName} 템플릿을 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/template/delete`, { data: item });
            this.$message({
              type: 'success',
              message: `${item.templateName} 템플릿 삭제 완료`,
            });
            this.systemLogInfo.page = `${this.$route.name} ${item.templateName}`
            this.systemLogInfo.saveType = "delete"
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
            if (this.fcltTypeData.templates) {
              if (item.templateId) {
                const idx = this.fcltTypeData.templates.findIndex((val) => {
                  return val === item.templateId;
                });
                if (idx > -1) {
                  console.log(idx);
                }
              }
            }
            console.log(this.fcltTypeData.templates);
            const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/fcltType/update`, { data: this.fcltTypeData });
            this.fcltTypeData = response.data;

            await this.getTemplates();
            this.resetData();
          } catch (err) {
            this.$message({
              type: 'info',
              message: `${item.templateName} 템플릿 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${item.templateName} 템플릿 삭제 취소`,
          });
        });
    },
  },
};
</script>
