var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { id: "pixelateWrap" } }, [
      _c("canvas", {
        ref: "controlCanvas",
        attrs: { id: "controlCanvas" },
        on: {
          mousedown: _vm.onMousedown,
          mouseup: _vm.onMouseup,
          mousemove: _vm.onMousemove
        }
      }),
      _c("img", { ref: "image", attrs: { id: "image" } })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }