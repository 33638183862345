<template>
    <div>
        모바일 테스트화면
    </div>
</template>

<script>
export default {
    components: {},
    data: ()=>({}),
    created() {},
    mounted() {},
    computed: {},
    methods: {},
}
</script>