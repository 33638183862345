var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notice" },
    [
      _vm.totalDocs > 0
        ? _c(
            "div",
            [
              _vm._l(_vm.notices, function(item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "notice-card",
                    on: {
                      click: function($event) {
                        return _vm.selectRow(item)
                      }
                    }
                  },
                  [
                    _c("div", [
                      _c("span", { staticClass: "notice-title" }, [
                        _vm._v(_vm._s(item.title))
                      ]),
                      _c("span", { staticClass: "notice-date" }, [
                        _vm._v(_vm._s(_vm.getWriteDate(item.writeDate)))
                      ])
                    ])
                  ]
                )
              }),
              _vm.displayPaging
                ? _c("dk-el-pagination", {
                    ref: "pagination",
                    attrs: {
                      totalDocs: _vm.totalDocs,
                      limit: _vm.limit,
                      pagingProc: _vm.pagingProc
                    }
                  })
                : _vm._e()
            ],
            2
          )
        : _c("div", [_vm._v("등록된 공지사항이 없습니다")]),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-notice",
          attrs: { visible: _vm.noticeShow },
          on: {
            "update:visible": function($event) {
              _vm.noticeShow = $event
            }
          }
        },
        [
          _c(
            "el-carousel",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.selectNotice.images &&
                    _vm.selectNotice.images.length > 0,
                  expression:
                    "selectNotice.images && selectNotice.images.length > 0"
                }
              ],
              attrs: { height: "400px" }
            },
            _vm._l(_vm.selectNotice.images, function(file, index) {
              return _c("el-carousel-item", { key: index }, [
                _c("div", { staticClass: "dk-carousel-item" }, [
                  _c("img", {
                    attrs: {
                      src:
                        _vm.$config.getServerConfig().image.url +
                        file.serverFilePath
                    }
                  })
                ])
              ])
            }),
            1
          ),
          _vm.selectNotice.images && _vm.selectNotice.images.length > 0
            ? _c("div", { staticStyle: { padding: "0 30px 20px 30px" } }, [
                _c("h3", [_vm._v(_vm._s(_vm.selectNotice.title))]),
                _c("h5", [_vm._v(_vm._s(_vm.selectNotice.text))])
              ])
            : _c("div", { staticStyle: { padding: "30px 30px 20px 30px" } }, [
                _c("h2", [_vm._v(_vm._s(_vm.selectNotice.title))]),
                _c("h4", [_vm._v(_vm._s(_vm.selectNotice.text))])
              ])
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog-notice",
          attrs: { visible: _vm.dailyNoticeShow },
          on: {
            "update:visible": function($event) {
              _vm.dailyNoticeShow = $event
            }
          }
        },
        [
          _c(
            "el-carousel",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.selectNotice.images &&
                    _vm.selectNotice.images.length > 0,
                  expression:
                    "selectNotice.images && selectNotice.images.length > 0"
                }
              ],
              attrs: { height: "400px" }
            },
            _vm._l(_vm.selectNotice.images, function(file, index) {
              return _c("el-carousel-item", { key: index }, [
                _c("div", { staticClass: "dk-carousel-item" }, [
                  _c("img", {
                    attrs: {
                      src:
                        _vm.$config.getServerConfig().image.url +
                        file.serverFilePath
                    }
                  })
                ])
              ])
            }),
            1
          ),
          _vm.selectNotice.images && _vm.selectNotice.images.length > 0
            ? _c("div", { staticStyle: { padding: "0 30px 20px 30px" } }, [
                _c("h3", [_vm._v(_vm._s(_vm.selectNotice.title))]),
                _c("h5", [_vm._v(_vm._s(_vm.selectNotice.text))])
              ])
            : _c("div", { staticStyle: { padding: "30px 30px 20px 30px" } }, [
                _c("h2", [_vm._v(_vm._s(_vm.selectNotice.title))]),
                _c("h4", [_vm._v(_vm._s(_vm.selectNotice.text))])
              ]),
          _vm.isDailyPopup
            ? _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.dailyClose } },
                    [_vm._v("하루동안 이 공지를 열지 않습니다")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }