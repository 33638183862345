<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    top="5vh"
    width="80%"
    @close="hideDialog"
    @open="openDialog"
    :close-on-click-modal="false"
  >
    <div>
      <el-row>
        <el-col :span="10">
          <dk-gis
            ref="fcltManageDialogGis"
            style="height:500px"
            :markerData="markerData"
            :gisApiInfo="gisApiInfo"
            :apiLoadComplete="fcltManageDialogGisInit"
            :markerDragEnd="markerDragEnd"
            :trafficLayerData="trafficLayerData"
            :markerInfoWindow="false"
          >
          </dk-gis>
        </el-col>
        <el-col :span="14">
          <el-form
            :model="formData"
            label-width="100px"
            size="mini"
            :rules="rules"
            ref="form0"
            :label-position="$isMobile ? 'top' : 'right'"
          >
            <el-row>
              <el-col :span="18">
                <el-form-item label="돌발내용" prop="happeningText">
                  <el-input v-model="formData.happeningText" placeholder="사고/돌발 내용을 입력하세요"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="돌발유형" prop="incidentType">
                  <el-select v-model="formData.incidentType" placeholder="돌발유형">
                    <el-option
                      v-for="(item, index) in incidentType"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="돌발일시" prop="title">
                  <el-date-picker
                    type="datetime"
                    v-model.lazy="formData.happeningTime"
                    placeholder="팝업 종료 일자"
                    ref="happeningTime"
                    style="width:100%;"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <h4>링크 구간</h4>
                <el-table :data="linkData" empty-text="링크정보 로딩 중입니다." size="mini" stripe>
                  <el-table-column prop="link_id" label="링크ID"> </el-table-column>
                  <el-table-column prop="road_name" label="도로명"> </el-table-column>
                  <el-table-column prop="f_node_name" label="시작구간"> </el-table-column>
                  <el-table-column prop="t_node_name" label="종료구간"> </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </el-form>
        </el-col>
      </el-row>
      <div class="form-bottom">
        <el-button @click="hideDialog" style="float:right;">
          취소
        </el-button>
        <el-button type="primary" @click="save" style="float:right; margin-right:5px;">
          저장
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import dkgismain from "@/components/dkGis/dkGisMain.vue"

export default {
  components: {
    "dk-gis": dkgismain,
  },
  data() {
    return {
      incidentType: [{ label: "사고", value: "1" }, { label: "고장", value: "2" }, { label: "공사", value: "3" }],
      incidentDetailType: [
        { label: "사고", value: "1" },
        { label: "고장", value: "2" },
        { label: "특별행사", value: "3" },
        { label: "공사", value: "4" },
      ],
      formDialog: false,
      formTitle: "",
      formData: {},
      linkData: [],
      rules: {},
      markerData: [
        {
          seq: 0,
          draggable: true,
          geometry: {
            lng: 127.10056150323506,
            lat: 37.489008855218195,
          },
          properties: {},
        },
      ],
      trafficLayerData: {
        base: [
          {
            name: "link_traf",
            url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
            //params: {'LAYERS': 'cite:gimpo_ifsc_traf', 'TILED': true,  'STYLES': 'cite:gimpo_utis_deselect_style' },
            params: { LAYERS: "cite:gimpo_link_comp", TILED: true, STYLES: "cite:gimpo_utis_deselect_style" },
            default: true,
            idKey: "link_id",
          },
        ],
        select: {
          source: {
            url: `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wms`,
          },
          mode: "Single",
        },
      },
      isGisInit: false,
    }
  },
  created() {},
  props: {
    gisApiInfo: {
      type: Array,
      // gis 기본 설정값을 띄우는 요부분은 나중에 vuex에서 받아서 처리할것
      default() {
        return []
      },
    },
    geometry: {
      type: Object,
      default: null,
    },
  },
  methods: {
    save() {
      this.$refs.form0.validate((valid) => {
        let me = this
        if (valid) {
          this.formData.images = this.$refs.uploadImage.getFileData()
          let saveFunc = "incident/addIncident"
          if (this.updateFlag) {
            saveFunc = "incident/updateIncident"
          }
          this.$store
            .dispatch(saveFunc, { data: this.formData })
            .then((response) => {
              me.$parent.$emit("procDataComplete", this.formData)
              me.resetData()
              me.hideDialog()
              me.$message({
                type: "success",
                message: `사고/돌발 등록 완료`,
              })
            })
            .catch((err) => {
              me.resetData()
              me.hideDialog()
              me.$message.error(`사고/돌발 등록 중 에러 발생`)
            })
        } else {
          return false
        }
      })
    },
    showDialog(row) {
      if (row) {
        this.updateFlag = true
        this.formTitle = "사고/돌발 등록"
        //this.formData = this.$lodash.cloneDeep(row.feature);
      } else {
        this.updateFlag = false
        this.formTitle = "사고/돌발 추가"
        this.resetData()
      }
      this.formDialog = true
      this.$forceUpdate()
      this.$nextTick(() => {})
    },
    openDialog() {
      this.fcltManageDialogGisInit()
    },
    hideDialog() {
      this.resetData()
      this.formDialog = false
    },
    resetData() {
      let tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      ;(this.formData = {
        title: "",
        text: "",
        images: [],
        endDate: tomorrow,
        isPopup: false,
      }),
        (this.linkData = [])
      this.isGisInit = false
    },

    changeIsPopup(val) {
      if (val) {
        //if (!this.formData.endDate) {
        //  this.formData.endDate = new Date();
        //}
      }
    },

    fcltManageDialogGisInit() {
      let me = this
      if (me.isGisInit) {
        return
      }
      me.isGisInit = true
      if (this.geometry && this.$refs.fcltManageDialogGis) {
        this.$refs.fcltManageDialogGis.setCenter(this.geometry)
        this.markerData[0].geometry = this.geometry
      } else if (this.$refs.fcltManageDialogGis) {
        let latlng = this.$refs.fcltManageDialogGis.getCenter()
        this.markerData[0].geometry = latlng
      }
      setTimeout(() => {
        this.$refs.fcltManageDialogGis.refresh()
        this.$refs.fcltManageDialogGis.setTrafficLayer(true)
      }, 100)
      setTimeout(() => {
        this.$refs.fcltManageDialogGis.getFeaturesAtCoord(this.geometry).then((feature) => {
          this.linkData.push(feature.properties)
        })
      }, 500)
    },

    markerDragEnd(result) {},

    setCenter(geometry) {
      this.$refs.fcltManageDialogGis.setCenter(geometry)
      this.markerData[0].geometry = geometry
    },
  },
}
</script>
