var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-width-height el-container" },
    [
      _c(
        "el-container",
        { staticClass: "page-body" },
        [
          _c(
            "el-main",
            [
              _c(
                "el-row",
                { staticClass: "content-wrap" },
                [
                  _c("dk-page-header"),
                  _c(
                    "el-col",
                    { staticClass: "page-content", attrs: { span: 24 } },
                    [
                      _c("dk-el-table-header", {
                        attrs: {
                          openFormDialog: _vm.openFormDialog,
                          isSearch: true,
                          search: _vm.search
                        }
                      }),
                      _c(
                        "el-table",
                        {
                          staticStyle: {
                            width: "100%",
                            "border-top": "1px solid #dedede"
                          },
                          attrs: {
                            data: _vm.materials,
                            "empty-text": "기자재 정보가 없습니다.",
                            size: "mini",
                            stripe: "",
                            "show-header": false
                          },
                          on: { "row-click": _vm.selectRow }
                        },
                        [
                          _c("el-table-column", {
                            attrs: { label: "썸네일", width: "150px" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "text-align": "center" }
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "material-thumb-img",
                                          attrs: {
                                            src: _vm.getThumbImg(scope.row)
                                          }
                                        })
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "내용", "min-width": "280px" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "material-contents" },
                                      [
                                        _c("h2", [
                                          _vm._v(_vm._s(scope.row.materialName))
                                        ]),
                                        _c(
                                          "p",
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.materialTypeName
                                                )
                                              )
                                            ]),
                                            _vm._l(
                                              scope.row.materialTypeData,
                                              function(data, index) {
                                                return _c(
                                                  "span",
                                                  { key: index },
                                                  [
                                                    _vm._v(
                                                      "\n                       / \n                      "
                                                    ),
                                                    data.label
                                                      ? _c("span", [
                                                          _vm._v(
                                                            _vm._s(data.label) +
                                                              " : "
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(data.value) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        ),
                                        scope.row.materialDesc
                                          ? _c("p", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.printDesc(
                                                    scope.row.materialDesc
                                                  )
                                                )
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: { label: "", width: "170px" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "small" },
                                        on: {
                                          click: function($event) {
                                            return _vm.openFormDialog(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("수정")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "small",
                                          type: "danger"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.removeMaterial(scope.row)
                                          }
                                        }
                                      },
                                      [_vm._v("삭제")]
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c("dk-el-pagination", {
                        ref: "pagination",
                        attrs: {
                          totalDocs: _vm.totalDocs,
                          limit: _vm.limit,
                          pagingProc: _vm.pagingProc
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("dk-info-panel", { ref: "infoPanel", attrs: { width: "400px" } }, [
        _c(
          "div",
          { staticClass: "info-panel-wrap" },
          [
            _c(
              "el-tabs",
              {
                model: {
                  value: _vm.activeTab,
                  callback: function($$v) {
                    _vm.activeTab = $$v
                  },
                  expression: "activeTab"
                }
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "기자재정보", name: "tab1" } },
                  [
                    _c("h3", [_vm._v(_vm._s(_vm.selectMaterial.materialName))]),
                    _vm.selectMaterial.images &&
                    _vm.selectMaterial.images.length > 0
                      ? _c(
                          "el-carousel",
                          { attrs: { height: "280px" } },
                          _vm._l(_vm.selectMaterial.images, function(
                            image,
                            index
                          ) {
                            return _c("el-carousel-item", { key: index }, [
                              _c(
                                "div",
                                {
                                  staticClass: "dk-carousel-item",
                                  staticStyle: { "background-color": "#fff" }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        _vm.$config.getServerConfig().image
                                          .url + image.serverFilePath
                                    }
                                  })
                                ]
                              )
                            ])
                          }),
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "dk-card" }, [
                      _c("div", { staticClass: "dk-card-title" }, [
                        _vm._v("기자재 정보")
                      ]),
                      _c(
                        "table",
                        { staticClass: "el-table el-table--mini" },
                        [
                          _c("tr", [
                            _c("th", { staticStyle: { width: "110px" } }, [
                              _vm._v("기자재 유형")
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.selectMaterial.materialTypeName)
                              )
                            ])
                          ]),
                          _vm._l(_vm.selectMaterial.materialTypeData, function(
                            mtData,
                            index
                          ) {
                            return _c("tr", { key: index }, [
                              _c("th", [_vm._v(_vm._s(mtData.label))]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    mtData.value ? mtData.value : "정보 없음"
                                  )
                                )
                              ])
                            ])
                          }),
                          _c(
                            "tr",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.selectMaterial.materialDesc,
                                  expression: "selectMaterial.materialDesc"
                                }
                              ]
                            },
                            [
                              _c("th", [_vm._v("설명")]),
                              _c("td", [
                                _vm._v(_vm._s(_vm.selectMaterial.materialDesc))
                              ])
                            ]
                          )
                        ],
                        2
                      )
                    ]),
                    _c("div", { staticClass: "dk-card" }, [
                      _c("div", { staticClass: "dk-card-title" }, [
                        _vm._v("장비 정의 데이터")
                      ]),
                      _c(
                        "table",
                        { staticClass: "el-table el-table--mini" },
                        [
                          _c("tr", [
                            _c("th", [_vm._v("데이터 명칭")]),
                            _c("th", [_vm._v("데이터 KEY")]),
                            _c("th", [_vm._v("데이터 유형")])
                          ]),
                          _vm._l(_vm.selectMaterial.equipData, function(
                            eData,
                            index
                          ) {
                            return _c("tr", { key: index }, [
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    eData.label ? eData.label : "정보 없음"
                                  )
                                )
                              ]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(eData.key ? eData.key : "정보 없음")
                                )
                              ]),
                              _c("td", [
                                eData.formType == "string"
                                  ? _c("span", [_vm._v("텍스트")])
                                  : eData.formType == "number"
                                  ? _c("span", [_vm._v("숫자")])
                                  : _c("span", [_vm._v("정보 없음")])
                              ])
                            ])
                          })
                        ],
                        2
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "관련자료", name: "tab2" } },
                  [
                    _c(
                      "div",
                      { staticClass: "dk-card" },
                      [
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: _vm.selectMaterial.files,
                              "empty-text":
                                "등록된 기자재 관련 자료가 없습니다.",
                              size: "mini",
                              stripe: ""
                            },
                            on: { "row-click": _vm.downloadReference }
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "파일 명" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "file-download-label" },
                                        [_vm._v(_vm._s(scope.row.name))]
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: { label: "파일 크기", width: "120px" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getHumanFileSize(scope.row.size)
                                        )
                                      )
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("form-material", { ref: "formDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }