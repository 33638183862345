<template>
  <div class="fill-width-height fill-wrap">
    <div class="header">
      <dk-page-header></dk-page-header>
    </div>
    <div class="work-report-wrap">
      <div class="user-list">
        <div class="user-list-header">
          담당자
        </div>
        <div v-for="(item, idx) in userList" :key="idx" class="user-list-body" :style="getUserItemHeight(item)">
          <span
            class="user-list-thumb-img"
            v-if="item.images && item.images.length > 0"
            :style="'background-image:url(' + $config.getServerConfig().image.url + item.images[0].serverFilePath + ')'"
          ></span>
          <span v-else class="user-list-thumb-img" style="background-image: url(static/images/unknown.png);"></span>
          <span>
            <div class="user-list-name">{{ item.name }}</div>
            <div class="user-list-group">{{ item.groupName }}</div>
          </span>
        </div>
      </div>
      <div class="weekly-work-report">
        <div class="weekly-work-report-table">
          <div class="date-cell">
            <div class="date-month">
              <div v-for="(item, idx) in finalWeekNumber" :key="idx" class="date-month-cell" :style="getMonthPosition(item - 1)">{{ item }} 주</div>
            </div>
            <div class="date-day">
              <div v-for="(item, idx) in dateData" :key="idx" class="date-day-cell" :style="getDayPosition(item)">
                {{ item.date.getDate() }}
              </div>
            </div>
          </div>
          <div class="work-cell-wrap" :style="getWorkCellSize()">
            <div v-for="(userItem, idx) in userList" :key="idx" class="work-cell-line" :style="getUserItemHeight(userItem)">
              <div class="work-cell" v-for="ii in diffDays" :key="ii">
                <button @click="registerWorkReport(userItem, ii, $event)"></button>
              </div>
              <div class="progress-cell" v-for="(a, ia) in getWorkDataByUserid(userItem.userid)" :key="ia" :style="getWorkProgressStyle(a)">
                <button @click="updateWorkReport(userItem, a, $event)">{{ a.workDesc }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="work-report-dialog" ref="formWorkReport" v-if="isDialogOpen" :style="getFormDialogStyle()">
      <el-form ref="form" :model="formData" label-width="80px">
        <el-form-item label="보고자">
          {{ selectedUserData.name }}
        </el-form-item>
        <el-form-item label="처리 기간">
          <el-col :span="11">
            <el-date-picker
              v-model="formData.startDate"
              type="date"
              placeholder="시작일"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              default-time="00:00:00"
              style="width: 100%;"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="2" style="text-align: center;">~</el-col>
          <el-col :span="11">
            <el-date-picker
              v-model="formData.endDate"
              type="date"
              placeholder="종료일"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              default-time="23:59:59"
              style="width: 100%;"
            >
            </el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="장소">
          <el-input v-model="formData.place"></el-input>
        </el-form-item>
        <el-form-item label="담당">
          <el-input v-model="formData.worker"></el-input>
        </el-form-item>
        <el-form-item label="내용">
          <el-input type="textarea" v-model="formData.workDesc"></el-input>
        </el-form-item>
        <div>
          <el-button type="danger" v-if="isUpdate" @click="removeData" style="float: left;" icon="el-icon-delete"></el-button>
          <el-button @click="isDialogOpen = false" style="float: right; margin: 0 4px;">취소</el-button>
          <el-button type="primary" @click="saveData" style="float: right; margin: 0 4px;">저장</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import _ from 'lodash';
export default {
  components: {},
  computed: {},
  data() {
    return {
      userList: [],
      dateData: [],
      workData: [],
      finalWeekNumber: -1,
      currentWeekNumber: -1,
      firstDay: null,
      finalDay: null,
      diffDays: -1,
      formData: {
        _id: '',
        startDate: null,
        endDate: null,
        place: '',
        workDesc: '',
        worker: '',
        userid: '',
      },
      selectedUserData: {
        name: '',
        userid: '',
      },
      isDialogOpen: false,
      xDialogPos: 0,
      yDialogPos: 0,
      dialogWidth: 450,
      isUpdate: false,
    };
  },
  mounted() {},
  created() {
    this.getUsers();
    this.getWeekData();
    this.getWorkData();
  },
  methods: {
    async getUsers() {
      try {
        let me = this;
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/user`, {});
        me.userList = response.data.docs;
      } catch (error) {
        this.$message({
          type: 'danger',
          message: `사용자목록 로드중 에러발생`,
        });
      }
    },
    async getWorkData() {
      try {
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/workReport`, {});
        const tmpData = response.data.docs;
        this.workData = {};
        let groupData = _.groupBy(tmpData, 'userid');
        _.map(groupData, (val, key) => {
          this.workData[key] = {};
          this.workData[key].data = _.map(val, (v2) => {
            v2.startIdx = this.getDateIndex(v2.startDate);
            v2.endIdx = this.getDateIndex(v2.endDate);
            return v2;
          });
          for (var i = 0; i < this.workData[key].data.length; i++) {
            let chk = 0;
            let lineIdx = 1;
            for (var j = 0; j < this.workData[key].data.length; j++) {
              if (i !== j) {
                let a = this.getOverlabCount([this.workData[key].data[i], this.workData[key].data[j]]);
                if (a > 1) {
                  chk++;
                  if (this.workData[key].data[j].lineIdx) lineIdx = Math.max(lineIdx, this.workData[key].data[j].lineIdx + 1);
                }
              }
            }
            this.workData[key].data[i].overlabCount = chk;
            this.workData[key].data[i].lineIdx = lineIdx;
          }

          // this.workData[key].lineCount = this.getOverlabCount(this.workData[key].data);
          this.workData[key].lineCount = _.maxBy(this.workData[key].data, 'lineIdx').lineIdx;
        });
      } catch (err) {
        console.error(err);
      }
    },
    getWeekData() {
      const today = new Date();
      const currentMonth = today.getMonth();
      const currentYear = today.getFullYear();
      this.currentWeekNumber = Math.ceil(today.getDate() / 7);
      this.finalWeekNumber = Math.ceil(this.daysInMonth(today.getFullYear(), today.getMonth() + 1) / 7);
      let firstDay = new Date(currentYear, currentMonth, 1);
      if (firstDay.getDay() !== 0) {
        firstDay.setDate(firstDay.getDate() - firstDay.getDay());
      }
      this.firstDay = firstDay;
      let finalDay = new Date(currentYear, currentMonth, this.daysInMonth(today.getFullYear(), today.getMonth() + 1));
      if (finalDay.getDay() !== 6) {
        finalDay.setDate(finalDay.getDate() + (7 - finalDay.getDay()));
      }
      this.finalDay = finalDay;
      const diffTime = Math.abs(finalDay - firstDay);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      this.diffDays = diffDays;
      for (var i = 0; i < diffDays; i++) {
        let tmpDate = new Date(firstDay.getTime());
        tmpDate.setDate(tmpDate.getDate() + i);
        this.dateData.push({
          date: tmpDate,
          week: Math.ceil((i + 1) / 7),
          idx: i,
        });
      }
    },
    daysInMonth(year, month) {
      return new Date(year, month, 0).getDate();
    },
    getDayPosition(item) {
      let color = '#667280';
      if (item.date.getDay() === 0) {
        color = '#e24f48';
      } else if (item.date.getDay() === 0) {
        color = '#4894e2';
      }

      return {
        left: item.idx * 40 + 'px',
        color: color,
      };
    },
    getMonthPosition(idx) {
      return {
        left: idx * 280 + 'px',
      };
    },
    getWorkCellSize() {
      return {
        width: this.finalWeekNumber * 280 + 'px',
      };
    },
    getUserItemHeight(user) {
      if (this.workData[user.userid] && this.workData[user.userid].lineCount) {
        return {
          height: 52 * this.workData[user.userid].lineCount + 'px',
        };
      }
    },
    getWorkProgressStyle(item) {
      return {
        top: (item.lineIdx - 1) * 52 + 4 + 'px',
        left: item.startIdx * 40 + 'px',
        width: (item.endIdx - item.startIdx + 1) * 40 + 'px',
      };
    },
    registerWorkReport(user, idx, e) {
      let startDate = new Date(this.firstDay.getTime());
      let endDate = new Date(this.firstDay.getTime());
      startDate.setDate(startDate.getDate() + (idx - 1));
      endDate.setDate(startDate.getDate() + 1);
      this.formData.startDate = startDate;
      this.formData.endDate = endDate;
      this.xDialogPos = e.x - 55;
      this.yDialogPos = e.y - 15;
      this.isDialogOpen = true;
      this.selectedUserData = user;
    },
    updateWorkReport(user, workData, e) {
      this.isUpdate = true;
      this.formData = {
        _id: workData._id,
        startDate: workData.startDate,
        endDate: workData.endDate,
        place: workData.place,
        workDesc: workData.workDesc,
        worker: workData.worker,
        userid: workData.userid,
      };
      this.xDialogPos = e.x - 55;
      this.yDialogPos = e.y - 15;
      this.isDialogOpen = true;
      this.selectedUserData = user;
    },
    async saveData() {
      let saveUrl = `${this.$config.getServerConfig().core.api}/core/api/workReport/add`;
      if (this.isUpdate) {
        saveUrl = `${this.$config.getServerConfig().core.api}/core/api/workReport/update`;
      }
      this.formData.userid = this.selectedUserData.userid;
      try {
        await this.$http.post(saveUrl, this.formData);
        this.isDialogOpen = false;
        this.getWorkData();
        this.resetFormData();
        this.$message({
          type: 'success',
          message: `작업 내역 저장 완료`,
        });
      } catch (err) {
        this.isDialogOpen = false;
      }
    },
    async removeData() {
      this.$confirm(` 작업 내역을 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/workReport/delete`, this.formData);
            this.isDialogOpen = false;
          } catch (err) {
            this.isDialogOpen = false;
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `작업 내역 삭제 취소`,
          });
        });
    },
    getFormDialogStyle() {
      let x = this.xDialogPos;
      let y = this.yDialogPos;
      if (x + 550 > window.innerWidth) {
        x = window.innerWidth - 550;
      }
      if (y + 420 > window.innerHeight) {
        y = window.innerHeight - 420;
      }

      return {
        width: this.dialogWidth + 'px',
        left: x + 'px',
        top: y + 'px',
      };
    },
    getDateIndex(dateString) {
      const firstDay = moment(this.firstDay);
      const diffDay = moment(dateString.substring(0, 10), 'YYYY-MM-DD');

      return diffDay.diff(firstDay, 'days');
    },
    getOverlabCount(arr) {
      let count = 0;
      let ans = 0;
      let data = [];

      for (var i = 0; i < arr.length; i++) {
        // pushing the x coordinate
        data.push([arr[i].startIdx, 'x']);

        // pushing the y coordinate
        data.push([arr[i].endIdx, 'y']);
      }

      data.sort(function (a, b) {
        if (a[0] > b[0]) {
          return 1;
        }
        if (a[0] <= b[0]) {
          return -1;
        }
        return 0;
      });

      for (var i = 0; i < data.length; i++) {
        if (data[i][1] == 'x') count++;

        if (data[i][1] == 'y') count--;

        ans = Math.max(ans, count);
      }
      return ans;
    },
    getWorkDataByUserid(userid) {
      if (this.workData[userid]) return this.workData[userid].data;
      else return [];
    },
    resetFormData() {
      this.isUpdate = false;
      this.formData = {
        _id: '',
        startDate: null,
        endDate: null,
        place: '',
        workDesc: '',
        worker: '',
        userid: '',
      };
    },
  },
};
</script>

<style scoped>
.fill-wrap {
  display: flex;
  flex-direction: column;
}

.header {
  padding: 20px 20px 0 20px;
}

.work-report-wrap {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.user-list {
  width: 220px;
  height: 100%;
  box-sizing: border-box;
  border-right: 5px solid #e9eaeb;
}

.user-list-header {
  background: #f3f4f5;
  color: #657280;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  font-weight: bold;
}

.user-list-thumb-img {
  width: 36px;
  height: 36px;
  margin: 0px;
  border-radius: 36px;
  display: inline-block;
  background-size: cover;
}

.user-list > div.user-list-body {
  display: flex;
  border-bottom: 1px solid #e9eaeb;
  height: 52px;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.user-list > div > span {
  margin: 0 4px 0 8px;
}

.user-list-name {
  color: #616263;
}

.user-list-group {
  color: #474849;
  font-size: 12px;
}

.weekly-work-report {
  flex: 1;
  overflow-x: auto;
}

.weekly-work-report-table {
  table-layout: fixed;
  border-collapse: collapse;
}
.date-day,
.date-month {
  position: relative;
  height: 22px;
}
.date-day-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #6d7175;
  position: absolute;
  border-bottom: 1px solid #d8d9da;
  border-right: 1px solid #d8d9da;
  overflow: hidden;
  transition: background-color 0.2s;
  font-size: 0.9em;
  font-weight: 500;
  white-space: nowrap;
  width: 40px;
  height: 22px;
  box-sizing: border-box;
}
.date-month-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: #667280;
  position: absolute;
  border-top: 1px solid #d8d9da;
  border-bottom: 1px solid #d8d9da;
  border-right: 1px solid #d8d9da;
  overflow: hidden;
  transition: background-color 0.2s;
  font-size: 0.9em;
  font-weight: 500;
  white-space: nowrap;
  width: 280px;
  height: 22px;
  box-sizing: border-box;
}

.work-cell-line {
  height: 52px;
  position: relative;
}
.work-cell-line:after {
  display: block;
  clear: both;
  content: '';
}

.work-cell {
  width: 40px;
  height: 100%;
  float: left;
  box-sizing: border-box;
  border-bottom: 1px solid #d8d9da;
  border-right: 1px solid #d8d9da;
}

.work-cell button {
  width: 100%;
  height: 100%;
  border: 0;
  background-color: transparent;
  transition-duration: 0.3s;
}
.work-cell button:hover {
  background-color: #6aaaec;
}

.work-report-dialog {
  position: absolute;
  background: #fff;
  min-width: 150px;
  border: 1px solid #ebeef5;
  padding: 12px;
  z-index: 2000;
  color: #606266;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  word-break: break-all;
  z-index: 2000;
  transform-origin: top center;
}

.progress-cell {
  position: absolute;
  will-change: contents, width, height, transform;
  transform-style: flat;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  transition: background-color 0.2s, color 0.2s, opacity 0.2s, font-weight 0.2s, border 0.2s;
  contain: layout style;
  background-color: steelblue;
  color: #fff;
  height: 44px;
}
.progress-cell button {
  width: 100%;
  height: 100%;
  border: 0;
  background-color: transparent;
  transition-duration: 0.3s;
  color: #fff;
}
.progress-cell button:hover {
  background-color: #6aaaec;
}
</style>
