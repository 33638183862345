var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "maintnce-board-container", attrs: { id: "maintnceBoard" } },
    [
      _c(
        "div",
        { staticClass: "maintnce-board-wrap" },
        [
          _c(
            "dk-kanban-board",
            {
              attrs: {
                stages: _vm.maintnceSteps,
                blocks: _vm.maintnceBoardItem,
                firstStageStatus: "step1"
              },
              on: {
                "update-block": _vm.updateMaintnceBoardItem,
                "add-block": _vm.addMaintnceBoardItem
              }
            },
            _vm._l(_vm.maintnceBoardItem, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  attrs: { slot: item._id },
                  on: {
                    mouseup: function($event) {
                      return _vm.updateModalMaintnceItem(item)
                    }
                  },
                  slot: item._id
                },
                [
                  _c("p", { staticClass: "maintnce-item-fclt-name" }, [
                    _vm._v(
                      "\n          " + _vm._s(item.fcltName) + "\n          "
                    ),
                    _c("span", [_vm._v(_vm._s(item.fcltTypeName))])
                  ]),
                  _c("p", { staticClass: "maintnce-item-content" }, [
                    _vm._v(_vm._s(item.maintnceSummary))
                  ]),
                  _c("div", { staticClass: "maintnce-tag-wrap" }, [
                    item.startDate
                      ? _c(
                          "span",
                          {
                            staticClass: "maintnce-tag-text date",
                            staticStyle: { float: "right" }
                          },
                          [_vm._v(_vm._s(_vm.getStartDateText(item.startDate)))]
                        )
                      : _vm._e(),
                    item.managerName
                      ? _c(
                          "span",
                          {
                            staticClass: "maintnce-tag-text",
                            staticStyle: { float: "right" }
                          },
                          [_vm._v(_vm._s(item.managerName))]
                        )
                      : _vm._e(),
                    _c("span", { staticStyle: { float: "right" } }, [
                      _vm._v(_vm._s(item.setNumberAddr))
                    ]),
                    item.workType
                      ? _c(
                          "span",
                          { staticClass: "workType-tag" },
                          [
                            _c("el-tag", { attrs: { size: "small" } }, [
                              _vm._v(_vm._s(_vm.getWorkTypeIcon(item.workType)))
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ]
              )
            }),
            0
          )
        ],
        1
      ),
      _c("form-maintnce", {
        ref: "formMaintnce",
        on: { procDataComplete: _vm.getMaintnceBoardItem }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }