var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("line-chart", {
        attrs: {
          "chart-data": _vm.datacollection,
          height: _vm.height,
          options: _vm.chartDarkOption
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }