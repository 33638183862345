var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-collapse",
        {
          attrs: { accordion: "" },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-collapse-item",
            { attrs: { title: "회", name: "1" } },
            [
              [
                _vm._l(_vm.value2, function(i, index) {
                  return _c("div", { key: index }, [
                    _vm._v("\n          " + _vm._s(_vm.data2[i]) + "\n        ")
                  ])
                }),
                _c("el-transfer", {
                  attrs: {
                    filterable: "",
                    "filter-method": _vm.filterMethod,
                    "filter-placeholder": "Auth",
                    titles: [" ", " "],
                    data: _vm.data2
                  },
                  model: {
                    value: _vm.value2,
                    callback: function($$v) {
                      _vm.value2 = $$v
                    },
                    expression: "value2"
                  }
                })
              ]
            ],
            2
          ),
          _c("el-collapse-item", { attrs: { title: "Feedback", name: "2" } }, [
            _vm._v("\n      " + _vm._s(_vm.data2) + "\n    ")
          ]),
          _c(
            "el-collapse-item",
            { attrs: { title: "Efficiency", name: "3" } },
            [
              _c("div", [
                _vm._v(
                  "Simplify the process: keep operating process simple and intuitive;"
                )
              ]),
              _c("div", [
                _vm._v(
                  "Definite and clear: enunciate your intentions clearly so that the users can quickly understand and make\n        decisions;\n      "
                )
              ]),
              _c("div", [
                _vm._v(
                  "Easy to identify: the interface should be straightforward, which helps the users to identify and frees them\n        from memorizing and recalling.\n      "
                )
              ])
            ]
          ),
          _c(
            "el-collapse-item",
            { attrs: { title: "Controllability", name: "4" } },
            [
              _c("div", [
                _vm._v(
                  "Decision making: giving advices about operations is acceptable, but do not make decisions for the users;\n      "
                )
              ]),
              _c("div", [
                _vm._v(
                  "Controlled consequences: users should be granted the freedom to operate, including canceling, aborting or\n        terminating current operation.\n      "
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }