<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :closeOnClickModal="false"
    :closeOnPressEscape="false"
    class="el-dialog-aside vms-editor"
    width="1000px"
    top="5vh"
  >
    <el-container class="dialog-container">
      <el-container>
        <el-form :model="formData" label-width="120px" size="medium" :rules="rules" ref="form0" :label-position="$isMobile ? 'top' : 'right'">
          <el-row>
            <el-col :span="12">
              <el-form-item label="메시지 이름" prop="vmsMessageName">
                <el-input v-model="formData.vmsMessageName" placeholder="VMS 메시지 이름"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="메시지 유형" prop="vmsMessageType">
                <el-select v-model="formData.vmsMessageType" placeholder="VMS 메시지 유형 ">
                  <el-option v-for="(item, index) in vmsConst.vmsMessageType" :key="index" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="메시지 크기" prop="vmsMessageSize">
                <el-select v-model="formData.vmsMessageSize" placeholder="VMS 메시지 크기 " @change="changeVmsSize">
                  <!--el-option label="3단3열" value="3x3"></el-option-->
                  <!-- 320px 240px -->
                  <el-option label="15단20열" value="15x20"></el-option>
                  <!-- 288px 128px -->
                  <el-option label="10단18열" value="10x18"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-main style="background-color: #acacac;">
                <vms-message-editor :vmsMessageSize="formData.vmsMessageSize" type="figure" ref="vmsMessageEditor"></vms-message-editor>
              </el-main>
            </el-col>
          </el-row>
        </el-form>
      </el-container>
      <el-aside width="360px">
        <div class="info-panel">
          <ul class="vms-library">
            <li v-for="(item, index) in vmsLibraries" :key="index">
              <img :src="getThumbImg(item)" @dblclick="addImageObject(item)" class="vmsLibrary-thumb-img" />
            </li>
          </ul>
        </div>
      </el-aside>
    </el-container>

    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">저장</el-button>
      <el-button @click="hideDialog">취소</el-button>
    </span>
  </el-dialog>
</template>

<script>
import vmsMessageEditor from './vmsMessageEditor.vue';
import vmsConst from '../vmsConst.json';

export default {
  components: {
    'vms-message-editor': vmsMessageEditor,
  },
  created() {
    this.getVmsLibraries(1);
  },
  data() {
    return {
      formDialog: false,
      formTitle: '',
      formData: {
        vmsMessageName: '',
        vsmCategory: [],
        vmsMessageData: {},
        thumbImageFile: '',
        vmsMessageSize: '15x20',
      },
      vmsLibraries: [],
      totalDocs: 0,
      limit: 50,
      rules: {
        vmsMessageName: [
          {
            required: true,
            message: 'VMS 메시지 이름을 입력해 주세요',
            trigger: 'blur',
          },
        ],
        vmsCategory: [
          {
            required: true,
            message: 'VMS 메시지 유형을 선택해 주세요',
            trigger: 'blur',
          },
        ],
      },
      vmsConst: vmsConst,
    };
  },
  methods: {
    async getVmsLibraries(pageNum, keyword) {
      let me = this;
      let param = {
        page: pageNum || this.$refs.pagination.getPage(),
        limit: this.limit,
      };
      if (keyword !== '' && keyword) {
        param.keyword = keyword;
      }

      const response = await this.$http.get(`/core/api/vmsLibrary`, {
        params: param,
      });
      me.vmsLibraries = response.data.docs;
      me.totalDocs = response.data.totalDocs;

      // this.$store.dispatch('vmsLibrary/getVmsLibrary', param).then(() => {
      //   me.vmsLibraries = me.$store.getters['vmsLibrary/getVmsLibrary'];
      //   me.totalDocs = me.$store.getters['vmsLibrary/getPagination'].totalDocs;
      // });
    },
    save() {
      this.$refs.form0.validate((valid) => {
        let me = this;
        if (valid) {
          this.$refs.vmsMessageEditor.exportData().then(async (data) => {
            this.formData.vmsMessageData = data.vmsMessageData;
            this.formData.vmsType = '2';
            this.formData.pixelate = data.vmsMessageData.bmpColor;
            this.formData.vmsMessageSvg = data.imgSvg;
            this.formData.isLineFillin = this.formData.vmsMessageData.lineData.length > 0 ? true : false;

            const textFillinCheck = this.formData.vmsMessageData.textData.find((textData) => {
              return textData.text.indexOf('@') > -1 ? true : false;
            });
            this.formData.isFillin = textFillinCheck ? true : false;

            const thumbImage = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/vmsMessage/saveImage`, {
              data: data.img,
            });

            this.formData.thumbImageFile = thumbImage.data.result.serverFilePath;
            let saveFunc = '/core/api/vmsMessage/add';
            if (this.updateFlag) {
              saveFunc = '/core/api/vmsMessage/update';
            }
            try {
              await this.$http.post(`${this.$config.getServerConfig().core.api}${saveFunc}`, {
                data: this.formData,
              });
              me.$parent.$emit('procDataComplete', this.formData);
              me.resetData();
              me.hideDialog();
              me.$message({
                type: 'success',
                message: `VMS 메시지 등록 완료`,
              });
            } catch (err) {
              me.resetData();
              me.hideDialog();
              me.$message.error(`VMS 메시지 등록 중 에러 발생`);
            }
          });
        } else {
          return false;
        }
      });
    },
    getThumbImg(vmsLibrary) {
      let thumb = 'static/images/no-image.jpg';
      if (!vmsLibrary.images) {
        return thumb;
      }
      if (vmsLibrary.images.length == 0) {
        return thumb;
      }
      thumb = `${vmsLibrary.images[0].serverFilePath}`;
      return thumb;
    },
    showDialog(row) {
      if (row) {
        this.updateFlag = true;
        this.formTitle = 'VMS 메시지 수정';
        this.formData = this.$lodash.cloneDeep(row);
      } else {
        this.updateFlag = false;
        this.formTitle = 'VMS 메시지 추가';
        this.resetData();
      }
      this.formDialog = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        if (row) {
          this.$refs.vmsMessageEditor.importData(row.vmsMessageData);
          if (row.vmsMessageSize) this.$refs.vmsMessageEditor.changeMessageSize(row.vmsMessageSize);
        }
      });
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    resetData() {
      this.formData = {
        vmsMessageName: '',
        vsmCategory: '',
        vmsMessageData: {},
        thumbImageFile: '',
        vmsMessageSize: '15x20',
      };
      if (this.$refs.vmsMessageEditor) {
        this.$refs.vmsMessageEditor.resetData();
      }
    },
    addImageObject(row) {
      this.$refs.vmsMessageEditor.addImageObject(0, 0, 64, 64, `${row.images[0].serverFilePath}`);
    },
    changeVmsSize(val) {
      this.$refs.vmsMessageEditor.changeMessageSize(val);
    },
  },
};
</script>

<style scoped></style>
