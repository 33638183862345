var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "fill-height": "" } },
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "900px" },
                  model: {
                    value: _vm.dialog,
                    callback: function($$v) {
                      _vm.dialog = $$v
                    },
                    expression: "dialog"
                  }
                },
                [
                  _c(
                    "v-stepper",
                    {
                      attrs: { vertical: "" },
                      model: {
                        value: _vm.steps,
                        callback: function($$v) {
                          _vm.steps = $$v
                        },
                        expression: "steps"
                      }
                    },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { dark: "" } },
                        [
                          _c("h2", [_vm._v(_vm._s(_vm.formTitle))]),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: { click: _vm.dialogFormClose }
                            },
                            [_vm._v("취소")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.steps > 1, step: "1" } },
                        [_c("small", [_vm._v("기본정보")])]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "1" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "mb-5", attrs: { id: "stepsForm" } },
                            [
                              _c(
                                "v-form",
                                {
                                  model: {
                                    value: _vm.valid,
                                    callback: function($$v) {
                                      _vm.valid = $$v
                                    },
                                    expression: "valid"
                                  }
                                },
                                [
                                  _c(
                                    "v-container",
                                    { attrs: { "grid-list-md": "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md5: "",
                                                "hidden-sm-and-down": ""
                                              }
                                            },
                                            [
                                              _c("v-subheader", [
                                                _vm._v("카메라 ID")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md7: "",
                                                sm12: "",
                                                xs12: ""
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  rules: _vm.nameRules,
                                                  label: "카메라 ID",
                                                  disabled: _vm.disabled
                                                },
                                                model: {
                                                  value:
                                                    _vm.cameraData.cameraId,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.cameraData,
                                                      "cameraId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "cameraData.cameraId"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md5: "",
                                                "hidden-sm-and-down": ""
                                              }
                                            },
                                            [
                                              _c("v-subheader", [
                                                _vm._v("카메라 지점명")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md7: "",
                                                sm12: "",
                                                xs12: ""
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  rules: _vm.nameRules,
                                                  label: "카메라 지점명",
                                                  disabled: _vm.viewDisabled
                                                },
                                                model: {
                                                  value:
                                                    _vm.cameraData
                                                      .cameraPlaceName,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.cameraData,
                                                      "cameraPlaceName",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "cameraData.cameraPlaceName"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md5: "",
                                                "hidden-sm-and-down": ""
                                              }
                                            },
                                            [
                                              _c("v-subheader", [
                                                _vm._v("카메라 모델명")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md7: "",
                                                sm12: "",
                                                xs12: ""
                                              }
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  required: "",
                                                  items: _vm.cameraModelInfo,
                                                  "item-text":
                                                    "cameraModelName",
                                                  "item-value": "cameraModelCd",
                                                  label: "카메라 모델명",
                                                  rules: _vm.select,
                                                  "single-line": "",
                                                  disabled: _vm.viewDisabled
                                                },
                                                model: {
                                                  value:
                                                    _vm.cameraData
                                                      .cameraModelCd,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.cameraData,
                                                      "cameraModelCd",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "cameraData.cameraModelCd"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md5: "",
                                                "hidden-sm-and-down": ""
                                              }
                                            },
                                            [
                                              _c("v-subheader", [
                                                _vm._v("카메라 설치 높이")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md7: "",
                                                sm12: "",
                                                xs12: ""
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  rules: _vm.nameRules,
                                                  type: "number",
                                                  label: "카메라 설치 높이",
                                                  disabled: _vm.viewDisabled
                                                },
                                                model: {
                                                  value:
                                                    _vm.cameraData
                                                      .cameraInitHeight,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.cameraData,
                                                      "cameraInitHeight",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression:
                                                    "cameraData.cameraInitHeight"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md5: "",
                                                "hidden-sm-and-down": ""
                                              }
                                            },
                                            [_c("v-subheader", [_vm._v("IP")])],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md7: "",
                                                sm12: "",
                                                xs12: ""
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  rules: _vm.nameRules,
                                                  label: "IP",
                                                  disabled: _vm.viewDisabled
                                                },
                                                model: {
                                                  value: _vm.cameraData.ip,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.cameraData,
                                                      "ip",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "cameraData.ip"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md5: "",
                                                "hidden-sm-and-down": ""
                                              }
                                            },
                                            [
                                              _c("v-subheader", [
                                                _vm._v("PORT")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md7: "",
                                                sm12: "",
                                                xs12: ""
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  rules: _vm.nameRules,
                                                  label: "PORT",
                                                  disabled: _vm.viewDisabled
                                                },
                                                model: {
                                                  value: _vm.cameraData.port,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.cameraData,
                                                      "port",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression: "cameraData.port"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md5: "",
                                                "hidden-sm-and-down": ""
                                              }
                                            },
                                            [
                                              _c("v-subheader", [
                                                _vm._v("카메라접속 ID")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md7: "",
                                                sm12: "",
                                                xs12: ""
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  rules: _vm.nameRules,
                                                  label: "카메라접속 ID",
                                                  disabled: _vm.viewDisabled
                                                },
                                                model: {
                                                  value:
                                                    _vm.cameraData.accessId,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.cameraData,
                                                      "accessId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "cameraData.accessId"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md5: "",
                                                "hidden-sm-and-down": ""
                                              }
                                            },
                                            [
                                              _c("v-subheader", [
                                                _vm._v("비밀번호")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md7: "",
                                                sm12: "",
                                                xs12: ""
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  rules: _vm.nameRules,
                                                  label: "비밀번호",
                                                  disabled: _vm.viewDisabled
                                                },
                                                model: {
                                                  value:
                                                    _vm.cameraData.password,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.cameraData,
                                                      "password",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "cameraData.password"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md5: "",
                                                "hidden-sm-and-down": ""
                                              }
                                            },
                                            [
                                              _c("v-subheader", [
                                                _vm._v("행정지역")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md7: "",
                                                sm12: "",
                                                xs12: ""
                                              }
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  required: "",
                                                  items: _vm.regionId,
                                                  "item-text": "dataName",
                                                  "item-value": "data",
                                                  label: "행정지역 명칭",
                                                  rules: _vm.select,
                                                  "single-line": "",
                                                  disabled: _vm.viewDisabled
                                                },
                                                model: {
                                                  value:
                                                    _vm.cameraData.regionId,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.cameraData,
                                                      "regionId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "cameraData.regionId"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md5: "",
                                                "hidden-sm-and-down": ""
                                              }
                                            },
                                            [
                                              _c("v-subheader", [
                                                _vm._v("rtsp주소")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md7: "",
                                                sm12: "",
                                                xs12: ""
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  rules: _vm.nameRules,
                                                  label: "rtsp주소",
                                                  disabled: _vm.viewDisabled
                                                },
                                                model: {
                                                  value: _vm.cameraData.rtspUrl,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.cameraData,
                                                      "rtspUrl",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "cameraData.rtspUrl"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md5: "",
                                                "hidden-sm-and-down": ""
                                              }
                                            },
                                            [
                                              _c("v-subheader", [
                                                _vm._v("mjpeg주소")
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                md7: "",
                                                sm12: "",
                                                xs12: ""
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                attrs: {
                                                  required: "",
                                                  rules: _vm.nameRules,
                                                  label: "mjpeg주소",
                                                  disabled: _vm.viewDisabled
                                                },
                                                model: {
                                                  value:
                                                    _vm.cameraData.mjpegUrl,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.cameraData,
                                                      "mjpegUrl",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "cameraData.mjpegUrl"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-toolbar",
                            { attrs: { dark: "" } },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function($event) {
                                      _vm.steps = _vm.next(_vm.steps)
                                    }
                                  }
                                },
                                [_vm._v("다음")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.dialogFormClose }
                                },
                                [_vm._v("취소")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-step",
                        { attrs: { complete: _vm.steps > 2, step: "2" } },
                        [_vm._v("상세정보")]
                      ),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "2" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "mb-5", attrs: { id: "stepsForm" } },
                            [
                              _c(
                                "v-container",
                                { attrs: { "grid-list-md": "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            md5: "",
                                            "hidden-sm-and-down": ""
                                          }
                                        },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("카메라 기본영상 가로해상도")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: { md7: "", sm12: "", xs12: "" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label:
                                                "카메라 기본영상 가로해상도",
                                              required: "",
                                              disabled: _vm.viewDisabled
                                            },
                                            model: {
                                              value: _vm.cameraData.basicWidth,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.cameraData,
                                                  "basicWidth",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "cameraData.basicWidth"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            md5: "",
                                            "hidden-sm-and-down": ""
                                          }
                                        },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("카메라 기본영상 세로해상도")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: { md7: "", sm12: "", xs12: "" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label:
                                                "카메라 기본영상 세로해상도",
                                              disabled: _vm.viewDisabled
                                            },
                                            model: {
                                              value: _vm.cameraData.basicHeight,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.cameraData,
                                                  "basicHeight",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "cameraData.basicHeight"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            md5: "",
                                            "hidden-sm-and-down": ""
                                          }
                                        },
                                        [
                                          _c("v-subheader", [
                                            _vm._v(
                                              "카메라 클릭설정시 가로해상도"
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: { md7: "", sm12: "", xs12: "" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label:
                                                "카메라 클릭설정시 가로해상도",
                                              disabled: _vm.viewDisabled
                                            },
                                            model: {
                                              value: _vm.cameraData.clickWidth,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.cameraData,
                                                  "clickWidth",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "cameraData.clickWidth"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            md5: "",
                                            "hidden-sm-and-down": ""
                                          }
                                        },
                                        [
                                          _c("v-subheader", [
                                            _vm._v(
                                              "카메라 클릭설정시 세로해상도"
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: { md7: "", sm12: "", xs12: "" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label:
                                                "카메라 클릭설정시 세로해상도",
                                              disabled: _vm.viewDisabled
                                            },
                                            model: {
                                              value: _vm.cameraData.clickHeight,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.cameraData,
                                                  "clickHeight",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "cameraData.clickHeight"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            md5: "",
                                            "hidden-sm-and-down": ""
                                          }
                                        },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("카메라 클릭x값")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: { md7: "", sm12: "", xs12: "" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "카메라 클릭x값",
                                              disabled: _vm.viewDisabled
                                            },
                                            model: {
                                              value: _vm.cameraData.clickX,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.cameraData,
                                                  "clickX",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "cameraData.clickX"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            md5: "",
                                            "hidden-sm-and-down": ""
                                          }
                                        },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("카메라 클릭y값")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: { md7: "", sm12: "", xs12: "" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "카메라 클릭y값",
                                              disabled: _vm.viewDisabled
                                            },
                                            model: {
                                              value: _vm.cameraData.clickY,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.cameraData,
                                                  "clickY",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression: "cameraData.clickY"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            md5: "",
                                            "hidden-sm-and-down": ""
                                          }
                                        },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("원클릭줌계산용 거리값")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: { md7: "", sm12: "", xs12: "" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "원클릭줌계산용 거리값",
                                              disabled: _vm.viewDisabled
                                            },
                                            model: {
                                              value:
                                                _vm.cameraData
                                                  .standardOneclickDistance,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.cameraData,
                                                  "standardOneclickDistance",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "cameraData.standardOneclickDistance"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            md5: "",
                                            "hidden-sm-and-down": ""
                                          }
                                        },
                                        [
                                          _c("v-subheader", [
                                            _vm._v("원클릭줌계산용 픽셀값")
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: { md7: "", sm12: "", xs12: "" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "원클릭줌계산용 픽셀값",
                                              disabled: _vm.viewDisabled
                                            },
                                            model: {
                                              value:
                                                _vm.cameraData
                                                  .standardOneclickPixel,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.cameraData,
                                                  "standardOneclickPixel",
                                                  _vm._n($$v)
                                                )
                                              },
                                              expression:
                                                "cameraData.standardOneclickPixel"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-toolbar",
                            { attrs: { dark: "" } },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.steps = 1
                                    }
                                  }
                                },
                                [_vm._v("이전")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.cameraSave }
                                },
                                [_vm._v("저장")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.dialogFormClose }
                                },
                                [_vm._v("취소")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mb-2",
                  attrs: { color: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addCameraDialog()
                    }
                  }
                },
                [_vm._v("카메라 추가")]
              ),
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.cameraInfo,
                  pagination: _vm.pagination,
                  "hide-actions": ""
                },
                on: {
                  "update:pagination": function($event) {
                    _vm.pagination = $event
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "headerCell",
                    fn: function(props) {
                      return [
                        _c("v-tooltip", { attrs: { bottom: "" } }, [
                          _c(
                            "span",
                            { attrs: { slot: "activator" }, slot: "activator" },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(props.header.text) +
                                  "\n                        "
                              )
                            ]
                          ),
                          _c("span", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(props.header.text) +
                                "\n                        "
                            )
                          ])
                        ])
                      ]
                    }
                  },
                  {
                    key: "items",
                    fn: function(props) {
                      return [
                        _c(
                          "tr",
                          [
                            _c(
                              "td",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.showDetailInfo(props.item)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(props.item.cameraId))]
                            ),
                            _c(
                              "td",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.showDetailInfo(props.item)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(props.item.cameraPlaceName))]
                            ),
                            _c(
                              "td",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.showDetailInfo(props.item)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$dkEnfCodeFormatter.getCameraModel(
                                      props.item.cameraModelCd
                                    ).cameraModelName
                                  )
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.showDetailInfo(props.item)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(props.item.ip))]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.editCameraDialog(props.item)
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("build")
                                ])
                              ]
                            ),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.cameraRemove(props.item)
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("delete_forever")
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c(
                "div",
                { staticClass: "text-xs-center pt-2" },
                [_c("dk-grid-page", { attrs: { pagination: _vm.pagination } })],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }