<template>
  <div class="fill-width-height el-container">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <el-row> </el-row>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  components: {
    'dk-el-pagination': dkelpagination,
  },
  data() {
    return {
      systemBackupSchedule: [],
    };
  },
  created() {
    this.getSystemBackupSchedule();
  },
  methods: {
    async getSystemBackupSchedule() {
      let result = await this.$http.get(
        `${this.$config.getServerConfig().core.api}/core/api/statistics/systemlog`,
        {}
      );
      this.systemBackupSchedule = result.data.docs;
    },
  },
};
</script>

<style scoped></style>
