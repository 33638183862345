<template>
  <div class="dk-template-table-wrap" :style="gridWrapStyle">
    <table>
      <thead>
        <tr class="grid-header">
          <th v-for="(item, idx) in header" :key="idx">
            {{ item.label }}
          </th>
          <!-- vue slot 여부 확인에 따라 추가 셀-->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIdx) in data" :key="rowIdx">
          <td v-for="(headerItem, headerIdx) in header" :key="headerIdx">
            {{ getCellValue(row, headerItem) }}
          </td>
          <!-- <td v-for="(cell, cellIdx) in row" :key="cellIdx"></td> -->
          <!-- vue slot 여부 확인에 따라 추가 셀-->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  data() {
    return {
      // header: {},
      // data: []
    };
  },
  props: {
    header: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    height: {
      type: Number,
      default: null,
    },
  },
  computed: {
    gridWrapStyle() {
      if (this.height) {
        return {
          height: this.height + 'px',
        };
      } else {
        return {};
      }
    },
  },
  methods: {
    getCellValue(row, header) {
      // console.log(row);
      // const result = _.find(row, (val, key) => {
      //   return key === ;
      // });
      return row[header.dataKey] ? row[header.dataKey] : '';
    },
  },
};
</script>
