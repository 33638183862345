<template>
  <div class="fill-width-height el-container">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <dk-el-table-header :openFormDialog="openFormDialog"></dk-el-table-header>
            <el-table
              :data="centerEquips"
              empty-text="등록된 센터 정보가 없습니다."
              size="mini"
              @cell-click="selectRow"
              stripe
            >
              <el-table-column
                label="센터 장비 명"
                prop="centerEquipName"
                header-align="center"
                align="center"
              ></el-table-column>
              <el-table-column
                label="센터 장비 유형"
                prop="centerEquipTypeName"
                width="120px"
                header-align="center"
                align="center"
              ></el-table-column>
              <el-table-column
                label="납품 업체"
                prop="supplyCompany"
                header-align="center"
                align="center"
              ></el-table-column>
              <el-table-column label="납품 일자" width="140px" header-align="center" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.workStartDate">{{ getWriteDate(scope.row.workStartDate) }}</span>
                  <span v-else>입력되지 않음</span>
                </template>
              </el-table-column>
              <el-table-column
                label="설치 업체"
                prop="installCompany"
                header-align="center"
                align="center"
              ></el-table-column>
              <el-table-column label width="170px">
                <template slot-scope="scope">
                  <el-button @click="openFormDialog(scope.row)" size="small">수정</el-button>
                  <el-button @click="remove(scope.row)" size="small" type="danger">삭제</el-button>
                </template>
              </el-table-column>
            </el-table>
            <dk-el-pagination
              :totalDocs="totalDocs"
              :limit="limit"
              :pagingProc="pagingProc"
              ref="pagination"
            ></dk-el-pagination>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <dk-info-panel ref="infoPanel" width="440px">
      <div class="info-panel-wrap">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="센터 장비 정보" name="tab1">
            <h3>센터 장비 정보</h3>
            <div class="dk-card">
              <table class="el-table el-table--mini">
                <tr>
                  <th style="width: 110px;">센터 장비 명</th>
                  <td>{{ selectCenterEquip.centerEquipName }}</td>
                </tr>
                <tr>
                  <th style="width: 110px;">센터 장비 유형</th>
                  <td>{{ selectCenterEquip.centerEquipTypeName }}</td>
                </tr>
                <tr>
                  <th style="width: 110px;">납품 업체</th>
                  <td>{{ selectCenterEquip.supplyCompany }}</td>
                </tr>
                <tr>
                  <th style="width: 110px;">납품 업체 연락처</th>
                  <td>{{ selectCenterEquip.supplyPhoneNumber }}</td>
                </tr>
                <tr>
                  <th style="width: 110px;">납품 일자</th>
                  <td>{{ getWriteDate(selectCenterEquip.supplyDate) }}</td>
                </tr>
                <tr>
                  <th style="width: 110px;">설치 업체</th>
                  <td>{{ selectCenterEquip.installCompany }}</td>
                </tr>
                <tr>
                  <th style="width: 110px;">설치 업체 연락처</th>
                  <td>{{ selectCenterEquip.installPhoneNumber }}</td>
                </tr>
                <tr>
                  <th style="width: 110px;">내용</th>
                  <td>{{ selectCenterEquip.text }}</td>
                </tr>
              </table>
            </div>
            <h4>장비 상세 정보</h4>
            <div v-for="(equip, index) in selectCenterEquip.equip" :key="index" class="dk-card">
              <div class="dk-card-title">{{ equip.materialName }}</div>
              <el-row>
                <el-col :span="10">
                  <img
                    v-if="equip.images && equip.images.length > 0"
                    :src="$config.getServerConfig().image.url + equip.images[0].serverFilePath"
                    style="width: 90%; height: auto;"
                  />
                  <span v-else>&nbsp;</span>
                </el-col>
                <el-col :span="14">
                  <table class="el-table el-table--mini">
                    <tr v-for="(data, index) in equip.materialTypeData" :key="index">
                      <th style="width: 100px;">{{ data.label }}</th>
                      <td>{{ data.value }}</td>
                    </tr>
                    <tr v-for="(data, index) in equip.equipData" :key="index">
                      <th>{{ data.label }}</th>
                      <td>{{ data.value }}</td>
                    </tr>
                  </table>
                </el-col>
              </el-row>
            </div>
          </el-tab-pane>
          <el-tab-pane label="관련 자료" name="tab2">
            <div class="dk-card">
              <el-table
                :data="selectCenterEquip.files"
                empty-text="등록된 사업 관련 자료가 없습니다."
                size="mini"
                stripe
                @row-click="downloadReference"
              >
                <el-table-column label="파일 명">
                  <template slot-scope="scope">
                    <span class="file-download-label">{{ scope.row.name }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="파일 크기" width="120px">
                  <template slot-scope="scope">{{ getHumanFileSize(scope.row.size) }}</template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </dk-info-panel>

    <form-center-equip ref="formDialog"></form-center-equip>
  </div>
</template>

<script>
import dkeltableheader from '@/components/dkElTableHeader.vue';
import formcenterequip from '@/core/forms/formCenterEquip.vue';
import dkelpagination from '@/components/dkElPagination.vue';
import dkinfopanel from '@/components/dkInfoPanel.vue';
import DkFileUtil from '@/components/dkFileUtil.js';
import moment from 'moment';

export default {
  components: {
    'dk-el-table-header': dkeltableheader,
    'form-center-equip': formcenterequip,
    'dk-el-pagination': dkelpagination,
    'dk-info-panel': dkinfopanel,
  },
  created() {
    this.getCenterEquips(1);
    this.$on('procDataComplete', (centerEquip) => {
      this.getCenterEquips();
    });
  },
  data() {
    return {
      centerEquips: [],
      limit: 10,
      totalDocs: 0,
      activeTab: 'tab1',
      centerEquipShow: false,
      selectCenterEquip: {
        supplyCompany: '',
        supplyPhoneNumber: '',
        installCompany: '',
        installPhoneNumber: '',
        supplyDate: null,
        centerEquipName: '',
        text: '',
        files: [],
        equip: [],
      },
    };
  },
  methods: {
    selectRow(row, column, cell, event) {
      if (!this.$refs.infoPanel.isOpened()) {
        this.$refs.infoPanel.open();
      }
      this.selectCenterEquip = row;
    },
    openFormDialog(row) {
      this.$refs.formDialog.showDialog(row);
    },
    remove(args) {
      this.$confirm(`센터 장비 자료를 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/centerEquip/delete`, { data: args });
            this.getCenterEquips();
            this.$message({
              type: 'success',
              message: `센터 장비 자료 삭제 완료`,
            });
            this.$refs.infoPanel.close();
          } catch (error) {
            this.$message({
              type: 'info',
              message: `센터 장비 자료 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `센터 장비 자료 삭제 취소`,
          });
        });
    },
    getThumbImg(centerEquip) {
      let thumb = 'static/images/no-image.jpg';
      if (!centerEquip.images) {
        return thumb;
      }
      if (centerEquip.images.length == 0) {
        return thumb;
      }
      thumb = `${centerEquip.images[0].serverFilePath}`;
      return thumb;
    },
    pagingProc(pageNum) {
      this.getCenterEquips();
    },
    async getCenterEquips(pageNum) {
      let me = this;
      try {
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/centerEquip`, {
          params: {
            page: pageNum || this.$refs.pagination.getPage(),
            limit: this.limit,
          },
        });
        me.centerEquips = response.data.docs;
        me.totalDocs = response.data.totalDocs;
        me.$refs.infoPanel.close();
      } catch (err) {
        this.$message({
          type: 'info',
          message: `공지사항표출 에러`,
        });
      }
    },
    getWriteDate(writeDate) {
      return moment(writeDate).format('YYYY년 MM월 DD일');
    },
    getHumanFileSize(bytes) {
      return DkFileUtil.getHumanFileSize(bytes, true);
    },
    downloadReference(row, event, column) {
      DkFileUtil.downloadFromUrl(row.url, row.name);
    },
  },
};
</script>

<style scoped></style>
