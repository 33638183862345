<template>
  <el-dialog
    :title="formTitle"
    :visible.sync="formDialog"
    @close="hideDialog"
    :close-on-click-modal="false"
  >
    <div>
      <el-steps :active="step" finish-status="success" align-center style="margin: 5px 0 30px 0;">
        <el-step v-for="(s, index) in stepData" :key="index" :title="s.title"></el-step>
      </el-steps>
      <div v-show="step === 0">
        <el-form
          :model="formData"
          label-width="160px"
          size="medium"
          :rules="rules"
          ref="form0"
          :label-position="$isMobile ? 'top' : 'right'"
        >
          <el-row>
            <el-col :xs="24" :sm="20" :md="20">
              <el-form-item label="서버 ID" prop="serverTitle">
                <el-input v-model="formData.serverTitle" placeholder="서버 ID를 입력하세요"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20" :md="20">
              <el-form-item label="서버명" prop="serverName">
                <el-input v-model="formData.serverName" placeholder="서버명을 입력하세요"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20" :md="20">
              <el-form-item label="서버 IP" prop="serverIp">
                <el-input v-model="formData.serverIp" placeholder="서버 IP를 입력하세요"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20" :md="20">
              <el-form-item label="서버 포트" prop="serverPort">
                <el-input v-model="formData.serverPort" placeholder="서버 포트를 입력하세요"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20" :md="20">
              <el-form-item label="제어시설물" prop="text">
                <el-input v-model="formData.text" placeholder="제어시설물"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="20" :md="20">
              <el-form-item label="프록시" prop="company">
                <el-input v-model="formData.company" placeholder="프록시를 입력하세요"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div v-show="step === 1">
        <el-row>
          <el-col :span="24">
            <el-button
              type="primary"
              @click="gisMarkerDialog = !gisMarkerDialog"
              size="small"
              style="margin-bottom: 10px;"
            >
              <i class="fas fa-plus-circle">&nbsp;추가</i>
            </el-button>
          </el-col>
          <el-col :xs="24" :sm="18" :md="18">
            <el-form-item label="서버 Key" prop="serverTitle">
              <el-input v-model="formData.serverTitle" placeholder="서버 Key를 입력하세요"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-table empty-text="등록된 추가 서버 정보가 없습니다." size="mini" stripe>
              <el-table-column label="서버ID" prop="serverId"></el-table-column>
              <el-table-column label="서버명" prop="serverName" header-align="center" align="center"></el-table-column>
              <el-table-column label="서버IP" header-align="center" align="center"></el-table-column>
              <el-table-column label="서버 포트" header-align="center" align="center"></el-table-column>
              <el-table-column>
                <el-button size="small">수정</el-button>
                <el-button size="small" type="danger">삭제</el-button>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </div>
      <div class="form-bottom">
        <el-button @click="prev" style="float: left;" v-show="step > 0">
          <i class="fas fa-angle-left"></i>&nbsp;&nbsp;이전
        </el-button>
        <el-button @click="next" style="float: right;" v-show="step < stepData.length - 1">
          다음&nbsp;&nbsp;
          <i class="fas fa-angle-right"></i>
        </el-button>
        <el-button @click="save" style="float: right;" v-show="step === stepData.length - 1">
          저장&nbsp;&nbsp;
          <i class="fas fa-angle-right"></i>
        </el-button>
      </div>
    </div>
    <!-- "추가 정보"란의 추가 모달창 -->
    <el-dialog
      width="35%"
      :visible.sync="gisMarkerDialog"
      append-to-body
      title="서버 정보 추가"
      class="el-dialog-inner"
    >
      <el-row>
        <el-form
          :model="formData"
          label-width="160px"
          size="medium"
          :rules="rules"
          ref="form0"
          :label-position="$isMobile ? 'top' : 'right'"
        >
          <el-col :xs="24" :sm="20" :md="20">
            <el-form-item label="서버 Key" prop="serverKey">
              <el-input v-model="formData.serverKey" placeholder="서버 Key를 입력하세요"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="20" :md="20">
            <el-form-item label="서버 명칭" prop="serverTitle">
              <el-input v-model="formData.serverTitle" placeholder="서버 명칭을 입력하세요"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="20" :md="20">
            <el-form-item label="서버 값" prop="serverValue">
              <el-input v-model="formData.serverValue" placeholder="서버 값를 입력하세요"></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div class="form-bottom">
        <el-button style="float: left;" type="danger">취소</el-button>
        <el-button @click="save" style="float: right;">저장</el-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script>
import dkElFileUpload from '@/components/dkElFileUpload.vue';
import moment from 'moment';

export default {
  components: {
    'dk-el-file-upload': dkElFileUpload,
  },
  data() {
    return {
      input: '',
      formDialog: false,
      formTitle: '',
      step: 0,
      stepData: [
        { idx: 0, title: '기본 정보' },
        { idx: 1, title: '추가 정보' },
      ],
      formData: {},
      rules: {
        serverTitle: [
          {
            required: true,
            message: '서버 ID를 입력해 주세요',
            trigger: 'blur',
          },
        ],
        serverName: [
          {
            required: true,
            message: '서버명을 입력해 주세요',
            trigger: 'blur',
          },
        ],
        serverIp: [
          {
            required: true,
            message: '서버 IP를 입력해 주세요',
            trigger: 'blur',
          },
        ],
        serverPort: [
          {
            required: true,
            message: '서버 포트를 입력해 주세요',
            trigger: 'blur',
          },
        ],
      },
      pickerOptions1: {
        disabledDate: this.disabledDate,
      },
      gisMarkerDialog: false,
    };
  },
  created() {},
  methods: {
    next() {
      if (this.$refs[`form${this.step}`]) {
        this.$refs[`form${this.step}`].validate((valid) => {
          if (valid) {
            this.step++;
          } else {
            return false;
          }
        });
      } else {
        this.step++;
      }

      if (this.step == 1) {
      }
    },
    prev() {
      if (this.step == 1) {
      }
      this.step--;
    },
    async save() {
      try {
        this.step++;
        this.formData.files = this.$refs.uploadFile.getFileData();
        this.formData.images = this.$refs.uploadImage.getFileData();

        let saveFunc = 'serviceManage/add';
        if (this.updateFlag) {
          saveFunc = 'serviceManage/update';
        }
        let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, {
          data: this.formData,
        });

        this.$parent.$emit('procDataComplete', this.formData);
        this.hideDialog();
        this.$message({
          type: 'success',
          message: `서버관리 자료 등록 완료`,
        });
      } catch (error) {
        this.hideDialog();
        this.$message.error(`서버관리 자료 등록 중 에러 발생`);
      }
    },
    showDialog(row) {
      if (row) {
        this.updateFlag = true;
        this.formTitle = '서버관리 자료 수정';
        this.formData = this.$lodash.cloneDeep(row);
      } else {
        this.updateFlag = false;
        this.formTitle = '서버관리 자료 추가';
        this.resetData();
      }
      this.formDialog = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        if (row) {
          this.$refs.uploadFile.setFileData(row.files);
          this.$refs.uploadImage.setFileData(row.images);
        } else {
          this.$refs.uploadFile.setFileData(null);
          this.$refs.uploadImage.setFileData(null);
        }
      });
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    resetData() {
      this.formData = {
        serviceTitle: '',
        writerId: '',
        company: '',
        workStartDate: '',
        workEndDate: '',
        text: '',
        images: [],
        files: [],
      };
      this.step = 0;
      if (this.$refs.form0) {
        this.$refs.form0.resetFields();
      }
    },

    changeIsPopup(val) {
      if (val) {
        //if (!this.formData.endDate) {
        //  this.formData.endDate = new Date();
        //}
      }
    },
    disabledDate(time) {
      // console.log(this.formData.workStartDate)
      if (this.formData.workStartDate) {
        return moment(time).isBefore(moment(this.formData.workStartDate));
      }
    },
  },
};
</script>
