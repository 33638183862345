<template>
  <el-dialog
    :visible.sync="formDialog"
    :title="formTitle"
    width="1300px"
    top="5vh"
    @close="hideDialog"
    @open="openDialog"
    :close-on-click-modal="false"
    id="formAiCameraDetect"
  >
    <!-- 시설물 관리 dialog -->
    <div>
      <div id="formAiCameraWrap">
        <div class="video-player-wrap">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            stroke="none"
            fill="none"
            overflow="hidden"
            preserveAspectRatio="none"
            class="editor-canvas"
            :width="videoWidth"
            :height="videoHeight"
            :viewBox="canvasViewBox"
            @mousedown="mousedownEditor"
            @mouseup="mouseupEditor"
            @mousemove="mousemoveEditor"
          >
            <line
              :x1="tempLineObject.x1"
              :y1="tempLineObject.y1"
              :x2="tempLineObject.x2"
              :y2="tempLineObject.y2"
              stroke="#409eff"
              stroke-width="10"
              v-show="isLineDraw"
            />
            <line-object
              v-for="item in lineData"
              ref="lineObject"
              :key="item.objectId"
              :objectId="item.objectId"
              :objectData="item"
              @selectObject="selectObject"
              @editLineObject="editLineObject"
            />
          </svg>
          <dk-hive-webrtc-player
            :server_ip="webRtcInfo.ip"
            :server_port="webRtcInfo.port"
            :video_name="webRtcInfo.videoName"
            ref="dkHiveWebrtcPlayer"
            @onloadWebrtcPlayer="onloadWebrtcPlayer"
            :videoWidth="videoWidth"
            :videoHeight="videoHeight"
          ></dk-hive-webrtc-player>
        </div>

        <div class="form-wrap">
          <div>
            <el-button :type="isLineEdit ? 'primary' : ''" @click="setLineEdit()">구역 추가</el-button>
          </div>

          <table class="el-table el-table--mini">
            <tr>
              <th style="width: 160px;">AI 카메라 시설물 ID</th>
              <td>{{ fcltData.fcltId }}</td>
            </tr>
            <tr>
              <th>AI 카메라 시설물 명칭</th>
              <td>{{ fcltData.fcltName }}</td>
            </tr>
          </table>
          <table class="line-list">
            <tr>
              <th>검지영역이름</th>
              <th>방향</th>
              <th>입출구여부</th>
            </tr>
            <tr
              @click="setLineDataToForm(item)"
              v-for="(item, idx) in lineGridData"
              :key="idx"
              :class="selectedObject && item.objectId === selectedObject.getObjectId() ? 'selected' : ''"
            >
              <td>{{ item.desc }}</td>
              <td>{{ displayDirection(item.direction) }}</td>
              <td>{{ displayEntrance(item.entrance) }}</td>
            </tr>
            <tr v-if="lineData.length <= 0">
              <td colspan="3">등록된 검지영역 정보가 없습니다.</td>
            </tr>
          </table>
          <el-form v-show="selectedObject" :model="formData" label-width="120px" size="small" :rules="rules" ref="form0">
            <el-form-item label="검지영역이름">
              <el-input v-model="formData.desc"></el-input>
            </el-form-item>
            <el-form-item label="방향">
              <el-radio-group v-model="formData.direction">
                <el-radio :label="true" border>진입</el-radio>
                <el-radio :label="false" border>진출</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="입/출구">
              <el-radio-group v-model="formData.entrance">
                <el-radio-button :label="-1">입출구 아님</el-radio-button>
                <el-radio-button :label="1">입구</el-radio-button>
                <el-radio-button :label="0">출구</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="form-bottom" style="padding: 0 8px 8px 8px;">
      <el-button @click="formDialog = false" style="float: right;">닫기</el-button>
      <el-button type="primary" @click="save" style="float: right; margin-right: 5px;">저장</el-button>
    </div>
    <!-- 시설물 관리 dialog 끝 -->
  </el-dialog>
</template>

<script>
let lineObject = {
  template: `       
        <g :id="objectId"           
            @mousedown="selectObject"
            v-if="!objectData.del"
        >
          <defs>
            <marker id="entryArrow" markerWidth="8" markerHeight="8" refX="4" refY="4" orient="auto" >            
            <circle cx="4" cy="4" r="4" stroke="none" fill="#0070d2" @mousedown="clickDirectionMarker($event)"/>
                <polygon   points="1 4, 2.5 4, 2.5 7, 5.5 7, 5.5 4, 7 4, 4 1" fill="#ffffff" @mousedown="clickDirectionMarker($event)"></polygon>
            </marker>
            <marker id="exitArrow" markerWidth="8" markerHeight="8" refX="4" refY="4" orient="auto" >
                <circle cx="4" cy="4" r="4" stroke="none" fill="#0070d2" @mousedown="clickDirectionMarker($event)"/>
                <polygon  points="1 4, 2.5 4, 2.5 1, 5.5 1, 5.5 4, 7 4, 4 7" fill="#ffffff" @mousedown="clickDirectionMarker($event)"></polygon>                
            </marker>
          </defs>
            
            <g>   
                 <g v-show="isSelected">
                    <line :x1="x1" :y1="y1" :x2="x2" :y2="y2" stroke="#90A4AE" :stroke-width="strokeWidth*2" stroke-dasharray="5,5" class="topDrawing"/>
                    <circle :cx="x1" :cy="y1" r="6" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="bottomRightDrawing" @mousedown="mousedown('startPoint', $event)"/>
                    <circle :cx="x2" :cy="y2" r="6" stroke="#E0E0E0" stroke-width="1" fill="#90A4AE" class="bottomRightDrawing" @mousedown="mousedown('endPoint', $event)"/>
                </g>                         
                <polyline :points="getPoints" :marker-mid="procDirection" :stroke="strokeColor" :stroke-width="strokeWidth" />
           
            </g>           
        </g>
    `,
  // <polygon points="1 4, 2.5 4, 2.5 1, 5.5 1, 5.5 4, 7 4, 4 7" fill="#ffffff"></polygon>

  //                     <line :x1="x2" :y1="y1" :x2="x2" :y2="y2" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="bottomDrawing"/>
  // <line :x1="x2" :y1="y1" :x2="x1" :y2="y1" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="leftDrawing"/>
  // <line :x1="x1" :y1="y1" :x2="x1" :y2="y2" stroke="#90A4AE" stroke-width="1" stroke-dasharray="5,5" class="rightDrawing"/>
  // <line :x1="x1-((this.strokeWidth /2) + 5)" :y1="y1" :x2="x2-((this.strokeWidth /2) + 5)" :y2="y2" stroke="#00ff00" stroke-width="3" stroke-dasharray="5,5" />
  // <line :x1="x1+((this.strokeWidth /2) + 5)" :y1="y1" :x2="x2+((this.strokeWidth /2) + 5)" :y2="y2" stroke="#ff0000" stroke-width="3" stroke-dasharray="5,5" />
  mounted() {
    this.$nextTick(() => {
      this.importData(this.objectData);
    });
  },
  computed: {
    getPoints() {
      let midX,
        midY = 0;
      if (this.x1 > this.x2) {
        midX = (Math.abs(this.x1) + Math.abs(this.x2)) / 2;
      } else {
        midX = (Math.abs(this.x2) + Math.abs(this.x1)) / 2;
      }

      if (this.y1 > this.y2) {
        midY = (Math.abs(this.y1) + Math.abs(this.y2)) / 2;
      } else {
        midY = (Math.abs(this.y2) + Math.abs(this.y1)) / 2;
      }

      let pos1 = `${this.x1},${this.y1}`;
      let mid = `${midX},${midY}`;
      let pos2 = `${this.x2},${this.y2}`;

      return `${pos1} ${mid} ${pos2}`;
    },
    procDirection() {
      if (this.objectData.direction) {
        return 'url(#entryArrow)';
      } else {
        return 'url(#exitArrow)';
      }
    },
  },
  data() {
    return {
      objectType: 'line',
      isSelected: false,
      isEdit: false,
      isEndArrow: false,
      strokeWidth: 5,
      strokeColor: '#3ebfea',
      x1: 0,
      y1: 0,
      x2: 0,
      y2: 0,
      currentAction: '',
      isTunnel: false,
      lineGroupId: '',
      direction: true,
      del: false,
    };
  },

  props: {
    objectId: {
      type: String,
      default: 'lineObject-0',
    },
    objectData: {
      type: Object,
    },
    bmpColor: {
      type: String,
      default: '24bit',
    },
  },
  methods: {
    selectObject() {
      this.isSelected = true;
      this.isEdit = true;
      this.$emit('selectObject', this);
    },
    deselectObject() {
      this.isSelected = false;
      this.isEdit = false;
    },
    setStrokeColor(color) {
      this.strokeColor = color;
      this.$forceUpdate();
    },
    exportData() {
      return {
        line: {
          p1: {
            x: this.x1,
            y: this.y1,
          },
          p2: {
            x: this.x2,
            y: this.y2,
          },
        },
        fcltDirectionId: this.objectId,
        direction: this.objectData.direction,
        entrance: this.objectData.entrance,
        desc: this.objectData.desc,
        del: this.objectData.del,
      };
    },
    importData(data) {
      this.x1 = data.x1 || this.x1;
      this.y1 = data.y1 || this.y1;
      this.x2 = data.x2 || this.x2;
      this.y2 = data.y2 || this.y2;
      this.strokeWidth = data.strokeWidth || 5;
      this.strokeColor = data.strokeColor || '#3ebfea';

      this.$forceUpdate();
    },
    getObjectId() {
      return this.objectId;
    },
    mousedown(flag, e) {
      this.currentAction = flag;
      this.selectObject();
      this.$emit('editLineObject', flag);
    },
    updateStartPoint(x, y) {
      this.x1 = x;
      this.y1 = y;
    },
    updateEndPoint(x, y) {
      this.x2 = x;
      this.y2 = y;
    },
    setStrokeWidth(strokeWidth) {
      this.strokeWidth = strokeWidth;
    },

    setIsEndArrow(isEndArrow) {
      this.isEndArrow = isEndArrow;
    },
    setLineGroupId(lineGroupId) {
      this.lineGroupId = lineGroupId;
    },
    getCurrentAction() {
      return this.currentAction;
    },
    getObjectType() {
      return this.objectType;
    },
    clickDirectionMarker(e) {
      e.stopPropagation();
      this.direction = !this.direction;
      console.log(this.direction);

      this.$forceUpdate();
    },
    getObjectData() {
      return this.objectData;
    },
    setDelete() {
      this.objectData.del = true;
      this.$forceUpdate();
    },
  },
};
import _ from 'lodash';
import uuid from 'uuid/v1';
import dkHiveWebRtcPlayer from '@/components/webrtc/dkHiveWebRtcPlayer.vue';

export default {
  components: {
    'dk-hive-webrtc-player': dkHiveWebRtcPlayer,
    'line-object': lineObject,
  },
  created() {
    window.addEventListener('keyup', this.keyUpEvent);
  },
  destroyed() {
    window.removeEventListener('keyup', this.keyUpEvent);
  },
  computed: {
    canvasViewBox() {
      return `0 0 ${this.videoWidth} ${this.videoHeight}`;
    },
    lineGridData() {
      const result = _.filter(this.lineData, (val) => {
        return !val.del;
      });
      return result;
    },
  },
  data() {
    return {
      formDialog: false,
      formTitle: 'AI 영상분석 설정',
      updateFlag: false,
      fcltData: {},
      formData: {
        fcltDirectionId: '',
        fcltId: '',
        line: {},
        direction: true, // true p1가 왼쪽 기준으로 밑에서 위로
        desc: '', //'어디어디행'
        entrance: true, //입구 출구 true 입구, false 출구
        del: false, //삭제 여부, 이전 데이터 정보 유지 위해서
      },
      webRtcInfo: {
        ip: '',
        port: 0,
        videoName: '',
      },
      videoWidth: 800,
      videoHeight: 450,
      rules: {},
      isLineDraw: 0,
      tempLineObject: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
      },
      lineData: [],
      selectedObject: null,
      isLineEdit: false,
    };
  },

  methods: {
    showDialog(fcltData) {
      if (!fcltData.fcltId) {
        return;
      }
      console.log(fcltData);
      this.fcltData = _.cloneDeep(fcltData);
      this.webRtcInfo.ip = fcltData.templateData.getValue('IP');
      this.webRtcInfo.port = fcltData.templateData.getValue('PORT');
      this.webRtcInfo.videoName = fcltData.templateData.getValue('WebRTC');
      this.formDialog = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        this.getFcltDirection();
        this.playWebrtcPlayer();
      });
    },
    playWebrtcPlayer() {
      //if (this.$refs.dkHiveWebrtcPlayer) this.$refs.dkHiveWebrtcPlayer.stop();

      // this.webRtcInfo.port = dkTemplateUtil.getTemplateValue(this.selectFcltMarkerData.fcltTypeData, 'webRtcPort') || 0;
      // this.webRtcInfo.ip = dkTemplateUtil.getTemplateValue(this.selectFcltMarkerData.fcltTypeData, 'webRtcIp') || '';
      // this.webRtcInfo.videoName = dkTemplateUtil.getTemplateValue(this.selectFcltMarkerData.fcltTypeData, 'webRtcVideoName') || '';

      setTimeout(() => {
        this.$refs.dkHiveWebrtcPlayer.play(this.webRtcInfo);
      }, 50);
    },
    hideDialog() {
      this.deselectObject();
      this.formDialog = false;
      this.tempLineObject = {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0,
      };
      this.lineData = [];
      this.fcltData = {};
      this.selectedObject = null;
    },
    openDialog() {},
    onloadWebrtcPlayer() {},
    async getFcltDirection() {
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltDirection/list`, {
        params: { fcltId: this.fcltData.fcltId },
      });
      console.log(response.data);
      response.data.forEach((val) => {
        if (!val.del) {
          let lineData = {
            objectId: val.fcltDirectionId,
            x1: val.line.p1.x || 0,
            y1: val.line.p1.y || 0,
            x2: val.line.p2.x || 0,
            y2: val.line.p2.y || 0,
            direction: val.direction,
            entrance: val.entrance,
            desc: val.desc || '',
            del: val.del || false,
          };
          this.lineData.push(lineData);
        }
      });
      this.$forceUpdate();
    },
    async save() {
      try {
        let exportLineData = [];
        this.$refs['lineObject'].forEach((val) => {
          let data = val.exportData();
          data.fcltId = this.fcltData.fcltId;
          data.screen = {
            width: this.videoWidth,
            height: this.videoHeight,
          };
          exportLineData.push(data);
        });
        let formData = {
          fcltId: this.fcltData.fcltId,
          lineData: exportLineData,
        };
        let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/fcltDirection/proc`, {
          data: formData,
        });
        this.$emit('saved', response);
        this.hideDialog();
        this.$message({
          type: 'success',
          message: `${this.fcltData.fcltName} AI 검지영역 설정 저장 완료`,
        });
      } catch (err) {
        console.error(err);
        this.hideDialog();
      }
    },
    mousedownEditor(e) {
      if (this.isLineEdit) {
        this.isLineDraw = 1;
        this.tempLineObject.x1 = e.offsetX;
        this.tempLineObject.y1 = e.offsetY;
        this.tempLineObject.x2 = e.offsetX;
        this.tempLineObject.y2 = e.offsetY;
      }
    },
    mouseupEditor(e) {
      if (this.isLineEdit && this.isLineDraw === 2) {
        const lineObj = {
          objectId: uuid(),
          x1: this.tempLineObject.x1,
          y1: this.tempLineObject.y1,
          x2: this.tempLineObject.x2,
          y2: this.tempLineObject.y2,
          direction: true,
          entrance: -1,
          del: false,
        };
        this.lineData.push(lineObj);
        this.isLineEdit = false;
        this.isLineDraw = 0;
        this.tempLineObject.x1 = 0;
        this.tempLineObject.y1 = 0;
        this.tempLineObject.x2 = 0;
        this.tempLineObject.y2 = 0;
      } else if (this.isLineDraw === 2 && this.selectedObject && this.selectedObject.getObjectType() === 'line') {
        this.isLineDraw = 0;
        this.tempLineObject.x1 = 0;
        this.tempLineObject.y1 = 0;
        this.tempLineObject.x2 = 0;
        this.tempLineObject.y2 = 0;
        return;
      }
    },
    mousemoveEditor(e) {
      if (this.isLineEdit && this.isLineDraw >= 1) {
        this.isLineDraw = 2;
        this.tempLineObject.x2 = e.offsetX;
        this.tempLineObject.y2 = e.offsetY;
        return;
      } else {
        if (this.isLineDraw >= 1 && this.selectedObject && this.selectedObject.getObjectType() === 'line') {
          this.isLineDraw = 2;
          if (this.selectedObject.getCurrentAction() == 'startPoint') {
            this.selectedObject.updateStartPoint(e.offsetX, e.offsetY);
          } else if (this.selectedObject.getCurrentAction() == 'endPoint') {
            this.selectedObject.updateEndPoint(e.offsetX, e.offsetY);
          }
        }
      }
    },
    editLineObject() {
      this.isLineDraw = 1;
    },
    setLineEdit() {
      this.deselectObject();
      this.isLineEdit = !this.isLineEdit;
    },
    deselectObject() {
      if (this.selectedObject) this.selectedObject.deselectObject();
    },

    selectObject(object) {
      if (this.selectedObject && this.selectedObject.getObjectId() == object.getObjectId()) {
      } else {
        if (this.selectedObject) this.selectedObject.deselectObject();

        this.selectedObject = object;
        this.formData = this.selectedObject.getObjectData();
      }
    },
    setLineDataToForm(item) {
      // this.formData = item;
      // console.log(item.objectId);
      // this.deselectObject();
      const targetObject = _.find(this.$refs.lineObject, (lo) => {
        return lo.getObjectId() === item.objectId;
      });
      targetObject.selectObject();
    },
    displayDirection(direction) {
      if (direction === true) {
        return '진입';
      } else if (direction === false) {
        return '진출';
      }
    },
    displayEntrance(entrance) {
      if (entrance === -1) {
        return '입출구 아님';
      } else if (entrance === 1) {
        return '입구';
      } else if (entrance === 0) {
        return '출구';
      }
    },
    removeObject() {
      if (this.selectedObject && this.selectedObject.getObjectId()) {
        if (this.selectedObject.getObjectId().indexOf('FD') < 0) {
          let delIdx = this.lineData.findIndex((ele) => {
            return ele.objectId === this.selectedObject.getObjectId();
          });
          this.lineData.splice(delIdx, 1);
          this.$forceUpdate();
        } else {
          console.log(this.selectedObject.getObjectId().indexOf('FD'));
          this.selectedObject.setDelete();
        }
        this.selectedObject = null;
      }
    },
    keyUpEvent(e) {
      if (this.selectedObject && this.selectedObject.objectId) {
        switch (e.keyCode) {
          case 46:
            this.removeObject();
            break;
        }
      }
    },
  },
};
</script>

<style scoped>
#videoPlayerWrap {
  position: relative;
}
.editor-canvas {
  position: absolute;
  z-index: 10;
}
</style>
