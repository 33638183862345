var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fill-width-height el-container",
      attrs: { id: "pageUserGroupInfo" }
    },
    [
      _c(
        "el-container",
        { staticClass: "page-body" },
        [
          _c(
            "el-main",
            [
              _c(
                "el-row",
                {
                  staticClass: "content-wrap",
                  staticStyle: { height: "100%" }
                },
                [
                  _c("dk-page-header"),
                  _c(
                    "el-col",
                    { staticClass: "group-wrap", attrs: { span: 24 } },
                    [
                      _c(
                        "div",
                        { staticClass: "group-list" },
                        [
                          _c(
                            "div",
                            { staticClass: "group-header" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small" },
                                  on: { click: _vm.addUserGroup }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-plus",
                                    staticStyle: { color: "#1783f0" }
                                  }),
                                  _vm._v(" 그룹 추가 ")
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._l(_vm.groupList, function(item, index) {
                            return _c(
                              "div",
                              { key: item.menuId, staticClass: "group-cell" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "group-name",
                                    attrs: { "data-text": item.name },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectGroup(item)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "group-button" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { size: "mini" },
                                        on: {
                                          click: function($event) {
                                            return _vm.updateUserGroup(item)
                                          }
                                        }
                                      },
                                      [_vm._v("수정")]
                                    ),
                                    item.authLevel
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled:
                                                item.userList &&
                                                item.userList.length > 0
                                                  ? true
                                                  : false,
                                              size: "mini",
                                              type: "danger"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeUserGroup(item)
                                              }
                                            }
                                          },
                                          [_vm._v("삭제")]
                                        )
                                      : _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              visibility: "hidden"
                                            },
                                            attrs: {
                                              size: "mini",
                                              type: "danger"
                                            }
                                          },
                                          [_vm._v("삭제")]
                                        )
                                  ],
                                  1
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "user-list" },
                        [
                          _c("div", { staticClass: "user-list-group" }, [
                            _c("div", [
                              _c("span", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(_vm.selectedGroupInfo.name) +
                                    "\n                  " +
                                    _vm._s(_vm.userListByGroup.length) +
                                    "명\n                "
                                )
                              ])
                            ]),
                            _c(
                              "span",
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      multiple: "",
                                      filterable: "",
                                      remote: "",
                                      "reserve-keyword": "",
                                      placeholder: "그룹 추가 대상 사용자 검색",
                                      "remote-method": _vm.remoteMethod,
                                      loading: _vm.loading
                                    },
                                    model: {
                                      value: _vm.targetUsers,
                                      callback: function($$v) {
                                        _vm.targetUsers = $$v
                                      },
                                      expression: "targetUsers"
                                    }
                                  },
                                  _vm._l(_vm.options, function(item) {
                                    return _c("el-option", {
                                      key: item.userid,
                                      attrs: {
                                        label: item.name,
                                        value: item.userid
                                      }
                                    })
                                  }),
                                  1
                                ),
                                _vm.targetUsers.length > 0
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: { click: _vm.procUserGroup }
                                      },
                                      [_vm._v("그룹 변경")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]),
                          _c(
                            "el-table",
                            {
                              staticClass: "user-list-grid",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.userListByGroup,
                                size: "small",
                                "default-sort": {
                                  prop: "userid",
                                  order: "descending"
                                }
                              }
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "",
                                  width: "80",
                                  "header-align": "center",
                                  align: "center",
                                  fixed: "left"
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        scope.row.images &&
                                        scope.row.images.length > 0
                                          ? _c("span", {
                                              staticClass:
                                                "user-list-thumb-img",
                                              style:
                                                "background-image:url(" +
                                                _vm.$config.getServerConfig()
                                                  .image.url +
                                                scope.row.images[0]
                                                  .serverFilePath +
                                                ")"
                                            })
                                          : _c("span", {
                                              staticClass:
                                                "user-list-thumb-img",
                                              staticStyle: {
                                                "background-image":
                                                  "url(static/images/unknown.png)"
                                              }
                                            })
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "userid",
                                  label: "아이디",
                                  width: "120",
                                  "header-align": "center",
                                  align: "center",
                                  sortable: ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "name",
                                  label: "이름",
                                  width: "130",
                                  "header-align": "center",
                                  align: "center",
                                  sortable: ""
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "phone",
                                  label: "전화번호",
                                  width: "150",
                                  "header-align": "center",
                                  align: "center"
                                }
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "email",
                                  label: "이메일",
                                  "header-align": "center",
                                  align: "center"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("form-user-group-info", {
        ref: "formUserGroupInfo",
        on: { procDataComplete: _vm.procDataComplete }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }