<template>
    <el-row>
        <el-col :xs="10" :sm="12" :md="12" class="grid-header-menu">
            <el-button
                type="primary"
                @click="openFormDialog(null)"                
                size="medium"
            >
                <i class="fas fa-plus-circle">&nbsp;추가</i>
            </el-button>
            <el-button
                size="medium"
                class="icon-button"
                @click="doExportPdf"
                v-if="exportPdf"
            >
                <i class="fas fa-file-pdf"></i>
            </el-button>
            <el-button
                size="medium"
                class="icon-button"
                @click="doExportExcel"
                v-if="exportExcel"
            >
                <i class="fas fa-file-excel"></i>
            </el-button>
        </el-col>
        <el-col :xs="14" :sm="12" :md="12" class="grid-header-menu" style="text-align:right;" v-if="isSearch">
            <el-input 
                placeholder="검색" 
                style="width:calc(100% - 60px); max-width:220px" 
                size="medium"
                v-model="keyword"
                @keyup.enter.native="doSearch"
                ></el-input>
            <el-button
                size="medium"
                class="icon-button"
                @click="doSearch"
            >
                <i class="fas fa-search"></i>
            </el-button>
            <!-- <el-button
                size="medium"
                class="icon-button"
            >
                <i class="fas fa-info-circle"></i>
            </el-button> -->
        </el-col>
    </el-row>
</template>

<script>
export default {
    data() {
        return {
            keyword: ""
        }
    },
    props : {        
        openFormDialog: {
            type:Function,
            default: function(flag) {

            }
        },
        search: {
            type:Function,
            default:null
        },
        pageInfo: {},
        exportExcel: {},
        exportPdf: {},
        isSearch: {
            type:Boolean,
            default: false
        }
    },
    methods :{
        doExportPdf() {
            if (this.exportPdf) {
                this.exportPdf();
            }
        },
        doExportExcel() {
            if (this.exportExcel) {
                this.exportExcel();
            }
        },
        doSearch() {
            if (this.search) {
                this.search(this.keyword);
            }
        }
    }
}
</script>

<style>
</style>
