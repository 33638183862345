<template>
  <div>
    <div class="top-bar-space" style="bottom: 0; overflow: hidden;">
      <div :class="'side-menu-container ' + (isNaviSelect ? 'd-block' : 'd-none')" @click="backgroundOff">
        <div class="navi-wrap d-flex">
          <div class="btn-style" @click="kakaoNavi"><img src="../assets/img/kakaonavi.png" /><span>카카오내비</span></div>
          <div class="btn-style" @click="tmapNavi"><img src="../assets/img/tmap.png" /><span>TMap</span></div>
        </div>
      </div>
      <div :class="'gis-search-mb ' + (!isGisSearchResult ? '' : 'gis-search-mb-focus')" v-show="isUIView">
        <span class="bar-back" @click="cancelSearchAdress">
          <i class="fas fa-chevron-left"></i>
        </span>
        <el-input
          placeholder="위치 검색"
          ref="mobileSearch"
          id="mobileSearch"
          v-model="keyword"
          @focus="openSearchAdress"
          @keyup.enter.native="onSearch"
        ></el-input>
        <!-- <span class="bar-cancle">
          <i class="fas fa-times"></i>
        </span> -->
      </div>
      <div class="legend-wrap" v-show="isUIView">
        <ul>
          <li
            :class="'flex-center ' + isSelectFcltType(fcltType)"
            @click="onClickFcltType(fcltType)"
            v-for="fcltType in fcltTypes"
            :key="fcltType.fcltTypeId"
          >
            <img :src="fcltType.gisMarkerImage.normal" />
            <span>{{ fcltType.fcltTypeName }}</span>
          </li>
        </ul>
      </div>
      <div class="func-item-container">
        <div
          v-show="isUIView"
          v-for="funcItem in funcItemList"
          :key="funcItem.key"
          :class="'btn-style func-btn-box ' + isClickReaction(funcItem)"
          @click="(e) => onClickFuncItem(e, funcItem)"
        >
          <!-- :class="'btn-style ' + (funcItem.isSelect ? 'select-func' : '')" -->
          <i :class="funcItem.iClassName"></i>
        </div>
        <div
          v-show="isUIView"
          :class="['btn-style func-btn-box ', isLabelVuew || isGisMarkerLabel ? 'select-func' : '']"
          @click="toggleGisMarkerLabel"
        >
          <!-- :class="'btn-style ' + (funcItem.isSelect ? 'select-func' : '')" -->
          <i :class="[isLabelVuew || isGisMarkerLabel ? 'icon-eye-open' : 'icon-eye-close']" style="color: #3f4d67;"></i>
        </div>
      </div>
      <div class="mygis-location btn-style" @click="getGeolocation" v-show="isUIView">
        <i class="icon-target"></i>
        <div>현재위치</div>
      </div>

      <div :class="'fclt-menu-item-container ' + (selectFcltMarkerData.fcltId ? '' : 'd-none')" ref="detail">
        <span class="fclt-info-open" @click="selectFcltDetail">
          <i :class="'arrow-click icon-arrow' + (isArrowReverse ? ' arrow-reverse' : '')"></i> 시설물 보기
        </span>
        <div class="mobile-fclt-label" @click="selectFcltDetail">
          <p class="fclt-name">{{ selectFcltMarkerData.fcltName }}</p>
          <span class="fclt-type">{{ selectFcltMarkerData.fcltTypeName }}</span>
        </div>
        <ul class="fclt-detail-view-menu">
          <li v-if="!maintenanceId" @click="maintnce" class="btn-style">
            <i class="fas fa-wrench" style="color: #939393;"></i>
            <div>유지관리등록</div>
          </li>
          <li v-if="!maintenanceId" @click="onClickMaintenance()" class="btn-style">
            <i class="icon-paper"></i>
            <div>유지관리내역</div>
          </li>
          <li @click="setRoadview(selectFcltMarkerData.gisLat, selectFcltMarkerData.gisLng)" class="btn-style">
            <i class="icon-marker"></i>
            <div>거리뷰</div>
          </li>
          <li @click="selectNavigation">
            <!-- <li @click="tmapNavi(selectFcltMarkerData.fcltName, selectFcltMarkerData.gisLng, selectFcltMarkerData.gisLat)" class="btn-style"> -->
            <i class="icon-navi"></i>
            <div>네비게이션</div>
          </li>
          <li v-if="maintenanceId" @click="showMaintenanceOne" class="btn-style">
            <!-- <li @click="tmapNavi(selectFcltMarkerData.fcltName, selectFcltMarkerData.gisLng, selectFcltMarkerData.gisLat)" class="btn-style"> -->
            <i class="icon-paper"></i>
            <div>상황전파확인</div>
          </li>
        </ul>
        <div class="">
          <div class="dk-card">
            <table class="el-table el-table--mini table-width">
              <tr>
                <th>시설물 명칭</th>
                <td>{{ selectFcltMarkerData.fcltName }}</td>
              </tr>
              <tr>
                <th>시설물 유형</th>
                <td>{{ selectFcltMarkerData.fcltTypeName }}</td>
              </tr>
              <tr>
                <th>도로명 주소</th>
                <td>{{ selectFcltMarkerData.setRoadAddr }}</td>
              </tr>
              <tr>
                <th>지번 주소</th>
                <td>{{ selectFcltMarkerData.setNumberAddr }}</td>
              </tr>
              <tr>
                <th>경도</th>
                <td>{{ selectFcltMarkerData.gisLng }}</td>
              </tr>
              <tr>
                <th>위도</th>
                <td>{{ selectFcltMarkerData.gisLat }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <div class="fclt-menu-item-container" style="top: calc(100% - 96px) !important;" v-if="placeInfoMaker && placeInfoMaker.marker">
        <!-- <span class="fclt-info-open" @click="selectFcltDetail"> <i class="icon-arrow"></i> 시설물 보기 </span> -->
        <div class="mobile-fclt-label">
          <p class="fclt-name">{{ placeInfoMaker.geoInfo.addr.num_addr }}</p>
          <!-- <span class="fclt-type">{{ selectFcltMarkerData.fcltTypeName }}</span> -->
        </div>
        <ul class="fclt-detail-view-menu">
          <!-- <li @click="maintnce">
            <i class="fas fa-wrench"></i>
            <div>유지관리등록</div>
          </li>
          <li @click="onClickMaintenance()">
            <i class="fas fa-wrench"></i>
            <div>유지관리내역</div>
          </li> -->
          <li @click="setRoadview(placeInfoMaker.geoInfo.geometry.lat, placeInfoMaker.geoInfo.geometry.lng)">
            <i class="icon-marker"></i>
            <div>거리뷰</div>
          </li>
          <li @click="selectNavigation">
            <!-- <li @click="tmapNavi(placeInfoMaker.geoInfo.addr.num_addr, placeInfoMaker.geoInfo.geometry.lat, placeInfoMaker.geoInfo.geometry.lng)"> -->
            <i class="icon-navi"></i>
            <div>네비게이션</div>
          </li>
        </ul>
      </div>

      <div class="fill-width-height" ref="gisContainer">
        <dk-gis
          ref="dkGis"
          id="dkGis"
          fill-height
          :gisApiInfo="gisApiInfo"
          :apiLoadComplete="apiLoadComplete"
          :markerData="[]"
          :clusterData="clusterData"
          :clickMarker="selectFclt"
          :selectBoxData="selectBoxData"
          @moveEnd="changedBounds"
          @zoomEnd="zoomEnd"
          @selectMarker="selectMarker"
        />
      </div>

      <!-- 시설물 검색창 -->
      <div class="gis-search-result" v-show="isGisSearchResult">
        <h3>주소 정보</h3>
        <ul v-if="searchPlaceData.length > 0">
          <li v-for="(item, idx) in searchPlaceData" :key="idx">
            <div class="search-list-text">
              <span class="title">{{ item.addressName }}</span>
            </div>
            <div class="search-list-button">
              <i class="fas fa-arrows-alt button-move" @click="moveGisCenter(item)"></i>
            </div>
          </li>
        </ul>
        <div class="no-result" v-else>검색된 주소정보가 없습니다.</div>
        <h3>시설물 정보</h3>
        <ul v-if="searchTempMarkerData.length > 0">
          <li v-for="(item, idx) in searchTempMarkerData" :key="idx">
            <div class="search-list-text">
              <div style="margin-bottom: 5px;">
                <span class="title">{{ item.properties.fcltName }}</span>
                {{ item.properties.fcltTypeName }}
              </div>
              <div>{{ item.properties.setNumberAddr }}</div>
            </div>
            <div class="search-list-button">
              <i class="fas fa-map-marker-alt" @click="applySearchMarkerData(item)"></i>
            </div>
          </li>
        </ul>
        <div class="no-result" v-else>검색된 시설물정보가 없습니다.</div>
      </div>
    </div>
    <span><img src="" /></span>
    <span></span>
    <!-- </el-dialog> -->
    <form-maintnce ref="formMaintnce"></form-maintnce>
    <form-maintance-view ref="formMaintanceView"></form-maintance-view>
  </div>
</template>

<script>
import dkgismain from '@/components/dkGis/dkGisMain.vue';
// import formMaintnce from '@/core/forms/formMaintnce.vue';
import formMaintnce from './forms/formMaintnce.vue';
import formMaintanceView from './forms/formMaintanceView.vue';

export default {
  components: {
    'dk-gis': dkgismain,
    'form-maintnce': formMaintnce,
    'form-maintance-view': formMaintanceView,
  },
  props: {
    checkBackAction: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    markerLayer: undefined,
    gisApiInfo: [],
    fcltTypes: [],
    // findFcltTypes: ['20d61430-90b2-11e9-afcb-b5143e4b15f4'],
    // findFcltTypes: ['FCLTTYPE10005'],
    findFcltTypes: [],
    selectFcltTypes: [],
    fclts: [],
    selectFcltMarkerData: {
      fcltId: '',
      fcltName: '',
      fcltTypeName: '',
      fcltTypeId: '',
      regionId: '',
      regionName: '',
      setDate: '',
      setNumberAddr: '',
      setRoadAddr: '',
      gisLat: '',
      gisLng: '',
      equip: [],
      fcltTypeData: {},
      images: [],
      files: [],
    },

    funcItemList: [
      {
        key: 'trafficLayer',
        startFunc: 'setTrafficLayer',
        endFunc: 'setTrafficLayer',
        iClassName: 'fas fa-car-alt',
        isSelect: false,
      },
      {
        key: 'distanceToggle',
        startFunc: 'startLineDraw',
        endFunc: 'endLineDraw',
        iClassName: 'icon-ruler',
        isSelect: false,
      },
      // {
      //   key: 'labelToggle',
      //   startFunc: 'openLabel',
      //   endFunc: 'closeLabel',
      //   iClassName: 'icon-eye-open',
      //   isSelect: true,
      // },
      {
        key: 'showPlaceInfo',
        startFunc: 'startShowPlaceInfo',
        endFunc: 'endShowPlaceInfo',
        iClassName: 'icon-facility',
        isSelect: false,
      },
      // {
      //   key: 'coverageToggle',
      //   startFunc: 'coverageOn',
      //   endFunc: 'coverageOff',
      //   iClassName: 'icon-facility',
      //   isSelect: true,
      // },
    ],

    clusterData: [],
    isOnTrafficLayer: false,
    geolocationAddr: '정보 없음',
    isGisSearchResult: false,
    searchPlaceData: [],
    searchTempMarkerData: [],
    searchPlaceData: [],
    keyword: '',
    changedBoundsTimer: 0,
    placeInfoMaker: {},
    isUIView: true,
    onOff: false,

    isLabelVuew: true,
    isNaviSelect: false,
    nowClickMapEventKey: '',
    freeClickMapEventKeyList: [],
    isArrowReverse: false,

    startY: 0,
    moveY: 0,

    cirecleInfo: {
      radius: 200, // 미터 단위의 원의 반지름입니다
      strokeWeight: 1, // 선의 두께입니다
      // strokeColor: '#75B8FA', // 선의 색깔입니다
      strokeColor: '#75B8FA', // 선의 색깔입니다
      strokeOpacity: 1, // 선의 불투명도 입니다 1에서 0 사이의 값이며 0에 가까울수록 투명합니다
      // strokeStyle, // 선의 스타일 입니다
      fillColor: '#CFE7FF', // 채우기 색깔입니다
      fillOpacity: 0.3, // 채우기 불투명도 입니다
      visible: true,
    },
    circleLayer: undefined,

    selectBoxData: [],

    level: 7,

    lat: 35.5830624002618,
    lng: 129.36080151001,

    maintenanceId: '',
    maintenanceFcltId: '',
  }),
  created() {
    const me = this;

    if (me.$isMobile && !Kakao.isInitialized()) {
      Kakao.init(me.$config.getSiteConfig().gis.apiKey);
    }

    // const { ulsanbukgu = {} } = me.$config.getSiteConfig();
    const { mobile = {} } = me.$config.getSiteConfig();
    const { ulsanbukgu = {} } = mobile;
    const { cctvFcltTypeIdList = [] } = ulsanbukgu;
    me.findFcltTypes = cctvFcltTypeIdList;

    // const { query = {} } = me.$route;
    // const { name, fcltTypes } = query;
    // if (query.fcltTypes) {
    //   if (Array.isArray(fcltTypes)) {
    //     me.findFcltTypes = fcltTypes;
    //   } else {
    //     me.findFcltTypes = [fcltTypes];
    //   }
    // }

    (async () => {
      me.gisApiInfo = me.$store.getters['gis/getGisApi'];
      if (!me.gisApiInfo.length || me.gisApiInfo.length.length === 0) {
        await me.$store.dispatch('gis/actGisApi', {});
        me.gisApiInfo = me.$store.getters['gis/getGisApi'];
      }
      me.gisApiInfo[0].apiKey = me.$config.getSiteConfig().gis.apiKey;
      me.gisApiInfo[0].geometry = {
        lat: me.lat,
        lng: me.lng,
      };
      me.gisApiInfo[0].level = me.level;
    })();

    // me.backAction();
  },
  async mounted() {
    const me = this;
    this.backAction();

    this.$refs.detail.addEventListener('touchstart', this.onTouchstart, false);
    this.$refs.detail.addEventListener('touchmove', this.onTouchmove, false);
    this.$refs.detail.addEventListener('touchend', this.onTouchEnd, false);

    const checkRoopFunc = async () => {
      try {
        me.$refs.dkGis.getLevel();
      } catch (e) {
        // console.error(`getLevel sincronize error`);
        return setTimeout(checkRoopFunc, 50);
      }
      const fcltTypes = await me.requestFcltType();
      me.fcltTypes = [...fcltTypes];
      me.selectFcltTypes = [...fcltTypes];
      await me.getFclt();

      const { query = {} } = me.$route;
      if (query.maintenanceId) {
        me.maintenanceId = query.maintenanceId;
        if (query.fcltId) {
          const ck = await me.selectFcltFromFcltId(query.fcltId);
          if (!ck) {
            me.selectPlace(query.gisLat, query.gisLng);
          }
        } else if (!!query.gisLat && !!query.gisLng) {
          me.selectPlace(query.gisLat, query.gisLng);
        }
      }

      // me.initMarkerPosition();
    };

    checkRoopFunc();
  },
  computed: {},
  methods: {
    backAction() {
      const me = this;
      window.backAction = () => {
        try {
          if (window.JSBridge && window.JSBridge.wvStatus) {
            window.JSBridge.wvStatus('using');
          }
          // 사이드 메뉴바 상테 확인
          if (this.checkBackAction()) {
            return;
          }
          // 로드뷰가 나오고 있을때
          else if (this.$refs.dkGis.isShowRoadView()) {
            this.$refs.dkGis.hideRoadview();
          }
          // 유지보수등록 창이 떠있을 때
          else if (this.$refs.formMaintnce.isOpenDialog()) {
            this.$refs.formMaintnce.hideDialog();
          }
          // 유지보수 위치보기에서 넘어온 경우
          else if (this.maintenanceId) {
            this.$router.go(-1);
          }
          // 네비게이션 선택창 닫기
          else if (this.isNaviSelect) {
            this.backgroundOff();
          }
          // 선택한 시설물 취소
          else if (this.selectFcltMarkerData.fcltId) {
            this.clearFclt();
          }
          // 시설물 선택 박스 취소
          else if (!!this.selectBoxData && this.selectBoxData.length > 0) {
            this.selectBoxData = [];
          }
          // 검색창 닫기
          else if (this.isGisSearchResult) {
            this.cancelSearchAdress();
          } else {
            this.$router.go(-1);
          }
        } catch (err) {
          // this.$message('뒤로가기 에러');
          this.$router.push(this.$config.getServerConfig().baseUrl);
        }
      };
    },

    async selectFcltFromFcltId(fcltId) {
      const { markerData: mkData } = await this.requestFclt({ fcltId });

      if (mkData.length > 0) {
        this.maintenanceFcltId = fcltId;
        this.selectFclt(mkData[0].properties);
        return true;
      } else {
        return false;
      }
    },

    async selectPlace(gisLat, gisLng) {
      const me = this;
      me.startShowPlaceInfo();
      me.showPlaceInfo(undefined, { lat: gisLat, lng: gisLng });

      this.$refs.dkGis.setLevel(1);
    },

    apiLoadComplete(currentGisApi) {
      this.markerLayer = this.$refs.dkGis.createMarkerLayer();
      this.setBasicMapClickEvent();
    },

    maintnce() {
      this.$refs.formMaintnce.showDialog(this.selectFcltMarkerData);
    },

    onClickMaintenance() {
      // this.$router.push('/maintenance');
      const { fcltId, fcltName } = this.selectFcltMarkerData;
      this.$router.push({
        path: '/maintenance',
        query: { fcltId, fcltName },
      });
    },

    async requestFcltType() {
      const me = this;
      try {
        const fcltTypeList = await me.$http.post(`${this.$config.getServerConfig().core.api}/core/api/fcltType`, {});

        if (fcltTypeList.status !== 200) {
          throw new Error('requestFcltType faile');
        }

        if (!me.findFcltTypes || me.findFcltTypes.length === 0) {
          return fcltTypeList.data;
        } else {
          return fcltTypeList.data.filter((fcltType) => {
            const ck = me.findFcltTypes.findIndex((elm) => elm === fcltType.fcltTypeId);
            return ck > -1;
          });
        }
      } catch (err) {
        // me.$message({ type: 'error', message: 'requestFcltType faile' });
        return [];
      }
    },

    async requestFclt(params) {
      const me = this;
      try {
        const search = {
          ...params,
        };
        // const fcltList = await me.$http.post(`${this.$config.getServerConfig().core.api}/core/api/fclt`,{search});
        const fcltList = await me.$http.get(`${this.$config.getServerConfig().core.api}/core/api/gis/getFcltMarker`, { params: search });

        if (fcltList.status !== 200) {
          throw new Error('requestFclt faile');
        }
        return fcltList.data;
      } catch (err) {
        // me.$message({ type: 'error', message: 'requestFclt faile' });
        return [];
      }
    },

    setIwContent(text) {
      return `<div class="el-tooltip__popper is-dark infowindow-position" style="margin-top:-20px">${text}</div>`;
    },

    async getFclt() {
      const me = this;
      const zoomLevel = me.$refs.dkGis.getLevel();
      const bounds = me.$refs.dkGis.getBounds();
      const centerCoords = me.$refs.dkGis.getCenter();

      let fclts = [];
      let clusterData = [];

      const fcltTypeId = me.selectFcltTypes.map((fcltType) => fcltType.fcltTypeId);

      const params = {
        fcltTypeId,

        bounds,
        zoomLevel,
        centerCoords,
        clusterLv0: 0,
        clusterLv1: 4,
        clusterLv2: 7,
        clusterEnable: true,
      };

      if (me.maintenanceFcltId) {
        params.fcltId = me.maintenanceFcltId;
      }

      const response = await me.requestFclt(params);

      this.$nextTick(() => {
        if (response.clusterData && response.clusterData.length > 0) {
          this.fclts = [];

          this.clusterData = response.clusterData.map((elm) => {
            const { properties = {} } = elm;
            return {
              ...elm,
              key: 'cluster_' + properties.clusterCd,
            };
          });
        } else {
          this.clusterData = [];
        }
        if (response.markerData && response.markerData.length > 0) {
          const fcltTypeMakerImg = {};
          me.fcltTypes.forEach((fType) => {
            const { fcltTypeId, gisMarkerImage } = fType;
            fcltTypeMakerImg[fcltTypeId] = gisMarkerImage.normal;
          });
          this.fclts = response.markerData.map((mData) => {
            const { geometry, properties } = mData;
            let markerImage = undefined;
            if (fcltTypeMakerImg[properties.fcltTypeId]) {
              markerImage = { src: fcltTypeMakerImg[properties.fcltTypeId] };
            }
            return {
              key: properties.fcltId,
              lat: geometry.lat,
              lng: geometry.lng,
              markerImage,
              // event: { click: me.selectFclt },
              event: { click: me.onClickMarker },
              infowindow: {
                iwContent: me.getLabel(65, properties.fcltName),
                visibleIw: me.isLabelVuew,
              },
              properties,
            };
          });
        } else {
          this.fclts = [];
        }

        me.clearCircleLayer();
        if (this.fclts.length > 0) {
          console.log('createCircleLayer');
          me.createCircleLayer();
        }
      });
    },

    getLabel(infoWindowOffsetY = 0, fcltName) {
      return `<div class="el-tooltip__popper is-dark infowindow-position" style="margin-top:-${infoWindowOffsetY}px">
                ${fcltName}
            <div>`;
    },

    async changedBounds() {
      if (this.maintenanceId) return;
      const me = this;
      const time = 500;

      // if (me.changedBoundsTimer > 0) return;

      // me.changedBoundsTimer = time;

      // setTimeout(() => {
      //   me.getFclt();
      //   me.changedBoundsTimer = 0;
      // }, me.changedBoundsTimer);

      if (me.changedBoundsTimer > 0) {
        me.changedBoundsTimer = time;
        return;
      }

      me.changedBoundsTimer = time;

      const timerFunc = () => {
        setTimeout(() => {
          me.changedBoundsTimer -= 50;

          if (me.changedBoundsTimer <= 0) {
            me.getFclt();
          } else {
            timerFunc();
          }
        }, 50);
      };

      timerFunc();
    },

    handleClose(done) {
      this.$confirm('Are you sure to close this dialog?')
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },

    onClickMarker(properties) {
      const overlapMarkerList = this.checkOverlapMarker(this.fclts, properties);

      if (overlapMarkerList.length === 1) {
        this.selectFclt(properties);
      } else if (overlapMarkerList.length > 1) {
        this.openMarkerSelectBox(overlapMarkerList);
      }
    },

    selectFclt(properties) {
      // const { properties } = args;

      this.selectFcltMarkerData = properties;

      this.$refs.dkGis.setCenter({
        lat: properties.gisLat,
        lng: properties.gisLng,
      });

      this.$refs.dkGis.setLevel(1);
      // this.changedBounds();
      this.getFclt();

      this.isUIView = true;
    },

    checkOverlapMarker(fcltList, markerInfo) {
      const me = this;
      try {
        const { gisLat, gisLng } = markerInfo;

        const level = me.$refs.dkGis.getLevel();

        const overlapMarkerList = fcltList.filter((fcltInfo) => {
          const { lat, lng } = fcltInfo;
          // return gisLat === lat && gisLng === lng;
          const i = Math.abs(gisLat - lat) + Math.abs(gisLng - lng);
          return i * 3000 < level;
        });
        return overlapMarkerList;
      } catch (err) {
        // me.$message.error('checkOverlapMarker Error');
        return [];
      }
    },

    openMarkerSelectBox(overlapMarkerList) {
      if (overlapMarkerList.length === 0) return;

      const key = overlapMarkerList[0].key;
      const geometry = {
        lat: overlapMarkerList[0].lat,
        lng: overlapMarkerList[0].lng,
      };

      this.selectBoxData = [
        {
          key,
          geometry,
          markerList: overlapMarkerList.map((elm) => ({
            ...elm,
            name: elm.properties.fcltName,
          })),
        },
      ];
    },

    clearFclt() {
      this.selectFcltMarkerData = {
        fcltId: '',
        fcltName: '',
        fcltTypeName: '',
        fcltTypeId: '',
        regionId: '',
        regionName: '',
        setDate: '',
        setNumberAddr: '',
        setRoadAddr: '',
        gisLat: '',
        gisLng: '',
        equip: [],
        fcltTypeData: {},
        images: [],
        files: [],
      };
    },

    setRoadview(lat, lng) {
      this.$refs.dkGis.setRoadview({ lat, lng });
    },

    selectNavigation() {
      this.isNaviSelect = true;
    },

    checkMobileWeb() {
      return this.$isMobile && !window.modbileDeviceId;
    },

    kakaoNavi() {
      if (!this.checkMobileWeb()) {
        window.JSBridge.kakaoNavi(this.selectFcltMarkerData.fcltName, this.selectFcltMarkerData.gisLng, this.selectFcltMarkerData.gisLat);
      } else {
        if (Kakao) {
          Kakao.Navi.start({
            name: this.selectFcltMarkerData.fcltName || this.selectFcltMarkerData.setNumberAddr,
            x: this.selectFcltMarkerData.gisLng,
            y: this.selectFcltMarkerData.gisLat,
            coordType: 'wgs84',
          });
        }
      }
    },

    tmapNavi() {
      window.JSBridge.tmapNavi(this.selectFcltMarkerData.fcltName, this.selectFcltMarkerData.gisLng, this.selectFcltMarkerData.gisLat);
    },

    onClickFcltType(fcltType) {
      const { fcltTypeId } = fcltType;
      const ck = this.selectFcltTypes.findIndex((ft) => ft.fcltTypeId === fcltTypeId);

      if (ck === -1) {
        this.selectFcltTypes.push(fcltType);
      } else {
        this.selectFcltTypes.splice(ck, 1);
      }

      this.getFclt();
    },

    isSelectFcltType(fcltType) {
      const { fcltTypeId } = fcltType;
      const ck = this.selectFcltTypes.findIndex((ft) => ft.fcltTypeId === fcltTypeId);

      if (ck === -1) {
        return 'not-select-fclt-type';
      } else {
        return 'select-fclt-type';
      }
    },

    getGeolocation() {
      let me = this;
      if ('geolocation' in navigator) {
        /* 지오로케이션 사용 가능 */
        navigator.geolocation.getCurrentPosition(async (position) => {
          let data = await me.$refs.dkGis.actGeolocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          me.geolocationAddr = data.addr && data.addr.num_addr ? data.addr.num_addr : '정보 없음';
        });
        // this.watchID = navigator.geolocation.watchPosition((position) => {
        //   console.log(position.coords.latitude, position.coords.longitude);

        //   this.$refs.dkGis.setCenter({
        //       lat:position.coords.latitude,
        //       lng:position.coords.longitude
        //   })
        // });
      } else {
        /* 지오로케이션 사용 불가능 */
      }
    },

    getMyPosition() {
      return new Promise((resolve, reject) => {
        // if ('geolocation' in navigator) {
        if (navigator.geolocation) {
          /* 지오로케이션 사용 가능 */
          navigator.geolocation.getCurrentPosition(
            (position) => {
              return resolve({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            },
            (err) => {
              // alert('GPS 기능을 지원하지 않는 상태 입니다.');
              return reject();
            }
          );
        } else {
          // alert('GPS 기능을 지원하지 않습니다.');
          return reject();
        }
      });
    },

    async initMarkerPosition() {
      // const position = await this.getMyPosition();
      // const { lat, lng } = position;
      // this.$refs.dkGis.setCenter({ lat, lng });
      // this.$refs.dkGis.setLevel(this.level);
    },

    openSearchAdress() {
      this.isGisSearchResult = true;
    },

    cancelSearchAdress() {
      this.isGisSearchResult = false;
      this.$refs.mobileSearch.blur();
    },

    async onSearch() {
      let param = {};
      this.searchTempMarkerData = [];
      this.searchPlaceData = [];

      param.keyword = this.keyword;
      param.fcltTypeId = this.findFcltTypes;

      // 장소 데이터는 검색하고 적용 했을 시, 그 부근으로 지도를 이동시키는 기능으로 실행한다.
      this.searchPlaceData = await this.$refs.dkGis.getCoordFromAddr(param.keyword);
      // 검색된 시설물 데이터는 임시 목록에 저장한 후, 적용하였을 시, markerData에 엎어씌워서
      // 시설물 마커를 rendering하고 선택한다.
      param.clusterLv0 = 0;
      param.clusterLv1 = 999;
      param.clusterLv2 = 999;
      let tmpData = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/gis/getFcltMarker`, { params: param });
      this.searchTempMarkerData = tmpData.data.markerData;
    },

    async moveGisCenter(placeData) {
      let data = await this.$refs.dkGis.actGeolocation({
        lat: placeData.lat,
        lng: placeData.lng,
      });
      this.geolocationAddr = placeData.addressName;
      this.isGisSearchResult = false;
      this.searchFclt();
      // this.geolocationAddr = (data.addr && data.addr.num_addr) ? data.addr.num_addr : "정보 없음"
    },

    applySearchMarkerData(fcltData) {
      // document.getElementById('mainView').scrollTop = 0;
      this.isGisSearchResult = false;
      this.isNoSearchByGisMove = true;
      this.clusterData = [];
      // this.markerData = [fcltData];
      // this.fclts = [fcltData];
      this.selectFclt(fcltData.properties);
    },

    distanceHtml(distance) {
      return `<div class="distance-wrap"><span class="distance-text">거리</span><span class="distance-count">${distance}</span>m</div>`;
    },

    onClickFuncItem(e, funcItem) {
      const me = this;

      const item = me.funcItemList.find((fi) => fi.key === funcItem.key);
      if (!item) return;

      if (!item.isSelect) {
        item.isSelect = true;
        me[item.startFunc](e, item);
      } else {
        item.isSelect = false;
        if (item.endFunc) {
          me[item.endFunc](e, item);
        }
      }
    },

    // 교통레이어 표출
    setTrafficLayer(e) {
      const funcItem = this.funcItemList.find((funcItem) => funcItem.key === 'trafficLayer');

      this.isOnTrafficLayer = this.$refs.dkGis.setTrafficLayer(!this.isOnTrafficLayer);
    },

    // 거리재기 온
    startLineDraw() {
      this.$refs.dkGis.startLineDraw({ getHtml: this.distanceHtml });
      this.onFuncItem('distanceToggle');
    },

    // 거리재기 오프
    endLineDraw() {
      this.$refs.dkGis.endLineDraw('distanceToggle');
      this.offFuncItem('distanceToggle');
    },

    // 클릭지점 정보 보기 온
    startShowPlaceInfo(e) {
      this.onFuncItem('showPlaceInfo');
      this.$refs.dkGis.setClickEvent(this.showPlaceInfo);
    },

    // 클릭지점 정보 보기 오프
    endShowPlaceInfo(e) {
      this.freePlaceInfo();
      this.$refs.dkGis.freeClickEvent(this.showPlaceInfo);
      this.offFuncItem('showPlaceInfo');
    },

    toggleGisMarkerLabel() {
      const me = this;
      me.isLabelVuew = !me.isLabelVuew;
      // this.$store.dispatch('gis/actIsOpenInfoWindow', { value: this.isLabelVuew });
      const markerList = me.markerLayer.markerList;
      markerList.forEach(({ marker }) => {
        marker.visibleIw = me.isLabelVuew;
      });
    },

    // // 라벨 표출 온
    // openLabel() {
    //   this.isLabelVuew = true;
    //   this.$store.dispatch('gis/actIsOpenInfoWindow', { value: this.isLabelVuew });
    //   // const funcItem = this.funcItemList.find(elm=>elm.key==='showPlaceInfo');
    // },

    // // 라벨 표출 오프
    // closeLabel() {
    //   this.isLabelVuew = false;
    //   this.$store.dispatch('gis/actIsOpenInfoWindow', { value: this.isLabelVuew });
    // },

    async showPlaceInfo(mouseEvent, gis) {
      const lat = mouseEvent.latLng.getLat();
      const lng = mouseEvent.latLng.getLng();

      this.freePlaceInfo();

      const placeInfoMaker = {};
      if (mouseEvent) {
        placeInfoMaker.marker = this.$refs.dkGis.createMarker(mouseEvent.latLng);
        placeInfoMaker.geoInfo = await this.$refs.dkGis.getAddr(mouseEvent.latLng);
        this.$refs.dkGis.setCenter({
          lat: mouseEvent.latLng.getLat(),
          lng: mouseEvent.latLng.getLng(),
        });
      } else {
        placeInfoMaker.marker = this.$refs.dkGis.createMarker(undefined, gis);
        placeInfoMaker.geoInfo = await this.$refs.dkGis.getAddr(undefined, gis);
        this.$refs.dkGis.setCenter(gis);
      }
      this.placeInfoMaker = placeInfoMaker;

      this.selectFcltMarkerData = {
        gisLat: lat,
        gisLng: lng,
        setNumberAddr: placeInfoMaker.geoInfo.addr.num_addr,
      };
    },

    freePlaceInfo() {
      if (this.placeInfoMaker && this.placeInfoMaker.marker) {
        this.$refs.dkGis.freeMarker(this.placeInfoMaker.marker);
      }
      this.placeInfoMaker = {};
    },

    mapClickEvent() {
      if (this.selectFcltMarkerData.fcltId) {
        this.clearFclt();
      } else {
        this.isUIView = !this.isUIView;
      }
      if (this.selectBoxData.length > 0) {
        this.selectBoxData = [];
      }
    },

    setBasicMapClickEvent() {
      this.$refs.dkGis.setClickEvent(this.mapClickEvent);
    },

    freeBasicMapClickEvent() {
      this.$refs.dkGis.freeClickEvent(this.mapClickEvent);
    },

    onFuncItem(key) {
      const me = this;

      const funcItem = me.funcItemList.find((funcItem) => funcItem.key === key);
      funcItem.isSelect = true;

      if (me.nowClickMapEventKey) {
        const fi = me.funcItemList.find((elm) => elm.key === me.nowClickMapEventKey);
        me.freeClickMapEventKeyList.push(me[fi.endFunc]);
      } else {
        me.freeClickMapEventKeyList.push(me.freeBasicMapClickEvent);
      }
      me.nowClickMapEventKey = key;

      me.freeClickMapEventKeyList.forEach((elm) => {
        elm();
      });
      me.freeClickMapEventKeyList = [];
    },

    offFuncItem(key) {
      const funcItem = this.funcItemList.find((funcItem) => funcItem.key === key);
      funcItem.isSelect = false;

      if (this.nowClickMapEventKey === key) {
        this.nowClickMapEventKey = '';
        this.setBasicMapClickEvent();
      }
    },
    infoOpen(e) {
      if (this.isArrowReverse === false) {
        this.isArrowReverse = true;
        e.target.parentElement.style = 'top:0 !important';
        e.target.parentElement.style = `top: calc( 100% - ${e.target.parentElement.scrollHeight}px ) !important`;
      } else {
        e.target.parentElement.style = 'top:0 !important';
        e.target.parentElement.style = `top: calc( 100% - 114px ) !important`;
        this.isArrowReverse = false;
      }
    },
    isClickReaction(funcItem) {
      if (funcItem.isSelect === true) {
        let clickClass = 'select-func';
        if (funcItem.key === 'labelToggle') {
          funcItem.iClassName = 'icon-eye-open';
        } else {
        }
        return clickClass;
      } else {
        if (funcItem.key === 'labelToggle') {
          funcItem.iClassName = 'icon-eye-close';
        } else {
        }
        return '';
      }
    },
    backgroundOff() {
      this.isNaviSelect = false;
    },

    onTouchstart(e) {
      this.startY = e.touches[0].clientY;
      this.moveY = 0;
    },
    onTouchmove(e) {
      this.moveY = e.touches[0].clientY - this.startY;
    },

    onTouchEnd(e) {
      if (this.moveY > 30) {
        // 터치 다운무브
        this.$refs.detail.style = `top: calc( 100% - 114px ) !important`;
        this.isArrowReverse = false;
      } else if (this.moveY < -30) {
        // 터치 업무브
        this.$refs.detail.style = `top: calc( 100% - ${this.$refs.detail.scrollHeight}px ) !important`;
        this.isArrowReverse = true;
      }
      this.moveY = 0;
    },

    selectFcltDetail(e) {
      if (this.isArrowReverse === false) {
        this.$refs.detail.style = `top: calc( 100% - ${this.$refs.detail.scrollHeight}px ) !important`;
        this.isArrowReverse = true;
      } else {
        this.$refs.detail.style = `top: calc( 100% - 114px ) !important`;
        this.isArrowReverse = false;
      }
    },

    createCircleLayer() {
      const me = this;

      // me.clearCircleLayer();

      if (me.fclts.length === 0) return;

      const coverageToggleInfo = me.funcItemList.find((f) => f.key === 'coverageToggle');
      if (!coverageToggleInfo.isSelect) {
        return;
      }

      const circleLayer = me.$refs.dkGis.createCircleLayer();
      const circleInfoList = me.fclts.map((f) => {
        const { geometry, properties } = f;
        const { fcltId } = properties;
        const { lng, lat } = geometry;
        return {
          key: fcltId,
          circleInfo: {
            ...me.cirecleInfo,
            // radius,
            lat,
            lng,
          },
          properties,
        };
      });
      circleLayer.addCircleList(circleInfoList);
      this.circleLayer = circleLayer;
    },

    clearCircleLayer() {
      if (this.circleLayer) {
        this.circleLayer.removeAllCircle();
        this.circleLayer = undefined;
        console.log('removeAllCircle');
      }
    },

    coverageOn() {
      this.createCircleLayer();
    },
    coverageOff() {
      this.clearCircleLayer();
    },

    selectMarker(args) {
      this.selectFclt(args.properties);
      this.selectBoxData = [];
    },

    async showMaintenanceOne() {
      const maintnceData = await this.requestMaintnce({ id: this.maintenanceId });
      this.$refs.formMaintanceView.showDialog(maintnceData);
    },

    async requestMaintnce(args = {}) {
      try {
        const search = {
          fcltId: this.fcltId,
          ...args,
          $or: [
            {
              fcltTypeId: this.fcltTypeIdList,
            },
            { fcltId: '' },
          ],
        };

        const response = await this.$http.post(`coreapi/core/api/maintnce`, {
          search,
        });
        if (response.status !== 200) {
          throw new Error('');
        }
        return response.data[0] || {};
      } catch (err) {
        // this.$message.error('유지보수 정보 로딩 실패');
      }
    },
  },

  watch: {
    fclts(n, o) {
      if (o.length > 0) {
        this.markerLayer.removeAllMarker();
      }
      if (n.length > 0) {
        this.markerLayer.addMarkerList(n);
      }
    },
  },
};
</script>

<style scoped>
.navi-modal {
}
.navi-modal span {
  background-color: dodgerblue;
  display: inline-block;
  height: 40px;
  margin-right: 30px;
  width: 40px;
}

.gis-search-result {
  background-color: #fff;
  bottom: 0;
  left: 0;
  margin-top: 50px;
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 100;
}

.gis-search-result h3 {
  background-color: #f0f0f0;
  border-bottom: 1px solid #cacaca;
  color: #333;
  font-size: 16px;
  margin: 0;
  padding: 10px 0;
  text-indent: 10px;
}

.gis-search-result ul {
  margin: 0;
  padding: 0;
}

.gis-search-result li {
  border-bottom: 1px solid #e0e0e0;
  color: #6f6f6f;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  list-style: none;
  padding: 12px 0;
}
.gis-search-result .search-list-text {
  flex: 1;
  margin-left: 10px;
}
.gis-search-result .search-list-button {
  align-items: center;
  color: rgba(25, 118, 210, 1);
  display: flex;
  font-size: 28px;
  justify-content: center;
  width: 60px;
}
.gis-search-result .button-move {
  font-size: 24px;
}

.gis-search-result li .title {
  color: #444;
  font-size: 14px;
  margin-right: 10px;
}

.gis-search-result div.no-result {
  border-bottom: 1px solid #e0e0e0;
  color: #555;
  font-size: 13px;
  padding: 15px 0;
  text-align: center;
  width: 100%;
}

.gis-search-result .search-list-button {
}
.icon-facility::before {
  /* color: #3e4c65; */
}
.icon-marker::before {
  color: #939393;
}
</style>
