import axios from 'axios'

var userAxios = {
  requestServer(api, payload) {
    let param = {data: payload}
    return new Promise((resolve, reject) => {
      axios.post(api, param).then(response => {
        resolve(response)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export default userAxios
