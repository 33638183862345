import { render, staticRenderFns } from "./formAiCamera.vue?vue&type=template&id=15f49b6f&scoped=true&"
import script from "./formAiCamera.vue?vue&type=script&lang=js&"
export * from "./formAiCamera.vue?vue&type=script&lang=js&"
import style0 from "./formAiCamera.vue?vue&type=style&index=0&id=15f49b6f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15f49b6f",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/dk-platform-frontend/dk-platform-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15f49b6f')) {
      api.createRecord('15f49b6f', component.options)
    } else {
      api.reload('15f49b6f', component.options)
    }
    module.hot.accept("./formAiCamera.vue?vue&type=template&id=15f49b6f&scoped=true&", function () {
      api.rerender('15f49b6f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ai/forms/formAiCamera.vue"
export default component.exports