var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.formTitle,
        visible: _vm.formDialog,
        "close-on-click-modal": false,
        top: "5vh"
      },
      on: {
        "update:visible": function($event) {
          _vm.formDialog = $event
        },
        close: _vm.hideDialog
      }
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "form0",
              attrs: {
                model: _vm.formData,
                "label-width": "80px",
                size: "medium",
                rules: _vm.rules,
                "label-position": _vm.$isMobile ? "top" : "top"
              }
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "flex-direction": "column" },
                  attrs: { type: "flex", align: "middle" }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "제목", prop: "title" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "공지사항 제목을 입력하세요"
                            },
                            model: {
                              value: _vm.formData.title,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "title", $$v)
                              },
                              expression: "formData.title"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "내용", prop: "text" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 10 },
                              placeholder: "공지사항 내용"
                            },
                            model: {
                              value: _vm.formData.text,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "text", $$v)
                              },
                              expression: "formData.text"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "팝업 여부" } },
                        [
                          _c("el-switch", {
                            staticStyle: { "margin-right": "10px" },
                            on: { change: _vm.changeIsPopup },
                            model: {
                              value: _vm.formData.isPopup,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "isPopup", $$v)
                              },
                              expression: "formData.isPopup"
                            }
                          }),
                          _c("el-date-picker", {
                            ref: "popupEndDate",
                            attrs: {
                              type: "date",
                              placeholder: "팝업 종료 일자",
                              disabled: !_vm.formData.isPopup,
                              "picker-options": _vm.pickerOptions1
                            },
                            model: {
                              value: _vm.formData.endDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "endDate", $$v)
                              },
                              expression: "formData.endDate"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 22 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "첨부파일" } },
                        [
                          _c(
                            "dk-el-file-upload",
                            {
                              ref: "uploadImage",
                              attrs: {
                                path: "notice",
                                fileList: _vm.formData.images
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "text-align": "left" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "primary" }
                                    },
                                    [_vm._v("첨부파일 업로드")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-bottom" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { float: "right" },
                  on: { click: _vm.hideDialog }
                },
                [_vm._v("취소")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { float: "right", "margin-right": "5px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.save }
                },
                [_vm._v("저장")]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }