var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        id: "formAssetDialog",
        title: _vm.formTitle,
        visible: _vm.formDialog,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.formDialog = $event
        },
        close: _vm.hideDialog
      }
    },
    [
      _c("dk-form", {
        ref: "dkTemplateForm",
        attrs: { formType: "input" },
        on: { renderAfter: _vm.createTemplateForm }
      }),
      _c(
        "div",
        { staticClass: "dk-form" },
        [
          _c(
            "el-form",
            {
              attrs: {
                "label-position": "left",
                "label-width": "165px",
                size: "small"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "관련 자료" } },
                [
                  _c(
                    "dk-el-file-upload",
                    {
                      ref: "uploadFile",
                      attrs: {
                        path: "asset",
                        fileList: _vm.formData.files,
                        multiple: true
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { "text-align": "left" } },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("관련자료 업로드")]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip"
                            },
                            [
                              _vm._v(
                                "자산 정보에 관련된 자료를 업로드 할 수 있습니다."
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("저장")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.formDialog = false
                }
              }
            },
            [_vm._v("닫기")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }