<template>
  <el-dialog title="시설물 주소 갱신" :visible.sync="formDialog" :before-close="hideDialog" :close-on-click-modal="false">
    <div>
      <h4>현재 등록된 시설물의 GIS 좌표를 이용하여 시설물의 주소를 갱신합니다.</h4>
      <el-row>
        <el-col :span="20">
          <p class="address-notice">
            이 기능은 VWorld geocoding을 이용하여 주소를 얻어옵니다. 실행 시 단시간에 여러 쿼리로 인한 api key block의 위험이 있으므로, 시설물 한
            지점당 약 100ms의 지연시간을 두고 실행합니다.
            <br />현재 시스템에 등록된 시설물의 수량이 총 <span>{{ this.gridData.length }}개소</span> 이므로, 작업시간은 약
            <span>{{ ((this.gridData.length * 100) / 1000).toFixed(0) }}초</span>가 예상됩니다. <br />또한 하루에 총 3만건 이상 조회 시 API키의 쿼리
            조회가 초과하여 더 이상 주소정보를 갱신할 수 없으므로, 작업에 유념하시길 바랍니다.
          </p>
        </el-col>
        <el-col :span="4" class="button">
          <el-button type="primary" @click="addressGenerate" class="generate-button" :disabled="!isReady">주소갱신</el-button>
        </el-col>
      </el-row>
      <div class="table-wrap">
        <table class="dk-table dk-table-small excel-preview-table" v-if="gridData.length > 0">
          <tr>
            <th>시설물유형</th>
            <th>시설물</th>
            <th>지번주소</th>
            <th>도로명주소</th>
            <th>경도</th>
            <th>위도</th>
          </tr>
          <tr v-for="(row, index) in gridData" :key="index">
            <td>{{ row.fcltTypeName }}</td>
            <td>{{ row.fcltName }}</td>
            <td>{{ row.setNumberAddr }}</td>
            <td>{{ row.setRoadAddr }}</td>
            <td>{{ row.gisLng }}</td>
            <td>{{ row.gisLat }}</td>
          </tr>
        </table>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import _ from 'lodash';
export default {
  data() {
    return {
      formDialog: false,
      gridData: [],
      loading: null,
      isReady: false,
    };
  },
  methods: {
    async getFcltData() {
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fclt`, {});
      this.gridData = response.data.docs;
      this.isReady = true;
      // return this.$store.dispatch('fclt/getFclt', {}).then(() => {
      //   this.gridData = this.$store.getters['fclt/getFclt'];
      // });
    },
    hideDialog() {
      this.formDialog = false;
      this.isReady = false;
    },
    showDialog() {
      this.formDialog = true;
      this.$nextTick(() => {
        this.getFcltData();
      });
    },
    addressGenerate() {
      this.loading = this.$loading({
        lock: true,
        text: `주소 갱신 중입니다. 총 작업시간은 약 ${((this.gridData.length * 100) / 1000).toFixed(0)}초가 예상됩니다.`,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      console.log(this.gridData.length * 100);
      this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/fclt/addressGenerate`);
      setTimeout(() => {
        this.loading.close();
        this.hideDialog();
        this.$emit('procDataComplete');
      }, this.gridData.length * 100);
    },
  },
};
</script>

<style scoped>
.address-notice {
  padding: 0 10px;
}

.address-notice span {
  font-weight: bold;
  color: #66b1ff;
}

.table-wrap {
  overflow-x: auto;
}

.generate-button {
  margin-top: 18px;
}
</style>
