import _ from 'lodash';
import dkTemplateUtil from './dkTemplateUtil';

let classObj;
class DkTemplateData {
  constructor(args) {
    //this._data = args;
    this._data = dkTemplateUtil.setTemplateValue(args);
  }
  /**
   *
   * 로딩된 전체 template 데이터를 불러온다
   *
   * @returns {object} templateData
   */
  getData() {
    return this._data;
  }
  /**
   * 라벨값을 입력하면 해당되는 값을 토해준다.
   *
   * @param {string} label
   * @return {*} value
   */
  getValue(label) {
    const result = _.find(this._data, (val) => {
      return val.label === label;
    });
    if (result) return result.value;
    else null;
  }
  /**
   * 라벨값을 입력하면 해당되는 출력용 값을 토해준다.
   *
   * @param {string} label
   * @return {*} value
   */
  getPrintValue(label) {
    const result = _.find(this._data, (val) => {
      return val.label === label;
    });
    if (result) {
      if (result.type === 'select') {
        const selectResult = _.find(result.options, (opt) => {
          return opt.value === result.value;
        });
        return selectResult.label;
      } else if (result.type === 'multiselect') {
        const selectResult = _.map(result.value, (r) => {
          return _.find(result.options, (opt) => {
            return opt.value === r;
          }).label;
        });
        return _.join(selectResult, ', ').toString();
      } else {
        return result.value;
      }
    } else null;
  }
}

/**
   * 템플릿데이터 객체를 초기화하고 리턴한다.
   *
   * @param {*} args
   * @returns
//    */

export function dkTemplateData(...args) {
  classObj = new DkTemplateData(...args);
  return classObj;
}
