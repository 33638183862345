<template>
  <el-dialog :title="formTitle" :visible.sync="formDialog" @close="hideDialog" :close-on-click-modal="false">
    <el-form :model="formData" label-width="160px" size="medium" :rules="rules" ref="form0" :label-position="$isMobile ? 'top' : 'right'">
      <el-row>
        <el-col :span="18">
          <el-form-item label="LED 라이브러리 이름" prop="title">
            <el-input v-model="formData.ledLibraryName" placeholder="LED 라이브러리 이름을 입력하세요"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="18">
          <el-form-item label="라이브러리 이미지">
            <dk-el-file-upload
              ref="uploadImage"
              uploaderType="image"
              listType="picture"
              path="ledLibrary"
              :fileList="formData.images"
              :checkUploadFile="checkUploadFile"
              :limit="1"
            >
              <div style="text-align: left;">
                <el-button size="small" type="primary">라이브러리 이미지 업로드</el-button>
                <div slot="tip" class="el-upload__tip">
                  이미지 파일만 업로드할 수 있습니다
                </div>
              </div>
            </dk-el-file-upload>
          </el-form-item>
        </el-col>

        <el-col :span="18">
          <el-form-item label="이미지 유형" prop="title">
            {{ formData.imageType }}
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">저장</el-button>
      <el-button @click="hideDialog">취소</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dkElFileUpload from '@/components/dkElFileUpload.vue';

export default {
  components: {
    'dk-el-file-upload': dkElFileUpload,
  },
  data() {
    return {
      formDialog: false,
      formTitle: '',
      formData: {
        ledLibraryId: null,
        ledLibraryName: '',
        ledCategory: '',
        images: [],
        imageType: '',
        width: 0,
        height: 0,
      },
      rules: {
        ledLibraryName: [
          {
            required: true,
            message: 'LED 라이브러리 이름을 입력해 주세요',
            trigger: 'blur',
          },
        ],
        ledCategory: [
          {
            required: true,
            message: 'LED 유형을 선택해 주세요',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    save() {
      let me = this;

      this.$refs.form0.validate((valid) => {
        if (valid) {
          let saveFunc = `${this.$config.getServerConfig().core.api}/led/api/ledLibrary/add`;
          if (this.updateFlag) {
            saveFunc = `${this.$config.getServerConfig().core.api}/led/api/ledLibrary/update`;
          }
          this.formData.images = this.$refs.uploadImage.getFileData();

          this.$http
            .post(saveFunc, { data: this.formData })

            .then((response) => {
              me.$parent.$emit('procDataComplete', this.formData);
              me.resetData();
              me.hideDialog();
              me.$message({
                type: 'success',
                message: `LED 라이브러리 등록 완료`,
              });
            })
            .catch((err) => {
              me.resetData();
              me.hideDialog();
              me.$message.error(`LED 라이브러리 등록 중 에러 발생`);
            });
        } else {
          return false;
        }
      });
    },
    showDialog(row) {
      const me = this;
      if (row) {
        this.updateFlag = true;
        this.formTitle = 'LED 라이브러리 수정';
        this.formData = this.$lodash.cloneDeep(row);
      } else {
        this.updateFlag = false;
        this.formTitle = 'LED 라이브러리 추가';
        this.resetData();
      }
      this.formDialog = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        if (row) {
          this.$refs.uploadImage.setFileData(row.images);
          if (row.images.length > 0) {
            const img = new Image();
            img.src = row.images[0].serverFilePath;
            img.onload = function () {
              me.formData.width = this.width;
              me.formData.height = this.height;
              console.log(me.formData);
            };
          }
        }
      });
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    resetData() {
      this.formData = {
        ledLibraryId: null,
        ledLibraryName: '',
        ledCategory: '',
        images: [],
        imageType: '',
        width: 0,
        height: 0,
      };
    },
    checkUploadFile(file) {
      this.formData.imageType = file.type;
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
.editor-canvas {
  width: 100%;
  height: auto;
}
</style>
