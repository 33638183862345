var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "template-form-dialog",
      attrs: {
        id: "formMaintnceTemplate",
        title: _vm.formTitle,
        visible: _vm.formDialog,
        "close-on-click-modal": false,
        width: "1200px",
        top: "8vh"
      },
      on: {
        "update:visible": function($event) {
          _vm.formDialog = $event
        },
        close: _vm.hideDialog,
        opened: _vm.createTemplateForm
      }
    },
    [
      _c("div", { staticClass: "wrap" }, [
        _c(
          "div",
          { staticClass: "template-list" },
          [
            _c("dk-scroll-view", {
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c("div", { staticClass: "template-header" }, [
                        _c("h4", [_vm._v("템플릿 목록")])
                      ])
                    ]
                  },
                  proxy: true
                },
                {
                  key: "body",
                  fn: function() {
                    return [
                      _vm._l(_vm.templateList, function(item, idx) {
                        return _c(
                          "div",
                          { key: idx, staticClass: "template-list-cell" },
                          [
                            _c("span", { staticClass: "template-name" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.templateName) +
                                  "\n            "
                              )
                            ]),
                            _c(
                              "span",
                              { staticClass: "template-button" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.updateTemplate(item)
                                      }
                                    }
                                  },
                                  [_vm._v("수정")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteTemplate(item)
                                      }
                                    }
                                  },
                                  [_vm._v("삭제")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      _c(
                        "div",
                        { staticClass: "template-button-wrap" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.addTemplate }
                            },
                            [_vm._v("템플릿 추가")]
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-wrap" },
          [
            _vm.templateData
              ? _c(
                  "div",
                  {
                    staticClass: "dk-form",
                    staticStyle: { "padding-top": "30px" }
                  },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form0",
                        attrs: {
                          "label-position": "left",
                          model: _vm.formData,
                          "label-width": "170px",
                          size: "small",
                          rules: _vm.rules
                        }
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "템플릿 명칭",
                              prop: "templateName"
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                size: "mini",
                                placeholder: "유지관리 작업 템플릿 명칭"
                              },
                              model: {
                                value: _vm.formData.templateName,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "templateName", $$v)
                                },
                                expression: "formData.templateName"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c("dk-form", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.templateData,
                  expression: "templateData"
                }
              ],
              ref: "dkTemplateForm",
              attrs: { formType: "template" },
              on: { renderAfter: _vm.createTemplateForm }
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.templateData,
                    expression: "!templateData"
                  }
                ],
                staticClass: "no-template-data"
              },
              [
                _vm._v(
                  "\n        유지관리 템플릿을 추가하거나 등록된 템플릿을 선택해주세요\n      "
                )
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveTemplateData } },
            [_vm._v("저장")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.formDialog = false
                }
              }
            },
            [_vm._v("닫기")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }