///////////////////////////////////////////////////////////////////
/*
/* OpenStreet(OpenLayers) GIS 메인 모듈
/*
/* GIS 레이어 선택 방식 변경 (다만 Geoserver에 할짓이 많아서 업데이트는 안함)
/*
/////////////////////////////////////////////////////////////////*/
import marker from './marker';
import dkMap from '../dkGisMap';
import * as ol from 'ol';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { LineString, MultiLineString } from 'ol/geom';
import { defaults as defaultControls, Control } from 'ol/control';
import XYZ from 'ol/source/XYZ';
import TileWMS from 'ol/source/TileWMS';
import Select from 'ol/interaction/Select';
import { click } from 'ol/events/condition.js';
import { Style, Stroke } from 'ol/style.js';
import _ from 'lodash';

export default {
  data() {
    return {
      fcltLayerSource: null,
      beforeSelectedMarker: null,
      olView: null,
      trafficWmsLayer: null,
      selectWmsLayer: null,
      TrafficSelectMode: 'Single',
      isTrafficLayer: false,
      cqlFilter: [],
      tileSources: [],
      currentTileSource: null,
      selectTileWMS: null,
      isFcltManageOnGis: false,
      isIncidentOnGis: false,
      maxExtent: {
        minX: 126.123047,
        minY: 37.302155,
        maxX: 127.147522,
        maxY: 37.823562,
      },
      selectedIdKey: '',
      selectedParamIdKey: '',
      selectedTrafficLayer: null,
      selectedTrafficSource: null,
      trafficLayers: [
        { level: 8, layer: 'gimpo_ifsc_level_16' },
        { level: 7, layer: 'gimpo_ifsc_level_15' },
        { level: 6, layer: 'gimpo_ifsc_level_14' },
        { level: 5, layer: 'gimpo_ifsc_level_13' },
        { level: 4, layer: 'gimpo_ifsc_level_12' },
        { level: 3, layer: 'gimpo_ifsc_level_12' },
      ],
    };
  },
  mixins: [dkMap],
  components: {
    'dk-marker': marker,
  },
  destroyed() {
    this.tileSources.forEach((source) => {
      source._tileWMS = null;
    });
    this.currentTileSource = null;
    this.mapObject.removeLayer(this.selectWmsLayer);
    this.mapObject.removeLayer(this.trafficWmsLayer);
    this.mapObject.setTarget(null);
    this.selectWmsLayer = null;
    this.trafficWmsLayer = null;
    this.mapObject = null;
    console.log('osm map destroyed');
  },

  created() {
    this.$on('markerDragEnd', (ev) => {
      return this.$parent.$emit('markerDragEnd', {
        geometry: {
          lat: ev.latLng.lat,
          lng: ev.latLng.lng,
        },
        addr: {
          num_addr: '',
          road_addr: '',
        },
      });
    });
  },

  mounted() {
    let me = this;
    // 서 남 동 북
    // let _tileWMS = null

    this.olView = new ol.View({
      projection: 'EPSG:4326',
      extent: [this.maxExtent.minX, this.maxExtent.minY, this.maxExtent.maxX, this.maxExtent.maxY],
      minZoom: 10,
      maxZoom: 17,
      restrictedExtent: [this.maxExtent.minX, this.maxExtent.minY, this.maxExtent.maxX, this.maxExtent.maxY],
      //center: [126.6559988,37.6429267],
      center: [this.gisApiInfo.geometry.lng, this.gisApiInfo.geometry.lat],
      zoom: this.getLocalLevel(this.gisApiInfo.level) || 17,
    });

    this.$nextTick(function () {
      let layers = [
        new TileLayer({
          source: new XYZ({
            // url: `http://192.168.0.102:8088/data/Jjajangsea/{z}/{x}/{y}.png`,
            url: `${this.$config.getServerConfig().traffic.mapServer}/{z}/{x}/{y}.png`,
          }),
        }),
      ];

      if (this.trafficLayerData) {
        this.trafficLayerData.base.forEach((source) => {
          let sourceObj = _.cloneDeep(source);
          sourceObj._tileWMS = new TileWMS({
            url: source.url,
            params: source.params,
            serverType: 'geoserver',
            transition: 0,
          });
          if (!sourceObj.idKey) {
            sourceObj.idKey = 'link_id';
          }
          this.tileSources.push(sourceObj);
        });

        let sourceObj = this.tileSources.find((source) => {
          return source.default == true;
        });
        this.currentTileSource = sourceObj;

        this.selectTileWMS = new TileWMS({
          url: this.trafficLayerData.select.url,
          params: this.trafficLayerData.select.params,
          serverType: 'geoserver',
          transition: 0,
        });

        this.trafficWmsLayer = new TileLayer({
          source: sourceObj._tileWMS,
          visible: false,
        });

        this.selectWmsLayer = new TileLayer({
          source: this.selectTileWMS,
          visible: false,
        });

        this.selectedTrafficSource = new VectorSource();
        this.selectedTrafficLayer = new VectorLayer({
          source: this.selectedTrafficSource,
          zIndex: 10,
          style: new Style({
            stroke: new Stroke({
              color: '#1E88E5',
              width: 5,
            }),
          }),
        });

        this.TrafficSelectMode = this.trafficLayerData.select.mode ? this.trafficLayerData.select.mode : 'Single';
        this.selectedIdKey = this.trafficLayerData.select.idKey || '';
        this.selectedParamIdKey = this.trafficLayerData.select.paramIdKey || '';

        layers.push(this.trafficWmsLayer);
        layers.push(this.selectWmsLayer);
        layers.push(this.selectedTrafficLayer);
      }

      this.mapObject = new ol.Map({
        controls: defaultControls({
          attribution: false,
          rotate: false,
          zoom: false,
        }),
        layers: layers,
        // restrictedExtent: [126.4310, 37.5655, 126.8876, 37.8060],
        target: this.$refs['vueDkgisContainer'],
        view: this.olView,
      });

      var currZoom = this.mapObject.getView().getZoom();
      this.mapObject.on('moveend', (e) => {
        var newZoom = this.mapObject.getView().getZoom();
        if (currZoom != newZoom) {
          currZoom = newZoom;
          // this.clearTrafficLayer();
        }
      });

      // 시설물 레이어
      this.fcltLayerSource = new VectorSource();
      let fcltLayer = new VectorLayer({
        source: this.fcltLayerSource,
        style: function (feature) {
          return feature.get('style');
        },
        name: 'fclt',
      });
      this.mapObject.addLayer(fcltLayer);
      var select = new Select({
        layers: [fcltLayer],
        condition: click,
      });
      this.mapObject.addInteraction(select);
      select.on('select', function (e) {
        e.stopPropagation();
        if (me.beforeSelectedMarker) {
          me.beforeSelectedMarker.setIconImageByStatus();
        }
        if (e.selected.length > 0) {
          let selectedMarker = e.selected[0].get('vueObject');
          selectedMarker.setIconSelected();
          me.beforeSelectedMarker = selectedMarker;
          me.$parent.$emit('clickMarker', selectedMarker);
        } else if (e.deselected.length > 0) {
          e.deselected[0].get('vueObject').setIconImageByStatus();
        }
      });
      select.on('');

      this.mapObject.on('click', async (evt) => {
        if (this.isFcltManageOnGis) {
          this.$parent.$emit('fcltManagerOnGis', {
            geometry: {
              lat: evt.coordinate[1],
              lng: evt.coordinate[0],
            },
            addr: null,
          });
          this.$el.children[0].className = '';
          this.isFcltManageOnGis = false;
        }
        var features = this.mapObject.getFeaturesAtPixel(evt.pixel);
        if (features && features.length > 0) {
          return;
        }
        if (!this.trafficLayerData) {
          return;
        }
        if (this.isTrafficLayer == false) {
          return;
        }

        var viewResolution = this.olView.getResolution(); //@type {number}
        var url = this.trafficWmsLayer.getSource().getFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:4326', {
          INFO_FORMAT: 'application/json',
        });

        let result = await this.$http.get(url);
        if (result && result.data) {
          if (result.data.features && result.data.features[0]) {
            let feature = result.data.features[0];
            console.log(`${this.selectedParamIdKey} : ${feature.properties[this.selectedIdKey]}`);
            let param = this.trafficLayerData.select.params;
            if (this.selectedParamIdKey !== '') {
              param.viewparams = `${this.selectedParamIdKey}:${feature.properties[this.selectedIdKey]}`;
            }
            this.selectTileWMS.updateParams(param);
            // let polyline;
            // let feature = result.data.features[0];
            // if (feature.geometry && feature.geometry.type) {
            //   if (feature.geometry.type.toLowerCase() === 'multilinestring') {
            //     polyline = new MultiLineString(feature.geometry.coordinates);
            //   } else if (feature.geometry.type.toLowerCase() === 'linestring') {
            //     polyline = new LineString(feature.geometry.coordinates);
            //   }
            //   let line = new ol.Feature(polyline);
            //   if (this.TrafficSelectMode == 'Single') {
            //     this.selectedTrafficSource.clear();
            //     this.selectedTrafficSource.addFeature(line);
            //   } else {
            //     this.selectedTrafficSource.addFeature(line);
            //   }
            // }
            return this.selectTrafficLayer(result.data.features[0].properties);
          } else {
            return null;
          }
        } else {
          return null;
        }

        /*
          if (url) {
            this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/gis/getFeature`, {data: url})
            .then(result => {
              if (result.data && result.data.features && result.data.features.length > 0) {                                
                const param = this.setCqlFilter(result.data.features[0].properties[this.currentTileSource.idKey]);
                this.selectTileWMS.updateParams({
                  'LAYERS': this.currentTileSource.params.LAYERS,
                  'TILED': true,
                  'STYLES': 'gimpo_utis_select_style_2',
                  'cql_filter': param
                });
                console.log(this.selectTileWMS)
                console.log(param)

                if (this.selectTrafficLayer && result.data.features.length > 0) {
                  this.selectTrafficLayer(result.data.features[0].properties)
                }
                if (param !== "") {                                    
                  this.selectWmsLayer.setVisible(true);
                } else {                  
                  this.selectWmsLayer.setVisible(false);
                }
                return result.data.features[0]
              }
              else {
                return null
              }
            })
            .then((feature)=> {              
              // 사고/돌발정보 이벤트 전파를 하는데, 선택한 선형의 feature정보와 같이 전파한다.
              if (this.isIncidentOnGis) {
                this.$parent.$emit('incidentOnGis', {
                  geometry: {
                    lat: evt.coordinate[1],
                    lng: evt.coordinate[0]
                  },
                  feature: feature.properties
                })
                this.$el.children[0].className = ""
                this.isIncidentOnGis = false;
              }
            })
            .catch(err => { console.log(err)})
          }
          else {
            if (this.isIncidentOnGis) {
              this.$parent.$emit('incidentOnGis', {
                geometry: {
                  lat: evt.coordinate[1],
                  lng: evt.coordinate[0]
                },
                feature: null
              })
              this.$el.children[0].className = ""
              this.isIncidentOnGis = false;
            }
          }
          */
      });
      this.$parent.$emit('apiLoadComplete', 'OSM 로딩 컴플릿');
      return {};
    });
  },

  methods: {
    getLevel() {
      var dkLevel = this.olView.getZoom() * -1 + 20;
      return dkLevel;
    },

    getLocalLevel(level) {
      return 20 - level;
    },

    setLevel(level) {
      var dkLevel = 20 - level;
      this.olView.setZoom(dkLevel);
    },

    getCenter() {
      let center = this.olView.getCenter();
      return {
        lng: center[0],
        lat: center[1],
      };
    },

    setCenter(coord) {
      this.olView.setCenter([coord.lng, coord.lat]);
    },

    showTrafficLayer(flag) {
      return false;
    },

    clickMarker(ev) {
      this.$parent.$emit('clickMarker', ev);
    },

    setTrafficLayer(flag) {
      if (!this.trafficLayerData) {
        this.isTrafficLayer = false;
        return false;
      }
      if (flag) {
        this.trafficWmsLayer.setVisible(true);
        this.selectWmsLayer.setVisible(true);
        this.isTrafficLayer = true;
        return true;
      } else {
        this.trafficWmsLayer.setVisible(false);
        this.selectWmsLayer.setVisible(false);
        this.isTrafficLayer = false;
        return false;
      }
    },

    setTrafficSelectMode(flag) {
      flag === 'Single' ? (this.TrafficSelectMode = 'Single') : (this.TrafficSelectMode = 'Multi');
      return this.TrafficSelectMode;
    },

    setTrafficLayerSource(name) {
      let sourceObj = this.tileSources.find((source) => {
        return source.name == name;
      });
      this.trafficWmsLayer.setSource(sourceObj._tileWMS);
      this.selectWmsLayer.setVisible(false);
      this.cqlFilter = [];
      this.currentTileSource = sourceObj;
      return name;
    },

    setCqlFilter(payload) {
      // if (this.TrafficSelectMode === "Single") {
      //   if (this.cqlFilter.length > 1) {
      //     this.cqlFilter = [payload];
      //     return this.cqlFilter.map(condition => {
      //       return (condition) ? `link_id=${condition}` : "";
      //     }).toString();
      //   } else {
      //     this.cqlFilter.includes(payload) ? this.cqlFilter = [] : this.cqlFilter = [payload];
      //     return this.cqlFilter.map(condition => {
      //       return (condition) ? `link_id=${condition}` : "";
      //     }).toString();
      //   }
      // } else if (this.TrafficSelectMode === "Multi") {
      //   this.cqlFilter.includes(payload) ? this.cqlFilter = _.without(this.cqlFilter, payload) : this.cqlFilter.push(payload);
      //   if (this.cqlFilter.length > 1) {
      //     return this.cqlFilter.reduce((acc, cur) => {
      //       return this.cqlFilter.indexOf(acc) === 0 ? `link_id=${acc} OR link_id=${cur}` : `${acc} OR link_id=${cur}`;
      //     })
      //   } else {
      //     return this.cqlFilter.map(condition => {
      //       return (condition) ? `link_id=${condition}` : "";
      //     }).toString();
      //   }
      // } else {
      //   return "";
      // }
      return this.dispatchCqlFilter(payload).fetchCqlFilter();
    },

    dispatchCqlFilter(payload) {
      if (this.TrafficSelectMode === 'Single') {
        if (this.cqlFilter.length > 1) {
          this.cqlFilter = [payload];
          return this;
        } else {
          this.cqlFilter.includes(payload) ? (this.cqlFilter = []) : (this.cqlFilter = [payload]);
          return this;
        }
      } else if (this.TrafficSelectMode === 'Multi') {
        this.cqlFilter.includes(payload) ? (this.cqlFilter = _.without(this.cqlFilter, payload)) : this.cqlFilter.push(payload);
        return this;
      }
    },

    fetchCqlFilter() {
      if (this.cqlFilter.length > 1) {
        return this.cqlFilter.reduce((acc, cur) => {
          return this.cqlFilter.indexOf(acc) === 0
            ? `${this.currentTileSource.idKey}=${acc} OR ${this.currentTileSource.idKey}=${cur}`
            : `${acc} OR ${this.currentTileSource.idKey}=${cur}`;
        });
      } else {
        return this.cqlFilter
          .map((condition) => {
            return condition ? `${this.currentTileSource.idKey}=${condition}` : '';
          })
          .toString();
      }
    },

    clearTrafficLayer() {
      this.selectedTrafficSource.clear();
    },

    refresh() {
      this.mapObject.updateSize();
    },

    hideRoadview() {},

    getMapObject() {
      return this.mapObject;
    },

    getFcltLayerSource() {
      return this.fcltLayerSource;
    },

    setFcltManageOnGis(flag) {
      if (!flag) {
        this.isIncidentOnGis = false;
        this.isFcltManageOnGis = true;
        this.$el.children[0].className = 'fclt-manage-on-gis';
      } else {
        this.isFcltManageOnGis = false;
        this.$el.children[0].className = '';
      }
    },

    setIncidentOnGis(flag) {
      if (!flag) {
        this.isFcltManageOnGis = false;
        this.isIncidentOnGis = true;
        this.$el.children[0].className = 'fclt-manage-on-gis';
      } else {
        this.isIncidentOnGis = false;
        this.$el.children[0].className = '';
      }
    },

    getAddrFromCenterCoord(centerMarker) {
      return new Promise((resolve, reject) => {
        let result = {
          geometry: this.getCenter(),
          addr: null,
        };
        return resolve(result);
      });
    },

    getCoordFromAddr(opts) {
      return new Promise((resolve, reject) => {
        return resolve([]);
      });
    },
    /* focusTrafficLayer(key, param){
      console.log(param)
      
      var filter = ``;
      var makeCqlFilter = ()=>{
        for (var i=0; i<param.length; i++) {
          // this.cqlFilter.push(param[i].toString())
          filter += `${key}=${param[i][key]}`
          if (i+1 < param.length) {
            filter += " OR "
          }
        }        
      }
      makeCqlFilter()      
      let url = 
      `${this.$config.getServerConfig().traffic.gisServer}/geoserver/wfs?service=WFS&version=2.0.0&request=GetFeature&typename=cite:gimpo_ifsc_offset&outputFormat=application/json&CQL_FILTER=${filter}`;

    },*/

    async focusTrafficLayer(key, param, layer) {
      this.selectedTrafficSource.clear();
      let filter = `${key}=${param}`;

      if (filter !== '') {
        this.selectWmsLayer.setVisible(true);

        let url = `${
          this.$config.getServerConfig().traffic.gisServer
        }/geoserver/wfs?service=WFS&version=2.0.0&request=GetFeature&typename=cite:${layer}&outputFormat=application/json&CQL_FILTER=${filter}`;
        let result = await this.$http.get(url);
        // if(!!result.data.features[0].geometry.coordinates[0] && result.data.features[0].geometry.type === "MultiLineString"){
        //   this.olView.setCenter(result.data.features[0].geometry.coordinates[0][0])
        // }
        // else if (!!result.data.features[0].geometry.coordinates[0] && result.data.features[0].geometry.type === "LineString") {
        //   this.olView.setCenter(result.data.features[0].geometry.coordinates[0])
        // }

        if (result && result.data) {
          if (result.data.features && result.data.features[0]) {
            let feature = result.data.features[0];
            if (feature.geometry && feature.geometry.type) {
              if (feature.geometry.type.toLowerCase() === 'multilinestring') {
                this.olView.setCenter(feature.geometry.coordinates[0][0]);
              } else if (feature.geometry.type.toLowerCase() === 'linestring') {
                this.olView.setCenter(feature.geometry.coordinates[0]);
              }
              result.data.features.forEach((f) => {
                let polyline;
                if (f.geometry.type.toLowerCase() === 'multilinestring') {
                  polyline = new MultiLineString(f.geometry.coordinates);
                } else if (f.geometry.type.toLowerCase() === 'linestring') {
                  polyline = new LineString(f.geometry.coordinates);
                }
                let line = new ol.Feature(polyline);
                this.selectedTrafficSource.addFeature(line);
              });
            }
            return this.selectTrafficLayer(result.data.features[0].properties);
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
    },

    trafficLayerRefresh() {
      this.trafficWmsLayer.getSource().updateParams({ TIME: Date.now() });
    },
    getFeaturesAtCoord(coord) {
      let pixel = this.mapObject.getPixelFromCoordinate([coord.lng, coord.lat]);
      let feature = this.mapObject.getFeaturesAtPixel(pixel);
      var viewResolution = this.olView.getResolution(); //@type {number}
      var url = this.trafficWmsLayer
        .getSource()
        .getFeatureInfoUrl([coord.lng, coord.lat], viewResolution, 'EPSG:4326', { INFO_FORMAT: 'application/json' });

      // return this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/gis/getFeature`, {data: url})
      // .then(result => {
      //   if (result.data && result.data.features && result.data.features.length > 0) {
      //     const param = this.setCqlFilter(result.data.features[0].properties[this.currentTileSource.idKey]);
      //     this.selectTileWMS.updateParams({
      //       'LAYERS': this.currentTileSource.params.LAYERS,
      //       'TILED': true,
      //       'STYLES': 'gimpo_utis_select_style',
      //       'cql_filter': param
      //     });
      //     if (this.selectTrafficLayer && result.data.features.length > 0) {
      //       this.selectTrafficLayer(result.data.features[0].properties)
      //     }
      //     if (param !== "") {
      //       this.selectWmsLayer.setVisible(true);
      //     } else {
      //       this.selectWmsLayer.setVisible(false);
      //     }
      //     return result.data.features[0]
      //   }
      //   else {
      //     return null
      //   }

      // })
    },
    getBounds() {
      return this.maxExtent;
    },
  },
};
