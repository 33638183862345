<template>
  <div class="info-panel-wrap">
    <el-form label-width="100px" size="small" class="fclt-status-search-form">
      <el-row>
        <el-col :span="12">
          <el-form-item label="시설물 유형" prop="fcltType">
            <el-select v-model="tempFcltTypeId" placeholder="시설물 유형" :multiple="true">
              <el-option v-for="item in fcltType" :key="item.fcltTypeId" :label="item.fcltTypeName" :value="item.fcltTypeId"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="text-align: left;">
          <el-button type="primary" size="small" @click="getGridViewList"> <i class="fas fa-search"></i>&nbsp;검색 </el-button>
        </el-col>
      </el-row>
    </el-form>

    <el-table :data="gridViewList" style="width: 100%;" stripe :row-class-name="(()=>'warning-row')" @row-click="selectFcltDataGrid">
      <el-table-column prop="fcltTypeName" label="종류" width="100px"></el-table-column>
      <el-table-column prop="fcltId" label="시설물ID" width="100px"></el-table-column>
      <el-table-column prop="fcltName" label="시설물명"></el-table-column>
      <el-table-column label="통신" width="70px">
        <template slot-scope="scope">
          <!-- <template v-slot:default="scope"> -->
          <el-tag :type="scope.row.statusFlag === true ? 'success' : 'danger'">{{ scope.row.statusFlag === true ? '연결' : '끊김' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column v-for="(item, index) in rowHeardList" :key="index" :label="item.label" width="70px">
        <template slot-scope="scope">
          <el-tag v-if="typeof scope.row.status[item.dataKey] === 'boolean'" :type="makeRowType(item, scope.row.statusFlag, scope.row.status)">{{
            makeRowText(item, scope.row.statusFlag, scope.row.status)
          }}</el-tag>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'fclt-status-grid',
  components: {},
  props: {
    searchFcltListFlag: {
      default: true,
    },
  },
  data: () => ({
    fcltType: [],
    tempFcltTypeId: '',
    searchFcltTypeId: '',
    fcltList: [],
    fcltStatusObj: {},
    gridViewList: [],
    rowHeardList: [
      {
        label: '전원',
        dataKey: 'powerStatus',
        body: [
          {
            data: true,
            color: 'success',
            text: 'ON',
          },
          {
            data: false,
            color: 'warning',
            text: 'OFF',
          },
        ],
      },
      {
        label: '문열림',
        dataKey: 'doorStatus',
        body: [
          {
            data: true,
            color: 'success',
            text: 'ON',
          },
          {
            data: false,
            color: 'warning',
            text: 'OFF',
          },
        ],
      },
      {
        label: '팬가동',
        dataKey: 'fanStatus',
        body: [
          {
            data: true,
            color: 'success',
            text: 'ON',
          },
          {
            data: false,
            color: 'warning',
            text: 'OFF',
          },
        ],
      },
    ],
    searchIntervalKey: null,
  }),
  created() {},
  destroyed() {
    this.gridViewList = [];
    clearInterval(this.searchIntervalKey);
  },
  mounted() {
    1;
    const _this = this;

    _this.getFcltType();
    _this.getGridViewList();

    _this.searchIntervalKey = setInterval(() => {
      _this.searchFcltStatus();
    }, 30 * 1000);
  },
  computed: {},
  watch: {
    fcltList(newVal, oldVal) {
      this.searchFcltStatus();
    },
  },
  methods: {
    async getFcltType(args) {
      let params = args || {};
      await this.$store.dispatch('fcltType/getFcltType', {});
      const fcltTypeList = this.$store.getters['fcltType/getFcltType'];
      this.fcltType = fcltTypeList;
    },
    async searchFcltList(args) {
      const _this = this;

      let fcltParams = args || {};

      fcltParams.fcltTypeId.push('FCLTTYPE10001');
      fcltParams.fcltTypeId.push('FCLTTYPE10002');

      if (_this.searchFcltListFlag === true) {
        const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fclt`, {
          params: fcltParams,
        });
        const fcltList = response.data.docs;
        // await _this.$store.dispatch("fclt/getFclt", fcltParams);
        // const fcltList = _this.$store.getters['fclt/getFclt'];
        _this.fcltList = fcltList;
      }
      _this.$emit('searchFcltList', fcltParams);
    },
    async searchFcltStatus() {
      const _this = this;

      let fcltTypeparams = {};
      if (_this.searchFcltTypeId) {
        fcltTypeparams.fcltTypeId = _this.searchFcltTypeId;
      }
      await _this.$store.dispatch('fcltStatus/getFcltStatus', fcltTypeparams);
      const fcltStatusList = _this.$store.getters['fcltStatus/getFcltStatus'];

      const fcltStatusObj = _this.$lodash.keyBy(fcltStatusList, (fcltStatus) => fcltStatus.fcltId);

      _this.gridViewList = _this.fcltList.map((fclt) => {
        let fcltStatus = fcltStatusObj[fclt.fcltId];
        let gridView = { ...fclt };
        if (fcltStatus) {
          if (fcltStatus.statusFlag == true) {
            gridView.statusFlag = fcltStatus.statusFlag;
            gridView.status = fcltStatus.status || {};
          } else {
            gridView.statusFlag = false;
            gridView.status = {};
          }
        } else {
          gridView.statusFlag = false;
          gridView.status = {};
        }
        return gridView;
      });
    },
    async getGridViewList() {
      const _this = this;

      _this.searchFcltTypeId = _this.tempFcltTypeId;
      let params = _this.searchFcltTypeId !== '' ? { fcltTypeId: _this.searchFcltTypeId } : {};
      _this.searchFcltList(params);
    },
    viewFcltType(value, row, column) {
      // debugger
    },
    makeRowType(rowHeard, statusFlag, status) {
      for (let i = 0; i < rowHeard.body.length; i++) {
        if (status[rowHeard.dataKey] === rowHeard.body[i].data) {
          return rowHeard.body[i].color;
        }
      }
    },
    makeRowText(rowHeard, statusFlag, status) {
      const statusKey = status[rowHeard.dataKey];
      for (let i = 0; i < rowHeard.body.length; i++) {
        if (status[rowHeard.dataKey] === rowHeard.body[i].data) {
          return rowHeard.body[i].text;
        }
      }
    },
    selectFcltDataGrid(row, event, column) {
      this.selectFclt(row);
      this.$emit('selectFcltDataGrid', row);
    },
    selectFclt(args) {},
  },
};
</script>

<style scoped>
.fclt-status-search-form {
  margin-top: -10px;
  /* padding-top: -10px; */
}

.fclt-status-search-form .el-form-item {
  margin-bottom: 4px !important;
}

/* .el-table .warning-row {
    background: #000000;
  }

  .success-row {
    background: #f0f9eb;
  } */
</style>
