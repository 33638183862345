var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize"
        }
      ],
      staticStyle: { padding: "6px 8px" },
      attrs: {
        fluid: "",
        "grid-list-md": "",
        id: "enfDataProc",
        "fill-height": ""
      }
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { xs12: "", sm5: "" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-2" },
                [
                  _c("v-card-title", { staticClass: "pt-2 pb-0 px-3" }, [
                    _c("h3", [_vm._v("단속자료 검색")])
                  ]),
                  _c(
                    "v-card-text",
                    {
                      staticClass: "py-0 px-2",
                      attrs: { id: "enfSelectCondition" }
                    },
                    [
                      _c(
                        "v-layout",
                        { attrs: { row: "", wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { sm6: "" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mb-0",
                                  attrs: {
                                    horizontal: "",
                                    "label-cols": 3,
                                    label: "단속 상태",
                                    "label-class": "text-sm-left",
                                    "label-for": "detectProgress"
                                  }
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "detectProgress",
                                      "value-field": "data",
                                      "text-field": "dataName",
                                      options: _vm.detectProgressInfo
                                    },
                                    model: {
                                      value: _vm.searchEnfData.detectProgress,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchEnfData,
                                          "detectProgress",
                                          $$v
                                        )
                                      },
                                      expression: "searchEnfData.detectProgress"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { sm6: "" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mb-0",
                                  attrs: {
                                    horizontal: "",
                                    "label-cols": 3,
                                    label: "차량 번호",
                                    "label-class": "text-sm-left",
                                    "label-for": "detectCarnum"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: { id: "detectCarnum" },
                                    model: {
                                      value: _vm.searchEnfData.detectCarnum,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchEnfData,
                                          "detectCarnum",
                                          $$v
                                        )
                                      },
                                      expression: "searchEnfData.detectCarnum"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { sm12: "" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mb-0",
                                  attrs: {
                                    horizontal: "",
                                    "label-cols": 3,
                                    label: "지점 명",
                                    "label-class": "text-sm-left",
                                    "label-for": "cameraId"
                                  }
                                },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      id: "cameraId",
                                      "value-field": "cameraId",
                                      "text-field": "cameraNm",
                                      options: _vm.cameraInfo
                                    },
                                    model: {
                                      value: _vm.searchEnfData.cameraId,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchEnfData,
                                          "cameraId",
                                          $$v
                                        )
                                      },
                                      expression: "searchEnfData.cameraId"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { sm6: "" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    lazy: "",
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "full-width": "",
                                    "max-width": "290px",
                                    "min-width": "290px"
                                  },
                                  model: {
                                    value: _vm.searchEnfData.enfStartDateMenu,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.searchEnfData,
                                        "enfStartDateMenu",
                                        $$v
                                      )
                                    },
                                    expression: "searchEnfData.enfStartDateMenu"
                                  }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      slot: "activator",
                                      label: "시작 일",
                                      "persistent-hint": "",
                                      "prepend-icon": "event",
                                      readonly: ""
                                    },
                                    slot: "activator",
                                    model: {
                                      value: _vm.computedEnfStartDate,
                                      callback: function($$v) {
                                        _vm.computedEnfStartDate = $$v
                                      },
                                      expression: "computedEnfStartDate"
                                    }
                                  }),
                                  _c("v-date-picker", {
                                    attrs: { "no-title": "" },
                                    on: {
                                      input: function($event) {
                                        _vm.searchEnfData.enfStartDateMenu = false
                                      }
                                    },
                                    model: {
                                      value: _vm.searchEnfData.enfStartDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchEnfData,
                                          "enfStartDate",
                                          $$v
                                        )
                                      },
                                      expression: "searchEnfData.enfStartDate"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { sm6: "" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "enfStartTimeMenuRef",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    "return-value":
                                      _vm.searchEnfData.enfStartTime,
                                    lazy: "",
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "full-width": "",
                                    "max-width": "290px",
                                    "min-width": "290px"
                                  },
                                  on: {
                                    "update:returnValue": function($event) {
                                      return _vm.$set(
                                        _vm.searchEnfData,
                                        "enfStartTime",
                                        $event
                                      )
                                    },
                                    "update:return-value": function($event) {
                                      return _vm.$set(
                                        _vm.searchEnfData,
                                        "enfStartTime",
                                        $event
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.searchEnfData.enfStartTimeMenu,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.searchEnfData,
                                        "enfStartTimeMenu",
                                        $$v
                                      )
                                    },
                                    expression: "searchEnfData.enfStartTimeMenu"
                                  }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      slot: "activator",
                                      label: "시작 시간",
                                      "prepend-icon": "access_time",
                                      readonly: ""
                                    },
                                    slot: "activator",
                                    model: {
                                      value: _vm.searchEnfData.enfStartTime,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchEnfData,
                                          "enfStartTime",
                                          $$v
                                        )
                                      },
                                      expression: "searchEnfData.enfStartTime"
                                    }
                                  }),
                                  _c("v-time-picker", {
                                    on: {
                                      change: function($event) {
                                        return _vm.$refs.enfStartTimeMenuRef.save(
                                          _vm.searchEnfData.enfStartTime
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.searchEnfData.enfStartTime,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchEnfData,
                                          "enfStartTime",
                                          $$v
                                        )
                                      },
                                      expression: "searchEnfData.enfStartTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { sm6: "" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "enfEndDateMenuRef",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    lazy: "",
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "full-width": "",
                                    "max-width": "290px",
                                    "min-width": "290px"
                                  },
                                  model: {
                                    value: _vm.searchEnfData.enfEndDateMenu,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.searchEnfData,
                                        "enfEndDateMenu",
                                        $$v
                                      )
                                    },
                                    expression: "searchEnfData.enfEndDateMenu"
                                  }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      slot: "activator",
                                      label: "종료 일",
                                      "persistent-hint": "",
                                      "prepend-icon": "event",
                                      readonly: ""
                                    },
                                    slot: "activator",
                                    model: {
                                      value: _vm.computedEnfEndDate,
                                      callback: function($$v) {
                                        _vm.computedEnfEndDate = $$v
                                      },
                                      expression: "computedEnfEndDate"
                                    }
                                  }),
                                  _c("v-date-picker", {
                                    attrs: { "no-title": "" },
                                    on: {
                                      input: function($event) {
                                        _vm.searchEnfData.enfEndDateMenu = false
                                      }
                                    },
                                    model: {
                                      value: _vm.searchEnfData.enfEndDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchEnfData,
                                          "enfEndDate",
                                          $$v
                                        )
                                      },
                                      expression: "searchEnfData.enfEndDate"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { sm6: "" } },
                            [
                              _c(
                                "v-menu",
                                {
                                  ref: "enfEndTimeMenuRef",
                                  attrs: {
                                    "close-on-content-click": false,
                                    "nudge-right": 40,
                                    "return-value":
                                      _vm.searchEnfData.enfEndTime,
                                    lazy: "",
                                    transition: "scale-transition",
                                    "offset-y": "",
                                    "full-width": "",
                                    "max-width": "290px",
                                    "min-width": "290px"
                                  },
                                  on: {
                                    "update:returnValue": function($event) {
                                      return _vm.$set(
                                        _vm.searchEnfData,
                                        "enfEndTime",
                                        $event
                                      )
                                    },
                                    "update:return-value": function($event) {
                                      return _vm.$set(
                                        _vm.searchEnfData,
                                        "enfEndTime",
                                        $event
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.searchEnfData.enfEndTimeMenu,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.searchEnfData,
                                        "enfEndTimeMenu",
                                        $$v
                                      )
                                    },
                                    expression: "searchEnfData.enfEndTimeMenu"
                                  }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      slot: "activator",
                                      label: "종료 시간",
                                      "prepend-icon": "access_time",
                                      readonly: ""
                                    },
                                    slot: "activator",
                                    model: {
                                      value: _vm.searchEnfData.enfEndTime,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchEnfData,
                                          "enfEndTime",
                                          $$v
                                        )
                                      },
                                      expression: "searchEnfData.enfEndTime"
                                    }
                                  }),
                                  _c("v-time-picker", {
                                    on: {
                                      change: function($event) {
                                        return _vm.$refs.enfEndTimeMenuRef.save(
                                          _vm.searchEnfData.enfEndTime
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.searchEnfData.enfEndTime,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.searchEnfData,
                                          "enfEndTime",
                                          $$v
                                        )
                                      },
                                      expression: "searchEnfData.enfEndTime"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "text-xs-right d-flex align-right",
                              attrs: { sm12: "" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.getEnfData("all")
                                    }
                                  }
                                },
                                [_vm._v("전체 검색")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.getEnfData("one")
                                    }
                                  }
                                },
                                [_vm._v("지점 검색")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { disabled: _vm.isImagePixelate },
                          on: { click: _vm.deleteAllDetectData }
                        },
                        [_vm._v("\n            선택 자료 삭제\n          ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            disabled: _vm.isImagePixelate
                          },
                          on: { click: _vm.confirmAllDetectData }
                        },
                        [_vm._v("\n            선택 자료 확정\n          ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    {
                      ref: "enfListWrap",
                      staticStyle: { "overflow-y": "auto" },
                      attrs: { row: "", wrap: "" }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { sm12: "" } },
                        [
                          _c(
                            "v-data-table",
                            {
                              ref: "detectDataGrid",
                              staticClass:
                                "elevation-0 enf-list-wrap detect-grid",
                              attrs: {
                                headers: _vm.detectDataHeader,
                                items: _vm.detectData,
                                pagination: _vm.pagination,
                                "hide-actions": "",
                                "select-all": "",
                                "item-key": "detectId"
                              },
                              on: {
                                "update:pagination": function($event) {
                                  _vm.pagination = $event
                                }
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "headers",
                                  fn: function(props) {
                                    return [
                                      _c(
                                        "tr",
                                        [
                                          _c(
                                            "th",
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  "input-value": props.all,
                                                  indeterminate:
                                                    props.indeterminate,
                                                  primary: "",
                                                  "hide-details": ""
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    return _vm.toggleAllEnfData(
                                                      $event
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._l(props.headers, function(
                                            header
                                          ) {
                                            return _c(
                                              "th",
                                              { key: header.text },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(header.text) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "items",
                                  fn: function(props) {
                                    return [
                                      props.index == _vm.enfDataCursor
                                        ? _c(
                                            "tr",
                                            {
                                              staticStyle: {
                                                "background-color": "#3266b4",
                                                color: "#ffffff"
                                              },
                                              attrs: { active: props.selected },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  return _vm.selectEnfDataProc(
                                                    props.item,
                                                    props.index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "td",
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      "input-value":
                                                        props.selected,
                                                      primary: "",
                                                      "hide-details": ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.toggleEnfData(
                                                          props
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.item.cameraPlaceName
                                                  )
                                                )
                                              ]),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-xs-right"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$dkEnfCodeFormatter.getDateFormat(
                                                        props.item.detectTime,
                                                        "YYYY-MM-DD HH:mm:ss"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-xs-right"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(props.item.carNum)
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _c(
                                            "tr",
                                            {
                                              attrs: { active: props.selected },
                                              on: {
                                                click: function($event) {
                                                  return _vm.selectEnfDataProc(
                                                    props.item,
                                                    props.index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "td",
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      "input-value":
                                                        props.selected,
                                                      primary: "",
                                                      "hide-details": ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.toggleEnfData(
                                                          props
                                                        )
                                                      }
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.item.cameraPlaceName
                                                  )
                                                )
                                              ]),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-xs-right"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$dkEnfCodeFormatter.getDateFormat(
                                                        props.item.detectTime,
                                                        "YYYY-MM-DD HH:mm:ss"
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                {
                                                  staticClass: "text-xs-right"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(props.item.carNum)
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                    ]
                                  }
                                }
                              ]),
                              model: {
                                value: _vm.selected,
                                callback: function($$v) {
                                  _vm.selected = $$v
                                },
                                expression: "selected"
                              }
                            },
                            [
                              _c(
                                "template",
                                { slot: "no-data" },
                                [
                                  _c(
                                    "v-alert",
                                    {
                                      attrs: {
                                        value: true,
                                        color: "info",
                                        icon: "warning"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      표출 할 단속 정보가 없습니다.\n                  "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-alert",
                                {
                                  attrs: {
                                    slot: "no-results",
                                    value: true,
                                    color: "error",
                                    icon: "warning"
                                  },
                                  slot: "no-results"
                                },
                                [
                                  _vm._v(
                                    "\n                검색 결과가 없습니다.\n              "
                                  )
                                ]
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "pt-2",
                          attrs: {
                            "align-center": "",
                            "justify-center": "",
                            row: ""
                          }
                        },
                        [
                          _c("v-pagination", {
                            ref: "enfDataPaging",
                            attrs: { length: _vm.pages, "total-visible": 7 },
                            model: {
                              value: _vm.pagination.page,
                              callback: function($$v) {
                                _vm.$set(_vm.pagination, "page", $$v)
                              },
                              expression: "pagination.page"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { xs12: "", sm7: "", "pl-2": "" } },
            [
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": 1200 },
                  model: {
                    value: _vm.enfDetailDialog,
                    callback: function($$v) {
                      _vm.enfDetailDialog = $$v
                    },
                    expression: "enfDetailDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _c("h2", [_vm._v("단속 상세 정보")])
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-layout",
                            { attrs: { wrap: "", row: "" } },
                            [
                              _c(
                                "v-flex",
                                { staticClass: "pr-2", attrs: { sm8: "" } },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { wrap: "", row: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        { attrs: { sm6: "" } },
                                        _vm._l(
                                          _vm.selectEnfData.detectImg,
                                          function(image) {
                                            return image.detectImgProgress ==
                                              "2"
                                              ? _c("div", { key: image.seq }, [
                                                  _c("img", {
                                                    staticClass:
                                                      "enf-image-size",
                                                    attrs: {
                                                      src:
                                                        _vm.$config.getServerConfig()
                                                          .enf.api.snapshot +
                                                        image.file
                                                    }
                                                  })
                                                ])
                                              : _vm._e()
                                          }
                                        ),
                                        0
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { sm6: "" } },
                                        _vm._l(
                                          _vm.selectEnfData.detectImg,
                                          function(image) {
                                            return image.detectImgProgress ==
                                              "3"
                                              ? _c("div", { key: image.seq }, [
                                                  _c("img", {
                                                    staticClass:
                                                      "enf-image-size",
                                                    attrs: {
                                                      src:
                                                        _vm.$config.getServerConfig()
                                                          .enf.api.snapshot +
                                                        image.file
                                                    }
                                                  })
                                                ])
                                              : _vm._e()
                                          }
                                        ),
                                        0
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { sm4: "", id: "enfDetailInfo" } },
                                [
                                  _c(
                                    "table",
                                    { staticClass: "tb-detail-info" },
                                    [
                                      _c("tr", [
                                        _c("th", [_vm._v("차량번호")]),
                                        _c("td", [
                                          _c(
                                            "h3",
                                            { staticClass: "display-1" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.selectEnfData.carNum)
                                              )
                                            ]
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("단속지점")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectEnfData.cameraPlaceName
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("행정구역")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$dkEnfCodeFormatter.getCodeValue(
                                                "REGION",
                                                _vm.selectEnfData.regionId
                                              )
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("단속영역 구분")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$dkEnfCodeFormatter.getCodeValue(
                                                "ENF_ZONE_TYPE",
                                                _vm.selectEnfData.zoneTypeCode
                                              )
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("과태료부과")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$dkEnfCodeFormatter.getCodeValue(
                                                "ENF_ZONE_TYPE",
                                                _vm.selectEnfData.zoneTypeCode
                                              )
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("자동차 유형")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$dkEnfCodeFormatter.getCodeValue(
                                                "CAR_TYPE",
                                                _vm.selectEnfData.carType
                                              )
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("단속일자")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$dkEnfCodeFormatter.getDateFormat(
                                                _vm.selectEnfData.detectTime,
                                                "YYYY-MM-DD"
                                              )
                                            )
                                          )
                                        ])
                                      ]),
                                      _c("tr", [
                                        _c("th", [_vm._v("경과시간")]),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(_vm.getDetectTimeDiff())
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.confirmDetectData("1")
                                }
                              }
                            },
                            [_vm._v("대사 확인")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "error" },
                              on: {
                                click: function($event) {
                                  return _vm.confirmDetectData("2")
                                }
                              }
                            },
                            [_vm._v("대사 취소")]
                          ),
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function($event) {
                                  _vm.enfDetailDialog = !_vm.enfDetailDialog
                                }
                              }
                            },
                            [_vm._v("닫기")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": 600 },
                  model: {
                    value: _vm.isMosaicProc,
                    callback: function($$v) {
                      _vm.isMosaicProc = $$v
                    },
                    expression: "isMosaicProc"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _c("h3", { staticClass: "display-1" }, [
                          _vm._v(_vm._s(_vm.isMosaicProcMsg))
                        ])
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c("v-progress-linear", {
                            attrs: { indeterminate: true }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card",
                { staticClass: "mb-1", attrs: { id: "enfDetectData" } },
                [
                  _c(
                    "v-card-title",
                    { staticStyle: { padding: "5px 16px 5px 16px" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", row: "" } },
                        [
                          _c(
                            "v-flex",
                            { attrs: { md6: "", sm7: "", xs12: "" } },
                            [
                              _c("v-text-field", {
                                ref: "carPlateNum",
                                attrs: {
                                  id: "carPlateNum",
                                  name: "carNum",
                                  readonly: _vm.isCarPlateModify
                                },
                                on: {
                                  focus: function($event) {
                                    _vm.isCarPlateModify = false
                                  },
                                  blur: function($event) {
                                    _vm.isCarPlateModify = true
                                  }
                                },
                                model: {
                                  value: _vm.selectEnfData.carNum,
                                  callback: function($$v) {
                                    _vm.$set(_vm.selectEnfData, "carNum", $$v)
                                  },
                                  expression: "selectEnfData.carNum"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    { staticStyle: { padding: "0 8px 5px 8px" } },
                    [
                      _c(
                        "v-data-table",
                        {
                          staticClass: "detect-grid",
                          attrs: {
                            headers: _vm.selectEnfDataHeader,
                            items: [_vm.selectEnfData],
                            "hide-actions": "",
                            id: "enfDetectDataGrid"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "headerCell",
                              fn: function(props) {
                                return [
                                  _c("v-tooltip", { attrs: { bottom: "" } }, [
                                    _c(
                                      "span",
                                      {
                                        attrs: { slot: "activator" },
                                        slot: "activator"
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(props.header.text) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(props.header.text) +
                                          "\n                "
                                      )
                                    ])
                                  ])
                                ]
                              }
                            },
                            {
                              key: "items",
                              fn: function(props) {
                                return [
                                  _c("td", [
                                    _vm._v(_vm._s(props.item.cameraPlaceName))
                                  ]),
                                  !props.item.regionId
                                    ? _c("td", {
                                        staticClass: "text-xs-center"
                                      })
                                    : _c(
                                        "td",
                                        { staticClass: "text-xs-center" },
                                        [
                                          _c(
                                            "v-edit-dialog",
                                            { attrs: { lazy: "" } },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$dkEnfCodeFormatter.getCodeValue(
                                                      "REGION",
                                                      props.item.regionId
                                                    )
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("v-select", {
                                                attrs: {
                                                  slot: "input",
                                                  items: _vm.regionInfo,
                                                  "item-text": "dataName",
                                                  "item-value": "data",
                                                  label: "행정구역"
                                                },
                                                slot: "input",
                                                model: {
                                                  value:
                                                    _vm.selectEnfData.regionId,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.selectEnfData,
                                                      "regionId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectEnfData.regionId"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                  !props.item.zoneTypeCode
                                    ? _c("td", {
                                        staticClass: "text-xs-center"
                                      })
                                    : _c(
                                        "td",
                                        { staticClass: "text-xs-center" },
                                        [
                                          _c(
                                            "v-edit-dialog",
                                            { attrs: { lazy: "" } },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$dkEnfCodeFormatter.getCodeValue(
                                                      "ENF_ZONE_TYPE",
                                                      props.item.zoneTypeCode
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                              _c("v-select", {
                                                attrs: {
                                                  slot: "input",
                                                  items: _vm.zoneTypeInfo,
                                                  "item-text": "dataName",
                                                  "item-value": "data",
                                                  label: "단속영역 구분"
                                                },
                                                slot: "input",
                                                model: {
                                                  value:
                                                    _vm.selectEnfData
                                                      .zoneTypeCode,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.selectEnfData,
                                                      "zoneTypeCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectEnfData.zoneTypeCode"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                  !props.item.carType
                                    ? _c("td", {
                                        staticClass: "text-xs-center"
                                      })
                                    : _c(
                                        "td",
                                        { staticClass: "text-xs-center" },
                                        [
                                          _c(
                                            "v-edit-dialog",
                                            { attrs: { lazy: "" } },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$dkEnfCodeFormatter.getCodeValue(
                                                      "CAR_TYPE",
                                                      props.item.carType
                                                    )
                                                  ) +
                                                  "\n                  "
                                              ),
                                              _c("v-select", {
                                                attrs: {
                                                  slot: "input",
                                                  items: _vm.carTypeInfo,
                                                  "item-text": "dataName",
                                                  "item-value": "data",
                                                  label: "자동차 유형"
                                                },
                                                slot: "input",
                                                model: {
                                                  value:
                                                    _vm.selectEnfData.carType,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.selectEnfData,
                                                      "carType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectEnfData.carType"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                  !props.item.detectTime
                                    ? _c("td")
                                    : _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$dkEnfCodeFormatter.getDateFormat(
                                              props.item.detectTime,
                                              "YYYY-MM-DD"
                                            )
                                          )
                                        )
                                      ]),
                                  !props.item.detectTime
                                    ? _c("td")
                                    : _c("td", [
                                        _vm._v(_vm._s(_vm.getDetectTimeDiff()))
                                      ])
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "template",
                            { slot: "no-data" },
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: {
                                    value: true,
                                    color: "info",
                                    icon: "warning"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                  표출 할 단속 정보가 없습니다.\n              "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c("h3", [_vm._v("차량 단속 사진")]),
                      _c("v-spacer"),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.isImagePixelate,
                              expression: "!isImagePixelate"
                            }
                          ]
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.setMosaic(true)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              모자이크 설정\n            "
                              )
                            ]
                          ),
                          _c("v-btn", { on: { click: _vm.mosaicRestore } }, [
                            _vm._v(
                              "\n              모자이크 복구\n            "
                            )
                          ]),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                disabled: _vm.isImagePixelate
                              },
                              on: {
                                click: function($event) {
                                  _vm.enfDetailDialog = !_vm.enfDetailDialog
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n              대사 정보 확인\n            "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isImagePixelate,
                              expression: "isImagePixelate"
                            }
                          ]
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.setMosaic(false)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " \n             모자이크 설정 완료\n            "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isImagePixelate,
                                  expression: "isImagePixelate"
                                }
                              ],
                              on: { click: _vm.clearRect }
                            },
                            [
                              _vm._v(
                                "\n             모자이크 취소\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-layout",
                        {
                          ref: "detectImgWrap",
                          attrs: { wrap: "", row: "", id: "detectImgWrap" }
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { sm6: "" } },
                            _vm._l(_vm.selectEnfData.detectImg, function(
                              image
                            ) {
                              return image.detectImgProgress == "2"
                                ? _c(
                                    "div",
                                    { key: image.seq },
                                    [
                                      _c("dk-enf-image", {
                                        ref: "detectImg",
                                        refInFor: true,
                                        attrs: {
                                          imgSrc:
                                            _vm.$config.getServerConfig().enf
                                              .api.snapshot + image.file,
                                          isImagePixelate: _vm.isImagePixelate,
                                          imgData: image,
                                          detectId: _vm.selectEnfData.detectId
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            _vm.enfDetailDialog = true
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            }),
                            0
                          ),
                          _c(
                            "v-flex",
                            { attrs: { sm6: "" } },
                            _vm._l(_vm.selectEnfData.detectImg, function(
                              image
                            ) {
                              return image.detectImgProgress == "3"
                                ? _c(
                                    "div",
                                    { key: image.seq },
                                    [
                                      _c("dk-enf-image", {
                                        ref: "detectImg",
                                        refInFor: true,
                                        attrs: {
                                          imgSrc:
                                            _vm.$config.getServerConfig().enf
                                              .api.snapshot + image.file,
                                          isImagePixelate: _vm.isImagePixelate,
                                          imgData: image,
                                          detectId: _vm.selectEnfData.detectId
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            _vm.enfDetailDialog = true
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            }),
                            0
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-snackbar",
            {
              attrs: {
                timeout: 2000,
                color: "primary",
                top: true,
                right: true
              },
              model: {
                value: _vm.isSnackbarDisplay,
                callback: function($$v) {
                  _vm.isSnackbarDisplay = $$v
                },
                expression: "isSnackbarDisplay"
              }
            },
            [_vm._v("\n        " + _vm._s(_vm.snackbarMsg) + "\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }