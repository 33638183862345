var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-container",
        { staticClass: "gis-wrap fill-width-height" },
        [
          _c("div", [
            _c("ul", { staticClass: "event-side-menu" }, [
              _c("li", { on: { click: _vm.onClickFlood } }, [
                _vm._v("폭우/침수 데이터")
              ]),
              _c("li", { on: { click: _vm.onClickDisease } }, [
                _vm._v("가축 질병 데이터")
              ]),
              _c("li", { on: { click: _vm.onClickWeatherList } }, [
                _vm._v("정부 재난 데이터")
              ]),
              _c("li", { on: { click: _vm.onClickCovid } }, [_vm._v("코로나")])
            ])
          ]),
          _c(
            "dk-info-panel",
            {
              ref: "infoPanel",
              attrs: {
                afterLeave: _vm.changeInfoPanel,
                afterEnter: _vm.changeInfoPanel,
                width: "320px",
                initShow: _vm.isPanelOpen,
                showCloseButton: false
              }
            },
            [
              _vm.showFlag === 1
                ? _c(
                    "div",
                    {
                      staticClass: "gis-info-panel-wrap",
                      staticStyle: { padding: "0 10px" }
                    },
                    _vm._l(_vm.floodList, function(floodContents) {
                      return _c(
                        "div",
                        { key: floodContents.key, staticClass: "flood-frame" },
                        [
                          _c("h4", [_vm._v(_vm._s(floodContents.title))]),
                          _c(
                            "table",
                            { staticClass: "el-table el-table--mini table-th" },
                            [
                              _c("tr", [
                                _c("th", [_vm._v("종류")]),
                                _c("td", [_vm._v(_vm._s(floodContents.type))])
                              ]),
                              _c("tr", { staticClass: "stat-text-color" }, [
                                _c("th", [_vm._v("상태")]),
                                _c("td", { class: floodContents.className }, [
                                  _vm._v(_vm._s(floodContents.status))
                                ])
                              ]),
                              floodContents.currentWaterLevel
                                ? _c("tr", [
                                    _c("th", [_vm._v("현재 수위")]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(floodContents.currentWaterLevel)
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              floodContents.renewTime
                                ? _c("tr", [
                                    _c("th", [_vm._v("갱신 시간")]),
                                    _c("td", [
                                      _vm._v(_vm._s(floodContents.renewTime))
                                    ])
                                  ])
                                : _vm._e(),
                              floodContents.statusUpdateName
                                ? _c("tr", [
                                    _c("th", [
                                      _vm._v(
                                        _vm._s(floodContents.statusUpdateName)
                                      )
                                    ]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(floodContents.statusUpdateTime)
                                      )
                                    ])
                                  ])
                                : _vm._e(),
                              floodContents.statusDuration
                                ? _c("tr", [
                                    _c("th", [_vm._v("상태유지시간")]),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(floodContents.statusDuration)
                                      )
                                    ])
                                  ])
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.showFlag === 2
                ? _c(
                    "div",
                    {
                      staticClass: "gis-info-panel-wrap",
                      staticStyle: { padding: "0 10px" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "distance-legend",
                          staticStyle: { "font-size": "17px", margin: "10px" }
                        },
                        [
                          _c("div", [
                            _c("span", [_vm._v("500mm")]),
                            _c("span", {
                              staticClass: "color-circle",
                              staticStyle: { "background-color": "#eb6637" }
                            })
                          ]),
                          _c("div", [
                            _c("span", [_vm._v("3km")]),
                            _c("span", {
                              staticClass: "color-circle",
                              staticStyle: { "background-color": "#f0d473" }
                            })
                          ]),
                          _c("div", [
                            _c("span", [_vm._v("10km")]),
                            _c("span", {
                              staticClass: "color-circle",
                              staticStyle: { "background-color": "#9cc593" }
                            })
                          ])
                        ]
                      ),
                      !_vm.diseaseList || _vm.diseaseList.length === 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "container-box mb-both-mg disaster-msg"
                            },
                            [
                              _c("span", [
                                _vm._v("가축질병이 발생하지 않았습니다.")
                              ])
                            ]
                          )
                        : _vm._l(_vm.diseaseList, function(diseaseContents) {
                            return _c(
                              "div",
                              {
                                key: diseaseContents.key,
                                staticClass: "flood-frame"
                              },
                              [
                                _c("h4", [
                                  _vm._v(_vm._s(diseaseContents.title))
                                ]),
                                Array.isArray(diseaseContents.dataList)
                                  ? _c(
                                      "table",
                                      {
                                        staticClass:
                                          "el-table el-table--mini table-th"
                                      },
                                      _vm._l(diseaseContents.dataList, function(
                                        data
                                      ) {
                                        return _c(
                                          "tr",
                                          { staticClass: "stat-text-color" },
                                          [
                                            _c("th", [
                                              _vm._v(_vm._s(data.name))
                                            ]),
                                            _c(
                                              "td",
                                              { class: data.className },
                                              [_vm._v(_vm._s(data.value))]
                                            )
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  : _vm._e()
                              ]
                            )
                          })
                    ],
                    2
                  )
                : _vm._e(),
              _vm.showFlag === 3
                ? _c("div", { staticStyle: { padding: "0 10px" } }, [
                    _c("h3", { staticClass: "middle-title" }, [
                      _vm._v("\n          울산 북구 날씨\n          "),
                      _c("span", { staticClass: "middle-title-date" }, [
                        _vm._v(_vm._s(_vm.weatherbaseTime))
                      ])
                    ]),
                    _c("div", { staticClass: "container-box mb-both-mg" }, [
                      _c(
                        "ul",
                        { staticClass: "table-title-ul" },
                        [
                          _c("li", { staticClass: "table-title table-align" }, [
                            _c("span"),
                            _c("span", [_vm._v("날씨")]),
                            _c("span", [_vm._v("온도")]),
                            _c("span", [_vm._v("강수량")])
                          ]),
                          _vm._l(_vm.weatherList, function(weatherContent) {
                            return _c(
                              "li",
                              {
                                key: weatherContent.key,
                                staticClass: "table-align"
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(weatherContent.name))
                                ]),
                                _c("span", [
                                  _c("img", {
                                    staticStyle: { width: "40px" },
                                    attrs: { src: weatherContent.weather }
                                  })
                                ]),
                                _c("span", [
                                  _vm._v(_vm._s(weatherContent.temp))
                                ]),
                                _c("span", [
                                  _vm._v(_vm._s(weatherContent.rain))
                                ])
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ]),
                    _c("h3", { staticClass: "middle-title" }, [
                      _vm._v("재난 발생 현황(지진, 태풍)")
                    ]),
                    _vm.specialReport
                      ? _c(
                          "div",
                          {
                            staticClass: "container-box mb-both-mg disaster-msg"
                          },
                          [_c("span", [_vm._v(_vm._s(_vm.specialReport))])]
                        )
                      : _c(
                          "div",
                          {
                            staticClass: "container-box mb-both-mg disaster-msg"
                          },
                          [
                            _c("span", [
                              _vm._v("현 지역에는 재난이 발생하지 않았습니다.")
                            ])
                          ]
                        )
                  ])
                : _vm._e(),
              _vm.showFlag === 4
                ? _c("div", [
                    _c("h3", { staticClass: "middle-title" }, [
                      _vm._v("\n          전국 "),
                      _c("span", { staticClass: "middle-title-date" }, [
                        _vm._v(_vm._s(_vm.totalDataDate))
                      ])
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "content-click-container flex-box mb-both-mg",
                        staticStyle: { height: "35%", "max-height": "35%" }
                      },
                      _vm._l(_vm.countryCovidList, function(countryCovidInfo) {
                        return _c(
                          "div",
                          {
                            key: countryCovidInfo.key,
                            staticClass: "content-click-box"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex-center covid-info" },
                              [
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(countryCovidInfo.name) + " "
                                  )
                                ]),
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(countryCovidInfo.count) + " "
                                  )
                                ]),
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(countryCovidInfo.upCount) + " "
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    ),
                    _c("h3", { staticClass: "middle-title" }, [_vm._v("울산")]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "content-click-container flex-box mb-both-mg",
                        staticStyle: { height: "35%", "max-height": "35%" }
                      },
                      _vm._l(_vm.ulsanCovidList, function(ulsanCovidInfo) {
                        return _c(
                          "div",
                          {
                            key: _vm.ulsanCovidList.key,
                            staticClass: "content-click-box"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "flex-center covid-info" },
                              [
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(ulsanCovidInfo.name) + " "
                                  )
                                ]),
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(ulsanCovidInfo.count) + " "
                                  )
                                ]),
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(ulsanCovidInfo.upCount) + " "
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    ),
                    _c("div", { staticClass: "covid-graph" })
                  ])
                : _vm._e()
            ]
          ),
          _c("dk-gis", {
            ref: "dkGis",
            attrs: {
              "fill-height": "",
              id: "dkGis",
              gisApiInfo: _vm.gisApiInfo,
              apiLoadComplete: _vm.apiLoadComplete,
              clusterData: _vm.clusterData,
              changedBounds: _vm.changedBounds
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }