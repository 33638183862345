export default (args) => {
return {
    data() {
        return {
            fcltTypeId: '',
            totalFclt: 0,
            error: 0,
            fcltStatusList: [],

            intervalTime: 30*1000,
            intervalKey: -1,
        }
    },
    created() {
        const _this = this;
        _this.fcltTypeId = args.fcltTypeId;
        // (async()=>{
        //     await _this.getFcltStatusInfo(_this.fcltTypeId);
        //     _this.updateErrorInfo();
        // })();
    },
    destroyed() {},
    beforeDestroy() {
        if(this.intervalKey !== -1) {
            clearInterval(this.intervalKey);
        }
    },
    mounted() {
        const _this = this;
        (async()=>{
            await _this.getFcltStatusInfo(_this.fcltTypeId);
            _this.updateErrorInfo();
        })();
        _this.intervalKey = setInterval(async () => {
            await _this.getFcltStatusInfo(_this.fcltTypeId);
            _this.updateErrorInfo();
        }, _this.intervalTime)
    },
    methods: {
        async getFcltStatusInfo(fcltTypeId) {
            const _this = this;
            await _this.$store.dispatch("fcltStatus/getFcltStatus", {fcltTypeId});
            _this.fcltStatusList = _this.$store.getters['fcltStatus/getFcltStatus'];
        },
        updateErrorInfo() {
            const _this = this;
            if(_this.fcltStatusList) {
                _this.totalFclt = _this.fcltStatusList.length;

                let errCnt = 0;
                _this.fcltStatusList.forEach(fcltStatus => {
                    errCnt += (fcltStatus.statusFlag === true)  ? 0 : 1;
                });
                _this.error = errCnt;
            } else {
                _this.totalFclt = 0;
                _this.error = 0;
            }
            // console.log(`fcltTypeId[${_this.fcltTypeId}]`)
            // console.log(`totalFclt[${_this.totalFclt}]`)
            // console.log(`error[${_this.error}]`)
        },
    },
}
};