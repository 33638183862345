<template>
  <div class="fill-width-height el-container" id="ledLibrary">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <el-row>
              <el-col :xs="10" :sm="12" :md="12" class="grid-header-menu">
                <el-button type="primary" @click="openFormDialog" size="medium" style="margin-top: 7px;">
                  <i class="fas fa-plus-circle">&nbsp;추가</i>
                </el-button>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="24">
            <ul class="led-library">
              <li v-for="(item, index) in ledLibraries" :key="index" @click="showLedLibrary(item)">
                <img :src="getThumbImg(item)" class="ledLibrary-thumb-img" />
                <div>{{ item.ledLibraryName }}</div>
              </li>
            </ul>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <form-led-library ref="formDialog"></form-led-library>
    <el-dialog title="LED 라이브러리" :visible.sync="ledLibraryDialog" class="el-dialog-aside">
      <el-container class="dialog-container">
        <el-container>
          <img :src="getThumbImg(selectLedLibrary)" class="ledLibrary-select-img" />
        </el-container>
        <el-aside width="560px">
          <div class="info-panel">
            <table class="dk-table">
              <colgroup>
                <col style="width: 180px;" />
                <col style="width: auto;" />
              </colgroup>
              <tbody>
                <tr>
                  <th scople="row">라이브러리 ID</th>
                  <td>{{ selectLedLibrary.ledLibraryId }}</td>
                </tr>
                <tr>
                  <th scople="row">라이브러리 명칭</th>
                  <td>{{ selectLedLibrary.ledLibraryName }}</td>
                </tr>
                <tr>
                  <th scople="row">이미지 포멧</th>
                  <td>{{ selectLedLibrary.imageType }}</td>
                </tr>
                <tr>
                  <th scople="row">카테고리</th>
                  <td>{{ getCategory(selectLedLibrary.ledCategory) }}</td>
                </tr>
                <tr>
                  <td colspan="2" style="text-align: right;">
                    <el-button size="small" @click="updateFormDialog(selectLedLibrary)">수정</el-button>
                    <el-button size="small" type="danger" @click="remove(selectLedLibrary)">삭제</el-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </el-aside>
      </el-container>
    </el-dialog>
  </div>
</template>

<script>
import formLedLibrary from './form/formLedLibrary.vue';

export default {
  components: {
    'form-led-library': formLedLibrary,
  },
  created() {
    this.getLedLibraries(1);
    this.$on('procDataComplete', (materialType) => {
      this.getLedLibraries();
    });
  },
  data() {
    return {
      ledLibraries: [],
      limit: 100,
      totalDocs: 0,
      shownInfoPanel: false,
      selectLedLibrary: {
        ledLibraryName: '',
        files: [],
      },
      ledLibraryDialog: false,
    };
  },
  methods: {
    async getLedLibraries() {
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/led/api/ledLibrary`);
      this.ledLibraries = response.data.docs;
    },
    updateFormDialog(row) {
      this.$refs.formDialog.showDialog(row);
    },
    openFormDialog() {
      this.$refs.formDialog.showDialog();
    },
    getThumbImg(ledLibrary) {
      let thumb = 'static/images/no-image.jpg';
      if (!ledLibrary.images) {
        return thumb;
      }
      if (ledLibrary.images.length == 0) {
        return thumb;
      }
      thumb = `${ledLibrary.images[0].serverFilePath}`;
      return thumb;
    },
    showLedLibrary(ledLibrary) {
      this.selectLedLibrary = ledLibrary;
      this.ledLibraryDialog = true;
    },
    remove(args) {
      this.$confirm(`${args.ledLibraryName} LED 라이브러리를 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(() => {
          this.$http
            .post(`${this.$config.getServerConfig().core.api}/led/api/ledLibrary/delete`, { data: args })
            .then((response) => {
              this.ledLibraryDialog = false;
              this.getLedLibraries();
              this.$message({
                type: 'success',
                message: `${args.ledLibraryName} LED 라이브러리 삭제 완료`,
              });
            })
            .catch(() => {
              this.ledLibraryDialog = false;

              this.$message({
                type: 'info',
                message: `${args.ledLibraryName} LED 라이브러리 삭제 중 에러 발생`,
              });
            });
        })
        .catch(() => {
          this.ledLibraryDialog = false;

          this.$message({
            type: 'info',
            message: `${args.ledLibraryName} LED 라이브러리 삭제 취소`,
          });
        });
    },
    getCategory(category) {
      if (category == 'traffic') {
        return '교통';
      } else {
        return '기타';
      }
    },
  },
};
</script>
