<template>
  <div style="position: absolute; top: 0; left: 0; bottom: 0; right: 0;">
    aaaa
    <dk-gis
      fill-height
      :gisApiInfo="gisApiInfo"
      :markerData="markerData"
      :clickMarker="selectFclt"
      :clickCluster="selectCluster"
      :fcltManagerOnGis="fcltManagerOnGis"
      :apiLoadComplete="apiLoadComplete"
      :clusterData="clusterData"
      @moveEnd="searchFcltGis"
      ref="dkGis"
    ></dk-gis>
  </div>
</template>

<script>
// DK components
import dkgismain from '@/components/dkGis/dkGisMain.vue';

export default {
  components: {
    'dk-gis': dkgismain,
  },
  data: () => ({
    pageInit: false,
    // infoPanelOpen: true,
    gisApiInfo: [],
    currentGisApi: {},
    platformType: 'fclt',
    totalFcltCount: 0,
    beforeZoomLevel: -1,
    beforeSearchFcltTypeId: [],
    markerData: [],
    clusterData: [],
    searchPlaceData: [],
    selectFcltMarkerData: {
      fcltId: '',
      fcltName: '',
      fcltTypeName: '',
      fcltTypeId: '',
      regionId: '',
      regionName: '',
      setDate: '',
      setNumberAddr: '',
      setRoadAddr: '',
      gisLat: '',
      gisLng: '',
      equip: [],
      fcltTypeData: {},
      images: [],
      files: [],
    },
    isOnTrafficLayer: false,
    geometry: {},
    fcltType: [],
    keyword: '',
    searchFcltTypeId: '',
    gisMenuItems1: [
      // {
      //   index:"0",
      //   label: "카카오",
      //   clickEvent: "changeKakao",
      //   icon: "fas fa-map"
      // },
      // {
      //   index:"1",
      //   label: "OSM",
      //   clickEvent: "changeOpenStreetMap",
      //   icon: "fas fa-map"
      // }
    ],
    gisMenuItems2: [
      {
        index: '2',
        label: '시설물추가',
        clickEvent: 'setFcltManageOnGis',
        icon: 'fas fa-map-marker-alt',
        type: 'global',
      },
      {
        index: '3',
        label: '교통정보',
        clickEvent: 'setTrafficLayer',
        icon: 'fas fa-traffic-light',
        type: 'global',
      },
      // {
      //   index: "4",
      //   label: "커버리지",
      //   clickEvent: "getCoverageArea",
      //   icon: "fas fa-traffic-light",
      //   type: 'global'
      // }
    ],
    activeUser: null,

    geolocationAddr: '정보 없음',
    showGisLegend: true,
    isGisSearchResult: false,

    /**
     * 시설물 검색으로 찾아서 selected 된 시설물정보가 있을경우에는,
     * 지도 이동으로 시설물 데이터를 refresh하지 않는다.
     */
    isNoSearchByGisMove: false,
    searchTempMarkerData: [],
  }),
  created() {
    this.activeUser = this.$dkRouter.getUserInfo();

    // GIS API 모듈 로딩정보
    let me = this;
    this.$store
      .dispatch('gis/actGisApi', {})
      .then(() => {
        me.gisApiInfo = me.$store.getters['gis/getGisApi'];
        me.platformType = me.gisApiInfo[0].platformType || 'fclt';

        // 카카오 내비 연동용 API 초기화
        // 나중에 어떻게 할지 고민좀 해봐야함
        // 모바일에서나 쓸거같으니 모바일만 초기화 함
        // 모바일 페이지는 어쩌징?
        // 막상 다시할라니 반응형이 지랄같겠는디..

        if (me.$isMobile) {
          Kakao.init(me.$config.getSiteConfig().gis.apiKey);
        }
      })
      .then(async () => {
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fcltType`, {});
        me.fcltType = response.data.docs;
        me.searchFcltTypeId = [];
        me.fcltType.forEach((ft) => {
          me.searchFcltTypeId.push(ft.fcltTypeId);
        });
      });

    // if (this.$config.getSiteConfig().platformType === 'utis') {
    //   this.gisMenuItems2.push({
    //     index: "4",
    //     label: "사고/돌발 등록",
    //     clickEvent: "setIncidentEvent",
    //     icon: "fas fa-car-crash"
    //   })
    // }

    // 모바일에서는 범례를 기본적으로 보여주지않음
    this.showGisLegend = !this.$isMobile;
  },
  mounted() {
    //   this.$refs.gisContainer.$on('procDataComplete', (fcltData) => {
    //     this.searchFclt()
    //     .then(()=>{
    //       this.$refs.dkGis.refresh();
    //       let selectedMarker = this.markerData.find((marker) => {
    //         return marker.fcltId = fcltData.fcltId
    //       })
    //       this.selectFclt(selectedMarker)
    //     })
    //   });
    this.$nextTick(() => {
      let api = this.gisApiInfo.find((apiInfo) => {
        return apiInfo.initGis;
      });
      if (api && api.gisName) {
        this.$refs.dkGis.gisModuleLoad(api.gisName);
      }
      if (this.markerData[0]) {
        this.selectFclt(this.markerData[0]);
      }
    });
  },
  destroyed() {
    if ('geolocation' in navigator) {
      // navigator.geolocation.clearWatch(this.watchID);
    }
  },
  methods: {
    async searchFcltGis(args) {
      let data = await this.$refs.dkGis.getAddrFromCenterCoord(true);
      this.geolocationAddr = data.addr && data.addr.num_addr ? data.addr.num_addr : '정보 없음';

      if (this.isNoSearchByGisMove) {
        return;
      }
      let param = {};
      // param.keyword = this.keyword;
      param.fcltTypeId = this.searchFcltTypeId;
      // this.markerData = []

      return this.getGisFcltMarker(param).then(() => {
        for (var i = 0; i < this.markerData.length; i++) {
          this.markerData[i].zoomLevel = args.level;
        }
        // this.$refs.pagination.setPage(1)
        // this.pagingProc(1)
        if (!this.pageInit) {
          this.pageInit = true;
        }
      });
    },
    searchFclt() {
      let param = {};
      // param.keyword = this.keyword;
      param.fcltTypeId = this.searchFcltTypeId;
      return this.getGisFcltMarker(param).then(() => {
        let zoomLevel = this.$refs.dkGis.getLevel();
        for (var i = 0; i < this.markerData.length; i++) {
          this.markerData[i].zoomLevel = zoomLevel;
        }
        if (!this.pageInit) {
          this.pageInit = true;
        }
      });
    },

    async searchFcltList() {
      let param = {};
      this.searchTempMarkerData = [];
      this.searchPlaceData = [];

      param.keyword = this.keyword;

      // 장소 데이터는 검색하고 적용 했을 시, 그 부근으로 지도를 이동시키는 기능으로 실행한다.
      this.searchPlaceData = await this.$refs.dkGis.getCoordFromAddr(param.keyword);
      // 검색된 시설물 데이터는 임시 목록에 저장한 후, 적용하였을 시, markerData에 엎어씌워서
      // 시설물 마커를 rendering하고 선택한다.
      param.clusterLv0 = 0;
      param.clusterLv1 = 999;
      param.clusterLv2 = 999;
      let tmpData = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/gis/getFcltMarker`, { params: param });
      this.searchTempMarkerData = tmpData.data.markerData;
    },

    async getGisFcltMarker(args) {
      let zoomLevel = this.$refs.dkGis.getLevel();
      if (this.beforeZoomLevel !== this.$refs.dkGis.getLevel()) {
        this.markerData = [];
        this.clusterData = [];
      }
      if (args && args.fcltTypeId) {
        if (!this.$lodash.isEqual(this.beforeSearchFcltTypeId, args.fcltTypeId)) {
          this.markerData = [];
          this.clusterData = [];
        }
        this.beforeSearchFcltTypeId = this.$lodash.cloneDeep(args.fcltTypeId);
      }
      this.beforeZoomLevel = zoomLevel;

      this.activeUser = this.$dkRouter.getUserInfo();
      if (args) {
        args.bounds = this.$refs.dkGis.getBounds();
        args.zoomLevel = zoomLevel;
        args.centerCoords = this.$refs.dkGis.getCenter();
      } else {
        args = {
          bounds: this.$refs.dkGis.getBounds(),
          zoomLevel: zoomLevel,
          centerCoords: this.$refs.dkGis.getCenter(),
        };
      }

      // if (args.keyword) {
      //   this.searchPlaceData = await this.$refs.dkGis.getCoordFromAddr(args.keyword)

      //   if (this.searchPlaceData.length > 0) {
      //     this.activeGisDataTab = 'tab0'
      //     delete args.keyword
      //   }
      // }

      args.clusterLv0 = 0;
      args.clusterLv1 = 4;
      args.clusterLv2 = 8;
      args.clusterEnable = true;

      return this.$store.dispatch('gis/actGetFcltMarker', args).then(async (response) => {
        if (this.$store.getters['gis/getClusterData'].length > 0) {
          let newClusterData = this.$lodash.map(this.$store.getters['gis/getClusterData'], (item) => {
            let chk = _.find(this.clusterData, function (o) {
              if (o && o.properties) {
                return item.properties.clusterCd === o.properties.clusterCd;
              } else {
                return false;
              }
            });
            if (!chk) {
              return item;
            } else {
              return null;
            }
          });
          this.clusterData = this.clusterData.concat(this.$lodash.compact(newClusterData));
        }
        if (this.$store.getters['gis/getFcltMarker'].length > 0) {
          let newMarkerData = this.$lodash.map(this.$store.getters['gis/getFcltMarker'], (item) => {
            let chk = _.find(this.markerData, function (o) {
              if (o && o.properties) {
                return item.properties.fcltId === o.properties.fcltId;
              } else {
                return false;
              }
            });
            if (!chk) {
              return item;
            } else {
              return null;
            }
          });
          this.markerData = this.markerData.concat(this.$lodash.compact(newMarkerData));
        }

        if (this.clusterData.length > 0) {
          this.totalFcltCount = 0;
          this.clusterData.forEach((c) => {
            this.totalFcltCount += parseInt(c.properties.fcltCount);
          });
        }
      });
    },
    selectFcltDataGrid(row, event, column) {
      this.selectFclt(row);
    },
    selectFclt(args) {
      this.$refs.dkGis.setCenter({
        lat: args.properties.gisLat,
        lng: args.properties.gisLng,
      });
      setTimeout(async () => {
        // this.$refs.dkGis.setLevel(3);
        let data = await this.$refs.dkGis.getAddrFromCenterCoord(true);
        this.geolocationAddr = data.addr && data.addr.num_addr ? data.addr.num_addr : '정보 없음';

        this.selectFcltMarkerData = args.properties;
        if (args.$markerClickEvent) {
          args.$markerClickEvent();
          return;
        }
        this.$forceUpdate();
      }, 100);
    },
    clearFclt() {
      this.selectMarkerObject = null;
      this.selectFcltMarkerData = {
        fcltName: '',
        fcltTypeName: '',
        fcltTypeId: '',
        regionId: '',
        regionName: '',
        setDate: '',
        setNumberAddr: '',
        setRoadAddr: '',
        gisLat: '',
        gisLng: '',
        equip: [],
        fcltTypeData: {},
        images: [],
        files: [],
      };
      // this.isShowFcltInfo = false;
      if (this.$refs.dkGis) {
        this.hideRoadview();
      }
    },
    selectMaintnceLog(maintnceLog) {
      this.$refs.formMaintnce.showDialog(maintnceLog);
    },
    maintnce() {
      this.$refs.formMaintnce.showDialogOnGis(this.selectFcltMarkerData);
    },
    fcltInfoCommand(command) {
      if (command == 'update') {
        this.updateFclt();
      } else if (command == 'remove') {
        this.removeFclt();
      }
    },
    updateFclt() {
      this.geometry = {
        lat: this.selectFcltMarkerData.gisLat,
        lng: this.selectFcltMarkerData.gisLng,
      };
      let updateFlag = true;
      this.$refs.formFcltDialog.showDialog(updateFlag);
      setTimeout(() => {
        this.$refs.formFcltDialog.setFcltDataDoc(this.selectFcltMarkerData);
      }, 100);
    },
    removeFclt() {
      this.$confirm(`${this.selectFcltMarkerData.fcltName} 시설물을 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          let response = await this.$http
            .post(`${this.$config.getServerConfig().core.api}/core/api//fclt/delete`, {
              data: this.selectFcltMarkerData,
            })
            .then((response) => {
              this.clearFclt();
              this.searchFclt();
              this.$message({
                type: 'success',
                message: `${this.selectFcltMarkerData.fcltName} 시설물 삭제 완료`,
              });
            })
            .catch(() => {
              this.$message({
                type: 'info',
                message: `${this.selectFcltMarkerData.fcltName} 시설물 삭제 중 에러 발생`,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${this.selectFcltMarkerData.fcltName} 시설물 삭제 취소`,
          });
        });
    },
    fcltManagerOnGis(args) {
      let updateFlag = false;
      let geometry = {};
      if (!args.geometry) {
        return;
      }
      let licenseInfo = this.$store.getters['license/getLicense'];
      if (licenseInfo.currentFcltCount >= licenseInfo.limitFcltCount) {
        this.$alert(
          `등록된 ${licenseInfo.currentFcltCount}건의 시설물 정보가 있고, 현재 라이선스는 총 ${licenseInfo.limitFcltCount}건의 시설물이 등록 가능하므로, 유효한 시설물 등록 수를 초과합니다.`,
          '라이선스 등록 초과',
          {
            confirmButtonText: '확인',
            type: 'warning',
          }
        );
        this.$store.dispatch('license/setDialog', true);
        return;
      }
      this.geometry = this.$lodash.cloneDeep(args.geometry);
      this.$refs.formFcltDialog.showDialog(updateFlag);
    },

    gisMenuSelect(eventName) {
      this[eventName]();
    },
    changeGoogle() {
      this.$refs.dkGis.gisModuleLoad('google');
    },

    changeKakao() {
      this.$refs.dkGis.gisModuleLoad('kakao');
    },

    changeOpenStreetMap() {
      this.$refs.dkGis.gisModuleLoad('openStreetMap');
    },

    setFcltManageOnGis() {
      this.$refs.dkGis.setFcltManageOnGis();
    },

    setTrafficLayer() {
      this.isOnTrafficLayer = this.$refs.dkGis.setTrafficLayer(!this.isOnTrafficLayer);
    },
    // setTrafficSelectMode(){
    //   this.trafficSelectMode = this.$refs.dkGis.setTrafficSelectMode(this.trafficSelectMode);
    // },
    // setIncidentOnGis() {
    //   this.$refs.dkGis.setIncidentOnGis();
    // },

    //   toggleInfoPanel() {
    //     this.$refs.infoPanel.toggle();
    //     this.isShowInfoPanel = this.$refs.infoPanel.isOpened();
    //   },

    //   changeInfoPanel(el) {
    //     this.$refs.dkGis.refresh();
    //   },

    //   getHumanFileSize(bytes) {
    //     return DkFileUtil.getHumanFileSize(bytes, true);
    //   },
    //   downloadReference(row, event, column) {
    //     DkFileUtil.downloadFromUrl(row.url, row.name);
    //   },
    getFcltDataValue(fcltData) {
      let val = fcltData.value;
      if (fcltData.formType == 'combobox') {
        if (fcltData.options) {
          let tempOption = fcltData.options.find((opt) => {
            return opt.value == fcltData.value;
          });
          if (tempOption) {
            val = tempOption.label;
          }
        }
      }
      return val;
    },
    setRoadview() {
      this.$refs.dkGis.setRoadview({
        lat: this.selectFcltMarkerData.gisLat,
        lng: this.selectFcltMarkerData.gisLng,
      });
    },
    hideRoadview() {
      this.$refs.dkGis.hideRoadview();
    },
    kakaoNavi() {
      if (Kakao) {
        Kakao.Navi.start({
          name: this.selectFcltMarkerData.fcltName,
          x: this.selectFcltMarkerData.gisLng,
          y: this.selectFcltMarkerData.gisLat,
          coordType: 'wgs84',
        });
      }
    },
    apiLoadComplete(currentGisApi) {
      this.currentGisApi = currentGisApi;
      setTimeout(() => {
        let param = {};
        param.fcltTypeId = this.searchFcltTypeId;
        this.getGeolocation();
        this.getGisFcltMarker(param);
      }, 1000);
    },

    selectCluster(args) {
      // console.log(args)
    },
    selectClusterOnGrid(args) {
      args.$clusterClickEvent();
    },
    // mouseEnterClusterOnGrid(row, column, cell, event) {
    //   row.$clusterFocusEvent()
    // },
    // mouseLeaveClusterOnGrid(row, column, cell, event) {
    //   row.$clusterBlurEvent()
    // },
    selectPlaceDataGrid(row) {
      this.$refs.dkGis.setCenter({
        lat: row.lat,
        lng: row.lng,
      });
      this.$refs.dkGis.setLevel(4);
    },
    getGeolocation() {
      let me = this;
      if ('geolocation' in navigator) {
        /* 지오로케이션 사용 가능 */
        navigator.geolocation.getCurrentPosition(async (position) => {
          let data = await me.$refs.dkGis.actGeolocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          me.geolocationAddr = data.addr && data.addr.num_addr ? data.addr.num_addr : '정보 없음';
        });
        // this.watchID = navigator.geolocation.watchPosition((position) => {
        //   console.log(position.coords.latitude, position.coords.longitude);

        //   this.$refs.dkGis.setCenter({
        //       lat:position.coords.latitude,
        //       lng:position.coords.longitude
        //   })
        // });
      } else {
        /* 지오로케이션 사용 불가능 */
      }
    },
    searchFocus(e) {
      this.isGisSearchResult = true;
      // this.clearFclt();
    },
    searchBlur(e) {
      this.isGisSearchResult = false;
    },
    checkDisplayFcltType(fcltTypeId) {
      let result = this.searchFcltTypeId.find((ft) => {
        return ft === fcltTypeId;
      });
      return result;
    },
    toggleFcltType(fcltTypeId) {
      this.searchFcltTypeId = this.$lodash.xor(this.searchFcltTypeId, [fcltTypeId]);
      this.$forceUpdate();
      this.searchFclt();
    },
    async moveGisCenter(placeData) {
      let data = await this.$refs.dkGis.actGeolocation({
        lat: placeData.lat,
        lng: placeData.lng,
      });
      this.geolocationAddr = placeData.addressName;
      this.isGisSearchResult = false;
      this.searchFclt();
      // this.geolocationAddr = (data.addr && data.addr.num_addr) ? data.addr.num_addr : "정보 없음"
    },
    async applySearchMarkerData(fcltData) {
      // document.getElementById('mainView').scrollTop = 0;
      this.isGisSearchResult = false;
      this.isNoSearchByGisMove = true;
      this.clusterData = [];
      this.markerData = [fcltData];
      this.selectFclt(fcltData);
    },
    resetSearch() {
      this.isNoSearchByGisMove = false;
      this.keyword = '';
      this.searchPlaceData = [];
      this.searchTempMarkerData = [];
      this.searchFclt();
      this.clearFclt();
    },
  },
};
</script>

<style scoped>
.gis-legend-menu {
  border-radius: 5px;
  border: 1px solid #b5c2ca;
}

.gis-legend-menu li {
  height: 30px !important;
  line-height: 30px !important;
  padding: 0 15px !important;
  border-bottom: 1px solid #b5c2ca;
}

.gis-legend {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(255, 255, 255, 0.7);
  bottom: 31px;
  left: -69px;
  border-radius: 5px;
  border: 1px solid rgba(181, 194, 202, 0.7);
  width: 138px;
  align-content: flex-end;
  z-index: 5555;
  list-style: none;
  margin: 0;
  padding: 0;
  top: auto;
}

.gis-legend li {
  width: 138px;
  float: left;
  font-size: 11px;
  box-sizing: border-box;
  text-indent: 5px;
  color: #6f6f6f;
  height: 26px;
  line-height: 26px;
  border-bottom: 1px solid rgba(181, 194, 202, 0.5);
}

.gis-legend li.selected {
  background-color: rgba(25, 118, 210, 0.7);
  color: #fff;
}

.gis-legend li img {
  height: 24px;
  margin-top: 1px;
  margin-left: 6px;
  float: left;
}
/* .gis-legend {
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    left: 80px;
    bottom: 70px;
    width: 170px;
    align-content: flex-end;
    z-index: 5555;
    list-style: none;
    margin: 0;
    padding: 0;  
  }

  .gis-legend li {
    width:168px;
    float:left;
    height:30px;
    line-height:30px;
    box-sizing: border-box;
    border-bottom:1px solid rgba(237, 241, 242, 0.6);
    color: #6f6f6f;
    font-size: 12px;
    text-indent:5px;
  }

  .gis-legend li img {
    height:26px;
    margin-top:2px; 
    float: left;
  }

  @media screen and (max-width: 768px) {
    
  } */
.geolocation-addr {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 0;
  text-align: center;
}

.geolocation-addr .location {
  overflow: hidden;
  display: inline-block;
  padding: 4px 12px 0;
  margin: 4px auto 0;
  height: 18px;
  border-radius: 10px;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  color: #555;
  opacity: 0.9;
  background-color: #fff;
  text-align: center;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
}

.gis-mobile-menu {
  position: relative;
  height: 40px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #b5c2ca;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 2px 6px;
}

.gis-mobile-menu-button {
  width: 32px;
  color: #5e6a76;
}

.gis-mobile-menu-search {
  flex: 1;
}

.gis-mobile-menu-button span {
  display: inline-block;
  width: 30px;
  height: 32px;
  text-align: center;
  font-size: 22px;
  line-height: 32px;
}

.gis-wrap {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.gis-wrap.on-fclt-selected {
  bottom: 90px;
}

.gis-search-result {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  background-color: #fff;
}

.gis-search-result h3 {
  font-size: 16px;
  color: #333;
  text-indent: 10px;
  border-bottom: 1px solid #cacaca;
  margin: 0;
  padding: 10px 0;
  background-color: #f0f0f0;
}

.gis-search-result ul {
  padding: 0;
  margin: 0;
}

.gis-search-result li {
  list-style: none;
  font-size: 12px;
  color: #6f6f6f;
  border-bottom: 1px solid #e0e0e0;
  padding: 12px 0;
  display: flex;
  flex-direction: row;
}
.gis-search-result .search-list-text {
  flex: 1;
  margin-left: 10px;
}
.gis-search-result .search-list-button {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: rgba(25, 118, 210, 1);
}
.gis-search-result .button-move {
  font-size: 24px;
}

.gis-search-result li .title {
  font-size: 14px;
  color: #444;
  margin-right: 10px;
}

.gis-search-result div.no-result {
  font-size: 13px;
  color: #555;
  width: 100%;
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #e0e0e0;
}

.gis-search-result .search-list-button {
}

.mobile-fclt-info {
  position: absolute;
  z-index: 5;
  width: 100%;
  top: calc(100% - 90px) !important;
  left: 0;
}

.mobile-fclt-label {
  background-color: #fff;
  /* padding: 8px 6px; */
  border-bottom: 1px solid #efefef;
}
.mobile-fclt-label:after {
  clear: both;
  display: block;
  content: '';
}

.mobile-fclt-label .fclt-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  float: left;
  margin: 0 5px;
  height: 36px;
  line-height: 36px;
  font-size: 14px;
}

.mobile-fclt-label span.fclt-type {
  color: #9a9a9a;
  font-size: 10px;
  float: left;
  margin: 13px 0 0 5px;
  width: 100px;
  overflow: hidden;
}

.mobile-fclt-label .fclt-btn {
  float: right;
  margin: 4px 4px 4px 0;
}

.gis-function-wrap {
  position: absolute;
  top: 4px;
  right: 5px;
  z-index: 3;
}

.gis-function-wrap > div {
  margin-bottom: 4px;
}

.gis-legend-wrap {
  position: absolute;
  bottom: 4px;
  right: 5px;
  z-index: 3;
}

.fclt-detail-view-menu .el-menu-item {
  padding-top: 5px;
  line-height: 22px;
  text-align: center;
}

.fclt-detail-view-menu a {
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .fclt-detail-view-menu .el-menu-item {
    width: 25%;
    padding: 5px 0 0 0;
  }
}

.el-submenu__title {
  line-height: 22px;
  padding-top: 5px;
}
</style>
