<template>
  <el-dialog
    title="VMS 기본메시지 다운로드"
    :visible.sync="isShowDialog"
    @close="closeModal"
    append-to-body
    :closeOnClickModal="false"
    top="5vh"
    class="el-dialog-aside"
    width="1100px"
  >
    <el-container>
      <el-row style="width: 100%; border-top: 1px solid rgb(222, 222, 222);">
        <el-col :span="12">
          <el-row>
            <el-col :span="24" style="height: 260px;">
              <h4>현재 기본 메시지</h4>
              <div v-if="currentDefaultMessage" class="vms-message-body">
                <img :src="currentDefaultMessage.vmsMessage.thumbImageFile" />
                <div>
                  {{ currentDefaultMessage.vmsMessage.vmsMessageName }}
                </div>
              </div>
              <div v-else class="vms-message-body">현재 설정된 기본메시지가 없습니다.</div>
            </el-col>
            <el-col :span="24" style="height: 260px;">
              <h4>설정 예정 기본 메시지</h4>
              <div v-if="selectedVmsMessage" class="vms-message-body">
                <img :src="selectedVmsMessage.thumbImageFile" />
                <div>
                  {{ selectedVmsMessage.vmsMessageName }}
                </div>
              </div>
              <div v-else class="vms-message-body">설정할 메시지를 선택해주세요.</div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12" class="vms-message-wrap">
          <el-table
            :data="vmsMessages"
            style="width: 100%; border-top: 1px solid #dedede;"
            empty-text="등록된 VMS 메시지가 없습니다."
            size="mini"
            stripe
            :height="550"
            @row-click="selectVmsMessage"
          >
            <el-table-column min-width="150px">
              <template slot-scope="scope">
                <img :src="scope.row.thumbImageFile" style="height: auto; width: 100%;" />
              </template>
            </el-table-column>
            <el-table-column prop="vmsMessageName" label="VMS 메시지 이름" min-width="80px"></el-table-column>
          </el-table>
        </el-col>
        <el-col :span="24" class="vms-button-wrap">
          <el-button @click="clickDownload" type="primary">기본메시지 다운로드</el-button>
        </el-col>
      </el-row>
    </el-container>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      vmsMessages: [],
      currentDefaultMessage: null,
      isShowDialog: false,
      selectedVmsMessage: null,
      selectedFcltData: null,
    };
  },
  methods: {
    async getCurrentDefaultMessage(row) {
      let param = { fcltId: row.fcltId };
      try {
        const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsCurrentDefaultMessage`, { params: param });
        this.currentDefaultMessage = response.data.docs || null;
        console.log(this.currentDefaultMessage);
      } catch (err) {}
    },
    async getVmsMessages(row) {
      //   if (this.selectVmsMessageSize !== '' && !!this.selectVmsMessageSize) {
      //     param.vmsMessageSize = this.selectVmsMessageSize;
      //   }

      let param = { vmsMessageSize: row.vmsSize };

      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/vmsMessage`, {
        params: param,
      });
      this.vmsMessages = response.data.docs;
    },

    selectLedSchedule(row) {
      this.selectedLedSchedule = row;
    },
    showDialog(row) {
      this.isShowDialog = true;
      this.selectedFcltData = row;
      console.log(row);
      this.getCurrentDefaultMessage(row);
      this.getVmsMessages(row);
    },
    selectVmsMessage(row) {
      this.selectedVmsMessage = row;
    },
    closeModal() {
      this.vmsMessages = [];
      this.selectedFcltData = null;
      this.currentDefaultMessage = null;
      this.selectedVmsMessage = null;
    },
    async clickDownload() {
      if (!this.selectedVmsMessage) {
        this.$message({
          type: 'warning',
          message: `설정할 VMS 메시지를 선택해주세요`,
        });
        return;
      }
      let param = {
        fcltId: this.selectedFcltData.fcltId,
        vmsMessage: this.selectedVmsMessage,
      };

      try {
        this.$confirm(`${this.selectedVmsMessage.vmsMessageName} 기본메시지를 설정 합니다.`, '경고', {
          confirmButtonText: '확인',
          cancelButtonText: '취소',
          type: 'info',
        })
          .then(async () => {
            this.loading = this.$loading({
              lock: true,
              text: '기본메시지를 설정 합니다',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)',
            });
            const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/vmsDefaultMessageDownload`, { data: param });
            this.loading.close();
            this.isShowDialog = false;
            this.$emit('completeDefaultMessage', {
              fcltId: param.fcltId,
            });
          })
          .catch(() => {
            this.loading.close();
            this.$message({
              type: 'info',
              message: `${this.selectedVmsMessage.vmsMessageName} LED 스케줄 설정 취소.`,
            });
          });
      } catch (err) {}
    },
  },
};
</script>

<style scoped>
.vms-message-wrap {
  border-left: 1px solid #ccc;
}
.vms-schedule-wrap h4 {
  text-align: left;
  margin: 14px 0 14px 10px;
}
.vms-message-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.vms-message-body img {
  max-height: 210px;
  object-fit: contain;
  max-width: 400px;
}
.vms-button-wrap {
  border-top: 1px solid #ccc;
  text-align: right;
  padding: 10px;
}
</style>
