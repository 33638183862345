var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-width-height el-container" },
    [
      _c(
        "el-container",
        { staticClass: "page-body" },
        [
          _c(
            "el-main",
            [
              _c(
                "el-row",
                { staticClass: "content-wrap" },
                [
                  _c("dk-page-header"),
                  _c(
                    "el-col",
                    { staticClass: "page-content", attrs: { span: 24 } },
                    [_c("el-row")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }