<template>
  <div class="drag-container">
    <ul class="drag-list">
      <li v-for="stage in stages" class="drag-column" :class="{ ['drag-column-' + stage.status]: true }" :key="stage.status">
        <span class="drag-column-header">
          <slot :name="stage">
            <h2>{{ stage.label }}</h2>
          </slot>
          <el-button
            v-if="stage.status == firstStageStatus"
            type="primary"
            size="small"
            icon="fas fa-plus"
            @click="addBlock(stage)"
            style="margin-left: 3px;"
            >&nbsp;추가</el-button
          >
        </span>
        <div class="drag-options"></div>
        <ul class="drag-inner-list" ref="list" :data-status="stage.status">
          <li :class="getImportanceClass(block)" v-for="block in getBlocks(stage.status)" :data-block-id="block._id" :key="block._id">
            <slot :name="block._id"> </slot>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import dragula from 'dragula';

export default {
  name: 'KanbanBoard',

  props: {
    stages: {},
    blocks: {},
    firstStageStatus: {
      default: 1,
    },
  },
  data() {
    return {};
  },

  computed: {
    localBlocks() {
      return this.blocks;
    },
  },

  methods: {
    getBlocks(status) {
      return this.localBlocks.filter((block) => block.status === status);
    },
    addBlock(stage) {
      this.$emit('add-block', stage);
    },
    getImportanceClass(data) {
      if (!data.importance) {
        return 'drag-item';
      } else {
        return `drag-item importance-${data.importance}`;
      }
    },
  },

  mounted() {
    dragula(this.$refs.list)
      .on('drag', (el) => {
        el.classList.add('is-moving');
      })
      .on('drop', (block, list) => {
        let index = 0;
        for (index = 0; index < list.children.length; index += 1) {
          if (list.children[index].classList.contains('is-moving')) break;
        }
        let item = this.blocks.find((b) => {
          return b._id == block.dataset.blockId;
        });
        this.$emit('update-block', item, list.dataset.status, index);
      })
      .on('dragend', (el) => {
        el.classList.remove('is-moving');

        window.setTimeout(() => {
          el.classList.add('is-moved');
          window.setTimeout(() => {
            el.classList.remove('is-moved');
          }, 400);
        }, 100);
      });
  },
};
</script>

<style lang="scss">
@import '../assets/style/_kanban.scss';
</style>
