<template>
  <div class="fill-width-height el-container">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <dk-el-table-header :openFormDialog="openFormDialog"></dk-el-table-header>
            <el-table
              :data="serviceManages"
              empty-text="등록된 사업관리 정보가 없습니다."
              size="mini"
              @cell-click="selectRow"
              stripe
            >
              <el-table-column label="사업명" prop="serviceTitle"></el-table-column>
              <el-table-column
                label="수행사"
                prop="company"
                width="120px"
                header-align="center"
                align="center"
              ></el-table-column>
              <el-table-column label="사업 시작일" width="140px" header-align="center" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.workStartDate">{{ getWriteDate(scope.row.workStartDate) }}</span>
                  <span v-else>입력되지 않음</span>
                </template>
              </el-table-column>
              <el-table-column label="사업 종료일" width="140px" header-align="center" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.workEndDate">{{ getWriteDate(scope.row.workEndDate) }}</span>
                  <span v-else>입력되지 않음</span>
                </template>
              </el-table-column>
              <el-table-column label width="170px">
                <template slot-scope="scope">
                  <el-button @click="openFormDialog(scope.row)" size="small">수정</el-button>
                  <el-button @click="remove(scope.row)" size="small" type="danger">삭제</el-button>
                </template>
              </el-table-column>
            </el-table>
            <dk-el-pagination
              :totalDocs="totalDocs"
              :limit="limit"
              :pagingProc="pagingProc"
              ref="pagination"
            ></dk-el-pagination>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <dk-info-panel ref="infoPanel" width="400px">
      <div class="info-panel-wrap">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="사업정보" name="tab1">
            <h3>사업 정보</h3>
            <el-carousel
              height="280px"
              v-if="selectServiceManage.images && selectServiceManage.images.length > 0"
            >
              <el-carousel-item v-for="(image, index) in selectServiceManage.images" :key="index">
                <div class="dk-carousel-item" style="background-color: #000; line-height: 280px;">
                  <img :src="$config.getServerConfig().image.url + image.serverFilePath" />
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="dk-card">
              <table class="el-table el-table--mini">
                <tr>
                  <th style="width: 110px;">사업명</th>
                  <td>{{ selectServiceManage.serviceTitle }}</td>
                </tr>
                <tr>
                  <th style="width: 110px;">수행사</th>
                  <td>{{ selectServiceManage.company }}</td>
                </tr>
                <tr>
                  <th style="width: 110px;">사업 시작일</th>
                  <td>{{ getWriteDate(selectServiceManage.workStartDate) }}</td>
                </tr>
                <tr>
                  <th style="width: 110px;">사업 종료일</th>
                  <td>{{ getWriteDate(selectServiceManage.workEndDate) }}</td>
                </tr>
                <tr>
                  <th style="width: 110px;">내용</th>
                  <td>{{ selectServiceManage.text }}</td>
                </tr>
              </table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="사업관련자료" name="tab2">
            <div class="dk-card">
              <el-table
                :data="selectServiceManage.files"
                empty-text="등록된 사업 관련 자료가 없습니다."
                size="mini"
                stripe
                @row-click="downloadReference"
              >
                <el-table-column label="파일 명">
                  <template slot-scope="scope">
                    <span class="file-download-label">{{ scope.row.name }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="파일 크기" width="120px">
                  <template slot-scope="scope">{{ getHumanFileSize(scope.row.size) }}</template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </dk-info-panel>

    <form-service-manage ref="formDialog"></form-service-manage>
  </div>
</template>

<script>
import dkeltableheader from '@/components/dkElTableHeader.vue';
import formservicemanage from '@/core/forms/formServiceManage.vue';
import dkelpagination from '@/components/dkElPagination.vue';
import dkinfopanel from '@/components/dkInfoPanel.vue';
import DkFileUtil from '@/components/dkFileUtil.js';
import moment from 'moment';

export default {
  components: {
    'dk-el-table-header': dkeltableheader,
    'form-service-manage': formservicemanage,
    'dk-el-pagination': dkelpagination,
    'dk-info-panel': dkinfopanel,
  },
  created() {
    this.getServiceManages(1);
    this.$on('procDataComplete', (serviceManageType) => {
      this.getServiceManages();
    });
  },
  data() {
    return {
      serviceManages: [],
      limit: 10,
      totalDocs: 0,
      activeTab: 'tab1',
      serviceManageShow: false,
      selectServiceManage: {
        serviceTitle: '',
        writerId: '',
        company: '',
        workStartDate: '',
        workEndDate: '',
        text: '',
        images: [],
        files: [],
      },
    };
  },
  methods: {
    selectRow(row, column, cell, event) {
      if (!this.$refs.infoPanel.isOpened()) {
        this.$refs.infoPanel.open();
      }
      this.selectServiceManage = row;
    },
    openFormDialog(row) {
      this.$refs.formDialog.showDialog(row);
    },
    remove(args) {
      this.$confirm(`사업관리 자료를 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/serviceManage/delete`, { data: args });
            this.getServiceManages();
            this.$message({
              type: 'success',
              message: `사업관리 자료 삭제 완료`,
            });
            this.$refs.infoPanel.close();
          } catch (error) {
            this.$message({
              type: 'info',
              message: `사업관리 자료 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `사업관리 자료 삭제 취소`,
          });
        });
    },
    getThumbImg(serviceManage) {
      let thumb = 'static/images/no-image.jpg';
      if (!serviceManage.images) {
        return thumb;
      }
      if (serviceManage.images.length == 0) {
        return thumb;
      }
      thumb = `${serviceManage.images[0].serverFilePath}`;
      return thumb;
    },
    pagingProc(pageNum) {
      this.getServiceManages();
    },
    async getServiceManages(pageNum) {
      try {
        let me = this;
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/serviceManage`, {
          params: {
            page: pageNum || this.$refs.pagination.getPage(),
            limit: this.limit,
          },
        });
        me.serviceManages = response.data.docs;
        me.totalDocs = response.data.totalDocs;
        me.$refs.infoPanel.close();
      } catch (error) {
        this.$message.error(`사업관리목록 로드 에러 발생`);
      }
    },
    getWriteDate(writeDate) {
      return moment(writeDate).format('YYYY년 MM월 DD일');
    },
    getHumanFileSize(bytes) {
      return DkFileUtil.getHumanFileSize(bytes, true);
    },
    downloadReference(row, event, column) {
      DkFileUtil.downloadFromUrl(row.url, row.name);
    },
  },
};
</script>

<style scoped></style>
