<template>
  <div class="page-set" style="height: 100vh;">
    <div class="top-bar-space">
      <h3 class="middle-title" style="margin-top: 65px;">
        울산 북구 날씨
        <span class="middle-title-date">{{ baseTime ? `(${baseTime} 기준)` : '' }}</span>
      </h3>

      <div class="container-box mb-both-mg">
        <ul class="table-title-ul">
          <li class="table-title table-align">
            <span></span>
            <span>날씨</span>
            <span>온도</span>
            <span>강수량</span>
          </li>
          <li v-for="weatherContent in weatherList" :key="weatherContent.key" class="table-align">
            <span>{{ weatherContent.name }}</span>
            <span><img :src="weatherContent.weather" style="width: 40px;" /></span>
            <span>{{ weatherContent.temp }}</span>
            <span>{{ weatherContent.rain }}</span>
          </li>
        </ul>
      </div>
      <div class="flex-box">
        <h3 class="middle-title" style="">울산 재난 발생 현황</h3>
        <button class="btn-log" @click="disasterLog">지난 로그 보기</button>
      </div>
      <template v-if="specialReportList.length > 0">
        <div class="container-box mb-both-mg disaster-msg" v-for="(report, idx) in specialReportList" :key="idx">
          <div class="disaster-text"><span class="point">알림</span>{{ report.msg }}</div>
          <!-- <div class="disaster-text" style="color: #999; margin-left: 48px;"><span class="">발효시각: </span>1월 28일 1시 47분</div> -->
          <div class="disaster-text" style="color: #999; margin-left: 48px;"><span class="">발효시각: </span>{{ report.time }}</div>
        </div>
      </template>
      <template v-else>
        <div class="container-box mb-both-mg disaster-msg">
          <span class="disaster-text" style="">현 지역에는 재난이 발생하지 않았습니다.</span>
        </div>
      </template>
    </div>
    <form-disaster-log ref="formDisasterLog"></form-disaster-log>
  </div>
</template>

<script>
import moment from 'moment';
import formDisasterLog from './forms/formDisasterLog.vue';

export default {
  components: {
    'form-disaster-log': formDisasterLog,
  },
  props: {
    checkBackAction: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    baseTime: '',

    weatherList: [],

    specialReportList: [],
    weatherDefine: {
      sun: {
        name: '맑음',
        img: 'static/images/weather/w-sun.png',
      },
      cloudSome: {
        name: '구름맑음',
        img: 'static/images/weather/w-cloud-some.png',
      },
      cloud: {
        name: '흐림',
        img: 'static/images/weather/w-cloud.png',
      },
      rain: {
        name: '비',
        img: 'static/images/weather/w-rain.png',
      },
      snowy: {
        name: '눈',
        img: 'static/images/weather/w-snowy.png',
      },
      sleet: {
        name: '눈비',
        img: 'static/images/weather/w-sleet.png',
      },
      error: {
        name: ' ',
        img: 'static/images/dot-and-circle_1.png',
      },
    },
    areaCodeList: ['L1080300', 'S1131100', 'S2110200', 'S2120400'],
  }),
  created() {
    // this.backAction();
  },
  async mounted() {
    this.backAction();
    this.getWeather();
    this.getDisaster();
  },
  computed: {},
  methods: {
    backAction() {
      const me = this;
      window.backAction = () => {
        try {
          if (window.JSBridge && window.JSBridge.wvStatus) {
            window.JSBridge.wvStatus('using');
          }
          if (this.checkBackAction()) {
            return;
          } else if (this.$refs.formDisasterLog.isOpenDialog()) {
            this.$refs.formDisasterLog.hideDialog();
          } else {
            this.$router.go(-1);
          }
        } catch (err) {
          this.$message('뒤로가기 에러');
        }
      };
    },

    async requestEvent(args) {
      try {
        const { search = {}, sort, limit } = args;

        const response = await this.$http.post(`${this.$config.getServerConfig().core.api}/ulsanBukgu/api/event`, { search, sort, limit });
        if (!response || response.status !== 200) {
          throw new Error();
        }
        return response.data;
      } catch (err) {
        // this.$message({
        //   type: 'error',
        //   message: 'requestEvent faile',
        // });
        return [];
      }
    },

    async getWeather() {
      const weatherList = await this.requestEvent({
        search: {
          eventTypeId: 'weatherNormalReport',
        },
        sort: { startDate: -1, eventId: 1 },
        limit: 50,
      });

      console.log('getWeather load');
      const weatherListTemp = [];
      let newTime = 0;

      weatherList.forEach((weatherInfo) => {
        const { eventId, eventData = {}, gisLat, gisLng, startDate } = weatherInfo;

        const startDt = new Date(startDate);
        if (newTime < startDt.getTime()) {
          newTime = startDt.getTime();
        } else if (newTime > startDt.getTime()) {
          return;
        }

        const { collectionPlace = {}, LGT, PTY, RN1, SKY, T1H, REH, UUU, VVV, VEC, WSD } = eventData;
        const { emd } = collectionPlace;

        const rain = RN1 && RN1 !== '0' && RN1 !== 0 ? RN1 + 'mm' : '-';

        let weather = '';
        if (PTY === '없음') {
          switch (SKY) {
            case '맑음': {
              weather = this.weatherDefine.sun.img;
              break;
            }
            case '구름맑음': {
              weather = this.weatherDefine.cloudSome.img;
              break;
            }
            case '흐림': {
              weather = this.weatherDefine.cloud.img;
              break;
            }
            default: {
              weather = this.weatherDefine.error.img;
            }
          }
        } else {
          switch (PTY) {
            case '비':
            case '소나기':
            case '빗방울': {
              weather = this.weatherDefine.rain.img;
              break;
            }
            case '눈':
            case '눈날림': {
              weather = this.weatherDefine.snowy.img;
              break;
            }
            case '비/눈':
            case '빗방울/눈날림': {
              weather = this.weatherDefine.sleet.img;
              break;
            }
            default: {
              weather = this.weatherDefine.error.img;
            }
          }
        }

        weatherListTemp.push({
          key: eventId,
          name: emd,
          weather,
          temp: typeof T1H !== 'undefined' ? T1H + '℃' : '-',
          rain,
        });
      });

      this.weatherList = weatherListTemp;
      if (weatherList.length > 0) {
        this.baseTime = moment(weatherList[0].startDate).format('YYYY/MM/DD HH:mm');
      }
    },

    async getDisaster() {
      const search = { eventTypeId: 'weatherSpecialReport' };

      search.$and = [];

      const orCode = this.areaCodeList.map((areaCode) => {
        return { 'eventData.areaCode': areaCode };
      });
      search.$and.push({ $or: orCode });

      // const orEndDate = [
      //   {
      //     eventStat: '1',
      //   } /* {
      //   eventStat: '99',
      //   endDate:{$lte:''},
      //  },*/,
      // ];
      // search.$and.push({ $or: orEndDate });

      search.$and.push({ eventStat: '1' });

      const dt = new Date();
      dt.setDate(dt.getDate() - 7);
      const dtStr = moment(dt).format('YYYYMMDD');
      // const gte = moment(dtStr, 'YYYYMMDD').toDate();

      search.$and.push({
        startDate: { $gte: moment(dtStr, 'YYYYMMDD').toDate() },
      });

      const weatherList = await this.requestEvent({
        search,
        sort: { collectDateTime: -1 },
      });

      if (!Array.isArray(weatherList) || weatherList.length === 0) {
        this.specialReportList = [];
        return;
      }

      const specialReportList = weatherList.map((weatherInfo) => {
        const { eventData } = weatherInfo;

        let stateStr = '발령';
        if (eventData.command === '해제') {
          stateStr = '해제';
        }

        let specialReport = `${eventData.areaName} ${eventData.warnVar}${eventData.warnStress} ${stateStr}`;

        const timeText = moment(eventData.startTime + '', 'YYYYMMDDHHmm').format('MM월 DD일 HH시 mm분');

        return {
          msg: specialReport,
          time: timeText,
        };
      });

      this.specialReportList = specialReportList;
    },
    disasterLog() {
      this.$refs.formDisasterLog.showDialogOnGis();
    },
  },
};
</script>
