<template>
  <div class="fill-width-height el-container">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <dk-el-table-header :openFormDialog="openFormDialog" :isSearch="true" :search="search"></dk-el-table-header>
            <el-table
              :data="materials"
              style="width: 100%; border-top: 1px solid #dedede;"
              empty-text="기자재 정보가 없습니다."
              size="mini"
              @row-click="selectRow"
              stripe
              :show-header="false"
            >
              <el-table-column label="썸네일" width="150px">
                <template slot-scope="scope">
                  <div style="text-align: center;">
                    <img :src="getThumbImg(scope.row)" class="material-thumb-img" />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="내용" min-width="280px">
                <template slot-scope="scope">
                  <div class="material-contents">
                    <h2>{{ scope.row.materialName }}</h2>
                    <p>
                      <span>{{ scope.row.materialTypeName }}</span>
                      <span v-for="(data, index) in scope.row.materialTypeData" :key="index">
                        &nbsp;/&nbsp;
                        <span v-if="data.label">{{ data.label }}&nbsp;:&nbsp;</span>
                        {{ data.value }}
                      </span>
                    </p>
                    <p v-if="scope.row.materialDesc" v-html="printDesc(scope.row.materialDesc)"></p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label width="170px">
                <template slot-scope="scope">
                  <el-button @click="openFormDialog(scope.row)" size="small">수정</el-button>
                  <el-button @click="removeMaterial(scope.row)" size="small" type="danger">삭제</el-button>
                </template>
              </el-table-column>
            </el-table>
            <dk-el-pagination
              :totalDocs="totalDocs"
              :limit="limit"
              :pagingProc="pagingProc"
              ref="pagination"
            ></dk-el-pagination>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <dk-info-panel ref="infoPanel" width="400px">
      <div class="info-panel-wrap">
        <el-tabs v-model="activeTab">
          <el-tab-pane label="기자재정보" name="tab1">
            <h3>{{ selectMaterial.materialName }}</h3>
            <el-carousel
              height="280px"
              v-if="selectMaterial.images && selectMaterial.images.length > 0"
            >
              <el-carousel-item v-for="(image, index) in selectMaterial.images" :key="index">
                <div class="dk-carousel-item" style="background-color: #fff;">
                  <img :src="$config.getServerConfig().image.url + image.serverFilePath" />
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="dk-card">
              <div class="dk-card-title">기자재 정보</div>
              <table class="el-table el-table--mini">
                <!-- <tr>
                                    <th style="width:120px">기자재 명칭</th>
                                    <td>{{selectMaterial.materialName}}</td>
                </tr>-->
                <tr>
                  <th style="width: 110px;">기자재 유형</th>
                  <td>{{ selectMaterial.materialTypeName }}</td>
                </tr>
                <tr v-for="(mtData, index) in selectMaterial.materialTypeData" :key="index">
                  <th>{{ mtData.label }}</th>
                  <td>{{ mtData.value ? mtData.value : '정보 없음' }}</td>
                </tr>
                <tr v-show="selectMaterial.materialDesc">
                  <th>설명</th>
                  <td>{{ selectMaterial.materialDesc }}</td>
                </tr>
              </table>
            </div>
            <div class="dk-card">
              <div class="dk-card-title">장비 정의 데이터</div>
              <table class="el-table el-table--mini">
                <tr>
                  <th>데이터 명칭</th>
                  <th>데이터 KEY</th>
                  <th>데이터 유형</th>
                </tr>
                <tr v-for="(eData, index) in selectMaterial.equipData" :key="index">
                  <td>{{ eData.label ? eData.label : '정보 없음' }}</td>
                  <td>{{ eData.key ? eData.key : '정보 없음' }}</td>
                  <td>
                    <span v-if="eData.formType == 'string'">텍스트</span>
                    <span v-else-if="eData.formType == 'number'">숫자</span>
                    <!-- <span v-else-if="eData.formType=='comobobox'">콤보박스</span> -->
                    <span v-else>정보 없음</span>
                  </td>
                </tr>
              </table>
            </div>
          </el-tab-pane>
          <el-tab-pane label="관련자료" name="tab2">
            <div class="dk-card">
              <el-table
                :data="selectMaterial.files"
                empty-text="등록된 기자재 관련 자료가 없습니다."
                size="mini"
                stripe
                @row-click="downloadReference"
              >
                <el-table-column label="파일 명">
                  <template slot-scope="scope">
                    <span class="file-download-label">{{ scope.row.name }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="파일 크기" width="120px">
                  <template slot-scope="scope">{{ getHumanFileSize(scope.row.size) }}</template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </dk-info-panel>
    <form-material ref="formDialog"></form-material>
  </div>
</template>

<script>
import dkeltableheader from '@/components/dkElTableHeader.vue';
import dkinfopanel from '@/components/dkInfoPanel.vue';
import formmaterial from '@/core/forms/formMaterial.vue';
import dkelpagination from '@/components/dkElPagination.vue';
import DkFileUtil from '@/components/dkFileUtil.js';

export default {
  components: {
    'dk-el-table-header': dkeltableheader,
    'dk-info-panel': dkinfopanel,
    'form-material': formmaterial,
    'dk-el-pagination': dkelpagination,
  },
  created() {
    this.getMaterials(1);
    this.$on('procDataComplete', (materialType) => {
      this.getMaterials();
    });
  },
  data() {
    return {
      materials: [],
      limit: 5,
      totalDocs: 0,
      shownInfoPanel: false,
      selectMaterial: {
        materialName: '',
        materialTypeName: '',
        files: [],
      },
      activeTab: 'tab1',
    };
  },
  methods: {
    selectRow(row, event, column) {
      if (!this.$refs.infoPanel.isOpened()) {
        this.$refs.infoPanel.open();
      }
      this.selectMaterial = row;
    },
    openFormDialog(row) {
      this.$refs.formDialog.showDialog(row);
    },
    removeMaterial(args) {
      this.$confirm(`${args.materialName} 기자재를 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/material/delete`, { data: args });
            this.getMaterials();
            this.$message({
              type: 'success',
              message: `${args.materialName} 기자재 삭제 완료`,
            });
            this.$refs.infoPanel.close();
          } catch (error) {
            this.$message({
              type: 'warning',
              message: `${args.materialName} 기자재 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${args.materialName} 기자재 삭제 취소`,
          });
        });
    },
    getThumbImg(material) {
      let thumb = 'static/images/no-image.jpg';
      if (!material.images) {
        return thumb;
      }
      if (material.images.length == 0) {
        return thumb;
      }
      thumb = `${material.images[0].serverFilePath}`;
      return thumb;
    },
    printDesc(desc) {
      let txt = desc.replace(/(?:\r\n|\r|\n)/g, '<br>');
      return txt;
    },
    pagingProc(pageNum) {
      this.getMaterials();
    },
    async getMaterials(pageNum, keyword) {
      let me = this;
      if (keyword !== '' && keyword) {
        param.keyword = keyword;
      }
      try {
        let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/material`, {
          params: {
            page: pageNum || this.$refs.pagination.getPage(),
            limit: this.limit,
          },
        });
        me.materials = response.data.docs;
        me.totalDocs = response.data.totalDocs;
        me.$refs.infoPanel.close();
      } catch (error) {
        this.$message({
          type: 'warning',
          message: `기자재정보 로드 에러`,
        });
      }
    },
    getHumanFileSize(bytes) {
      return DkFileUtil.getHumanFileSize(bytes, true);
    },
    downloadReference(row, event, column) {
      DkFileUtil.downloadFromUrl(row.url, row.name);
    },
    search(keyword) {
      this.getMaterials(null, keyword);
    },
  },
};
</script>

<style scoped>
.material-thumb-img {
  width: auto;
  height: auto;
  max-height: 100px;
  max-width: 100%;
  margin: 5px;
}

.material-contents {
  max-width: 560px;
  max-height: 130px;
  padding: 0 10px;
}

.material-contents p {
  line-height: 18px;
}
</style>
