import axios from 'axios';

export default {
  namespaced: true,
  state: {
    vmsDownloadedSchedule: {
      value: null,
    },
    vmsAvailableDownloadedSchedule: {
      value: null,
    },
    pagination: {
      totalDocs: 0,
      limit: 10,
      page: 1,
    },
  },
  getters: {
    getVmsDownloadedSchedule: (state) => {
      return state.vmsDownloadedSchedule.value;
    },
    getVmsAvailableDownloadedSchedule: (state) => {
      return state.vmsAvailableDownloadedSchedule.value;
    },
    getPagination: (state) => {
      return state.pagination;
    },
  },
  mutations: {
    setVmsDownloadedSchedule(state, payload) {
      state.vmsDownloadedSchedule.value = payload;
      //localStorage.setItem('gisApi', payload)
    },
    setVmsAvailableDownloadedSchedule(state, payload) {
      state.vmsAvailableDownloadedSchedule.value = payload;
    },
    setPagination(state, payload) {
      state.pagination = payload;
    },
  },
  actions: {
    getVmsDownloadedSchedule({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${this.state.dkConfig.getServerConfig().core.api}/core/api/vmsDownloadedSchedule`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
        .then((response) => {
          commit('setVmsDownloadedSchedule', response.data);
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
    },
    getVmsAvailableDownloadedSchedule({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${this.state.dkConfig.getServerConfig().core.api}/core/api/vmsAvailableDownloadedSchedule`, { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
        .then((response) => {
          commit('setVmsAvailableDownloadedSchedule', response.data);
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    procVmsDownloadedSchedule({ commit }, payload) {
      return axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/api/vmsDownloadedSchedule/proc`, payload);
    },
    deleteVmsDownloadedSchedule({ commit }, payload) {
      return axios.post(`${this.state.dkConfig.getServerConfig().core.api}/core/api/vmsDownloadedSchedule/delete`, payload);
    },
  },
};
