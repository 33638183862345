var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-width-height" },
    [
      _c(
        "el-row",
        { staticClass: "page-body content-wrap" },
        [
          _c("dk-page-header"),
          _c(
            "el-col",
            { staticClass: "page-content", attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.features,
                    "empty-text": "등록된 기능모듈 정보가 없습니다.",
                    size: "mini"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "기능모듈 ID", prop: "featureId" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "모듈 명칭", prop: "moduleName" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "라우팅 URL", prop: "url" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "icon", label: "아이콘", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [_c("i", { class: scope.row.icon })]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "컴포넌트 경로", prop: "componentPath" }
                  }),
                  _c("el-table-column", {
                    attrs: { width: "250" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            !scope.row.editable
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.editRow(scope.$index)
                                      }
                                    }
                                  },
                                  [_vm._v("수정")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "danger" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteRow(scope.$index)
                                  }
                                }
                              },
                              [_vm._v("삭제")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }