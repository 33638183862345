var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "시설물 주소 갱신",
        visible: _vm.formDialog,
        "before-close": _vm.hideDialog,
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.formDialog = $event
        }
      }
    },
    [
      _c(
        "div",
        [
          _c("h4", [
            _vm._v(
              "현재 등록된 시설물의 GIS 좌표를 이용하여 시설물의 주소를 갱신합니다."
            )
          ]),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 20 } }, [
                _c("p", { staticClass: "address-notice" }, [
                  _vm._v(
                    "\n          이 기능은 VWorld geocoding을 이용하여 주소를 얻어옵니다. 실행 시 단시간에 여러 쿼리로 인한 api key block의 위험이 있으므로, 시설물 한\n          지점당 약 100ms의 지연시간을 두고 실행합니다.\n          "
                  ),
                  _c("br"),
                  _vm._v("현재 시스템에 등록된 시설물의 수량이 총 "),
                  _c("span", [_vm._v(_vm._s(this.gridData.length) + "개소")]),
                  _vm._v(" 이므로, 작업시간은 약\n          "),
                  _c("span", [
                    _vm._v(
                      _vm._s(((this.gridData.length * 100) / 1000).toFixed(0)) +
                        "초"
                    )
                  ]),
                  _vm._v("가 예상됩니다. "),
                  _c("br"),
                  _vm._v(
                    "또한 하루에 총 3만건 이상 조회 시 API키의 쿼리\n          조회가 초과하여 더 이상 주소정보를 갱신할 수 없으므로, 작업에 유념하시길 바랍니다.\n        "
                  )
                ])
              ]),
              _c(
                "el-col",
                { staticClass: "button", attrs: { span: 4 } },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "generate-button",
                      attrs: { type: "primary", disabled: !_vm.isReady },
                      on: { click: _vm.addressGenerate }
                    },
                    [_vm._v("주소갱신")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "table-wrap" }, [
            _vm.gridData.length > 0
              ? _c(
                  "table",
                  {
                    staticClass: "dk-table dk-table-small excel-preview-table"
                  },
                  [
                    _c("tr", [
                      _c("th", [_vm._v("시설물유형")]),
                      _c("th", [_vm._v("시설물")]),
                      _c("th", [_vm._v("지번주소")]),
                      _c("th", [_vm._v("도로명주소")]),
                      _c("th", [_vm._v("경도")]),
                      _c("th", [_vm._v("위도")])
                    ]),
                    _vm._l(_vm.gridData, function(row, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(row.fcltTypeName))]),
                        _c("td", [_vm._v(_vm._s(row.fcltName))]),
                        _c("td", [_vm._v(_vm._s(row.setNumberAddr))]),
                        _c("td", [_vm._v(_vm._s(row.setRoadAddr))]),
                        _c("td", [_vm._v(_vm._s(row.gisLng))]),
                        _c("td", [_vm._v(_vm._s(row.gisLat))])
                      ])
                    })
                  ],
                  2
                )
              : _vm._e()
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }