<template>
  <div class="fill-width-height el-container">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="page-content">
            <dk-el-table-header :openFormDialog="openFormDialog"></dk-el-table-header>
            <el-table empty-text="등록된 센터관리 정보가 없습니다." size="mini" stripe>
              <el-table-column label="서버ID" prop="serverId"></el-table-column>
              <el-table-column label="서버명" prop="serverName" header-align="center" align="center"></el-table-column>
              <el-table-column label="서버IP" header-align="center" align="center"></el-table-column>
              <el-table-column label="서버 포트" header-align="center" align="center"></el-table-column>
              <el-table-column>
                <el-button size="small">수정</el-button>
                <el-button size="small" type="danger">삭제</el-button>
              </el-table-column>
            </el-table>
            <dk-el-pagination
              :totalDocs="totalDocs"
              :limit="limit"
              :pagingProc="pagingProc"
              ref="pagination"
            ></dk-el-pagination>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <form-server-manage ref="formDialog"></form-server-manage>
  </div>
</template>

<script>
import dkeltableheader from '@/components/dkElTableHeader.vue';
import dkelpagination from '@/components/dkElPagination.vue';
import formservermanage from '@/core/forms/formServerManage.vue';

export default {
  components: {
    'dk-el-table-header': dkeltableheader,
    'dk-el-pagination': dkelpagination,
    'form-server-manage': formservermanage,
  },
  created() {},
  data() {
    return {
      totalDocs: 0,
      limit: 10,
    };
  },
  methods: {
    pagingProc(pageNum) {
      this.getServiceManages();
    },
    openFormDialog(row) {
      this.$refs.formDialog.showDialog(row);
    },
  },
};
</script>

<style scoped></style>
