var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-set" }, [
    _c("div", { staticClass: "top-bar-space", staticStyle: { bottom: "0" } }, [
      _c(
        "ul",
        { staticClass: "disease-type-container" },
        _vm._l(_vm.menuList, function(menuInfo) {
          return _c(
            "li",
            {
              key: menuInfo.key,
              on: {
                click: function(e) {
                  _vm.onClickMenu(e, menuInfo)
                }
              }
            },
            [
              _c("span", [
                _vm._v("\n          " + _vm._s(menuInfo.name) + "\n        ")
              ])
            ]
          )
        }),
        0
      ),
      _c("h3", { staticClass: "middle-title" }, [
        _vm._v("\n      전국 현황 "),
        _c("span", { staticClass: "middle-title-date" }, [
          _vm._v(
            "(" +
              _vm._s(_vm.totalDataDate ? _vm.totalDataDate + " 기준" : "") +
              ")"
          )
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "content-click-container flex-box mb-both-mg",
          staticStyle: { height: "35%", "max-height": "35%" }
        },
        _vm._l(_vm.countryCovidList, function(countryCovidInfo) {
          return _c(
            "div",
            { key: countryCovidInfo.key, staticClass: "content-click-box" },
            [
              _c("div", { staticClass: "flex-center covid-info" }, [
                _c("span", [_vm._v(" " + _vm._s(countryCovidInfo.name) + " ")]),
                _c("span", [
                  _vm._v(" " + _vm._s(countryCovidInfo.count) + " ")
                ]),
                _c("span", [
                  _vm._v(" " + _vm._s(countryCovidInfo.upCount) + " ")
                ])
              ])
            ]
          )
        }),
        0
      ),
      _c("h3", { staticClass: "middle-title" }, [
        _vm._v("\n      울산 현황 "),
        _c("span", { staticClass: "middle-title-date" }, [
          _vm._v(
            "(" +
              _vm._s(_vm.ulsanDataDate ? _vm.ulsanDataDate + " 기준" : "") +
              ")"
          )
        ])
      ]),
      _c(
        "div",
        {
          staticClass: "content-click-container flex-box mb-both-mg",
          staticStyle: { height: "35%", "max-height": "35%" }
        },
        _vm._l(_vm.ulsanCovidList, function(ulsanCovidInfo) {
          return _c(
            "div",
            { key: ulsanCovidInfo.key, staticClass: "content-click-box" },
            [
              _c("div", { staticClass: "flex-center covid-info" }, [
                _c("span", [_vm._v(" " + _vm._s(ulsanCovidInfo.name) + " ")]),
                _c("span", [_vm._v(" " + _vm._s(ulsanCovidInfo.count) + " ")]),
                _c("span", [_vm._v(" " + _vm._s(ulsanCovidInfo.upCount) + " ")])
              ])
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "covid-graph" },
        [
          _c("covid-chart", {
            attrs: {
              chartHeight: "250px",
              chartWidth: _vm.chartWidth,
              columnNameList: _vm.columnNameList,
              columnColorList: _vm.columnColorList,
              dataList: _vm.chartDataList
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }