<template>
  <el-dialog
    :visible.sync="formDialog"
    :title="formTitle"
    @close="hideDialog"
    :close-on-click-modal="false"
  >
    <!-- 기자재 관리 dialog -->
    <div>
      <el-steps :active="step" finish-status="success" align-center style="margin: 5px 0 10px 0;">
        <el-step v-for="(s, index) in stepData" :key="index" :title="s.title"></el-step>
      </el-steps>
      <div v-show="step === 0">
        <el-form
          :model="materialDataDoc"
          label-width="120px"
          size="medium"
          :rules="rules"
          ref="form0"
          :label-position="$isMobile ? 'top' : 'right'"
        >
          <el-row>
            <el-col :span="20">
              <el-form-item label="기자재 명칭" prop="materialName">
                <el-input v-model="materialDataDoc.materialName" placeholder="기자재 명칭"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="기자재 유형" prop="materialTypeId">
                <el-select
                  v-model="materialDataDoc.materialTypeId"
                  placeholder="기자재 유형"
                  @change="selectMaterialType"
                >
                  <el-option
                    v-for="item in materialType"
                    :key="item._id"
                    :label="item.materialTypeName"
                    :value="item._id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <dk-dynamic-form labelWidth="120px" :formData="materialDataDoc.materialTypeData"></dk-dynamic-form>
            </el-col>
            <el-col :span="20">
              <el-form-item label="설명" prop="materialDesc">
                <el-input type="textarea" v-model="materialDataDoc.materialDesc" placeholder="설명"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div v-show="step === 1">
        <el-transfer v-model="tempEquipData" :data="datasets" :titles="['데이터셋', '시설물 장비 데이터']"></el-transfer>
      </div>

      <div v-show="step === 2">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12">
            <h3>기자재 관련 사진</h3>
            <dk-el-file-upload
              ref="uploadImage"
              uploaderType="image"
              listType="picture-card"
              path="material"
              :fileList="materialDataDoc.images"
            >
              <div>
                <i class="el-icon-plus"></i>
              </div>
            </dk-el-file-upload>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12">
            <h3>기자재 관련 자료</h3>
            <dk-el-file-upload
              ref="uploadFile"
              path="materialDoc"
              :fileList="materialDataDoc.files"
              :drag="true"
              :multiple="true"
            >
              <div>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  이곳에 업로드할 파일을 드롭하시거나
                  <br />
                  <em>클릭해서 업로드하세요</em>
                </div>
              </div>
            </dk-el-file-upload>
          </el-col>
        </el-row>
      </div>

      <div class="form-bottom">
        <el-button @click="prev" style="float: left;" v-show="step > 0">
          <i class="fas fa-angle-left"></i>&nbsp;&nbsp;이전
        </el-button>
        <el-button @click="next" style="float: right;" v-show="step < stepData.length - 1">
          다음&nbsp;&nbsp;
          <i class="fas fa-angle-right"></i>
        </el-button>
        <el-button @click="save" style="float: right;" v-show="step === stepData.length - 1">
          저장&nbsp;&nbsp;
          <i class="fas fa-angle-right"></i>
        </el-button>
      </div>
    </div>
    <!-- 기자재 관리 dialog 끝 -->
  </el-dialog>
</template>

<script>
import dkdynamicform from '@/components/dkDynamicForm.vue';
import DkFileUtil from '@/components/dkFileUtil.js';
import dkElFileUpload from '@/components/dkElFileUpload.vue';

export default {
  components: {
    'dk-dynamic-form': dkdynamicform,
    'dk-el-file-upload': dkElFileUpload,
  },
  created() {
    this.getMaterialType();
    this.getDataSet();
  },
  data() {
    return {
      formDialog: false,
      formTitle: '기자재 추가',
      updateFlag: false,
      materialType: [],
      datasets: [],
      step: 0,
      stepData: [
        { idx: 0, title: '기자재 정보' },
        { idx: 1, title: '장비 데이터셋 설정' },
        { idx: 2, title: '관련 파일' },
      ],
      materialDataDoc: {
        materialName: '',
        materialTypeName: '',
        materialTypeId: '',
        files: [],
        equipData: {},
        materialTypeData: {},
        materialDesc: '',
        files: [],
        images: [],
      },
      tempEquipData: [],
      rules: {
        materialName: [
          {
            required: true,
            message: '기자재 명칭을 입력해 주세요',
            trigger: 'blur',
          },
          { min: 3, message: '3자 이상 입력해주세요', trigger: 'blur' },
        ],
        materialTypeId: [
          {
            required: true,
            message: '기자재 유형을 선택해 주세요',
            trigger: 'change',
          },
        ],
      },
    };
  },
  props: {},
  updated() {},
  methods: {
    async getMaterialType() {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/materialType`, {});
      this.materialType = response.data.docs;
    },
    async getDataSet() {
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/dataset`, {});
      this.datasets = response.data;
    },
    next() {
      if (this.$refs[`form${this.step}`]) {
        this.$refs[`form${this.step}`].validate((valid) => {
          if (valid) {
            this.step++;
          } else {
            return false;
          }
        });
      } else {
        this.step++;
      }

      if (this.step == 1) {
      } else if (this.step == 2) {
      }
    },
    prev() {
      if (this.step == 1) {
      }
      this.step--;
    },
    async save() {
      this.step++;
      let me = this;
      let saveFunc = 'material/add';
      if (this.updateFlag) {
        saveFunc = 'material/update';
      }
      let tempData = {};
      this.tempEquipData.forEach((val) => {
        let data = this.datasets.find((dataset) => {
          return val == dataset.key;
        });
        tempData[val] = data;
      });
      this.materialDataDoc.equipData = tempData;
      this.materialDataDoc.files = this.$refs.uploadFile.getFileData();
      this.materialDataDoc.images = this.$refs.uploadImage.getFileData();
      try {
        let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/${saveFunc}`, {
          data: this.materialDataDoc,
        });
        me.$parent.$emit('procDataComplete', this.materialDataDoc);
        me.resetData();
        me.hideDialog();
        me.$message({
          type: 'success',
          message: `${me.materialDataDoc.materialName} 기자재 저장 완료`,
        });
      } catch (error) {
        me.resetData();
        me.hideDialog();
        me.$message({
          type: 'info',
          message: `${me.materialDataDoc.materialName} 기자재 저장 중 에러 발생`,
        });
      }
    },
    resetData() {
      this.step = 0;
      this.materialDataDoc = {
        materialName: '',
        materialTypeName: '',
        materialTypeId: '',
        files: [],
        equipData: {},
        materialTypeData: {},
        materialDesc: '',
        files: [],
        images: [],
      };
      this.selectEquipFormData = {};
      this.tempEquipData = [];
    },
    showDialog(row) {
      if (row) {
        this.updateFlag = true;
        this.formTitle = '기자재 수정';
        let tempData = this.$lodash.cloneDeep(row);
        for (var key in tempData.equipData) {
          this.tempEquipData.push(key);
        }
        tempData.equipData = {};
        this.materialDataDoc = tempData;
      } else {
        this.formTitle = '기자재 추가';
      }
      this.formDialog = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        if (row) {
          this.$refs.uploadFile.setFileData(row.files);
          this.$refs.uploadImage.setFileData(row.images);
        } else {
          this.$refs.uploadFile.setFileData(null);
          this.$refs.uploadImage.setFileData(null);
        }
      });
    },
    hideDialog() {
      this.resetData();
      this.formDialog = false;
    },
    selectMaterialType(val) {
      let me = this;

      let tempList = this.materialType.filter((item) => {
        return item._id.toLowerCase().indexOf(val.toLowerCase()) > -1;
      });
      if (tempList.length > 0) {
        this.materialDataDoc.materialTypeName = tempList[0].materialTypeName;
        this.materialDataDoc.materialTypeData = this.$lodash.cloneDeep(tempList[0].materialTypeData);
      }
    },
    procUploadFile(file, fileList) {
      this.tempUploadFiles = [];
      fileList.forEach((fl) => {
        if (fl.status === 'success') {
          if (fl.response) {
            this.tempUploadFiles.push({
              name: fl.name,
              serverFileName: fl.response.result.serverFileName,
              serverFilePath: fl.response.result.serverFilePath,
              size: fl.size,
              url: `${fl.response.result.serverFilePath}`,
            });
          } else {
            this.tempUploadFiles.push(fl);
          }
        }
      });
    },
    procRemoveFile(file, fileList) {
      this.tempUploadFiles = [];
      fileList.forEach((fl) => {
        this.tempUploadFiles.push(fl);
      });
    },
    procUploadImage(file, fileList) {
      this.tempUploadImages = [];
      fileList.forEach((fl) => {
        if (fl.status === 'success') {
          if (fl.response) {
            this.tempUploadImages.push({
              name: fl.name,
              serverFileName: fl.response.result.serverFileName,
              serverFilePath: fl.response.result.serverFilePath,
              size: fl.size,
              url: `${fl.response.result.serverFilePath}`,
            });
          } else {
            this.tempUploadImages.push(fl);
          }
        }
      });
    },
    procRemoveImage(file, fileList) {
      this.tempUploadImages = [];
      fileList.forEach((fl) => {
        this.tempUploadImages.push(fl);
      });
    },
    checkUploadImage(file) {
      if (!DkFileUtil.isImage(file.name)) {
        this.$message.error(`이미지 파일만 업로드 할 수 있습니다.`);
        return false;
      }
    },
  },
};
</script>

<style scoped>
.el-form-item__content {
  margin-left: 120px;
}
</style>
