var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-width-height el-container" },
    [
      _c(
        "el-container",
        { staticClass: "page-body" },
        [
          _c(
            "el-main",
            [
              _c(
                "el-row",
                { staticClass: "content-wrap" },
                [
                  _c("dk-page-header"),
                  _c(
                    "el-col",
                    { staticClass: "page-content", attrs: { span: 24 } },
                    [
                      _c(
                        "el-table",
                        {
                          staticStyle: {
                            width: "100%",
                            "border-top": "1px solid #dedede"
                          },
                          attrs: {
                            data: _vm.vmsLists,
                            "empty-text": "등록된 VMS 장비가 없습니다.",
                            size: "mini",
                            stripe: ""
                          },
                          on: { "row-click": _vm.selectRow }
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "fcltName",
                              label: "VMS 지점명",
                              "min-width": "180px"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "통신 상태",
                              "header-align": "center",
                              align: "center",
                              width: "80px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "통신 상태",
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-tag",
                                          { attrs: { type: "success" } },
                                          [_vm._v("정상")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "모듈 전원",
                              "header-align": "center",
                              align: "center",
                              width: "80px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "모듈 전원",
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-tag",
                                          { attrs: { type: "success" } },
                                          [_vm._v("정상")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "팬 동작",
                              "header-align": "center",
                              align: "center",
                              width: "80px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "팬 동작",
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-tag",
                                          { attrs: { type: "success" } },
                                          [_vm._v("정상")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "히터 동작",
                              "header-align": "center",
                              align: "center",
                              width: "80px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "히터 동작",
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-tag",
                                          { attrs: { type: "success" } },
                                          [_vm._v("정상")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "함체 팬",
                              "header-align": "center",
                              align: "center",
                              width: "80px"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(scope) {
                                  return [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: "함체 팬 동작",
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-tag",
                                          { attrs: { type: "success" } },
                                          [_vm._v("정상")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c("dk-el-pagination", {
                        ref: "pagination",
                        attrs: {
                          totalDocs: _vm.totalDocs,
                          limit: _vm.limit,
                          pagingProc: _vm.pagingProc
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("dk-info-panel", { ref: "infoPanel", attrs: { width: "400px" } }, [
        _c("div", { staticClass: "info-panel-wrap" })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }