<template>
  <div class="fill-width-height el-container" id="pageAssetManage">
    <el-container class="page-body">
      <el-main>
        <el-row class="content-wrap" style="height: 100%;">
          <dk-page-header></dk-page-header>
          <el-col :span="24" class="data-wrap">
            <div class="template-list">
              <dk-scroll-view>
                <template v-slot:header>
                  <div class="template-header">
                    <h4>템플릿 목록</h4>
                    <div class="template-button-wrap">
                      <el-button size="small" @click="addTemplate">템플릿 추가</el-button>
                    </div>
                  </div>
                </template>
                <template v-slot:body>
                  <div v-for="(item, idx) in templateList" :key="idx" class="template-list-cell">
                    <el-dropdown split-button type="default" @click="selectTemplate(item)">
                      {{ item.templateName }}
                      <el-dropdown-menu slot="dropdown">
                        <el-button icon="el-icon-edit" @click="updateTemplate(item)" size="mini">수정</el-button>
                        <el-button icon="el-icon-delete" @click="deleteTemplate(item)" size="mini" type="danger">삭제</el-button>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </template>
              </dk-scroll-view>
            </div>
            <div class="asset-data-wrap">
              <div v-if="this.selectedTemplate" class="asset-data-grid" ref="assetDataGrid">
                <div class="asset-data-header">
                  <span class="title">
                    <h3>{{ this.selectedTemplate.templateName }}</h3>
                  </span>
                  <span class="button-wrap">
                    <el-button @click="addAsset" type="primary">자산정보 추가</el-button>
                    <el-button size="small" class="icon-button-2" @click="assetExportExcel">
                      <img src="../assets/img/excel_icon.png" />엑셀 출력
                    </el-button>
                  </span>
                </div>
                <el-table :data="assetList" style="width: 100%;" :height="gridHeight" lazy>
                  <el-table-column
                    v-for="(header, idx) in this.selectedTemplate.templateData"
                    :key="idx"
                    :prop="header.dataKey"
                    :label="header.label"
                  >
                    <template slot-scope="scope">
                      <span
                        v-if="
                          (scope.row[header.dataKey] && scope.row[header.dataKey].type === 'string') ||
                          (scope.row[header.dataKey] && scope.row[header.dataKey].type === 'number') ||
                          (scope.row[header.dataKey] && scope.row[header.dataKey].type === 'select')
                        "
                      >
                        {{ scope.row[header.dataKey].value }}
                      </span>
                      <span v-else-if="scope.row[header.dataKey] && scope.row[header.dataKey].type === 'date'">
                        {{ getFormatDate(scope.row[header.dataKey].value) }}
                      </span>
                      <span v-else> </span>
                    </template>
                  </el-table-column>
                  <el-table-column width="160">
                    <template slot-scope="scope">
                      <el-button @click="updateAsset(scope.row)" size="mini">수정</el-button>
                      <el-button @click="deleteAsset(scope.row)" size="mini" type="danger">삭제</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <form-asset-template ref="formAssetTemplate" @registerdTemplate="getTemplates" />
    <form-asset-dialog ref="formAssetDialog" @registeredAssetData="getAssets"></form-asset-dialog>
  </div>
</template>

<script>
import _ from 'lodash';
import dkScrollView from '@/components/dkScrollView.vue';
// import dkTemplateTable from '@/components/dkTemplateTable.vue';
import formAssetTemplate from '@/core/forms/formAssetTemplate.vue';
import formAssetDialog from '@/core/forms/formAssetDialog.vue';
import moment from 'moment';
import XLSX from 'xlsx';

export default {
  components: {
    'form-asset-template': formAssetTemplate,
    'dk-scroll-view': dkScrollView,
    'form-asset-dialog': formAssetDialog,
    // 'dk-template-table': dkTemplateTable,
  },
  created() {
    window.addEventListener('resize', this.resizeScreen);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeScreen);
  },
  data() {
    return {
      assetList: [],
      templateList: [],
      selectedTemplate: null,
      gridHeight: 500,
      systemLogInfo: {},
      currentRow: null,
    };
  },
  async mounted() {
    await this.getTemplates();
    await this.getAssets();
  },
  updated() {
    this.$nextTick(function () {
      this.resizeScreen();
    });
  },
  methods: {
    async getTemplates() {
      const param = {
        featureId: this.$route.meta.featureId,
      };
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/template`, {
        params: param,
      });
      this.templateList = response.data;
      if (this.templateList && this.templateList.length > 0) {
        this.selectedTemplate = this.templateList[0];
      }
    },
    async getAssets() {
      const param = {
        templateId: this.selectedTemplate.templateId,
      };
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/asset`, {
        params: param,
      });
      this.assetList = response.data;
    },
    selectTemplate(template) {
      this.selectedTemplate = template;
      this.getAssets();
    },
    addTemplate() {
      this.$refs.formAssetTemplate.showDialog(this.$route.meta.featureId);
    },
    updateTemplate(templateData) {
      this.$refs.formAssetTemplate.showDialog(this.$route.meta.featureId, templateData);
    },
    deleteTemplate(templateData) {
      debugger;
      this.systemLogInfo.page = `${this.$route.name} ${templateData.templateName} 템플릿`;
      this.systemLogInfo.saveType = 'delete';
      this.$confirm(`${templateData.templateName} 템플릿을 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/template/delete`, { data: templateData });
            this.$message({
              type: 'success',
              message: `${templateData.templateName} 템플릿 삭제 완료`,
            });
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
            await this.getTemplates();
            await this.getAssets();
          } catch (err) {
            this.$message({
              type: 'info',
              message: `${templateData.templateName} 템플릿 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `${templateData.templateName} 템플릿 삭제 취소`,
          });
        });
    },
    addAsset() {
      this.$refs.formAssetDialog.showDialog(null, this.selectedTemplate);
    },
    updateAsset(assetData) {
      this.$refs.formAssetDialog.showDialog(assetData, this.selectedTemplate);
    },
    deleteAsset(assetData) {
      this.$confirm(`해당 자산정보를 삭제 합니다.`, '경고', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          try {
            await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/asset/delete`, { data: assetData });
            this.$message({
              type: 'success',
              message: `자산정보 삭제 완료`,
            });
            await this.getAssets();
          } catch (err) {
            this.$message({
              type: 'info',
              message: `자산정보 삭제 중 에러 발생`,
            });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: `자산정보 삭제  취소`,
          });
        });
    },
    resizeScreen() {
      this.gridHeight = this.$refs.assetDataGrid.offsetHeight - 60;
    },
    getFormatDate(timestamp) {
      return moment.unix(timestamp / 1000).format('YYYY-MM-DD');
    },
    async assetExportExcel() {
      let label = {};
      let bodyData = [];
      let headerData = [];
      let optionData = [];
      let labelData = [];
      // const header = await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/asset/getHeader`, { params: this.selectedTemplate });
      for (let i = 0; i < this.selectedTemplate.templateData.length; i++) {
        headerData.push({
          key: i,
          name: this.selectedTemplate.templateData[i].dataKey,
        });
        if (this.selectedTemplate.templateData[i].options) {
          optionData.push({
            key: this.selectedTemplate.templateData[i].dataKey,
            option: this.selectedTemplate.templateData[i].options,
          });
        }
        label[this.selectedTemplate.templateData[i].dataKey] = this.selectedTemplate.templateData[i].label;
      }
      labelData.push(label);
      const body = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/asset/getExcelData`, {
        params: {
          templateId: this.selectedTemplate.templateId,
        },
      });
      body.data.forEach((n) => {
        let rowData = {};
        _.map(headerData, (filter) => {
          const key = filter.name;
          const val = _.find(n, (v, k) => {
            return k === key;
          });

          if (typeof val === 'object') {
            if (val.type === 'date') {
              rowData[key] = moment.unix(parseFloat(val.value) / 1000).format('YYYY-MM-DD');
            } else {
              rowData[key] = val.value;
            }
          } else {
            rowData[key] = val;
          }
        });
        bodyData.push(rowData);
      });
      const excelData = this.$lodash.union(labelData, bodyData);
      let wb = XLSX.utils.book_new();
      let ws = XLSX.utils.json_to_sheet(excelData, headerData);
      ws['!rows'] = [];
      ws['!rows'][0] = { hidden: true };
      XLSX.utils.book_append_sheet(wb, ws, 'Data Sheet');
      XLSX.writeFile(wb, `자산관리 시트.xlsx`, { bookType: 'xlsx', type: 'array' });
      this.systemLogInfo.page = this.$route.name + '템플릿';
      this.systemLogInfo.saveType = 'exportExcel';
      await this.$http.post(`${this.$config.getServerConfig().core.api}/core/api/systemLog/add`, { data: this.systemLogInfo });
    },
  },
};
</script>

<style scoped></style>
