<template>
  <div>
    <div class="dk-card" :style="cardTransparent">
      <div class="dk-card-title" v-if="isTitleShow">공지사항</div>
      <div>
        <el-table
          :data="notices"
          :class="isTransparent ? 'transparent-dark-table' : ''"
          empty-text="등록된 공지사항이 없습니다."
          size="mini"
          @cell-click="selectRow"
        >
          <el-table-column label="제목" prop="title" header-align="center"></el-table-column>
          <el-table-column label="작성일" header-align="center" align="center">
            <template slot-scope="scope">{{ getWriteDate(scope.row.writeDate) }}</template>
          </el-table-column>
        </el-table>
        <dk-el-pagination :totalDocs="totalDocs" :limit="limit" :pagingProc="pagingProc" v-if="displayPaging" ref="pagination"></dk-el-pagination>
      </div>
    </div>
    <el-dialog :visible.sync="noticeShow" class="el-dialog-notice">
      <el-carousel v-show="selectNotice.images && selectNotice.images.length > 0" height="400px">
        <el-carousel-item v-for="(file, index) in selectNotice.images" :key="index">
          <div class="dk-carousel-item">
            <img :src="$config.getServerConfig().image.url + file.serverFilePath" />
          </div>
        </el-carousel-item>
      </el-carousel>
      <div v-if="selectNotice.images && selectNotice.images.length > 0" style="padding: 0 30px 20px 30px;">
        <h3>{{ selectNotice.title }}</h3>
        <h5>{{ selectNotice.text }}</h5>
      </div>
      <div v-else style="padding: 30px 30px 20px 30px;">
        <h2>{{ selectNotice.title }}</h2>
        <h4>{{ selectNotice.text }}</h4>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dailyNoticeShow" class="el-dialog-notice">
      <el-carousel v-show="selectNotice.images && selectNotice.images.length > 0" height="400px">
        <el-carousel-item v-for="(file, index) in selectNotice.images" :key="index">
          <div class="dk-carousel-item">
            <img :src="$config.getServerConfig().image.url + file.serverFilePath" />
          </div>
        </el-carousel-item>
      </el-carousel>
      <div v-if="selectNotice.images && selectNotice.images.length > 0" style="padding: 0 30px 20px 30px;">
        <h3>{{ selectNotice.title }}</h3>
        <h5>{{ selectNotice.text }}</h5>
      </div>
      <div v-else style="padding: 30px 30px 20px 30px;">
        <h2>{{ selectNotice.title }}</h2>
        <h4>{{ selectNotice.text }}</h4>
      </div>
      <span slot="footer" class="dialog-footer" v-if="isDailyPopup">
        <el-button @click="dailyClose" size="mini">하루동안 이 공지를 열지 않습니다</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import moment from 'moment';
import dkelpagination from '@/components/dkElPagination.vue';

export default {
  components: {
    'dk-el-pagination': dkelpagination,
  },
  created() {
    this.getNotices(1).then(() => {
      this.checkPopupNotice();
    });
  },
  computed: {
    cardTransparent() {
      let style = {};
      if (this.isTransparent) {
        style['backgroundColor'] = 'transparent';
      }
      return style;
    },
  },
  props: {
    displayPaging: {
      default: true,
      type: Boolean,
    },
    isTitleShow: {
      default: true,
      type: Boolean,
    },
    isTransparent: {
      default: false,
      type: Boolean,
    },
    noticeCount: {
      default: 5,
      type: Number,
    },
  },
  data() {
    return {
      notices: [],
      limit: this.noticeCount,
      totalDocs: 0,
      noticeShow: false,
      dailyNoticeShow: false,
      selectNotice: {
        title: '',
        text: '',
        writerId: [],
        images: [],
      },
      dataCursor: 0,
      isDailyPopup: true,
    };
  },
  methods: {
    checkPopupNotice() {
      if (this.dataCursor >= this.notices.length) {
        this.isDailyPopup = false;
        return;
      }
      let notice = this.notices[this.dataCursor];
      if (!notice.isPopup) {
        this.dataCursor++;
        return this.checkPopupNotice();
      }
      let endPopupTime = moment(notice.endDate);
      let now = moment();
      if (now.unix() > endPopupTime.unix()) {
        this.dataCursor++;
        return this.checkPopupNotice();
      }
      let cookieTime = localStorage.getItem(`notice${notice._id}`);
      if (cookieTime) {
        if (moment(parseInt(cookieTime)).date() == now.date()) {
          this.dataCursor++;
          return this.checkPopupNotice();
        }
      }
      this.selectNotice = notice;
      this.dailyNoticeShow = true;
    },
    async getNotices(pageNum) {
      let me = this;
      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/notice`, {
        params: {
          page: pageNum || this.$refs.pagination.getPage(),
          limit: this.limit,
        },
      });

      me.notices = response.data.docs;
      me.totalDocs = response.data.totalDocs;
    },
    pagingProc(pageNum) {
      this.getNotices();
    },
    getWriteDate(writeDate) {
      return moment(writeDate).format('YYYY-MM-DD');
    },
    selectRow(row, column, cell, event) {
      if (column.label == '') {
        return;
      }
      this.selectNotice = row;
      this.noticeShow = true;
    },
    dailyClose() {
      localStorage.setItem(`notice${this.selectNotice._id}`, new Date().getTime());
      this.dailyNoticeShow = false;
      this.dataCursor++;
      this.checkPopupNotice();
    },
  },
};
</script>

<style scoped></style>
